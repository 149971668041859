//* ******************************** REACT NATIVE IMPORTS ********************************
import React, { useEffect, useState } from "react";

//* *********************************** EXTERNAL PACKAGES ************************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
import EventsMatrix from "../../../components/charts/home/eventsMatrix";

//* ************************************** GLOBALS ***************************************
import { getEvents, getFreqList } from "../../../redux/actions/patientEvents";

//* ************************************** UTILS ***************************************
import { capitalizeFirstLetter } from "../../../utils/string";

const EventSymptomsInfo = ({ patientId }) => {
  //* *************************************** SETUP ****************************************
  const [isEventDataLoading, setIsEventDataLoading] = useState(false);
  const [isFreqListLoading, setIsFreqListLoading] = useState(false);
  const [hasAccessPermit, setHasAccessPermit] = useState(true);

  const { t, i18n } = useTranslation();

  const freqList = useSelector((state) => state.patientEvents.freqList);

  // This store value 'extraSymptoms' randomly isn't getting initialized in the store for some reason.
  // Avoid what I did here, since it's what we use initalStates for
  const extraSymptoms = useSelector(
    (state) => state.patientEvents.extraSymptoms || {}
  );

  const dispatch = useDispatch();

  const shouldShowEvents = freqList && extraSymptoms;

  useEffect(() => {
    if (patientId) {
      setIsEventDataLoading(true);
      setIsFreqListLoading(true);
      dispatch(getEvents(patientId))
        .then(() => {
          setIsEventDataLoading(false);
          setHasAccessPermit(true);
        })
        .catch((error) => {
          if (
            error.error.response.data.msg &&
            error.error.response.data.msg === "cannot_access_patient"
          ) {
            setHasAccessPermit(false);
          }
        });

      dispatch(getFreqList(patientId)).then((res) => {
        setIsFreqListLoading(false);
      });
    }
  }, [patientId]);

  //* ************************************** PARTIALS **************************************

  const _renderEventItem = (eventKey, value) => {
    const eventList = t("home.events.event_list", { returnObjects: true });
    return (
      <div key={eventKey}>
        {
          <div className="row-space-between event-item">
            <span className="event-name">
              {eventList[eventKey]
                ? capitalizeFirstLetter(eventList[eventKey])
                : t("home.events.undefined_event")}
            </span>
            <div className="event-value">{value}</div>
          </div>
        }
        <hr />
      </div>
    );
  };

  const _renderExtraSymptomItem = (eventKey, value) => {
    return (
      <div key={eventKey}>
        {
          <div className="row-space-between event-item">
            <span className="event-name">
              {capitalizeFirstLetter(eventKey)}
            </span>
            <div className="event-value">{value}</div>
          </div>
        }
        <hr />
      </div>
    );
  };

  const _renderEventList = () => {
    const freqListArray = Object.entries(freqList).sort((a, b) => b[1] - a[1]);
    const extraSymptomsArray = Object.entries(extraSymptoms).sort(
      (a, b) => b[1] - a[1]
    );
    return (
      <div className="flex-d-column events-list">
        <div className="regular-text mb-2">{t("home.events.most_common")}</div>
        {freqListArray.map(([eventKey, value]) =>
          _renderEventItem(eventKey, value)
        )}
        {extraSymptomsArray.map(([eventKey, value]) =>
          _renderExtraSymptomItem(eventKey, value)
        )}
      </div>
    );
  };

  //* ************************************** HANDLERS ************************************

  //* ************************************** RENDER **************************************

  return (
    hasAccessPermit && (
      <div className="events-info data-card flex-d-column p-2 mb-2">
        <span className="small-title mb-4">
          {t("home.events.events_symptoms")}
        </span>
        {isEventDataLoading || isFreqListLoading ? (
          <div className="position-relative">
            <Spinner type={"small-blue"} />
          </div>
        ) : !shouldShowEvents ? (
          <div className="no-data-text">{t("home.no_data")}</div>
        ) : (
          <div className="flex-d-row">
            <EventsMatrix />
            {_renderEventList()}
          </div>
        )}
      </div>
    )
  );
};

export default EventSymptomsInfo;
