export const GET_LICENSE_TYPE = "GET_LICENSE_TYPE";
export const GET_LICENSE_TYPE_SUCCESS = "GET_LICENSE_TYPE_SUCCESS";

export const GET_PATIENTS_WITH_LICENSE = "GET_PATIENTS_WITH_LICENSE";
export const GET_PATIENTS_WITH_LICENSE_SUCCESS =
  "GET_PATIENTS_WITH_LICENSE_SUCCESS";

export const GET_ALL_CLINICIAN = "GET_ALL_CLINICIAN";
export const GET_ALL_CLINICIAN_SUCCESS = "GET_ALL_CLINICIAN_SUCCESS";

export const SET_LICENSE_WITH_EMAIL = "SET_LICENSE_WITH_EMAIL";
export const SET_LICENSE_WITH_EMAIL_SUCCESS = "SET_LICENSE_WITH_EMAIL_SUCCESS";

export const SET_LICENSE_WITH_FILE = "SET_LICENSE_WITH_FILE";
export const SET_LICENSE_WITH_FILE_SUCCESS = "SET_LICENSE_WITH_FILE_SUCCESS";

export const SET_LICENSE_WITH_PATIENT_ID = "SET_LICENSE_WITH_PATIENT_ID";
export const SET_LICENSE_WITH_PATIENT_ID_SUCCESS =
  "SET_LICENSE_WITH_PATIENT_ID_SUCCESS";

export const SET_MULTIPLE_LICENSES_RENEW = "SET_MULTIPLE_LICENSES_RENEW";
export const SET_MULTIPLE_LICENSES_RENEW_SUCCESS =
  "SET_MULTIPLE_LICENSES_RENEW_SUCCESS";

export const CANCEL_MKINETIKOS_LICENSES_INVITE =
  "CANCEL_MKINETIKOS_LICENSES_INVITE";
export const CANCEL_MKINETIKOS_LICENSES_INVITE_SUCCESS =
  "CANCEL_MKINETIKOS_LICENSES_INVITE_SUCCESS";

export const CANCEL_MKINETIKOS_LICENSES = "CANCEL_MKINETIKOS_LICENSES_INVITE";
export const CANCEL_MKINETIKOS_LICENSES_SUCCESS =
  "CANCEL_MKINETIKOS_LICENSES_SUCCESS";

export const SET_AUTO_RENEW = "SET_AUTO_RENEW";
export const SET_AUTO_RENEW_SUCCESS = "SET_AUTO_RENEW_SUCCESS";

export const SET_PROVIDERS_WITH_FILE = "SET_PROVIDERS_WITH_FILE";
export const SET_PROVIDERS_WITH_FILE_SUCCESS =
  "SET_PROVIDERS_WITH_FILE_SUCCESS";

export const CHECK_CONNECTIONS = "CHECK_CONNECTIONS";
export const CHECK_CONNECTIONS_WITH_EMAIL = "CHECK_CONNECTIONS_WITH_EMAIL";

export const CHECK_FEATURE_ACCESS = "CHECK_FEATURE_ACCESS";
export const CHECK_FEATURE_ACCESS_SUCCESS = "CHECK_FEATURE_ACCESS_SUCCESS";

export const getLicenseType = () => ({
  type: GET_LICENSE_TYPE,
  payload: {
    request: {
      method: "get",
      url: "/cdss/my_area/mkinetikos-licenses/available-types",
    },
  },
});

export const getPatientsWithLicense = (
  page = 1,
  order_by = undefined,
  q = undefined
) => ({
  type: GET_PATIENTS_WITH_LICENSE,
  payload: {
    request: {
      method: "get",
      url: "/cdss/my_area/mkinetikos-licenses/patients-list/",
      params: { page, order_by, name: q },
    },
  },
});

export const getClinicians = (institution_id, userName = "") => ({
  type: GET_ALL_CLINICIAN,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/all-users/?search=${userName}`,
    },
  },
});

export const importLicenseWithEmail = (institution_id, data) => ({
  type: SET_LICENSE_WITH_EMAIL,
  payload: {
    request: {
      method: "PUT",
      url: `/cdss/institutions/${institution_id}/manage/app-license/use/emails-list/`,
      data: data,
    },
  },
});

export const importLicenseWithFile = (institution_id, data) => ({
  type: SET_LICENSE_WITH_FILE,
  payload: {
    request: {
      method: "post",
      url: `/cdss/institutions/${institution_id}/manage/app-license/use/csv-file/`,
      data: data,
      headers: { "content-type": "multipart/form-data" },
    },
  },
});

export const mkinetikosLicenseUse = (institution_id, data) => ({
  type: SET_LICENSE_WITH_PATIENT_ID,
  payload: {
    request: {
      method: "put",
      url: `cdss/institutions/${institution_id}/manage/app-license/use/`,
      data: data,
    },
  },
});

export const licenceRenew = (institution_id, data) => ({
  type: SET_MULTIPLE_LICENSES_RENEW,
  payload: {
    request: {
      method: "post",
      url: `/cdss/institutions/${institution_id}/manage/app-license/renew/`,
      data: data,
    },
  },
});

export const cancelInvitation = (institution_id, license_ids) => ({
  type: CANCEL_MKINETIKOS_LICENSES_INVITE,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/institutions/${institution_id}/manage/app-license/cancel-invite/`,
      data: license_ids,
    },
  },
});

export const cancelLicense = (institution_id, license_ids) => ({
  type: CANCEL_MKINETIKOS_LICENSES,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/institutions/${institution_id}/manage/app-license/expire/`,
      data: license_ids,
    },
  },
});

export const putAutoRenew = (
  institution_id,
  license_ids,
  auto_renew = false
) => ({
  type: SET_AUTO_RENEW,
  payload: {
    request: {
      method: "put",
      url: `/cdss/institutions/${institution_id}/manage/app-license/auto-renew/`,
      data: { license_ids, auto_renew },
    },
  },
});

export const importProvidersWithFile = (institution_id, data) => ({
  type: SET_PROVIDERS_WITH_FILE,
  payload: {
    request: {
      method: "post",
      url: `/cdss/institutions/${institution_id}/provider_accounts/upload_csv/`,
      data: data,
      headers: { "content-type": "multipart/form-data" },
    },
  },
});

export const requestConnections = (patient_id, email) => ({
  type: CHECK_CONNECTIONS,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/${patient_id}/mkinetikos/connections/`,
      data: { email },
    },
  },
});

export const requestConnectionsWithEmail = (email) => ({
  type: CHECK_CONNECTIONS_WITH_EMAIL,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/mkinetikos/connections/`,
      data: { email },
    },
  },
});

export const connectToInstitutionWithEmailList = (
  email_list,
  clinician_username
) => ({
  type: CHECK_CONNECTIONS_WITH_EMAIL,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/mkinetikos/connections/`,
      data: { email_list, clinician_username, auto_connect: true },
    },
  },
});

export const checkFeatureAccess = (institution_id) => ({
  type: CHECK_FEATURE_ACCESS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/provider_accounts/has_feature_access/`,
    },
  },
});
