//* *********************************** REACT IMPORTS ************************************
import React, { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Password from "./partials/password";
import ChangePassword from "./partials/change_password";
import MultiFactor from "./partials/multifactor";
import ChangeMethod from "./partials/change_method";
import BackupTokens from "./partials/backup_tokens";
import NavBar from "../../../components/navbar";
//* ************************************** GLOBALS ***************************************

const Security = (props) => {
  //* ************************************** SETUP **************************************
  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************
  const [isScreenChangePassword, setIsScreenChangePassword] = useState(false);
  const [isScreenChangeMethod, setIsScreenChangeMethod] = useState(false);
  const [isScreenBackupTokens, setIsScreenBackupTokens] = useState(false);
  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.security")} />
      <div className="profile-mandatory-fields-wrapper">
        <span className="regular-text profile-mandatory-fields-wrapper">
          {t("forms.all_fields")}{" "}
          <span className="warning-color">{t("star")}</span>{" "}
          {t("forms.are_mandatory")}
        </span>
        <div className="profile-security row">
          <div className="col-lg-4 mb-4">
            {isScreenChangePassword ? (
              <ChangePassword
                cancelChangePassword={() => setIsScreenChangePassword(false)}
              />
            ) : (
              <Password
                goToChangePassword={() => setIsScreenChangePassword(true)}
              />
            )}
          </div>
          <div className="col-lg-4 mb-4">
            {isScreenChangeMethod ? (
              <ChangeMethod
                cancelChangeMethod={() => setIsScreenChangeMethod(false)}
              />
            ) : isScreenBackupTokens ? (
              <BackupTokens
                cancelBackupTokens={() => setIsScreenBackupTokens(false)}
              />
            ) : (
              <MultiFactor
                goToChangeMethod={() => setIsScreenChangeMethod(true)}
                goToBackupTokens={() => setIsScreenBackupTokens(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Security);
