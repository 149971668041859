import { useContext } from "react";
import { ToastContext } from "../components/toast/context/toastContext";

/**
 * Hook for remove the toast with toast ID;
 * ex:
 *  removeToast = useToastRemove()
 *  removeToast(123456...)
 * @returns function closeToast
 */
const useToastRemove = () => {
  const { _, closeToast } = useContext(ToastContext);
  return closeToast;
};

export default useToastRemove;
