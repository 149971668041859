import i18next from "i18next";
import { registerLocale } from "react-datepicker";
import { enGB, pt, es } from "date-fns/locale";

const languageCodes = {
  "pt-PT": 1,
  "en-GB": 2,
  "es-ES": 3,
};

export const geti18nLanguage = () => i18next.language;

export const getLanguageID = (lang) => {
  if (lang in languageCodes) {
    return languageCodes[lang];
  }
  return languageCodes["en-GB"];
};

export const getLanguageString = (id) =>
  Object.keys(languageCodes).find((lang) => languageCodes[lang] === id);

export const simplifyLanguageID = (lang) => lang.split("-")[0];

export const changeLanguage = (id) => {
  i18next.changeLanguage(getLanguageString(parseInt(id)));
};

export const localeForDatePicker = (lang) => {
  let language = "language";

  if (lang === "pt-PT") registerLocale(language, pt);
  else if (lang === "es-ES") registerLocale(language, es);
  else registerLocale(language, enGB);

  return language;
};
