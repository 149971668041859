import {
  GET_LICENSE_TYPE_SUCCESS,
  GET_PATIENTS_WITH_LICENSE_SUCCESS,
  GET_ALL_CLINICIAN_SUCCESS,
  CANCEL_MKINETIKOS_LICENSES_INVITE_SUCCESS,
} from "../actions/mKinetikos";

const initialState = {
  patientWithLicense: [],
  licenseType: [],
  clinicians: [],
};

export const licenseHandler = (state = initialState, action) => {
  switch (action.type) {
    case GET_LICENSE_TYPE_SUCCESS: {
      return { ...state, licenseType: action.payload.data.data };
    }
    case GET_PATIENTS_WITH_LICENSE_SUCCESS: {
      return { ...state, patientWithLicense: action.payload.data.data };
    }
    case GET_ALL_CLINICIAN_SUCCESS: {
      return { ...state, clinicians: action.payload.data.data };
    }
    // case CANCEL_MKINETIKOS_LICENSES_INVITE_SUCCESS: {
    //   return { ...state };
    // }
    default:
      return state;
  }
};

export default licenseHandler;
