export const GET_RISK_SUMMARY = "GET_RISK_SUMMARY";
export const GET_RISK_SUMMARY_SUCCESS = "GET_RISK_SUMMARY_SUCCESS";
export const GET_RISK_SUMMARY_FAIL = "GET_RISK_SUMMARY_FAIL";

export const getRiskSummary = (patient_id) => ({
  type: GET_RISK_SUMMARY,
  payload: {
    request: {
      method: "get",
      url: `/cdss/clinical_risk_alert/patients/${patient_id}/home_summary`,
    },
  },
});
