import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { create_card } from "../../../utils/create_card";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";
const MetricsChart = ({ showInfo, details }) => {
  const reff = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  // const data = [
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 0.62,
  //             std: null,
  //             color: "#ff6961",
  //             title: null,
  //             key_split: "speed",
  //             unit: "m/s",
  //             name: "Gait speed",
  //             general_name: "Gait speed",
  //           },
  //         ],
  //         unit: "m/s",
  //         metric_name: "Gait speed",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [0.85, 1.49],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "center",
  //             value: 0.62,
  //             title: null,
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [0.56, 0.85, 1.49, 1.64],
  //     unit: "m/s",
  //   },
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 117.58,
  //             std: null,
  //             color: "#ff6961",
  //             title: null,
  //             key_split: "cadence",
  //             unit: "steps/min ",
  //             name: "Gait cadence",
  //             general_name: "Gait cadence",
  //           },
  //         ],
  //         unit: "steps/min ",
  //         metric_name: "Gait cadence",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [94.68, 124.21],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "center",
  //             value: 117.58,
  //             title: null,
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [85.21, 94.68, 124.21, 136.63],
  //     unit: "steps/min ",
  //   },
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 1.02,
  //             std: null,
  //             color: "#ff6961",
  //             title: null,
  //             key_split: "duration",
  //             unit: "s",
  //             name: "Stride duration",
  //             general_name: "Stride duration",
  //           },
  //         ],
  //         unit: "s",
  //         metric_name: "Stride duration",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [0.96, 1.26],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "center",
  //             value: 1.02,
  //             title: null,
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [0.86, 0.96, 1.26, 1.39],
  //     unit: "s",
  //   },
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 31.98,
  //             std: null,
  //             color: "#ff6961",
  //             title: "R",
  //             key_split: "r",
  //             unit: "%",
  //             name: "Right swing duration",
  //             general_name: "Swing Fraction",
  //           },
  //           {
  //             value: 30.01,
  //             std: null,
  //             color: "#ff6961",
  //             title: "L",
  //             key_split: "l",
  //             unit: "%",
  //             name: "Left swing duration",
  //             general_name: "Swing Fraction",
  //           },
  //         ],
  //         unit: "%",
  //         metric_name: "Swing Fraction",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [28.9, 37.69],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "end",
  //             value: 31.98,
  //             title: "R",
  //           },
  //           {
  //             position: "start",
  //             value: 30.01,
  //             title: "L",
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [26.01, 28.9, 37.69, 41.46],
  //     unit: "%",
  //   },
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 18.29,
  //             std: null,
  //             color: "#ff6961",
  //             title: "R",
  //             key_split: "r",
  //             unit: "%",
  //             name: "Double Support Right",
  //             general_name: "Double Support Time",
  //           },
  //           {
  //             value: 19.58,
  //             std: null,
  //             color: "#ff6961",
  //             title: "L",
  //             key_split: "l",
  //             unit: "%",
  //             name: "Double Support Left",
  //             general_name: "Double Support Time",
  //           },
  //         ],
  //         unit: "%",
  //         metric_name: "Double Support Time",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [12.15, 20.32],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "end",
  //             value: 18.29,
  //             title: "R",
  //           },
  //           {
  //             position: "start",
  //             value: 19.58,
  //             title: "L",
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [10.94, 12.15, 20.32, 22.35],
  //     unit: "%",
  //   },
  //   {
  //     values: [
  //       {
  //         values_list: [
  //           {
  //             value: 32.14,
  //             std: null,
  //             color: "#ff6961",
  //             title: "R",
  //             key_split: "r",
  //             unit: "cm",
  //             name: "Right step length",
  //             general_name: "Step length ",
  //           },
  //           {
  //             value: 31.23,
  //             std: null,
  //             color: "#ff6961",
  //             title: "L",
  //             key_split: "l",
  //             unit: "cm",
  //             name: "Left step length",
  //             general_name: "Step length ",
  //           },
  //         ],
  //         unit: "cm",
  //         metric_name: "Step length ",
  //         norm_list: [
  //           {
  //             label: null,
  //             color: "#aeffc8",
  //             interval: [53, 85],
  //             title: null,
  //           },
  //         ],
  //         card_id: null,
  //         table_list: [
  //           {
  //             position: "end",
  //             value: 32.14,
  //             title: "R",
  //           },
  //           {
  //             position: "start",
  //             value: 31.23,
  //             title: "L",
  //           },
  //         ],
  //       },
  //     ],
  //     category_name: "",
  //     limits: [28.11, 53, 85, 93.5],
  //     unit: "cm",
  //   },
  // ];
  // const details = {
  //   id: 33,
  //   type: 20,
  //   title: "Spatio-temporal Metrics",
  //   description: "Spatio-temporal Metrics",
  //   icon: '<i class="fa fa-th" aria-hidden="true"></i>',
  //   color: "#BDDA57",
  //   span_row: 6,
  //   span_col: 10,
  // };

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_metrics_bar_chart(card, data, color) {
      if (color === undefined || color === "" || color === null)
        color = "var(--kblue-color)";

      let card_body = card.querySelector(".card-body");

      data.forEach((metric, plot_number) => {
        create_category_plot(card_body, metric, plot_number);
      });

      function create_category_plot(container, metric, plot_number) {
        let title_separator = document.createElement("div"),
          title_span = document.createElement("p"),
          wrapper_div = document.createElement("div");
        title_separator.classList = "line";
        title_span.innerText = metric.category_name;
        title_span.classList = "title azureGrad";
        wrapper_div.classList = "svg-div noselect";
        wrapper_div.id = `metrics_plot_${plot_number}`;
        title_separator.appendChild(title_span);
        container.appendChild(title_separator);
        container.appendChild(wrapper_div);

        create_category_svg(wrapper_div, metric);
      }

      function create_category_svg(wrapper_div, metrics) {
        //limits, labels, unit, values){
        wrapper_div.style.setProperty(
          "height",
          `calc(${metrics.values.length} * 2em + 2em)`
        );
        let rect = wrapper_div.getBoundingClientRect(),
          wrapper = d3
            .select(`#${card.id} #${wrapper_div.id}.svg-div`)
            .append("svg")
            //.attr("width", "100%")
            .attr("width", "100%")
            .attr("height", `calc(${metrics.values.length} * 2em + 2em)`)
            .attr("preserveAspectRatio", "xMidYMin meet"),
          wrapper_height = Number(wrapper.style("height").replace("px", "")),
          // margin = { top: 15, right: 100, bottom: 0, left: 5 },
          margin = { top: 15, right: 100, bottom: 0, left: 5 },
          inner_width = rect.width - margin.left - margin.right,
          inner_height = wrapper_height - margin.top - margin.bottom,
          x_scale = d3
            .scaleLinear()
            .range([inner_width / 5, inner_width * 0.92]),
          y_scale = d3.scaleBand().range([0, inner_height]).padding(0.3);
        wrapper.attr("viewBox", `0 0 ${rect.width} ${wrapper_height}`);
        // Scale the range of the data in the domains
        x_scale.domain([
          metrics.limits[0],
          metrics.limits[metrics.limits.length - 1],
        ]);
        y_scale.domain(
          metrics.values.map((d) => {
            return d.metric_name;
          })
        );

        call_create_svg_elements(
          wrapper,
          margin,
          x_scale,
          y_scale,
          inner_width,
          inner_height,
          metrics
        ); //, limits, labels, unit, values);
      }

      function append_text(element, class_name, anchor, x, y, text, transform) {
        return element
          .append("text")
          .attr("class", class_name)
          .attr("text-anchor", anchor)
          .attr("x", x)
          .attr("y", y)
          .text(text)
          .attr("transform", transform);
      }

      function append_circle(element, class_name, fill, cx, cy, r) {
        return element
          .append("circle")
          .attr("class", class_name)
          .attr("fill", fill)
          .attr("cx", cx)
          .attr("cy", cy)
          .attr("r", r);
      }

      function append_normal_rect(
        element,
        fill,
        opacity,
        rx,
        x,
        y,
        width,
        height
      ) {
        return element
          .append("rect")
          .attr("fill", fill)
          .attr("opacity", opacity)
          .attr("rx", rx)
          .attr("x", x)
          .attr("y", y)
          .attr("width", width)
          .attr("height", height);
      }

      function append_path(element, class_name, d, transform) {
        element
          .append("path")
          .attr("class", class_name)
          .attr("transform", transform)
          .attr("d", d);
      }

      function call_create_svg_elements(
        wrapper,
        margin,
        x_scale,
        y_scale,
        inner_width,
        inner_height,
        metrics
      ) {
        //, limits, labels, unit, values);
        let limits = metrics.limits,
          labels = metrics.labels,
          unit = metrics.unit,
          values = metrics.values;

        let top_limit_index = limits.length - 1,
          label_y_deviation = y_scale.bandwidth() * 0.55;

        // Add vertical Lines
        limits.forEach((limit) => {
          append_path(
            wrapper,
            "vertical-line",
            () => {
              return `M ${x_scale(limit)}, ${inner_height} ${x_scale(
                limit
              )}, 0`;
            },
            `translate(${margin.left}, ${margin.top})`
          );
          let label = append_text(
            wrapper,
            "axis-text",
            "middle",
            () => {
              return x_scale(limit);
            },
            -5,
            limit,
            `translate(${margin.left}, ${margin.top})`
          );
          label
            .append("tspan")
            .text(unit)
            .attr("font-size", "0.6em")
            .attr("dx", ".1em");
        });

        let g = wrapper
            .append("g")
            .attr(
              "transform",
              "translate(" + margin.left + "," + margin.top + ")"
            ),
          data_entry_element = g
            .selectAll(".background-bar")
            .data(values)
            .enter();

        data_entry_element.each((d, i, e) => {
          let element = d3.select(e[i]),
            round = 2;
          let norm_list = d.norm_list;
          let values_list = d.values_list;
          let table_list = d.table_list;
          //Horizontal Lines
          append_path(
            element,
            "horizontal-line",
            (d) => {
              return `M ${x_scale(limits[0])}, ${
                y_scale(d.metric_name) + y_scale.bandwidth() / 2
              } ${x_scale(limits[top_limit_index])}, ${
                y_scale(d.metric_name) + y_scale.bandwidth() / 2
              }`;
            },
            null
          );
          // Normal bar
          if (norm_list) {
            norm_list.forEach((norm) => {
              let norm_color = norm.color,
                lower_v = norm.interval[0],
                highest_v = norm.interval[1];
              append_normal_rect(
                element,
                norm_color,
                0.5,
                15,
                (d) => {
                  return x_scale(lower_v);
                },
                (d) => {
                  return y_scale(d.metric_name);
                },
                (d) => {
                  return x_scale(highest_v) - x_scale(lower_v);
                },
                y_scale.bandwidth()
              )
                .append("svg:title")
                .text(function (d) {
                  return norm.title;
                });
            });
          }
          // Category labels
          append_text(
            element,
            "bar-text smallText kinetikosBlue",
            "end",
            x_scale(limits[0]) - 30,
            (d) => {
              return y_scale(d.metric_name) + label_y_deviation;
            },
            (d) => {
              return d.metric_name;
            }
          )
            .on(
              "click",
              () => {
                if (d.card_id) {
                  showInfo(details.type, data.url);
                }
              },
              false
            )
            .on(
              "touch",
              () => {
                if (d.card_id) {
                  showInfo(details.type, data.url);
                }
              },
              false
            )
            .on("mouseover", () => {
              if (d.card_id) {
                document.body.style.cursor = "pointer";
              }
            })
            .on("mouseout", () => {
              if (d.card_id) {
                document.body.style.cursor = "default";
              }
            });

          if (values_list) {
            values_list.forEach((value_dict) => {
              let value_dict_value = Array.isArray(value_dict.value)
                ? value_dict.value
                : [value_dict.value];
              for (let d_value in value_dict_value) {
                d_value = parseFloat(value_dict_value[d_value]);
                append_circle(
                  element,
                  "left-circle",
                  value_dict.color,
                  (d) => {
                    return x_scale(d_value);
                  },
                  (d) => {
                    return y_scale(d.metric_name) + y_scale.bandwidth() / 2;
                  },
                  y_scale.bandwidth() / 2.5
                )
                  .append("svg:title")
                  .text(function (d) {
                    let unit = value_dict.unit ? value_dict.unit : "";
                    return `${d_value.toFixed(round)} ${unit}`;
                  });
                append_text(
                  element,
                  "bar-text smallText white",
                  "start",
                  (d) => {
                    return x_scale(d_value);
                  },
                  (d) => {
                    return y_scale(d.metric_name) + y_scale.bandwidth() / 2;
                  },
                  value_dict.title
                )
                  .attr("dx", "-0.3em")
                  .attr("dy", "0.4em")
                  .attr("font-size", "0.7em")
                  .attr("font-weight", "bold")
                  .attr("fill", "white")
                  .append("svg:title")
                  .text(function (d) {
                    let unit = value_dict.unit ? value_dict.unit : "";
                    return `${d_value.toFixed(round)} ${unit}`;
                  });
              }
            });

            if (table_list) {
              table_list.forEach((column) => {
                let text_offset =
                  column.position == "start"
                    ? 30
                    : column.position == "end"
                    ? 170
                    : 90;
                append_text(
                  element,
                  "axis-text",
                  column.position,
                  x_scale(limits[top_limit_index]) + text_offset,
                  (d) => {
                    return y_scale(d.metric_name) + label_y_deviation;
                  },
                  (d) => {
                    return column.value.toFixed(round);
                  }
                );
                append_text(
                  element,
                  "axis-text",
                  column.position,
                  x_scale(limits[top_limit_index]) + text_offset,
                  y_scale(-5),
                  column.title
                );
              });
            }
          }
        });
      }
    }
    if (data === null) {
      create_card(
        reff.current,
        29,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    } else {
      build_metrics_bar_chart(reff.current, data, details.color);
    }
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default MetricsChart;
