import {
  GET_MEDICINE_SUMMARY_SUCCESS,
  GET_MEDICINE_SUMMARY_FAIL,
} from "../actions/medicineSummary";

const initialState = {
  data: [],
  error: false,
};

const MedicineSummary = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICINE_SUMMARY_SUCCESS: {
      return { ...state, data: action.payload.data.msg, error: false };
    }
    case GET_MEDICINE_SUMMARY_FAIL: {
      return { ...state, error: true };
    }

    default:
      return state;
  }
};

export default MedicineSummary;
