export const CREATE_SEARCH_FILTER = "CREATE_SEARCH_FILTER";
export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const CLEAR_SEARCH_FILTER = "CLEAR_SEARCH_FILTER";

export const createSearchFilter = (filterName) => ({
  type: CREATE_SEARCH_FILTER,
  payload: { filterName },
});

export const setSearchFilter = ({
  filterName,
  q,
  page,
  itemsPerPage,
  filterType = "all",
  orderBy = null,
}) => ({
  type: UPDATE_SEARCH_FILTER,
  payload: {
    filterName: filterName,
    filterParams: { q, page, filterType, orderBy, itemsPerPage },
  },
});

export const clearSearchFilter = (filterName) => ({
  type: CLEAR_SEARCH_FILTER,
  payload: { filterName },
});
