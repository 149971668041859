import React, { useState, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { dateToString } from "../../../../utils/dataAndTime.js";
import ReactTooltip from "react-tooltip";
import helpIcon from "../../../../assets/images/help.svg";
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import { errors } from "../../../../constants/general.js";
import { createMedicine } from "../../../../redux/actions/patientMedicines.js";
import calendar from "../../../../assets/images/calendar-small.svg";
import plusBlue from "../../../../assets/images/plus-blue.svg";
import timerIcon from "../../../../assets/images/timer.svg";

import TimePicker from "rc-time-picker";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { localeForDatePicker } from "utils/language.js";
import MaxDailyIntake from "pages/patient/mkinetikos_medicines/partials/maxDailyIntake.js";

const AddMedicinesModal = ({
  patientId,
  close,
  closeAndRefresh,
  prescription,
  hasAddedMedication,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [medicineDetails, setMedicineDetails] = useState({
    text: "",
    notes: "",
    end_date: null,
    is_sos: false,
    days: [],
    times: [""],
    max_daily_intake: null,
  });
  const [mediceDetailsErrors, setMedicineDetailsErrors] = useState({});
  const datePickerInput = useRef(null);

  const { t, i18n } = useTranslation();

  const saveMedicine = (e) => {
    e.preventDefault();
    const newMedicineDetails = {
      ...medicineDetails,
      times: medicineDetails.times.filter((time) => time !== "" && time),
    };
    setIsLoading(true);

    dispatch(createMedicine(patientId, newMedicineDetails, prescription))
      .then(() => {
        closeAndRefresh();
        hasAddedMedication(true);
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response.data;
          console.log(data);

          if (data.form_errors) {
            setMedicineDetailsErrors(data.form_errors);
          }
        } else {
          alert(errors.service_error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const changeSelectedTime = (time, index) => {
    let newTimes = [...medicineDetails.times];
    if (time === null) {
      newTimes[index] = "";
      setMedicineDetails({ ...medicineDetails, times: newTimes });
    } else {
      newTimes[index] = moment(time).format("HH:mm");
      setMedicineDetails({ ...medicineDetails, times: newTimes });
    }
  };

  const inputsHandlerMedicine = (e) => {
    delete mediceDetailsErrors[e.target.name];
    setMedicineDetails({ ...medicineDetails, [e.target.name]: e.target.value });
  };

  const changeSelectDays = (day) => {
    let newSelectedList = [...medicineDetails.days];
    if (medicineDetails.days.includes(day)) {
      newSelectedList = medicineDetails.days.filter((item) => item !== day);
    } else {
      newSelectedList.push(day);
    }
    setMedicineDetails({ ...medicineDetails, days: newSelectedList });
  };

  const changeDate = (newDate) => {
    setMedicineDetails({
      ...medicineDetails,
      end_date: newDate ? dateToString(newDate) : null,
    });
  };

  const removeTime = (index) => {
    let timesCopy = [...medicineDetails.times];
    timesCopy.splice(index, 1);
    setMedicineDetails({ ...medicineDetails, times: timesCopy });
  };

  const maxIntakeCounter = (value) => {
    if (value > 0) {
      setMedicineDetails({ ...medicineDetails, max_daily_intake: value });
    } else {
      setMedicineDetails({ ...medicineDetails, max_daily_intake: null });
    }
  };

  const resetMaxIntake = () => {
    setMedicineDetails({ ...medicineDetails, max_daily_intake: null });
  };

  const areMandatoryFieldsFilled =
    medicineDetails.text !== "" &&
    ((medicineDetails.days.length > 0 && medicineDetails.times[0] !== "") ||
      medicineDetails.is_sos);

  return (
    <div className="background-modal-container">
      <div className="message-modal-container">
        <div className="add-medicine-modal">
          <div className="row align-items-center">
            <div className="col mt-1">
              <h4>{t("titles.add_med")}</h4>
            </div>
            <div className="col-auto justify-content-end mt-1">
              <button
                disabled={isLoading}
                onClick={close}
                type="button"
                className="transparent-button small-wider-button mr-1"
              >
                <u>{t("buttons.cancel")}</u>
              </button>
              <button
                disabled={isLoading || !areMandatoryFieldsFilled}
                type="button"
                className="secondary-button small-wider-button"
                onClick={saveMedicine}
              >
                {t("buttons.save")}
              </button>
            </div>
            <form>
              <div className="position-relative">
                <div className="mt-4 flex-d-column">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>
                      {t("patient.meds.med_name")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    {mediceDetailsErrors.text && (
                      <span className="warning-color">
                        {mediceDetailsErrors.text}
                      </span>
                    )}
                  </label>

                  <input
                    disabled={isLoading}
                    onChange={inputsHandlerMedicine}
                    value={medicineDetails.text}
                    placeholder="Medicine name"
                    autoComplete="off"
                    className={`form-input with-input-sizing ${
                      mediceDetailsErrors.text ? "error" : ""
                    }`}
                    name="text"
                    id="text"
                  />

                  <div className="col">
                    <label
                      className="d-flex justify-content-between"
                      htmlFor="prescription_id"
                    >
                      <span className="input-label-text">
                        {t("patient.meds.prescription")}{" "}
                      </span>
                    </label>
                    <div className="regular-text">{prescription.name}</div>
                  </div>
                  <div className="check-box-wrapper mt-2">
                    <div className="input-wrapper col-md-auto">
                      <input
                        disabled={isLoading}
                        checked={medicineDetails.is_sos}
                        onChange={(e) =>
                          setMedicineDetails({
                            ...medicineDetails,
                            is_sos: e.target.checked,
                            days: e.target.checked ? [] : medicineDetails.days,
                            times: e.target.checked
                              ? [""]
                              : medicineDetails.times,
                          })
                        }
                        name="save_for_institution"
                        type={"checkbox"}
                        id="isSosCheckbox"
                      />
                    </div>
                    <label type="checkbox" htmlFor="isSosCheckbox">
                      <span className="regular-text">
                        {t("patient.meds.set_as_sos")}
                      </span>
                      <>
                        <img
                          alt="Help"
                          data-tip={t("patient.meds.set_as_sos_tip")}
                          data-for={`save-exercise`}
                          src={helpIcon}
                          className="help-img"
                        />
                        <ReactTooltip
                          className="tooltip"
                          id={`save-exercise`}
                          place="top"
                          effect="solid"
                        />
                      </>
                    </label>
                  </div>
                  {
                    <MaxDailyIntake
                      medicineDetails={medicineDetails}
                      maxIntakeCounter={maxIntakeCounter}
                      resetMaxIntake={resetMaxIntake}
                    />
                  }
                  <div
                    className={`${
                      medicineDetails.is_sos ? "disabled-container" : ""
                    }`}
                  >
                    <div className="mt-4 flex-d-column">
                      <label className="input-label-text">
                        <span>
                          {t("patient.meds.select_frequency")}{" "}
                          <span className="warning-color">{t("star")}</span>
                        </span>
                      </label>
                      <SelectWeekDays
                        disabled={isLoading || medicineDetails.is_sos}
                        activeDays={medicineDetails.days}
                        changeSelectDays={changeSelectDays}
                      />
                    </div>
                    <label className="input-label-text mt-4">
                      {t("patient.meds.select_time") + " "}
                      <span className="warning-color">{t("star")}</span>
                    </label>
                    {medicineDetails.times.map((time, index) => (
                      <div className="flex-d-row align-items-center">
                        <div className="input-image-wrapper image-right exercise-details-input position-relative">
                          <TimePicker
                            disabled={isLoading || medicineDetails.is_sos}
                            onChange={(e) => changeSelectedTime(e, index)}
                            showSecond={false}
                            value={time !== "" ? moment(time, "HH:mm") : time}
                            clearIcon={<img src={timerIcon} />}
                          />
                        </div>
                        {index === 0 ? (
                          <button
                            type="button"
                            disabled={isLoading || medicineDetails.is_sos}
                            onClick={() =>
                              setMedicineDetails({
                                ...medicineDetails,
                                times: [...medicineDetails.times, ""],
                              })
                            }
                            className="ml-1 tertiary-button small-button mb-3"
                          >
                            <img
                              disabled={isLoading}
                              alt="Plus Blue"
                              className="mr-1"
                              src={plusBlue}
                            />
                            {t("buttons.add_another_time")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={isLoading || medicineDetails.is_sos}
                            onClick={() => removeTime(index)}
                            className="warning-button small-button mb-3 ml-1"
                          >
                            {t("buttons.remove")}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>

                  <label className="input-label-text mt-1">
                    {t("patient.meds.end_date")}
                  </label>
                  <div className="input-image-wrapper image-right exercise-details-input">
                    <DatePicker
                      disabled={isLoading}
                      ref={datePickerInput}
                      autoComplete="off"
                      className={`form-input  ${
                        mediceDetailsErrors.end_date ? "error" : ""
                      }`}
                      name="date"
                      id="date"
                      placeholderText={t("placeholders.date")}
                      locale={localeForDatePicker(i18n.language)}
                      minDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      selected={
                        medicineDetails.end_date
                          ? new Date(medicineDetails.end_date)
                          : null
                      }
                      onChange={(date) => changeDate(date)}
                    />
                    <img
                      disabled={isLoading}
                      onClick={() => datePickerInput.current.setOpen(true)}
                      alt="calendar"
                      className="input-right-image"
                      src={calendar}
                    />
                  </div>
                </div>

                <label className="input-label-text  d-flex justify-content-between mt-1">
                  <span>{t("patient.meds.observations")}</span>
                  {mediceDetailsErrors.notes && (
                    <span className="warning-color">
                      {mediceDetailsErrors.notes}
                    </span>
                  )}
                </label>
                <textarea
                  disabled={isLoading}
                  as="textarea"
                  rows="5"
                  autoComplete="off"
                  onChange={inputsHandlerMedicine}
                  value={medicineDetails.notes}
                  className={`form-input  ${
                    mediceDetailsErrors.notes ? "error" : ""
                  }`}
                  name="notes"
                  placeholder={t("placeholders.write_observation")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(AddMedicinesModal);
