//Module Types
export const moduleTypes = {
  clinicalData: "clinical_data",
  intervention: "intervention",
  questionnarie: "questionnaire",
  kinematicTest: "kinematic_tests",
  all: "all",
};

export const getPackagePlanName = {
  dashboard: "Dashboard",
  clinik: "Clinic",
  clinikPro: "Clinic Pro",
  premium: "Premium",
};

export const errors = {
  service_error:
    "Something went wrong...We're having some difficulties processing your request. Please try again later or return to the previous page.",
};

export const success = {
  update_success: "Your changes were successfully updated",
};

//file limits in bytes
export const fileLimits = {
  video: 52428800,
  image: 52428800,
};

export const fileAllowedExtensions = {
  video: [
    "video/m4v",
    "video/avi",
    "video/mpg",
    "video/mp4",
    "video/quicktime",
  ],
  image: ["image/png", "image/jpeg", "image/jpg"],
};
