import {
  GET_PATIENT_HOME_FAIL,
  GET_PATIENT_HOME_SUCCESS,
  PUT_PATIENT_CONSENT_CHECK_SUCCESS,
} from "../actions/patients";

const initialState = {
  has_mkinetikos: false,
  mkinetikos_photo: null,
  mkinetikos_username: "",
  name: "",
  is_followed: true,
  consent_check: false,
};

const PatientHome = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_HOME_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_HOME_FAIL: {
      return initialState;
    }
    case PUT_PATIENT_CONSENT_CHECK_SUCCESS: {
      return { ...state, consent_check: true };
    }
    default:
      return state;
  }
};

export default PatientHome;
