import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrentInstitution } from "../../../../../redux/actions/institutions";
import { useTranslation } from "react-i18next";

import checkmark from "../../../../../assets/images/plan-checkmark.svg";
import helpIcon from "../../../../../assets/images/help.svg";

import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const ManagePlans = () => {
  const dispatch = useDispatch();
  const currInstitution = useSelector(
    (state) => state.institutions.current_institution
  );

  useEffect(() => {
    dispatch(getCurrentInstitution())
      .then(() => {})
      .catch(() => {});
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div className="institution-form-body-wrapper">
      <div className="row">
        <div className="col-md-6 col-xl-3 mt-2">
          <div className="package-plan">
            <div className="title">{t("plans.dashboard").toUpperCase()}</div>
            {/*          <div className="contact-for-pricing">
              {t("plans.contact_for_pricing")}
            </div> */}
            <div className="body">
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.visualize_data")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.perscribe_medication")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.assess_patient_outcomes")}
                </div>
              </div>
            </div>
            {currInstitution.package_plan_id === 1 ? (
              <button
                disabled={true}
                className="secondary-button big-button w-100 mt-2"
              >
                {t("plans.current")}
              </button>
            ) : (
              <Link
                to={{ pathname: "mailto:support@kinetikoshealth.com" }}
                target={"_blank"}
              >
                <button className="contact-button big-button w-100 mt-2">
                  {t("plans.choose")}
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mt-2">
          <div className="package-plan">
            <div className="title">{t("plans.clinic").toUpperCase()}</div>
            <div className="body">
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.visualize_data")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.perscribe_medication")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.assess_patient_outcomes")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.2d_analysis")}
                </div>
              </div>
            </div>
            {currInstitution.package_plan_id === 2 ? (
              <button
                disabled={true}
                className="secondary-button big-button w-100 mt-2"
              >
                {t("plans.current")}
              </button>
            ) : (
              <Link
                to={{ pathname: "mailto:support@kinetikoshealth.com" }}
                target={"_blank"}
              >
                <button className="contact-button big-button w-100 mt-2">
                  {t("plans.choose")}
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mt-2">
          <div className="package-plan">
            <div className="title">{t("plans.clinic_pro").toUpperCase()}</div>
            <div className="body">
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.visualize_data")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.perscribe_medication")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.assess_patient_outcomes")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.2d_analysis")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.3d_analysis")}
                </div>
              </div>
            </div>
            {currInstitution.package_plan_id === 3 ? (
              <button
                disabled={true}
                className="secondary-button big-button w-100 mt-2"
              >
                {t("plans.current")}
              </button>
            ) : (
              <Link
                to={{ pathname: "mailto:support@kinetikoshealth.com" }}
                target={"_blank"}
              >
                <button className="contact-button big-button w-100 mt-2">
                  {t("plans.choose")}
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mt-2">
          <div className="package-plan">
            <div className="title">{t("plans.premium").toUpperCase()}</div>

            <div className="body">
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.visualize_data")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.perscribe_medication")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.assess_patient_outcomes")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.2d_3d_analysis")}
                </div>
              </div>
              <div className="plan-feature mt-3">
                <img alt="" src={checkmark} />
                <div className="regular-text ml-1">
                  {t("plans.create_evaluation_specific")}
                </div>
              </div>
            </div>
            {currInstitution.package_plan_id === 4 ? (
              <button
                disabled={true}
                className="secondary-button big-button w-100 mt-2"
              >
                {t("plans.current")}
              </button>
            ) : (
              <Link
                to={{ pathname: "mailto:support@kinetikoshealth.com" }}
                target={"_blank"}
              >
                <button className="contact-button big-button w-100 mt-2">
                  {t("plans.choose")}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePlans;
