import {
  GET_EVALUATION_TAGS_FAIL,
  GET_EVALUATION_TAGS_SUCCESS,
} from "../actions/evaluations";

const initialState = [];

const patientEvaluations = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVALUATION_TAGS_SUCCESS: {
      return [...action.payload.data.data];
    }
    case GET_EVALUATION_TAGS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientEvaluations;
