/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, memo, useEffect, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import DropdownOptions from "../dropdownOptions";
import Paginator from "../paginator";
import Spinner from "../spinner";
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************
import {
  getPatientEvaluations,
  createPatientEvaluation,
  deletePatientEvaluation,
  setConsentCheck,
  getPatientConsentPdf,
  getPatientHome,
} from "../../redux/actions/patients";
import { getEvaluationTags } from "../../redux/actions/evaluations";

//* ************************************** ASSETS ****************************************
import evaluationsIcon from "../../assets/images/evaluationsModuleIcons/evaluations_white.svg";
import chevronUpIcon from "../../assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronUpSelectedIcon from "../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownIcon from "../../assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import search from "../../assets/images/search.svg";
import moreBlue from "../../assets/images/more-blue.svg";
import moreBlueSelected from "../../assets/images/more-blue-selected-grey.svg";
import { parseDateFromDatetime } from "../../utils/dataAndTime";
import MessageModal from "../messageModal";
import CreateEvaluationModal from "./partials/createEvaluationModal";
import arrowRightIcon from "../../assets/images/arrow-right.svg";

const EvaluationsModule = ({
  history,
  patientId = null,
  headerText = "",
  itemsPerPage,
  isNewEvaluationsBtn = true,
  isSearchBar = true,
  isPaginator = true,
  colsToShow = ["name", "tags", "date"],
  nameTextLink = null,
  rightMenueButtons = [],
  isFullRowClickable = false,
  extraStyleClass = "",
  spinnerType = "big-blue",
}) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");

  const dropDownDivRef = useRef(null);
  const optionsRef = useRef([]);

  const [isEvaluationListLoading, setIsEvaluationListLoading] = useState(false);

  const [selectedEvaluationId, setSelectedEvaluationId] = useState(null);
  const [deletingEvaluationId, setDeletingEvaluationId] = useState(null);
  const [showCreateEvalModal, setShowCreateEvalModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [hasAccessPermit, setHasAccessPermit] = useState(true);
  const [firstConsent, setFirstConsent] = useState(false);
  const [acceptConsentCheckbox, setAcceptConsentCheckbox] = useState(false);
  const [isConsentRequestLoading, setIsConsentRequestLoading] = useState(false);
  const [isEvaluationCreating, setIsEvaluationCreating] = useState(false);

  const patientEvaluations = useSelector((state) => state.patientEvaluations.evaluations);
  const patientHome = useSelector((state) => state.patientHome);
  const hasConsent = useSelector((state) => state.patientHome.consent_check);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (patientId !== null) {
      getEvaluations();
      dispatch(getPatientHome(patientId)).finally(() => setAcceptConsentCheckbox(false));
    }
  }, [patientId]);

  useOnClickOutside([dropDownDivRef, optionsRef], () => setSelectedEvaluationId(null));

  const getEvaluations = () => {
    setIsEvaluationListLoading(true);
    dispatch(
      getPatientEvaluations(patient_id ? patient_id : patientId, {
        page: searchOptions?.page,
        q: searchOptions?.q,
        order_by: searchOptions?.orderBy,
        items_per_page: searchOptions?.itemsPerPage,
      })
    ).then(
      (res) => {
        setIsEvaluationListLoading(false);
        setHasAccessPermit(true);
      },
      (err) => {
        setIsEvaluationListLoading(false);
        console.log(err);
        if (
          err.error.response.data.msg &&
          err.error.response.data.msg === "cannot_access_patient"
        ) {
          setHasAccessPermit(false);
        }
      }
    );
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(
    getEvaluations,
    300,
    "evaluations"
  );

  const getAvailableTags = () => {
    dispatch(getEvaluationTags());
  };

  const createNewEvaluation = (evalName, evalTagsList, evalDate, evalNotes) => {
    setIsEvaluationCreating(true);
    dispatch(
      createPatientEvaluation(patient_id ? patient_id : patientId, {
        evaluation_name: evalName,
        tag_list: evalTagsList,
        date: evalDate,
        comments: evalNotes,
      })
    )
      .then(
        (res) => {
          setShowCreateEvalModal(false);
          if (res.payload && res.payload.data && res.payload.data.data) {
            const evaluation_id = res.payload.data.data.evaluation_id;
            history.push(
              `/patient/${patient_id ? patient_id : patientId}/evaluations/${evaluation_id}/edit`
            );
          }
        },
        (err) => {
          console.log(err);
          setShowCreateEvalModal(false);
        }
      )
      .finally(() => setIsEvaluationCreating(false));
  };

  const deleteEvaluation = (evaluationId) => {
    dispatch(deletePatientEvaluation(patient_id ? patient_id : patientId, evaluationId)).then(
      getEvaluations
    );
  };

  const openNewEvaluationModal = () => {
    getAvailableTags();
    setShowCreateEvalModal(true);
  };

  const handleNewEvaluationBtnClick = () => {
    if (!hasConsent) setFirstConsent(true);
    else {
      openNewEvaluationModal();
    }
  };

  const handleConsentCheck = () => {
    setIsConsentRequestLoading(true);
    dispatch(setConsentCheck(patient_id ? patient_id : patientId))
      .then((res) => {
        setFirstConsent(false);
        openNewEvaluationModal();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsConsentRequestLoading(false));
  };

  const downloadConsentPdf = () => {
    dispatch(
      getPatientConsentPdf({
        name: patientHome?.name,
        birthdate: patientHome?.birthdate,
      })
    )
      .then((response) => {
        var blob = new Blob([response.payload.data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "consent_" + patientHome?.name + ".pdf";
        link.click();
      })
      .catch((error) => {
        console.log("ERROR GETTING PDF");
      });
  };

  const _renderButtons = () => {
    let buttons = [];

    rightMenueButtons.map((value) => {
      if (value === "report") {
        buttons.push({
          text: <span className="regular-text">{t("buttons.report")}</span>,
          action: () => {
            history.push(
              `/patient/${
                patient_id ? patient_id : patientId
              }/evaluations/${selectedEvaluationId}/report`
            );
          },
        });
      } else if (value === "edit") {
        buttons.push({
          text: <span className="regular-text">{t("buttons.edit")}</span>,
          action: () => {
            history.push(
              `/patient/${
                patient_id ? patient_id : patientId
              }/evaluations/${selectedEvaluationId}/edit`
            );
          },
        });
      } else if (value === "delete") {
        buttons.push({
          text: <span className="regular-text warning">{t("buttons.delete")}</span>,
          action: () => {
            setDeletingEvaluationId(selectedEvaluationId);
            setSelectedEvaluationId(null);
          },
        });
      }
      // TODO: Backend is not ready
      // else if (value === "export") {
      //   buttons.push({
      //     text: <span className="regular-text">{t("buttons.export")}</span>,
      //     action: () => {
      //       history.push(`#`);
      //     },
      //   });
      // }
      //
      else if (value === "view_details") {
        buttons.push({
          text: <span className="regular-text">{t("buttons.view_details")}</span>,
          action: () => {
            history.push(
              `/patient/${
                patient_id ? patient_id : patientId
              }/evaluations/${selectedEvaluationId}/report`
            );
          },
        });
      }
    });

    return buttons;
  };

  const _renderFirstConsentModal = () => {
    return (
      <MessageModal
        hasCloseButton={true}
        handleClose={() => setFirstConsent(false)}
        headerText={t("my_area.mKinetikos.consent_title")}
        body={
          <div className="first-consent-container">
            <div className="input-wrapper check-box">
              <input
                disabled={false}
                name={"checkbox"}
                checked={acceptConsentCheckbox}
                onChange={() => setAcceptConsentCheckbox(!acceptConsentCheckbox)}
                type={"checkbox"}
              />
            </div>
            <span>
              {t("create_patient.main_data.consent_text")}{" "}
              <span className="primary-hover-color button" onClick={() => downloadConsentPdf()}>
                {t("create_patient.main_data.available")}
              </span>
            </span>
          </div>
        }
        footerButtons={[
          <button
            className="transparent-button small-wider-button mr-1"
            onClick={() => setFirstConsent(false)}
          >
            <u>{t("buttons.cancel")}</u>
          </button>,
          <button
            className="secondary-button small-wider-button"
            disabled={!acceptConsentCheckbox}
            onClick={() => handleConsentCheck()}
          >
            {isConsentRequestLoading ? (
              <div className="position-relative p-1">
                <Spinner type={"small"} />
              </div>
            ) : (
              t("buttons.confirm")
            )}
          </button>,
        ]}
      />
    );
  };

  //* ************************************** HANDLERS **************************************

  return (
    hasAccessPermit && (
      <>
        <div
          className={`evaluations-module-wrapper ${extraStyleClass}`}
          style={{ padding: extraStyleClass ? "0" : "" }}
        >
          <div className="evaluations-module-container">
            <div className="row-space-between">
              <span className="small-title">{headerText}</span>
              {isNewEvaluationsBtn && (
                <button
                  onClick={() => {
                    handleNewEvaluationBtnClick();
                  }}
                  className="button small-button primary-button"
                >
                  <img alt="New Evaluation" className="eval-icon" src={evaluationsIcon} />
                  <span>{t("buttons.new_evaluation")}</span>
                </button>
              )}
            </div>
            {isSearchBar && (
              <div className="row search-wrapper">
                <div className="col">
                  <div style={{ position: "relative" }}>
                    <input
                      value={searchOptions?.q}
                      onChange={(e) => updateSearchText(e.target.value)}
                      type="text"
                      className="search-input w-100"
                      placeholder={t("placeholders.search_evaluations")}
                    />
                    <img alt="search-icon" className="search-icon" src={search} />
                  </div>
                </div>
              </div>
            )}
            <div className="evaluations-list">
              <div className="row list-label eval-list-labels-wrapper">
                {colsToShow.includes("name") && (
                  <div
                    className={
                      colsToShow.length === 1
                        ? `col d-flex align-item-center`
                        : `col-${(colsToShow.length % 3) + 3} d-flex align-item-center`
                    }
                  >
                    <div
                      onClick={() => {
                        updateOrderBy(
                          searchOptions?.orderBy === "name"
                            ? "name_inv"
                            : searchOptions?.orderBy === "name_inv"
                            ? "date_inv"
                            : "name"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("patient.evaluations.name_short")}
                    </div>
                    <div
                      className={`order-img-container ${
                        searchOptions?.orderBy === "name" || searchOptions?.orderBy === "name_inv"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        updateOrderBy(
                          searchOptions?.orderBy === "name"
                            ? "name_inv"
                            : searchOptions?.orderBy === "name_inv"
                            ? "date_inv"
                            : "name"
                        );
                      }}
                    >
                      {searchOptions?.orderBy !== "name" && (
                        <img
                          src={
                            searchOptions?.orderBy === "name_inv"
                              ? chevronUpSelectedIcon
                              : chevronUpIcon
                          }
                          alt="Order by Name"
                        />
                      )}
                      {searchOptions?.orderBy !== "name_inv" && (
                        <img
                          src={
                            searchOptions?.orderBy === "name"
                              ? chevronDownSelectedIcon
                              : chevronDownIcon
                          }
                          alt="Order by Name Invert"
                        />
                      )}
                    </div>
                  </div>
                )}
                {colsToShow.includes("tags") && (
                  <div className="col-6">{t("patient.evaluations.tags")}</div>
                )}
                {colsToShow.includes("date") && (
                  <div className="col d-flex align-item-center">
                    <div
                      onClick={() => {
                        updateOrderBy(searchOptions?.orderBy === "date_inv" ? "date" : "date_inv");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("patient.evaluations.date")}
                    </div>
                    <div
                      className={`order-img-container ${
                        searchOptions?.orderBy === "date" || searchOptions?.orderBy === "date_inv"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        updateOrderBy(searchOptions?.orderBy === "date_inv" ? "date" : "date_inv");
                      }}
                    >
                      {searchOptions?.orderBy !== "date_inv" && (
                        <img
                          src={
                            searchOptions?.orderBy === "date"
                              ? chevronUpSelectedIcon
                              : chevronUpIcon
                          }
                          alt="Order from Old to New"
                        />
                      )}
                      {searchOptions?.orderBy !== "date" && (
                        <img
                          src={
                            searchOptions?.orderBy === "date_inv"
                              ? chevronDownSelectedIcon
                              : chevronDownIcon
                          }
                          alt="Order from New to Old"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {isEvaluationListLoading ? (
                <div style={{ height: "100px" }}>
                  <div className="loading-wrapper">
                    <Spinner type={spinnerType} />
                  </div>
                </div>
              ) : !isEvaluationListLoading && patientEvaluations.obj_list.length === 0 ? (
                <div className="medium-base-color-text message-wrapper">
                  <p>{t("patient.evaluations.no_match")}</p>
                </div>
              ) : (
                !isEvaluationListLoading &&
                patientEvaluations.obj_list.map((evaluation, index) => (
                  <div
                    key={evaluation.id + index.toString()}
                    className={`row eval-info-wrapper`}
                    style={{ cursor: isFullRowClickable ? "pointer" : "" }}
                    onClick={
                      isFullRowClickable
                        ? () =>
                            history.push(
                              `/patient/${patient_id ? patient_id : patientId}/evaluations/${
                                evaluation.id
                              }/edit/`
                            )
                        : undefined
                    }
                  >
                    {colsToShow.includes("name") && (
                      <div
                        onClick={() => {
                          history.push(
                            nameTextLink
                              ? `/patient/${patient_id ? patient_id : patientId}/evaluations/${
                                  evaluation.id
                                }/${nameTextLink}/`
                              : `#`
                          );
                        }}
                        className={
                          colsToShow.length === 1
                            ? `col regular-text black-color align-items-center pointer text-truncate`
                            : `col-${
                                (colsToShow.length % 3) + 3
                              } regular-text black-color align-items-center pointer text-truncate`
                        }
                      >
                        {evaluation.name}
                      </div>
                    )}

                    {colsToShow.includes("tags") && (
                      <div
                        // onClick={() => {
                        //   history.push(
                        //     `/patient/${patient_id}/evaluations/${evaluation.id}`
                        //   );
                        // }}
                        className="col-6 tags-wrapper regular-text black-color"
                      >
                        {evaluation.tags.length === 0
                          ? "-----"
                          : evaluation.tags.map((tag) => (
                              <div key={tag.id} className="tag-container">
                                {tag.name}
                              </div>
                            ))}
                      </div>
                    )}
                    {colsToShow.includes("date") && (
                      <div
                        // onClick={() => {
                        //   history.push(
                        //     `/patient/${patient_id}/evaluations/${evaluation.id}`
                        //   );
                        // }}
                        className="col regular-text black-color"
                      >
                        {parseDateFromDatetime(evaluation.date)}
                      </div>
                    )}

                    {isFullRowClickable && (
                      <div className="col-auto">
                        <div className="d-flex align-items-center">
                          <div className="more-image-wrapper">
                            <img
                              className="image-cursor"
                              onClick={() => setSelectedEvaluationId(evaluation.id)}
                              src={arrowRightIcon}
                              alt="More Blue"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {rightMenueButtons.length > 0 && !isFullRowClickable && (
                      <div className="col-auto">
                        <div className="d-flex align-items-center">
                          <div className="more-image-wrapper">
                            <img
                              ref={(el) => (optionsRef.current[index] = el)}
                              className="image-cursor"
                              onMouseOver={(e) =>
                                selectedEvaluationId !== evaluation.id &&
                                (e.currentTarget.src = moreBlueSelected)
                              }
                              onMouseOut={(e) =>
                                selectedEvaluationId !== evaluation.id &&
                                (e.currentTarget.src = moreBlue)
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                selectedEvaluationId === evaluation.id
                                  ? setSelectedEvaluationId(null)
                                  : setSelectedEvaluationId(evaluation.id);
                              }}
                              src={
                                selectedEvaluationId === evaluation.id ? moreBlueSelected : moreBlue
                              }
                              alt="More Blue"
                            />
                            {selectedEvaluationId === evaluation.id && (
                              <DropdownOptions ref={dropDownDivRef} buttons={_renderButtons()} />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
          {!isEvaluationListLoading && isPaginator && (
            <Paginator
              currPage={patientEvaluations.page}
              totalPages={patientEvaluations.num_pages}
              maxPages={3}
              changePage={updatePage}
            />
          )}
        </div>
        {deletingEvaluationId !== null && (
          <MessageModal
            headerText={t("modals.attention_header")}
            body={t("modals.delete_evaluation_body")}
            footerButtons={[
              <button
                onClick={() => {
                  setDeletingEvaluationId(null);
                }}
                className="small-button transparent-button"
              >
                <u>{t("buttons.cancel")}</u>
              </button>,
              <button
                onClick={() => {
                  deleteEvaluation(deletingEvaluationId);
                  setDeletingEvaluationId(null);
                }}
                className="small-button warning-button"
              >
                {t("buttons.delete")}
              </button>,
            ]}
          />
        )}
        {showCreateEvalModal && (
          <CreateEvaluationModal
            onCancel={() => setShowCancelModal(true)}
            onSubmit={createNewEvaluation}
            isButtonDisabled={isEvaluationCreating}
          />
        )}
        {/* The parent div should actually have a position relative so the modal centers around it but this might
       break the design elsewhere so this is a quick fix*/}
        {showCancelModal && (
          <MessageModal
            headerText={t("patient.evaluations.discard_evaluation_title")}
            body={t("patient.evaluations.discard_evaluation_body")}
            footerButtons={[
              <button
                onClick={() => {
                  setShowCancelModal(false);
                }}
                type="button"
                className="small-button transparent-button"
              >
                <u>{t("buttons.cancel")}</u>
              </button>,
              <button
                onClick={() => {
                  setShowCancelModal(false);
                  setShowCreateEvalModal(false);
                }}
                type="button"
                className="small-button secondary-button"
              >
                <u>{t("buttons.confirm")}</u>
              </button>,
            ]}
          />
        )}
        {firstConsent && _renderFirstConsentModal()}
      </>
    )
  );
};

export default memo(EvaluationsModule);
