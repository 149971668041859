import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
// import "./report.scss";
import { create_card } from "../../../utils/create_card";
import React from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

const LineChart = ({ modal = false, details }) => {
  // const svgRef = useRef(null);
  const reff = useRef(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();
  // let data = {
  //   plot_data: [
  //     [
  //       "Stand ",
  //       "Walk (Go)",
  //       "Turn",
  //       "Walk (Back)",
  //       "Turn (To Sit)",
  //       "Sit ",
  //       "TUG Time ",
  //     ],
  //     [
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 1.24,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 2.08,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 1.16,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 1.79,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 0.81,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 0.35,
  //         },
  //       ],
  //       [
  //         {
  //           date: "2022-04-14",
  //           value: 7.43,
  //         },
  //       ],
  //     ],
  //   ],
  //   settings: {
  //     value_dates: false,
  //     dropdown: true,
  //     domain: [0, 45],
  //     round: 1,
  //   },
  //   labels: {
  //     day: "Date",
  //     value: "Value",
  //     y_label: "s",
  //   },
  //   no_data_text: "No data",
  // };
  // const details = {
  //   id: 108,
  //   type: 1,
  //   title: "TUGs Duration Overtime",
  //   description: "TUGs Duration Overtime",
  //   icon: '<i class="fa fa-area-chart" aria-hidden="true"></i>',
  //   color: "var(--kred-color)",
  //   span_row: 4,
  //   span_col: 5,
  // };
  // let color = "#4c657a";

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_line_plot(card, data, color) {
      let x_key = "date",
        x_key_tolltip = data.labels.day,
        y_key = "value",
        y_key_tolltip = data.labels.value,
        extra_key = "deviation",
        extra_key_tolltip = data.labels.extra,
        thresholds = data.thresholds,
        y_label = data.labels.y_label,
        no_data_text = data.no_data_text,
        single = data.settings.dropdown,
        data_domain = data.settings.domain,
        is_value_dates = data.settings.value_dates,
        round = data.settings.round ? data.settings.round : 2;

      if (color === undefined || color === "" || color === null)
        color = "var(--kblue-color)";
      data = data.plot_data;
      let categories = data[0];

      //let card_body = card.querySelector(".card-body"),
      let card_body = document
          .querySelector(`[data-card_id="${details.id}"]`)
          .querySelector(".card-body"), // get the matching card
        wrapper_div = document.createElement("div"),
        card_header_actions = card.querySelector(".card-header .actions"),
        dropdown = document.createElement("select");
      wrapper_div.classList = modal ? "svg-div modall" : "svg-div";
      dropdown.classList = "Form-input selectInput plot-dropdown ";

      if (single) {
        categories.forEach((label, i) => {
          let drop_item = document.createElement("option");
          drop_item.innerText = label;
          drop_item.value = i;
          dropdown.appendChild(drop_item);
        });

        dropdown.addEventListener("change", dropdown_change, false);
        function dropdown_change(e) {
          // let svg = d3.select(`#${card.id} svg`);
          let svg = d3.select(`[data-card_id="${details.id}"] svg`);

          d3.select(`[data-card_id="${details.id}"] svg`);
          svg.selectAll("*").remove();
          draw_plot(data[1][this.value]);
        }
        card_header_actions.appendChild(dropdown);
      }
      card_body.appendChild(wrapper_div);
      // Create the tooltip element. This element is unique to the chart, and moves to the hovered dot. THERE IS ONLY ONE TOOLTIP
      // It is created in this way so that we have a D3 object with its inherited functions
      //let tooltip = d3
      //  .select(`#${card.id} .card-body`)
      let tooltip = d3
        .select(`[data-card_id="${details.id}"] .card-body`)
        .append("div")
        .attr("class", "plot-tooltip")
        .style("opacity", 0);

      let rect = wrapper_div.getBoundingClientRect(),
        wrapper = modal
          ? d3
              .select(`#${card.id} .svg-div.modall`)
              .append("svg")
              .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
              .attr("preserveAspectRatio", "xMidYMin meet")
          : d3
              //.select(`#${card.id} .svg-div:not(.modall)`)
              .select(`[data-card_id="${details.id}"] .svg-div:not(.modall)`)
              .append("svg")
              .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
              .attr("preserveAspectRatio", "xMidYMin meet"),
        margin = { top: 20, right: 25, bottom: 20, left: 40 },
        inner_width = rect.width - margin.left - margin.right,
        inner_height = rect.height - margin.top - margin.bottom,
        // Creation of some time parsers to better handle labeling and scales
        parseTime = d3.timeParse("%d/%m/%y"),
        formatTime = d3.timeFormat("%e %B, %Y"),
        // The Number of thicks in the plot
        // Note that D3 Automaticaly adjusts this value to one that better adjusts to the ploted data
        tick_num = 4;
      draw_plot(data[1][0]);

      function draw_plot(data) {
        if (data !== undefined && data.length > 0) {
          let x_scale = d3.scaleTime().rangeRound([0, inner_width]),
            y_scale = (
              is_value_dates ? d3.scaleTime() : d3.scaleLinear()
            ).rangeRound([inner_height, 0]),
            x_axis = d3.axisBottom().scale(x_scale),
            y_axis = d3.axisLeft().scale(y_scale),
            // create color scale and domain
            color_scale = d3.scaleOrdinal(d3.schemeCategory10), // d3.scaleOrdinal().range(["#6F257F", "#CA0D59"])
            //create the line constructor
            line_const = d3
              .line()
              .curve(d3.curveMonotoneX)
              .x((d) => {
                return x_scale(d.time);
              })
              // the key value will be created by us, it doesn't come in the tsv
              .y((d) => {
                return y_scale(d.value);
              }),
            // Create the Chart area and translate it to the desired position, considering the margins defined above.
            g = wrapper
              .append("g")
              .attr("transform", `translate(${margin.left}, ${margin.top})`),
            // In this stage we will remove any entrys of data that are empty or unusable
            noY_keyIndexes = new Array(), // This will accomodate the indexes of Y values that are not usable
            noX_keyIndexes = new Array(); // This will accomodate the indexes of X values that are not usable

          // For each data entry we will perform the checks

          data.map((item, index) => {
            if (is_value_dates) {
              item[y_key] = new Date(item[y_key]);
            } //Also .getTime() in the next line
            if (isNaN(item[y_key]) || item[y_key] === null) {
              // If the Y value is not a number
              noY_keyIndexes.push(index); // This item will have to be declared as no data
              // But we still want to no if there was a record for this day, but just not evaluated
              if (!(item[x_key] === null)) {
                // So If the X value exits
                item[x_key] = new Date(item[x_key]); // Make it a dateTime object to use in the time scale
              }
            } else {
              // If the Y value was a number
              if (item[x_key] === null) {
                // If there is no date for this record we want to dele it
                noX_keyIndexes.push(index); // This item will have to be deleted
              } else {
                item[x_key] = new Date(item[x_key]); // Transform the date string into a real date
              }
            }
          });
          // Remove the values that have no data associated
          noX_keyIndexes.forEach(function (item, index) {
            data.splice(item - index, 1); //because the length of data gets shorter at each splice we have to subtract the index
          });

          // The domain has to be set here, before splicing the data, were there are no values but theere is a dateTime
          // So that they are also ploted
          if (data.length > 1) {
            x_scale.domain(
              d3.extent(data, function (d) {
                return d[x_key];
              })
            ); // Automaticaly sets the domain from the older date to the newest
          } else {
            let aux_date = new Date(data[0][x_key]);
            x_scale.domain([
              aux_date.setMonth(aux_date.getMonth() - 1),
              aux_date.setMonth(aux_date.getMonth() + 2),
            ]); // Automaticaly sets the domain from the older date to the newest
          }
          if (data_domain == true) {
            y_scale.domain([0, 100]);
          } else {
            if (data_domain == false) {
              y_scale.domain([
                d3.min(data, function (d) {
                  return d[y_key];
                }),
                d3.max(data, function (d) {
                  return d[y_key];
                }),
              ]);
            } else {
              y_scale.domain(data_domain);
            }
          }

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * TICKS AND GRID  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          place_ticks(
            g,
            x_scale,
            y_scale,
            inner_height,
            tick_num,
            y_label,
            is_value_dates
          );
          place_grid_lines(
            g,
            x_scale,
            y_scale,
            inner_width,
            inner_height,
            tick_num
          );

          //We will remove all entrys of the data object that have no probability, and place a X with zero has the probability
          let removedLen = noX_keyIndexes.length; //we need to take into consideration the fact that we already removed some indexes
          noY_keyIndexes.forEach((item, index) => {
            let tooltip_text = `<strong>${formatTime(
                new Date(data[item - index - removedLen][x_key])
              )}</strong><br>${no_data_text}`,
              x_pixels = x_scale(
                new Date(data[item - index - removedLen][x_key])
              );

            g.append("text")
              .attr("x", -6 + x_pixels)
              .attr(
                "y",
                7 +
                  y_scale(
                    is_value_dates
                      ? d3.min(data, function (d) {
                          return d[y_key];
                        })
                      : 0
                  )
              ) // Date
              .attr("font-size", "1.2em")
              .attr("font-weight", 900)
              .attr("class", "no_data_marker")
              .attr("fill", color)
              .text("X")

              .on("mouseover", (e) => {
                tooltip_mouseover(
                  tooltip,
                  tooltip_text,
                  x_pixels,
                  y_scale(
                    is_value_dates
                      ? d3.min(data, function (d) {
                          return d[y_key];
                        })
                      : 0
                  ),
                  color,
                  rect
                );
                dashed_line_mousehover(
                  g,
                  x_pixels,
                  y_scale(
                    is_value_dates
                      ? d3.min(data, function (d) {
                          return d[y_key];
                        })
                      : 0
                  ),
                  inner_width,
                  inner_height,
                  color,
                  0
                );
              })
              .on("mouseout", function () {
                tooltip_mouseout(tooltip);
                dashed_line_mouseout(g);
              });
            data.splice(item - index - removedLen, 1); //because the length of data gets shorter and shorter at each splice
          });

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * GRADIENT  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          place_gadrient(card.id, g, y_scale, color);

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * AREA  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          let value_area = d3
            .area()
            .x(function (d) {
              return x_scale(d[x_key]);
            })
            .y0(inner_height)
            .y1(function (d) {
              return y_scale(d[y_key]);
            });

          place_area(card.id, data, g, value_area);
          let t = d3.transition().duration(1500).ease(d3.easeLinear);

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * DASHED LINES  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          place_dashed_lines(g, inner_width, inner_height, color);

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * OUTER CICLES  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

          g.selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("r", "0.9em")
            .attr("cx", function (d) {
              return x_scale(d[x_key]);
            })
            .attr("cy", function (d) {
              return y_scale(d[y_key]);
            })
            .attr("stroke", "none")
            .attr("fill", function (d) {
              if (thresholds == null || d[extra_key] == null) {
                return "none";
              } else {
                if (d[extra_key] < thresholds.t1) {
                  return "var(--benchmark-0)";
                } else {
                  if (d[extra_key] < thresholds.t2) {
                    return "var(--benchmark-1)";
                  } else {
                    if (d[extra_key] < thresholds.t3) {
                      return "var(--benchmark-2)";
                    } else {
                      if (d[extra_key] >= thresholds.t3) {
                        return "var(--benchmark-3)";
                      } else {
                        return "none";
                      }
                    }
                  }
                }
              }
            });

          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * LINE  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          let line = d3
            .line()
            .x(function (d) {
              return x_scale(d[x_key]);
            })
            .y(function (d) {
              return y_scale(d[y_key]);
            });
          place_line(data, g, line, color);
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * CIRCLES  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
          // Add the scatterplot
          g.selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "chart-circle")
            .attr("r", "0.5em")
            .attr("stroke", color)
            .attr("fill", color)
            .attr("cx", function (d) {
              return x_scale(d[x_key]);
            })
            .attr("cy", function (d) {
              return y_scale(d[y_key]);
            })
            .on("mouseover", function (d) {
              var tooltip_text;
              if (thresholds == null || d[extra_key] == null) {
                tooltip_text = `<p class="smallText secondary">${x_key_tolltip}: <span class="smallText kinetikosBlue">${formatTime(
                  d[x_key]
                )}</span></p>
                                                  <p class="smallText secondary">${y_key_tolltip}: <span class="smallText kinetikosBlue">${
                  is_value_dates
                    ? formatTime(d[y_key])
                    : parseFloat(d[y_key]).toFixed(round) + y_label
                }</span></p>`;
              } else {
                tooltip_text = `<p class="smallText secondary">${x_key_tolltip}: <span class="smallText kinetikosBlue">${formatTime(
                  d[x_key]
                )}</span></p>
                                                  <p class="smallText secondary">${y_key_tolltip}: <span class="smallText kinetikosBlue"> ${
                  is_value_dates
                    ? formatTime(d[y_key])
                    : parseFloat(d[y_key]).toFixed(round) + y_label
                }</span><p>
                                                  <p class="smallText secondary">${extra_key_tolltip}: <span class="smallText kinetikosBlue"> ${parseFloat(
                  d[extra_key]
                ).toFixed(2)}</span></p>`;
              }
              tooltip_mouseover(
                tooltip,
                tooltip_text,
                x_scale(d[x_key]),
                y_scale(d[y_key]),
                color,
                rect
              ); //d3.event.pageX, d3.event.pageY, color);
              dashed_line_mousehover(
                g,
                x_scale(d[x_key]),
                y_scale(d[y_key]),
                inner_width,
                inner_height,
                color,
                0
              );
              // dashed_line_mousehover(g, x_pixels, y_scale(d3.min(data, function(d) { return d[y_key]; })), inner_width, inner_height, color, 0);
            })
            .on("mouseout", function (d) {
              tooltip_mouseout(tooltip);
              dashed_line_mouseout(g);
            });
        }
      }
    }

    function tooltip_mouseover(tooltip, tooltip_text, x, y, color, rect) {
      tooltip.html(tooltip_text);

      tooltip
        .transition()
        .duration(200)
        .style("opacity", 0.9)
        .style("border", "0.1em solid " + color);

      var tool_rect = tooltip.node().getBoundingClientRect();
      y = y - tool_rect.height / 3 - 15;

      // tooltip extends the card to the left
      if (x - tool_rect.width / 2 < 0) {
        x = x + tool_rect.width / 2 - 10;
        tooltip.attr("style", "--left: 5%");
      } else if (x + tool_rect.width / 2 > rect.width) {
        // tooltip extends the card to the rigth
        x = x - tool_rect.width / 2 + 10;
        tooltip.attr("style", "--left: 95%");
      } else {
        tooltip.attr("style", "--left: 50%");
      }

      tooltip
        .style("left", `calc(${x}px - 5em + 2 * 0.8em)`)
        .style("top", `calc((${y}px + 2em) + 2 * 0.2em)`);
    }
    function tooltip_mouseover_dual_axis(tooltip, tooltip_text, x, y, color) {
      tooltip.html(tooltip_text);

      tooltip
        .transition()
        .duration(200)
        .style("opacity", 0.9)
        .style("border", "0.1em solid " + color);

      var tool_rect = tooltip.node().getBoundingClientRect();
      if (tool_rect.height > 50) {
        y = y - tool_rect.height / 2;
      } else {
        y = y - tool_rect.height / 3;
      }
      tooltip
        .style("left", x - tool_rect.width / 2 + 30 + "px")
        .style("top", y + "px");
    }

    function tooltip_mouseout(tooltip) {
      tooltip.transition().duration(200).style("opacity", 0);
    }

    function dashed_line_mousehover(g, x, y, width, height, color, side) {
      // PLACE THE LINE WHERE IT IS EXPECTED AND MAKE ITS HEIGHT = 0
      g.select(".x-hover-line")
        .attr("stroke", color)
        .attr("y1", y)
        .attr("y2", y)
        .style("display", "initial")
        .attr("transform", "translate(" + x + ", 0)");

      // TRANSITION UNTIL THE LINE REACHED THE X AXIS
      g.select(".x-hover-line").transition().duration(400).attr("y2", height);

      g.select(".y-hover-line")
        .attr("stroke", color)
        .attr("transform", "translate(0 ," + y + ")")
        .attr("x1", x)
        .attr("x2", x)
        .style("display", "initial");

      if (side == 1) {
        //right
        g.select(".y-hover-line").transition().duration(400).attr("x2", width);
      } else {
        g.select(".y-hover-line").transition().duration(400).attr("x2", 0);
      }
    }
    function dashed_line_mouseout(g) {
      g.select(".x-hover-line").style("display", "none");
      g.select(".y-hover-line").style("display", "none");
    }

    // gridlines in x axis function
    function make_x_gridlines(x, tick_num) {
      return d3.axisBottom(x).ticks(tick_num);
    }

    // gridlines in y axis function
    function make_y_gridlines(y, tick_num) {
      return d3.axisLeft(y).ticks(tick_num);
    }

    function create_svg(chart_id, margin, width, height) {
      // console.log(chart_id)
      return (
        d3
          .select(`#${chart_id} .card-body`)
          .append("svg")
          // .attr("width", width + margin.left + margin.right)
          // .attr("height", height + margin.top + margin.bottom)
          .attr(
            "viewBox",
            `0 0 ${width + margin.left + margin.right} ${
              height + margin.top + margin.bottom
            }`
          )
          .attr("preserveAspectRatio", "xMidYMid meet")
          .attr("class", "noselect")
      ); // To avoid any selections with the mouse or by long pressing the svg on a phone
    }

    function place_ticks(g, x, y, height, tick_num, y_label, is_value_dates) {
      g.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).ticks(tick_num));

      var y_axis = g.append("g").call(d3.axisLeft(y).ticks(tick_num));

      y_axis
        .append("text")
        .attr("fill", "#000")
        .attr("x", 5)
        .attr("y", -12)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")
        .attr("stroke", "lightgray")
        .attr("stroke-opacity", "0.7")
        .text(y_label);

      if (is_value_dates) {
        y_axis
          .selectAll(".tick text")
          .style("text-anchor", "middle")
          .attr("x", 0)
          .attr("y", -10)
          .attr("transform", "rotate(-45)");
      }

      return y_axis;
    }
    function place_grid_lines(g, x, y, width, height, tick_num) {
      // add the X gridlines
      g.append("g")
        .attr("class", "grid")
        .attr("transform", "translate(0," + height + ")")
        .call(make_x_gridlines(x, tick_num).tickSize(-height).tickFormat(""));

      // add the Y gridlines
      g.append("g")
        .attr("class", "grid")
        .call(make_y_gridlines(y, tick_num).tickSize(-width).tickFormat(""));
    }

    function place_gadrient(chart_id, g, y, color) {
      //blue gradient for the area below the line
      g.append("linearGradient")
        .attr("id", chart_id + "-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", y(0))
        .attr("x2", 0)
        .attr("y2", y(100))
        .selectAll("stop")
        .data([
          { offset: "0%", color: color, opacity: "0.2" },
          { offset: "50%", color: color, opacity: "0.1" },
          { offset: "80%", color: color, opacity: "0.05" },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        })
        .attr("stop-opacity", function (d) {
          return d.opacity;
        });
    }

    function place_area(chart_id, data, g, value_area) {
      // Add the area beneath the plot.
      g.append("path")
        .datum(data)
        .attr("class", "area")
        .attr("fill", "url(#" + chart_id + "-gradient)")
        .attr("d", value_area)
        .attr("stroke-dasharray", function (d) {
          return this.getTotalLength();
        });
    }
    function place_line(data, g, line, color) {
      g.append("path")
        .datum(data)
        .attr("d", line)
        .attr("class", "chart-line")
        .attr("stroke", color);
    }

    function place_dashed_lines(g, width, height, color) {
      g.append("line")
        .attr("class", "x-hover-line hover-line")
        .attr("y1", 0)
        .attr("y2", height)
        .style("display", "none");

      g.append("line")
        .attr("class", "y-hover-line hover-line")
        .attr("x1", 0)
        .attr("x2", width)
        .style("display", "none");
    }
    if (data === null)
      create_card(
        reff.current,
        3,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    if (data) build_line_plot(reff.current, data, details.color);
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default React.memo(LineChart);
