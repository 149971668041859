import DonutChart from "../../../components/charts/Report/donutChart";
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import SpiderChart from "../../../components/charts/Report/spiderChart";
import LineChart from "../../../components/charts/Report/lineChart";
import DonnutChart from "../../../components/charts/Report/donnutChart";
import HorizontalBars from "../../../components/charts/Report/horizontalBarsChart";
import ReportModal from "../../../components/reportModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InteractiveMultilineChart from "../../../components/charts/Report/interactiveMultilineChart";
import { getPatientEvaluationReportDetails } from "../../../redux/actions/patients";
import { useHistory, useParams } from "react-router-dom";
import EventsChart from "../../../components/charts/Report/eventsChart";
import MetricsChart from "../../../components/charts/Report/metricsChart";
import ImageCard from "../../../components/charts/Report/imageCard";
import TextCard from "../../../components/charts/Report/textCard";
import GaugeChart from "../../../components/charts/Report/gaugeChart";
import VideoCard from "../../../components/charts/Report/videoCard";
import Spinner from "../../../components/spinner";
import Breadcrumbs from "../../../components/breadcrumbs";
import NavBar from "../../../components/navbar";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import { useTranslation } from "react-i18next";

const Report = () => {
  // const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  const [selectCategoryId, setSelectCategoryId] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const reportDetails = useSelector(
    (state) => state.patientEvaluations.reportDetails
  );

  const [chartModalInfo, setChartModalInfo] = useState({
    open: false,
    chartId: null,
    url: "",
  });

  useEffect(() => {
    setHasError(reportDetails?.templates[selectCategoryId]?.has_error);
  }, [selectCategoryId]);

  const onPreview = (chartId, url) => {
    setChartModalInfo({
      open: true,
      chartId,
      url,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPatientEvaluationReportDetails(patient_id, evaluation_id))
      .then((res) => {})
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const renderChart = (details, index) => {
    if (details.type === 1) {
      return <LineChart details={details} />;
    } else if (details.type === 2) {
      return <HorizontalBars details={details} />;
    } else if (details.type === 4) {
      return (
        <DonnutChart
          details={details}
          showInfo={(chartId, url) => onPreview(chartId, url)}
          key={index}
        />
      );
    } else if (
      details.type === 3 ||
      details.type === 5 ||
      details.type === 6 ||
      details.type === 11 ||
      details.type === 12 ||
      details.type === 19
    ) {
      return (
        <TextCard
          id={details.type}
          details={details}
          showInfo={(chartId, url) => onPreview(chartId, url)}
        />
      );
    } else if (details.type === 7) {
      return <VideoCard details={details} />;
    } else if (details.type === 8) {
      return <InteractiveMultilineChart details={details} />;
    } else if (details.type === 9) {
      return <SpiderChart details={details} />;
    } else if (details.type === 10) {
      return <EventsChart details={details} />;
    } else if (details.type === 20) {
      return <MetricsChart details={details} />;
    } else if (details.type === 21) {
      return <ImageCard details={details} />;
    } else if (details.type === 24) {
      return <GaugeChart details={details} />;
    }
  };

  const changeCategory = (id) => {
    // console.log(document.getElementById("grid-drag-drop"));
    // document.getElementById("grid-drag-drop").html("");
    // $("#grid-drag-drop").empty();

    setSelectCategoryId(id);
    setIsCategoryLoading(true);
    setTimeout(() => setIsCategoryLoading(false), 1000);
  };

  const onClickDismissBtn = () => {
    setHasError(false);
  };

  const _renderErrorMessage = () => {
    return (
      <div className="error-message-container">
        <Icon icon={icons["error-icon"]} className={"p-2"} />
        <span className="regular-text black-color">
          {t("patient.reports.report-error-msg")}
        </span>
        <button
          className="small-button transparent-button mr-1"
          onClick={() => onClickDismissBtn()}
        >
          <u>{t("toast_component.dismiss")}</u>
        </button>
      </div>
    );
  };

  return (
    <div className="col patient-container reports-page position-relative">
      {hasError && !isLoading && _renderErrorMessage()}
      {isLoading ? (
        <Spinner type={"biggest-blue"} />
      ) : (
        <div className="position-relative flex-d-column d-flex h-100">
          {" "}
          <div className="row-space-between">
            <Breadcrumbs />
            <NavBar />
          </div>
          <div className="flex-d-column align-items-start mb-6">
            <div className="title-container mb-0">
              <img
                onClick={() => {
                  history.push(`/patient/${patient_id}/evaluations`);
                }}
                alt="back Button"
                src={bigBackButton}
              />
              <h4>{reportDetails.name}</h4>
            </div>
            <div className="mt-1 regular-text">
              {reportDetails.date.split("T")[0]}
            </div>
          </div>
          <div className="category-buttons-wrapper">
            {reportDetails.templates.map((template, index) => (
              <div
                onClick={() => changeCategory(index)}
                key={template.id + index}
                className={`category-container ${
                  index === selectCategoryId && "active"
                }`}
              >
                {template.name}
              </div>
            ))}
          </div>
          {isCategoryLoading ? (
            <div className="position-relative flex-d-column d-flex h-100">
              <Spinner type={"biggest-blue"} />
            </div>
          ) : (
            <div class="wrapper mt-4" id="grid-drag-drop">
              <React.Fragment>
                {!isLoading &&
                  reportDetails.templates[selectCategoryId] &&
                  reportDetails.templates[selectCategoryId].card_id_list &&
                  reportDetails.templates[selectCategoryId].card_id_list.map(
                    (card, index) => renderChart(card, index)
                  )}
              </React.Fragment>
            </div>
          )}
        </div>
      )}

      {chartModalInfo.open && (
        <ReportModal
          closeModal={() => setChartModalInfo({ open: false, details: null })}
          chartId={chartModalInfo.chartId}
          details={chartModalInfo.details}
          url={chartModalInfo.url}
        />
      )}
    </div>
  );
};

export default Report;
