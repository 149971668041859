//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import MedicinesModulesList from "../partials/medicinesModuleList.js";
import MessageModal from "../../../../components/messageModal/index.js";
import AddMedicinesModal from "../partials/addMedicinesModal.js";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import {
  deletePrescription,
  getPrescriptionsDetails,
  updatePrescription,
} from "../../../../redux/actions/patientPrescriptions.js";
import { errors } from "../../../../constants/general.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import Spinner from "../../../../components/spinner/index.js";

const EditPrescription = (props) => {
  //* *************************************** SETUP ***************************************
  const dispatch = useDispatch();
  const { patient_id, prescription_id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddMedicineModalOpen, setIsAddMedicineModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [prescriptionDetails, setPrescriptionDetails] = useState({
    active: true,
    id: null,
    name: "",
    user_can_edit: true,
    medicines: [],
  });
  const [originalPrescriptionDetails, setOriginalPrescriptionDetails] =
    useState(null);

  const [hasEditedMedication, setHasEditedMedication] = useState(false);

  const [prescriptionDetailsErrors, setPrescriptionDetailsErrors] = useState(
    {}
  );

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { t, i18n } = useTranslation();

  const onClickDelete = () => setShowDeleteModal(true);
  const onCancelDelete = () => setShowDeleteModal(false);
  const onConfirmDelete = () => {
    setIsDeleting(true);
    dispatch(deletePrescription(patient_id, prescription_id)).then(() => {
      setIsDeleting(false);
      setShowDeleteModal(false);
      props.history.goBack();
    });
  };

  //* ************************************** HANDLERS **************************************
  useEffect(() => {
    getPrescriptions();
  }, []);

  const getPrescriptions = () => {
    setLoading(true);
    dispatch(getPrescriptionsDetails(patient_id, prescription_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          const details = res.payload.data.data;
          setPrescriptionDetails(details);
          setOriginalPrescriptionDetails(details);
          setLoading(false);
        }
      })
      .catch(() => {
        alert(errors.service_error);
        setLoading(false);
      });
  };

  const savePrescription = () => {
    setIsSaving(true);
    const newPrescriptionDetails = { ...prescriptionDetails };
    delete prescriptionDetails["medicines"];
    dispatch(
      updatePrescription(patient_id, prescription_id, newPrescriptionDetails)
    )
      .then(() =>
        props.history.replace(`/patient/${patient_id}/mkinetikos_prescriptions`)
      )
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response.data;
          if (data.form_errors) {
            setPrescriptionDetailsErrors(data.form_errors);
          }
        } else {
          alert(errors.service_error);
        }
      })
      .finally(() => setIsSaving(false));
  };

  const goBackToPrescriptionList = () =>
    props.history.replace(`/patient/${patient_id}/mkinetikos_prescriptions`);

  const refreshAndCloseMedicinesModal = () => {
    setIsAddMedicineModalOpen(false);
    getPrescriptions();
  };

  const haveFieldsChanged =
    prescriptionDetails.name !== originalPrescriptionDetails?.name ||
    prescriptionDetails.active !== originalPrescriptionDetails?.active ||
    prescriptionDetails.user_can_edit !==
      originalPrescriptionDetails?.user_can_edit ||
    hasEditedMedication;

  // In case we need a validator at some point
  const isAbleToSave =
    prescriptionDetails.name?.trim() !== "" && haveFieldsChanged;

  //* ************************************** RENDER **************************************
  return (
    <div className={`col patient-container`}>
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={goBackToPrescriptionList}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.edit_prescription")}</h4>
      </div>

      <div className={`create-exercise-box`}>
        <div className="flex-d-row d-flex justify-content-between align-items-center">
          <div className="small-title mt-1">
            {t("patient.meds.edit_prescription")}
          </div>
          <div className="mt-1 flex-d-row">
            {prescriptionDetails.active && (
              <button
                type="button"
                onClick={() => onClickDelete()}
                className="small-button warning-button mr-1"
                disabled={isSaving}
              >
                {t("buttons.delete")}
              </button>
            )}
            <button
              onClick={() => setIsCancelModalOpen(true)}
              className="transparent-button small-button mr-1"
              disabled={isSaving}
            >
              <u>{t("buttons.cancel")}</u>
            </button>
            <div className="input-image-wrapper m-0">
              <button
                onClick={savePrescription}
                disabled={isSaving || !isAbleToSave}
                className={`secondary-button small-button ${
                  isSaving && "transparent"
                }`}
              >
                {t("buttons.save")}
              </button>
              {isSaving && <Spinner type={"small"} />}
            </div>
          </div>
        </div>
        <div className="mt-5 flex-d-column">
          <div className="input-label-text ">
            {t("patient.meds.activate_deactivate")}
          </div>
          <div className="regular-text  flex-d-row align-items-center">
            <span className="mr-1">{t("patient.meds.deactivate")}</span>
            <div className="form-check form-switch">
              <input
                disabled={isSaving}
                onChange={(e) =>
                  setPrescriptionDetails({
                    ...prescriptionDetails,
                    active: e.target.checked,
                  })
                }
                className="form-check-input shadow-none"
                checked={prescriptionDetails.active}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
            </div>
            <span className="ml-1">{t("patient.meds.activate")}</span>
          </div>
          <label className="input-label-text d-flex justify-content-between mt-3">
            <span>
              {t("patient.meds.prescription_name")}{" "}
              <span className="warning-color">{t("star")}</span>
              {prescriptionDetailsErrors.name && (
                <span className="warning-color">
                  {prescriptionDetailsErrors.name}
                </span>
              )}
            </span>
          </label>
          <input
            value={prescriptionDetails.name}
            disabled={isSaving}
            onChange={(e) =>
              setPrescriptionDetails({
                ...prescriptionDetails,
                name: e.target.value,
              })
            }
            placeholder={t("placeholders.prescription_name")}
            autoComplete="off"
            className={`form-input with-input-sizing ${
              prescriptionDetailsErrors.name ? "error" : ""
            }`}
            name="name"
            id="name"
          />
          <div className="check-box-wrapper mt-1">
            <div className="input-wrapper col-md-auto">
              <input
                disabled={isSaving}
                checked={prescriptionDetails.user_can_edit}
                onChange={(e) =>
                  setPrescriptionDetails({
                    ...prescriptionDetails,
                    user_can_edit: e.target.checked,
                  })
                }
                name="save_for_institution"
                type={"checkbox"}
                id="checkbox"
              />
            </div>

            <label type="checkbox" htmlFor="checkbox">
              <span className="regular-text">
                {t("patient.meds.allow_edit")}
              </span>
            </label>
          </div>
          <div>
            <button
              onClick={() => setIsAddMedicineModalOpen(true)}
              className="mt-5 small-button primary-button"
              disabled={!prescriptionDetails.active}
            >
              {t("buttons.add_med")}
            </button>
          </div>

          <div className={`regular-text mt-4`}>{t("patient.meds.meds")}</div>
          <div className="mt-2" disabled={!prescriptionDetails.active}>
            {loading ? (
              <div className="loading-wrapper mb-2">
                <Spinner type="big-blue" />
              </div>
            ) : (
              <MedicinesModulesList
                currentInstId={currentInstitutionId}
                medicines={prescriptionDetails.medicines}
                onDelete={() => {
                  getPrescriptions();
                  setHasEditedMedication(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <MessageModal
          headerText={t("modals.delete_prescription_header")}
          body={t("modals.delete_prescription_body")}
          footerButtons={[
            <button
              disabled={isDeleting}
              onClick={onCancelDelete}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                type="button"
                disabled={isDeleting}
                value={t("buttons.delete")}
                onClick={onConfirmDelete}
                className={`warning-button small-wider-button ${
                  isDeleting && "transparent"
                }`}
              />
              {isDeleting && <Spinner type={"small-warning"} />}
            </div>,
          ]}
        />
      )}
      {isCancelModalOpen && (
        <MessageModal
          headerText={t("modals.cancel_add_action_header")}
          body={t("modals.cancel_add_action_body")}
          footerButtons={[
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={goBackToPrescriptionList}
              className="small-button secondary-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {isAddMedicineModalOpen && (
        <AddMedicinesModal
          prescription={prescription_id}
          close={() => setIsAddMedicineModalOpen(false)}
          closeAndRefresh={refreshAndCloseMedicinesModal}
          patientId={patient_id}
          hasAddedMedication={setHasEditedMedication}
        />
      )}
    </div>
  );
};

export default EditPrescription;
