import {
  GET_PATIENT_CHAT_FAIL,
  GET_PATIENT_CHAT_SUCCESS,
} from "../actions/patients";

const initialState = [];

const patientChat = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_CHAT_SUCCESS: {
      if (action.payload.data.update) {
        let newChatArray = [...state];
        action.payload.data.data.forEach((date) => {
          const dateIndex = newChatArray.findIndex(
            (stateDate) => stateDate.date === date.date
          );
          if (dateIndex >= 0) {
            newChatArray[dateIndex].messages = date.messages.concat(
              newChatArray[dateIndex].messages
            );
          } else {
            newChatArray.unshift(date);
          }
        });
        return newChatArray;
      } else {
        return [...action.payload.data.data];
      }
    }
    case GET_PATIENT_CHAT_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientChat;
