//* ******************************** REACT IMPORTS ********************************
import React, { useEffect, useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//* *********************************** OUR COMPONENTS ***********************************
import { getTickets } from "redux/actions/tickets";
import DropdownOptions from "components/dropdownOptions";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import Navbar from "components/navbar";
import OptionsButton from "components/optionsButton";
import Paginator from "components/paginator";
import Spinner from "components/spinner";
import useSearch from "hooks/useSearch";
import NewSupportTicketModal from "components/newSupportTicketModal";

//* ****************************************** UTILS *****************************************
import { parseDate } from "utils/dataAndTime";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ***************************************** ASSETS *****************************************

// Query strings for ordering when it's implemented in the backend
const order = {
  ticketId: "id",
  ticketIdInv: "-id",
  subject: "title",
  subjectInv: "-title",
  openedDate: "creation_date",
  openedDateInv: "-creation_date",
  lastUpdated: "last_updated",
  lastUpdatedInv: "-last_updated",
  status: "support_state",
  statusInv: "-support_state",
};

const SupportOverview = (props) => {
  //* ************************************** SETUP **************************************
  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [isCreateTicketModalOpen, setIsCreateTicketModalOpen] = useState(false);
  const [isTicketsLoading, setIsTicketsLoading] = useState(true);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const tickets = useSelector((state) => state.tickets.tickets);

  useEffect(() => {
    getSupportTickets();
  }, [isCreateTicketModalOpen]);

  //* ************************************** HANDLERS **************************************

  const getSupportTickets = () => {
    setIsTicketsLoading(true);
    dispatch(
      getTickets({
        order_by: searchOptions.orderBy,
        items_per_page: tickets.items_per_page,
        page: searchOptions.page,
      })
    ).finally(() => {
      setIsTicketsLoading(false);
    });
  };

  const closeCreateTicketModal = () => {
    setIsCreateTicketModalOpen(false);
  };

  const onClickTicketHandler = (ticket_id) => () =>
    props.history.push(`/my_area/support/ticket/${ticket_id}/detail`);

  const setStatusLabelBackground = (state) => {
    return state === 1
      ? "primary-label"
      : state === 2
      ? "secondary-label"
      : state === 3
      ? "tertiary-label"
      : null;
  };

  useOnClickOutside([dropDownRef, optionsRef], () => setSelectedTicketId(null));

  //* ************************************** PARTIALS **************************************

  const _renderTicket = (ticket, index) => {
    return (
      <div
        className="support-overview-ticket-outer"
        onClick={onClickTicketHandler(ticket.id)}
      >
        <div className={`row support-overview-ticket-inner`}>
          <div className="col-3">
            <span className="support-overview-ticket-item">{ticket.id}</span>
          </div>
          <div className="col-3">
            <span className="support-overview-ticket-item">{ticket.title}</span>
          </div>
          <div className="col-2">
            <span className="support-overview-ticket-item">
              {ticket.creation_date
                ? parseDate(ticket.creation_date.split("T")[0])
                : ""}
            </span>
          </div>
          <div className="col-2">
            <span className="support-overview-ticket-item">
              {ticket.last_updated
                ? parseDate(ticket.last_updated.split("T")[0])
                : ""}
            </span>
          </div>
          <div className="col-2 support-overview-ticket-label-container">
            <div
              className={`label small-label primary-label rounded ${setStatusLabelBackground(
                ticket.support_state
              )}`}
            >
              {ticket.support_state === 1
                ? t("labels.opened")
                : ticket.support_state === 2
                ? t("labels.pending")
                : t("labels.closed")}
            </div>
            <div className="more-image-wrapper">
              <OptionsButton
                ref={(el) => (optionsRef.current[index] = el)}
                isSelected={selectedTicketId === ticket.id}
                onClick={(e) => {
                  e.stopPropagation();
                  selectedTicketId === ticket.id
                    ? setSelectedTicketId(null)
                    : setSelectedTicketId(ticket.id);
                }}
              />
              {selectedTicketId === ticket.id && (
                <DropdownOptions
                  ref={dropDownRef}
                  buttons={[
                    {
                      text: (
                        <span className="regular-text">
                          {t("buttons.view")}
                        </span>
                      ),
                      action: () => {
                        props.history.push(
                          `/my_area/support/ticket/${ticket.id}/detail`
                        );
                      },
                    },
                    {
                      text: (
                        <span className="regular-text">
                          {t("buttons.edit")}
                        </span>
                      ),
                      action: () => {
                        props.history.push(
                          `/my_area/support/ticket/${ticket.id}/edit`
                        );
                      },
                    },
                  ].filter(Boolean)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { searchOptions, updatePage, updateOrderBy } = useSearch(
    getSupportTickets,
    300
  );

  //* *************************************** RENDER ***************************************
  return (
    <>
      <div className="col profile-body">
        <Navbar />
        <div className="col d-flex">
          <div className="col body-wrapper">
            <div className="row support-overview-container">
              <h4>{t("titles.support_ticket")}</h4>
              <div className="col support-overview-content-container">
                <div className="row support-overview-subheader-container">
                  <div className="col">
                    <span className="text-semi-bold text bigger-normal base-color support-overview-subheader">
                      {t("support.tickets")}
                    </span>
                  </div>
                  <div className="col-auto support-overview-create-ticket-button-container">
                    <button
                      onClick={() => setIsCreateTicketModalOpen(true)}
                      className="primary-button small-wider-button"
                    >
                      <Icon
                        icon={icons["support-tickets-inverted"]}
                        className="button-icon"
                        alt="plus-icon"
                      />
                      {t("buttons.create_new_ticket")}
                    </button>
                  </div>
                </div>
                <div className="row support-overview-labels-container">
                  <div className="col-3 d-flex align-items-center">
                    <div className="list-label">{t("support.ticket_id")}</div>
                    <div
                      className={`order-img-container ${
                        searchOptions.orderBy === order.ticketId ||
                        searchOptions.orderBy === order.ticketIdInv
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        updateOrderBy(
                          searchOptions.orderBy === order.ticketId
                            ? order.ticketIdInv
                            : order.ticketId
                        )
                      }
                    >
                      {searchOptions.orderBy !== order.ticketId && (
                        <Icon icon={icons["chevron-up"]} alt="Sort by id" />
                      )}
                      {searchOptions.orderBy !== order.ticketIdInv && (
                        <Icon
                          icon={icons["chevron-down"]}
                          alt="Sort by id descending"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-3 d-flex align-items-center">
                    <div className="list-label">{t("support.subject")}</div>
                    <div
                      className={`order-img-container ${
                        searchOptions.orderBy === order.subject ||
                        searchOptions.orderBy === order.subjectInv
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        updateOrderBy(
                          searchOptions.orderBy === order.subject
                            ? order.subjectInv
                            : order.subject
                        )
                      }
                    >
                      {searchOptions.orderBy !== order.subject && (
                        <Icon
                          icon={icons["chevron-up"]}
                          alt="Sort by subject"
                        />
                      )}
                      {searchOptions.orderBy !== order.subjectInv && (
                        <Icon
                          icon={icons["chevron-down"]}
                          alt="Sort by subject descending"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <div className="list-label">{t("support.opened_date")}</div>
                    <div
                      className={`order-img-container ${
                        searchOptions.orderBy === order.openedDate ||
                        searchOptions.orderBy === order.openedDateInv
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        updateOrderBy(
                          searchOptions.orderBy === order.openedDate
                            ? order.openedDateInv
                            : order.openedDate
                        )
                      }
                    >
                      {searchOptions.orderBy !== order.openedDate && (
                        <Icon
                          icon={icons["chevron-up"]}
                          alt="Sort by Opened date"
                        />
                      )}
                      {searchOptions.orderBy !== order.openedDateInv && (
                        <Icon
                          icon={icons["chevron-down"]}
                          alt="Sort by opened Date descending"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <div className="list-label">
                      {t("support.last_updated")}
                    </div>
                    <div
                      className={`order-img-container ${
                        searchOptions.orderBy === order.lastUpdated ||
                        searchOptions.orderBy === order.lastUpdatedInv
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        updateOrderBy(
                          searchOptions.orderBy === order.lastUpdated
                            ? order.lastUpdatedInv
                            : order.lastUpdated
                        )
                      }
                    >
                      {searchOptions.orderBy !== order.lastUpdated && (
                        <Icon
                          icon={icons["chevron-up"]}
                          alt="Sort by Last updated"
                        />
                      )}
                      {searchOptions.orderBy !== order.lastUpdatedInv && (
                        <Icon
                          icon={icons["chevron-down"]}
                          alt="Sort by Last updated descending"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-2 d-flex align-items-center">
                    <div className="list-label">{t("support.status")}</div>
                    <div
                      className={`order-img-container ${
                        searchOptions.orderBy === order.status ||
                        searchOptions.orderBy === order.statusInv
                          ? "active"
                          : ""
                      }`}
                      onClick={() =>
                        updateOrderBy(
                          searchOptions.orderBy === order.status
                            ? order.statusInv
                            : order.status
                        )
                      }
                    >
                      {searchOptions.orderBy !== order.status && (
                        <Icon icon={icons["chevron-up"]} alt="Sort by Status" />
                      )}
                      {searchOptions.orderBy !== order.statusInv && (
                        <Icon
                          icon={icons["chevron-down"]}
                          alt="Sort by status descending"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {isTicketsLoading ? (
                  <div style={{ height: "100px" }}>
                    <div className="loading-wrapper">
                      <Spinner type={"big-blue"} />
                    </div>
                  </div>
                ) : tickets.count !== 0 ? (
                  <ul className="row support-overview-tickets-list d-flex">
                    {tickets.obj_list.map((ticket, index) => (
                      <li key={`ticket ${ticket.id}`}>
                        {_renderTicket(ticket, index)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex-d-column no-data-text">
                    <span>{t("support.no_tickets")}</span>
                  </div>
                )}
                {tickets.obj_list.length > 0 && !isTicketsLoading && (
                  <div className="pagination-wrapper">
                    <Paginator
                      currPage={tickets.page}
                      totalPages={tickets.num_pages}
                      maxPages={3}
                      changePage={updatePage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCreateTicketModalOpen && (
        <NewSupportTicketModal onClose={() => closeCreateTicketModal()} />
      )}
    </>
  );
};

export default SupportOverview;
