import React from "react";
import { useEffect, useState, useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import FileUpload from "../../../../../components/fileUpload";
import LicenseCard from "../../../../../components/licenseCard";
import DropDownInputBox from "../../../../../components/dropDownInputBox";
import Spinner from "../../../../../components/spinner";
import MessageModal from "../../../../../components/messageModal";

import { useTranslation } from "react-i18next";
import search from "../../../../../assets/images/search.svg";
import fileDownload from "../../../../../assets/images/file-download.svg";
import crossIcon from "../../../../../assets/images/home/close.svg";
import errorIcon from "../../../../../assets/images/toast/error.svg";
import { geti18nLanguage } from "utils/language";

const GroupPatientsImport = ({
  dispatchFunction = "",
  isCliniciansInfoLoading = "",
  setImportData = {},
  setFileData = {},
  isLoading = false,
  error = "",
  setError,
  isProviderTab = false,
}) => {
  const { t, i18n } = useTranslation();
  const textAreaRef = useRef(null);

  const [checkedRadioButtonIndex, setCheckedRadioButtonIndex] = useState(0);
  const [autoRenew, setAutoRenew] = useState(false);
  const [patientsUploadMethod, setPatientsUploadMethod] = useState("email");
  const [selectedClinician, setSelectedClinician] = useState();
  const [textAreaEmails, setTextAreaEmails] = useState([]);

  const [files, setFiles] = useState({});

  const licenseData = useSelector((state) => state.licenseHandler.licenseType);
  const clinicians = useSelector((state) => state.licenseHandler.clinicians);
  const userId = useSelector((state) => state.user.widget_info.userinfo_id);

  const radioButtonOption = {
    text: t("my_area.mKinetikos.select"),
    onClickText: t("my_area.mKinetikos.selected"),
  };

  const DropDownConfig = {
    show: "name",
    set: "username",
    dispatchFunction: dispatchFunction,
    isLoading: isCliniciansInfoLoading,
    selector: clinicians,
  };

  const preSetDropdown = clinicians.filter(
    (clinician) => clinician.id === userId
  );

  const onChangeHandler = (e) => {
    setTextAreaEmails(e.target.value);
  };

  const downloadTemplate = () => {
    const basePath = `${process.env.REACT_APP_BACKEND_HOST}/static/cdss/guides/csv/`;
    const languageSuffix = geti18nLanguage() === "pt-PT" ? "_pt" : "_en";
    const templateType = isProviderTab
      ? "providers_mkinetikos_accounts"
      : "use_multiple_licenses_template";

    const url = `${basePath}${templateType}${languageSuffix}.csv`;
    return window.open(url);
  };

  const onSubmitHandler = (ignoreFailed = false) => {
    if (patientsUploadMethod === "email" && !isProviderTab) {
      if (textAreaEmails) {
        setImportData({
          emails_list: textAreaEmails.split(","),
          clinician_username: selectedClinician,
          auto_renew: autoRenew,
          mkinetikos_license_type_id: checkedRadioButtonIndex,
          ignore_failed: ignoreFailed,
        });
      }
    } else {
      const formData = new FormData();

      formData.append("file", files["file"]);
      formData.append("ignore_failed", JSON.stringify(ignoreFailed));

      setFileData(formData);
    }
  };

  const resetForm = () => {
    setAutoRenew(false);
    setTextAreaEmails("");
    setCheckedRadioButtonIndex(null);
    setSelectedClinician("");
    setFiles({});
  };

  const isButtonDisable =
    (textAreaEmails && selectedClinician && checkedRadioButtonIndex) ||
    files["file"];

  const renderUploadArea = () => {
    return (
      <>
        <span
          className='link-text-regular text-right primary-normal-color mb-1'
          onClick={() => {
            downloadTemplate();
          }}
        >
          {t("my_area.mKinetikos.download_template")}{" "}
          <img alt='Download Template' src={fileDownload} />
        </span>

        <FileUpload
          updateFilesCb={(files) => {
            setFiles(files);
          }}
          isDisable={isLoading}
          acceptFile={[".csv"]}
          reset={files}
          errors={
            error &&
            error.hasOwnProperty("failed_licenses") &&
            error["failed_licenses"]["not_enought_licenses"]
              ? t("my_area.mKinetikos.not_enough_licenses")
              : !error?.hasOwnProperty("failed_licenses")
              ? error
              : t("authentication.error.something_wrong")
          }
        />
      </>
    );
  };

  const renderSubmitButtons = () => {
    return (
      <div className='box-footer mt-2'>
        <button
          className='small-button transparent-button'
          onClick={() => resetForm()}
        >
          <u>{t("buttons.cancel")}</u>
        </button>
        <button
          className='small-button secondary-button false'
          onClick={() => onSubmitHandler()}
          disabled={isLoading ? true : !isButtonDisable}
        >
          {isLoading && (
            <div className='position-relative button-container'>
              <Spinner type='small' />
            </div>
          )}
          {!isLoading && t(isProviderTab ? "buttons.save" : "buttons.import")}
        </button>
      </div>
    );
  };

  return (
    <div className='row mt-2'>
      {isProviderTab ? (
        <div className='col-6 license-body'>
          <div className='border-box' disabled={isLoading}>
            <div className='file-upload'>
              <div className='row center-align'>
                <div className=''>{t("my_area.mKinetikos.upload_file")}</div>
                {renderUploadArea()}
                {renderSubmitButtons()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='col-6 license-body'>
          <div className='border-box' disabled={isLoading}>
            <div className='file-upload'>
              <div className='row center-align'>
                <div className='mb-2'>
                  1.{" "}
                  <span className='tabs'>
                    <span
                      className={
                        patientsUploadMethod === "email" ? "tab-active" : ""
                      }
                      onClick={() => {
                        setPatientsUploadMethod("email");
                        setFiles("");
                      }}
                    >
                      {t("my_area.mKinetikos.email_list")}
                    </span>{" "}
                    <span
                      className={
                        patientsUploadMethod === "file" ? "tab-active" : ""
                      }
                      onClick={() => {
                        setPatientsUploadMethod("file");
                      }}
                    >
                      {t("my_area.mKinetikos.upload_file")}
                    </span>
                  </span>
                </div>
                {patientsUploadMethod === "email" ? (
                  <>
                    <div className='input-label-text d-flex justify-content-between'>
                      <span></span>
                      <span className='warning-color'>
                        {error && !error.hasOwnProperty("failed_licenses")
                          ? error
                          : error?.hasOwnProperty("failed_licenses") &&
                            error["failed_licenses"]?.hasOwnProperty(
                              "not_enought_licenses"
                            ) &&
                            t("my_area.mKinetikos.not_enough_licenses")}
                      </span>
                    </div>
                    <textarea
                      disabled={isLoading}
                      as='textarea'
                      rows='6'
                      autoComplete='off'
                      value={textAreaEmails}
                      className={`form-input`}
                      name='emails'
                      onChange={(e) => onChangeHandler(e)}
                      placeholder={t(
                        "my_area.mKinetikos.separate_emails_comma"
                      )}
                      ref={textAreaRef}
                    />
                  </>
                ) : (
                  renderUploadArea()
                )}
              </div>
            </div>
            {patientsUploadMethod === "email" && (
              <>
                <div className='license-offers mt-3'>
                  2. {t("my_area.mKinetikos.license_type")}
                  <div className='row'>
                    {licenseData && licenseData.length > 0 ? (
                      licenseData.map((value) => {
                        return (
                          <LicenseCard
                            key={value.mkinetikos_license_type}
                            headerText={
                              value.mkinetikos_license_type__duration +
                              " " +
                              t("my_area.mKinetikos.months")
                            }
                            minValue={value.available}
                            maxValue={value.total}
                            index={value.mkinetikos_license_type}
                            activeIndex={checkedRadioButtonIndex}
                            checkedRadioButtonIndex={checkedRadioButtonIndex}
                            radioButtonOption={radioButtonOption}
                            setCheckedRadioButtonIndex={
                              setCheckedRadioButtonIndex
                            }
                            isRadioButton={true}
                          />
                        );
                      })
                    ) : (
                      <span className='regular-text p-3'>
                        {t("my_area.mKinetikos.license_type_not_found")}
                      </span>
                    )}
                  </div>
                </div>
                <div className='auto-renew mt-3'>
                  3. {t("my_area.mKinetikos.auto_renew")}
                  {/* slide button */}
                  <div className='regular-text mt-2 flex-d-row align-items-center'>
                    <span className='mr-1'>{t("no")}</span>
                    <div className='form-check form-switch'>
                      <input
                        disabled={false}
                        className='form-check-input shadow-none'
                        checked={autoRenew}
                        onChange={() => {
                          setAutoRenew(!autoRenew);
                        }}
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckChecked'
                      />
                    </div>
                    <span className='ml-1'>{t("yes")}</span>
                  </div>
                  {/* end */}
                </div>
                <div className='col-6 search-clinician mt-3'>
                  4. {t("my_area.mKinetikos.patients_clinician")}
                  <DropDownInputBox
                    leftImage={search}
                    placeholder={t("placeholders.clinician")}
                    config={DropDownConfig}
                    getSelectedText={(value) => setSelectedClinician(value)}
                    reset={selectedClinician}
                    preSetValue={preSetDropdown}
                  />
                </div>
              </>
            )}
            {renderSubmitButtons()}
          </div>
        </div>
      )}

      {/* Right side:  */}
      <div className='col-1'></div>
      <div className='col-3 licenses-left'>
        <div className='small-title mb-2'>
          {t("my_area.mKinetikos.available_licenses")}
        </div>
        <div className='license-body'>
          <div className='border-box'>
            {licenseData && licenseData.length > 0 ? (
              licenseData.map((value) => {
                return (
                  <LicenseCard
                    key={value.mkinetikos_license_type}
                    headerText={
                      value.mkinetikos_license_type__duration +
                      " " +
                      t("my_area.mKinetikos.months")
                    }
                    minValue={value.available}
                    maxValue={value.total}
                    index={value.mkinetikos_license_type}
                  />
                );
              })
            ) : (
              <span className='regular-text'>
                {t("my_area.mKinetikos.license_type_not_found")}
              </span>
            )}
          </div>
        </div>
      </div>
      {error &&
        error.hasOwnProperty("failed_licenses") &&
        !error["failed_licenses"].hasOwnProperty(
          "license_type_does_not_exist"
        ) &&
        (error["failed_licenses"].hasOwnProperty("bad_email") ||
          error["failed_licenses"].hasOwnProperty("user_already_premium")) && (
          <MessageModal
            headerText={
              <div className='row'>
                <img src={errorIcon} className='col-1 email-validation-img' />
                <div className='col row-space-between p-1'>
                  <span>{t("my_area.mKinetikos.err_title")}</span>
                  <img
                    className='cursor-pointer'
                    src={crossIcon}
                    alt='close pointer'
                    onClick={() => setError(false)}
                  />
                </div>
                <div className='hr-border-base'></div>
              </div>
            }
            body={
              <>
                {"bad_email" in error["failed_licenses"] && (
                  <div className='email-validation'>
                    <span>{t("my_area.mKinetikos.no_email")}</span>
                    {error["failed_licenses"]["bad_email"].map(
                      (email, index) => {
                        return (
                          <div key={index} className='col email-list'>
                            {email}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {"user_already_premium" in error["failed_licenses"] && (
                  <div className='email-validation'>
                    <span>{t("my_area.mKinetikos.already_premium")}</span>
                    {error["failed_licenses"]["user_already_premium"].map(
                      (email, index) => {
                        return (
                          <div key={index} className='col email-list'>
                            {email}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </>
            }
            footerButtons={[
              <button
                onClick={() => {
                  if (patientsUploadMethod === "email") {
                    textAreaRef.current.focus();
                    setError(null);
                  } else {
                    setError(null);
                    setFileData(null);
                  }
                }}
                className='button small-button primary-button'
              >
                {patientsUploadMethod === "email"
                  ? t("buttons.edit_emails")
                  : "cancel"}
              </button>,
              <button
                onClick={() => {
                  onSubmitHandler(true);
                }}
                className='button small-button tertiary-button'
                disabled={isLoading}
              >
                {isLoading && (
                  <div className='position-relative loading-container-btn'>
                    <Spinner type='small-blue' />
                  </div>
                )}
                {!isLoading && t("my_area.mKinetikos.btn_invite_without")}
              </button>,
            ]}
          />
        )}
    </div>
  );
};

export default GroupPatientsImport;
