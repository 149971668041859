//* *********************************** REACT IMPORTS ************************************
import React, { useEffect } from "react";
import { useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../../components/spinner";
import AddModule from "../../../../components/evaluationTemplates/addModule";
import CurrModules from "../../../../components/evaluationTemplates/currModules";
import SaveTemplateModal from "../../../../components/evaluationTemplates/saveTemplateModal";
import MessageModal from "../../../../components/messageModal";
//* ************************************** GLOBALS ***************************************
import {
  getEvaluationTemplate,
  editNewEvaluationTemplate,
  markTemplateAsFavourite,
  addNewEvaluationTemplate,
  removeFromTemplates,
} from "../../../../redux/actions/evaluationTemplates";

import favourite_blue from "../../../../assets/images/star-blue.svg";
import favourite_white from "../../../../assets/images/star-white.svg";

import edit_blue from "../../../../assets/images/edit-blue.svg";
import clinical_data from "../../../../assets/images/clinical-data.svg";
import questionnaries from "../../../../assets/images/questionnaries.svg";
import treatments from "../../../../assets/images/treatments.svg";
import kinematic_tests from "../../../../assets/images/kinematic-tests.svg";
import big_arrow_left from "../../../../assets/images/big-left-arrow.svg";

import { moduleTypes } from "../../../../constants/general";
import NavBar from "../../../../components/navbar";
const EditEvaluationTemplates = (props) => {
  const dispatch = useDispatch();

  const [currSelectedModuleType, setCurrSelectedModuleType] = useState(null);

  const [isMarkedAsFavourite, setIsMarkedAsFavourite] = useState(false);
  const [modules, setModules] = useState([]);
  const [presetModules, setPresetModules] = useState([]);
  const [evaluationTemplateTitle, setEvaluationTemplateTitle] = useState("");
  const [presetEvaluationTemplateTitle, setPresetEvaluationTemplateTitle] =
    useState("");
  const [isOwnerInstitution, setIsOwnerInstitution] = useState(false);

  const [isSaveNewTemplateOpen, setIsSaveNewTemplateOpen] = useState(false);
  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);

  const [isSavingCurrentTemplate, setIsSavingCurrentTemplate] = useState(false);
  const [isSavingToInstitution, setIsSavingToInstitution] = useState(false);
  const [isSavingToUser, setIsSavingToUser] = useState(false);

  const { id } = useParams();

  const { t, i18n } = useTranslation();

  const titleInputRef = useRef(null);
  const markAsFavouriteImage = useRef(null);

  const currModuleTypeList = useSelector(
    (state) => state.evaluationTemplates.module_type_list
  );

  //* ************************************** HANDLERS **************************************
  useEffect(() => {
    dispatch(getEvaluationTemplate(id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          const { modules, template } = res.payload.data.data;
          setModules([...modules]);
          setEvaluationTemplateTitle(template.name);
          setIsMarkedAsFavourite(!template.is_favorite);
          setIsOwnerInstitution(template.is_owner_institution);

          setPresetModules([...modules]);
          setPresetEvaluationTemplateTitle(template.name);
        }
      })
      .catch(() => alert("error"));
  }, []);

  const inputsHandler = (e) => {
    setEvaluationTemplateTitle(e.target.value);
  };

  const saveCurrentEvaluationTemplate = () => {
    setIsSavingCurrentTemplate(true);
    const template_ids = modules.map((module) => module.id);
    dispatch(
      editNewEvaluationTemplate(
        id,
        template_ids,
        evaluationTemplateTitle,
        isOwnerInstitution
      )
    )
      .then(() => {
        props.history.replace("/my_area/evaluation_templates");
      })
      .catch(() =>
        alert(
          "It was not possible to edit the template at this moment. Please try again later or contact support centre if the problem persists"
        )
      )
      .finally(() => setIsSavingCurrentTemplate(false));
  };

  const saveNewEvaluationTemplate = (is_owner_institution) => {
    is_owner_institution
      ? setIsSavingToInstitution(true)
      : setIsSavingToUser(true);
    const template_ids = modules.map((module) => module.id);
    dispatch(
      addNewEvaluationTemplate(
        template_ids,
        evaluationTemplateTitle,
        is_owner_institution
      )
    )
      .then(() => {
        props.history.replace("/my_area/evaluation_templates");
      })
      .catch(() =>
        alert(
          "It was not possible to edit the template at this moment. Please try again later or contact support centre if the problem persists"
        )
      )
      .finally(() =>
        is_owner_institution
          ? setIsSavingToInstitution(false)
          : setIsSavingToUser(false)
      );
  };

  const removeTemplate = () => {
    dispatch(removeFromTemplates(id))
      .then(() => {
        goBack();
        setIsDeleteTemplateModalOpen(false);
        console.log("sucess");
      })
      .catch(() => {
        alert("Error, something went wrong with your request");
      });
  };

  const getCurrentModuleTypeImage = (image) => {
    if (image === moduleTypes.clinicalData || image === 1) return clinical_data;
    else if (image === moduleTypes.intervention || image === 2)
      return treatments;
    else if (image === moduleTypes.kinematicTest || image === 4)
      return kinematic_tests;
    else if (image === moduleTypes.questionnarie || image === 3)
      return questionnaries;
    return null;
  };

  const addModule = (module) => {
    setModules([module, ...modules]);
  };

  const deleteModule = (moduleIndex) => {
    const newModules = [...modules];
    newModules.splice(moduleIndex, 1);
    setModules(newModules);
  };

  const isSaveDisabled =
    (JSON.stringify(presetModules) !== JSON.stringify(modules) ||
      JSON.stringify(presetEvaluationTemplateTitle) !==
        JSON.stringify(evaluationTemplateTitle) ||
      evaluationTemplateTitle === "" ||
      isSavingCurrentTemplate) &&
    modules.length > 0;

  const goBack = () => {
    if (props.location.cameFromEvaluationTemplateHome) {
      props.history.goBack();
    } else {
      props.history.replace("/my_area/evaluation_templates");
    }
  };

  const markFavorite = () => {
    setIsMarkedAsFavourite(!isMarkedAsFavourite);
    dispatch(markTemplateAsFavourite(id))
      .then(() => {
        console.log("success");
      })
      .catch(() => {
        console.log("error");
      });
  };
  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar />
      <div className="flex-d-row align-items-center">
        <img
          onClick={() => props.history.replace("/my_area/evaluation_templates")}
          alt="left arrow"
          className="left-arrow"
          src={big_arrow_left}
        />
        <h4 className="m-0">{t("my_area.templates.edit")}</h4>
      </div>
      <div className="profile_create_edit_templates">
        <div className="row row-space-between">
          <div className="col-auto">
            <div className="input-title-wrapper image-right">
              <input
                type="text"
                ref={titleInputRef}
                autoComplete="off"
                value={evaluationTemplateTitle}
                className="form-input-3 w-100"
                onChange={inputsHandler}
              />
              <img
                onClick={() => titleInputRef.current.focus()}
                className="edit-input"
                alt="Edit blue"
                src={edit_blue}
              />
            </div>
          </div>
          <div className="col-auto flex-d-row align-items-center">
            <button
              onMouseOver={() =>
                !isMarkedAsFavourite &&
                (markAsFavouriteImage.current.src = favourite_white)
              }
              onMouseLeave={() =>
                !isMarkedAsFavourite &&
                (markAsFavouriteImage.current.src = favourite_blue)
              }
              onClick={markFavorite}
              className={`button small-button mr-1 ${
                isMarkedAsFavourite ? "marked-button" : "tertiary-button"
              }`}
            >
              <img
                ref={markAsFavouriteImage}
                alt="plus-icon"
                className="button-icon"
                src={isMarkedAsFavourite ? favourite_white : favourite_blue}
              />
              {isMarkedAsFavourite
                ? t("my_area.templates.mark_favourite")
                : t("my_area.templates.unmark_favourite")}
            </button>

            <button
              disabled={isSavingCurrentTemplate}
              onClick={() => setIsDeleteTemplateModalOpen(true)}
              className="small-wider-button warning-button mr-1"
            >
              {t("buttons.delete")}
            </button>
          </div>

          <div className="save-cancel-wrapper">
            <button
              disabled={isSavingCurrentTemplate}
              onClick={goBack}
              className="small-wider-button transparent-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>
            <button
              onClick={() => setIsSaveNewTemplateOpen(true)}
              disabled={!isSaveDisabled}
              className="button small-button 
               tertiary-button"
            >
              {t("buttons.save_as_new_template")}
            </button>
            <div className="input-image-wrapper m-0">
              <input
                disabled={!isSaveDisabled}
                value={t("buttons.save_template")}
                onClick={saveCurrentEvaluationTemplate}
                type="submit"
                className={`small-wider-button secondary-button ${
                  isSavingCurrentTemplate && "transparent"
                }`}
              />
              {isSavingCurrentTemplate && <Spinner type={"small"} />}
            </div>
          </div>
        </div>

        <div className="row modules-body">
          <AddModule
            currModuleTypeList={currModuleTypeList}
            setCurrSelectedModuleType={(type) =>
              setCurrSelectedModuleType(type)
            }
            currSelectedModuleType={currSelectedModuleType}
            getCurrentModuleTypeImage={(image) =>
              getCurrentModuleTypeImage(image)
            }
            addModule={(module) => addModule(module)}
            addedModuleIDs={modules.map((module) => module.id)}
          />
          <CurrModules
            deleteModule={(moduleId) => deleteModule(moduleId)}
            modules={modules}
            getCurrentModuleTypeImage={(image) =>
              getCurrentModuleTypeImage(image)
            }
          />
        </div>
      </div>
      <>
        {isSaveNewTemplateOpen && (
          <SaveTemplateModal
            saveNewEvaluationTemplate={saveNewEvaluationTemplate}
            cancel={() => setIsSaveNewTemplateOpen(false)}
            isSavingToUser={isSavingToUser}
            isSavingToInstitution={isSavingToInstitution}
          />
        )}
        {isDeleteTemplateModalOpen && (
          <MessageModal
            headerText={t("my_area.templates.delete")}
            body={t("my_area.templates.delete_confirm")}
            footerButtons={[
              <button
                onClick={() => setIsDeleteTemplateModalOpen(false)}
                className="transparent-button small-button"
              >
                <u> {t("buttons.cancel")} </u>
              </button>,
              <button
                onClick={() => {
                  removeTemplate();
                }}
                className="small-button warning-button"
              >
                {t("buttons.delete")}
              </button>,
            ]}
          />
        )}
      </>
    </div>
  );
};

export default React.memo(EditEvaluationTemplates);
