export const GET_READ_NOTIFICATION_LIST = "GET_READ_NOTIFICATION_LIST";
export const GET_READ_NOTIFICATION_LIST_SUCCESS =
  "GET_READ_NOTIFICATION_LIST_SUCCESS";

export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";

export const GET_UNREAD_NOTIFICATION_LIST = "GET_UNREAD_NOTIFICATION_LIST";
export const GET_UNREAD_NOTIFICATION_LIST_SUCCESS =
  "GET_UNREAD_NOTIFICATION_LIST_SUCCESS";

export const GET_NEW_NOTIFICATION_COUNT = "GET_NEW_NOTIFICATION_COUNT";
export const GET_NEW_NOTIFICATION_COUNT_SUCCESS =
  "GET_NEW_NOTIFICATION_COUNT_SUCCESS";
export const GET_NEW_NOTIFICATION_COUNT_FAIL =
  "GET_NEW_NOTIFICATION_COUNT_FAIL";

export const GET_READ_NOTIFICATION_BY_INSTITUTION_ID =
  "GET_READ_NOTIFICATION_BY_INSTITUTION_ID";

export const MARK_AS_READ = "MARK_AS_READ";
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";

export const MARK_AS_ARCHIVE = "MARK_AS_ARCHIVE";
export const MARK_AS_ARCHIVE_SUCCESS = "MARK_AS_ARCHIVE_SUCCESS";

export const GET_NOTIFICATION_BY_ID = "GET_NOTIFICATION_BY_ID";
export const GET_NOTIFICATION_BY_ID_SUCCESS = "GET_NOTIFICATION_BY_ID_SUCCESS";

export const RESET_STORE = "RESET_STORE";

export const getNewNotificationCount = (
  { signal = null } = { signal: null }
) => {
  return {
    type: GET_NEW_NOTIFICATION_COUNT,
    payload: {
      request: {
        method: "get",
        url: "cdss/notifications/unread_count/",
        signal: signal,
      },
    },
  };
};

export const setNotificationsCount = (notificationsCount) => ({
  type: SET_NOTIFICATIONS_COUNT,
  payload: notificationsCount,
});

export const getReadNotificationList = (data) => ({
  type: GET_READ_NOTIFICATION_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/notifications/`,
      params: data,
    },
  },
});

export const getUnreadNotificationList = (data) => ({
  type: GET_UNREAD_NOTIFICATION_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/notifications/`,
      params: data,
    },
  },
});

export const getReadNotificationByInstitutionId = (institution_id) => ({
  type: GET_READ_NOTIFICATION_BY_INSTITUTION_ID,
  payload: {
    request: {
      method: "get",
      url: `/cdss/notifications/`,
      params: { new: true, institution_id: institution_id },
    },
  },
});

export const markAsRead = (data, isRead) => ({
  type: MARK_AS_READ,
  payload: {
    request: {
      method: "PATCH",
      url: `cdss/notifications/read/`,
      data: { notification_ids: data, read: isRead },
    },
  },
});

export const markAsArchive = (data, isArchive) => ({
  type: MARK_AS_ARCHIVE,
  payload: {
    request: {
      method: "PATCH",
      url: `cdss/notifications/archived/`,
      data: { notification_ids: data, archived: isArchive },
    },
  },
});

export const resetNotifications = () => ({
  type: RESET_STORE,
});
