import React from "react";
import Slider from "rc-slider";

const fingerTappingMarks = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
};

const FingerTappingSlider = ({ value, onChange, disabled = false }) => (
  <div
    style={{ width: "400px", marginLeft: "10px" }}
    className="regular-text mt-4 mb-2"
  >
    <Slider
      disabled={disabled}
      min={0}
      max={8}
      marks={fingerTappingMarks}
      onChange={(value) => onChange("mds_fingertapping_th", value)}
      step={null}
      dots={false}
      value={value}
      defaultValue={0}
      trackStyle={{ backgroundColor: "#EFF3F6", height: "4px" }}
      railStyle={{ backgroundColor: "#508CC0", height: "4px" }}
      dotStyle={{
        backgroundColor: "#508CC0",
        borderRadius: 0,
        height: "10px",
        top: -3,
        width: "2px",
        borderColor: "#508CC0",
      }}
      activeDotStyle={{ borderColor: "#508CC0" }}
      handleStyle={{
        borderColor: "#508CC0",
        width: 20,
        height: 20,
        marginTop: -8,
        boxShadow: "none",
      }}
    />
  </div>
);

export default React.memo(FingerTappingSlider);
