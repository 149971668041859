//* ********************************** REACT IMPORTS **********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

const CustomMessage = ({ type, changeToLogin, extraDesign }) => {
  const { t, i18n } = useTranslation();

  const messageTypes = {
    checkEmailOpen: {
      title: t("authentication.reset_password.email_sent"),
      body: t("authentication.reset_password.email_sent_message"),
    },

    successResetPassword: {
      title: t("authentication.reset_password.success"),
      body: t("authentication.reset_password.success_message"),
    },

    successSignUp: {
      title: t("authentication.signup.request_sent_title"),
      body: t("authentication.signup.request_sent_message"),
    },

    accountActivated: {
      title: t("authentication.signup.congratulation"),
      body: t("authentication.signup.congratulation_msg"),
    },

    activateAccount: {
      title: t("authentication.login.activate_account"),
      body: t("authentication.login.activate_account_msg"),
    },
  };
  //* ************************************** HANDLERS **************************************

  const backToLogin = (e) => {
    e.preventDefault();
    changeToLogin();
  };

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <h2>{messageTypes[type].title}</h2>
      <p
        style={{ marginTop: "1.44em", marginBottom: "3.44em" }}
        className="regular-text"
      >
        {messageTypes[type].body}
      </p>

      {changeToLogin && (
        <div className="row">
          <div className="col">
            <input
              value={t("buttons.back_to_login")}
              onClick={backToLogin}
              type="submit"
              className="primary-button small-button w-100"
            />
          </div>
        </div>
      )}

      {extraDesign && extraDesign}
    </div>
  );
};

export default CustomMessage;
