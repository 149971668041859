export const GET_PRESCRIPTION_LIST = "GET_PRESCRIPTION_LIST";
export const GET_PRESCRIPTION_LIST_SUCCESS = "GET_PRESCRIPTION_LIST_SUCCESS";
export const GET_PRESCRIPTION_LIST_FAIL = "GET_PRESCRIPTION_LIST_FAIL";

export const GET_PRESCRIPTION_DETAILS = "GET_PRESCRIPTION_DETAILS";
export const GET_PRESCRIPTION_DETAILS_SUCCESS =
  "GET_PRESCRIPTION_DETAILS_SUCCESS";
export const GET_PRESCRIPTION_DETAILS_FAIL = "GET_PRESCRIPTION_DETAILS_FAIL";

export const CREATE_PRESCRIPTION = "CREATE_PRESCRIPTION";
export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_FAIL = "CREATE_PRESCRIPTION_FAIL";

export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";
export const UPDATE_PRESCRIPTION_FAIL = "UPDATE_PRESCRIPTION_FAIL";

export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const DELETE_PRESCRIPTION_SUCCESS = "DELETE_PRESCRIPTION_SUCCESS";
export const DELETE_PRESCRIPTION_FAIL = "DELETE_PRESCRIPTION_FAIL";

export const getPrescriptionsList = (
  patient_id,
  { page = 1, q = "", order_by = "", items_per_page = 10, filter_type = "" }
) => ({
  type: GET_PRESCRIPTION_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/`,
      params: { page, q, order_by, items_per_page, filter_type },
    },
  },
});

export const getPrescriptionsDetails = (
  patient_id,
  prescription_id,
  active_only = false
) => ({
  type: GET_PRESCRIPTION_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}`,
      params: { active_only },
    },
  },
});

export const createPrescription = (patient_id, data) => ({
  type: CREATE_PRESCRIPTION,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/`,
      data: data,
    },
  },
});

export const updatePrescription = (
  patient_id,
  prescription_id,
  data,
  active_only = false
) => ({
  type: UPDATE_PRESCRIPTION,
  payload: {
    request: {
      method: "put",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}/`,
      data: data,
      params: { active_only },
    },
  },
});

export const deletePrescription = (patient_id, prescription_id) => ({
  type: DELETE_PRESCRIPTION,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}`,
    },
  },
});
