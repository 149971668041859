//* *********************************** REACT IMPORTS ************************************
import { memo, useEffect, useRef, useState } from "react";
//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import ProgressBar from "../progressBar";
//* ************************************** GLOBALS ***************************************
import UploadFilesIcon from "../../assets/images/upload-image.svg";

/**
 * FileUpload component props can take:
 *  - title
 *  - maxSize (in byte), default value = 500000 or 5Mb
 *  - A call back function, using this function mother component can access the uploaded file. it should be a set state variable
 *  - isMultiple, if it is true this component will allow to upload multiple files.
 *  - acceptFile, will take a list of file extensions and only allow that extension file to upload.
 *    ex: acceptFile={[".csv", ".png", ".text"]}
 */

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUpload = ({
  title = "",
  maxSize = 500000,
  updateFilesCb,
  isMultiple = false,
  acceptFile = ["*.*"],
  isDisable = false,
  reset,
  errors = "",
}) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState("");
  const isButtonOrInputDisable = true;
  const [files, setFiles] = useState({});

  useEffect(() => {
    if (!reset["file"]) {
      removeAll();
    }
  }, [reset]);

  const filesInputRef = useRef(null);

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleUploadBtnClick = () => {
    filesInputRef.current.click();
  };

  const addNewFiles = (NewFiles) => {
    for (let file of NewFiles) {
      console.log(file.name.split(".").pop());
      if (acceptFile.includes("." + file.name.split(".").pop())) {
        if (file.size < maxSize) {
          setFileError("");
          if (!isMultiple) {
            return { file };
          }
          files[file.name] = file;
        } else {
          setFileError(`Max size error`);
        }
      } else {
        setFileError("File type is not supported");
      }

      return { ...files };
    }
  };

  const callUpdateFilesCb = (files) => {
    if (isMultiple) {
      const filesAsArray = convertNestedObjectToArray(files);
      updateFilesCb(filesAsArray);
    } else {
      updateFilesCb(files);
    }
  };

  const handleNewFileUpload = (newFiles) => {
    // const { files: newFiles } = e.target;
    if (
      (newFiles.length === 1 && !isMultiple) ||
      (isMultiple && newFiles.length > 1)
    ) {
      setFileError("");
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    } else {
      setFileError("Multipal file not allowed!");
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const removeAll = () => {
    setFiles({});
  };

  return (
    <>
      <div className="col-lg col-md-12 mb-2" disabled={isDisable}>
        <label className="input-label-text d-flex justify-content-between">
          <span>{title}</span>
          {(fileError || errors) && (
            <span className="warning-color">{errors ? errors : fileError}</span>
          )}
        </label>
        {Object.keys(files).length ? (
          <ProgressBar
            deleteItem={() => updateFilesCb({})}
            changeFile={() => handleUploadBtnClick()}
            timeInterval={0.5}
          />
        ) : (
          <div className="file-upload-body">
            <div
              onDragLeave={(e) => handleDrag(e)}
              onDragOver={(e) => handleDrag(e)}
              onDragEnter={(e) => handleDrag(e)}
              onDrop={(event) => {
                handleDrag(event);
                handleNewFileUpload(event.dataTransfer.files);

                if (!isButtonOrInputDisable) {
                  handleDrag(event);
                  handleUploadBtnClick();
                }
              }}
              onClick={handleUploadBtnClick}
              className="upload-wrapper"
            >
              <img
                className="upload-image"
                src={UploadFilesIcon}
                alt="Upload"
              ></img>
              <span className="details-text">{t("files.upload")}</span>
            </div>
          </div>
        )}
        <input
          // disabled={isButtonOrInputDisable}
          type="file"
          ref={filesInputRef}
          title={""}
          value={""}
          onChange={(e) => handleNewFileUpload(e.target.files)}
          hidden
          multiple={isMultiple ? true : false}
          accept={acceptFile}
        />
      </div>
    </>
  );
};

export default memo(FileUpload);
