import {
  GET_PATIENT_PERSONAL_DATA_FAIL,
  GET_PATIENT_PERSONAL_DATA_SUCCESS,
  GET_PATIENT_CONTACT_INFO_FAIL,
  GET_PATIENT_CONTACT_INFO_SUCCESS,
  GET_PATIENT_CLINICAL_DATA_FAIL,
  GET_PATIENT_CLINICAL_DATA_SUCCESS,
  GET_PATIENT_IDENTIFICATION_FAIL,
  GET_PATIENT_IDENTIFICATION_SUCCESS,
} from "../actions/patients";

const initialPersonalInfo = {
  birth_date: null,
  consent_check: false,
  full_name: null,
  process_number: null,
};

const initialContactInfo = {
  educational_level_id: null,
  email: null,
  language_id: null,
  marital_status_id: null,
  nationality_id: null,
  occupation: null,
  phone: null,
  telephone: null,
};

const initialClinicalData = {
  dominant_side_id: null,
  gender_id: null,
  height: null,
  medical_conditions: [],
  weight: null,
};

const initialIdentification = [];

const initialState = {
  ...initialPersonalInfo,
  ...initialContactInfo,
  ...initialClinicalData,
  identificationDocuments: initialIdentification,
};

const PatientProfile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_PERSONAL_DATA_SUCCESS:
    case GET_PATIENT_CONTACT_INFO_SUCCESS:
    case GET_PATIENT_CLINICAL_DATA_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_IDENTIFICATION_SUCCESS: {
      return {
        ...state,
        identificationDocuments: [...action.payload.data.data],
      };
    }
    case GET_PATIENT_PERSONAL_DATA_FAIL:
      return { ...state, ...initialPersonalInfo };
    case GET_PATIENT_CONTACT_INFO_FAIL:
      return { ...state, ...initialContactInfo };
    case GET_PATIENT_CLINICAL_DATA_FAIL:
      return { ...state, ...initialClinicalData };
    case GET_PATIENT_IDENTIFICATION_FAIL: {
      return {
        ...state,
        identificationDocuments: initialIdentification,
      };
    }
    default:
      return state;
  }
};

export default PatientProfile;
