import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import DropdownOptions from "../dropdownOptions";
import OptionsButton from "../../components/optionsButton";
import useOnClickOutside from "hooks/useOnClickOutside";

const CurrModules = ({
  modules,
  getCurrentModuleTypeImage,
  deleteModule,
  withOptions = true,
}) => {
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(null);

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const { t, i18n } = useTranslation();

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setSelectedModuleIndex(null)
  );

  return (
    <div className="col-sm-12 col-lg-6">
      <span className="small-title">
        {t("my_area.templates.modules")} ({modules && modules.length})
      </span>
      <div className="modules-list">
        {modules.map((module, index) => (
          <div key={module + index.toString()} className="module">
            <div>
              <img
                className="module-image"
                // onClick={() =>
                //   setMoreOptionsInstitutionId(currInstitution.id)
                // }
                src={getCurrentModuleTypeImage(
                  module.module_type === undefined
                    ? module.module_type_id
                    : module.module_type
                )} // modules objects are different in backend depending on request
                alt="More Blue"
              />
              {module.name}
            </div>
            {withOptions && (
              <div className="more-image-wrapper">
                <OptionsButton
                  id={`options ${index}`}
                  ref={(el) => (optionsRef.current[index] = el)}
                  isSelected={selectedModuleIndex === index}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedModuleIndex === index
                      ? setSelectedModuleIndex(null)
                      : setSelectedModuleIndex(index);
                  }}
                />
                {selectedModuleIndex === index && (
                  <DropdownOptions
                    ref={dropDownRef}
                    buttons={[
                      {
                        text: (
                          <span className="regular-text warning">
                            {t("buttons.delete")}
                          </span>
                        ),
                        action: () => {
                          setSelectedModuleIndex(null);
                          deleteModule(index);
                        },
                      },
                    ]}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(CurrModules);
