import {
  GET_EVALUATION_MODULE_FORM_FAIL,
  GET_EVALUATION_MODULE_FORM_SUCCESS,
} from "../actions/patientEvaluationModule";

const initialState = {
  allow_edit: null,
  filled: null,
  form: [],
  module_title: "",
};

const patientEvaluationModule = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVALUATION_MODULE_FORM_SUCCESS: {
      return {
        ...state,
        allow_edit: action.payload.data.allow_edit,
        filled: action.payload.data.filled,
        form: action.payload.data.form,
        module_title: action.payload.data.module_title,
        evaluation_module_id: action.payload.data.evaluation_module_id,
        module_id: action.payload.data.module_id,
        nr_files_allowed: action.payload.data.nr_files_allowed,
      };
    }
    case GET_EVALUATION_MODULE_FORM_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientEvaluationModule;
