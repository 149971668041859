/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import { useState, memo, useRef } from "react";
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import DropdownOptions from "../../../../components/dropdownOptions";
import Paginator from "../../../../components/paginator";
import Spinner from "../../../../components/spinner";
import OptionsButton from "../../../../components/optionsButton";
import SelectedWeekDays from "../../../../components/selectedWeekDays";
import MessageModal from "../../../../components/messageModal";

//* ************************************** HOOKS *****************************************
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************

import { deleteMedicine, getMedicinesList } from "../../../../redux/actions/patientMedicines";

//* ************************************** ASSETS ****************************************
import search from "../../../../assets/images/search.svg";
import filter_list_blue from "../../../../assets/images/filter-list-blue.svg";
import filter_list_white from "../../../../assets/images/filter-list-white.svg";
import prescriptionIcon from "../../../../assets/images/prescription.svg";
import SOSIcon from "../../../../assets/images/SOS.svg";
import chevronUpIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronUpSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";

import { errors } from "../../../../constants/general";
import moment from "moment";
import { dateToString } from "../../../../utils/dataAndTime";

const MedicineModuleList = ({ history, itemsPerPage }) => {
  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);
  const filterOptionsRef = useRef(null);
  const filterButtonRef = useRef(null);

  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");

  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);
  const [isPrescriptionListLoading, setIsPrescriptionListLoading] = useState(false);

  const [isDeleteMedicineModalOpenId, setIsDeleteMedicineModalOpenId] = useState(null);

  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);

  const [showPrescriptionIds, setShowPrescriptionsIds] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const { t, i18n } = useTranslation();

  const filterImage = useRef(null);

  const patientMedicines = useSelector((state) => state.patientsMedicines.medicine_list);

  const currInstitutionId = useSelector((state) => state.institutions.current_institution.id);

  const prescription_filter_types = {
    all: t("patient.meds.filter_msg.all"),
    current: t("patient.meds.filter_msg.current"),
    current_inv: t("patient.meds.filter_msg.current_inv"),
    expired: t("patient.meds.filter_msg.expired"),
    active: t("patient.meds.filter_msg.active"),
  };

  const sortOrders = {
    name: "name",
    name_inv: "-name",
    expiration_date: "expiration_date",
    expiration_date_inv: "-expiration_date",
    prescription: "prescription",
    prescripion_inv: "-prescription",
    time: "time",
    time_inv: "-time",
    days: "days",
    days_inv: "-days",
    date_inv: "-date",
  };
  const getCurrMedicineList = () => {
    setIsPrescriptionListLoading(true);
    dispatch(
      getMedicinesList(patient_id, {
        page: searchOptions.page,
        q: searchOptions.q,
        order_by: searchOptions.orderBy,
        filter_type: searchOptions.filterType,
      })
    ).finally(() => setIsPrescriptionListLoading(false));
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy, updateFilterType } =
    useSearch(getCurrMedicineList, 300, "medicine", [patient_id]);

  useOnClickOutside([filterOptionsRef, filterButtonRef], () => setFilterOptionsOpen(false));

  useOnClickOutside([dropDownRef, optionsRef], () => setSelectedPrescriptionId(null));

  const deleteSelectedMedicine = (values) => {
    setIsDeleting(true);
    dispatch(deleteMedicine(patient_id, values.medicine_id, values.prescription_id))
      .then(() => {
        setIsDeleting(false);
        setIsDeleteMedicineModalOpenId(null);
        getCurrMedicineList();
      })
      .catch(() => {
        setIsDeleting(false);
        setIsDeleteMedicineModalOpenId(null);
      });
  };

  const goToDetails = (medicine) =>
    history.push(
      `/patient/${patient_id}/mkinetikos_prescriptions/${medicine.prescription_id}/medicines/${medicine.medicine_id}/show`
    );

  const goToEdit = (medicine) =>
    history.push(
      `/patient/${patient_id}/mkinetikos_prescriptions/${medicine.prescription_id}/medicines/${medicine.medicine_id}/edit`
    );

  //* ************************************** PARTIALS **************************************
  const _patientListItem = (medicine, index) => {
    const isPrescriptionShowing = showPrescriptionIds.includes(medicine.prescription_id);
    return (
      <div key={medicine.id + index.toString()}>
        <div className={`medicine row align-items-center`}>
          <div
            className={`col-3 flex-d-row align-items-center ${
              !medicine.medicine_active && "disabled-container"
            } `}
          >
            <span className="cursor-pointer" onClick={() => goToDetails(medicine)}>
              {medicine.medicine_text}{" "}
            </span>
            {medicine.medicine_is_sos && (
              <img
                alt="SOS"
                className={`button-icon ml-1 ${!medicine.medicine_active && "disabled-container"}`}
                src={SOSIcon}
              ></img>
            )}
          </div>
          {medicine.medicine_is_sos ? (
            <div
              className={`col-5 regular-text grey-intense ${
                !medicine.medicine_active && "disabled-container"
              }`}
            >
              {" "}
              {t("patient.meds.sos_meds_dont_require")}{" "}
            </div>
          ) : (
            <>
              <div className={`col-4 ${!medicine.medicine_active && "disabled-container"}`}>
                <SelectedWeekDays activeDays={medicine.medicine_days} />
              </div>
              <div className={`col-1 ${!medicine.medicine_active && "disabled-container"}`}>
                {" "}
                {medicine.medicine_times.map((time, index) =>
                  index === 0 ? time.slice(0, -3) + " " : "/ " + time.slice(0, -3) + " "
                )}
              </div>
            </>
          )}
          <div className="col-2">
            {moment(medicine.medicine_end_date).isBefore(moment(dateToString(new Date()))) ||
            !medicine.medicine_active ? (
              <div className={`expired-date `}> {t("patient.meds.expired")} </div>
            ) : (
              medicine.medicine_end_date
            )}
          </div>{" "}
          <div
            className={`col-2 regular-text black-color flex-d-row align-items-center justify-content-between`}
          >
            <div className={`${!medicine.medicine_active && "disabled-container"}`}>
              {medicine.prescription_name
                ? medicine.prescription_name
                : t("patient.meds.prescribed_by_patient")}
            </div>
            <div className="flex-d-row align-items-center">
              <div className="more-image-wrapper">
                <OptionsButton
                  ref={(el) => (optionsRef.current[index] = el)}
                  type={isPrescriptionShowing ? 2 : 1}
                  isSelected={selectedPrescriptionId === medicine.medicine_id}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedPrescriptionId === medicine.medicine_id
                      ? setSelectedPrescriptionId(null)
                      : setSelectedPrescriptionId(medicine.medicine_id);
                  }}
                />
                {selectedPrescriptionId === medicine.medicine_id && (
                  <DropdownOptions
                    ref={dropDownRef}
                    buttons={
                      currInstitutionId === medicine.prescription_inst_id
                        ? [
                            medicine.medicine_active && {
                              text: <span className="regular-text">{t("buttons.edit")}</span>,
                              action: () => goToEdit(medicine),
                            },
                            {
                              text: (
                                <span className="regular-text">{t("buttons.view_details")}</span>
                              ),
                              action: () => goToDetails(medicine),
                            },
                            medicine.medicine_active && {
                              text: (
                                <span className="regular-text warning">{t("buttons.delete")}</span>
                              ),

                              action: () =>
                                setIsDeleteMedicineModalOpenId({
                                  prescription_id: medicine.prescription_id,
                                  medicine_id: medicine.medicine_id,
                                }),
                            },
                          ].filter(Boolean)
                        : [
                            {
                              text: (
                                <span className="regular-text">{t("buttons.view_details")}</span>
                              ),
                              action: () => goToDetails(medicine),
                            },
                          ]
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="medicines-module-wrapper">
        <div className="mkinetikos-medicines-modules-box">
          <div className="row align-items-center">
            <div className="col mt-1">
              <div className="small-title">{t("titles.meds")}</div>
            </div>
            <div className="col-auto mt-1">
              <button
                onClick={() => history.push(`/patient/${patient_id}/mkinetikos_medicines/create`)}
                className="button primary-button small-button"
              >
                <img alt="Person exercise" className="button-icon" src={prescriptionIcon} />
                {t("buttons.add_med")}
              </button>
            </div>
          </div>
          <div className="row search-filter-wrapper">
            <div className="col">
              <div style={{ position: "relative" }}>
                <input
                  value={searchOptions?.q}
                  onChange={(e) => updateSearchText(e.target.value)}
                  type="text"
                  className="search-input w-100"
                  placeholder={t("placeholders.search_for_prescription")}
                />
                <img alt="search-icon" className="search-icon" src={search} />
              </div>
            </div>
            <div className="col-auto filter-button">
              <button
                ref={filterButtonRef}
                onClick={() => setFilterOptionsOpen(!filterOptionsOpen)}
                onMouseOver={() =>
                  !filterOptionsOpen && (filterImage.current.src = filter_list_white)
                }
                onMouseLeave={() =>
                  !filterOptionsOpen && (filterImage.current.src = filter_list_blue)
                }
                className={`button justify-content-center small-button ${
                  filterOptionsOpen ? "marked-button" : "tertiary-button"
                }`}
              >
                <img
                  ref={filterImage}
                  alt="plus-icon"
                  className="button-icon"
                  src={filterOptionsOpen ? filter_list_white : filter_list_blue}
                />
                {t("buttons.filter")}
              </button>
              {filterOptionsOpen && (
                <DropdownOptions
                  ref={filterOptionsRef}
                  buttons={[
                    {
                      text: <span className="regular-text">{t("filters.all_meds")}</span>,
                      action: () => {
                        updateFilterType("all");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: (
                        <span className="regular-text">
                          {t("filters.meds_from_current_institution")}
                        </span>
                      ),
                      action: () => {
                        updateFilterType("current");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: <span className="regular-text">{t("filters.meds_from_other")}</span>,
                      action: () => {
                        updateFilterType("current_inv");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: <span className="regular-text">{t("filters.active")}</span>,
                      action: () => {
                        updateFilterType("active");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: <span className="regular-text">{t("filters.expired_meds")}</span>,
                      action: () => {
                        updateFilterType("expired");
                        setFilterOptionsOpen(false);
                      },
                    },
                  ]}
                />
              )}
            </div>
            <div className="filter-by-text">
              {t("filters.filtered_by")} {prescription_filter_types[searchOptions?.filterType]}
            </div>
          </div>

          <div className="row list-label mt-4 mb-1">
            <div className="col-3 d-flex align-item-center">
              {t("patient.meds.name_short")}
              <div
                className={`order-img-container ${
                  searchOptions?.orderBy === sortOrders.name ||
                  searchOptions?.orderBy === sortOrders.name_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (searchOptions?.orderBy === sortOrders.name) {
                    updateOrderBy(sortOrders.name_inv);
                  } else if (searchOptions?.orderBy === sortOrders.name_inv) {
                    updateOrderBy(sortOrders.date_inv);
                  } else {
                    updateOrderBy(sortOrders.name);
                  }
                }}
              >
                {searchOptions?.orderBy !== sortOrders.name_inv && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.name
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
                {searchOptions?.orderBy !== sortOrders.name && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.name_inv
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by Name"
                  />
                )}
              </div>
            </div>
            <div className="col-4 d-flex align-item-center">{t("patient.meds.days")}</div>
            <div className="col-1 d-flex align-item-center">
              {t("patient.meds.time")}
              <div
                className={`order-img-container ${
                  searchOptions?.orderBy === sortOrders.time ||
                  searchOptions?.orderBy === sortOrders.time_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (searchOptions?.orderBy === sortOrders.time) {
                    updateOrderBy(sortOrders.time_inv);
                  } else if (searchOptions?.orderBy === sortOrders.time_inv) {
                    updateOrderBy(sortOrders.date_inv);
                  } else {
                    updateOrderBy(sortOrders.time);
                  }
                }}
              >
                {searchOptions?.orderBy !== sortOrders.time_inv && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.time
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
                {searchOptions?.orderBy !== sortOrders.time && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.time_inv
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by Name"
                  />
                )}
              </div>
            </div>
            <div className="col-2 d-flex align-item-center">
              {t("patient.meds.end_date")}
              <div
                className={`order-img-container ${
                  searchOptions?.orderBy === sortOrders.expiration_date ||
                  searchOptions?.orderBy === sortOrders.expiration_date_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (searchOptions?.orderBy === sortOrders.expiration_date) {
                    updateOrderBy(sortOrders.expiration_date_inv);
                  } else if (searchOptions?.orderBy === sortOrders.expiration_date_inv) {
                    updateOrderBy(sortOrders.date_inv);
                  } else {
                    updateOrderBy(sortOrders.expiration_date);
                  }
                }}
              >
                {searchOptions?.orderBy !== sortOrders.expiration_date_inv && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.expiration_date
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by Name"
                  />
                )}
                {searchOptions?.orderBy !== sortOrders.expiration_date && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.expiration_date_inv
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
              </div>
            </div>
            <div className="col-2 d-flex align-item-center">
              {t("patient.meds.prescription")}
              <div
                className={`order-img-container ${
                  searchOptions?.orderBy === sortOrders.prescription ||
                  searchOptions?.orderBy === sortOrders.prescription_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  updateOrderBy(
                    searchOptions?.orderBy === sortOrders.prescription
                      ? sortOrders.prescription_inv
                      : sortOrders.prescription
                  );
                }}
              >
                {searchOptions?.orderBy !== sortOrders.prescription && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.prescription_inv
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by Name"
                  />
                )}
                {searchOptions?.orderBy !== sortOrders.prescription_inv && (
                  <img
                    src={
                      searchOptions?.orderBy === sortOrders.prescription
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="list-content regular-text black-color">
            {isPrescriptionListLoading ? (
              <div className="loading-wrapper">
                <Spinner type="big-blue" />
              </div>
            ) : patientMedicines.obj_list.length === 0 ? (
              <div className="medium-base-color-text message-wrapper">
                <p>{t("patient.meds.no_prescriptions_match")}</p>
              </div>
            ) : (
              patientMedicines.obj_list.map((medicine, index) =>
                /* medicine.medicine_active &&  */ _patientListItem(medicine, index)
              )
            )}
          </div>
        </div>

        <Paginator
          currPage={patientMedicines.page}
          totalPages={patientMedicines.num_pages}
          maxPages={3}
          changePage={updatePage}
        />
      </div>
      {isDeleteMedicineModalOpenId && (
        <MessageModal
          headerText={t("modals.delete_med_header")}
          body={t("modals.delete_med_body")}
          footerButtons={[
            <button
              disabled={isPrescriptionListLoading || isDeleting}
              onClick={() => setIsDeleteMedicineModalOpenId(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                disabled={isPrescriptionListLoading || isDeleting}
                type="button"
                value={t("buttons.delete")}
                onClick={() => deleteSelectedMedicine(isDeleteMedicineModalOpenId)}
                className={`warning-button small-wider-button ${isDeleting && "transparent"}`}
              />
              {isDeleting && <Spinner type={"small-warning"} />}
            </div>,
          ]}
        />
      )}
    </>
  );
};

export default memo(MedicineModuleList);
