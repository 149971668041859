//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import GroupPatientsImport from "./partials/groupPatientsImport";
import ManagePatients from "./partials/managePatients";
import Spinner from "../../../components/spinner";
import useToast from "../../../hooks/useToast";
import NavBar from "../../../components/navbar";

//* ************************************** GLOBALS ***************************************
import { getMkinetikosAccessPermission } from "../../../redux/actions/permissions";
import {
  getLicenseType,
  getPatientsWithLicense,
  getClinicians,
  importLicenseWithEmail,
  importLicenseWithFile,
  importProvidersWithFile,
  checkFeatureAccess,
} from "../../../redux/actions/mKinetikos";

const Tabs = {
  mkinetikos: "mkinetikos",
  provider_accounts: "provider_accounts",
};

const MKinetikos = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const showToast = useToast();

  const [importData, setImportData] = useState({});
  const [importFileData, setImportFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isCliniciansInfoLoading, setIsCliniciansInfoLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("status");
  const [formErrors, setFormErrors] = useState();
  const [currentTab, setCurrentTab] = useState(Tabs.mkinetikos);
  const [hasFeatureAccess, setHasFeatureAccess] = useState(false);

  const licenseType = useSelector((state) => state.licenseHandler.licenseType);
  const patientsWithLicense = useSelector(
    (state) => state.licenseHandler.patientWithLicense
  );
  const clinicians = useSelector((state) => state.licenseHandler.clinicians);
  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const customToast = (res) => {
    if (
      res.payload.data.data.sent_emails >
      res.payload.data.data.reserved_licenses
    ) {
      showToast({
        message: (
          <Trans i18nKey={"my_area.mKinetikos.number_of_licenses_reserved"}>
            {res.payload.data.data.reserved_licenses?.toString()}
            {(
              res.payload.data.data.sent_emails -
              res.payload.data.data.reserved_licenses
            )?.toString()}
          </Trans>
        ),
        type: "info",
      });
    }
  };

  useEffect(() => {
    if (currentInstitutionId) {
      dispatch(checkFeatureAccess(currentInstitutionId))
        .then((res) => {
          if (res.payload.data.data.has_feature_access) {
            setHasFeatureAccess(true);
          } else {
            setHasFeatureAccess(false);
          }
        })
        .catch((err) => {
          console.error("Failed to check feature access", err);
        });
    }
  }, [currentInstitutionId, dispatch]);

  useEffect(() => {
    setIsPatientLoading(true);
    dispatch(getMkinetikosAccessPermission(currentInstitutionId)).then(
      (res) => {
        dispatch(getLicenseType()).finally(() => {
          setIsPatientLoading(false);
        });
      },
      (err) => {
        props.history.push("/my_area");
      }
    );
  }, []);

  const getCliniciansInfo = (userName = "") => {
    setIsCliniciansInfoLoading(true);
    if (currentInstitutionId) {
      dispatch(getClinicians(currentInstitutionId, userName)).finally(() => {
        setIsCliniciansInfoLoading(false);
      });
    }
  };

  useEffect(() => {
    if (
      importData &&
      Object.keys(importData).length > 0 &&
      currentInstitutionId
    ) {
      setIsLoading(true);
      dispatch(importLicenseWithEmail(currentInstitutionId, importData))
        .then(
          (res) => {
            setFormErrors(null);
            dispatch(getLicenseType());
            changePatientsPage(
              patientsWithLicense.page ? patientsWithLicense.page : 1,
              orderBy
            );
            customToast(res);
          },
          (err) => {
            if (
              err.error.response.data.msg ===
              "Unable to execute any action with the data provided"
            ) {
              setFormErrors(err.error.response.data.data);
            } else {
              setFormErrors(err.error.response.data.msg);
            }

            // console.log(formErrors);
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [importData]);

  useEffect(() => {
    //TODO: clean this.
    const isProviderAccounts = currentTab === Tabs.provider_accounts;
    const importFunction = isProviderAccounts
      ? importProvidersWithFile
      : importLicenseWithFile;
    const additionalActions = () => {
      if (!isProviderAccounts) {
        dispatch(getLicenseType());
        changePatientsPage(patientsWithLicense.page || 1, orderBy);
      }
    };

    if (importFileData) {
      setIsLoading(true);
      dispatch(importFunction(currentInstitutionId, importFileData))
        .then((res) => {
          setFormErrors(null);
          additionalActions();
          customToast(res);
        })
        .catch((err) => {
          const message = err.error.response.data.msg;
          const data = err.error.response.data.data;
          setFormErrors(
            message === "Unable to execute any action with the data provided"
              ? data
              : message
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileData]);

  const changePatientsPage = (page, orderBy) => {
    setIsPatientLoading(true);
    dispatch(getPatientsWithLicense(page, orderBy ? orderBy : undefined))
      .catch((error) => console.log("error " + error))
      .finally(() => {
        setIsPatientLoading(false);
      });
  };

  const renderTabs = () => {
    const tabs = Object.values(Tabs).filter(
      (tab) => tab !== Tabs.provider_accounts || hasFeatureAccess
    );
    return (
      <span className='main-tabs'>
        {tabs.map((tab, index) => (
          <span
            key={index}
            className={`mr-2 ${
              currentTab === Object.values(Tabs)[index] ? "tab-active" : ""
            }`}
            onClick={() => setCurrentTab(tab)}
          >
            {t(`my_area.mKinetikos.${tab}`)}
          </span>
        ))}
      </span>
    );
  };

  return (
    <div className='col profile-body'>
      <NavBar title='mKinetikos' />
      {isPatientLoading ? (
        <Spinner type={"biggest-blue"} />
      ) : (
        <>
          {renderTabs()}
          <GroupPatientsImport
            dispatchFunction={getCliniciansInfo}
            isCliniciansInfoLoading={isCliniciansInfoLoading}
            setImportData={(value) => setImportData(value)}
            setFileData={(file) => setImportFileData(file)}
            error={formErrors}
            setError={setFormErrors}
            isLoading={isLoading}
            isProviderTab={currentTab === Tabs.provider_accounts}
          />
          {currentTab === Tabs.mkinetikos && <ManagePatients props={props} />}
        </>
      )}
    </div>
  );
};

export default MKinetikos;
