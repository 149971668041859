//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import { getMedicinesDetails } from "../../../../redux/actions/patientMedicines.js";
import { errors } from "../../../../constants/general.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";

const ShowMedicine = (props) => {
  //* *************************************** SETUP ***************************************
  const { patient_id, medicine_id, prescription_id } = useParams();
  const medicineInfo = useSelector(
    (state) => state.patientsMedicines.curr_medicine
  );
  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getMedicinesDetails(patient_id, medicine_id, prescription_id)
    ).catch(() => {
      alert(errors.service_error);
    });
  }, []);

  //* ************************************** HANDLERS **************************************
  const goBack = () =>
    props.history.replace(`/patient/${patient_id}/mkinetikos_medicines`);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img onClick={goBack} alt="back Button" src={bigBackButton} />
        <h4>{medicineInfo.text}</h4>
      </div>
      <div className="position-relative">
        <div className="create-exercise-box flex-d-column regular-text black-color">
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">{t("patient.meds.details")}</div>
            {currentInstitutionId ===
              medicineInfo.prescription?.prescription_inst_id && (
              <div className="mt-1">
                <button
                  disabled={!medicineInfo.active}
                  onClick={() =>
                    props.history.push(
                      `/patient/${patient_id}/mkinetikos_prescriptions/${medicineInfo.prescription?.prescription_id}/medicines/${medicine_id}/edit`
                    )
                  }
                  className="primary-button small-button"
                >
                  {t("buttons.edit")}
                </button>
              </div>
            )}
          </div>

          <label className="input-label-text mt-3">
            {" "}
            {t("patient.meds.med_name")}{" "}
          </label>
          {medicineInfo.text}
          <label className="input-label-text mt-4">
            {t("patient.meds.prescription")}
          </label>
          {medicineInfo.prescription?.prescription_name
            ? medicineInfo.prescription?.prescription_name
            : medicineInfo.prescription?.prescription_inst
            ? t("patient.meds.unnamed_prescription")
            : t("patient.meds.patient_prescription")}
          <label className="input-label-text mt-4">
            {t("patient.meds.prescribed_by")}
          </label>
          {medicineInfo.prescription?.prescription_inst
            ? medicineInfo.prescription?.prescription_inst
            : t("patient.meds.patient")}
          {medicineInfo.is_sos && (
            <>
              <label className="input-label-text mt-4">
                {t("patient.meds.maximum_intakes")}
              </label>
              <span>{medicineInfo?.max_daily_intake || "----"}</span>
            </>
          )}
          {!medicineInfo.is_sos && (
            <>
              <label className="input-label-text mt-4">
                <span>{t("patient.meds.frequency")}</span>
              </label>
              <SelectWeekDays
                isStatic={true}
                disabled={true}
                activeDays={medicineInfo.days}
              />
              <label className="input-label-text mt-4">
                {t("patient.meds.time")}
              </label>
              <div className="flex-d-row">
                {medicineInfo.times.map((time, index) => (
                  <div key={time + index.toString()}>
                    {index !== 0 ? `/ ${time}` : time}{" "}
                  </div>
                ))}
              </div>
            </>
          )}

          <label className="input-label-text mt-4">
            {t("patient.meds.end_date")}
          </label>
          {medicineInfo.end_date}
          <label className="input-label-text mt-4">
            {t("patient.meds.observations")}
          </label>
          {medicineInfo.notes}
        </div>
      </div>
    </div>
  );
};

export default ShowMedicine;
