//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useTranslation, Trans } from "react-i18next";

//* ***************************************** ACTIONS ****************************************
import {
  licenceRenew,
  getLicenseType,
  getClinicians,
  mkinetikosLicenseUse,
  importLicenseWithEmail,
  connectToInstitutionWithEmailList,
} from "../../redux/actions/mKinetikos";

//* ************************************* OUR COMPONENTS *************************************
import MessageModal from "../messageModal";
import LicenseCard from "../licenseCard";
import DropDownInputBox from "../dropDownInputBox";
import Spinner from "../spinner";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************
import uuid from "../../utils/uuid";

//* ***************************************** ASSETS *****************************************
import searchIcon from "../../assets/images/search.svg";

const RenewLicenceModal = ({
  showModal,
  onClose,
  data,
  renewWithEmail = false,
  hasMultipleEmails,
  withPatientID = false,
  afterOnClickFunction,
  beforeOnClickFunction,
  customHead = null,
  btnName = "",
  emailRequired = false,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );
  const licenseType = useSelector((state) => state.licenseHandler.licenseType);
  const clinicians = useSelector((state) => state.licenseHandler.clinicians);
  const userId = useSelector((state) => state.user.widget_info.userinfo_id);

  const [checkedRadioButtonIndex, setCheckedRadioButtonIndex] = useState(null);
  const [selectedClinician, setSelectedClinician] = useState("");
  const [autoRenew, setAutoRenew] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onlyPremiumUsers =
    data.premiumsCount > 0 &&
    data.patientsEmails?.length === data.premiumsCount;
  const nonPremiumCount = data.patientsEmails?.length - data.premiumsCount;

  const dataWithPatientGroup = {
    auto_connect: true,
    emails_list: data.patientsEmails,
    clinician_username: selectedClinician,
    auto_renew: autoRenew,
    mkinetikos_license_type_id: checkedRadioButtonIndex,
    connection_key: data?.connection_key,
  };

  const dataWithPatientId = {
    patient_id: data?.patient_id,
    email: patientEmail,
    clinician_username: selectedClinician,
    auto_renew: autoRenew,
    mkinetikos_license_type_id: checkedRadioButtonIndex,
    connection_key: data?.connection_key,
  };

  const dataWithEmail = {
    email: data?.email,
    patient_id: data?.patient_id || undefined,
    clinician_username: selectedClinician,
    auto_renew: autoRenew,
    mkinetikos_license_type_id: checkedRadioButtonIndex,
  };

  const dataForRenew = {
    previous_license_list:
      !renewWithEmail &&
      !withPatientID &&
      data &&
      data?.map((patient) => {
        if (patient) return patient.license_id;
      }),
    auto_renew: autoRenew,
    mkinetikos_license_type_id: checkedRadioButtonIndex,
  };

  const preSetDropdown = clinicians.filter(
    (clinician) => clinician.id === userId
  );

  useEffect(() => {
    dispatch(getLicenseType());
    if (currentInstitutionId) dispatch(getClinicians(currentInstitutionId));
  }, []);

  // TODO: we might have some bugs, never stop looking on this component
  const handleRenew = () => {
    setIsLoading(true);
    if (beforeOnClickFunction) {
      dispatch(beforeOnClickFunction()).then(() => {
        dispatch(
          hasMultipleEmails && onlyPremiumUsers
            ? connectToInstitutionWithEmailList(
                data.patientsEmails,
                selectedClinician
              )
            : hasMultipleEmails
            ? importLicenseWithEmail(currentInstitutionId, dataWithPatientGroup)
            : renewWithEmail
            ? mkinetikosLicenseUse(currentInstitutionId, dataWithEmail)
            : withPatientID
            ? mkinetikosLicenseUse(currentInstitutionId, dataWithPatientId)
            : licenceRenew(currentInstitutionId, dataForRenew)
        )
          .then(
            (res) => {
              if (afterOnClickFunction) {
                afterOnClickFunction();
              }
              onClose();
            },
            (err) => console.log(err)
          )
          .finally(() => {
            setIsLoading(false);
          });
      });
    } else {
      dispatch(
        hasMultipleEmails && onlyPremiumUsers
          ? connectToInstitutionWithEmailList(
              data.patientsEmails,
              selectedClinician
            )
          : hasMultipleEmails
          ? importLicenseWithEmail(currentInstitutionId, dataWithPatientGroup)
          : renewWithEmail
          ? mkinetikosLicenseUse(currentInstitutionId, dataWithEmail)
          : withPatientID
          ? mkinetikosLicenseUse(currentInstitutionId, dataWithPatientId)
          : licenceRenew(currentInstitutionId, dataForRenew)
      )
        .then(
          (res) => {
            if (afterOnClickFunction) {
              afterOnClickFunction();
            }
            onClose();
          },
          (err) => console.log(err)
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const searchClinicians = (userName = "") => {
    if (currentInstitutionId) {
      //   setIsCliniciansInfoLoading(true);
      dispatch(getClinicians(currentInstitutionId, userName)).finally(() => {
        // setIsCliniciansInfoLoading(false);
      });
    }
  };

  //* ************************************** PARTIALS ****************************************

  const DropDownConfig = {
    show: "name",
    set: "username",
    dispatchFunction: searchClinicians,
    // isLoading: isCliniciansInfoLoading,
    selector: clinicians,
  };

  const readyToGo =
    (onlyPremiumUsers && selectedClinician !== "") ||
    (checkedRadioButtonIndex !== null && !renewWithEmail && !withPatientID) ||
    (renewWithEmail &&
      selectedClinician !== "" &&
      checkedRadioButtonIndex !== null) ||
    (emailRequired &&
      withPatientID &&
      patientEmail !== "" &&
      selectedClinician !== "" &&
      checkedRadioButtonIndex !== null) ||
    (withPatientID &&
      selectedClinician !== "" &&
      checkedRadioButtonIndex !== null);

  //* *************************************** RENDER *****************************************
  const __renderModalBody = () => {
    const radioButtonOption = {
      text: t("my_area.mKinetikos.select"),
      onClickText: t("my_area.mKinetikos.selected"),
    };

    return (
      <>
        {!renewWithEmail && !withPatientID && (
          <div className="renew-licence-modal">
            <div className="row-space-between title">
              <span>{t("my_area.mKinetikos.patient_name")}</span>
              <span>{t("my_area.mKinetikos.previous_licence")}</span>
            </div>
            {data.map((patient) => {
              return (
                patient && (
                  <div className="row-space-between licence-row " key={uuid()}>
                    <span className="surface-black-color">{patient.name}</span>
                    <span>
                      <i>
                        {patient.license_duration}{" "}
                        {" " + t("my_area.mKinetikos.months")}
                      </i>
                    </span>
                  </div>
                )
              );
            })}
          </div>
        )}

        {emailRequired && (
          <div className="email-form">
            <span>{t("home.add_new_patient.email_invite")}</span>
            <input
              className={`form-input mt-1`}
              placeholder={t("placeholders.email")}
              onChange={(e) => setPatientEmail(e.target.value)}
            />
          </div>
        )}

        {data.premiumsCount > 0 &&
          data.patientsEmails.length !== data.premiumsCount && (
            <div className="row">
              <div className="col-auto need-license-container">
                <span>
                  <Trans
                    i18nKey={"home.need_license"}
                    first={nonPremiumCount}
                    second={data.patientsEmails.length}
                  >
                    {nonPremiumCount.toString()}
                    {data.patientsEmails.length.toString()}
                  </Trans>
                </span>
              </div>
            </div>
          )}
        {!onlyPremiumUsers && (
          <>
            <div className={`row license-type ${!withPatientID && "mt-4"}`}>
              <span>{t("my_area.mKinetikos.license_type")}</span>
              {licenseType && licenseType.length > 0 ? (
                licenseType.map((value) => {
                  return (
                    <div className="card-container" key={uuid()}>
                      <LicenseCard
                        key={value.mkinetikos_license_type}
                        headerText={
                          value.mkinetikos_license_type__duration +
                          " " +
                          t("my_area.mKinetikos.months")
                        }
                        minValue={value.available}
                        maxValue={value.total}
                        index={value.mkinetikos_license_type}
                        activeIndex={checkedRadioButtonIndex}
                        checkedRadioButtonIndex={checkedRadioButtonIndex}
                        radioButtonOption={radioButtonOption}
                        setCheckedRadioButtonIndex={setCheckedRadioButtonIndex}
                        isRadioButton={true}
                      />
                    </div>
                  );
                })
              ) : (
                <span className="regular-text p-3">
                  {t("my_area.mKinetikos.license_type_not_found")}
                </span>
              )}
            </div>
            <div className="auto-renew mt-3">
              {t("my_area.mKinetikos.auto_renew")}
              {/* slide button */}
              <div className="regular-text mt-2 flex-d-row align-items-center">
                <span className="mr-1">{t("no")}</span>
                <div className="form-check form-switch">
                  <input
                    disabled={false}
                    className="form-check-input shadow-none"
                    checked={autoRenew}
                    onChange={() => {
                      setAutoRenew(!autoRenew);
                    }}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                  />
                </div>
                <span className="ml-1">{t("yes")}</span>
              </div>
              {/* end */}
            </div>
          </>
        )}
        {(renewWithEmail || withPatientID) && (
          <div className="col search-clinician mt-3">
            {t("my_area.mKinetikos.patients_clinician")}
            <DropDownInputBox
              leftImage={searchIcon}
              placeholder={t("placeholders.clinician")}
              config={DropDownConfig}
              getSelectedText={(value) => setSelectedClinician(value)}
              preSetValue={preSetDropdown}
            />
          </div>
        )}
      </>
    );
  };

  return (
    showModal && (
      <MessageModal
        headerText={
          customHead ? customHead : t("my_area.mKinetikos.renew_licence")
        }
        body={__renderModalBody()}
        isDisable={isLoading}
        footerButtons={[
          <button
            onClick={() => {
              onClose();
            }}
            className="transparent-button small-button"
          >
            <u> {t("buttons.cancel")} </u>
          </button>,
          <button
            onClick={() => {
              handleRenew();
            }}
            className="secondary-button small-button"
            disabled={!readyToGo && !isLoading}
          >
            {isLoading && (
              <div className="position-relative loading-container-btn">
                <Spinner type={"small"} />
              </div>
            )}
            {!isLoading && (btnName ? btnName : t("buttons.renew"))}
          </button>,
        ]}
      />
    )
  );
};

export default RenewLicenceModal;
