/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect, memo, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import DropdownOptions from "../../../../components/dropdownOptions";
import Paginator from "../../../../components/paginator";
import MessageModal from "../../../../components/messageModal";

//* ************************************** HOOKS *****************************************
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************
import {
  getPatientPrograms,
  deleteProgramProtocol,
  deletePatientProgram,
} from "redux/actions/patientsPrograms";

//* ************************************** ASSETS ****************************************
import programsIcon from "../../../../assets/images/patientSidebarIcons/programs_white.svg";
import chevronUpIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronUpSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import search from "../../../../assets/images/search.svg";
import moreBlue from "../../../../assets/images/more-blue.svg";
import moreBlueSelected from "../../../../assets/images/more-blue-selected-grey.svg";
import { parseDateFromDatetime } from "../../../../utils/dataAndTime";
import Spinner from "../../../../components/spinner";
import { languageCodes } from "configs/i18n";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

const ProgramsModule = ({ history, itemsPerPage }) => {
  const sortOrders = {
    name: "name",
    name_inv: "name_inv",
    date: "date",
    date_inv: "date_inv",
  };

  const protocolTypes = {
    survey: "Survey",
    test: "Test",
    surveyPT: "Questionário",
    testPT: "Teste",
  };

  const dropdownProgramsRef = useRef(null);
  const programsRef = useRef([]);

  const protocolRef = useRef(null);
  const protocolButtonRef = useRef([]);

  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");

  const [openProgramId, setOpenProgramId] = useState(null);

  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [deletingProgramId, setDeletingProgramId] = useState(null);

  const [selectedProtocolId, setSelectedProtocolId] = useState(null);
  const [deletingProtocolId, setDeletingProtocolId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isNotAllowedModalOpen, setIsNotAllowedModalOpen] = useState(false);

  const currInstitution = useSelector(
    (state) => state.institutions.current_institution
  );

  const { t, i18n } = useTranslation();

  const patientPrograms = useSelector((state) => state.patientPrograms);

  const loadPatientPrograms = () => {
    setIsLoading(true);
    dispatch(
      getPatientPrograms(patient_id, {
        page: searchOptions.page,
        q: searchOptions.q,
        order_by: searchOptions.orderBy,
        items_per_page: searchOptions.itemsPerPage,
        lang_id: languageCodes[i18n.language],
      })
    ).finally(() => setIsLoading(false));
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } =
    useSearch(loadPatientPrograms, 300);

  const permissionTooltipText = (
    <span>
      {t("patient.programs.no_permission_tooltip_text")}{" "}
      <u
        onClick={() => setIsNotAllowedModalOpen(true)}
        className={"cursor-pointer"}
      >
        {t("patient.programs.no_permission_tooltip_see_why")}
      </u>
    </span>
  );

  const permissionTooltipProps = {
    dataTip: permissionTooltipText,
    dataFor: "permissionTooltip",
    place: "top",
  };
  //* ************************************** HANDLERS **************************************

  const onDeleteProgram = () => {
    setIsDeleting(true);
    dispatch(deletePatientProgram(patient_id, deletingProgramId)).then(
      () => {
        setIsDeleting(false);
        setDeletingProgramId(null);
        loadPatientPrograms();
      },
      (err) => {
        console.log("error deleteProgramProtocol:", err);
        setIsDeleting(false);
        setDeletingProgramId(null);
      }
    );
  };

  const onDeleteProtocol = () => {
    setIsDeleting(true);
    dispatch(
      deleteProgramProtocol(patient_id, openProgramId, deletingProtocolId)
    ).then(
      () => {
        setIsDeleting(false);
        setDeletingProtocolId(null);
        loadPatientPrograms();
      },
      (err) => {
        console.log("error deleteProgramProtocol:", err);
        setDeletingProtocolId(null);
      }
    );
  };

  const onClickResults = (protocolType, activityId, protocolName) => {
    if (
      protocolType === protocolTypes.survey ||
      protocolType === protocolTypes.surveyPT
    )
      return history.push(
        `/patient/${patient_id}/mkinetikos_surveys/activity/${activityId}`,
        { protocolName }
      );
    else if (
      protocolType === protocolTypes.test ||
      protocolType === protocolTypes.testPT
    )
      return history.push(
        `/patient/${patient_id}/mkinetikos_tests/activity/${activityId}`,
        { protocolName }
      );
  };

  const onCancelDeleteProgram = () => setDeletingProgramId(null);
  const onCancelDeleteModule = () => setDeletingProtocolId(null);

  const onClickCloseNotAllowedModal = () => setIsNotAllowedModalOpen(false);

  const _renderNotAllowedModal = () => {
    return (
      <MessageModal
        hasCloseButton={true}
        handleClose={() => onClickCloseNotAllowedModal()}
        headerText={
          <div className="row">
            <Icon
              icon={icons["error-icon"]}
              className="col-1 email-validation-img"
            />
            <div className="col row-space-between p-1">
              <span>{t("patient.programs.no_permission_modal_title")}</span>
            </div>
            <div className="hr-border-base"></div>
          </div>
        }
        body={<span>{t("patient.programs.no_permission_modal_text")}</span>}
        footerButtons={[
          <button
            className="button justify-content-center small-wider-button tertiary-button flex-fill"
            onClick={() => onClickCloseNotAllowedModal()}
          >
            {t("buttons.close")}
          </button>,
        ]}
      />
    );
  };
  useOnClickOutside([dropdownProgramsRef, programsRef], () =>
    setSelectedProgramId(null)
  );

  useOnClickOutside([protocolRef, protocolButtonRef], () =>
    setSelectedProtocolId(null)
  );

  return (
    <>
      <div className="programs-module-wrapper">
        <div className="programs-module-container">
          <div className="row-space-between">
            <span className="small-title">
              {t("patient.programs.programs")}
            </span>
            <button
              onClick={() => {
                history.push(
                  `/patient/${patient_id}/mkinetikos_programs/create`
                );
              }}
              className="button small-button primary-button"
            >
              <img
                alt="Create Program"
                className="programs-icon"
                src={programsIcon}
              />
              <span>{t("buttons.create_program")}</span>
            </button>
          </div>
          <div className="row search-wrapper">
            <div className="col">
              <div style={{ position: "relative" }}>
                <input
                  value={searchOptions.q}
                  onChange={(e) => updateSearchText(e.target.value)}
                  type="text"
                  className="search-input w-100"
                  placeholder={t("placeholders.search_for_program")}
                />
                <img alt="search-icon" className="search-icon" src={search} />
              </div>
            </div>
          </div>
          <div className="programs-list">
            <div className="row list-label programs-list-labels-wrapper">
              <div className="col-3 d-flex align-item-center">
                <div>{t("patient.programs.program_name")}</div>
                <div
                  className={`order-img-container ${
                    searchOptions.orderBy === sortOrders.name ||
                    searchOptions.orderBy === sortOrders.name_inv
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateOrderBy(
                      searchOptions.orderBy === sortOrders.name
                        ? sortOrders.name_inv
                        : sortOrders.name
                    );
                  }}
                >
                  {searchOptions.orderBy !== sortOrders.name && (
                    <img
                      src={
                        searchOptions.orderBy === sortOrders.name_inv
                          ? chevronUpSelectedIcon
                          : chevronUpIcon
                      }
                      alt="Order by Name"
                    />
                  )}
                  {searchOptions.orderBy !== sortOrders.name_inv && (
                    <img
                      src={
                        searchOptions.orderBy === sortOrders.name
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                      alt="Order by Name Invert"
                    />
                  )}
                </div>
              </div>
              <div className="col d-flex align-item-center">
                <div>{t("patient.programs.creation_date")}</div>
                <div
                  className={`order-img-container ${
                    searchOptions.orderBy === sortOrders.date ||
                    searchOptions.orderBy === sortOrders.date_inv
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateOrderBy(
                      searchOptions.orderBy === sortOrders.date_inv
                        ? sortOrders.date
                        : sortOrders.date_inv
                    );
                  }}
                >
                  {searchOptions.orderBy !== sortOrders.date_inv && (
                    <img
                      src={
                        searchOptions.orderBy === sortOrders.date
                          ? chevronUpSelectedIcon
                          : chevronUpIcon
                      }
                      alt="Order from Old to New"
                    />
                  )}
                  {searchOptions.orderBy !== sortOrders.date && (
                    <img
                      src={
                        searchOptions.orderBy === sortOrders.date_inv
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                      alt="Order from New to Old"
                    />
                  )}
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="loading-wrapper p-4">
                <Spinner type="big-blue" />
              </div>
            ) : patientPrograms.obj_list.length === 0 ? (
              <div className="medium-base-color-text message-wrapper">
                <p>{t("patient.programs.no_program_match")}</p>
              </div>
            ) : (
              patientPrograms.obj_list.map((program, index) => {
                const isOpen = program.program_id === openProgramId;
                return (
                  <div
                    key={program.program_id + index.toString()}
                    className={`program-info-details-wrapper ${
                      isOpen ? "open" : ""
                    }`}
                  >
                    <div
                      className={`row program-info-wrapper ${
                        isOpen ? "open" : ""
                      }`}
                    >
                      <div className="col-3 regular-text black-color align-items-center text-truncate">
                        {program.program_name}
                      </div>
                      <div className="col tags-wrapper regular-text black-color">
                        {parseDateFromDatetime(program.start_date)}
                      </div>
                      <div className="col-auto">
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => {
                              setOpenProgramId(
                                !isOpen ? program.program_id : null
                              );
                            }}
                            className="quaternary-button small-wider-button"
                          >
                            <span className="regular-text black-color">
                              {!isOpen
                                ? t("patient.programs.show_program")
                                : t("patient.programs.hide_program")}
                            </span>
                          </div>
                          <div className="more-image-wrapper">
                            <img
                              ref={(el) => (programsRef.current[index] = el)}
                              className="image-cursor"
                              onMouseOver={(e) =>
                                selectedProgramId !== program.program_id &&
                                (e.currentTarget.src = moreBlueSelected)
                              }
                              onMouseOut={(e) =>
                                selectedProgramId !== program.program_id &&
                                (e.currentTarget.src = moreBlue)
                              }
                              onClick={() => {
                                selectedProgramId === program.program_id
                                  ? setSelectedProgramId(null)
                                  : setSelectedProgramId(program.program_id);
                              }}
                              src={
                                selectedProgramId === program.program_id
                                  ? moreBlueSelected
                                  : moreBlue
                              }
                              alt="More Blue"
                            />
                            {selectedProgramId === program.program_id && (
                              <DropdownOptions
                                tooltip={
                                  currInstitution.id !==
                                    program.institution_id &&
                                  permissionTooltipProps
                                }
                                ref={dropdownProgramsRef}
                                buttons={[
                                  {
                                    text: (
                                      <span className={`regular-text`}>
                                        {t("buttons.edit")}
                                      </span>
                                    ),
                                    action: () => {
                                      history.push(
                                        `/patient/${patient_id}/mkinetikos_programs/${selectedProgramId}`
                                      );
                                    },
                                    disabled:
                                      currInstitution.id !==
                                      program.institution_id,
                                  },
                                  {
                                    text: (
                                      <span className={`regular-text warning `}>
                                        {t("buttons.delete")}
                                      </span>
                                    ),
                                    action: () => {
                                      setDeletingProgramId(selectedProgramId);
                                      setSelectedProgramId(null);
                                    },
                                    disabled:
                                      currInstitution.id !==
                                      program.institution_id,
                                  },
                                ]}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isOpen && (
                      <>
                        {program.protocols.length === 0 ? (
                          <div className="medium-base-color-text message-wrapper">
                            <p>{t("patient.programs.no_protocols")}</p>
                          </div>
                        ) : (
                          <div className="program-details-wrapper">
                            <div className="row list-label protocols-list-labels-wrapper">
                              <div className="col-3 d-flex align-items-center">
                                <div>{t("patient.programs.title")}</div>
                              </div>
                              <div className="col-3 d-flex align-item-center">
                                <div>{t("patient.programs.type")}</div>
                              </div>
                              <div className="col d-flex align-item-center">
                                <div>{t("patient.programs.frequency")}</div>
                              </div>
                            </div>
                            {program.protocols.map((protocol, index) => (
                              <div
                                key={index}
                                className={`row protocol-info-wrapper ${
                                  isOpen ? "open" : ""
                                }`}
                              >
                                <div
                                  onClick={() => {}}
                                  className="col-3 regular-text black-color align-items-center pointer text-truncate"
                                >
                                  {protocol.protocol_name}
                                </div>
                                <div
                                  onClick={() => {}}
                                  className="col-3 tags-wrapper regular-text black-color pointer"
                                >
                                  {protocol.protocol_type}
                                </div>
                                <div
                                  onClick={() => {}}
                                  className="col tags-wrapper regular-text black-color pointer"
                                >
                                  {protocol.protocol_frequency}
                                </div>
                                <div className="col-auto">
                                  <div className="d-flex align-items-center">
                                    <div
                                      onClick={() =>
                                        onClickResults(
                                          protocol.protocol_type,
                                          protocol.protocol_activity_id,
                                          protocol.protocol_name
                                        )
                                      }
                                      className="quaternary-button small-wider-button"
                                    >
                                      <span className="regular-text black-color">
                                        {t("buttons.show_results")}
                                      </span>
                                    </div>
                                    <div className="more-image-wrapper">
                                      <img
                                        ref={(el) =>
                                          (protocolButtonRef.current[index] =
                                            el)
                                        }
                                        className="image-cursor"
                                        onMouseOver={(e) =>
                                          selectedProtocolId !==
                                            protocol.protocol_id &&
                                          (e.currentTarget.src =
                                            moreBlueSelected)
                                        }
                                        onMouseOut={(e) =>
                                          selectedProtocolId !==
                                            protocol.protocol_id &&
                                          (e.currentTarget.src = moreBlue)
                                        }
                                        onClick={() =>
                                          setSelectedProtocolId(
                                            protocol.protocol_id
                                          )
                                        }
                                        src={
                                          selectedProtocolId ===
                                          protocol.protocol_id
                                            ? moreBlueSelected
                                            : moreBlue
                                        }
                                        alt="More Blue"
                                      />
                                      {selectedProtocolId ===
                                        protocol.protocol_id && (
                                        <DropdownOptions
                                          ref={protocolRef}
                                          buttons={[
                                            {
                                              text: (
                                                <span className="regular-text warning">
                                                  {t("buttons.delete")}
                                                </span>
                                              ),
                                              action: () => {
                                                setDeletingProtocolId(
                                                  selectedProtocolId
                                                );
                                                setSelectedProtocolId(null);
                                              },
                                              disabled:
                                                currInstitution.id !==
                                                program.institution_id,
                                            },
                                          ]}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <Paginator
          currPage={patientPrograms.page}
          totalPages={patientPrograms.num_pages}
          maxPages={3}
          changePage={updatePage}
        />
      </div>
      {deletingProgramId !== null && (
        <MessageModal
          headerText={t("modals.attention_header")}
          body={t("modals.delete_program_body")}
          footerButtons={[
            <button
              disabled={isDeleting}
              onClick={onCancelDeleteProgram}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <button
                onClick={onDeleteProgram}
                disabled={isDeleting}
                className={`warning-button small-wider-button ${
                  isDeleting && "transparent"
                }`}
              >
                {t("buttons.delete")}
              </button>
              {isDeleting && <Spinner type={"small-warning"} />}
            </div>,
          ]}
        />
      )}
      {isNotAllowedModalOpen && _renderNotAllowedModal()}
      {deletingProtocolId !== null && (
        <MessageModal
          headerText={t("modals.attention_header")}
          body={t("modals.protocol_body")}
          footerButtons={[
            <button
              disabled={isDeleting}
              onClick={() => {
                setDeletingProtocolId(null);
              }}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <button
                onClick={onDeleteProtocol}
                disabled={isDeleting}
                className={`warning-button small-wider-button ${
                  isDeleting && "transparent"
                }`}
              >
                {t("buttons.delete")}
              </button>
              {isDeleting && <Spinner type={"small-warning"} />}
            </div>,
          ]}
        />
      )}
    </>
  );
};

export default memo(ProgramsModule);
