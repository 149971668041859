import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchFilter,
  createSearchFilter,
  setSearchFilter,
} from "../redux/actions/searchFilter";
import { useDebounce } from "./useDebounce";

/**
 * This hook is used to call a search API with a debounced value.
 * Currently components or pages with more than one search/filter functionality are unsupported
 *
 * @param {searchCallback}: The function handling the API call
 * @param {delay}: Debounce delay in ms
 *
 * @return {searchOptions}: The current set search parameters ({q, page, filterType, orderBy, itemsPerPage})
 * @return {updateSearchText}: An updater for the text value, q. Used in search fields
 * @return {updatePage}: An updater for the page value, page. Used in paginators
 * @return {updateOrderBy}: An updater for the sorting method value, page. Used in sorting icons
 * @return {updateItemsPerPage}: An updater for the max items per page, page. Used in paginators
 * @return {updateFilterType}: An updater for the filter type, page. Used in filters
 *
 * EXAMPLE
 *
 * Increments a counter 0.5 seconds after
 *
 * const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(getPatientList, 300);
 *
 */

const useSearch = (
  searchCallback,
  delay = 300,
  name = "default",
  dependency = []
) => {
  const searchOptions = useSelector(
    (state) => state.searchFilter.filters[name]
  );
  const dispatch = useDispatch();

  const updateSearchFilter = (searchOptions) => {
    dispatch(
      setSearchFilter({
        filterName: name,
        q: searchOptions.q,
        page: searchOptions.page,
        orderBy: searchOptions.orderBy,
        itemsPerPage: searchOptions.itemsPerPage,
        filterType: searchOptions.filterType,
      })
    );
  };

  const updateSearchText = (query) =>
    updateSearchFilter({
      ...searchOptions,
      q: query,
      page: 1,
      orderBy: null,
    });

  const updateOrderBy = (orderBy) =>
    updateSearchFilter({
      ...searchOptions,
      page: 1,
      orderBy: orderBy,
    });

  const updatePage = (page) =>
    updateSearchFilter({
      ...searchOptions,
      page: page,
    });

  const updateItemsPerPage = (itemsPerPage) =>
    updateSearchFilter({
      ...searchOptions,
      itemsPerPage: itemsPerPage,
    });

  const updateFilterType = (filterType) =>
    updateSearchFilter({ ...searchOptions, page: 1, filterType: filterType });

  // Debounce search for 300 ms to prevent requests while typing
  useDebounce(searchCallback, [searchOptions, ...dependency], delay);

  // Clear search fields after component unmounts
  useEffect(() => {
    dispatch(createSearchFilter(name));
    return () => dispatch(clearSearchFilter(name));
  }, []);

  return {
    searchOptions,
    updateSearchText,
    updatePage,
    updateOrderBy,
    updateItemsPerPage,
    updateFilterType,
  };
};

export default useSearch;
