import {
  GET_PRESCRIPTION_LIST_SUCCESS,
  GET_PRESCRIPTION_LIST_FAIL,
  GET_PRESCRIPTION_DETAILS_SUCCESS,
  GET_PRESCRIPTION_DETAILS_FAIL,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPDATE_PRESCRIPTION_FAIL,
} from "../actions/patientPrescriptions";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  prescription_list: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
    links: {
      has_next: false,
      has_previous: false,
    },
  },
  curr_prescription: {
    id: null,
    name: "",
    user_can_edit: false,
    active: false,
    medicines: [],
    inst_id: null,
  },
};

const PatientsPrescriptions = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESCRIPTION_LIST_SUCCESS: {
      return { ...state, prescription_list: action.payload.data.data };
    }
    case GET_PRESCRIPTION_LIST_FAIL: {
      return { ...state, prescription_list: initialState.prescription_list };
    }
    case GET_PRESCRIPTION_DETAILS_SUCCESS:
    case UPDATE_PRESCRIPTION_SUCCESS: {
      return { ...state, curr_prescription: { ...action.payload.data.data } };
    }
    case GET_PRESCRIPTION_DETAILS_FAIL:
    case UPDATE_PRESCRIPTION_FAIL: {
      return { ...state, curr_prescription: initialState.curr_prescription };
    }

    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default PatientsPrescriptions;
