//* ******************************** REACT NATIVE IMPORTS ********************************
import React, { useState, useEffect } from "react";

//* *********************************** EXTERNAL PACKAGES ********************************
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "components/messageModal";
import RenewLicenceModal from "components/renewLicenceModal";
import { rejectPatientConnectionRequests } from "redux/actions/patientConnectionRequests";
import useToast from "hooks/useToast";
import Spinner from "components/spinner";

//* ************************************** GLOBALS ***************************************
import crossIcon from "assets/images/home/close.svg";
import { capitalizeFirstLetter } from "utils/string";

const ConnectionRequestsOverview = ({
  closeModal,
  fetchRequests,
  newOnly,
  pageChange,
  isFetching,
}) => {
  //* *************************************** SETUP ****************************************
  const dispatch = useDispatch();
  const showToast = useToast();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);
  const [toBeDeletedConnectionIds, setToBeDeletedConnectionIds] = useState([]);
  const [toBeApprovedConnectionsEmails, setToBeApprovedConnectionsEmails] =
    useState([]);
  const [toBeApprovedPremiumsCount, setToBeApprovedPremiumsCount] =
    useState(null);
  const [
    isRejectConnectionRequestModalOpen,
    setIsRejectConnectionRequestModalOpen,
  ] = useState(false);
  const [
    isApproveConnectionRequestModalOpen,
    setIsApproveConnectionRequestModalOpen,
  ] = useState(false);
  const currentInstitution = useSelector(
    (state) => state.institutions.current_institution
  );
  const institutionId = currentInstitution.id;
  const patientConnectionRequests = useSelector(
    (state) => state.patientConnectionRequests
  );
  const newPatientConnectionRequests = patientConnectionRequests.results.filter(
    (connection) => connection.is_new === true
  );

  const patientConnectionList = newOnly
    ? newPatientConnectionRequests
    : patientConnectionRequests.results;

  useEffect(() => {
    setToBeDeletedConnectionIds([]);
    setToBeApprovedConnectionsEmails([]);
    setToBeApprovedPremiumsCount(null);
    setSelectedConnectionIds([]);
  }, [pageChange]);

  useEffect(() => {
    if (newOnly) {
      fetchRequests();
    }
  }, [newOnly]);

  useEffect(() => {
    if (!newOnly) fetchRequests();
  }, [institutionId]);

  const checkboxHandler = (id) => {
    if (id === "all") {
      if (patientConnectionList.length !== selectedConnectionIds.length) {
        setSelectedConnectionIds(
          patientConnectionList.map((connection) => connection.id)
        );
      } else {
        setSelectedConnectionIds([]);
      }
    } else {
      const isSelected = selectedConnectionIds.includes(id);
      if (isSelected) {
        setSelectedConnectionIds(
          selectedConnectionIds.filter((connection) => connection !== id)
        );
      }
      if (!isSelected) setSelectedConnectionIds([...selectedConnectionIds, id]);
    }
  };

  const rejectConnectionRequest = () => {
    dispatch(
      rejectPatientConnectionRequests({
        requests_id_list: toBeDeletedConnectionIds,
        current_institution_id: institutionId,
      })
    )
      .then(() => {
        fetchRequests();
        setSelectedConnectionIds([]);
        showToast({
          message: t("home.licenses_successfully_rejected"),
          type: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSelectedConnectionIds([]);
        setToBeDeletedConnectionIds([]);
        setIsRejectConnectionRequestModalOpen(false);
      });
  };

  const approveButtonHandler = () => {
    const newToBeApprovedConnections = selectedConnectionIds.map(
      (connection) =>
        patientConnectionList.filter((request) => request.id === connection)[0]
    );
    const newToBeApprovedConnectionsEmails = newToBeApprovedConnections.map(
      (connection) => connection.user_email
    );
    const newToBeApprovedPremiumsCount = newToBeApprovedConnections.filter(
      (request) => request.is_premium === true
    ).length;

    setToBeApprovedConnectionsEmails(newToBeApprovedConnectionsEmails);
    setToBeApprovedPremiumsCount(newToBeApprovedPremiumsCount);
    setIsApproveConnectionRequestModalOpen(true);
  };

  const deleteButtonHandler = () => {
    setToBeDeletedConnectionIds(selectedConnectionIds);
    setIsRejectConnectionRequestModalOpen(true);
  };

  //* ************************************** PARTIALS **************************************

  const _renderConnectionRequest = (connectionRequest, index) => {
    return (
      <div key={"connection request" + index}>
        <div className="connection-request-container">
          <div
            className={`connection-request-content ${
              selectedConnectionIds.includes(connectionRequest.id) &&
              "connection-request-content--selected"
            }`}
          >
            <div className="col-2">
              <div className="check-box-wrapper" style={{ paddingBottom: 0 }}>
                <div className="input-wrapper col-md-auto">
                  <input
                    checked={selectedConnectionIds.includes(
                      connectionRequest.id
                    )}
                    onChange={() => checkboxHandler(connectionRequest.id)}
                    name={connectionRequest.user_name}
                    type="checkbox"
                  />
                </div>
                <label type="checkbox" htmlFor="checkbox">
                  <span className="regular-text black-color">
                    {capitalizeFirstLetter(connectionRequest.user_name)}
                  </span>
                </label>
                {connectionRequest.is_new && (
                  <span className="new-patinet-tag">{t("home.new_tag")}</span>
                )}
              </div>
            </div>
            <div className="col-3">
              <span className="regular-text black-color">
                {connectionRequest.user_email}
              </span>
            </div>
            <div className="col-5">
              <span className="regular-text black-color">
                {connectionRequest.is_premium
                  ? t("home.premium")
                  : t("home.not_premium")}
              </span>
            </div>
            {selectedConnectionIds.length === 0 && (
              <div className="col-2 home-buttons-container">
                <button
                  onClick={() => {
                    setToBeApprovedConnectionsEmails([
                      connectionRequest.user_email,
                    ]);
                    const toBeApprovedConnection = patientConnectionList.filter(
                      (request) => request.id === connectionRequest.id
                    )[0];
                    const isPremium = toBeApprovedConnection.is_premium;
                    setToBeApprovedPremiumsCount(isPremium ? 1 : 0);
                    setIsApproveConnectionRequestModalOpen(true);
                  }}
                  className={`transparent-button approve-button`}
                >
                  {t("buttons.approve")}
                </button>
                <button
                  onClick={() => {
                    setToBeDeletedConnectionIds([connectionRequest.id]);
                    setIsRejectConnectionRequestModalOpen(true);
                  }}
                  className={`transparent-button reject-button`}
                >
                  {t("buttons.reject")}
                </button>
              </div>
            )}
          </div>
        </div>
        {index + 1 !== patientConnectionRequests.length && (
          <div className="row border-wrapper">
            <hr
              className={`${
                selectedConnectionIds.includes(connectionRequest.id)
                  ? "transparent-border"
                  : "hr-border"
              }`}
            />
          </div>
        )}
      </div>
    );
  };

  //* ************************************** RENDER **************************************
  return isFetching ? (
    <Spinner type={"biggest-blue"} />
  ) : (
    <>
      {newOnly && (
        <div
          className={`row-space-between ${
            patientConnectionList.length === 0 &&
            "patient-connection-no-request-border"
          }`}
        >
          <span className="regular-text dark-grey-color">
            {t("home.following_patients_asked_to_connect")}
          </span>
          <button
            className="transparent-button patient-connection-see-requests"
            onClick={() => {
              closeModal();
              history.push({
                pathname: "/",
                cameFromNotificationsModal: true,
              });
            }}
          >
            <span className="regular-text primary-color">
              {t("home.see_all_requests")}
            </span>
          </button>
        </div>
      )}
      {patientConnectionList.length !== 0 && (
        <div className="patient-connection-requests-header">
          <div className="check-box-wrapper">
            <div className="input-wrapper col-md-auto">
              <input
                checked={
                  selectedConnectionIds.length === patientConnectionList.length
                }
                onChange={() => checkboxHandler("all")}
                name="all"
                type="checkbox"
                id="all"
              />
            </div>
            <label type="checkbox" htmlFor="all-checkbox">
              <span className="regular-text black-color">
                {t("home.select_all")}
              </span>
            </label>
          </div>
          <div className="home-buttons-container">
            <button
              disabled={selectedConnectionIds.length === 0}
              onClick={() => deleteButtonHandler()}
              className="small-button tertiary-button mr-2"
            >
              {t("buttons.reject")}
              {selectedConnectionIds.length > 0 &&
                ` (${selectedConnectionIds.length})`}
            </button>
            <button
              disabled={selectedConnectionIds.length === 0}
              onClick={() => approveButtonHandler()}
              className="small-button primary-button"
            >
              {t("buttons.approve")}
              {selectedConnectionIds.length > 0 &&
                ` (${selectedConnectionIds.length})`}
            </button>
          </div>
        </div>
      )}
      <div className="row connection-request-list d-flex">
        {patientConnectionList.length !== 0 ? (
          patientConnectionList.map((connectionRequest, index) =>
            _renderConnectionRequest(connectionRequest, index)
          )
        ) : (
          <div className="flex-d-column no-data-text">
            <span className="base-color">
              {newOnly
                ? t("home.new_patient_connection_requests_not_found")
                : t("home.patient_connection_requests_not_found")}
            </span>
          </div>
        )}
      </div>
      {isRejectConnectionRequestModalOpen && (
        <MessageModal
          headerText={t("home.are_you_sure_short")}
          body={t("home.are_you_sure_long")}
          footerButtons={[
            <button
              onClick={() => {
                setToBeDeletedConnectionIds([]);
                setIsRejectConnectionRequestModalOpen(false);
              }}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={() => {
                rejectConnectionRequest();
              }}
              className="small-button warning-button"
            >
              {t("buttons.delete")}
            </button>,
          ]}
        />
      )}
      <RenewLicenceModal
        showModal={isApproveConnectionRequestModalOpen}
        onClose={() => {
          setSelectedConnectionIds([]);
          setToBeApprovedConnectionsEmails([]);
          setToBeApprovedPremiumsCount(null);
          setIsApproveConnectionRequestModalOpen(false);
        }}
        hasMultipleEmails
        data={{
          patientsEmails: toBeApprovedConnectionsEmails,
          premiumsCount: toBeApprovedPremiumsCount,
        }}
        renewWithEmail={true}
        customHead={
          <div className="row-space-between">
            <span>
              {toBeApprovedPremiumsCount > 0 &&
              toBeApprovedConnectionsEmails.length === toBeApprovedPremiumsCount
                ? t("home.add_new_patient.connect_institution")
                : t("home.add_new_patient.premium_licence")}
            </span>
            <img
              className="cursor-pointer"
              src={crossIcon}
              alt="close pointer"
              onClick={() => {
                setIsApproveConnectionRequestModalOpen(false);
              }}
            />
          </div>
        }
        btnName={t("buttons.approve")}
        afterOnClickFunction={() => {
          setSelectedConnectionIds([]);
          setToBeApprovedConnectionsEmails([]);
          setToBeApprovedPremiumsCount(null);
          fetchRequests();
        }}
      />
    </>
  );
};

export default ConnectionRequestsOverview;
