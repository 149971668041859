import { useEffect, useRef, useState } from "react";
import { create_card } from "../../../utils/create_card";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

const ImageCard = ({ showInfo, details }) => {
  const reff = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  // const data =
  //   "https://media.discordapp.net/attachments/842081127678214165/973536845340217384/unknown.png";
  // const details = {
  //   id: 109,
  //   type: 21,
  //   title: "Balance Plot",
  //   description: "Balance Plot",
  //   icon: '<i class="fa fa-area-chart" aria-hidden="true"></i>',
  //   color: "var(--kred-color)",
  //   span_row: 4,
  //   span_col: 5,
  // };

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_display_image_card(card, data, color) {
      let card_body = card.querySelector(".card-body");

      if (data !== null) {
        let img_elem = document.createElement("img");
        img_elem.src = data;
        //img_elem.scr =
        // "src/media/metric_images/standing_velocity_plot_1_1_3ab4oYB.png";
        // img_elem.classList = 'myImg';
        card_body.appendChild(img_elem);
        img_elem.addEventListener("click", append_to_overlay, false);
        img_elem.addEventListener("touch", append_to_overlay, false);

        function append_to_overlay() {
          showInfo(details.type, data);
        }
      }

      if (color !== undefined) {
        card_body.style.setProperty("--color", color);
      }
    }
    if (data === null) {
      create_card(
        reff.current,
        31,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    } else {
      build_display_image_card(reff.current, data, details.color);
    }
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default ImageCard;
