import {
  GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_FAIL,
  GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_SUCCESS,
} from "../actions/patients";

const initialState = [];

const patientAvailableChatParticipants = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_SUCCESS: {
      return [...action.payload.data.data];
    }
    case GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientAvailableChatParticipants;
