//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";
import PrivacyElement from "../../../../components/privacyElement";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************
import { getTermsAndConditions } from "../../../../redux/actions/user";
import { getLanguageID } from "../../../../utils/language";

const TermsAndConditions = (props) => {
  //* ************************************** HANDLERS **************************************
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [termsAndConditions, setTermsAndCondtions] = useState("");

  useEffect(() => {
    dispatch(getTermsAndConditions(getLanguageID(i18n.language)))
      .then((res) => {
        if (res.payload && res.payload.data)
          setTermsAndCondtions(res.payload.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const onGoBack = () => {
    if (props.history.action !== "POP") props.history.goBack();
  };
  //* ************************************** RENDER **************************************
  return (
    <PrivacyElement
      title={t("titles.terms")}
      onGoBack={onGoBack}
      isPublic={false}
      bodyText={termsAndConditions}
    />
  );
};

export default React.memo(TermsAndConditions);
