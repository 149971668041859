import React, { useEffect } from "react";
import $ from "jquery";

const NumberInput = ({
  value = "",
  onChange = () => {},
  id,
  name,
  placeholder = "",
  disabled = false,
  type = "int",
  min = null,
  max = null,
}) => {
  useEffect(() => {
    setTimeout(() => {
      const jsValue = `${$(`#${id}`)[0].value}`;
      if (jsValue !== value) {
        onChange({ target: { value: jsValue } });
      }
    }, 1);
  });

  const isValid = () => {
    if (value !== "") {
      if (type === "int") {
        const number = parseInt(value, 10);
        if (min !== null && number < min) return false;
        if (max !== null && number > max) return false;
      } else if (type === "float") {
        const number = parseFloat(value, 10);
        if (min !== null && number < min) return false;
        if (max !== null && number > max) return false;
      }
    }

    return true;
  };

  return (
    <input
      autoComplete="off"
      value={value}
      onChange={(e) => {
        if (type === "int") {
          if (/^\d*$/.test(e.target.value) || /^-\d*$/.test(e.target.value)) {
            onChange(e);
          }
        } else if (type === "float") {
          if (
            /^\d*$/.test(e.target.value) ||
            /^-\d*$/.test(e.target.value) ||
            /^\d*\.\d*$/.test(e.target.value) ||
            /^-\d*\.\d*$/.test(e.target.value)
          ) {
            onChange(e);
          }
        }
      }}
      className={`ev-mod-number-input ${isValid() ? "" : "error"}`}
      id={id}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default React.memo(NumberInput);
