///* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useRef, useState, useEffect } from "react";
import Spinner from "../spinner";
///* *********************************** EXTERNAL PACKAGES ************************************
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../messageModal";

//* ************************************** GLOBALS ***************************************
import edit_blue from "../../assets/images/edit-blue.svg";

const SaveTemplateModal = ({
  cancel,
  saveNewEvaluationTemplate,
  isSavingToUser,
  isSavingToInstitution,
  hasTextInput = false,
}) => {
  const [evaluationTemplateTitle, setEvaluationTemplateTitle] = useState("");

  const { t, i18n } = useTranslation();
  const titleInputRef = useRef(null);

  const inputsHandler = (e) => setEvaluationTemplateTitle(e.target.value);

  useEffect(() => {
    if (hasTextInput) {
      titleInputRef.current.focus();
    }
  }, []);

  const body = (
    <div className="col">
      <div>{t("modals.save_template_body")}</div>
      {hasTextInput && (
        <div className="col-auto mt-3">
          <div className="input-title-wrapper image-right">
            <input
              type="text"
              ref={titleInputRef}
              autoComplete="off"
              value={evaluationTemplateTitle}
              className="form-input-3 w-100"
              onChange={inputsHandler}
              placeholder="Template Name"
            />
            <img
              onClick={() => titleInputRef.current.focus()}
              className="edit-input"
              alt="Edit blue"
              src={edit_blue}
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <MessageModal
      headerText={t("modals.save_template_header")}
      body={body}
      footerButtons={[
        <button
          disabled={isSavingToInstitution || isSavingToUser}
          onClick={cancel}
          className="small-button transparent-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <div className="input-image-wrapper m-0">
          <button
            disabled={
              isSavingToInstitution ||
              isSavingToUser ||
              (hasTextInput && evaluationTemplateTitle === "")
            }
            onClick={() =>
              saveNewEvaluationTemplate(true, evaluationTemplateTitle)
            }
            className="small-button secondary-button"
          >
            {t("buttons.save_to_institution")}
          </button>
          {isSavingToInstitution && <Spinner type={"small"} />}
        </div>,
        <div className="input-image-wrapper m-0">
          <button
            disabled={
              isSavingToInstitution ||
              isSavingToUser ||
              (hasTextInput && evaluationTemplateTitle === "")
            }
            onClick={() =>
              saveNewEvaluationTemplate(false, evaluationTemplateTitle)
            }
            className="button small-button secondary-button"
          >
            {t("buttons.save_to_me")}
          </button>
          {isSavingToUser && <Spinner type={"small"} />}
        </div>,
      ]}
    />
  );
};

export default React.memo(SaveTemplateModal);
