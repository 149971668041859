export const GET_MEDICINE_LIST = "GET_MEDICINE_LIST";
export const GET_MEDICINE_LIST_SUCCESS = "GET_MEDICINE_LIST_SUCCESS";
export const GET_MEDICINE_LIST_FAIL = "GET_MEDICINE_LIST_FAIL";

export const GET_MEDICINE_DETAILS = "GET_MEDICINE_DETAILS";
export const GET_MEDICINE_DETAILS_SUCCESS = "GET_MEDICINE_DETAILS_SUCCESS";
export const GET_MEDICINE_DETAILS_FAIL = "GET_MEDICINE_DETAILS_FAIL";

export const CREATE_MEDICINE = "CREATE_MEDICINE";
export const CREATE_MEDICINE_SUCCESS = "CREATE_MEDICINE_SUCCESS";
export const CREATE_MEDICINE_FAIL = "CREATE_MEDICINE_FAIL";

export const UPDATE_MEDICINE = "UPDATE_MEDICINE";
export const UPDATE_MEDICINE_SUCCESS = "UPDATE_MEDICINE_SUCCESS";
export const UPDATE_MEDICINE_FAIL = "UPDATE_MEDICINE_FAIL";

export const DELETE_MEDICINE = "DELETE_MEDICINE";
export const DELETE_MEDICINE_SUCCESS = "DELETE_MEDICINE_SUCCESS";
export const DELETE_MEDICINE_FAIL = "DELETE_MEDICINE_FAIL";

export const getMedicinesList = (
  patient_id,
  { page = 1, q = "", order_by = "", filter_type = "", items_per_page = 10 }
) => ({
  type: GET_MEDICINE_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/medicines/`,
      params: { page, q, order_by, filter_type, items_per_page },
    },
  },
});

export const getMedicinesDetails = (
  patient_id,
  medicine_id,
  prescription_id
) => ({
  type: GET_MEDICINE_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}/medicines/${medicine_id}`,
    },
  },
});

export const createMedicine = (patient_id, data, prescription_id) => ({
  type: CREATE_MEDICINE,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}/medicines/`,
      data: data,
    },
  },
});

export const updateMedicine = (
  patient_id,
  medicine_id,
  data,
  prescription_id
) => ({
  type: UPDATE_MEDICINE,
  payload: {
    request: {
      method: "put",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}/medicines/${medicine_id}/`,
      data: data,
    },
  },
});

export const deleteMedicine = (patient_id, medicine_id, prescription_id) => ({
  type: DELETE_MEDICINE,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/${prescription_id}/medicines/${medicine_id}`,
    },
  },
});
