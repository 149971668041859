import { useEffect, useRef, useState } from "react";
import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";

import { create_card } from "../../../utils/create_card";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";
import {
  getVideo,
  requestVideo,
} from "../../../redux/actions/patientEvaluationReports";
const VideoCard = ({ details }) => {
  const reff = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  /*   useEffect(() => {
    dispatch(getVideo(patient_id, evaluation_id, 32)).then(
      (res) => {
        console.log(res);
        const status = res.payload.data.data.status_id;
        console.log("Status: " + status);
        if (status > 4) {
        }
        if (status === 5) {
          console.log("status error " + status);
        }
      },
      (err) => {
        console.log(err);
        // clearInterval(interval);
      }
    );
  }); */

  useEffect(() => {
    // const data = {
    //   video_link: "/media/a469725b32e067b4b2684d5bbe9556fa5fc44e54_1_TUG.mp4",
    //   video_links: [],
    //   exam_dates: [],
    //   ik_status: false,
    //   kine_file_manager_status_id: false,
    //   api_url: "/file_submissions_status_api/",
    //   module_id: 435,
    //   extra_args: "",
    // };
    // const details = {
    //   id: 101,
    //   type: 7,
    //   title: "Kinematic Video",
    //   description:
    //     "Video of the performed movement NOTE: To generate the video from this session click on the icon of your camcorder.",
    //   icon: '<i class="fa fa-film" aria-hidden="true"></i>',
    //   color: "var(--mkinetikos-gray-color)",
    //   span_row: 4,
    //   span_col: 5,
    // };

    function build_video_card(card, data) {
      //console.log('data', data)
      let card_body = card.querySelector(".card-body"),
        card_header_actions = card.querySelector(".card-header .actions"),
        wrapper_div = document.createElement("div"),
        video = document.createElement("video"),
        source = document.createElement("source"),
        dropdown = document.createElement("select");

      card_header_actions.innerHTML = "";
      card_body.innerHTML = "";

      control_video_ik(card, data);

      wrapper_div.classList = "video-div noselect";
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* DROPDOWN  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      dropdown.classList = "Form-input selectInput plot-dropdown";

      data.exam_dates.forEach((date, i) => {
        let drop_item = document.createElement("option");
        drop_item.innerText = date;
        drop_item.value = data.video_links[i];
        if (data.video_links[i] === null) drop_item.disabled = true;
        if (data.video_links[i] === data.video_link) drop_item.selected = true;

        dropdown.appendChild(drop_item);
      });

      dropdown.addEventListener("change", dropdown_change, false);

      function dropdown_change() {
        source.src = this.value;
        video.load();
      }

      // if (!document.getElementById(`${card.getAttribute('data-card_id')}_dropdown`)) {
      // TODO: Review
      let build_video_button = document.createElement("div");
      build_video_button.classList = "action";

      build_video_button.id = `${card.getAttribute("data-card_id")}_dropdown`;

      if (data.ik_status == false) {
        //if (data.ik_status === 4) {
        build_video_button.innerHTML =
          '<i class="icon video" aria-hidden="true"></i>';
        build_video_button.addEventListener(
          "touch",
          () => handle_click(),
          false
        );
        build_video_button.addEventListener(
          "click",
          () => handle_click(),
          false
        );
      } else if (data.ik_status < 4) {
        build_video_button.innerHTML =
          '<i class="icon loading" aria-hidden="true"></i>';
      } else if (data.ik_status > 4) {
        build_video_button.innerHTML =
          '<i class="icon close" aria-hidden="true"></i>';
      }

      card_header_actions.appendChild(build_video_button);
      card_header_actions.appendChild(dropdown);

      function handle_click() {
        let card_id = card.getAttribute("data-card_id");

        dispatch(requestVideo(patient_id, evaluation_id, data)).then(
          (res) => {
            if (res.payload && res.payload.data) {
              build_video_button.innerHTML =
                '<i class="icon loading" aria-hidden="true"></i>';
              const submission_status_id =
                res.payload.data.data.submission_status_id;
              const interval = setInterval(() => {
                dispatch(
                  getVideo(patient_id, evaluation_id, submission_status_id)
                ).then(
                  (res) => {
                    const status = res.payload.data.data.status_id;
                    if (status >= 4) {
                      clearInterval(interval);
                      build_video_button.innerHTML = "";
                    }
                    if (status === 4) {
                      dispatch(
                        getPatientEvaluatioCardDetails(
                          patient_id,
                          evaluation_id,
                          details.id,
                          getLanguageID(i18n.language)
                        )
                      ).then(
                        (res) => {
                          if (res.payload && res.payload.data) {
                            setData(res.payload.data.data.data);
                          }
                        },
                        (err) => console.log(err)
                      );
                    }
                    if (status >= 5) {
                      build_video_button.innerHTML =
                        '<i class="icon close" aria-hidden="true"></i>';
                    }
                  },
                  (err) => {
                    console.log(err);
                    clearInterval(interval);
                  }
                );
              }, 5000);
            }
          },
          (err) => console.log(err)
        );

        /* axios
          .post(
            process.env.REACT_APP_BACKEND_URL +
              `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/video/`,
            {
              params: {
                module_id: data.module_id,
                extra_args: data.extra_args,
              },
            }
          )
          .then((res) => {
            alert("Your video is being processed");
            if (res.payload && res.payload.data) {
              const submission_status_id =
                res.payload.data.data.submission_status_id;
              var int = setInterval(() => {
                axios
                  .get(
                    process.env.REACT_APP_BACKEND_URL +
                      `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/video/`,
                    {
                      params: {
                        submission_id: submission_status_id,
                      },
                    }
                  )
                  .then((res) => {
                    if (res.payload && res.payload.data) {
                      const status = res.payload.data.data.status_id;
                      if (status > 4) {
                        clearInterval(int);
                      } else if (status === 5) {
                        dispatch(
                          getPatientEvaluatioCardDetails(
                            patient_id,
                            evaluation_id,
                            details.id,
                            getLanguageID(i18n.language)
                          )
                        )
                          .then((res) => {
                            if (res.payload && res.payload.data) {
                              console.log(res.payload.data.data.data);
                              setData(res.payload.data.data.data);
                            }
                          })
                          .catch(() => {
                            alert("error");
                          });
                      }
                    }
                  })
                  .catch((error) => {
                    // clearInterval(int);
                    alert("fail");
                  });
              }, 5000);
            }
          })
          .catch((error) => {}); */

        // $.ajax({
        //   type: "POST",
        //   url: url,
        //   data: {
        //     evaluation_id: eval_id,
        //     module_id: data.module_id,
        //     extra_args: data.extra_args,
        //     card_id: card_id,
        //     csrfmiddlewaretoken: csrf,
        //   },
        //   success: function (result) {
        //     refesh_card(card_id);
        //     // Display the done pop up, and then populate it with the right content according to the result
        //     // refesh_card(35);
        //     // if(result.success){
        //     //     refesh_card(card.getAttribute('data-card_id'))
        //     //     toogleDone(true);
        //     // }else{
        //     //     toogleDone(false, result.message);
        //     // }
        //   },
        //   error: function () {
        //     //alert user in case of failure
        //     // toogleDone(false);
        //     refesh_card(card_id);
        //   },
        // });
      }

      // video.src = data.video_link;
      // source.src = data.video_link;
      // source.type = "video/mp4";
      // video.innerText = `Your browser does not support the video tag.`;
      // video.setAttribute("align", "middle");
      // video.controls = true;

      // video.appendChild(source);
      // wrapper_div.appendChild(video);
      // card_body.appendChild(wrapper_div);

      // video.load();

      const onSuccessVideo = () => {
        source.src = process.env.REACT_APP_BACKEND_HOST + data.video_link;
        source.type = "video/mp4";
        video.innerText = `Your browser does not support the video tag.`;
        video.setAttribute("align", "middle");
        video.controls = true;

        video.appendChild(source);
        wrapper_div.appendChild(video);
        card_body.appendChild(wrapper_div);

        video.load();
      };

      onSuccessVideo();

      // }

      function control_video_ik(card, data) {
        if (data.ik_status != false && data.ik_status < 4) {
          // var refreshIntervalId = setInterval(function () {
          //   $(function () {
          //     $.ajax({
          //       type: "POST",
          //       url: data.api_url,
          //       data: {
          //         submission_id: data.kine_file_manager_status_id,
          //         csrfmiddlewaretoken: csrf,
          //       },
          //       success: function (re_data) {
          //         if (re_data.status_id > 3) {
          //           clearInterval(refreshIntervalId);
          //           refesh_card(card.getAttribute("data-card_id"));
          //         }
          //       },
          //       error: function (request) {},
          //     });
          //   });
          // }, 10000);
        }
      }
    }
    if (data === null)
      create_card(
        reff.current,
        3,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    if (data) build_video_card(reff.current, data, details.color);
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default React.memo(VideoCard);
