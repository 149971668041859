/* eslint-disable jsx-a11y/anchor-is-valid */
//import liraries

// create a component
const Paginator = ({ currPage, totalPages, changePage, maxPages }) => {
  if (totalPages === 0) return <div></div>;
  // New logic start:
  // --------------- //
  const pageSize = 10;
  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currPage - maxPagesBeforeCurrentPage;
      endPage = currPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  // let startIndex = (currPage - 1) * pageSize;
  // let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages =
    endPage &&
    startPage &&
    Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);

  // --------------- //
  // New logic end

  const firstPage =
    currPage +
    (currPage % maxPages === 0 ? -maxPages + 1 : -((currPage % maxPages) - 1));
  const possiblePages =
    firstPage + maxPages > totalPages ? totalPages - firstPage + 1 : maxPages;

  const changePageCheck = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      changePage(newPage);
    }
  };

  return (
    <div className="pagination-wrapper">
      <ul className="pagination pagination-sm">
        <li className={currPage === 1 ? "disabled" : "page-item"}>
          <a
            className="page-link"
            role="button"
            onClick={() => changePageCheck(1)}
          >
            <span aria-hidden="true">«</span>
          </a>
        </li>
        <li className={currPage === 1 ? "disabled" : "page-item"}>
          <a
            className="page-link"
            role="button"
            onClick={() => changePageCheck(currPage - 1)}
          >
            <span aria-hidden="true">‹</span>
          </a>
        </li>

        {/* old code */}

        {/* {[...Array(possiblePages)].map((page, index) => (
          <div key={index}>
            <li
              className={`page-item ${
                currPage === firstPage + index && "active"
              }`}
            >
              <a
                onClick={() => changePageCheck(firstPage + index)}
                className="page-link"
                role="button"
              >
                {firstPage + index}
              </a>
            </li>
          </div>
        ))} */}
        {/* old code end */}

        {pages &&
          pages.map((page, index) => (
            <div key={index}>
              <li className={`page-item ${currPage === page && "active"}`}>
                <a
                  onClick={() => changePageCheck(page)}
                  className="page-link"
                  role="button"
                >
                  {page}
                </a>
              </li>
            </div>
          ))}
        <li className={totalPages === currPage ? "disabled" : "page-item"}>
          <a
            className="page-link"
            role="button"
            onClick={() => changePageCheck(currPage + 1)}
          >
            <span aria-hidden="true">›</span>
          </a>
        </li>
        <li className={totalPages === currPage ? "disabled" : "page-item"}>
          <a
            className="page-link"
            role="button"
            onClick={() => changePageCheck(totalPages)}
          >
            <span aria-hidden="true">»</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

// define your styles

//make this component available to the app
export default Paginator;
