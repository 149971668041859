/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useCallback, useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import ExercisesModule from "./partials/exercisesModule";
import Paginator from "../../../components/paginator";
import MessageModal from "../../../components/messageModal";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import { getExerciseList, deleteExerciseDetails } from "../../../redux/actions/patientExercises";
import { useSelector } from "react-redux";
import { errors } from "../../../constants/general";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";
import useSearch from "hooks/useSearch";

const PatientMkinetikosExercises = (props) => {
  const { patient_id } = useParams("patient_id");

  const exercisesInfo = useSelector((state) => state.patientExercises.exercise_list);
  // const searchOptions = useSelector((state) => state.searchFilter);

  const exerciseList = exercisesInfo.obj_list;

  const dispatch = useDispatch();
  const [isExerciseListLoading, setIsExerciseListLoading] = useState(false);

  const [isDeleteExerciseModalOpenId, setIsDeleteExerciseModalOpenId] = useState(null);

  const { t, i18n } = useTranslation();

  const requestPatientExercices = () => {
    setIsExerciseListLoading(true);
    dispatch(
      getExerciseList(patient_id, {
        page: searchOptions.page,
        q: searchOptions.q,
        filter_type: searchOptions.filterType ? searchOptions.filterType : "all",
        order_by: searchOptions.orderBy,
      })
    ).then(() => {
      setIsExerciseListLoading(false);
    });
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy, updateFilterType } =
    useSearch(requestPatientExercices, 300, "exercises");

  const deleteExercise = (exercise_id) => {
    dispatch(deleteExerciseDetails(patient_id, exercise_id)).then(() => {
      if (isDeleteExerciseModalOpenId) setIsDeleteExerciseModalOpenId(null);
      requestPatientExercices();
    });
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.push(`/patient/${patient_id}/evaluations`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.exercises")}</h4>
      </div>
      <ExercisesModule
        deleteExercise={setIsDeleteExerciseModalOpenId}
        isExerciseListLoading={isExerciseListLoading}
        searchOptions={searchOptions}
        updateSearchText={updateSearchText}
        updateFilterType={updateFilterType}
        updateOrderBy={updateOrderBy}
      />
      {exerciseList.length > 0 && (
        <div className="pagination-wrapper">
          <Paginator
            currPage={exercisesInfo.page}
            totalPages={exercisesInfo.num_pages}
            maxPages={3}
            changePage={updatePage}
          />
        </div>
      )}
      {isDeleteExerciseModalOpenId !== null && (
        <MessageModal
          headerText={t("modals.delete_exercise_header")}
          body={t("modals.delete_exercise_body")}
          footerButtons={[
            <button
              onClick={() => setIsDeleteExerciseModalOpenId(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                type="button"
                value={t("buttons.delete")}
                onClick={() => deleteExercise(isDeleteExerciseModalOpenId)}
                className="warning-button small-wider-button"
              />
            </div>,
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(PatientMkinetikosExercises);
