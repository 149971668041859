/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import PrescriptionsModule from "./partials/prescriptionsModule";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import crossIcon from "../../../assets/images/cross_darkgrey.svg";
import crossSelectedIcon from "../../../assets/images/cross_darkgrey_selected.svg";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";

const PatientMkinetikosPrescriptions = (props) => {
  const { patient_id } = useParams("patient_id");

  const { t, i18n } = useTranslation();
  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.replace(
              `/patient/${patient_id}/mkinetikos_medicines`
            );
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.prescriptions")}</h4>
      </div>
      <div className="regular-text">{t("patient.meds.view_mode")}</div>
      <div className="programs-filter-container">
        <div
          className="programs-filter-btn active"
          onClick={() => {
            props.history.replace(
              `/patient/${patient_id}/mkinetikos_medicines`
            );
          }}
        >
          {t("patient.meds.prescriptions")}
        </div>
        <div
          onClick={() => {
            props.history.replace(
              `/patient/${patient_id}/mkinetikos_medicines`
            );
          }}
        >
          <img
            alt="Close Programs Filter"
            className="programs-filter-close-btn"
            onMouseOver={(e) => (e.currentTarget.src = crossSelectedIcon)}
            onMouseOut={(e) => (e.currentTarget.src = crossIcon)}
            src={crossIcon}
          />
        </div>
      </div>
      {
        <PrescriptionsModule
          goToShow={(prescription_id) =>
            props.history.push(
              `/patient/${patient_id}/mkinetikos_prescriptions/${prescription_id}/show`
            )
          }
          goToCreate={() =>
            props.history.push(
              `/patient/${patient_id}/mkinetikos_prescriptions/create`
            )
          }
          goToEdit={(prescription_id) =>
            props.history.push(
              `/patient/${patient_id}/mkinetikos_prescriptions/${prescription_id}/edit`
            )
          }
        />
      }
    </div>
  );
};

export default React.memo(PatientMkinetikosPrescriptions);
