//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************
import { parseDateFromDatetime, formatDate } from "utils/dataAndTime";
import { nameShortener } from "utils/avatar";

//* ***************************************** STYLES *****************************************
import Styles from "./styles";

//* ***************************************** ASSETS *****************************************
import logo from "assets/images/logo-primary.png";

const ResultPdfTemplate = ({ patientInfo, data, option, downloadedBy }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const companyName = "Kinetikos Driven Solutions, SA";
  const options = {
    test: t("labels.test_report"),
    survey: t("labels.survey_report"),
  };

  //* ************************************** PARTIALS ****************************************
  const __renderSurveyAnswer = (answers) => {
    return answers && answers?.length > 0
      ? Array.isArray(answers)
        ? answers?.map((answer, index) => {
            return index < answers.length - 1 ? answer + ", " : answer;
          })
        : answers
      : "-----";
  };

  const __renderSurveyResult = () => {
    return (
      <>
        <Text style={[Styles.boldText, Styles.sectionTitle]}>{t("labels.questions")}</Text>
        {data?.result_questions?.map((question, index) => {
          return (
            <>
              <View style={Styles.questionAnswer} key={index}>
                <Text style={Styles.normalText}>Q: {question.question}</Text>
                <Text style={Styles.boldText}>A: {__renderSurveyAnswer(question?.answer)}</Text>
              </View>
              <View style={Styles.separator} key={index}></View>
            </>
          );
        })}
      </>
    );
  };

  const __renderTestResult = () => {
    return (
      <>
        <Text style={[Styles.boldText, Styles.sectionTitle]}>{t("buttons.show_results")}</Text>
        {data?.result_metrics?.map((question, index) => {
          return (
            <>
              <View style={Styles.questionAnswer} key={index}>
                <Text style={Styles.normalText}>
                  {t("patient.programs.metric")}: {question.metric}
                </Text>
                <Text style={Styles.boldText}>
                  {t("patient.programs.value")}:{" "}
                  {Array.isArray(question.value)
                    ? question.value.map((value, index) =>
                        question.value.length - 1 === index
                          ? `{${Number(value)?.toFixed(2)}} `
                          : `{${Number(value)?.toFixed(2)}}, `
                      )
                    : Number(question?.value)?.toFixed(2)}{" "}
                  {question?.units ? question.units : ""}
                </Text>
                <Text style={Styles.boldText}>
                  {t("patient.programs.std")}:{" "}
                  {question?.std ? Number(question?.std)?.toFixed(2) : "-----"}
                </Text>
              </View>
              <View style={Styles.separator} key={index}></View>
            </>
          );
        })}
      </>
    );
  };

  const __renderResults = (option) => {
    switch (option) {
      case "survey":
        return __renderSurveyResult();
      case "test":
        return __renderTestResult();
    }
  };
  // patientInfo.mkinetikos_photo
  //* *************************************** RENDER *****************************************
  return (
    <Document title={options[option]} author={companyName}>
      <Page size="A4" style={Styles.page}>
        <View style={Styles.header} fixed>
          <View style={Styles.profileImageContainer}>
            {patientInfo.mkinetikos_photo ? (
              <Image src={patientInfo.mkinetikos_photo} style={Styles.profileImage} cache={false} />
            ) : (
              <View style={Styles.profileImage}>
                <Text>{nameShortener(patientInfo.name)}</Text>
              </View>
            )}
            <View>
              <Text style={Styles.patinetName}>{patientInfo.name}</Text>
              <Text style={Styles.patinetEmail}>{patientInfo.email}</Text>
            </View>
          </View>
          <Image src={logo} style={Styles.logo} />
        </View>
        <View style={Styles.evaluationSection}>
          <View style={Styles.section}>
            <Text style={Styles.boldText}>{t("labels.evaluation")}</Text>
            <Text style={Styles.normalText}>{data.name}</Text>
          </View>
          <View style={Styles.section}>
            <Text style={Styles.boldText}>{t("patient.programs.submission_date")}</Text>
            <Text style={Styles.normalText}>{parseDateFromDatetime(data.submission_date)}</Text>
          </View>
          <View style={Styles.section}>
            <Text style={Styles.boldText}>{t("buttons.show_results")}</Text>
            <Text style={Styles.normalText}>
              {data.result !== null && data.result !== undefined
                ? data.result?.toFixed(2)
                : "-----"}
            </Text>
          </View>
          {option === "survey" && (
            <View style={Styles.section}>
              <Text style={Styles.boldText}>{t("patient.programs.raw_score")}</Text>
              <Text style={Styles.normalText}>
                {data.absolute_value !== null && data.absolute_value !== undefined
                  ? data.absolute_value
                  : "-----"}
              </Text>
            </View>
          )}
        </View>
        <View style={Styles.separator} />

        <View style={Styles.questionAnswerSection}>{__renderResults(option)}</View>

        <View
          style={[Styles.separator, { position: "absolute", bottom: 15, left: 0, right: 0 }]}
          fixed
        />
        <View style={Styles.footer} fixed>
          <Text style={Styles.footerText}>
            {options[option]} - {patientInfo.name}
          </Text>
          <Text
            style={Styles.footerText}
            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          />
        </View>
        <View style={Styles.downloadByContainer} fixed>
          <Text style={Styles.downloadByText}>
            {t("pdf_templates.downloaded_by")} {downloadedBy.fullName} {t("on")}{" "}
            {formatDate(downloadedBy.date, "YYYY MMM DD")} {t("at")}{" "}
            {formatDate(downloadedBy.date, "hh:mm")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default React.memo(ResultPdfTemplate);
