/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useState, useEffect, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *************************************** HOOKS ***************************************
import useOnClickOutside from "hooks/useOnClickOutside";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "components/spinner";
import DropdownOptions from "components/dropdownOptions";
import Paginator from "components/paginator";
import MessageModal from "components/messageModal";
import OptionsButton from "components/optionsButton";
import NavBar from "components/navbar";

//* *********************************** REDUX ***********************************
import {
  getEvaluationTemplates,
  markTemplateAsFavourite,
  removeFromTemplates,
} from "redux/actions/evaluationTemplates";

//* ************************************** GLOBALS ***************************************
import template_book_icon from "../../../assets/images/buttonIcons/template_book.svg";
import search from "../../../assets/images/search.svg";
import filter_list_blue from "../../../assets/images/filter-list-blue.svg";
import filter_list_white from "../../../assets/images/filter-list-white.svg";
import starYellow from "../../../assets/images/star-yellow.svg";
import chevronUpSelectedIcon from "../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownSelectedIcon from "../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";

const EvaluationTemplates = (props) => {
  const dispatch = useDispatch();

  const filterDropDownRef = useRef(null);
  const filterButtonRef = useRef(null);

  const templateDropdownRef = useRef(null);
  const templateButtonRef = useRef([]);

  const [isEvaluationListLoading, setIsEvaluationListLoading] = useState(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const lastSelectedTemplateId = useRef(null);
  const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
    useState(false);
  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);

  const [currSelectedFilter, setCurrSelectedFilter] = useState("all");
  const [searchText, setSearchedText] = useState("");

  const [orderBy, setOrderBy] = useState("");

  // Query strings for ordering when it's implemented in the backend
  const order = {
    name: "name",
    nameInv: "name_inv",
    process: "process",
    processInv: "process_inv",
    lastEval: "eval",
    lastEvalInv: "eval_inv",
  };

  const filterImage = useRef(null);

  const evaluationTemplatesInfo = useSelector(
    (state) => state.evaluationTemplates.templates
  );
  const evaluationTemplatesList = evaluationTemplatesInfo.obj_list;

  const { t, i18n, ready } = useTranslation();

  const filterTypes = {
    all: t("filters.all"),
    favorite: t("filters.favorite"),
    user: t("filters.private"),
    institution: t("filters.public"),
  };

  useOnClickOutside([filterDropDownRef, filterButtonRef], () =>
    setFilterOptionsOpen(null)
  );

  useOnClickOutside([templateButtonRef, templateDropdownRef], () =>
    setSelectedTemplateId(null)
  );

  useEffect(() => {
    requestEvaluationsTemplates(1);
  }, [currSelectedFilter, searchText]);

  useEffect(() => {
    if (selectedTemplateId !== null)
      lastSelectedTemplateId.current = selectedTemplateId;
  }, [selectedTemplateId]);

  //* ************************************** HANDLERS **************************************

  const requestEvaluationsTemplates = (page) => {
    setIsEvaluationListLoading(true);
    dispatch(
      getEvaluationTemplates({
        page,
        filter_type: currSelectedFilter,
        search: searchText,
      })
    )
      .then(() => {
        setIsEvaluationListLoading(false);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const requestMarktemplateAsFavourite = (id) => {
    dispatch(markTemplateAsFavourite(id))
      .then(() => {
        console.log("sucess");
        // Refresh templates list so favorite requests show on the top
        requestEvaluationsTemplates(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsEvaluationListLoading(false));
  };

  const removeTemplate = (id) => {
    dispatch(removeFromTemplates(id))
      .then(() => {
        setIsDeleteTemplateModalOpen(false);
        console.log("sucess");
        if (evaluationTemplatesList.length === 1) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Error, something went wrong with your request");
      });
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.evaluation_templates")} />
      <div className="mt-4">
        <span className="regular-text">
          {t("my_area.templates.templates_info")}
        </span>
      </div>

      <div className="profile-evaluation-templates">
        <div className="row-space-between">
          <span className="small-title">{t("my_area.templates.list")}</span>
          <button
            onClick={() =>
              props.history.push({
                pathname: "/my_area/evaluation_templates/create",
                cameFromEvaluationTemplateHome: true,
              })
            }
            className="button small-button primary-button"
          >
            <img
              alt="plus-icon"
              className="button-icon"
              src={template_book_icon}
            />
            {t("buttons.add_template")}
          </button>
        </div>
        <div className="row search-filter-wrapper">
          <div className="col">
            <div style={{ position: "relative" }}>
              <input
                value={searchText}
                onChange={(e) => setSearchedText(e.target.value)}
                type="text"
                className="search-input w-100"
                placeholder={t("placeholders.search_template")}
              />
              <img alt="search-icon" className="search-icon" src={search} />
            </div>
          </div>
          <div className="col-auto filter-button">
            <button
              onClick={(e) => {
                e.stopPropagation();
                filterOptionsOpen
                  ? setFilterOptionsOpen(false)
                  : setFilterOptionsOpen(true);
              }}
              ref={filterButtonRef}
              onMouseOver={() =>
                !filterOptionsOpen &&
                (filterImage.current.src = filter_list_white)
              }
              onMouseLeave={() =>
                !filterOptionsOpen &&
                (filterImage.current.src = filter_list_blue)
              }
              className={`button justify-content-center small-button ${
                filterOptionsOpen ? "marked-button" : "tertiary-button"
              }`}
            >
              <img
                ref={filterImage}
                alt="plus-icon"
                className="button-icon"
                src={filterOptionsOpen ? filter_list_white : filter_list_blue}
              />
              {t("buttons.filter")}
            </button>
            {filterOptionsOpen && (
              <DropdownOptions
                ref={filterDropDownRef}
                buttons={[
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.all_templates")}
                      </span>
                    ),
                    action: () => {
                      setCurrSelectedFilter("all");
                      setFilterOptionsOpen(false);
                    },
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.favourite_template")}
                      </span>
                    ),
                    action: () => {
                      setCurrSelectedFilter("favorite");
                      setFilterOptionsOpen(false);
                    },
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.private_template")}
                      </span>
                    ),
                    action: () => {
                      setCurrSelectedFilter("user");
                      setFilterOptionsOpen(false);
                    },
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.public_template")}
                      </span>
                    ),
                    action: () => {
                      setCurrSelectedFilter("institution");
                      setFilterOptionsOpen(false);
                    },
                  },
                ]}
              />
            )}
          </div>
          <div className="filter-by-text">
            {t("filters.filtered_by")}
            <span style={{ textDecoration: "underline" }}>
              {ready && filterTypes[currSelectedFilter]}
            </span>
          </div>
        </div>
        <div className="evaluation-templates-list">
          <div className="row list-label template-label-wrapper">
            <div className="col-4 d-flex align-items-center">
              <div className="list-label">{t("my_area.templates.name")}</div>
              <div
                className={`order-img-container ${
                  orderBy === order.name || orderBy === order.nameInv
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  setOrderBy(
                    orderBy === order.name ? order.nameInv : order.name
                  )
                }
              >
                {orderBy !== order.name && (
                  <img src={chevronUpSelectedIcon} alt="Sort by name " />
                )}
                {orderBy !== order.nameInv && (
                  <img
                    src={chevronDownSelectedIcon}
                    alt="Sort by name descending"
                  />
                )}
              </div>
            </div>

            <div className="col-4 d-flex align-items-center">
              <div className="list-label">{t("my_area.templates.owner")}</div>
            </div>

            <div className="col-5 d-flex align-items-center">
              <div className="list-label"></div>
            </div>
          </div>
          {isEvaluationListLoading ? (
            <div className="loading-wrapper">
              <Spinner type="big-blue" />
            </div>
          ) : evaluationTemplatesList.length === 0 ? (
            <div className="medium-base-color-text message-wrapper">
              <p>{t("my_area.templates.none_available")}</p>
              <p>{t("my_area.templates.can_create_new")}</p>
            </div>
          ) : (
            <div>
              {evaluationTemplatesList.map((template, index) => (
                <div
                  key={template.id + index.toString()}
                  className="row template-info-wrapper regular-text black-color"
                >
                  <div className="col-4 align-items-center">
                    {template.is_favorite && (
                      <img alt="Star" src={starYellow} className="mr-1"></img>
                    )}
                    {template.name}
                  </div>
                  <div className="col-3">{template.owner}</div>
                  <div className="col-5">
                    <div className="d-flex flex-direction-row justify-content-end align-items-center">
                      {template.is_favorite && (
                        <i className="favourite-text">
                          {t("my_area.templates.marked_favourite")}
                        </i>
                      )}
                      <div className="more-image-wrapper">
                        <OptionsButton
                          ref={(el) => (templateButtonRef.current[index] = el)}
                          isSelected={selectedTemplateId === template.id}
                          onClick={() =>
                            selectedTemplateId === template.id
                              ? setSelectedTemplateId(null)
                              : setSelectedTemplateId(template.id)
                          }
                        />
                        {selectedTemplateId === template.id && (
                          <DropdownOptions
                            ref={templateDropdownRef}
                            buttons={[
                              {
                                text: (
                                  <span className="regular-text">
                                    {template.is_favorite
                                      ? t("my_area.templates.unmark_favourite")
                                      : t("my_area.templates.mark_favourite")}
                                  </span>
                                ),
                                action: () => {
                                  requestMarktemplateAsFavourite(template.id);
                                  setSelectedTemplateId(null);
                                },
                              },
                              template.can_edit && {
                                text: (
                                  <span className="regular-text">
                                    {t("buttons.edit")}
                                  </span>
                                ),
                                action: () => {
                                  props.history.push(
                                    `/my_area/evaluation_templates/${template.id}/edit`
                                  );
                                },
                              },
                              template.can_edit && {
                                text: (
                                  <span className="regular-text warning">
                                    {t("buttons.delete")}
                                  </span>
                                ),
                                action: () => {
                                  setIsDeleteTemplateModalOpen(true);
                                  setSelectedTemplateId(null);
                                },
                              },
                            ].filter(Boolean)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="pagination-wrapper">
                <Paginator
                  currPage={evaluationTemplatesInfo.page}
                  totalPages={evaluationTemplatesInfo.num_pages}
                  maxPages={3}
                  changePage={requestEvaluationsTemplates}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isDeleteTemplateModalOpen && (
        <MessageModal
          headerText={t("my_area.templates.delete")}
          body={t("my_area.templates.delete_confirm")}
          footerButtons={[
            <button
              onClick={() => setIsDeleteTemplateModalOpen(false)}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={() => {
                removeTemplate(lastSelectedTemplateId.current);
              }}
              className="small-button warning-button"
            >
              {t("buttons.delete")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(EvaluationTemplates);
