import {
  GET_MEDICINE_LIST_SUCCESS,
  GET_MEDICINE_LIST_FAIL,
  GET_MEDICINE_DETAILS_SUCCESS,
  GET_MEDICINE_DETAILS_FAIL,
  UPDATE_MEDICINE_SUCCESS,
  UPDATE_MEDICINE_FAIL,
} from "../actions/patientMedicines";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  medicine_list: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
    links: {
      has_next: false,
      has_previous: false,
    },
    has_opened_patient_medicines_page: false,
  },
  curr_medicine: {
    text: "",
    id: null,
    notes: "",
    end_date: "",
    prescription: {
      prescription_id: null,
      prescription_inst: "",
      prescription_inst_id: null,
      prescription_name: "",
    },
    is_sos: false,
    days: [],
    times: [],
  },
};

const PatientsMedicines = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICINE_LIST_SUCCESS: {
      return { ...state, medicine_list: action.payload.data.data };
    }
    case GET_MEDICINE_LIST_FAIL: {
      return { ...state, medicine_list: initialState.medicine_list };
    }
    case GET_MEDICINE_DETAILS_SUCCESS:
    case UPDATE_MEDICINE_SUCCESS: {
      return { ...state, curr_medicine: { ...action.payload.data.data } };
    }
    case GET_MEDICINE_DETAILS_FAIL:
    case UPDATE_MEDICINE_FAIL: {
      return { ...state, curr_medicine: initialState.curr_medicine };
    }

    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default PatientsMedicines;
