import React from "react";

import { useSelector } from "react-redux";
import i18next from "i18next";
import { store } from "../../redux/store";
import { parseDate } from "../../utils/dataAndTime";
import kinetikosAddress from "../../assets/images/loginpageicon/address-icon.svg";
import kinetikosEstablished from "../../assets/images/loginpageicon/established-icon.svg";

import logoLeft from "../../assets/images/logo-blue.svg";

import snIcon from "../../assets/images/loginpageicon/sn-icon.svg";
import ceIcon from "../../assets/images/loginpageicon/ce-icon.svg";
import icon1 from "../../assets/images/loginpageicon/icon-1.svg";
import icon2 from "../../assets/images/loginpageicon/icon-2.svg";
import icon3 from "../../assets/images/loginpageicon/icon-3.svg";
import ScallingBallAnimation from "../../pages/authentication/partials/scallingBallAnimation";
import kinetikosColapsed from "../../assets/images/logo-colapsed.svg";
import { sendCrashLog } from "../../redux/actions/errorBoundary";

const company_name = "Kinetikos Platform";
const product_version = process.env.REACT_APP_PRODUCT_VERSION;
const product_date = process.env.REACT_APP_PRODUCT_VERSION_DATE;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eror: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // HANDLE CRASH LOG HERE

    console.log(store.getState().user);
    store
      .dispatch(
        sendCrashLog({
          error: error.toString(),
          stack: errorInfo.componentStack,
        })
      )
      .then(
        (res) => "Crash log was sent",
        (err) => console.log(err)
      )
      .finally(() => console.log("Handled error"));

    // DELETE CACHE HERE
    localStorage.removeItem("persist:root");
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="main-wrapper">
          <div className="row g-0">
            <div className="col auth-left-side">
              <div className="footer-top-animation">
                <ScallingBallAnimation />
                <img
                  alt="authBackground"
                  className="auth-left-logo"
                  src={logoLeft}
                />
              </div>

              <div className="row footer-company-info">
                <span className="regular-text text--grey-intense header-text">
                  {company_name}
                </span>
                <div className="col">
                  <div className="col">
                    <img src={kinetikosAddress} />
                    <p className="ml-4">
                      <b>Kinetikos Driven Solutions, SA</b>
                      <br></br>
                      Rua Pedro Nunes, Edifício C.<br></br>
                      3030-199 Coimbra, Portugal
                    </p>
                  </div>
                  <div className="col">
                    <img src={kinetikosEstablished} />
                    <p className="ml-4">{parseDate(product_date)}</p>
                  </div>
                  <div className="col">
                    <p>{new Date().getFullYear()} © Kinetikos</p>
                  </div>
                </div>

                <div className="col flex-d-column">
                  <div className="col">
                    <img src={snIcon} />
                    <span className="regular-text text--grey-intense product_version">
                      {product_version}
                    </span>
                    <img src={ceIcon} className="ml-2" />
                  </div>

                  <div className="col">
                    <img src={icon1} className="mr-3" />
                    <img src={icon2} className="mr-3" />
                    <img src={icon3} />
                  </div>
                  <div className="col">
                    <p className="mt-1">UDI-DI 05600638781804</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col auth-right-side">
              <div className="scroll">
                <div className="row g-0 align-self-center align-items-center justify-content-center">
                  <div className="col-8 form-auth-container">
                    <div className="form-auth-wrapper">
                      <h3>
                        {i18next.t("authentication.error.something_wrong")}
                      </h3>
                      <img
                        alt="Kinetikos information"
                        className="kinetikos-colapsed"
                        src={kinetikosColapsed}
                      />

                      <div className="form-auth-bottom text-center p-0">
                        <div className="auth-bottom-element inline-block p-0">
                          <span className="regular-text">
                            {i18next.t(
                              "authentication.error.having_difficulties"
                            )}
                            &nbsp;
                          </span>
                          <span
                            className="button link-text"
                            onClick={() => window.location.replace("/home")}
                          >
                            {i18next.t("authentication.error.back_to_login")}
                          </span>
                        </div>

                        <div className="auth-bottom-element inline-block">
                          <a href="mailto:support@kinetikoshealth.com">
                            <span className="regular-text">
                              {i18next.t(
                                "authentication.error.problem_persists"
                              )}
                              &nbsp;
                            </span>
                            <span className="button link-text">
                              {i18next.t("authentication.error.support_center")}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
{
  /* <h2>Something went wrong.</h2>
              <details style={{ whiteSpace: "pre-wrap" }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details> */
}
export default ErrorBoundary;
