import React from "react";

import Slider from "rc-slider";
import { useTranslation } from "react-i18next";

const RiskOfFall = ({ value, onChange, disabled }) => {
  const { t, i18n } = useTranslation();

  const riskOfFallMarks = {
    0: t("low"),
    1: t("medium"),
    2: t("high"),
  };

  return (
    <>
      <div
        style={{ width: "400px", marginLeft: "10px" }}
        className="regular-text"
      >
        <Slider
          disabled={disabled}
          min={0}
          max={2}
          marks={riskOfFallMarks}
          onChange={(value) => onChange("risk_of_fall_th", value)}
          step={null}
          dots={false}
          value={value}
          defaultValue={0}
          trackStyle={{ backgroundColor: "#EFF3F6", height: "4px" }}
          railStyle={{ backgroundColor: "#508CC0", height: "4px" }}
          dotStyle={{
            backgroundColor: "#508CC0",
            borderRadius: 0,
            height: "10px",
            top: -3,
            width: "2px",
            borderColor: "#508CC0",
          }}
          activeDotStyle={{ borderColor: "#508CC0" }}
          handleStyle={{
            borderColor: "#508CC0",
            width: 20,
            height: 20,
            marginTop: -8,
            boxShadow: "none",
          }}
        />
      </div>{" "}
    </>
  );
};
export default React.memo(RiskOfFall);
