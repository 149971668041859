import moment from "moment";
import { parseISO, format } from "date-fns";
import { geti18nLanguage } from "utils/language";
import { enGB, pt } from "date-fns/locale";

export const formatDate = (date, pattern = "YYYY-MM-DD") =>
  date instanceof Date ? moment(date).format(pattern) : date.format(pattern);

/**
   Convert ISO to Date
**/
export const isoToDate = (isoDate) => parseISO(isoDate);

export const hourStringToTime = (hourString, sep = ":") =>
  hourString.split(sep).map((val) => parseInt(val));

/**
   @todo: move months labels to I18n
   @todo: to be replaced 
**/
export const parseDate = (date) => {
  try {
    let dateArray = [];
    if (date.toString().indexOf("-") > -1) {
      dateArray = date.toString().split("-");
    } else if (date.toString().indexOf("/") > -1) {
      dateArray = date.toString().split("/");
    } else if (date.toString().indexOf(".") > -1) {
      dateArray = date.toString().split(".");
    }

    if (dateArray.length !== 3) return "-----";

    const monthsInits = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${dateArray[2]} ${monthsInits[dateArray[1] - 1]} ${dateArray[0]}`;
  } catch {
    return "-----";
  }
};

export const parseDateFromDatetime = (datetime) => {
  try {
    const datetimeArray = datetime.split("T");
    return parseDate(datetimeArray[0]);
  } catch {
    return "-----";
  }
};

export const dateToString = (date) => {
  return `${date.getFullYear()}-${
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
};

export const isDateBetweenDates = (date, startDate, endDate) => {
  var currDate = moment(date, "YYYY-MM-DD");
  var currStartDate = moment(startDate, "YYYY-MM-DD");
  var currEndDate = moment(endDate, "YYYY-MM-DD");

  // omitting the optional third parameter, 'units'
  return currDate.isBetween(currStartDate, currEndDate, null, "[]");
};

export const timeToDecimal = (time) => {
  return moment.duration(time).asHours();
};

/**
   @todo: to be replaced 
**/
export const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const getCurrMonthBetweenDates = () => {
  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment();
  return { startOfMonth, endOfMonth };
};

export const getLastMonthBetweenDates = () => {
  const startOfLastMonth = moment().subtract(1, "months").startOf("month");

  const endOflastMonth = moment().subtract(1, "months").endOf("month");

  return { startOfLastMonth, endOflastMonth };
};

export const parseTime = (time) => {
  try {
    const timeArray = time.split(":");
    return `${timeArray[0]}:${timeArray[1]}`;
  } catch {
    return "-----";
  }
};

/**
   @todo: include the translation of yesterday and today
**/
export const transformDateToTodayOrYesterday = (date) => {
  const today = moment().format("DD MMM YYYY");
  if (date === today) return "Today";
  const yesterday = moment().subtract(1, "days").format("DD MMM YYYY");
  if (date === yesterday) return "Yesterday";

  return date;
};

/**
 * Returns last seven days
 *
 * @return {date[]} array of last seven days
 */
export const getLastSevenDays = () => {
  const lastSevenDays = 7;
  const weekDays = [];

  for (var i = 0; i < lastSevenDays; i++) {
    let newDate = new Date();
    newDate.setDate(newDate.getDate() - i);
    weekDays.push(newDate);
  }
  return weekDays.reverse();
};

/**
 * Returns week day abbreviation
 *
 * @param {date} date a date
 *
 * @return {string} translated week day abbreviation (ex: wed)
 */
export const getWeekDayAbbrevation = (date) => {
  return format(date, "eee", {
    locale: getDateFnsTranslation(geti18nLanguage()),
  });
};

export const getDateFnsTranslation = (language) => {
  if (language === "en-GB") return enGB;
  if (language === "pt-PT") return pt;

  return enGB;
};

export const getDateDiff = (date1, date2 = new Date()) => {
  const startDate = moment(date2, "YYYY-MM-DD");
  const endDate = moment(date1, "YYYY-MM-DD");
  const diff = endDate.diff(startDate);
  const diffDuration = moment.duration(diff);

  return diffDuration.days();
};

export const getTimeDateDiff = (date) => {
  return moment.duration(date).days() != 0
    ? moment.duration(date).days() + "d"
    : moment.duration(date).hours() != 0
    ? moment.duration(date).hours() + "h"
    : moment.duration(date).minutes() != 0
    ? moment.duration(date).minutes() + "m"
    : moment.duration(date).seconds() + "s";
};

export const getPastDate = (numberOfDays = 6, format = "YYYY-MM-DD") => {
  return moment().subtract(numberOfDays, "d").format(format);
};

export const stringToDate = (dateString) => moment(dateString);

export const getToday = () => moment();
