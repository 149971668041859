import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import search from "../../../../assets/images/search.svg";
import MessageModal from "../../../../components/messageModal";
import { errors } from "../../../../constants/general";
import {
  getRiskAssementPatients,
  followPatients,
  unfollowPatients,
  getRiskAssementFollowingPatients,
  getRiskAssementUnfollowingPatients,
} from "../../../../redux/actions/riskAlert";
import Spinner from "../../../../components/spinner";

import { useTranslation } from "react-i18next";

const PatientFollow = ({ setSelectedCustomizePatientOpen }) => {
  const [followsSearchText, setFollowsSearchText] = useState("");
  const [unfollowsSearchText, setUnfollowsSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowSearch, setIsFollowSearch] = useState(false);
  const [isUnfollowSearch, setIsUnfollowSearch] = useState(false);

  const [selectedFollowIds, setSelectedFollowIds] = useState([]);
  const [selectedUnfollowIds, setSelectedUnfollowIds] = useState([]);
  const [openUnfollowModalPatientId, setOpenUnfollowModalPatientId] =
    useState(null);

  const [openUnfollowModalPatients, setOpenUnfollowModalPatients] =
    useState(false);

  const dispatch = useDispatch();

  const following_patients_list = useSelector(
    (state) => state.riskAlert.global_settings.following_list
  );

  const patient_list = useSelector(
    (state) => state.riskAlert.global_settings.unfollowing_list
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getRiskAssementPatients())
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const changeSelectedFollowing = (id) => {
    if (selectedFollowIds.includes(id)) {
      const newSelectedList = selectedFollowIds.filter(
        (followingId) => followingId !== id
      );
      setSelectedFollowIds(newSelectedList);
    } else {
      setSelectedFollowIds([...selectedFollowIds, id]);
    }
  };

  const changeSelectedUnfollowing = (id) => {
    if (selectedUnfollowIds.includes(id)) {
      const newSelectedList = selectedUnfollowIds.filter(
        (followingId) => followingId !== id
      );
      setSelectedUnfollowIds(newSelectedList);
    } else {
      setSelectedUnfollowIds([...selectedUnfollowIds, id]);
    }
  };

  const unfollowPatient = (patientId) => {
    setIsLoading(true);
    dispatch(unfollowPatients([patientId]))
      .then(() =>
        dispatch(getRiskAssementPatients()).catch((error) =>
          alert(errors.service_error)
        )
      )
      .catch((error) => alert(errors.service_error))
      .finally(() => {
        setIsLoading(false);
        setOpenUnfollowModalPatientId(null);
      });
  };

  const followPatient = (patientId) => {
    setIsLoading(true);
    dispatch(followPatients([patientId]))
      .then(() =>
        dispatch(getRiskAssementPatients()).catch((error) =>
          alert(errors.service_error)
        )
      )
      .catch((error) => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  };

  const checkIfAnyPatientHasCustomization = () => {
    following_patients_list.forEach((patient) => {
      if (selectedFollowIds.includes(patient.id) && patient.tag === "custom") {
        return true;
      }
    });
    return false;
  };

  const unfollowSelectedPatients = () => {
    setIsLoading(true);
    dispatch(unfollowPatients(selectedUnfollowIds))
      .then(() => {
        const newUnFollowArray = selectedUnfollowIds.filter(
          (val) => !selectedUnfollowIds.includes(val)
        );
        setSelectedUnfollowIds(newUnFollowArray);
        dispatch(getRiskAssementPatients()).catch((error) =>
          alert(errors.service_error)
        );
      })
      .catch((error) => alert(errors.service_error))
      .finally(() => {
        setIsLoading(false);
        setOpenUnfollowModalPatients(false);
      });
  };

  const followSelectedPatients = () => {
    setIsLoading(true);
    dispatch(followPatients(selectedFollowIds))
      .then(() => {
        const newFollowArray = selectedFollowIds.filter(
          (val) => !selectedFollowIds.includes(val)
        );
        setSelectedFollowIds(newFollowArray);
        dispatch(getRiskAssementPatients()).catch((error) =>
          alert(errors.service_error)
        );
      })
      .catch((error) => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  };

  const getFollowPatients = (q) => {
    if (q === "" || q.length > 2) {
      setIsLoading(true);
      setIsFollowSearch(true);
      dispatch(getRiskAssementFollowingPatients(q))
        .catch((error) => alert(errors.service_error))
        .finally(() => {
          setIsLoading(false);
          setIsFollowSearch(false);
        });
    }
  };

  const getUnfollowPatients = (q) => {
    if (q === "" || q.length > 2) {
      setIsLoading(true);
      setIsUnfollowSearch(true);
      dispatch(getRiskAssementUnfollowingPatients(q))
        .catch((error) => alert(errors.service_error))
        .finally(() => {
          setIsLoading(false);
          setIsUnfollowSearch(false);
        });
    }
  };

  return (
    <div className="row">
      <div className="col-lg col-md-12 profile-notification-patient-wrapper-left">
        <div className="profile-notification-patient-box">
          <div className="header">
            {t("my_area.risk.not_following")}
            <div className="row mt-2">
              <div className="col mt-1">
                <div className="position-relative">
                  <input
                    value={unfollowsSearchText}
                    onChange={(e) => {
                      setUnfollowsSearchText(e.target.value);
                      getUnfollowPatients(e.target.value);
                    }}
                    type="text"
                    className="search-input w-100"
                    placeholder="Search for patients"
                  />
                  <img alt="search-icon" className="search-icon" src={search} />
                  {isUnfollowSearch && (
                    <div className="search-input-loading">
                      <Spinner type={"small-blue"} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-auto mt-1">
                <button
                  onClick={followSelectedPatients}
                  disabled={selectedFollowIds.length === 0 || isLoading}
                  className="primary-button small-button"
                >
                  {t("buttons.follow")} ({selectedFollowIds.length})
                </button>
              </div>
              <div className="col-auto mt-1">
                <button
                  onClick={() => setSelectedFollowIds([])}
                  disabled={selectedFollowIds.length === 0 || isLoading}
                  className="quaternary-button small-button"
                >
                  {t("buttons.unselect")} ({selectedFollowIds.length})
                </button>
              </div>
            </div>
          </div>
          <div className="mt-3 patients">
            {!isLoading &&
              unfollowsSearchText !== "" &&
              patient_list.length === 0 && (
                <div className="d-flex-column d-flex h-100 justify-content-center align-items-center">
                  <div className="medium-base-color-text message-wrapper text-center">
                    <p>{t("my_area.risk.patient_not_found")}</p>
                  </div>
                </div>
              )}
            {patient_list.map((patient, index) => (
              <div key={patient.id} className="patient">
                <div className="row row-cols-2">
                  <div className="col check-box-wrapper pb-0">
                    <div className="input-wrapper">
                      <input
                        disabled={isLoading}
                        checked={selectedFollowIds.includes(patient.id)}
                        onChange={() => changeSelectedFollowing(patient.id)}
                        type={"checkbox"}
                      />
                    </div>
                    <label type="checkbox">
                      <span className="regular-text">{patient.full_name}</span>
                    </label>
                  </div>
                  <div className="col regular-text flex-d-row align-items-center justify-content-end">
                    <div
                      disabled={isLoading}
                      role="button"
                      onClick={() => followPatient(patient.id)}
                    >
                      {t("buttons.follow")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-lg col-md-12 profile-notification-patient-wrapper-right">
        <div className="profile-notification-patient-box">
          {" "}
          <div className="header">
            {t("my_area.risk.following")}
            <div className="row mt-2">
              <div className="col mt-1">
                <div className="position-relative">
                  <input
                    value={followsSearchText}
                    onChange={(e) => {
                      setFollowsSearchText(e.target.value);
                      getFollowPatients(e.target.value);
                    }}
                    type="text"
                    className="search-input w-100"
                    placeholder="Search for patients"
                  />
                  <img alt="search-icon" className="search-icon" src={search} />
                  {isFollowSearch && (
                    <div className="search-input-loading">
                      <Spinner type={"small-blue"} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-auto mt-1">
                <button
                  onClick={() => {
                    if (checkIfAnyPatientHasCustomization()) {
                      setOpenUnfollowModalPatients(true);
                    } else {
                      unfollowSelectedPatients();
                    }
                  }}
                  disabled={selectedUnfollowIds.length === 0 || isLoading}
                  className="primary-button small-button"
                >
                  {t("buttons.unfollow")} ({selectedUnfollowIds.length})
                </button>
              </div>
              <div className="col-auto mt-1">
                <button
                  onClick={() => setSelectedUnfollowIds([])}
                  disabled={selectedUnfollowIds.length === 0 || isLoading}
                  className="quaternary-button small-button"
                >
                  {t("buttons.unselect")} ({selectedUnfollowIds.length})
                </button>
              </div>
            </div>
          </div>
          <div className="mt-3 patients">
            {!isLoading &&
              followsSearchText === "" &&
              following_patients_list.length === 0 && (
                <div className="d-flex-column d-flex h-100 justify-content-center align-items-center">
                  <div className="medium-base-color-text message-wrapper text-center">
                    <p>{t("my_area.risk.not_following_any")}</p>
                  </div>
                </div>
              )}
            {!isLoading &&
              followsSearchText !== "" &&
              following_patients_list.length === 0 && (
                <div className="d-flex-column d-flex h-100 justify-content-center align-items-center">
                  <div className="medium-base-color-text message-wrapper text-center">
                    <p>{t("my_area.risk.patient_not_found")}</p>
                  </div>
                </div>
              )}
            {following_patients_list.map((patient, index) => (
              <div key={patient.id} className="patient">
                <div className="row row-cols-2">
                  <div className="col check-box-wrapper pb-0">
                    <div className="input-wrapper">
                      <input
                        disabled={isLoading}
                        checked={selectedUnfollowIds.includes(patient.id)}
                        onChange={() => changeSelectedUnfollowing(patient.id)}
                        type={"checkbox"}
                      />
                    </div>
                    <label type="checkbox">
                      <span className="regular-text">{patient.full_name}</span>
                    </label>
                  </div>
                  <div className="col regular-text flex-d-row align-items-center justify-content-end">
                    <div
                      disabled={isLoading}
                      role="button"
                      onClick={() =>
                        patient.tag === "custom"
                          ? setOpenUnfollowModalPatientId(patient.id)
                          : unfollowPatient(patient.id)
                      }
                    >
                      {t("buttons.unfollow")}
                    </div>
                    <div className="ml-1 mr-1">|</div>
                    <div
                      disabled={isLoading}
                      role="button"
                      onClick={() =>
                        setSelectedCustomizePatientOpen(patient.id)
                      }
                      className="cursor-pointer"
                    >
                      {t("buttons.customize")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {openUnfollowModalPatientId && (
        <MessageModal
          headerText={"Unfollow patient"}
          body={t("modals.unfollow_body")}
          footerButtons={[
            <button
              onClick={() => setOpenUnfollowModalPatientId(null)}
              className="transparent-button small-button"
            >
              {t("buttons.cancel")}
            </button>,
            <button
              onClick={() => unfollowPatient(openUnfollowModalPatientId)}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {openUnfollowModalPatients && (
        <MessageModal
          headerText={t("modals.unfollow_plural_header")}
          body={t("modals.unfollow_plural_body")}
          footerButtons={[
            <button
              onClick={() => setOpenUnfollowModalPatients(false)}
              className="transparent-button small-button"
            >
              {t("buttons.cancel")}
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                type="button"
                disabled={isLoading}
                value={t("buttons.confirm")}
                onClick={() => unfollowSelectedPatients()}
                className={`small-wider-button secondary-button ${
                  isLoading && "transparent"
                }`}
              />
              {isLoading && <Spinner type={"small"} />}
            </div>,
          ]}
        />
      )}
    </div>
  );
};
export default React.memo(PatientFollow);
