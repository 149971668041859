//* ******************************** REACT NATIVE IMPORTS ********************************
import React from "react";

//* *********************************** EXTERNAL PACKAGES ********************************
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************
import close from "../../../../assets/images/close_primary_back_white.svg";

//* ************************************** UTILS ***************************************
import { capitalizeFirstLetter } from "../../../../utils/string";

export default function EventPopup({ data, position, handleClose }) {
  //* ************************************** SETUP ***************************************
  const { t, i18n } = useTranslation();

  //* ************************************ PARTIALS **************************************
  const drawEventItem = (event, time, custom = false) => {
    const eventList = t("home.events.event_list", { returnObjects: true });
    // Distinguish standard events from custom ones
    const eventName = custom
      ? capitalizeFirstLetter(event)
      : eventList[event]
      ? capitalizeFirstLetter(eventList[event])
      : t("home.events.undefined_event");

    const actualTime =
      time === 12
        ? t("time.noon")
        : data[1] < 12
        ? data[1] + " AM"
        : data[1] - 12 + " PM";

    return (
      <div className="event-item mt-1 ml-1" key={event}>
        <div className="flex-d-row mb-1" style={{ alignItems: "center" }}>
          {/* Temporarily removing icon */}
          {/*<img src={eventList[event].icon} />*/}
          {eventName}
        </div>

        <div className="flex-d-row">
          <div className="registered">
            {t("home.events.registered_at")}&nbsp;
          </div>
          <div className="time"> {actualTime}</div>
        </div>
        <hr />
      </div>
    );
  };

  //* ************************************* RENDER ***************************************
  return (
    <div
      className="flex-d-column event-popup"
      style={{ left: position.x, top: position.y }}
    >
      <div className="row-space-between event-list-header">
        <div className="title pt-1 pb-1 pl-1">
          {t("home.events.events_symptoms")}
        </div>
        <img
          src={close}
          alt="close"
          className={"pointer"}
          onClick={handleClose}
        />
      </div>
      <div className="event-list pb-1">
        {data[2].symptoms.map((event) => drawEventItem(event, data[1], false))}
        {data[2].extra_symptoms.map((event) =>
          drawEventItem(event, data[1], true)
        )}
      </div>
    </div>
  );
}
