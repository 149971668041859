export const POST_CRASH_LOG = "POST_CRASH_LOG";
export const POST_CRASH_LOG_SUCCESS = "POST_CRASH_LOG_SUCCCESS";
export const POST_CRASH_LOG_FAIL = "POST_CRASH_LOG_FAIL";

export const sendCrashLog = (data) => ({
  type: "POST_CRASH_LOG",
  payload: {
    request: {
      method: "post",
      url: "cdss/error-logs/",
      data: data,
    },
  },
});
