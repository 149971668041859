import {
  GET_PATIENT_CHATS_LIST_FAIL,
  GET_PATIENT_CHATS_LIST_SUCCESS,
} from "../actions/patients";

const initialState = {
  institution_chat: {
    id: null,
    name: "",
    photo: "",
    participants: [],
  },
  private_chat: [],
};

const patientChatsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_CHATS_LIST_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_CHATS_LIST_FAIL: {
      // If it failed because the action was canceled then return the previous state
      if (
        action.error &&
        action.error.data &&
        action.error.data === "canceled"
      ) {
        return state;
      } else {
        return initialState;
      }
    }
    default:
      return state;
  }
};

export default patientChatsList;
