import { useState, useEffect } from "react";
//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Login from "./partials/login";
import SignUp from "./partials/signUp";
import CustomMessage from "./partials/customMessage";
import ResetPassword from "./partials/resetPassword";
import ErrorPage from "./partials/errorPage";
import SetupMultiFactorAuth from "./partials/setupMultiFactorAuth";
import MultiFactorAuth from "./partials/multiFactorAuth";
import ScallingBallsAnimation from "./partials/scallingBallAnimation";
import { parseDate } from "../../utils/dataAndTime";
import SessionExpired from "./partials/sessionExpired";
import PasswordExpiredForm from "../password_expired/partials/passwordExpiredForm";
import MessageModal from "../../components/messageModal";
import ForgotAccount from "./partials/forgotAccount";
import { RenderActivateAccountFooter } from "./partials/renders";

//* ************************************** GLOBALS ***************************************
// import { version } from "../../../package.json";
import logoLeft from "../../assets/images/logo-blue.svg";
import backButton from "../../assets/images/back-button.svg";
import sn from "../../assets/images/sn.svg";
import kinetikosAddress from "../../assets/images/loginpageicon/address-icon.svg";
import kinetikosEstablished from "../../assets/images/loginpageicon/established-icon.svg";
import snIcon from "../../assets/images/loginpageicon/sn-icon.svg";
import ceIcon from "../../assets/images/loginpageicon/ce-icon.svg";
import icon1 from "../../assets/images/loginpageicon/icon-1.svg";
import icon2 from "../../assets/images/loginpageicon/icon-2.svg";
import icon3 from "../../assets/images/loginpageicon/icon-3.svg";
import {
  forgotPasswordConfirm,
  forgotUsernameConfirm,
  userLogout,
  activateAccount,
  resendActivateAcconutEmail,
} from "../../redux/actions/user";
import { clearRedirectTo } from "../../redux/actions/redirect";
import { simplifyLanguageID } from "../../utils/language";

const company_name = "Kinetikos Platform";
const product_version = process.env.REACT_APP_PRODUCT_VERSION;
const product_date = process.env.REACT_APP_PRODUCT_VERSION_DATE;

const Auth = (props) => {
  const selectScreen = {
    registration: "registration",
    login: "login",
    checkEmailOpen: "checkEmailOpen",
    successResetPassword: "successResetPassword",
    successSignUp: "successSignUp",
    resetPassword: "resetPassword",
    errorPage: "errorPage",
    setupMultiFactorAuth: "setupMultiFactorAuth",
    multiFactorAuth: "multiFactorAuth",
    sessionExpired: "sessionExpired",
    passwordExpired: "passwordExpired",
    accountActivated: "accountActivated",
    forgotAccount: "forgotAccount",
    activateAccount: "activateAccount",
  };

  const { uidb64, token, activate_account_token } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const redirectTo = useSelector((state) => state.redirectTo.url);

  const [currentScreen, setCurrentScreen] = useState(
    window.location.pathname.includes("signup")
      ? selectScreen.registration
      : selectScreen.login
  );

  const [userInfo, setUserInfo] = useState();

  //* ************************************** HANDLERS **************************************
  useEffect(() => {
    if (
      window.location.pathname.includes("forgot_password") &&
      uidb64 &&
      token
    ) {
      dispatch(forgotPasswordConfirm(uidb64, token))
        .then(() => {
          setCurrentScreen(selectScreen.resetPassword);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (
      window.location.pathname.includes("forgot_username") &&
      uidb64 &&
      token
    ) {
      dispatch(forgotUsernameConfirm(uidb64, token))
        .then(() => {
          setCurrentScreen(selectScreen.resetPassword);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (window.location.pathname.includes("logout")) {
      dispatch(userLogout()).finally(() => {
        setCurrentScreen(selectScreen.login);
      });
    }
    if (activate_account_token) {
      dispatch(activateAccount(activate_account_token))
        .then(() => setCurrentScreen(selectScreen.accountActivated))
        .catch(() => setCurrentScreen(selectScreen.errorPage));
    }
    if (window.location.pathname.includes("setup_multifactor_auth")) {
      setCurrentScreen(selectScreen.setupMultiFactorAuth);
    } else if (window.location.pathname.includes("multifactor_auth")) {
      setCurrentScreen(selectScreen.multiFactorAuth);
    } else if (window.location.pathname.includes("session_expired")) {
      setCurrentScreen(selectScreen.sessionExpired);
    } else if (window.location.pathname.includes("password_expired")) {
      setCurrentScreen(selectScreen.passwordExpired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const onBackButtonClicked = () => {
    setCurrentScreen(selectScreen.login);
  };

  const navigateToHome = () => {
    props.history.replace(redirectTo);
    dispatch(clearRedirectTo());
  };

  const getManual = () => {
    const lang = simplifyLanguageID(i18n.language);
    window.open(
      `${process.env.REACT_APP_BACKEND_HOST}/user_manuals/kinetikos_platform/${lang}/`
    );
  };

  const resendEmail = () => {
    dispatch(resendActivateAcconutEmail(userInfo))
      .then(() => setCurrentScreen(selectScreen.successSignUp))
      .catch(() => setCurrentScreen(selectScreen.errorPage));
  };

  const shouldShowBackButton =
    currentScreen === selectScreen.registration ||
    currentScreen === selectScreen.resetPassword ||
    currentScreen === selectScreen.forgotAccount;

  //* *************************************** RENDER ***************************************
  return (
    <div className="main-wrapper">
      <div className="row g-0">
        <div className="col auth-left-side">
          <div className="footer-top-animation">
            <ScallingBallsAnimation />
            <img
              alt="authBackground"
              className="auth-left-logo"
              src={logoLeft}
            />
          </div>

          <div className="row footer-company-info">
            <span className="regular-text text--grey-intense header-text">
              {company_name}
            </span>
            <div className="col">
              <div className="col">
                <img src={kinetikosAddress} />
                <p className="ml-4">
                  <b>Kinetikos Driven Solutions, SA</b>
                  <br></br>
                  Rua Pedro Nunes, Edifício C.<br></br>
                  3030-199 Coimbra, Portugal
                </p>
              </div>
              <div className="col">
                <img src={kinetikosEstablished} />
                <p className="ml-4">{parseDate(product_date)}</p>
              </div>
              <div className="col">
                <p>{new Date().getFullYear()} © Kinetikos</p>
              </div>
            </div>

            <div className="col flex-d-column">
              <div className="col">
                <img src={snIcon} />
                <span className="regular-text text--grey-intense product_version">
                  {product_version}
                </span>
                <img src={ceIcon} className="ml-2" />
              </div>

              <div className="col">
                <img src={icon1} className="mr-3" />
                <img
                  src={icon2}
                  className="mr-3 cursor-pointer"
                  onClick={getManual}
                />
                <img src={icon3} />
              </div>
              <div className="col">
                <p className="mt-1">UDI-DI 05600638781804</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col auth-right-side">
          <div className="scroll">
            <div className="support-link">
              <a href="mailto:support@kinetikoshealth.com">
                <span className="regular-text">
                  {t("support.contact")}&nbsp;
                </span>
                <span className="link-text">
                  {t("support.center").toLowerCase()}
                </span>
              </a>
            </div>

            <div className="row g-0 align-self-center align-items-center justify-content-center">
              <div className="col-8 form-auth-container">
                {shouldShowBackButton && (
                  <div
                    className="button back-button-wrapper mb-1"
                    onClick={onBackButtonClicked}
                  >
                    <img src={backButton} alt="backButton" />
                    {t("buttons.back")}
                  </div>
                )}
                {currentScreen === selectScreen.registration ? (
                  <SignUp
                    navigateToHome={navigateToHome}
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    changeToLogin={() => setCurrentScreen(selectScreen.login)}
                    changeToSuccessSignUp={() =>
                      setCurrentScreen(selectScreen.successSignUp)
                    }
                  />
                ) : currentScreen === selectScreen.forgotAccount ? (
                  <ForgotAccount
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    changeToCheckEmail={() =>
                      setCurrentScreen(selectScreen.checkEmailOpen)
                    }
                  />
                ) : currentScreen === selectScreen.checkEmailOpen ||
                  currentScreen === selectScreen.successResetPassword ||
                  currentScreen === selectScreen.successSignUp ? (
                  <CustomMessage
                    type={currentScreen}
                    changeToLogin={() => setCurrentScreen(selectScreen.login)}
                  />
                ) : currentScreen === selectScreen.accountActivated ? (
                  <CustomMessage
                    type={currentScreen}
                    changeToLogin={() => props.history.replace("/login")} // Forcing redirect addresses QR Bug in MFA
                  />
                ) : currentScreen === selectScreen.resetPassword ? (
                  <ResetPassword
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    changeToSuccessResetPassword={() =>
                      setCurrentScreen(selectScreen.successResetPassword)
                    }
                  />
                ) : currentScreen === selectScreen.errorPage ? (
                  <ErrorPage
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    changeToLogin={() => setCurrentScreen(selectScreen.login)}
                  />
                ) : currentScreen === selectScreen.setupMultiFactorAuth ? (
                  <SetupMultiFactorAuth
                    navigateToHome={navigateToHome}
                    changeToLogin={() => {
                      dispatch(userLogout()).finally(() => {
                        props.history.replace("/login");
                        setCurrentScreen(selectScreen.login);
                      });
                    }}
                  />
                ) : currentScreen === selectScreen.multiFactorAuth ? (
                  <MultiFactorAuth
                    navigateToHome={navigateToHome}
                    changeToLogin={() => {
                      dispatch(userLogout()).finally(() => {
                        props.history.replace("/login");
                        setCurrentScreen(selectScreen.login);
                      });
                    }}
                  />
                ) : currentScreen === selectScreen.sessionExpired ? (
                  <SessionExpired
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    navigateToHome={navigateToHome}
                    changeToSignUp={() =>
                      setCurrentScreen(selectScreen.registration)
                    }
                    changeToForgotAccount={() =>
                      setCurrentScreen(selectScreen.forgotAccount)
                    }
                    changeToActivateAccount={() =>
                      setCurrentScreen(selectScreen.activateAccount)
                    }
                    userInfoCallback={(info) => setUserInfo(info)}
                  />
                ) : currentScreen === selectScreen.passwordExpired ? (
                  <PasswordExpiredForm
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    changeToSuccessResetPassword={() =>
                      setCurrentScreen(selectScreen.successResetPassword)
                    }
                  />
                ) : currentScreen === selectScreen.activateAccount ? (
                  <CustomMessage
                    type={currentScreen}
                    extraDesign={
                      <RenderActivateAccountFooter
                        onClickResendEmail={() => resendEmail()}
                        changeToLogin={() =>
                          setCurrentScreen(selectScreen.login)
                        }
                      />
                    }
                  />
                ) : (
                  <Login
                    showErrorPage={() =>
                      setCurrentScreen(selectScreen.errorPage)
                    }
                    navigateToHome={navigateToHome}
                    changeToSignUp={() =>
                      setCurrentScreen(selectScreen.registration)
                    }
                    changeToForgotAccount={() =>
                      setCurrentScreen(selectScreen.forgotAccount)
                    }
                    changeToActivateAccount={() =>
                      setCurrentScreen(selectScreen.activateAccount)
                    }
                    userInfoCallback={(info) => setUserInfo(info)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
