export const SET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const GET_INFO_MSG = "GET_INFO_MSG";

export const getMessage = (msg) => ({
  type: SET_MESSAGE_SUCCESS,
  payload: {
    msg: { message: msg },
  },
});

export const removeMessage = () => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: {
    msg: { message: "" },
  },
});
