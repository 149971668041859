//* ************************************** REACT IMPORTS *************************************
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************
import { acceptByCollaborator } from "../../../redux/actions/institutions";

//* ************************************* OUR COMPONENTS *************************************
import Spinner from "../../../components/spinner";
import CompanyInfo from "../../../components/companyInfo";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import logo from "../../../assets/images/logo-primary.svg";
import sn from "../../../assets/images/sn.svg";

const AcceptByCollaborator = ({ changeToLogin }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const { invite_token } = useParams();

  let history = useHistory();

  const institution = useSelector(
    (state) => state.institutions.invite_institution
  );

  const [isError, setIsError] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isHomeLoading, setIsHomeLoading] = useState(false);
  const [isAlreadyAccepted, setIsAlreadyAccepted] = useState(false);

  const product_version = process.env.REACT_APP_PRODUCT_VERSION;

  const dispatch = useDispatch();

  //* ************************************** HANDLERS ***************************************
  const chooseInstitution = () => {
    setIsHomeLoading(true);
    history.push("/choose_institution");
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(acceptByCollaborator(invite_token))
      .then(() => setIsError(false))
      .catch((res) => {
        setIsError(true);
        if (
          res.error.response.data.msg &&
          res.error.response.data.msg === "already_accepted"
        ) {
          setIsAlreadyAccepted(true);
        }
        console.log(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onClickHandler = (e) => {
    if (isAlreadyAccepted) {
      chooseInstitution();
    } else if (institution.id) {
      chooseInstitution();
    } else {
      alert("error!!");
    }
  };

  //* ************************************** PARTIALS ****************************************
  const _renderSuccess = () => (
    <>
      <div className="row justify-content-between">
        <div className="col-auto">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="col-auto">
          <div>
            <img style={{ marginRight: "4px" }} alt="SN" src={sn} />
            <span className="regular-text text--grey-intense">
              {product_version}
            </span>
          </div>
        </div>
      </div>

      <div className="row choose-institution-wrapper">
        <div className="col-sm-8 col-lg-4 choose-institution-box">
          <h3>
            {isAlreadyAccepted ? "Hmm.." : t("accepted_collaborator.success")}
          </h3>

          <div className="regular-text mt-4">
            <div className="regular-text mt-2 mb-3">
              {isAlreadyAccepted
                ? `${t("accepted_collaborator.already_accepted")}`
                : `${t("accepted_collaborator.added_to")} ${institution.name}.`}
            </div>
            <div className="position-relative mt-1">
              <button
                className="small-wider-button primary-button w-100"
                onClick={(e) => onClickHandler(e)}
              >
                {isHomeLoading
                  ? ""
                  : t("accepted_collaborator.go_to_choose_institution")}
              </button>
              {isHomeLoading && <Spinner type={"small"} />}
            </div>
            <div className="mt-4 text-center regular-text">
              <a href="mailto:support@kinetikoshealth.com">
                <span className="regular-text">
                  {t("authentication.error.contact_our")}&nbsp;
                </span>
                <span className="button link-text">
                  {t("authentication.error.support_center")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row h-100">
        <div className="col kinetikos-bottom-information-wrapper">
          <CompanyInfo />
        </div>
      </div>
    </>
  );

  const _renderFailed = () => (
    <>
      <div className="row justify-content-between">
        <div className="col-auto">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="col-auto">
          <div>
            <img style={{ marginRight: "4px" }} alt="SN" src={sn} />
            <span className="regular-text text--grey-intense">
              {product_version}
            </span>
          </div>
        </div>
      </div>

      <div className="row choose-institution-wrapper">
        <div className="col-sm-8 col-lg-4 choose-institution-box">
          <h3>Hmmmm....</h3>

          <div className="regular-text mt-4">
            <div className="regular-text mt-2 mb-3">
              {t("accepted_collaborator.not_logged")}
            </div>
            <div className="position-relative mt-1">
              <button
                className="small-wider-button primary-button w-100"
                onClick={() => history.push("/login")}
              >
                {t("buttons.log_in")}
              </button>
            </div>
            <div className="mt-4 text-center regular-text">
              <a href="mailto:support@kinetikoshealth.com">
                <span className="regular-text">
                  {t("authentication.error.problem_persists")}&nbsp;
                </span>
                <span className="button link-text">
                  {t("authentication.error.support_center")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row h-100">
        <div className="col kinetikos-bottom-information-wrapper">
          <CompanyInfo />
        </div>
      </div>
    </>
  );

  //* *************************************** RENDER *****************************************
  return (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        {isLoading ? (
          <div>
            <Spinner type={"big-blue"} />
          </div>
        ) : !isError || isAlreadyAccepted ? (
          _renderSuccess()
        ) : (
          _renderFailed()
        )}
      </div>
    </div>
  );
};

export default AcceptByCollaborator;
