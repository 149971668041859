/*
Capitalize the first letter of a string
*/

export const capitalizeFirstLetter = (s) => {
  s = s.trim();
  return s.trim().charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Converts a string to different types of formatted strings
 */
export const removeSpaces = (string, format) => {
  switch (format) {
    case "underscore":
      string = string.replace(/\s+/g, "_"); // Collapse whitespace and replace by _
      return string;
  }
};
