import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-primary.svg";
import snIcon from "../../assets/images/sn.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getLanguageID } from "../../utils/language";
import {
  getTermsAndConditions,
  acceptTermsAndConditions,
  userLogout,
} from "../../redux/actions/user";
import { clearRedirectTo } from "../../redux/actions/redirect";
import Spinner from "../../components/spinner";
import CompanyInfo from "../../components/companyInfo";

const product_version = process.env.REACT_APP_PRODUCT_VERSION;

const TermsAndConditionsUpdate = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [termsAndConditions, setTermsAndCondtions] = useState({ __html: "" });
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [isSignup, setIsSignup] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/terms_and_conditions") {
      setIsSignup(true);
    }

    setIsPageLoading(true);
    dispatch(getTermsAndConditions(getLanguageID(i18n.language)))
      .then((res) => {
        if (res.payload && res.payload.data) console.log(res.payload.data);
        setTermsAndCondtions({ __html: res.payload.data.data });
      })
      .catch((error) => console.error(error))
      .finally(() => setIsPageLoading(false));
  }, []);

  const acceptTAC = () => {
    dispatch(acceptTermsAndConditions())
      .then((res) => {
        console.log("success!!");
        props.history.push("/home");
      })
      .catch((error) => console.error(error));
  };

  const rejectTAC = () => {
    dispatch(userLogout()).finally(() => {
      props.history.replace("/login");
    });
  };

  return (
    <>
      {isPageLoading ? (
        <Spinner type={"big-blue"} />
      ) : (
        <div className="col profile-body">
          <div className="row-space-between">
            <img src={logo} />
            <div>
              <img src={snIcon} />
              <span className="regular-text">
                {product_version === "" ? "Error" : product_version}
              </span>
            </div>
          </div>

          {!isSignup && (
            <div className="header-box regular-text">
              {t("titles.terms_and_conditions_update_title2")}
            </div>
          )}

          <div className="body-text-wrapper">
            <div className="title small-title">{t("titles.terms")}</div>
            <div
              className="body-text regular-text black-color"
              dangerouslySetInnerHTML={termsAndConditions}
            ></div>
          </div>

          <div className="col tac-footer">
            {!isSignup && (
              <div className="col-2 accept-btn">
                <div className="row mb-1">
                  <button
                    className="button small-button primary-button justify-content-center"
                    onClick={acceptTAC}
                    disabled={isPageLoading}
                  >
                    {t("buttons.tac_update_accept")}
                  </button>
                </div>
                <div className="row">
                  <button
                    className="small-button transparent-button"
                    onClick={rejectTAC}
                    disabled={isPageLoading}
                  >
                    <u>{t("buttons.reject")}</u>
                  </button>
                </div>
              </div>
            )}
            <div className="kinetikos-logo">
              <CompanyInfo />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditionsUpdate;
