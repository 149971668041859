//import liraries
import React, { forwardRef } from "react";
import moreBlueSelected from "../../assets/images/more-blue-selected-grey.svg";
import moreBlue from "../../assets/images/more-blue-selected-white.svg";

// create a component
const OptionsButton = forwardRef(
  ({ isSelected, onClick, type = 1, id }, ref) => {
    const initialIcon = type === 1 ? moreBlue : moreBlueSelected;
    const selectedIcon = type === 1 ? moreBlueSelected : moreBlue;
    return (
      <img
        id={id}
        ref={ref}
        className="image-cursor"
        onMouseOver={(e) => !isSelected && (e.currentTarget.src = selectedIcon)}
        onMouseOut={(e) => !isSelected && (e.currentTarget.src = initialIcon)}
        onClick={onClick}
        src={isSelected ? selectedIcon : initialIcon}
        alt="More Blue"
      />
    );
  }
);

//make this component available to the app
export default OptionsButton;
