import { memo, useRef } from "react";
import ProgressBar from "./progressBar";
import UploadFilesIcon from "../../../assets/images/upload-image.svg";
import { useTranslation } from "react-i18next";

const FileUpload = ({ id, files = [], onChangeFiles = [], nrFiles = [], filled = false }) => {
  const { t } = useTranslation();

  const filesInputRef = useRef(null);

  const acceptedFileType = "text/plain";

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const parseExpectedFilesText = () => {
    let nrFilesString = "";
    nrFiles.forEach((nr, index) => {
      if (index === nrFiles.length - 1) {
        nrFilesString = nrFilesString + `${nr} `;
      } else if (index === nrFiles.length - 2) {
        nrFilesString = nrFilesString + `${nr} ${t("or").toLowerCase()} `;
      } else {
        nrFilesString = nrFilesString + `${nr}, `;
      }
    });
    nrFilesString =
      nrFiles.length === 1 && nrFiles[0] === 1
        ? nrFilesString + t("files.file_expected")
        : nrFilesString + t("files.files_expected");
    return nrFilesString;
  };

  return (
    <>
      <div
        id={id}
        className={`ev-mod-file-upload-container cursor-pointer ${
          filled === false && !nrFiles.includes(files.length) && files.length > 0 ? "error" : ""
        }`}
      >
        {filled === true ? (
          <div className="filled-container">
            <div className="filled-header">
              <div
                className="col align-items-center regular-text"
                style={{ textTransform: "capitalize" }}
              >
                {t("files.submited_files")}
              </div>
              <div className="col-auto flex-d-row">
                <button
                  onClick={() => filesInputRef.current.click()}
                  className="transparent-button small-button base-color"
                >
                  {t("buttons.change_file")}
                </button>
                <button
                  onClick={() => onChangeFiles([])}
                  className="transparent-button small-button warning-color"
                >
                  {t("buttons.delete")}
                </button>
              </div>
            </div>
            <div className="filled-uploaded-files-container">
              {files.map((file, index) => (
                <div key={`${file.name}-${index}`} className="filled-uploaded-files">
                  {file.name ?? file}
                </div>
              ))}
            </div>
          </div>
        ) : files.length > 0 ? (
          <ProgressBar
            text={`${files.length} ${
              files.length === 1 ? t("files.file_submitted") : t("files.files_submitted")
            }`}
            deleteItem={() => onChangeFiles([])}
            changeFile={() => filesInputRef.current.click()}
            timeInterval={0.5}
          />
        ) : (
          <div className="insert-image-modal-body cursor-pointer">
            <div
              accept={acceptedFileType}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onClick={() => filesInputRef.current.click()}
              onDrop={(event) => {
                handleDrag(event);
                onChangeFiles(event.dataTransfer.files || null);
              }}
              className="upload-wrapper"
            >
              <img className="upload-image" src={UploadFilesIcon} alt="Upload"></img>
              <span className="details-text">{t("files.upload")}</span>
            </div>
          </div>
        )}
        <input
          type="file"
          accept={acceptedFileType}
          ref={filesInputRef}
          hidden
          multiple
          onChange={(event) => {
            console.log("filesInputRef:", event);
            if (event.target.files.length > 0) {
              onChangeFiles(event.target.files);
            }
          }}
        />
      </div>
      <div
        className={`regular-text mt-1 ${
          filled === false && !nrFiles.includes(files.length) && files.length > 0 ? "warning" : ""
        }`}
        style={{ textAlign: "center" }}
      >
        {parseExpectedFilesText()}
      </div>
    </>
  );
};

export default memo(FileUpload);
