//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useRef, useState } from "react";

//* *********************************** EXTERNAL PACKAGES ***********************************
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../messageModal";

//* ************************************** GLOBALS ***************************************
import upload_image_icon from "../../assets/images/upload-image.svg";
import check_circle_icon from "../../assets/images/check-circle.svg";
import { fileAllowedExtensions } from "../../constants/general";

const InsertImageModal = ({
  headerText,
  setPhoto,
  currPhoto,
  deletePhoto,
  closeModal,
}) => {
  const fileInput = useRef(null);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [hasUploadedPhoto, setHasUploadedPhoto] = useState(false);

  const { t, i18n } = useTranslation();

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOnDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const handleFile = (file) => {
    if (!file) {
      setUploadedPhoto(null);
    } else if (!fileAllowedExtensions.image.includes(file.type)) {
      alert("Invalid image format");
    } else if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPhoto(e.target.result);
        setHasUploadedPhoto(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const RenderLastButton = () => {
    if (
      (currPhoto === null && uploadedPhoto === null) ||
      uploadedPhoto !== null
    ) {
      return (
        <button
          onClick={() => setPhoto(uploadedPhoto)}
          disabled={uploadedPhoto === null}
          className="small-button secondary-button"
        >
          {t("buttons.confirm")}
        </button>
      );
    } else if (currPhoto !== null) {
      return (
        <button onClick={deletePhoto} className="small-button warning-button">
          {t("buttons.delete")}
        </button>
      );
    } else {
      return null;
    }
  };

  const InsertImageModalBody = () => (
    <div className="insert-image-modal-body cursor-pointer">
      <span className="regular-text">{t("files.upload_delete_photo")}</span>
      <div
        onDragLeave={handleDragOver}
        onDragOver={handleDragOver}
        onDrop={handleOnDrop}
        className="upload-wrapper mt-1"
      >
        <img
          className="upload-image"
          onClick={() => fileInput.current.click()}
          src={hasUploadedPhoto ? check_circle_icon : upload_image_icon}
          alt="Upload"
        ></img>
        <span className="details-text">
          {hasUploadedPhoto ? t("files.uploaded") : t("files.upload")}
        </span>
        <input
          type="file"
          accept="image/*"
          ref={fileInput}
          hidden
          onChange={(e) => handleFile(e.target.files[0])}
        />
      </div>
    </div>
  );

  return (
    <MessageModal
      headerText={headerText}
      body={<InsertImageModalBody />}
      footerButtons={[
        <button
          onClick={closeModal}
          className="small-button transparent-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <RenderLastButton />,
      ]}
    />
  );
};

export default React.memo(InsertImageModal);
