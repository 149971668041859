import React from "react";

import checkIcon from "../../../assets/images/evalModules/ok_white.svg";

const CheckBox = ({
  id,
  question = "",
  options = [],
  vertical = false,
  checked = [],
  onOptionClick = () => {},
}) => {
  return (
    <div id={id} className="checkbox-container">
      {question !== "" && <div className="checkbox-question">{question}</div>}
      <div className={`checkbox-options-wrapper ${vertical ? "vertical" : ""}`}>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={`checkbox-option-container`}
              onClick={() => {
                onOptionClick(option);
              }}
            >
              {option[1] !== "" && option[1] + ":"}
              <div
                className={`option-box ${
                  checked.includes(option[0]) ? "checked" : ""
                }`}
              >
                {checked.includes(option[0]) && (
                  <img alt="Checked" src={checkIcon} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(CheckBox);
