export const GET_EVALUATION_TEMPLATES = "GET_EVALUATION_TEMPLATES";
export const GET_EVALUATION_TEMPLATES_SUCCESS =
  "GET_EVALUATION_TEMPLATES_SUCCESS";
export const GET_EVALUATION_TEMPLATES_FAIL = "GET_EVALUATION_TEMPLATES_FAIL";

export const GET_EVALUATION_TEMPLATE = "GET_EVALUATION_TEMPLATE";
export const GET_EVALUATION_TEMPLATE_SUCCESS =
  "GET_EVALUATION_TEMPLATE_SUCCESS";
export const GET_EVALUATION_TEMPLATE_FAIL = "GET_EVALUATION_TEMPLATE_FAIL";

export const GET_EVALUATION_TEMPLATES_MODULE_LIST =
  "GET_EVALUATION_TEMPLATES_MODULE_LIST";
export const GET_EVALUATION_TEMPLATES_MODULE_LIST_SUCCESS =
  "GET_EVALUATION_TEMPLATES_MODULE_LIST_SUCCESS";
export const GET_EVALUATION_TEMPLATES_MODULE_LIST_FAIL =
  "GET_EVALUATION_TEMPLATES_MODULE_LIST_FAIL";

export const ADD_EVALUATION_TEMPLATES = "ADD_EVALUATION_TEMPLATES";

export const EDIT_EVALUATION_TEMPLATES = "EDIT_EVALUATION_TEMPLATES";

export const MARK_EVALUATION_TEMPLATE_FAVOURITE =
  "MARK_EVALUATION_TEMPLATE_FAVOURITE";

export const MARK_EVALUATION_TEMPLATE_FAVOURITE_SUCCESS =
  "MARK_EVALUATION_TEMPLATE_FAVOURITE_SUCCESS";

export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";
export const REMOVE_TEMPLATE_SUCCESS = "REMOVE_TEMPLATE_SUCCESS";
export const REMOVE_TEMPLATE_FAIL = "REMOVE_TEMPLATE_FAIL";

export const DOWNLOAD_RAW_FILE = "DOWNLOAD_RAW_FILE";
export const DOWNLOAD_METRICS = "DOWNLOAD_METRICS";
export const GET_EVALUATION_MODULE_SUMMARY_STATUS_SUCCESS =
  "GET_EVALUATION_MODULE_SUMMARY_STATUS_SUCCESS";

export const getEvaluationTemplates = ({
  page = 1,
  search = "",
  filter_type = "all",
  items_per_page = 10,
} = {}) => {
  return {
    type: GET_EVALUATION_TEMPLATES,
    payload: {
      request: {
        method: "get",
        url: "/cdss/evaluation/templates",
        params: { page, search, filter_type, items_per_page },
      },
    },
  };
};

export const getEvaluationTemplate = (id) => ({
  type: GET_EVALUATION_TEMPLATE,
  payload: {
    request: {
      method: "get",
      url: `/cdss/evaluation/templates/${id}`,
    },
  },
});

export const getEvaluationTemplatesModuleList = (
  module_type = "all",
  keyword = ""
) => ({
  type: GET_EVALUATION_TEMPLATES_MODULE_LIST,
  payload: {
    request: {
      method: "get",
      url: "/cdss/evaluation/modules",
      params: { module_type, keyword },
    },
  },
});

export const addNewEvaluationTemplate = (
  module_list_id = [],
  template_title = "",
  is_owner_institution = false
) => ({
  type: ADD_EVALUATION_TEMPLATES,
  payload: {
    request: {
      method: "post",
      url: "/cdss/evaluation/templates/",
      data: { module_list_id, template_title, is_owner_institution },
    },
  },
});

export const editNewEvaluationTemplate = (
  id,
  module_list_id = [],
  template_title = "",
  is_owner_institution = false
) => ({
  type: EDIT_EVALUATION_TEMPLATES,
  payload: {
    request: {
      method: "put",
      url: `/cdss/evaluation/templates/${id}`,
      data: { module_list_id, template_title, is_owner_institution },
    },
  },
});

export const markTemplateAsFavourite = (id) => ({
  type: MARK_EVALUATION_TEMPLATE_FAVOURITE,
  payload: {
    request: {
      method: "put",
      url: `/cdss/evaluation/templates/${id}/fav`,
    },
    id: id,
  },
});

export const removeFromTemplates = (id) => ({
  type: REMOVE_TEMPLATE,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/evaluation/templates/${id}`,
    },
    id: id,
  },
});

export const downloadRawFile = (evaluation_id, module_id) => ({
  type: DOWNLOAD_RAW_FILE,
  payload: {
    request: {
      responseType: "arraybuffer",
      method: "get",
      url: `/cdss/evaluation/${evaluation_id}/evalmod/${module_id}/raw_files/`,
    },
  },
});

export const downloadMetrics = (evaluation_id, module_id) => ({
  type: DOWNLOAD_METRICS,
  payload: {
    request: {
      responseType: "blob",
      method: "get",
      url: `/cdss/evaluation/${evaluation_id}/evalmod/${module_id}/download_metrics/`,
    },
  },
});
