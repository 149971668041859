import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { create_activities_button } from "../../../utils/report";
import { create_card } from "../../../utils/create_card";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

import zoomIcon from "../../../assets/images/zoom-in.svg";
import resetIcon from "../../../assets/images/reset_icon.svg";
import tagIcon from "../../../assets/images/tag.svg";

const InteractiveMultilineChart = ({ details }) => {
  const reff = useRef(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);
  // const data = {
  //   sto_file: "/media/730c7ef8dba39386408883a38fcc26f7c784cede_1_Gait.sto",
  //   files_list: [
  //     "/media/3b17d70972a317f5437531c6554eca3a1f87fed1_1_Gait.sto",
  //     "/media/730c7ef8dba39386408883a38fcc26f7c784cede_1_Gait.sto",
  //     "/media/d6d06310a483fff46d05ba6179cff93755f4a597_1_Gait.sto",
  //   ],
  //   evaluation_dates: ["2022-04-01", "2022-04-05", "2022-04-08"],
  //   plot_keys: [
  //     "hip_flexion_r",
  //     "hip_adduction_r",
  //     "hip_rotation_r",
  //     "knee_angle_r",
  //     "ankle_angle_r",
  //     "hip_flexion_l",
  //     "hip_adduction_l",
  //     "hip_rotation_l",
  //     "knee_angle_l",
  //     "ankle_angle_l",
  //   ],
  //   labels: {
  //     clav_elev_r: {
  //       name: "Clavicula Elevation",
  //       side: "R",
  //     },
  //     clav_elev_l: {
  //       name: "Clavicula Elevation",
  //       side: "L",
  //     },
  //     shoulder_elv_r: {
  //       name: "Shoulder Elevation",
  //       side: "R",
  //     },
  //     shoulder_elv_l: {
  //       name: "Shoulder Elevation",
  //       side: "L",
  //     },
  //     plane_of_elev_r: {
  //       name: "Plane of Elevation",
  //       side: "R",
  //     },
  //     plane_of_elev_l: {
  //       name: "Plane of Elevation",
  //       side: "L",
  //     },
  //     hip_flexion_r: {
  //       name: "Hip Flexion",
  //       side: "R",
  //     },
  //     hip_flexion_l: {
  //       name: "Hip Flexion",
  //       side: "L",
  //     },
  //     ankle_angle_r: {
  //       name: "Ankle Flexion",
  //       side: "R",
  //     },
  //     ankle_angle_l: {
  //       name: "Ankle Flexion",
  //       side: "L",
  //     },
  //     hip_adduction_r: {
  //       name: "Hip Adduction",
  //       side: "R",
  //     },
  //     hip_adduction_l: {
  //       name: "Hip Adduction",
  //       side: "L",
  //     },
  //     hip_rotation_r: {
  //       name: "Hip Rotation",
  //       side: "R",
  //     },
  //     hip_rotation_l: {
  //       name: "Hip Rotation",
  //       side: "L",
  //     },
  //     knee_angle_r: {
  //       name: "Knee Flexion",
  //       side: "R",
  //     },
  //     knee_angle_l: {
  //       name: "Knee Flexion",
  //       side: "L",
  //     },
  //     scapula_abduction_r: {
  //       name: "Scapula Abduction",
  //       side: "R",
  //     },
  //     scapula_abduction_l: {
  //       name: "Scapula Abduction",
  //       side: "L",
  //     },
  //     scapula_elevation_r: {
  //       name: "Scapula Elevation",
  //       side: "R",
  //     },
  //     scapula_elevation_l: {
  //       name: "Scapula Elevation",
  //       side: "L",
  //     },
  //     scapula_upward_rot_r: {
  //       name: "Scapula Upward Rotation",
  //       side: "R",
  //     },
  //     scapula_upward_rot_l: {
  //       name: "Scapula Upward Rotation",
  //       side: "L",
  //     },
  //     scapula_winging_r: {
  //       name: "Scapula Winging",
  //       side: "R",
  //     },
  //     scapula_winging_l: {
  //       name: "Scapula Winging",
  //       side: "L",
  //     },
  //     flexion_extension: {
  //       name: "Flexion/Extension",
  //       side: "M",
  //     },
  //     lateral_flexion: {
  //       name: "Lateral Flexion",
  //       side: "M",
  //     },
  //     rotation: {
  //       name: "Rotation",
  //       side: "M",
  //     },
  //     axial_rot_r: {
  //       name: "Internal Rotation",
  //       side: "R",
  //     },
  //     axial_rot_l: {
  //       name: "Internal Rotation",
  //       side: "L",
  //     },
  //     elbow_flexion_r: {
  //       name: "Elbow Flexion",
  //       side: "R",
  //     },
  //     elbow_flexion_l: {
  //       name: "Elbow Flexion",
  //       side: "L",
  //     },
  //     pro_sup_r: {
  //       name: "Pronosupination",
  //       side: "R",
  //     },
  //     pro_sup_l: {
  //       name: "Pronosupination",
  //       side: "L",
  //     },
  //     wrist_flex_r: {
  //       name: "Wrist Flexion",
  //       side: "R",
  //     },
  //     wrist_flex_l: {
  //       name: "Wrist Flexion",
  //       side: "L",
  //     },
  //     wrist_dev_r: {
  //       name: "Wrist Deviation",
  //       side: "R",
  //     },
  //     wrist_dev_l: {
  //       name: "Wrist Deviation",
  //       side: "L",
  //     },
  //     initial_dropdown_option: "-- Select an Option --",
  //   },
  //   ik_status: {
  //     notstatus: false,
  //     video_path: "/media/730c7ef8dba39386408883a38fcc26f7c784cede_1_Gait.sto",
  //   },
  // };
  // const details = {
  //   id: 67,
  //   type: 8,
  //   title: "Gait Kinematics",
  //   description: "Gait Kinematics",
  //   icon: '<i class="fa fa-th" aria-hidden="true"></i>',
  //   color: "var(--kred-color)",
  //   span_row: 9,
  //   span_col: 10,
  // };

  useEffect(() => {
    function build_interactive_plot(card, data) {
      let file_name = data.sto_file,
        files_list = data.files_list,
        exam_dates = data.evaluation_dates,
        plot_keys = data.plot_keys;

      let card_body = card.querySelector(".card-body"),
        card_header_actions = card.querySelector(".card-header .actions"),
        wrapper_div = document.createElement("div"),
        dropdown = document.createElement("select"),
        actions_row = document.createElement("div"),
        labels_div = document.createElement("div"),
        actions_div = document.createElement("div"),
        slider = document.createElement("input");
      // variables that control the zoom and labeling capability, enabling it or disabling it
      let zoomable = false,
        prev_zoomable = false,
        tags_active = false;

      wrapper_div.classList = "svg-div noselect";
      dropdown.classList = "Form-input selectInput plot-dropdown";
      actions_row.classList = "actions-row";
      labels_div.classList = "interactive-plot-labels noselect";
      actions_div.classList = "plot-actions row noselect";
      card_header_actions.appendChild(dropdown);
      card_body.appendChild(actions_row);
      actions_row.appendChild(labels_div);
      actions_row.appendChild(actions_div);
      card_body.appendChild(wrapper_div);

      // TEMPORARILY REMOVED ACTIONS_DIV
      // card_body.appendChild(actions_div);

      const zoom_button = document.createElement("div");
      zoom_button.classList =
        "interactive-plot-label deselect-button plot-disabled";
      zoom_button.innerHTML = `<img src=${zoomIcon} >`;

      const reset_zoom_button = document.createElement("div");
      reset_zoom_button.classList = "interactive-plot-label deselect-button";
      reset_zoom_button.innerHTML = `<img src=${resetIcon} >`;

      const labels_button = document.createElement("div");
      labels_button.classList =
        "interactive-plot-label deselect-button plot-disabled";
      labels_button.innerHTML = `<img src=${tagIcon} >`;

      /* et zoom_button = create_activities_button(actions_div, "zoom", false), */
      actions_div.appendChild(zoom_button);
      actions_div.appendChild(reset_zoom_button);
      actions_div.appendChild(labels_button);
      /* let reset_zoom_button = create_activities_button(
          actions_div,
          "reset_zoom",
          false,
          false,
          false
        ), */
      /* let labels_button = create_activities_button(actions_div, "tag", false),
        hide_button = create_activities_button(
          actions_div,
          "hide",
          false,
          false,
          false
        ), */
      // prev_zoom_button = create_activities_button(actions_div, 'zoom', true),

      let rect = wrapper_div.getBoundingClientRect(),
        wrapper = d3
          .select(`#${card.id} .svg-div`)
          .append("svg")
          .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
          .attr("preserveAspectRatio", "xMidYMin meet"),
        margin = { top: 20, right: 10, bottom: 20, left: 25 },
        inner_width = rect.width - margin.left - margin.right,
        inner_height = rect.height - margin.top - margin.bottom,
        tick_num = 5,
        x_scale = d3.scaleLinear().rangeRound([0, inner_width]),
        y_scale = d3.scaleLinear().rangeRound([inner_height, 0]),
        new_x_scale = x_scale,
        new_y_scale = y_scale,
        prev_x_scale = null,
        new_prev_x_scale = null,
        prev_xzoom = null,
        x_axis = d3.axisBottom().scale(x_scale).ticks(tick_num),
        prev_x_axis = null,
        prev_x_appended_axis = null,
        y_axis = d3.axisLeft().scale(y_scale).ticks(tick_num),
        // create color scale and domain
        color_scale = d3
          .scaleOrdinal()
          .range([
            "#4d9fcc",
            "#a7e973",
            "#facd59",
            "#ec5c9b",
            "#16789b",
            "#2b3fbd",
            "#5ae071",
            "#e58e26",
            "#b72e70",
            "#0c4a62",
          ]), //d3.schemePaired
        color_scale_prev = d3
          .scaleOrdinal()
          .range([
            "#1d6f9c",
            "#77b943",
            "#ca9d29",
            "#bc2c6b",
            "#06486b",
            "#000f8d",
            "#2ab041",
            "#b55e00",
            "#870040",
            "#001a32",
          ]), //d3.schemePaired //d3.schemePaired), // d3.scaleOrdinal().range(["#6F257F", "#CA0D59"])
        //create the line constructor
        line_const = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => {
            return x_scale(d.time);
          })
          // the key value will be created by us, it doesn't come in the tsv
          .y((d) => {
            return y_scale(d.value);
          }),
        lines_group = null,
        prev_lines_group = null,
        prev_wrapper = wrapper.append("g").attr("class", `prev-exam-plot`),
        hide_curr = true;
      let hide_prev = true;
      let hide = true;

      /* hide_button.addEventListener("click", hide_all, false);
      hide_button.addEventListener("touch", hide_all, false); */

      function hide_all() {
        d3.selectAll(`#${card.id} .interactive-plot-line`).classed(
          "hide-svg-element",
          hide
        );
        d3.selectAll(`#${card.id} .tag-per-line`).classed("hide-tags", hide);

        //let labels = card.querySelectorAll(`.interactive-plot-labels span`);
        let labels = card.querySelectorAll(
          //`.interactive-plot-labels span:not(.previous-label):not(.deselect-button)`
          `div.interactive-plot-label`
        );
        [...labels].map((label_elem) =>
          hide
            ? label_elem.classList.add("plot-disabled")
            : label_elem.classList.remove("plot-disabled")
        );
        this.innerHTML = hide
          ? `<i class="icon visible" aria-hidden="true"></i>`
          : `<i class="icon hidden" aria-hidden="true"></i>`;
        hide = !hide;
      }

      function hide_session(button, previous) {
        if (!previous) {
          d3.selectAll(
            `#${card.id} svg > .interactive_plot_lines_group .interactive-plot-line`
          ).classed("hide-svg-element", hide_curr);
          d3.selectAll(
            `#${card.id} svg > .tag-per-line:not(.previous)`
          ).classed("hide-tags", hide_curr);

          let labels = card.querySelectorAll(
            //`.interactive-plot-labels span:not(.previous-label):not(.deselect-button)`
            `div.interactive-plot-label`
          );

          // Can't get query selector to remove .deselect-button properly
          [...labels].slice(1).map((label_elem) => {
            label_elem.classList.toggle("plot-disabled");
            return hide_curr
              ? label_elem.classList.add("plot-disabled")
              : label_elem.classList.remove("plot-disabled");
          });
          hide_curr = !hide_curr;
        } else {
          d3.selectAll(
            `#${card.id} .prev-exam-plot .interactive-plot-line`
          ).classed("hide-svg-element", hide_prev);
          d3.selectAll(`#${card.id} .tag-per-line.previous`).classed(
            "hide-tags",
            hide_prev
          );

          let labels = card.querySelectorAll(
            `.interactive-plot-labels span.previous-label`
          );
          [...labels].map((label_elem) => {
            label_elem.classList.toggle("plot-disabled");
            return hide_curr
              ? label_elem.classList.add("plot-disabled")
              : label_elem.classList.remove("plot-disabled");
          });
          hide_prev = !hide_prev;
        }
      }

      function draw_resample(data) {
        color_scale.domain(
          d3.keys(data[0]).filter((key) => {
            return plot_keys.includes(key);
          })
        ); //time is not a data catergory
        color_scale_prev.domain(
          d3.keys(data[0]).filter((key) => {
            return plot_keys.includes(key);
          })
        ); //time is not a data catergory

        let chart_data = parse_data_and_add_label(card, data, color_scale);
        add_deselect_label(card, false);
        // the x and y scales are already created, we just need to add domains to them
        set_domain(x_scale, chart_data, "x");
        set_domain(y_scale, chart_data, "y");
        add_grid_lines(
          wrapper,
          inner_width,
          inner_height,
          margin.left,
          margin.top,
          x_scale,
          y_scale,
          tick_num
        );

        // append the axis to the svg
        let x_appended_axis = append_axis(
            wrapper,
            x_axis,
            margin.left,
            inner_height + margin.top
          ),
          y_appended_axis = append_axis(
            wrapper,
            y_axis,
            margin.left,
            margin.top
          );

        append_clip_path(
          card,
          wrapper,
          inner_width,
          inner_height,
          margin.top,
          margin.left
        );
        lines_group = place_lines(
          card,
          wrapper,
          chart_data,
          margin.top,
          margin.left,
          line_const,
          color_scale,
          false
        );

        place_tags(card, wrapper, chart_data, margin, color_scale);

        labels_button.addEventListener("click", () => {
          labels_button.classList.toggle("plot-disabled");
          control_tags_functionality();
        });
        labels_button.addEventListener("touch", () => {
          labels_button.classList.toggle("plot-disabled");
          control_tags_functionality();
        });

        function control_tags_functionality() {
          d3.select(`#${card.id} .tags-vertical-line`).classed(
            "hide-svg-element",
            tags_active
          );
          d3.selectAll(`#${card.id} .tag-per-line`).classed(
            "hide-svg-element",
            tags_active
          );
          tags_active = !tags_active;
        }

        let vline_area = append_hidden_rect(
          wrapper,
          inner_width,
          inner_height,
          margin.left,
          margin.top
        );
        vline_area
          .on("mouseout", function () {
            // on mouse out hide line, circles and text
            d3.select(".tags-vertical-line").style("opacity", "0");
            d3.selectAll(".tag-per-line:not(.hide-tags)").style("opacity", "0");
          })
          .on("mouseover", function () {
            // on mouse in show line, circles and text
            d3.select(".tags-vertical-line").style("opacity", "1");
            d3.selectAll(".tag-per-line:not(.hide-tags)").style("opacity", "1");
          })
          .on("mousemove", mousemove);

        vline_area.on("touch", mousemove);
        vline_area.on("touchmove", mousemove);

        function mousemove() {
          let mouse = d3.mouse(this);

          d3.select(".tags-vertical-line").attr("d", () => {
            return `M ${mouse[0]}, ${inner_height} ${mouse[0]}, 0`;
          });

          d3.selectAll(".tag-per-line:not(.hide-tags)").attr(
            "transform",
            function (d, i) {
              let time = this.classList.contains("previous")
                  ? new_prev_x_scale.invert(mouse[0])
                  : new_x_scale.invert(mouse[0]), //get the corresponding time to this position in the axis
                bisect = d3.bisector(function (d) {
                  return d.time;
                }).left;
              let idx = bisect(d.values, time);
              if (idx <= 0) {
                let value = d.values[0].value,
                  y = new_y_scale(value);
                d3.select(this).select("text").text(value.toFixed(2));

                return `translate(${mouse[0]}, ${y})`;
              } else if (idx >= d.values.length) {
                let value = d.values[d.values.length - 1].value,
                  y = new_y_scale(value);
                d3.select(this).select("text").text(value.toFixed(2));

                return `translate(${mouse[0]}, ${y})`;
              } else {
                let value0 = d.values[idx - 1],
                  value1 = d.values[idx],
                  value =
                    time - value0.time > time - value1.time
                      ? value1.value
                      : value0.value,
                  y = new_y_scale(value);

                d3.select(this).select("text").text(value.toFixed(2));

                return `translate(${mouse[0]}, ${y})`;
              }
            }
          );
        }

        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* ZOOM STUFF  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        //used on the tags mousemove; initialized as x_scale because the user might not zoom before using this functionality
        prev_xzoom = d3.zoom().on("zoom", prev_perform_xzoom);
        let xzoom = d3.zoom().on("zoom", perform_xzoom),
          yzoom = d3.zoom().on("zoom", perform_yzoom),
          // append the zoom detecting areas to the svg
          xzoom_area = append_hidden_rect(
            wrapper,
            inner_width,
            margin.top + margin.bottom,
            margin.left,
            inner_height + margin.top
          ),
          prev_xzoom_area = append_hidden_rect(
            wrapper,
            inner_width,
            margin.top,
            margin.left,
            0
          ),
          yzoom_area = append_hidden_rect(
            wrapper,
            margin.left * 2,
            inner_height,
            -margin.left,
            margin.top
          );

        xzoom_area
          .on("mouseover", function () {
            if (zoomable) {
              d3.select(this).style("cursor", "col-resize");
            }
          })
          .on("mouseout", function () {
            d3.select(this).style("cursor", "default");
          })
          .on("dblclick", function () {
            xzoom.transform(xzoom_area, d3.zoomIdentity);
          });

        prev_xzoom_area
          .on("mouseover", function () {
            if (zoomable) {
              d3.select(this).style("cursor", "col-resize");
            }
          })
          .on("mouseout", function () {
            d3.select(this).style("cursor", "default");
          })
          .on("dblclick", function () {
            prev_xzoom.transform(prev_xzoom_area, d3.zoomIdentity);
          });

        yzoom_area
          .on("mouseover", function () {
            if (zoomable) {
              d3.select(this).style("cursor", "row-resize");
            }
          })
          .on("mouseout", function () {
            d3.select(this).style("cursor", "default");
          })
          .on("dblclick", function () {
            yzoom.transform(yzoom_area, d3.zoomIdentity);
          });

        prev_xzoom_area.attr("id", "prev_xzoom_area");
        zoom_button.addEventListener(
          "click",
          () => {
            zoom_button.classList.toggle("plot-disabled");
            zoomable = !zoomable;
            control_zoom_functionality();
          },
          false
        );
        zoom_button.addEventListener(
          "touch",
          () => {
            zoom_button.classList.toggle("plot-disabled");
            zoomable = !zoomable;
            control_zoom_functionality();
          },
          false
        );
        reset_zoom_button.addEventListener(
          "click",
          () => {
            yzoom.transform(yzoom_area, d3.zoomIdentity);
            xzoom.transform(xzoom_area, d3.zoomIdentity);
            if (prev_x_scale !== null)
              prev_xzoom.transform(prev_xzoom_area, d3.zoomIdentity);
          },
          false
        );
        reset_zoom_button.addEventListener(
          "touch",
          () => {
            yzoom.transform(yzoom_area, d3.zoomIdentity);
            xzoom.transform(xzoom_area, d3.zoomIdentity);
            if (prev_x_scale !== null)
              prev_xzoom.transform(prev_xzoom_area, d3.zoomIdentity);
          },
          false
        );
        // prev_zoom_button.addEventListener('click', () => { prev_zoom_button.classList.toggle('disabled'); prev_zoomable = !prev_zoomable; control_zoom_functionality(); }, false);
        // prev_zoom_button.addEventListener('touch', () => { prev_zoom_button.classList.toggle('disabled'); prev_zoomable = !prev_zoomable; control_zoom_functionality(); }, false);

        // activate or deactivate the zoom functionality
        function control_zoom_functionality() {
          if (zoomable) {
            yzoom_area.call(yzoom).on("dblclick.zoom", null);
            xzoom_area.call(xzoom).on("dblclick.zoom", null);
            if (prev_lines_group != null) {
              prev_xzoom_area.call(prev_xzoom).on("dblclick.zoom", null);
            }
          } else {
            yzoom_area.on(".zoom", null);
            xzoom_area.on(".zoom", null);
            if (prev_lines_group != null) {
              prev_xzoom_area.on(".zoom", null);
            }
          }
        }

        function perform_xzoom() {
          let transform = d3.event.transform;
          new_x_scale = transform.rescaleX(x_scale);
          x_appended_axis.call(x_axis.scale(new_x_scale));

          let curr_transf = getTransformation(lines_group.attr("transform"));
          lines_group.attr(
            "transform",
            `translate(${margin.left + transform.x}, ${
              curr_transf.translateY
            }) scale(${transform.k}, ${curr_transf.scaleY})`
          );
        }

        function prev_perform_xzoom() {
          let transform = d3.event.transform;
          new_prev_x_scale = transform.rescaleX(prev_x_scale);
          prev_x_appended_axis.call(prev_x_axis.scale(new_prev_x_scale));

          let curr_transf = getTransformation(
            prev_lines_group.attr("transform")
          );
          prev_lines_group.attr(
            "transform",
            `translate(${margin.left + transform.x}, ${
              curr_transf.translateY
            }) scale(${transform.k}, ${curr_transf.scaleY})`
          );
        }

        function perform_yzoom() {
          let transform = d3.event.transform,
            curr_transf = getTransformation(lines_group.attr("transform"));
          new_y_scale = transform.rescaleY(y_scale);
          y_appended_axis.call(y_axis.scale(new_y_scale));

          lines_group.attr(
            "transform",
            `translate(${curr_transf.translateX}, ${
              margin.top + transform.y
            }) scale(${curr_transf.scaleX}, ${transform.k})`
          );

          if (prev_lines_group != null) {
            let curr_prev_transf = getTransformation(
              prev_lines_group.attr("transform")
            );
            prev_lines_group.attr(
              "transform",
              `translate(${curr_prev_transf.translateX}, ${
                margin.top + transform.y
              }) scale(${curr_prev_transf.scaleX}, ${transform.k})`
            );
          }
        }
      } //end tsv reading
      axios.get(process.env.REACT_APP_BACKEND_HOST + file_name).then((res) => {
        const content = res.data;
        const tsv_file = content.replace(/[\w=.*\n]+(time)/g, "time");

        var resample = d3.tsvParse(tsv_file, (d) => {
          // the tsv lines are composed of strings. so we need to parse them to ints
          for (var key in d) {
            if (key !== "time") {
              let polarity = key.includes("knee") ? -1 : 1;
              d[key] = ((+d[key] * 180) / 3.1415926) * polarity;
            } else {
              d[key] = +d[key];
            }
          }
          return d;
        });

        draw_resample(resample);
      });
      // d3.text(
      //   process.env.REACT_APP_BACKEND_HOST + file_name,
      //   function (tsv_file) {
      //     // remove comment rows with regex - not fully tested, but should work
      //     tsv_file = tsv_file.replace(/[\w=.*\n]+(time)/g, "time");

      //     var resample = d3.tsvParse(tsv_file, (d) => {
      //       // the tsv lines are composed of strings. so we need to parse them to ints
      //       for (var key in d) {
      //         if (key !== "time") {
      //           let polarity = key.includes("knee") ? -1 : 1;
      //           d[key] = ((+d[key] * 180) / 3.1415926) * polarity;
      //         } else {
      //           d[key] = +d[key];
      //         }
      //       }
      //       return d;
      //     });

      //     draw_resample(resample);
      //   }
      // );

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* DROPDOWN  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      let empty_option = document.createElement("option");
      let current_date_index = files_list.indexOf(file_name);
      let current_date = exam_dates[current_date_index];
      empty_option.innerHTML = current_date;
      empty_option.selected = true;
      empty_option.value = file_name;
      dropdown.appendChild(empty_option);

      exam_dates.forEach((date, i) => {
        if (i !== files_list.indexOf(file_name)) {
          let drop_item = document.createElement("option");
          drop_item.innerText = `${
            exam_dates[files_list.indexOf(file_name)]
          } vs ${date}`;
          drop_item.value = files_list[i];
          if (files_list[i] == null) {
            drop_item.disabled = true;
          }
          dropdown.appendChild(drop_item);
        }
      });

      dropdown.addEventListener("change", dropdown_change, false);
      function dropdown_change() {
        let new_file_path = this.value;
        let prev_exam_plot = d3.select(`#${card.id} svg .prev-exam-plot`);

        if (new_file_path === file_name) {
          // Chose the current exam (remove the prev_exam comparison)
          if (prev_x_appended_axis !== null) {
            prev_exam_plot.selectAll("*").remove();
            wrapper.selectAll(".previous").remove();
            [].forEach.call(
              card.querySelectorAll(`.previous-label`),
              function (e) {
                e.parentNode.removeChild(e);
              }
            );
            let deselect_buttons = card.querySelectorAll(".deselect-button");
            if (deselect_buttons.length > 1) {
              card
                .querySelector(".deselect-labels")
                .querySelector(".line-actions")
                .removeChild(deselect_buttons[1]);
            }
            d3.select("#prev_x_appended_axis").remove();
            let prev_xzoom_area = wrapper.select("#prev_xzoom_area");
            prev_xzoom.transform(prev_xzoom_area, d3.zoomIdentity);
          }
        } else {
          if (prev_x_appended_axis !== null) {
            prev_exam_plot.selectAll("*").remove();
            wrapper.selectAll(".previous").remove();
            [].forEach.call(
              card.querySelectorAll(`.previous-label`),
              function (e) {
                e.parentNode.removeChild(e);
              }
            );
            let deselect_buttons = card.querySelectorAll(".deselect-button");
            if (deselect_buttons.length > 1) {
              card
                .querySelector(".deselect-labels")
                .querySelector(".line-actions")
                .removeChild(deselect_buttons[1]);
            }
            d3.select("#prev_x_appended_axis").remove();
            let prev_xzoom_area = wrapper.select("#prev_xzoom_area");
            prev_xzoom.transform(prev_xzoom_area, d3.zoomIdentity);
          }

          // prev_zoom_button.style.display = 'inherit';
          axios
            .get(process.env.REACT_APP_BACKEND_HOST + new_file_path)
            .then((res) => {
              const content = res.data;
              // remove comment rows with regex - not fully tested, but should work
              const tsv_file = content.replace(/[\w=.*\n]+(time)/g, "time");

              var resample = d3.tsvParse(tsv_file, (d) => {
                // the tsv lines are composed of strings. so we need to parse them to ints
                for (var key in d) {
                  if (key !== "time") {
                    let polarity = key.includes("knee") ? -1 : 1;
                    d[key] = ((+d[key] * 180) / 3.1415926) * polarity;
                  } else {
                    d[key] = +d[key];
                  }
                }
                return d;
              });

              prev_x_scale = new_prev_x_scale = new_x_scale;
              prev_x_axis = d3.axisTop().scale(prev_x_scale).ticks(tick_num);
              let prev_line_const = d3
                .line()
                .curve(d3.curveMonotoneX)
                .x((d) => {
                  return prev_x_scale(d.time);
                })
                // the key value will be created by us, it doesn't come in the tsv
                .y((d) => {
                  return y_scale(d.value);
                });

              let chart_data = parse_data_and_add_label(
                card,
                resample,
                color_scale_prev,
                true
              );
              add_deselect_label(card, true);
              prev_lines_group = place_lines(
                card,
                prev_exam_plot,
                chart_data,
                margin.top,
                margin.left,
                prev_line_const,
                color_scale_prev,
                true
              );
              prev_x_appended_axis = append_axis(
                wrapper,
                prev_x_axis,
                margin.left,
                margin.top
              );
              prev_x_appended_axis.attr("id", "prev_x_appended_axis");
              let curr_transf = getTransformation(
                lines_group.attr("transform")
              );
              prev_lines_group.attr(
                "transform",
                `translate(${margin.left}, ${curr_transf.translateY}) scale(1, ${curr_transf.scaleY})`
              );

              place_tags(
                card,
                wrapper,
                chart_data,
                margin,
                color_scale_prev,
                true
              );

              if (zoomable) {
                eventFire(card.querySelector(".plot-actions div"), "click");
              }
            });
        }
      }

      function place_lines(
        card,
        svg_group,
        data,
        margin_top,
        margin_left,
        line,
        color_scale,
        previous = false
      ) {
        // create the data group that olds the data and that is within the clipPath
        var lines_group = svg_group
          .selectAll(".interactive_plot_lines_group")
          .data(data)
          .enter()
          .append("g")
          .attr("clip-path", `url(#${card.id}-clip)`)
          .attr("class", "interactive_plot_lines_group");

        // add all the lines paths
        return lines_group
          .append("path")
          .attr("class", "interactive-plot-line")
          .attr("id", function (d) {
            return previous ? `${d.key}-previous` : d.key;
          })
          .attr("d", (d) => {
            return line(d.values);
          })
          .attr("fill", "none")
          .attr("transform", `translate(${margin_left}, ${margin_top})`)
          .style("stroke", (d) => {
            return color_scale(d.key);
          });
      }

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* PLOT TAGS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      function place_tags(
        card,
        wrapper,
        chart_data,
        margin,
        color_scale,
        previous = false
      ) {
        // the over events are all placed below the creaton of the xzoom area, because the over detection must be ontop of the vertical line
        if (!previous) {
          wrapper
            .append("path") // this is the black vertical line to follow the mouse
            .attr("class", "tags-vertical-line hide-svg-element")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .style("opacity", "0");
        }

        let tag_per_line_class = tags_active
          ? "tag-per-line"
          : "tag-per-line hide-svg-element";
        if (previous) tag_per_line_class = tag_per_line_class + " previous";
        let tag_per_line = wrapper
          .selectAll(null)
          .data(chart_data)
          .enter()
          .append("g")
          .attr("id", function (d) {
            return previous ? `tag-${d.key}-previous` : `tag-${d.key}`;
          })
          .style("opacity", "0")
          .attr("class", tag_per_line_class);
        tag_per_line
          .append("circle")
          .attr("transform", `translate(${margin.left}, ${margin.top})`)
          .attr("r", 4)
          .attr("class", "tag-circle")
          .style("stroke", (d) => {
            return color_scale(d.key);
          })
          .style("fill", (d) => {
            return color_scale(d.key);
          });

        tag_per_line
          .append("text")
          .attr("class", "tag-text smallText")
          // .attr('style', 'font-weight: bold')
          .attr("transform", "translate(30,10)");
      }

      // create and array of objects, that old the data for each category of data
      //map function will perform this function to every entry of the domain array, which are the tsv keys, except 'time'
      function parse_data_and_add_label(
        card,
        data,
        color_scale,
        previous = false
      ) {
        return color_scale.domain().map((key) => {
          add_label_to_card(card, key, color_scale(key), previous);
          return {
            // in this category (represented by the key) for each line, we will parse a time and value
            key: key,
            values: data.map((d) => {
              return { time: d.time, value: d[key] };
            }),
          };
        });
      }

      function add_deselect_label(card, previous) {
        let labels = document.querySelector(
            `#${card.id} .interactive-plot-labels`
          ),
          label_div = card.querySelector(
            `#${card.id} .interactive-plot-labels .deselect-labels`
          ),
          label_span = document.createElement("div");

        if (!label_div) {
          label_div = document.createElement("div");
          label_div.classList =
            "deselect-labels interactive-plot-label-container";
          labels.insertBefore(label_div, labels.firstChild);
        }

        let label_actions = label_div.querySelector(".line-actions");

        if (!label_actions) {
          label_actions = document.createElement("div");
          let empty_p = document.createElement("p");
          empty_p.classList = "label-container-title regular-text medium mb-0";
          empty_p.innerHTML = "&nbsp;";

          label_div.appendChild(empty_p);
          label_actions.classList =
            "line-actions interactive-plot-label-container";

          // label_span.classList = "deselect-button interactive-plot-label";
          label_span.classList = "deselect-button interactive-plot-label";
          // label_span.innerHTML = `<i class="icon hidden white small" aria-hidden="true"></i>`;
          label_span.innerText = "All";
          // add event listeners
          label_span.addEventListener(
            "click",
            () => {
              hide_session(label_span, previous);
            },
            false
          );
          label_span.addEventListener(
            "touch",
            () => {
              hide_session(label_span, previous);
            },
            false
          );
          // TEMPORARILY REMOVING "ALL" LABEL
          label_actions.appendChild(label_span);
          label_div.appendChild(label_actions);
        } else {
          label_span.classList = "deselect-button";
          label_span.innerHTML = `<i class="icon hidden white small" aria-hidden="true"></i>`;
          // add event listeners
          label_span.addEventListener(
            "click",
            () => {
              hide_session(label_span, previous);
            },
            false
          );
          label_span.addEventListener(
            "touch",
            () => {
              hide_session(label_span, previous);
            },
            false
          );
          label_actions.appendChild(label_span);
        }
      }

      // this function is responsible to add the labels to the card. creating all elements and event listeners necessary
      // the '.interactive-plot-labels' major container, is the only element not created by this function beacuse it needs to influence the svg div height and width,
      // being, therefor, created right in the start of the plot building
      function add_label_to_card(card, data_key, label_color, previous) {
        let labels = data.labels;
        let side = labels[data_key].side,
          label_div =
            document.querySelector(
              `#${card.id} .interactive-plot-label-container[data-title = '${labels[data_key].name}']`
            ) || create_label_div(card, data_key, labels), //if the div exists get it, if not, create it
          label_span = document.createElement("div"); //create the element that will be clickable and colored in accordance to the line color
        label_span.classList = "interactive-plot-label";
        label_div = label_div.querySelector(".line-actions");
        label_span.innerText = side === "M" ? "   " : side;

        if (side === "M") label_span.classList.add("middle_label");

        label_span.style.background = label_color;
        if (previous) label_span.classList.add("previous-label");
        let line_id = previous ? `${data_key}-previous` : data_key; //the id of the line that this label controls

        // add event listeners
        label_span.addEventListener("click", () => {
          label_click(card, label_span, line_id);
        });
        label_span.addEventListener("touch", () => {
          label_click(card, label_span, line_id);
        });
        label_span.addEventListener("dblclick", () => {
          label_dbclick(card, label_span, line_id);
        });
        label_span.addEventListener("mouseenter", () => {
          label_mouseenter(card, label_span, line_id);
        });
        label_span.addEventListener("mouseout", () => {
          label_mouseout(card, label_span, line_id);
        });

        label_div.appendChild(label_span);

        function create_label_div(card, label_id, labels) {
          let label_container = document.createElement("div");
          let label_container_title = document.createElement("p");

          // label_container.id = label_id;
          label_container.classList = "interactive-plot-label-container";

          label_container_title.innerText = label_container.dataset.title =
            labels[label_id].name;
          label_container_title.classList =
            "label-container-title regular-text medium mb-0";
          label_container.appendChild(label_container_title);
          let label_actions_wrapper = document.createElement("div");
          label_actions_wrapper.classList = "line-actions";
          label_container.appendChild(label_actions_wrapper);

          card
            .querySelector(".interactive-plot-labels")
            .appendChild(label_container);
          return label_container;
        }

        // the button is clicked. Remeber that it is already on the state of mouse enter
        function label_click(card, label_elem, id) {
          if (!label_elem.classList.contains("wait-mouseout")) {
            // the button was clicked but the mouse is still on top of it
            if (!label_elem.classList.contains("plot-disabled")) {
              d3.select(`#${card.id} #${id}`).classed("hide-svg-element", true);
              d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", true);
              label_elem.classList.add("wait-mouseout");
            }
          } else {
            if (label_elem.classList.contains("plot-disabled")) {
              d3.select(`#${card.id} #${id}`).classed(
                "hide-svg-element",
                false
              );
              d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", false);
            } else {
              d3.select(`#${card.id} #${id}`).classed("hide-svg-element", true);
              d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", true);
            }
          }
          label_elem.classList.toggle("plot-disabled");

          if (
            d3
              .selectAll(
                `#${card.id} .interactive-plot-line:not(.hide-svg-element)`
              )
              .empty()
          ) {
            /*             hide_button.innerHTML = hide
              ? `<i class="icon visible" aria-hidden="true"></i>`
              : `<i class="icon hidden" aria-hidden="true"></i>`; */
            hide = !hide;
          }
        }

        function label_dbclick(card, label_elem, id) {
          d3.selectAll(`#${card.id} .interactive-plot-line`).classed(
            "hide-svg-element",
            true
          );
          d3.selectAll(`#${card.id} .tag-per-line`).classed("hide-tags", true);
          d3.select(`#${card.id} #${id}`).classed("hide-svg-element", false);
          d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", false);

          let labels = card.querySelectorAll(`.interactive-plot-label`);
          [...labels].slice(1).map((label) => {
            label.classList.add("plot-disabled");
          });
          label_elem.classList.remove("plot-disabled");
        }

        function label_mouseenter(card, label_elem, id) {
          d3.select(`#${card.id} #${id}`).classed("bold-line", true); //.moveToFront();
          if (label_elem.classList.contains("plot-disabled")) {
            d3.select(`#${card.id} #${id}`).classed("hide-svg-element", false);
            d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", false);
            // d3.select(`#${card.id} #circle-${id}`).classed("hide-svg-element", false);
            // d3.select(`#${card.id} #text-${id}`).classed("hide-svg-element", false);
          }
        }

        function label_mouseout(card, label_elem, id) {
          d3.select(`#${card.id} #${id}`).classed("bold-line", false);
          if (label_elem.classList.contains("plot-disabled")) {
            d3.select(`#${card.id} #${id}`).classed("hide-svg-element", true);
            d3.select(`#${card.id} #tag-${id}`).classed("hide-tags", true);
            // d3.select(`#${card.id} #circle-${id}`).classed("hide-svg-element", true);
            // d3.select(`#${card.id} #text-${id}`).classed("hide-svg-element", true);
            label_elem.classList.remove("wait-mouseout");
          }
        }
      } //end of label related functions
    }

    function append_clip_path(
      card,
      svg_group,
      inner_width,
      inner_height,
      margin_top,
      margin_left
    ) {
      //adding a clipPath to the svg that is the exact size of the visible area, to avid lines overflow on zoom
      svg_group
        .append("defs")
        .append("clipPath")
        .attr("id", `${card.id}-clip`)
        .append("rect")
        .attr("width", inner_width)
        .attr("height", inner_height)
        .attr("transform", `translate(${margin_left}, ${margin_top})`);
    }

    function set_domain(scale, data, axis) {
      if (axis === "x") {
        scale.domain([
          d3.min(data, function (c) {
            return d3.min(c.values, function (v) {
              return v.time;
            });
          }),
          d3.max(data, function (c) {
            return d3.max(c.values, function (v) {
              return v.time;
            });
          }),
        ]);
      } else {
        scale.domain([
          d3.min(data, function (c) {
            return d3.min(c.values, function (v) {
              return v.value;
            });
          }),
          d3.max(data, function (c) {
            return d3.max(c.values, function (v) {
              return v.value;
            });
          }),
        ]);
      }
    }

    function add_grid_lines(
      svg_group,
      width,
      height,
      margin_left,
      margin_top,
      x_scale,
      y_scale,
      tick_num
    ) {
      // add the X gridlines
      svg_group
        .append("g")
        .attr("transform", `translate(${margin_left}, ${height + margin_top})`)
        .attr("class", "grid")
        .call(
          d3
            .axisBottom(x_scale)
            .ticks(tick_num)
            .tickSize(-height)
            .tickFormat("")
        );

      // add the Y gridlines
      svg_group
        .append("g")
        .attr("transform", `translate(${margin_left}, ${margin_top})`)
        .attr("class", "grid")
        .call(
          d3.axisLeft(y_scale).ticks(tick_num).tickSize(-width).tickFormat("")
        );
    }

    function append_axis(svg_group, axis, margin_left, margin_top) {
      return svg_group
        .append("g")
        .attr("transform", `translate(${margin_left}, ${margin_top})`)
        .attr("class", "axis")
        .call(axis);
    }

    function append_hidden_rect(
      svg_group,
      width,
      height,
      margin_left,
      margin_top
    ) {
      return svg_group
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("transform", `translate(${margin_left}, ${margin_top})`)
        .style("visibility", "hidden")
        .attr("pointer-events", "all");
    }

    function getTransformation(transform) {
      // Create a dummy g for calculation purposes only. This will never
      // be appended to the DOM and will be discarded once this function
      // returns.
      var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

      // Set the transform attribute to the provided string value.
      g.setAttributeNS(null, "transform", transform);

      // consolidate the SVGTransformList containing all transformations
      // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
      // its SVGMatrix.
      var matrix = g.transform.baseVal.consolidate().matrix;

      // Below calculations are taken and adapted from the private function
      // transform/decompose.js of D3's module d3-interpolate.
      var { a, b, c, d, e, f } = matrix; // ES6, if this doesn't work, use below assignment
      // var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
      var scaleX, scaleY, skewX;
      if ((scaleX = Math.sqrt(a * a + b * b))) {
        a /= scaleX;
        b /= scaleX;
      }
      if ((skewX = a * c + b * d)) {
        c -= a * skewX;
        d -= b * skewX;
      }
      if ((scaleY = Math.sqrt(c * c + d * d))) {
        c /= scaleY;
        d /= scaleY;
        skewX /= scaleY;
      }
      if (a * d < b * c) {
        a = -a;
        b = -b;
        skewX = -skewX;
        scaleX = -scaleX;
      }
      return {
        translateX: e,
        translateY: f,
        rotate: (Math.atan2(b, a) * 180) / Math.PI,
        skewX: (Math.atan(skewX) * 180) / Math.PI,
        scaleX: scaleX,
        scaleY: scaleY,
      };
    }

    function eventFire(el, etype) {
      if (el.fireEvent) {
        el.fireEvent("on" + etype);
      } else {
        var evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
    if (data === null)
      create_card(
        reff.current,
        15,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );

    if (data) build_interactive_plot(reff.current, data);
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default InteractiveMultilineChart;
