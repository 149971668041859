export const GET_PATIENT_CONNECTION_REQUESTS =
  "GET_PATIENT_CONNECTION_REQUESTS";
export const GET_PATIENT_CONNECTION_REQUESTS_SUCCESS =
  "GET_PATIENT_CONNECTION_REQUESTS_SUCCESS";
export const GET_PATIENT_CONNECTION_REQUESTS_FAIL =
  "GET_PATIENT_CONNECTION_REQUESTS_FAIL";

export const REJECT_PATIENT_CONNECTION_REQUESTS =
  "REJECT_PATIENT_CONNECTION_REQUESTS";
export const REJECT_PATIENT_CONNECTION_REQUESTS_SUCCESS =
  "REJECT_PATIENT_CONNECTION_REQUESTS_SUCCESS";
export const REJECT_PATIENT_CONNECTION_REQUESTS_FAIL =
  "REJECT_PATIENT_CONNECTION_REQUESTS_FAIL";

export const getPatientConnectionRequests = ({
  page = 1,
  current_institution_id,
  search = undefined,
  new_only = false,
} = {}) => ({
  type: GET_PATIENT_CONNECTION_REQUESTS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${current_institution_id}/mkinetikos_licenses/requests/`,
      params: { page, search, new_only },
    },
  },
});

export const rejectPatientConnectionRequests = ({
  current_institution_id,
  requests_id_list,
}) => ({
  type: REJECT_PATIENT_CONNECTION_REQUESTS,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/institutions/${current_institution_id}/mkinetikos_licenses/requests/reject/`,
      data: { requests_id_list },
    },
  },
});
