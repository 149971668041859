import { useState } from "react";

/**
 * Hook for form validation
 * @param {options}: A dictionary of the field names and their validations
 *
 * @return {data}: A dictionary whose keys are the field names, and whose values include validation logic
 * @return {handleChange}: A handler for the component to update the data state
 * @return {handleSubmit}: A handler for the component to submit the data
 * @return {errors}: Any existing form errors
 *
 * EXAMPLE:
 * const { handleSubmit, handleChange, data, errors } = useForm({
 * validations: {
 *   name: {
 *     pattern: {
 *       value: '^[A-Za-z]*$',
 *       message: "You're not allowed to...",
 *     },
 *   },
 *   age: {
 *     custom: {
 *       isValid: (value) => parseInt(value, 10) > 17,
 *       message: 'You have to be at least 18 years old.',
 *     },
 *   },
 *   password: {
 *     required: {
 *       value: true,
 *       message: 'This field is required',
 *     },
 *     custom: {
 *       isValid: (value) => value.length > 6,
 *       message: 'The password needs to be at...',
 *     },
 *   },
 *  },
 * });
 */

export const useFormValidation = (options) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState({});

  const handleChange = (key, sanitizeFn) => (e) => {
    const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
    setData({ ...data, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validations = options?.validations;

    if (validations) {
      let valid = true;
      const newErrors = {};

      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];

        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }

        if (!valid) {
          setErrors(newErrors);
          return;
        }
      }

      setErrors({});
    }

    if (options?.onSubmit) {
      options.onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  };
};
