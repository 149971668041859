import {
  GET_TICKETS_FAIL,
  GET_TICKETS_SUCCESS,
  GET_TICKET_INFO_FAIL,
  GET_TICKET_INFO_SUCCESS,
  GET_TICKET_REPLIES_FAIL,
  GET_TICKET_REPLIES_SUCCESS,
} from "../actions/tickets";

const initialState = {
  tickets: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
  },
  ticketInfo: {
    id: null,
    title: "",
    support_state: "",
  },
  ticketReplies: {
    obj_list: [],
  },
};

const tickets = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKETS_SUCCESS: {
      return { ...state, tickets: action.payload.data.data };
    }
    case GET_TICKETS_FAIL: {
      return initialState;
    }
    case GET_TICKET_INFO_SUCCESS: {
      return { ...state, ticketInfo: action.payload.data.data };
    }
    case GET_TICKET_INFO_FAIL: {
      return { ...state, tickets: initialState.tickets };
    }
    case GET_TICKET_REPLIES_SUCCESS: {
      return { ...state, ticketReplies: action.payload.data.data };
    }
    case GET_TICKET_REPLIES_FAIL: {
      return { ...state, tickets: initialState.tickets };
    }
    default:
      return state;
  }
};

export default tickets;
