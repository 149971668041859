import { StyleSheet } from "@react-pdf/renderer";

const Styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "#eff3f6",
  },
  text: {
    fontSize: 12,
    margin: 12,
    color: "#4c657a",
    textAlign: "justify",
  },
  subText: {
    fontSize: 10,
    color: "#4c657a",
  },
  boldText: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#255986",
    marginBottom: 4,
  },
  title: {
    margin: 12,
    paddingTop: 20,
  },
  section: {
    width: "60%",
    margin: "0 auto",
    padding: 20,
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  backupTokenContainer: {
    width: "30%",
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: "1px solid #eff3f6",
    fontSize: 10,
  },
  image: {
    width: "20%",
    margin: 7,
  },
});

export default Styles;
