/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import DropdownOptions from "../../../../components/dropdownOptions";
import Spinner from "../../../../components/spinner";

//* ************************************** REDUX *****************************************
import {
  getPatientAvailableChatParticipants,
  createPatientChat,
  getPatientChatsList,
} from "../../../../redux/actions/patients";

//* ************************************** GLOBALS ***************************************
import chevronRightIcon from "../../../../assets/images/chevron-right_base.svg";
import groupIcon from "../../../../assets/images/group_base.svg";
import plusIcon from "../../../../assets/images/plus-blue-round-white.svg";
import closeIcon from "../../../../assets/images/close_white.svg";
import removeIcon from "../../../../assets/images/remove_primary_white.svg";
import { getAvatarWithInitials } from "../../../../utils/avatar";

const ChatsList = ({
  openChatId,
  setOpenChatId,
  chatsList,
  setShowChatAlreadyExistsModal,
  canOnlyManageOwnPatients,
}) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");

  const [isCreateChatOpen, setIsCreateChatOpen] = useState(false);
  const newParticipantInputRef = useRef(null);

  const [newChatParticipants, setNewChatParticipants] = useState([]);
  const [isLoadingNewChatParticipants, setIsLoadingNewChatParticipants] = useState(false);
  const [newChatParticipantSearch, setNewChatParticipantSearch] = useState("");

  const patientHome = useSelector((state) => state.patientHome);
  const patientAvailableChatParticipants = useSelector(
    (state) => state.patientAvailableChatParticipants
  );

  const { t, i18n } = useTranslation();

  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
    if (newChatParticipantSearch !== "") {
      setIsLoadingNewChatParticipants(true);
      dispatch(
        getPatientAvailableChatParticipants(patient_id, {
          q: newChatParticipantSearch,
        })
      ).then(
        (res) => {
          setIsLoadingNewChatParticipants(false);
        },
        (err) => {
          console.log("getPatientAvailableChatParticipants Error:", err);
          setIsLoadingNewChatParticipants(false);
        }
      );
    }
  };
  const onBlur = () => setFocused(false);
  const onInput = (e) => {
    setNewChatParticipantSearch(e.target.textContent);
    if (e.target.textContent !== "") {
      setIsLoadingNewChatParticipants(true);
      dispatch(
        getPatientAvailableChatParticipants(patient_id, {
          q: e.target.textContent,
        })
      ).then(
        (res) => {
          setIsLoadingNewChatParticipants(false);
        },
        (err) => {
          console.log("getPatientAvailableChatParticipants Error:", err);
          setIsLoadingNewChatParticipants(false);
        }
      );
    }
  };

  const addNewChatParticipant = (newParticipant) => {
    setNewChatParticipants([...newChatParticipants, newParticipant]);
  };

  const removeNewChatParticipant = (id) => {
    const idIndex = newChatParticipants.findIndex((participant) => {
      return participant.id === id;
    });

    if (idIndex > -1) {
      let participantsArray = [...newChatParticipants];
      participantsArray.splice(idIndex, 1);
      setNewChatParticipants(participantsArray);
    }
  };

  const createNewChat = () => {
    const newChatParticipantsIdList = newChatParticipants.map((participant) => participant.id);
    dispatch(createPatientChat(patient_id, newChatParticipantsIdList)).then(
      (res) => {
        setIsCreateChatOpen(false);
        dispatch(getPatientChatsList(patient_id)).then(
          (resu) => {
            if (res.payload.data && res.payload.data.msg === "Chat already exists") {
              setShowChatAlreadyExistsModal(true);
            }
            if (res.payload.data.data && res.payload.data.data.id) {
              setOpenChatId(res.payload.data.data.id);
            }
          },
          (erro) => {
            console.log("getPatientChatsList Error:", erro);
          }
        );
      },
      (err) => {
        console.log("createPatientChat Error:", err);
        setIsCreateChatOpen(false);
        dispatch(getPatientChatsList(patient_id)).then(
          (resu) => {},
          (erro) => {
            console.log("getPatientChatsList Error:", erro);
          }
        );
      }
    );
  };
  //* ************************************** HANDLERS ************************************

  const renderParticipantsAvatars = (participants) => {
    const reverseParticipants = [...participants].reverse();
    return reverseParticipants.map((participant, index) => (
      <img
        key={index}
        className="chat-participant-avatar"
        alt="Participant avatar"
        src={participant.photo_url}
        onError={(e) => {
          e.target.src = getAvatarWithInitials(
            64,
            participant.id === patientHome.mkinetikos_id ? patientHome.name : participant.name
          );
        }}
      />
    ));
  };

  const getDropdownButtons = () => {
    let buttonList = [];

    if (isLoadingNewChatParticipants) {
      buttonList = [
        {
          html: (
            <div
              className="loading"
              key="loading"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              <div className="spinner small-blue" />
            </div>
          ),
        },
      ];
    } else {
      if (patientAvailableChatParticipants.length > 0) {
        buttonList = patientAvailableChatParticipants
          .map((avPart) => {
            const alreadyInChat = newChatParticipants.some((participant) => {
              return participant.id === avPart.id;
            });
            if (!alreadyInChat) {
              return {
                html: (
                  <button
                    key={avPart.id}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      addNewChatParticipant(avPart);
                      setNewChatParticipantSearch("");
                      newParticipantInputRef.current.textContent = "";
                    }}
                  >
                    <img
                      src={avPart.photo}
                      alt="New Chat Participant"
                      className="dropdown-btn-img"
                      onError={(e) => {
                        e.target.src = getAvatarWithInitials(64, avPart.full_name);
                      }}
                    />
                    <div className="regular-text black-color">{avPart.full_name}</div>
                  </button>
                ),
              };
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);
      }

      if (buttonList.length === 0) {
        buttonList = [
          {
            html: (
              <button key="no_users">
                <div className="regular-text black-color">
                  {t("patient.my_care_team.no_available_users")}
                </div>
              </button>
            ),
          },
        ];
      }
    }
    return buttonList;
  };

  const renderInstitutionChat = () => {
    return (
      <div
        className={`chat-list-item-wrapper ${
          openChatId === chatsList.institution_chat.id ? "open" : ""
        }`}
      >
        <div
          className={`chat-list-item ${openChatId === chatsList.institution_chat.id ? "open" : ""}`}
          onClick={() => {
            if (openChatId !== chatsList.institution_chat.id) {
              setOpenChatId(chatsList.institution_chat.id);
            }
          }}
        >
          <img
            src={chatsList.institution_chat.photo}
            alt="Chat Avatar"
            className="chat-avatar mr-1"
          />
          <div className="small-title">{chatsList.institution_chat.name}</div>
          {chatsList.institution_chat.new_messages > 0 ? (
            <>
              <div className="ms-auto new-messages-container">
                <div className="regular-text surface-white-color">
                  {chatsList.institution_chat.new_messages}
                </div>
              </div>
              <img className="ml-4" alt="Open chat" src={chevronRightIcon} />
            </>
          ) : (
            <img className="ms-auto" alt="Open chat" src={chevronRightIcon} />
          )}
        </div>
      </div>
    );
  };

  const renderPrivateChats = () => {
    let chatsListToRender = [];
    let groupChatNum = 0;
    for (let i = 0; i < chatsList.private_chat.length; i++) {
      let chat = chatsList.private_chat[i];
      let chatName = "";
      if (chat.participants.length > 2) {
        groupChatNum++;
        chatName = t("patient.my_care_team.group_conversation") + "I".repeat(groupChatNum);
        chatName = `${t("patient.my_care_team.group_conversation")} ${groupChatNum}`;
      } else {
        chatName = t("patient.my_care_team.private_conversation");
      }

      chatsListToRender.push(
        <div
          key={i}
          className={`chat-list-item-wrapper ${
            i === chatsList.private_chat.length - 1 ? "mb-4" : "mb-2"
          } ${openChatId === chat.id ? "open" : ""}`}
        >
          <div
            className={`chat-list-item ${openChatId === chat.id ? "open" : ""}`}
            onClick={() => {
              if (openChatId !== chat.id) {
                setOpenChatId(chat.id);
              }
            }}
          >
            <img
              src={chat.participants.length > 2 ? groupIcon : patientHome.mkinetikos_photo}
              alt="Chat Avatar"
              className="chat-avatar circle mr-1"
              onError={(e) => {
                if (chat.participants.length === 2) {
                  e.target.src = getAvatarWithInitials(64, patientHome.name);
                }
              }}
            />
            <div className="d-flex flex-column justify-content-between">
              <div className="small-title">{chatName}</div>
              {chat.participants.length > 2 && (
                <div className="chat-participants-container">
                  {renderParticipantsAvatars(chat.participants)}
                </div>
              )}
            </div>
            {chat.new_messages > 0 ? (
              <>
                <div className="ms-auto new-messages-container">
                  <div className="regular-text surface-white-color">{chat.new_messages}</div>
                </div>
                <img className="ml-4" alt="Open chat" src={chevronRightIcon} />
              </>
            ) : (
              <img className="ms-auto" alt="Open chat" src={chevronRightIcon} />
            )}
          </div>
        </div>
      );
    }

    return chatsListToRender;
  };

  return (
    <div className="d-flex flex-column">
      <div className="small-title mb-2">{t("patient.my_care_team.institution_chat")}</div>
      {renderInstitutionChat()}
      <div className="small-title mt-4 mb-2">{t("patient.my_care_team.private_chats")}</div>
      {renderPrivateChats()}

      {isCreateChatOpen && (
        <div className="new-chat-wrapper">
          <div className="new-chat-header">
            <div className="regular-text surface-white-color">
              {t("patient.my_care_team.new_chat")}
            </div>
            <img
              src={closeIcon}
              alt="Close new chat"
              className="close-icon"
              onClick={() => {
                setNewChatParticipants([]);
                setIsCreateChatOpen(false);
              }}
            />
          </div>
          <div className="new-chat-container">
            <div className="regular-text mr-2">{t("patient.my_care_team.participants")}:</div>
            <div
              onClick={(e) => {
                e.preventDefault();
                newParticipantInputRef.current.focus();
              }}
              className="new-chat-participants"
            >
              <div className="new-chat-participant-container current-patient">
                <div className="regular-text surface-white-color">{patientHome.name}</div>
              </div>
              {newChatParticipants.map((participant) => (
                <div key={participant.id} className="new-chat-participant-container">
                  <div className="regular-text surface-white-color">{participant.full_name}</div>
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      removeNewChatParticipant(participant.id);
                    }}
                    src={removeIcon}
                    alt="Remove participant"
                    className="remove-icon"
                  />
                </div>
              ))}
              <span
                ref={newParticipantInputRef}
                autoFocus
                className="regular-text black-color add-participant-input"
                contentEditable
                onFocus={onFocus}
                onBlur={onBlur}
                onInput={onInput}
              />
              {focused && newChatParticipantSearch !== "" && (
                <DropdownOptions buttons={getDropdownButtons()} />
              )}
            </div>
            <button
              onClick={() => {
                createNewChat();
              }}
              className="button small-button primary-button ml-4"
            >
              {t("buttons.create_chat")}
            </button>
          </div>
        </div>
      )}

      <button
        onClick={() => {
          setIsCreateChatOpen(true);
          setTimeout(() => {
            newParticipantInputRef.current.focus();
          });
        }}
        className="button small-button primary-button align-self-center"
        disabled={isCreateChatOpen || canOnlyManageOwnPatients}
      >
        <img alt="New Conversation" className="mr-1" src={plusIcon} />
        <span>{t("patient.my_care_team.new_chat")}</span>
      </button>
    </div>
  );
};

export default React.memo(ChatsList);
