//* ************************************** REACT IMPORTS *************************************
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************
import { addCollaborator, cancelInvitation } from "redux/actions/institutions";
import {
  getCollaborators,
  updateCollaborator,
  removeCollaborator,
} from "../../../../../redux/actions/institutions";

//* ************************************* OUR COMPONENTS *************************************
import OptionsButton from "../../../../../components/optionsButton";
import DropdownOptions from "../../../../../components/dropdownOptions";
import AddCollaborator from "../../../../../components/addCollaborator";
import ChangeCollaboratorRole from "../../../../../components/changeCollaboratorRole";
import Paginator from "../../../../../components/paginator";
import MessageModal from "../../../../../components/messageModal";
import Spinner from "components/spinner";

//* ************************************** CUSTOM HOOKS **************************************
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import plusBlue from "../../../../../assets/images/plus-blue-background-white.svg";
import search from "../../../../../assets/images/search.svg";
import userIcon from "../../../../../assets/images/buttonIcons/person.svg";
import chevronUpSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronUpIcon from "assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronDownIcon from "assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";

const Collaborators = ({ goToUser }) => {
  //* **************************************** SETUP *****************************************
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { institution_id } = useParams();

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCollaboratorModalOpen, setIsAddCollaboratorModalOpen] = useState(false);
  const [changeRoleIdSelectedUser, setChangeRoleIdSelectedUser] = useState(null);
  const [toRemoveCollab, setToRemoveCollab] = useState(null);
  const [cancelInvitationConfirm, setCancelInvitationConfirm] = useState(null);
  const [cancelColabInfo, setCancelColabInfo] = useState({});
  const [isResendInvitationLoading, setIsResendInvitationLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("status");

  const collaborators = useSelector((state) => state.institutions.collaborators);
  const currentUserId = useSelector((state) => state.user.widget_info.userinfo_id);

  useOnClickOutside([dropDownRef, optionsRef], () => setSelectedUser(null));

  const getCollaboratorsList = (page) => {
    setIsLoading(true);
    dispatch(
      getCollaborators(institution_id, {
        page: searchOptions?.page,
        search: searchOptions?.q,
        order_by: searchOptions?.orderBy || "status",
        items_per_page: searchOptions?.itemsPerPage,
      })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {});
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(
    getCollaboratorsList,
    300,
    "collaborators",
    "status"
  );

  const changeCollaboratorRole = (collaborator_id, role_id) => {
    dispatch(updateCollaborator(institution_id, collaborator_id, role_id)).then(() => {
      setChangeRoleIdSelectedUser(null);
    });
  };

  const removeCollab = (collaborator_id) => {
    dispatch(removeCollaborator(institution_id, collaborator_id)).then(() => {
      setToRemoveCollab(null);
    });
  };

  const resendInvitation = (data) => {
    setIsResendInvitationLoading(true);
    dispatch(addCollaborator(institution_id, data)).finally(() =>
      setIsResendInvitationLoading(false)
    );
  };

  const confirmCancelInvitation = (invite_token) => {
    dispatch(cancelInvitation(invite_token)).then(() => {
      setCancelInvitationConfirm(null);
      getCollaboratorsList(1);
    });
  };

  const onClickResendInvitation = (data) => {
    resendInvitation(data);
  };

  const onClickCancelInvitation = (data) => {
    setCancelInvitationConfirm(true);
    setCancelColabInfo(data);
  };

  const getUserStatusStyle = (user_status) => {
    switch (user_status) {
      case "expired":
        return "";
      case "pending":
        return "light-orange";
      case "refused":
        return "warning-color";
      default:
        return "secondary-color";
    }
  };
  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <div className="institution-form-body-wrapper-2">
      <div className="row">
        <div className="col">
          <div className="position-relative mb-4">
            <input
              value={searchOptions?.q}
              onChange={(e) => updateSearchText(e.target.value)}
              type="text"
              className="search-input w-100"
              placeholder={t("placeholders.search_collaborator")}
            />
            <img alt="search-icon" className="search-icon" src={search} />
          </div>
        </div>
        <div className="col-auto">
          <button
            onClick={() => setIsAddCollaboratorModalOpen(true)}
            className="button small-button primary-button"
          >
            <img alt="plus-icon" className="button-icon" src={plusBlue} />
            {t("buttons.add_collaborator")}
          </button>
        </div>
      </div>
      <div className="row align-items-center list-label">
        <div
          className="col-4 d-flex pointer"
          onClick={() => {
            updateOrderBy(
              searchOptions?.orderBy === "name"
                ? "name_inv"
                : searchOptions?.orderBy === "name_inv"
                ? "status"
                : "name"
            );
          }}
        >
          {t("user_info.name")}
          <div
            className={`order-img-container ${
              searchOptions?.orderBy === "name" || searchOptions?.orderBy === "name_inv"
                ? "active"
                : ""
            }`}
          >
            {searchOptions?.orderBy !== "name" && (
              <img
                src={searchOptions?.orderBy === "name_inv" ? chevronUpSelectedIcon : chevronUpIcon}
                alt="Order by Name"
              />
            )}
            {searchOptions?.orderBy !== "name_inv" && (
              <img
                src={searchOptions?.orderBy === "name" ? chevronDownSelectedIcon : chevronDownIcon}
                alt="Order by Name Invert"
              />
            )}
          </div>
        </div>
        <div
          className="col-2 d-flex pointer"
          onClick={() => {
            updateOrderBy(
              searchOptions?.orderBy === "role"
                ? "role_inv"
                : searchOptions?.orderBy === "role_inv"
                ? "status"
                : "role"
            );
          }}
        >
          {t("roles.role")}
          <div
            className={`order-img-container ${
              searchOptions?.orderBy === "role" || searchOptions?.orderBy === "role_inv"
                ? "active"
                : ""
            }`}
          >
            {searchOptions?.orderBy !== "role" && (
              <img
                src={searchOptions?.orderBy === "role_inv" ? chevronUpSelectedIcon : chevronUpIcon}
                alt="Order by Role"
              />
            )}
            {searchOptions?.orderBy !== "role_inv" && (
              <img
                src={searchOptions?.orderBy === "role" ? chevronDownSelectedIcon : chevronDownIcon}
                alt="Order by Role Invert"
              />
            )}
          </div>
        </div>
        <div
          className="col-2 d-flex center-align"
          onClick={() => {
            updateOrderBy(searchOptions?.orderBy === "status_inv" ? "status" : "status_inv");
          }}
        >
          {t("status.status")}
          <div
            className={`order-img-container ${
              searchOptions?.orderBy === "status" || searchOptions?.orderBy === "status_inv"
                ? "active"
                : ""
            }`}
          >
            {searchOptions?.orderBy !== "status" && (
              <img
                src={
                  searchOptions?.orderBy === "status_inv" ? chevronUpSelectedIcon : chevronUpIcon
                }
                alt="Order by licence dates"
              />
            )}
            {searchOptions?.orderBy !== "status_inv" && (
              <img
                src={
                  searchOptions?.orderBy === "status" ? chevronDownSelectedIcon : chevronDownIcon
                }
                alt="Order by licence dates Invert"
              />
            )}
          </div>
        </div>
        <div className="col"></div>
        <div className="col"></div>
      </div>
      <div className="row">
        <ul className="col collaborators-items-list mt-1">
          {isLoading && (
            <div className="position-relative p-5">
              <Spinner type={"big-blue"} />
            </div>
          )}
          {!isLoading &&
            collaborators.obj_list.map((user, index) => (
              <div key={user.name + index.toString()}>
                <li className="row regular-text black-color">
                  <div className="col-4">
                    {user.name || user.email}{" "}
                    {currentUserId === user.id && <img height={12} width={12} src={userIcon} />}
                  </div>
                  <div className="col-2">{t(`roles.${user.role_name}`)}</div>
                  <div className={`col-2 ${getUserStatusStyle(user?.status)}`}>
                    {t(`status.${user?.status}`)}
                  </div>
                  <div className="col-3" disabled={isResendInvitationLoading}>
                    {user.status === "pending" && (
                      <>
                        <span
                          className="link-text-normal mr-1"
                          onClick={() => {
                            onClickResendInvitation({
                              email: user.email,
                              user_type_id: user.role_id,
                              email_body: "",
                              id: user.id,
                            });
                          }}
                        >
                          {t("buttons.resend_invitation")}
                        </span>
                        <span
                          className="link-text-normal dark-grey-color"
                          onClick={() => {
                            onClickCancelInvitation(user);
                          }}
                        >
                          {t("buttons.cancel")}
                        </span>
                      </>
                    )}
                  </div>

                  <div
                    className="col more-image-wrapper col-auto mr-1 pr-0"
                    disabled={user.id === null || user.status !== "active"}
                  >
                    <OptionsButton
                      ref={(el) => (optionsRef.current[index] = el)}
                      isSelected={selectedUser === user.id && user.id !== null}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectedUser === user.id ? setSelectedUser(null) : setSelectedUser(user.id);
                      }}
                    />
                    {selectedUser === user.id && user.id !== null && (
                      <DropdownOptions
                        ref={dropDownRef}
                        buttons={[
                          {
                            text: (
                              <span className="regular-text">
                                {t("my_area.institutions.visit_collaborator")}
                              </span>
                            ),
                            action: () => goToUser(user.id),
                          },
                          {
                            text: <span className="regular-text">{t("buttons.change_role")}</span>,
                            action: () => setChangeRoleIdSelectedUser(user),
                          },
                          {
                            text: (
                              <span className="regular-text warning">
                                {t("buttons.remove_from_institution")}
                              </span>
                            ),
                            action: () => setToRemoveCollab(user),
                          },
                        ]}
                      />
                    )}
                  </div>
                </li>
                {changeRoleIdSelectedUser && changeRoleIdSelectedUser.id === user.id && (
                  <ChangeCollaboratorRole
                    changeCollaboratorRole={(role_id) => changeCollaboratorRole(user.id, role_id)}
                    closeModal={() => setChangeRoleIdSelectedUser(null)}
                    user={user}
                    isChangeRoleLoading={false}
                  />
                )}
              </div>
            ))}
          {collaborators.obj_list.length > 0 && (
            <div className="pagination-wrapper">
              <Paginator
                currPage={collaborators.page}
                totalPages={collaborators.num_pages}
                maxPages={3}
                changePage={updatePage}
              />
            </div>
          )}
        </ul>
      </div>
      {isAddCollaboratorModalOpen && (
        <AddCollaborator
          closeModal={() => setIsAddCollaboratorModalOpen(false)}
          getCollaboratorsList={() => getCollaboratorsList(1)}
        />
      )}
      {toRemoveCollab !== null && (
        <MessageModal
          headerText={`${t("modals.remove_from_institution_header_1")} ${toRemoveCollab.name} ${t(
            "modals.remove_from_institution_header_2"
          )}`}
          body={t("modals.remove_from_institution_body")}
          footerButtons={[
            <button
              onClick={() => setToRemoveCollab(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                readOnly
                type="button"
                value={t("buttons.remove")}
                onClick={() => removeCollab(toRemoveCollab.id)}
                className="warning-button small-wider-button"
              />
            </div>,
          ]}
        />
      )}
      {cancelInvitationConfirm !== null && (
        <MessageModal
          headerText={t("my_area.institutions.cancel_invitation")}
          body={t("my_area.institutions.cancel_invitation_msg")}
          footerButtons={[
            <button
              onClick={() => setCancelInvitationConfirm(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              className="small-button secondary-button"
              onClick={() => {
                confirmCancelInvitation(cancelColabInfo.invite_token);
              }}
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default Collaborators;
