import { CLEAR_REDIRECT, SET_REDIRECT } from "../actions/redirect";

const initialState = { url: "/" };

const redirectTo = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return { ...state, url: action.payload.url };
    case CLEAR_REDIRECT:
      return initialState;
    default:
      return state;
  }
};

export default redirectTo;
