import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { history } from "../utils/history";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import Cookies from "js-cookie";
import reducers from "./reducers";
import { getMessage } from "./actions/messageHandler";
import { USER_LOGIN, isUserAuthenticated } from "./actions/user";

axios.defaults.withCredentials = true;
export const baseURL = process.env.REACT_APP_BACKEND_URL;

const client = axios.create({
  baseURL: baseURL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "Content-Language": "en",
    "X-Frame-Options": "sameorigin",
  },
  timeout: 100000,
  responseType: "json",
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["institutions", "riskAlert", "searchFilter", "redirectTo"],
};

const SESSION_EXPIRED_MIDDLEWARE_EXCEPTIONS = [USER_LOGIN];

// setup axios to automaticaly set the loading state
const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, req) {
          req.headers["X-CSRFToken"] = Cookies.get("csrftokenz");
          return req;
        },
        error: function ({ getState, dispatch, getSourceAction }, error) {
          console.log("error");
        },
      },
    ],
    response: [
      {
        success: async function ({ getState, dispatch, getSourceAction }, res) {
          const response_str = JSON.stringify(res);
          if (res.data && res.data.csrftoken) {
            const { csrftoken } = res.data;
            Cookies.set("csrftokenz", csrftoken);
          }
          if (res.data) {
            store.dispatch(getMessage(res.data.msg));
          }
          if (res.data && res.data.msg === "Password changed") {
            //  The user no longer has an active backend session, but we need to update the local store
            store.dispatch(isUserAuthenticated()).then(() => {
              history.replace("/");
              localStorage.removeItem("persist:root");
            });
          } else if (res.data && res.data.msg === "user_pending_invitation") {
            history.push("/pending_invitation");
          }
          return Promise.resolve(res);
        },
        error: async function ({ getState, dispatch, getSourceAction }, error) {
          // First thing to do is check if the error is a canceled request
          if (error.message && error.message === "canceled") return Promise.reject(error);

          if (error.response && error.response.data) {
            store.dispatch(getMessage(error.response.data.msg));
          }
          if (error.response && error.response.data.csrftoken) {
            const { csrftoken } = error.response.data;
            Cookies.set("csrftokenz", csrftoken);
          }
          if (error.response.status === 404) {
            history.replace("/404");
          }
          if (error.response === undefined) {
            store.dispatch(getMessage("generic_error"));
            /* alert(
              "Connection error. Please check your internet connection and try again"
            ); */
          } else if (error.response.status === 403) {
            if (error.response.data && error.response.data.msg === "mfa_device_missing") {
              history.replace("/setup_multifactor_auth");
            } else if (
              error.response.data &&
              error.response.data.data &&
              error.response.data.msg === "mfa_login_missing"
            ) {
              error.response.data.data.available_methods.includes("sms")
                ? history.replace("/multifactor_auth/sms")
                : history.replace("/multifactor_auth/app");
            } else if (error.response.data && error.response.data.msg === "no_institution") {
              history.replace("/choose_institution");
            } else if (
              error.response.data &&
              error.response.data.msg === "not_the_right_user_type"
            ) {
              history.replace("/my_area/institutions");
            } else if (
              error.response.data &&
              error.response.data.error === "terms_and_conditions"
            ) {
              history.replace("/update_terms_and_conditions");
            } else if (
              error.response.data &&
              error.response.data.error === "user_update_password"
            ) {
              history.replace("/password_expired");
            }
          } else if (
            // 401 errors are session expired ones except for specific actions
            error.response.status === 401 &&
            !SESSION_EXPIRED_MIDDLEWARE_EXCEPTIONS.includes(
              error.response.config.reduxSourceAction.type
            )
          ) {
            store.dispatch(isUserAuthenticated()).then(() => {
              history.replace("/session_expired");
              localStorage.removeItem("persist:root");
            });
          }
          Cookies.set("csrftokenz", error.config.headers["X-CSRFToken"]);
          return Promise.reject(error);
        },
      },
    ],
  },
};

const middleware = applyMiddleware(axiosMiddleware(client, middlewareConfig));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(persistReducer(persistConfig, reducers), composeEnhancers(middleware));
let persistor = persistStore(store);

// eslint-disable-next-line import/no-anonymous-default-export
export { store, persistor };
