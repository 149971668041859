//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect } from "react";
import PrivacyElement from "../../../../components/privacyElement";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import { getPrivacyPolicy } from "redux/actions/user";
import { getLanguageID } from "utils/language";

//* ************************************** GLOBALS ***************************************

const PrivacyPolicy = (props) => {
  //* ************************************** HANDLERS **************************************
  const { t, i18n } = useTranslation();
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrivacyPolicy(getLanguageID(i18n.language)))
      .then((res) => {
        if (res.payload && res.payload.data)
          setPrivacyPolicy(res.payload.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const onGoBack = () => {
    if (props.history.action !== "POP") props.history.goBack();
  };
  //* ************************************** RENDER **************************************
  return (
    <PrivacyElement
      title={t("titles.privacy_policy")}
      onGoBack={onGoBack}
      isPublic={false}
      bodyText={privacyPolicy}
    />
  );
};

export default React.memo(PrivacyPolicy);
