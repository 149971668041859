//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import {
  getPendingInstitutions,
  acceptByCollaborator,
  cancelInvitation,
} from "redux/actions/institutions";
import { clearRedirectTo } from "redux/actions/redirect";
import { userLogout } from "redux/actions/user";

//* ************************************* OUR COMPONENTS *************************************
import Spinner from "components/spinner";
import CompanyInfo from "components/companyInfo";
import { errors } from "constants/general";
import MessageModal from "components/messageModal";

//* ************************************** CUSTOM HOOKS **************************************
import useToast from "hooks/useToast";

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import logo from "assets/images/logo-primary.svg";
import sn from "assets/images/sn.svg";

const AcceptInstituteInvitation = (props) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const redirectTo = useSelector((state) => state.redirectTo.url);

  const [isLoading, setIsLoading] = useState(true);
  const [pendingInvitations, setPendingInvitations] = useState({});
  const [confirmReject, setConfirmReject] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState({});

  const product_version = process.env.REACT_APP_PRODUCT_VERSION;

  const institutions = useSelector(
    (state) => state.institutions.user_institutions
  );

  const userEmail = useSelector((state) => state.user.personal_data.email);

  const showToast = useToast();

  //* **************************************** UTILS *****************************************
  const getPendingInstitutionsRequest = () => {
    setIsLoading(true);
    dispatch(getPendingInstitutions())
      .then((res) => {
        setPendingInvitations(res.payload.data.data.pending_invites);
        if (res.payload.data.data.pending_invites.length === 0) {
          props.history.push("/home");
        }
      })
      .catch(() => {
        alert(errors.service_error);
      })
      .finally(() => setIsLoading(false));
  };

  const rejectInstitutionAction = (invitation_token) => {
    dispatch(cancelInvitation(invitation_token))
      .then(() => {
        getPendingInstitutionsRequest();
      })
      .finally(() => setConfirmReject(false));
  };

  const logout = () => {
    dispatch(userLogout()).finally(() => {
      props.history.push("/login");
    });
  };

  //* ************************************** HANDLERS ****************************************

  const onClickAcceptButton = (institution_info) => {
    dispatch(acceptByCollaborator(institution_info.invite_token)).then(() => {
      dispatch(getPendingInstitutions()).then((res) => {
        setPendingInvitations(res.payload.data.data.pending_invites);
        showToast({
          message: (
            <Trans
              i18nKey={"my_area.institutions.collab_accept_institution_toast"}
            >
              {institution_info.institution_name}
            </Trans>
          ),
          type: "success",
        });
        if (res.payload.data.data.pending_invites.length === 0) {
          props.history.push("/choose_institution");
        }
      });
    });
  };

  const onClickRejectButton = (institiution_info) => {
    setConfirmReject(true);
    setSelectedInstitution(institiution_info);
  };

  const onClickLogoutButton = logout;

  //* ************************************** EFFECTS *****************************************
  useEffect(() => {
    if (redirectTo.includes("/accept_invite/")) {
      dispatch(clearRedirectTo());
    }

    getPendingInstitutionsRequest();
  }, []);
  //* *************************************** RENDER *****************************************

  return (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        <div className="row justify-content-between">
          <div className="col-auto">
            <img className="logo" src={logo} alt="Logo" />
          </div>
          <div className="col-auto">
            <div>
              <img style={{ marginRight: "4px" }} alt="SN" src={sn} />
              <span className="regular-text text--grey-intense">
                {product_version}
              </span>
            </div>
          </div>
        </div>

        <div className="row choose-institution-wrapper">
          {isLoading ? (
            <div className="col-sm-8 col-lg-4 institution-loading-box">
              <Spinner type={"big-blue"} />
            </div>
          ) : (
            <div className="col-sm-8 col-lg-4 choose-institution-box">
              {pendingInvitations.length > 0 ? (
                <div>
                  <div className="big-semibold-base-text">
                    {pendingInvitations.length > 1 ? (
                      t("my_area.institutions.collab_invitation_title_multiple")
                    ) : (
                      <Trans
                        i18nKey={"my_area.institutions.collab_invitation_title"}
                      >
                        {pendingInvitations[0].institution_name}
                      </Trans>
                    )}
                  </div>
                  <div className="regular-text mt-2 mb-3">
                    {t("my_area.institutions.collab_invitation_msg")}
                  </div>
                  {pendingInvitations.map((institution, index) => (
                    <div
                      key={institution.institution_id}
                      className={`institution mb-2`}
                    >
                      <div className="row-space-between">
                        <div>
                          <img
                            src={institution.institution_photo}
                            className="photo"
                            alt="Home Blue"
                          />
                          {institution.institution_name}
                        </div>

                        <div>
                          <span
                            className="link-text-normal mr-2"
                            onClick={() => {
                              onClickAcceptButton(institution);
                            }}
                          >
                            {t("buttons.accept")}
                          </span>
                          <span
                            className="link-text-normal warning-color mr-2"
                            onClick={() => {
                              onClickRejectButton(institution, undefined);
                            }}
                          >
                            {t("buttons.reject")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="regular-text mt-6">
                    {t("institution.create")}
                  </div>
                  <button
                    onClick={() =>
                      props.history.push({
                        pathname: "/create_institution",
                        cameFromApp: true,
                      })
                    }
                    className="mt-2 w-100 small-button background-button"
                  >
                    {t("buttons.create_institution")}
                  </button>
                  <div className="mt-4 text-center regular-text">
                    {t("my_area.institutions.collab_trouble_msg")}
                    <Link
                      to={{
                        pathname: "mailto:support@kinetikoshealth.com",
                      }}
                      target={"_blank"}
                    >
                      <span className="link-text">
                        &nbsp;
                        {t("my_area.institutions.collab_contract_support")}
                      </span>
                    </Link>
                    <div className="mt-4 text-center regular-text">
                      <span className="link-text" onClick={onClickLogoutButton}>
                        <u>{t("buttons.logout")}</u>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no-institutions-found">
                  <h3>
                    {t("my_area.institutions.collab_no_invitation_found")}
                  </h3>
                  <div className="regular-text black-color mt-3 mb-3">
                    {t("my_area.institutions.collab_no_invitation_found_msg")}
                  </div>
                  <div className="line-border" />
                  <div className="big-semibold-base-text mt-3 mb-4">
                    {t("institution.none_create")}
                  </div>
                  <div className="regular-text mb-4">
                    {t("institution.accepted_period")}
                  </div>
                  <button
                    onClick={() =>
                      props.history.push({
                        pathname: "/create_institution",
                        cameFromChooseInstitution: true,
                      })
                    }
                    className="small-button primary-button w-100"
                  >
                    {t("buttons.create_institution")}
                  </button>
                  <div className="mt-4 text-center regular-text">
                    {t("support.contact")}
                    <Link
                      to={{
                        pathname: "mailto:support@kinetikoshealth.com",
                      }}
                      target={"_blank"}
                    >
                      <span className="link-text">
                        &nbsp;{t("support.center")}
                      </span>
                    </Link>
                  </div>
                  <div className="mt-4 text-center regular-text">
                    <span className="link-text" onClick={onClickLogoutButton}>
                      <u>{t("buttons.logout")}</u>
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="row h-100">
          <div className="col kinetikos-bottom-information-wrapper">
            <CompanyInfo />
          </div>
        </div>
      </div>
      {confirmReject && (
        <MessageModal
          headerText={t("my_area.institutions.cancel_invitation")}
          body={t("my_area.institutions.collab_invitation_cancel_msg")}
          footerButtons={[
            <button
              onClick={() => {
                setConfirmReject(null);
              }}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              className="small-button secondary-button"
              onClick={() => {
                rejectInstitutionAction(selectedInstitution.invite_token);
              }}
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default AcceptInstituteInvitation;
