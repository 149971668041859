export const GET_FLASH_MESSAGE = "GET_FLASH_MESSAGE";
export const GET_FLASH_MESSAGE_SUCCESS = "GET_FLASH_MESSAGE_SUCCESS";

export const getFlashMessage = (lang_id = 2) => ({
  type: GET_FLASH_MESSAGE,
  payload: {
    request: {
      method: "get",
      url: `/cdss/homepage/flash_message/`,
      params: { lang_id },
    },
  },
});
