//* *********************************** REACT IMPORTS ************************************
import { memo, useEffect, useState } from "react";

//* *********************************** EXTERNAL IMPORTS *********************************
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../../../components/spinner";
import DropList from "../../../../../components/evaluationModules/dropList";
import TextInput from "../../../../../components/evaluationModules/textInput";
import TextArea from "../../../../../components/evaluationModules/textArea";
import NumberInput from "../../../../../components/evaluationModules/numberInput";
import RadioButtons from "../../../../../components/evaluationModules/radioButtons";
import CheckBox from "../../../../../components/evaluationModules/checkBox";
import SectionTitle from "../../../../../components/evaluationModules/sectionTitle";
import Separator from "../../../../../components/evaluationModules/separator";
import FileUpload from "../../../../../components/evaluationModules/fileUpload";
import DateInput from "../../../../../components/evaluationModules/dateInput";

import helpIcon from "../../../../../assets/images/help.svg";
import scripts from "./scripts";

const EvaluationModuleController = ({
  isLoading = false,
  evaluationModule = {
    allow_edit: null,
    filled: null,
    form: [],
    module_title: "",
    module_id: null,
    evaluation_module_id: null,
    nr_files_allowed: [],
  },
  sendFormAnswers = () => {},
  isSendingForm = false,
  setIsSendingForm = () => {},
  formErrors = {},
  setFormErrors = () => {},
}) => {
  const [formAnswers, setFormAnswers] = useState({});

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      if (Object.keys(scripts).includes(`${evaluationModule.module_id}`)) {
        console.log("HAVE SCRIPT!!");
        scripts[evaluationModule.module_id]();
      } else {
        console.log("DON'T HAVE SCRIPT!!");
      }
    }
  }, [isLoading]);

  const handleSaveButton = () => {
    setIsSendingForm(true);

    const formData = new FormData();
    evaluationModule.form.forEach((field) => {
      if (field.widget_type !== "separator") {
        if (field.widget_type === "file_upload") {
          for (let i = 0; i < formAnswers[field.field_id].length; i++) {
            formData.append(field.field_id, formAnswers[field.field_id][i]);
          }
        } else if (
          field.widget_type === "checkbox" ||
          field.widget_type === "multiple_select_droplist" ||
          field.widget_type === "non_validating_multiple_select"
        ) {
          if (formAnswers[field.field_id] !== undefined) {
            for (let i = 0; i < formAnswers[field.field_id].length; i++) {
              formData.append(field.field_id, formAnswers[field.field_id][i]);
            }
          } else {
            try {
              let initialValues = JSON.parse(
                field.initial.replaceAll("'", '"')
              );
              for (let i = 0; i < initialValues.length; i++) {
                formData.append(field.field_id, initialValues[i]);
              }
            } catch (e) {
              console.log("ERROR PARSING INITIAL VALUES");
            }
          }
        } else {
          formData.append(
            field.field_id,
            formAnswers[field.field_id] ?? field.initial ?? ""
          );
        }
      }
    });
    console.log("formData:", formData);
    sendFormAnswers(formData);
  };

  const checkIfAllAnswered = () => {
    const isValid = evaluationModule.form
      .map((field) => {
        if (!field.required) return true;
        if (field.disabled) return true;
        if (field.widget_type === "file_upload") {
          if (
            formAnswers[field.field_id] !== undefined &&
            JSON.parse(evaluationModule.nr_files_allowed).includes(
              formAnswers[field.field_id].length
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        if (
          formAnswers[field.field_id] !== undefined &&
          formAnswers[field.field_id] !== ""
        )
          return true;
        if (formAnswers[field.field_id] === "") return false;
        if (
          formAnswers[field.field_id] === undefined &&
          field.initial !== null &&
          field.initial !== ""
        )
          return true;
        return false;
      })
      .every((v) => v === true);
    return !isValid;
  };

  const renderForm = () => {
    return evaluationModule.form.map((field, index) => (
      <div
        id={`group-id_${field.field_id}`}
        key={`group-id_${field.field_id}`}
        className={`${index !== 0 ? "mt-4" : ""}`}
      >
        {field.widget_type !== "separator" && field.widget_type !== "section_title" && (
          <div className="regular-text mb-2" style={{ position: "relative", whiteSpace: "pre-wrap" }}>
            {field.label}
            {field.required && (
              <span className="warning-color"> {t("star")}</span>
            )}
            {field.help_text && field.help_text !== "" && (
              <>
                <img
                  alt="Help"
                  data-tip={field.help_text}
                  data-for={`field-${index}`}
                  src={helpIcon}
                  className="help-img"
                />
                <ReactTooltip
                  html={true}
                  className="tooltip"
                  id={`field-${index}`}
                  place="right"
                  effect="solid"
                />
              </>
            )}
          </div>
        )}
        {renderField(field, index)}
        {formErrors[field.field_id] !== undefined && (
          <span className="regular-text warning-color">
            {formErrors[field.field_id]}
          </span>
        )}
      </div>
    ));
  };

  const renderField = (field, index) => {
    switch (field.widget_type) {
      case "file_upload":
        return renderFileUpload(field, index);
      case "section_title":
        return renderSectionTitle(field, index);
      case "separator":
        return renderSeparator(field, index);
      case "drop_list":
        return renderDropList(field, index);
      case "multiple_select_droplist":
      case "non_validating_multiple_select":
        return renderMultipleSelectDropList(field, index);
      case "text_input":
        return renderTextInput(field, index);
      case "text_area":
        return renderTextArea(field, index);
      case "integer_input":
        return renderIntegerInput(field, index);
      case "float_input":
        return renderFloatInput(field, index);
      case "radio_header":
      case "radio_row":
        return renderRadioButtons(field, index);
      case "radio_vertical":
        return renderVerticalRadioButtons(field, index);
      case "checkbox":
        return renderCheckBox(field, index);
      case "date_input":
        return renderDateInput(field, index);
      default:
        return null;
    }
  };

  const renderFileUpload = (field, index) => {
    return (
      <>
        <FileUpload
          id={`id_${field.field_id}`}
          name={field.field_id}
          files={
            formAnswers[field.field_id] === undefined
              ? (typeof field.initial === "string"
                  ? [field.initial]
                  : field.initial) ?? []
              : formAnswers[field.field_id]
          }
          onChangeFiles={(files) => {
            setFormAnswers({
              ...formAnswers,
              [field.field_id]: files,
            });
            setFormErrors({ ...formErrors, [field.field_id]: undefined });
          }}
          filled={
            evaluationModule.filled && formAnswers[field.field_id] === undefined
          }
          nrFiles={JSON.parse(evaluationModule.nr_files_allowed)}
        />
      </>
    );
  };

  const renderSectionTitle = (field, index) => {
    return (
      <SectionTitle
        id={`id_${field.field_id}`}
        name={field.field_id}
        content={field.label}
      />
    );
  };

  const renderSeparator = (field, index) => {
    return (
      <Separator
        id={`id_${field.field_id}`}
        name={field.field_id}
        content={field.label}
      />
    );
  };

  const renderDropList = (field, index) => {
    return (
      <DropList
        id={`id_${field.field_id}`}
        name={field.field_id}
        selected={
          formAnswers[field.field_id] === undefined
            ? field.initial
            : formAnswers[field.field_id]
        }
        options={field.choices}
        selectOption={(choice) => {
          setFormAnswers({ ...formAnswers, [field.field_id]: choice[0] });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
      />
    );
  };

  const renderMultipleSelectDropList = (field, index) => {
    return (
      <DropList
        id={`id_${field.field_id}`}
        name={field.field_id}
        selected={
          formAnswers[field.field_id] === undefined
            ? typeof field.initial === "string"
              ? JSON.parse(field.initial?.replaceAll("'", '"')) ?? []
              : []
            : formAnswers[field.field_id]
        }
        multipleChoice
        options={field.choices}
        selectOption={(choice) => {
          let selec = [];
          if (formAnswers[field.field_id] === undefined) {
            selec = [choice[0]];
          } else {
            selec = [...formAnswers[field.field_id]];
            if (!formAnswers[field.field_id].includes(choice[0])) {
              selec.push(choice[0]);
            }
          }

          setFormAnswers({ ...formAnswers, [field.field_id]: selec });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
        removeOption={(choice) => {
          let selec = [...formAnswers[field.field_id]];
          selec.splice(selec.indexOf(choice[0]), 1);
          setFormAnswers({ ...formAnswers, [field.field_id]: selec });
        }}

        /* selectOption={(index) => {
          if (!selec2.includes(index)) {
            setSelec2([...selec2, index]);
          }
        }}
        removeOption={(index) => {
          let options = [...selec2];
          options.splice(index, 1);
          setSelec2(options);
        }} */
      />
    );
  };

  const renderTextInput = (field, index) => {
    return (
      <>
        <TextInput
          id={`id_${field.field_id}`}
          name={field.field_id}
          value={
            formAnswers[field.field_id] === undefined
              ? field.initial ?? ""
              : formAnswers[field.field_id]
          }
          onChange={(e) => {
            setFormAnswers({
              ...formAnswers,
              [field.field_id]: field.max_length
                ? e.target.value.slice(0, field.max_length - 1)
                : e.target.value,
            });
            setFormErrors({ ...formErrors, [field.field_id]: undefined });
          }}
        />
        {field.max_length && (
          <div className="regular-text">{`[max ${field.max_length} ch.]`}</div>
        )}
      </>
    );
  };

  const renderTextArea = (field, index) => {
    return (
      <>
        <TextArea
          id={`id_${field.field_id}`}
          name={field.field_id}
          value={
            formAnswers[field.field_id] === undefined
              ? field.initial ?? ""
              : formAnswers[field.field_id]
          }
          onChange={(e) => {
            setFormAnswers({
              ...formAnswers,
              [field.field_id]: field.max_length
                ? e.target.value.slice(0, field.max_length - 1)
                : e.target.value,
            });
            setFormErrors({ ...formErrors, [field.field_id]: undefined });
          }}
        />
        {field.max_length && (
          <div className="regular-text">{`[max ${field.max_length} ch.]`}</div>
        )}
      </>
    );
  };

  const renderIntegerInput = (field, index) => {
    return (
      <>
        <NumberInput
          id={`id_${field.field_id}`}
          name={field.field_id}
          value={
            formAnswers[field.field_id] === undefined
              ? field.initial ?? ""
              : formAnswers[field.field_id]
          }
          onChange={(e) => {
            setFormAnswers({
              ...formAnswers,
              [field.field_id]: e.target.value,
            });
            setFormErrors({ ...formErrors, [field.field_id]: undefined });
          }}
          type="int"
          min={field.min_value}
          max={field.max_value}
        />
        {(field.min_value || field.max_value) && (
          <div>
            {(field.min_value || field.min_value === 0) && (
              <span className="regular-text">{`[min = ${field.min_value}] `}</span>
            )}
            {(field.max_value || field.max_value === 0) && (
              <span className="regular-text">{`[max = ${field.max_value}]`}</span>
            )}
          </div>
        )}
      </>
    );
  };

  const renderFloatInput = (field, index) => {
    return (
      <>
        <NumberInput
          id={`id_${field.field_id}`}
          name={field.field_id}
          value={
            formAnswers[field.field_id] === undefined
              ? field.initial ?? ""
              : formAnswers[field.field_id]
          }
          onChange={(e) => {
            setFormAnswers({
              ...formAnswers,
              [field.field_id]: e.target.value,
            });
            setFormErrors({ ...formErrors, [field.field_id]: undefined });
          }}
          type="float"
          min={field.min_value}
          max={field.max_value}
        />
        {(field.min_value || field.max_value) && (
          <div>
            {(field.min_value || field.min_value === 0) && (
              <span className="regular-text">{`[min = ${field.min_value}] `}</span>
            )}
            {(field.max_value || field.max_value === 0) && (
              <span className="regular-text">{`[max = ${field.max_value}]`}</span>
            )}
          </div>
        )}
      </>
    );
  };

  const renderRadioButtons = (field, index) => {
    return (
      <RadioButtons
        id={`id_${field.field_id}`}
        name={field.field_id}
        checked={
          formAnswers[field.field_id] === undefined
            ? field.initial
            : formAnswers[field.field_id]
        }
        options={field.choices}
        onOptionClick={(choice) => {
          setFormAnswers({ ...formAnswers, [field.field_id]: choice[0] });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
      />
    );
  };

  const renderVerticalRadioButtons = (field, index) => {
    return (
      <RadioButtons
        id={`id_${field.field_id}`}
        name={field.field_id}
        vertical
        checked={
          formAnswers[field.field_id] === undefined
            ? field.initial
            : formAnswers[field.field_id]
        }
        options={field.choices}
        onOptionClick={(choice) => {
          setFormAnswers({ ...formAnswers, [field.field_id]: choice[0] });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
      />
    );
  };

  const renderCheckBox = (field, index) => {
    return (
      <CheckBox
        id={`id_${field.field_id}`}
        name={field.field_id}
        /* vertical */
        checked={
          formAnswers[field.field_id] === undefined
            ? typeof field.initial === "string"
              ? JSON.parse(field.initial?.replaceAll("'", '"')) ?? []
              : []
            : formAnswers[field.field_id]
        }
        options={field.choices}
        onOptionClick={(choice) => {
          let checked = [];
          if (formAnswers[field.field_id] === undefined) {
            checked = [choice[0]];
          } else {
            const i = formAnswers[field.field_id].indexOf(choice[0]);

            if (i < 0) {
              checked = [...formAnswers[field.field_id], choice[0]];
            } else {
              checked = [...formAnswers[field.field_id]];
              checked.splice(i, 1);
            }
          }
          setFormAnswers({ ...formAnswers, [field.field_id]: checked });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
      />
    );
  };

  const renderDateInput = (field, index) => {
    const date =
      formAnswers[field.field_id] === undefined
        ? field.initial ?? "--"
        : formAnswers[field.field_id];

    const dateArray = date.split("-");

    const day = dateArray[2],
      month = dateArray[1],
      year = dateArray[0];

    return (
      <DateInput
        id={`id_${field.field_id}`}
        name={field.field_id}
        withDay={true}
        valueDay={day}
        valueMonth={month}
        valueYear={year}
        onChangeDay={(newDay) => {
          setFormAnswers({
            ...formAnswers,
            [field.field_id]: `${year}-${month}-${newDay}`,
          });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
        onChangeMonth={(newMonth) => {
          setFormAnswers({
            ...formAnswers,
            [field.field_id]: `${year}-${newMonth}-${day}`,
          });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
        onChangeYear={(newYear) => {
          setFormAnswers({
            ...formAnswers,
            [field.field_id]: `${newYear}-${month}-${day}`,
          });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
        onChangeDate={(newDate) => {
          setFormAnswers({
            ...formAnswers,
            [field.field_id]: newDate,
          });
          setFormErrors({ ...formErrors, [field.field_id]: undefined });
        }}
      />
    );
  };

  return (
    <div>
      <div
        // className="mt-6"
        style={{
          backgroundColor: "#FDFDFE",
          boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.12)",
          borderRadius: "5px",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          position: "relative",
        }}
      >
        {isLoading ? (
          <Spinner type={"big-blue"} />
        ) : (
          <>
            <div className="row align-items-center mb-6">
              <div className="col">
                <div className="small-title">
                  {evaluationModule.module_title}
                </div>
              </div>
              <div className="col-auto">
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="transparent-button small-button"
                >
                  <u> {t("buttons.cancel")} </u>
                </button>
                <button
                  disabled={checkIfAllAnswered() || isSendingForm}
                  onClick={handleSaveButton}
                  className="secondary-button small-button ml-1"
                  style={{ position: "relative" }}
                >
                  <span style={{ opacity: isSendingForm ? 0 : 1 }}>
                    {t("buttons.save")}
                  </span>
                  {isSendingForm && <Spinner type={"small"} />}
                </button>
              </div>
            </div>
            {renderForm()}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(EvaluationModuleController);
