//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************

/**
 *
 * After creating the div, it will return true.
 * search: Portal
 */
export const Portal = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const div = document.createElement("div");
    div.id = "toast";
    div.className = "toast-wrapper";
    if (!document.getElementById("toast")) {
      document.getElementsByTagName("body")[0].prepend(div);
    }

    setIsLoaded(true);
  }, []);

  return isLoaded;
};
