//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";
import { usePDF, PDFViewer } from "@react-pdf/renderer";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

/**
 * Use this hook to generate pdf from react-pdf template
 * @param {pdfTemplate}: the pdfTemplate must be a react-pdf document
 *                       for more info: https://react-pdf.org/
 *
 *
 *
 */
export const usePdf = (pdfTemplate) => {
  const [instance, pdfUpdateInstance] = usePDF({ document: pdfTemplate });
  const pdfDownloadUrl = instance.url;
  const pdfBlob = instance.blob;
  const isPdfLoading = instance.loading;
  const pdfError = instance.error;

  const openPdfInBrowser = (target = "_blank") => {
    window.open(instance.url, target);
  };

  const printPdf = () => {
    window.open(instance.url).print();
    return false;
  };

  return {
    isPdfLoading,
    pdfError,
    pdfDownloadUrl,
    pdfBlob,
    pdfUpdateInstance,
    openPdfInBrowser,
    printPdf,
  };
};
