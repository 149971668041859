/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useState, useEffect, memo, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import DropdownOptions from "../../../../components/dropdownOptions";
import Paginator from "../../../../components/paginator";
import Spinner from "../../../../components/spinner";
import OptionsButton from "../../../../components/optionsButton";
import MedicinesModulesList from "./medicinesModuleList";
import MessageModal from "../../../../components/messageModal";

//* *********************************** HOOKS ***********************************
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************

//* ************************************** ASSETS ****************************************
import chevronUpIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronUpSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import prescriptionIcon from "../../../../assets/images/prescription.svg";

import search from "../../../../assets/images/search.svg";
import {
  getPrescriptionsList,
  deletePrescription,
} from "../../../../redux/actions/patientPrescriptions";
import { errors } from "../../../../constants/general";

const PrescriptionsModule = ({
  history,
  itemsPerPage,
  goToCreate,
  goToEdit,
  goToShow,
  goToShowMedicine,
  goToEditMedicine,
}) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const [isDeletePrescriptionModalOpenId, setIsDeletePrescriptionModalOpenId] =
    useState(null);

  const [isPrescriptionListLoading, setIsPrescriptionListLoading] =
    useState(false);

  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);

  const [showPrescriptionId, setShowPrescriptionsId] = useState(null);

  const { t, i18n } = useTranslation();

  const patientPrescriptions = useSelector(
    (state) => state.patientsPrescriptions.prescription_list
  );

  const currentInstitution = useSelector(
    (state) => state.institutions.current_institution
  );

  const sortOrders = {
    name: "name",
    name_inv: "-name",
    inst: "connection",
    inst_inv: "-connection",
    date: "creation_date",
    date_inv: "-creation_date",
  };

  const getPrescriptions = () => {
    setIsPrescriptionListLoading(true);
    dispatch(
      getPrescriptionsList(patient_id, {
        page: searchOptions.page,
        q: searchOptions.q,
        items_per_page: searchOptions.itemsPerPage,
        order_by: searchOptions.orderBy,
      })
    ).finally(() => setIsPrescriptionListLoading(false));
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } =
    useSearch(getPrescriptions, 300);

  //* ************************************** HANDLERS **************************************
  const deleteSelectedPrescription = (prescription_id) => {
    setIsPrescriptionListLoading(true);

    dispatch(deletePrescription(patient_id, prescription_id))
      .then(() => getPrescriptions())
      .catch(() => {})
      .finally(() => {
        setIsDeletePrescriptionModalOpenId(null);
        setIsPrescriptionListLoading(false);
      });
  };

  const showHidePrescription = (prescription_id) => {
    if (showPrescriptionId === prescription_id) {
      setShowPrescriptionsId(null);
    } else {
      setShowPrescriptionsId(prescription_id);
    }
  };

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setSelectedPrescriptionId(null)
  );

  const _patientListItem = (prescription, index) => {
    const isPrescriptionShowing =
      prescription.prescription_id === showPrescriptionId;
    return (
      <div
        style={{ flexWrao: "wrap" }}
        key={prescription.id + index.toString()}
      >
        <div
          className={`prescription ${
            isPrescriptionShowing ? "viewing" : ""
          }  row align-items-center`}
        >
          <div
            className={`col regular-text black-color text-truncate ${
              !prescription.active && "inactive"
            }`}
          >
            {prescription.prescription_name
              ? prescription.prescription_name
              : prescription.prescription_inst
              ? t("patient.meds.unnamed_prescription")
              : t("patient.meds.patient_prescription")}
          </div>
          <div className="col-5 regular-text black-color flex-d-row align-items-center justify-content-between">
            {prescription.prescription_inst
              ? prescription.prescription_inst
              : t("patient.meds.patient")}
            <div className="flex-d-row align-items-center">
              <button
                onClick={() =>
                  showHidePrescription(prescription.prescription_id)
                }
                className={`show-hide-prescription ${
                  isPrescriptionShowing ? "viewing" : ""
                }  mr-2`}
              >
                {isPrescriptionShowing
                  ? t("patient.meds.hide_prescription")
                  : t("patient.meds.show_prescription")}
              </button>
              <div className="more-image-wrapper">
                <OptionsButton
                  ref={(el) => (optionsRef.current[index] = el)}
                  type={isPrescriptionShowing ? 2 : 1}
                  isSelected={
                    selectedPrescriptionId === prescription.prescription_id
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedPrescriptionId === prescription.prescription_id
                      ? setSelectedPrescriptionId(null)
                      : setSelectedPrescriptionId(prescription.prescription_id);
                  }}
                />
                {selectedPrescriptionId === prescription.prescription_id && (
                  <DropdownOptions
                    ref={dropDownRef}
                    buttons={
                      currentInstitution.id ===
                      prescription.prescription_inst_id
                        ? [
                            {
                              text: (
                                <span className="regular-text">
                                  {t("buttons.edit")}
                                </span>
                              ),
                              action: () =>
                                goToEdit(prescription.prescription_id),
                            },
                            {
                              text: (
                                <span className="regular-text">
                                  {t("buttons.view_details")}
                                </span>
                              ),
                              action: () =>
                                goToShow(prescription.prescription_id),
                            },
                            prescription.active && {
                              text: (
                                <span className="regular-text warning">
                                  {t("buttons.delete")}
                                </span>
                              ),
                              action: () =>
                                setIsDeletePrescriptionModalOpenId(
                                  prescription.prescription_id
                                ),
                            },
                          ]
                        : [
                            {
                              text: (
                                <span className="regular-text">
                                  {t("buttons.view_details")}
                                </span>
                              ),
                              action: () =>
                                goToShow(prescription.prescription_id),
                            },
                          ]
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isPrescriptionShowing && (
          <MedicinesModulesList
            goToEditMedicine={goToEditMedicine}
            goToShowMedicine={goToShowMedicine}
            currentInstId={currentInstitution.id}
            medicines={prescription.prescription_medicines}
            onDelete={getPrescriptions}
            disabled={!prescription.active}
          />
        )}
      </div>
    );
  };
  return (
    <>
      <div className="prescriptions-module-wrapper">
        <div className="mkinetikos-prescriptions-modules-box">
          <div className="row align-items-center">
            <div className="col mt-1">
              <div className="small-title">
                {t("patient.meds.prescriptions")}
              </div>
            </div>
            <div className="col-auto mt-1">
              <button
                onClick={goToCreate}
                className="button primary-button small-button"
              >
                <img
                  alt="Person exercise"
                  className="button-icon"
                  src={prescriptionIcon}
                />
                {t("buttons.add_prescription")}
              </button>
            </div>
          </div>
          <div className="row search-filter-wrapper">
            <div className="col">
              <div style={{ position: "relative" }}>
                <input
                  value={searchOptions.q}
                  onChange={(e) => updateSearchText(e.target.value)}
                  type="text"
                  className="search-input w-100"
                  placeholder="Search for a prescription"
                />
                <img alt="search-icon" className="search-icon" src={search} />
              </div>
            </div>
          </div>

          <div className="row list-label mt-4 mb-1">
            <div className="col-7 d-flex align-item-center">
              <div>{t("patient.meds.prescription")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === sortOrders.name ||
                  searchOptions.orderBy === sortOrders.name_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (searchOptions.orderBy === sortOrders.name) {
                    updateOrderBy(sortOrders.name_inv);
                  } else if (searchOptions.orderBy === sortOrders.name_inv) {
                    updateOrderBy(sortOrders.date_inv);
                  } else {
                    updateOrderBy(sortOrders.name);
                  }
                }}
              >
                {searchOptions.orderBy !== sortOrders.name_inv && (
                  <img
                    src={
                      searchOptions.orderBy === sortOrders.name
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by Name"
                  />
                )}
                {searchOptions.orderBy !== sortOrders.name && (
                  <img
                    src={
                      searchOptions.orderBy === sortOrders.name_inv
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
              </div>
            </div>
            <div className="col-5 d-flex align-item-center">
              <div>{t("patient.meds.prescribed_by")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === sortOrders.inst ||
                  searchOptions.orderBy === sortOrders.inst_inv
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  if (searchOptions.orderBy === sortOrders.inst) {
                    updateOrderBy(sortOrders.inst_inv);
                  } else if (searchOptions.orderBy === sortOrders.inst_inv) {
                    updateOrderBy(sortOrders.date_inv);
                  } else {
                    updateOrderBy(sortOrders.inst);
                  }
                }}
              >
                {searchOptions.orderBy !== sortOrders.inst_inv && (
                  <img
                    src={
                      searchOptions.orderBy === sortOrders.inst
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order from New to Old"
                  />
                )}
                {searchOptions.orderBy !== sortOrders.inst && (
                  <img
                    src={
                      searchOptions.orderBy === sortOrders.inst_inv
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order from Old to New"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="list-content">
            {isPrescriptionListLoading ? (
              <div className="loading-wrapper">
                <Spinner type="big-blue" />
              </div>
            ) : patientPrescriptions.obj_list.length === 0 ? (
              <div className="medium-base-color-text message-wrapper">
                <p>{t("patient.meds.no_prescriptions_match")}</p>
              </div>
            ) : (
              patientPrescriptions.obj_list.map((prescription, index) =>
                _patientListItem(prescription, index)
              )
            )}
          </div>
        </div>
        <Paginator
          currPage={patientPrescriptions.page}
          totalPages={patientPrescriptions.num_pages}
          maxPages={3}
          changePage={updatePage}
        />
        {isDeletePrescriptionModalOpenId && (
          <MessageModal
            headerText={t("modals.delete_prescription_header")}
            body={t("modals.delete_prescription_body")}
            footerButtons={[
              <button
                disabled={isPrescriptionListLoading}
                onClick={() => setIsDeletePrescriptionModalOpenId(null)}
                className="small-button transparent-button"
              >
                <u>{t("buttons.cancel")}</u>
              </button>,
              <div className="input-image-wrapper m-0">
                <input
                  disabled={isPrescriptionListLoading}
                  type="button"
                  value={t("buttons.delete")}
                  onClick={() =>
                    deleteSelectedPrescription(isDeletePrescriptionModalOpenId)
                  }
                  className="warning-button small-wider-button"
                />
              </div>,
            ]}
          />
        )}
      </div>
    </>
  );
};

export default memo(PrescriptionsModule);
