import React, { useRef, useState } from "react";
import CalendarIcon from "../../../assets/images/evalModules/calendar_base.svg";
import PrevIcon from "../../../assets/images/evalModules/chevron-left_base.svg";
import NextIcon from "../../../assets/images/evalModules/chevron-right_base.svg";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";

const DateInput = ({
  valueDay = "",
  valueMonth = "",
  valueYear = "",
  onChangeDay = () => {},
  onChangeMonth = () => {},
  onChangeYear = () => {},
  onChangeDate = () => {},
  id,
  withDay = true,
}) => {
  const { t } = useTranslation();

  const dateInputContainerRef = useRef(null);
  const yearSelectorRef = useRef(null);
  const monthSelectorRef = useRef(null);
  const [dateInputContainerFocus, setDateInputContainerFocus] = useState(false);
  const [error, setError] = useState(false);

  const getSelectedDate = () => {
    if (valueDay === "" || valueMonth === "" || valueYear === "") return null;

    const date = new Date(valueYear, valueMonth - 1, valueDay);
    return date;
  };

  const validateInput = () => {
    if (withDay === true) {
      if (valueDay === "" && valueMonth === "" && valueYear === "") return true;
      if (valueDay === "" || valueMonth === "" || valueYear === "") {
        setError(true);
        return false;
      }

      const date = new Date(valueYear, valueMonth - 1, valueDay);

      if (parseInt(valueMonth) === 0 || parseInt(valueDay) === 0) {
        setError(true);
        return false;
      }

      onChangeDay(
        `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
      );
      onChangeMonth(
        `${
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }`
      );
      onChangeYear(`${date.getFullYear()}`);

      setError(false);
      return true;
    } else {
      if (valueMonth === "" && valueYear === "") return true;
      if (valueMonth === "" || valueYear === "") {
        setError(true);
        return false;
      }

      if (parseInt(valueMonth) === 0) {
        setError(true);
        return false;
      }

      onChangeMonth(
        `${
          parseInt(valueMonth) < 10
            ? "0" + parseInt(valueMonth)
            : parseInt(valueMonth)
        }`
      );
      onChangeYear(`${valueYear}`);

      setError(false);
      return true;
    }
  };

  const yearsList = () => {
    const currYear = new Date().getFullYear();
    let array = [];
    for (let i = 0; i < 200; i++) {
      array.push(currYear - 100 + i);
    }

    return array;
  };

  return (
    <div
      id={id}
      ref={dateInputContainerRef}
      onFocus={() => {
        setDateInputContainerFocus(true);
        setTimeout(() => {
          if (yearSelectorRef.current !== null) {
            if (valueYear === "") {
              document
                .getElementById(`year-${new Date().getFullYear()}`)
                .scrollIntoView({ block: "center" });
            } else {
              document
                .getElementById(`year-${valueYear}`)
                .scrollIntoView({ block: "center" });
            }
          }
          if (monthSelectorRef.current !== null) {
            if (valueMonth === "") {
              document
                .getElementById(`month-${new Date().getMonth()}`)
                .scrollIntoView({ block: "center" });
            } else {
              document
                .getElementById(`month-${parseInt(valueMonth) - 1}`)
                .scrollIntoView({ block: "center" });
            }
          }
        }, 1);
      }}
      onBlur={() => {
        setDateInputContainerFocus(false);
        validateInput();
      }}
      tabIndex={0}
      className={`ev-mod-date-input-container ${error ? "error" : ""}`}
    >
      <input
        autoComplete="off"
        value={valueYear}
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (/^\d*$/.test(e.target.value[e.target.value.length - 1]) &&
              parseInt(e.target.value) > 0 &&
              e.target.value.length < 5)
          ) {
            onChangeYear(e.target.value);
            setError(false);
          }
        }}
        className="dark-grey-color year"
        placeholder="----"
      />
      <span className="dark-grey-color">/&#160;</span>
      <input
        autoComplete="off"
        value={valueMonth}
        onChange={(e) => {
          if (
            e.target.value === "" ||
            (/^\d*$/.test(e.target.value[e.target.value.length - 1]) &&
              parseInt(e.target.value) > 0 &&
              parseInt(e.target.value) < 13 &&
              e.target.value.length < 3) ||
            (parseInt(e.target.value) === 0 && e.target.value.length === 1)
          ) {
            onChangeMonth(e.target.value);
            setError(false);
          }
        }}
        className="dark-grey-color"
        placeholder="--"
      />
      {withDay && (
        <>
          <span className="dark-grey-color">/&#160;</span>
          <input
            autoComplete="off"
            value={valueDay}
            onChange={(e) => {
              if (
                e.target.value === "" ||
                (/^\d*$/.test(e.target.value[e.target.value.length - 1]) &&
                  parseInt(e.target.value) > 0 &&
                  parseInt(e.target.value) < 32 &&
                  e.target.value.length < 3) ||
                (parseInt(e.target.value) === 0 && e.target.value.length === 1)
              ) {
                onChangeDay(e.target.value);
                setError(false);
              }
            }}
            className="dark-grey-color"
            placeholder="--"
          />
        </>
      )}
      <span style={{ marginLeft: "8px", flex: 1 }} />
      <img alt="Clock" src={CalendarIcon} />
      {dateInputContainerRef.current !== null &&
        dateInputContainerRef.current.matches(":focus-within") &&
        (withDay ? (
          <div className="date-input-select">
            <Calendar
              onChange={(date) => {
                const newDate = `${date.getFullYear()}-${
                  date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
                }-${
                  date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                }`;

                onChangeDate(newDate);
                setError(false);
              }}
              calendarType="US"
              value={getSelectedDate()}
              prevLabel={<img alt="Previous" src={PrevIcon} />}
              prev2Label={null}
              nextLabel={<img alt="Next" src={NextIcon} />}
              next2Label={null}
              formatShortWeekday={(_, date) => {
                return t("time.weekdays", { returnObjects: true })[
                  date.getDay()
                ].slice(0, 2);
              }}
              formatMonth={(_, date) => {
                return t("time.months", { returnObjects: true })[
                  date.getMonth()
                ];
              }}
              formatMonthYear={(_, date) => {
                return (
                  t("time.months", { returnObjects: true })[date.getMonth()] +
                  " " +
                  date.getFullYear()
                );
              }}
            />
          </div>
        ) : (
          <div className="month-year-input-select">
            <div className="month-section" ref={monthSelectorRef}>
              {t("time.months", { returnObjects: true }).map(
                (monthName, month) => (
                  <div
                    key={month}
                    id={`month-${month}`}
                    className={`item-card ${
                      parseInt(valueMonth) - 1 === month ? "selected" : ""
                    }`}
                    onClick={() => {
                      onChangeMonth(
                        `${month + 1 < 10 ? "0" + (month + 1) : month + 1}`
                      );
                      setError(false);
                    }}
                  >
                    {monthName}
                  </div>
                )
              )}
            </div>
            <div className="year-section" ref={yearSelectorRef}>
              {yearsList().map((year) => (
                <div
                  key={year}
                  id={`year-${year}`}
                  className={`item-card ${
                    parseInt(valueYear) === year ? "selected" : ""
                  }`}
                  onClick={() => {
                    onChangeYear(year);
                    setError(false);
                  }}
                >
                  {year}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default React.memo(DateInput);
