//* ********************************** REACT IMPORTS **********************************
import { useEffect, useState } from "react";
import RadioButtons from "../../../components/radioButtons";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import QRCode from "react-qr-code";
import { isInteger } from "mathjs";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import BackupCodePdfTemplate from "pdfTemplates/backupCode/backupCodePdfTemplate";

//* ************************************** GLOBALS ***************************************
import {
  getAuthenticatorUrl,
  setSmsDevice,
  confirmSmsDevice,
  multiAuthLogin,
  getBackupTokens,
} from "../../../redux/actions/multiFactorAuth";
import { getCurrentInstitution } from "../../../redux/actions/institutions";
import { clearRedirectTo } from "../../../redux/actions/redirect";
import { userLogout } from "../../../redux/actions/user";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import MessageBox from "components/messageBox";
import { dateToString } from "utils/dataAndTime";
import { usePdf } from "hooks/usePdf";

//* *************************************** STYLES ***************************************
import { ReactComponent as InternationalIcon } from "../../../assets/images/flag-international.svg";

const SetupMultiFactorAuth = ({ navigateToHome }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const pdfFileName = `backup_code_${dateToString(new Date())}.pdf`;

  const radioButtonOptions = [
    {
      key: "generator",
      text: t("authentication.mfa.app_authenticator"),
      tip: t("authentication.mfa.app_authenticator_tip"),
    },
    { key: "sms", text: t("authentication.mfa.phone_number") },
  ];

  const [currStep, setCurrStep] = useState(0);
  const [checkedRadioButtonIndex, setCheckedRadioButtonIndex] = useState(0);
  const [phoneNr, setPhoneNr] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [wasCodeSent, setWasCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [appCode, setAppCode] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [zoomQr, setZoomQr] = useState(false);
  const [backupTokens, setBackupTokens] = useState([]);
  const [showError, setShowError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [backupCodeGeneratedDateTime, setBackupCodeGeneratedDateTime] =
    useState("");
  const history = useHistory();

  const {
    isPdfLoading,
    pdfError,
    pdfDownloadUrl,
    pdfBlob,
    pdfUpdateInstance,
    openPdfInBrowser,
    printPdf,
  } = usePdf(
    <BackupCodePdfTemplate
      backupTokens={backupTokens}
      backupCodeGeneratedDateTime={backupCodeGeneratedDateTime}
    />
  );

  useEffect(() => {
    pdfUpdateInstance();
  }, [backupTokens, backupCodeGeneratedDateTime]);

  useEffect(() => {
    setShowError(false);
    if (radioButtonOptions[checkedRadioButtonIndex].key === "generator") {
      dispatch(getAuthenticatorUrl()).then(
        (res) => {
          if (res.payload.data && res.payload.data.data) {
            setQrUrl(res.payload.data.data);
          }
        },
        (err) => {
          setQrUrl("error");
          console.log("getAuthenticatorUrl Error:", err);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRadioButtonIndex]);

  //* ************************************** HANDLERS **************************************

  const handleSendCodeBySms = () => {
    if (!isSendingCode && isPossiblePhoneNumber(phoneNr)) {
      setIsSendingCode(true);
      dispatch(setSmsDevice({ number: phoneNr })).then(
        (res) => {
          console.log("setSmsDevice:", res);
          setShowError(false);
        },
        (err) => {
          console.log("setSmsDevice Error:", err);
          setShowError(true);
        }
      );
      setTimeout(() => {
        setIsSendingCode(false);
        setWasCodeSent(true);
      }, 1500);
    }
  };

  const handleConfirmSmsCode = () => {
    if (smsCode.length === 6) {
      setValidationError(false);
      dispatch(confirmSmsDevice(smsCode, { number: phoneNr })).then(
        (res) => {
          console.log("setSmsDevice:", res);
          setShowError(false);
          setCurrStep(2);
          createBackupTokens();
        },
        (err) => {
          console.log("setSmsDevice Error:", err);
          if (err.error.response.data?.data?.form_errors?.token?.length > 0)
            setShowError(true);
        }
      );
    } else {
      setValidationError(true);
    }
  };

  const handleConfirmAppCode = () => {
    if (appCode.length === 6) {
      setValidationError(false);
      dispatch(multiAuthLogin(appCode)).then(
        (res) => {
          setShowError(false);
          setCurrStep(2);
          createBackupTokens();
        },
        (err) => {
          setShowError(true);
        }
      );
    } else {
      setValidationError(true);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout()).finally(() => {
      window.location.reload(true);
      history.push("/login");
    });
  };

  const createBackupTokens = () => {
    dispatch(getBackupTokens()).then(
      (res) => {
        if (res.payload.data && res.payload.data.data) {
          setBackupTokens(res.payload.data.data);
          setBackupCodeGeneratedDateTime(dateToString(new Date()));
        }
      },
      (err) => {
        console.log("getBackupTokens Error:", err);
        setBackupCodeGeneratedDateTime("");
      }
    );
  };

  const countryCodehandler = () => {
    const { value: country } = document.querySelector(
      ".PhoneInputCountry SELECT"
    );
    const newCountryCode =
      country === "ZZ" ? "" : `+${getCountryCallingCode(country)}`;
    setCountryCode(newCountryCode);
  };

  const renderSmsOption = () => {
    return (
      <>
        <div className="regular-text mt-3 mb-4">
          {t("authentication.mfa.phone_required")}
        </div>

        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
          className="mb-1"
        >
          <PhoneInput
            /* PhoneInput changed in css, see _input.scss file */
            placeholder={t("placeholders.phone")}
            // value={phoneNr ? phoneNr : "+"}
            value={phoneNr}
            onChange={(phoneNr) => {
              countryCodehandler();
              if (phoneNr) {
                setPhoneNr(phoneNr);
              }
            }}
            internationalIcon={InternationalIcon}
          />
          <div className="countryCode">
            <p>{countryCode}</p>
          </div>
          <div
            className={`regular-text send-code-btn ${
              wasCodeSent ? "secondary-color" : "primary-color"
            } ${isPossiblePhoneNumber(phoneNr) ? "" : "disabled"}`}
            onClick={() => {
              if (!wasCodeSent) {
                handleSendCodeBySms();
              }
            }}
          >
            {isSendingCode ? (
              <Spinner type="small-blue" />
            ) : wasCodeSent ? (
              t("authentication.mfa.sent")
            ) : (
              t("authentication.mfa.send_code")
            )}
          </div>
        </div>

        <div
          className="d-flex"
          style={{
            opacity: wasCodeSent ? 1 : 0,
          }}
        >
          <input
            value={smsCode}
            autoComplete="off"
            className="form-input mb-1"
            name="smsCode"
            id="smsCode"
            placeholder="CODE"
            onChange={(e) => {
              if (isInteger(e.target.value)) setSmsCode(e.target.value);
            }}
            style={{
              cursor: wasCodeSent ? "auto" : "default",
            }}
            maxLength={6}
          />

          <button
            className="primary-button small-button mb-1 ml-2"
            disabled={smsCode === ""}
            onClick={() => {
              handleConfirmSmsCode();
            }}
          >
            {t("buttons.confirm")}
          </button>
        </div>
        {showError && (
          <MessageBox
            type={"warning"}
            message={t("authentication.mfa.invalid_code")}
          />
        )}
        {validationError && (
          <MessageBox type={"warning"} message={t("form_errors.six_digits")} />
        )}

        <div className="regular-text">
          {t("authentication.mfa.no_message_received")}{" "}
          <span
            className="link-text"
            onClick={() => {
              if (wasCodeSent) {
                handleSendCodeBySms();
              }
            }}
          >
            {t("authentication.mfa.send_again")}
          </span>
          .
        </div>

        <div className="form-auth-bottom text-center">
          <div className="mfa-buttons-wrapper">
            <button
              className="small-button transparent-button mt-1"
              onClick={handleLogout}
            >
              <span className="link-text">{t("buttons.logout")}</span>
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderAppOption = () => {
    return (
      <>
        <div className="regular-text mt-3 mb-2">
          {t("authentication.mfa.auth_app_required")}
        </div>
        <div className="regular-text mb-4">
          {t("authentication.mfa.recommended_apps")}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            target={"_blank"}
            className="link-text-normal"
          >
            <u>Google authenticator</u>
          </a>{" "}
          (Android),{" "}
          <a
            href="https://2fas.com/"
            target={"_blank"}
            className="link-text-normal"
          >
            <u>2FAS Backup</u>
          </a>
          (iOS)
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: "#FDFDFE",
            boxShadow: "0px 2px 12px rgba(37, 89, 134, 0.43)",
            borderRadius: "5px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            cursor: "zoom-in",
          }}
          onClick={() => {
            setZoomQr(true);
          }}
        >
          {qrUrl !== "" ? (
            <QRCode value={qrUrl} size={96} />
          ) : (
            <Spinner type="small-blue" />
          )}
        </div>

        <div
          className="regular-text primary-color mt-1"
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            setZoomQr(true);
          }}
        >
          {t("authentication.mfa.scan_qr")}
        </div>

        <div className="d-flex mt-4">
          <input
            value={appCode}
            autoComplete="off"
            className="form-input mb-1"
            name="appCode"
            id="appCode"
            placeholder={t("placeholders.code").toUpperCase()}
            onChange={(e) => {
              if (isInteger(e.target.value)) setAppCode(e.target.value);
            }}
            maxLength={6}
          />

          <button
            className="primary-button small-button mb-1 ml-2"
            disabled={appCode === ""}
            onClick={() => {
              handleConfirmAppCode();
            }}
          >
            {t("buttons.confirm")}
          </button>
        </div>
        {showError && (
          <MessageBox
            type={"warning"}
            message={t("authentication.mfa.invalid_code")}
          />
        )}
        {validationError && (
          <MessageBox type={"warning"} message={t("form_errors.six_digits")} />
        )}

        <div className="form-auth-bottom text-center">
          <div className="mfa-buttons-wrapper">
            <button
              className="small-button transparent-button mt-1"
              onClick={handleLogout}
            >
              <span className="link-text">{t("buttons.logout")}</span>
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderQRModal = () => {
    return (
      <div className="qrcode-modal-wrapper">
        <div
          className="qrcode-modal-container"
          onClick={() => {
            setZoomQr(false);
          }}
        >
          <QRCode value={qrUrl} size={256} />
        </div>
      </div>
    );
  };

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      {currStep === 0 ? (
        <>
          <h2 className="mb-4">{t("authentication.mfa.authentication")}</h2>
          <div className="regular-text">
            <span className="smaller-title dark-grey-color">
              {t("authentication.mfa.two_factor")}
            </span>{" "}
            {t("authentication.mfa.extra_security")}
          </div>
          <div className="smaller-title mt-5 text-center">
            {t("authentication.mfa.enable_two_factor")}
          </div>
          <div className="form-auth-bottom text-center">
            <div className="mfa-buttons-wrapper">
              <button
                className="primary-button small-button"
                onClick={() => {
                  setCurrStep(1);
                }}
              >
                {t("buttons.enable")}
              </button>
              <button
                className="small-button transparent-button mt-1"
                onClick={handleLogout}
              >
                <span className="link-text">{t("buttons.logout")}</span>
              </button>
            </div>
          </div>
        </>
      ) : currStep === 1 ? (
        <>
          <h2 className="mb-4">{t("authentication.mfa.authentication")}</h2>
          <div className="regular-text mb-4">
            {t("authentication.mfa.choose_method")}
          </div>
          <RadioButtons
            checkedIndex={checkedRadioButtonIndex}
            options={radioButtonOptions}
            onOptionClick={setCheckedRadioButtonIndex}
          />
          {radioButtonOptions[checkedRadioButtonIndex].key === "sms"
            ? renderSmsOption()
            : renderAppOption()}
        </>
      ) : (
        <>
          <h2 className="mb-4">{t("authentication.mfa.success")}</h2>
          <div className="smaller-title mb-3">
            {t("authentication.mfa.save_codes")}
          </div>
          <div className="regular-text mb-4">
            {t("authentication.mfa.two_factor_enabled")}
          </div>
          <div className="row-space-between mb-2 ">
            <div>
              <span className="smaller-title">
                {t("authentication.mfa.codes")}
              </span>
              <div className="regular-text" style={{ fontSize: 10 }}>
                {backupCodeGeneratedDateTime &&
                  t("authentication.mfa.generated_on", {
                    dateTime: backupCodeGeneratedDateTime,
                  })}
              </div>
            </div>
            <div className="backup-code-icon-container">
              {isPdfLoading ? (
                <div className={"position-relative"}>
                  <Spinner type="small-blue" />
                </div>
              ) : (
                <>
                  <a href={pdfDownloadUrl} download={pdfFileName}>
                    <Icon icon={icons["download-icon"]} />
                  </a>
                  <Icon
                    icon={icons["print-icon"]}
                    className={"ml-2 cursor-pointer"}
                    onClick={() => printPdf()}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={`backup-tokens-wrapper ${
              backupTokens.length === 0 ? "loading" : ""
            }`}
          >
            {backupTokens.length !== 0 ? (
              backupTokens.map((token, index) => (
                <div key={index} className="backup-token-container">
                  {token}
                </div>
              ))
            ) : (
              <Spinner type="small-blue" />
            )}
          </div>
          <div className="d-flex flex-row-reverse">
            <button
              className="primary-button small-button"
              onClick={() => {
                dispatch(getCurrentInstitution());
                navigateToHome();
              }}
            >
              {t("buttons.continue")}
            </button>
          </div>
        </>
      )}
      {zoomQr && renderQRModal()}
    </div>
  );
};

export default SetupMultiFactorAuth;
