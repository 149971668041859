import React, { useState, useEffect, useRef } from "react";

/**
 * This hook is similar to a standard setInterval, but it manages its cleanup and is safe for multiple re-renders.
 * @param {callback}: The logic to be run during the loop
 * @param {delay}: The duration of the loop
 *
 */

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let callbackReturnFn;
    function tick() {
      return savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(() => {
        callbackReturnFn = tick();
      }, delay);
      return () => {
        clearInterval(id);
        if (callbackReturnFn) callbackReturnFn();
      };
    }
  }, [delay]);
}

export default useInterval;
