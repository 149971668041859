const POST_REPORT_VIDEO = "POST_REPORT_VIDEO";
const GET_REPORT_VIDEO = "GET_REPORT_VIDEO";

export const requestVideo = (patient_id, evaluation_id, data) => ({
  type: POST_REPORT_VIDEO,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/video/`,
      params: {
        module_id: data.module_id,
        extra_args: data.extra_args,
      },
    },
  },
});

export const getVideo = (patient_id, evaluation_id, submission_id) => ({
  type: GET_REPORT_VIDEO,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/video/`,
      params: {
        submission_id: submission_id,
      },
    },
  },
});
