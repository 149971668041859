//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../../components/spinner";
import AddModule from "../../../../components/patientEvaluationsModule/partials/addModule";
import CurrModules from "../../../../components/patientEvaluationsModule/partials/currModules";
import MessageModal from "../../../../components/messageModal";
import NavBar from "../../../../components/navbar";
import Breadcrumbs from "../../../../components/breadcrumbs";
import AddTagsModal from "../../../../components/patientEvaluationsModule/partials/addTagsModal";

//* ************************************** GLOBALS ***************************************
import { getEvaluationTemplates } from "../../../../redux/actions/evaluationTemplates";
import {
  editPatientEvaluation,
  getPatientEvaluation,
} from "../../../../redux/actions/patients";
import {
  addEvaluationModule,
  deleteEvaluationModule,
} from "../../../../redux/actions/patientEvaluationModule";

import calendar from "../../../../assets/images/calendar-small.svg";
import edit_blue from "../../../../assets/images/edit-blue.svg";
import clinical_data from "../../../../assets/images/clinical-data.svg";
import questionnaries from "../../../../assets/images/questionnaries.svg";
import treatments from "../../../../assets/images/treatments.svg";
import kinematic_tests from "../../../../assets/images/kinematic-tests.svg";
import big_arrow_left from "../../../../assets/images/big-left-arrow.svg";
import reportIcon from "../../../../assets/images/patientSidebarIcons/reports_white.svg";
import {
  dateToString,
  parseDateFromDatetime,
} from "../../../../utils/dataAndTime";

import useInterval from "../../../../hooks/useInterval";
import { localeForDatePicker } from "utils/language";

const PatientEvaluationEdit = (props) => {
  //* ************************************** SETUP **************************************

  const dispatch = useDispatch();

  const [evaluationDetailsEditableInfo, setEvaluationDetailsEditableInfo] =
    useState({
      name: "",
      comments: "",
      date: "",
      evaluation_date: "",
      created_by: "",
      tags: [],
    });

  const [isEvaluationDetailsExpanded, setIsEvaluationDetailsExpanded] =
    useState(false);
  const [currSelectedModuleType, setCurrSelectedModuleType] = useState(null);

  const [isEvaluationLoading, setIsEvaluationLoading] = useState(false);

  const [areTemplatesLoading, setAreTemplatesLoading] = useState(false);

  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  const titleInputRef = useRef(null);

  const currModuleTypeList = useSelector(
    (state) => state.evaluationTemplates.module_type_list
  );

  const evaluationDetailsInfo = useSelector(
    (state) => state.patientEvaluations.currEvaluation
  );

  const evaluationTemplatesInfo = useSelector(
    (state) => state.evaluationTemplates
  );

  const [cancelModal, setIsCancelModal] = useState(false);
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);

  //* ************************************** HANDLERS **************************************

  const getCurrEvaluationTemplates = () => {
    setAreTemplatesLoading(true);
    dispatch(getEvaluationTemplates({ items_per_page: 100 }))
      .catch((error) => {
        console.log("error");
      })
      .finally(() => {
        setAreTemplatesLoading(false);
      });
  };

  useEffect(() => {
    setIsEvaluationLoading(true);
    dispatch(getPatientEvaluation(patient_id, evaluation_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          const { evaluation_details } = res.payload.data.data;
          setEvaluationDetailsEditableInfo(evaluation_details);
        }
      })
      .catch(() => alert("error"))
      .finally(setIsEvaluationLoading(false));

    getCurrEvaluationTemplates();
  }, []);

  const onCancel = () => {
    setEvaluationDetailsEditableInfo(evaluationDetailsInfo.evaluation_details);
    setIsCancelModal(false);
    setIsEvaluationDetailsExpanded(false);
  };

  const getCurrentModuleTypeImage = (image) => {
    if (image === 1 || image === "clinical_data") return clinical_data;
    else if (image === 2 || image === "intervention") return treatments;
    else if (image === 4 || image === "kinematic_tests") return kinematic_tests;
    else if (image === 3 || image === "questionnaire") return questionnaries;
    return null;
  };

  const addModule = (module) => {
    dispatch(addEvaluationModule(evaluation_id, module.id)).then(
      (res) => {
        dispatch(getPatientEvaluation(patient_id, evaluation_id));
        console.log("addEvaluationModule:", res);
      },
      (err) => {
        console.log("addEvaluationModule ERROR:", err);
      }
    );
  };

  const deleteModule = (moduleIndex, remove) => {
    const moduleId = evaluationDetailsInfo.summary_status[moduleIndex].id;

    dispatch(deleteEvaluationModule(evaluation_id, moduleId, remove)).then(
      (res) => {
        dispatch(getPatientEvaluation(patient_id, evaluation_id));
        console.log("deleteEvaluationModule:", res);
      },
      (err) => {
        console.log("deleteEvaluationModule ERROR:", err);
      }
    );
  };

  const inputsHandler = (e) => {
    setEvaluationDetailsEditableInfo({
      ...evaluationDetailsEditableInfo,
      [e.target.name]: e.target.value,
    });
  };

  const changeDate = (newDate) => {
    setEvaluationDetailsEditableInfo({
      ...evaluationDetailsEditableInfo,
      date: dateToString(newDate),
    });
  };

  const onTagsAdded = (tags) =>
    setEvaluationDetailsEditableInfo({
      ...evaluationDetailsEditableInfo,
      tags: tags,
    });

  const onTagClicked = (tagId) =>
    setEvaluationDetailsEditableInfo({
      ...evaluationDetailsEditableInfo,
      tags: evaluationDetailsEditableInfo.tags.filter(
        (tag) => tag.id !== tagId
      ),
    });

  const saveEvaluationInfo = () => {
    setIsEvaluationDetailsExpanded(false);
    dispatch(
      editPatientEvaluation(patient_id, evaluation_id, {
        ...evaluationDetailsEditableInfo,
        tags: evaluationDetailsEditableInfo.tags.map((tag) => tag.id),
      })
    ).then(
      (res) => {
        setIsEvaluationLoading(true);
        dispatch(getPatientEvaluation(patient_id, evaluation_id))
          .then((res) => {
            if (res.payload && res.payload.data) {
              const { evaluation_details } = res.payload.data.data;
              setEvaluationDetailsEditableInfo(evaluation_details);
            }
          })
          .catch(() => alert("error"))
          .finally(setIsEvaluationLoading(false));
      },
      (error) => console.log(error)
    );
  };

  useInterval(() => {
    if (
      evaluationDetailsInfo?.summary_status !== null &&
      evaluationDetailsInfo?.summary_status &&
      evaluationDetailsInfo.summary_status.length > 0
    ) {
      evaluationDetailsInfo.summary_status.map((value) => {
        if (value?.submission_status_id !== 4 && value?.module_type === 4) {
          dispatch(getPatientEvaluation(patient_id, evaluation_id));
        }
      });
    }
  }, 15000);

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body patient-evaluations">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="flex-d-row align-items-center">
        <img
          onClick={() =>
            props.history.replace(`/patient/${patient_id}/evaluations`)
          }
          alt="left arrow"
          className="left-arrow"
          src={big_arrow_left}
        />
        <h4 className="m-0">{t("titles.edit_evaluation")}</h4>
      </div>
      <div className="evaluation-details">
        <div className="row row-space-between align-items-center">
          <div className="col-auto small-title">
            {t("patient.evaluations.evaluation_details")}
          </div>
          <div className="col-auto flex-d-row align-items-center">
            <button
              onClick={() =>
                props.history.push(
                  `/patient/${patient_id}/evaluations/${evaluation_id}/report`
                )
              }
              className="small-button primary-button"
            >
              <img alt="New Evaluation" className="mr-1" src={reportIcon} />
              {t("buttons.report")}
            </button>
          </div>
        </div>
        <div className="mt-2">
          <div className="box">
            {isEvaluationDetailsExpanded ? (
              <div>
                <div className="row row-space-between">
                  <div className="col-auto">
                    <div className="input-title-wrapper image-right position-relative">
                      <input
                        type="text"
                        ref={titleInputRef}
                        autoComplete="off"
                        name="name"
                        value={evaluationDetailsEditableInfo.name}
                        className="form-input-3 pr-3 w-100"
                        onChange={inputsHandler}
                      />
                      <img
                        onClick={() => titleInputRef.current.focus()}
                        className="edit-input"
                        alt="Edit blue"
                        src={edit_blue}
                      />
                    </div>
                  </div>
                  <div className="col-auto flex-d-row align-items-center">
                    <button
                      onClick={() => setIsCancelModal(true)}
                      className="small-wider-button transparent-button mr-1"
                    >
                      <u> {t("buttons.cancel")} </u>
                    </button>

                    <button
                      onClick={saveEvaluationInfo}
                      className={`small-button secondary-button`}
                    >
                      {t("buttons.save")}
                    </button>
                  </div>
                </div>
                <div className="mt-4 flex-d-row regular-text black-color">
                  <div className="mr-3">
                    <label className="input-label-text" htmlFor="notes">
                      {t("patient.evaluations.date")}
                    </label>
                    <div>
                      {parseDateFromDatetime(
                        evaluationDetailsEditableInfo.date
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="input-label-text" htmlFor="notes">
                      {t("patient.evaluations.created_by")}
                    </label>
                    <div>{evaluationDetailsEditableInfo.creation_user}</div>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="input-label-text" htmlFor="notes">
                    {t("patient.evaluations.selected_tags")}
                  </label>

                  <div className="days-buttons-wrapper">
                    {evaluationDetailsEditableInfo.tags.map((tag) => (
                      <div
                        className="day-container active"
                        onClick={() => onTagClicked(tag.id)}
                        key={tag.id}
                      >
                        {tag.name}
                      </div>
                    ))}
                    <div
                      className="day-container"
                      onClick={() => setShowAddTagsModal(true)}
                    >
                      + {t("patient.evaluations.add_tags")}
                    </div>
                  </div>
                </div>
                <div className="mt-4 row">
                  <div className="col">
                    <label className="input-label-text" htmlFor="notes">
                      {t("patient.evaluations.date")}
                    </label>
                    <div className="input-image-wrapper image-right ">
                      <DatePicker
                        // disabled={isButtonOrInputDisable}
                        // ref={datePickerInput}
                        autoComplete="off"
                        className={`form-input`}
                        name="date"
                        id="date"
                        placeholderText={t("placeholders.date")}
                        locale={localeForDatePicker(i18n.language)}
                        // minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        selected={
                          evaluationDetailsEditableInfo.date
                            ? new Date(evaluationDetailsEditableInfo.date)
                            : null
                        }
                        onChange={(date) => changeDate(date)}
                      />
                      <img
                        // disabled={isButtonOrInputDisable}
                        // onClick={() => datePickerInput.current.setOpen(true)}
                        alt="calendar"
                        className="input-right-image"
                        src={calendar}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label className="input-label-text" htmlFor="notes">
                      {t("patient.evaluations.notes")}
                    </label>
                    <textarea
                      // disabled={isButtonOrInputDisable}
                      as="textarea"
                      rows="5"
                      autoComplete="off"
                      onChange={inputsHandler}
                      value={evaluationDetailsEditableInfo.comments}
                      className={`form-input`}
                      name="comments"
                      placeholder={t("placeholders.notes")}
                    />
                  </div>
                </div>
                <div className="expand-container">
                  <div
                    onClick={() => setIsEvaluationDetailsExpanded(false)}
                    className="expand-collapse"
                  >
                    {t("buttons.collapse")}
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div>
                <div className="row flex-d-row align-items-center">
                  <div className="col-3">
                    <div className="name">
                      {evaluationDetailsEditableInfo.name}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="days-buttons-wrapper">
                      {evaluationDetailsEditableInfo.tags.length > 0 && (
                        <div className="day-container-static active">
                          {evaluationDetailsEditableInfo.tags[0].name}
                        </div>
                      )}
                      {evaluationDetailsEditableInfo.tags.length > 1 && (
                        <div className="day-container-static">
                          {evaluationDetailsEditableInfo.tags.length - 1} more
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <label className="input-label-text">
                      {t("patient.evaluations.date")}
                    </label>
                    <div className="regular-text black-color">
                      {parseDateFromDatetime(
                        evaluationDetailsEditableInfo.date
                      )}
                    </div>
                  </div>
                  <div className="col-3 flex-d-row justify-content-end">
                    <button
                      onClick={() => setIsEvaluationDetailsExpanded(true)}
                      className="small-button secondary-button mr-1"
                    >
                      {t("buttons.edit")}
                    </button>
                  </div>
                </div>
                <div className="expand-container">
                  <div
                    onClick={() => setIsEvaluationDetailsExpanded(true)}
                    className="expand-collapse"
                  >
                    {t("buttons.expand")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="profile_create_edit_templates">
          <div className="row modules-body">
            {!isEvaluationLoading &&
              !areTemplatesLoading &&
              evaluationDetailsInfo.summary_status && (
                <AddModule
                  currModuleTypeList={currModuleTypeList}
                  setCurrSelectedModuleType={(type) =>
                    setCurrSelectedModuleType(type)
                  }
                  currSelectedModuleType={currSelectedModuleType}
                  getCurrentModuleTypeImage={(image) =>
                    getCurrentModuleTypeImage(image)
                  }
                  addModule={(module) => addModule(module)}
                  hasAddedModules={
                    evaluationDetailsInfo.summary_status.length !== 0
                  }
                  templatesAvailable={evaluationTemplatesInfo.length !== 0}
                />
              )}
            {!isEvaluationLoading &&
            !areTemplatesLoading &&
            evaluationDetailsInfo &&
            evaluationDetailsInfo.summary_status ? (
              <CurrModules
                deleteModule={(moduleIndex, remove) =>
                  deleteModule(moduleIndex, remove)
                }
                patientId={patient_id}
                evaluationId={evaluation_id}
                modules={evaluationDetailsInfo.summary_status}
                getCurrentModuleTypeImage={(image) =>
                  getCurrentModuleTypeImage(image)
                }
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                }}
              >
                <Spinner type={"big-blue"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {cancelModal && (
          <MessageModal
            headerText={t("modals.cancel_changes_header")}
            body={t("modals.cancel_changes_body")}
            footerButtons={[
              <button
                onClick={() => setIsCancelModal(false)}
                className="transparent-button small-button"
              >
                <u> {t("buttons.cancel")} </u>
              </button>,
              <button
                onClick={onCancel}
                className="small-button secondary-button"
              >
                {t("buttons.confirm")}
              </button>,
            ]}
          />
        )}
        {showAddTagsModal && (
          <AddTagsModal
            evaluationTags={evaluationDetailsEditableInfo.tags}
            onCancel={() => setShowAddTagsModal(false)}
            onSubmit={(tags) => {
              onTagsAdded(tags);
              setShowAddTagsModal(false);
            }}
          />
        )}
      </>
    </div>
  );
};

export default React.memo(PatientEvaluationEdit);
