//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import useToastRemove from "../../../hooks/useToastRemove";

//* ************************************** GLOBALS ***************************************
import success from "../../../assets/images/toast/success.svg";
import error from "../../../assets/images/toast/error.svg";
import info from "../../../assets/images/toast/info.svg";
import connection from "../../../assets/images/toast/connection.svg";

/**
 * Main Toast message design is here.
 * If you are looking for design bug or auto toast close bug, you are in correct place!!
 */
export const ToastComponent = ({
  timeOut = 5, // animation and auto close time(in second)
  id = "",
  message = "default message from toastComponent", // default message
  type = "info", // default type
}) => {
  const removeToast = useToastRemove();
  const [toastRemoving, setToastRemoving] = useState("");
  const { t, i18n } = useTranslation();

  const getImage = (type) => {
    let typeImg = info;

    if (type === "success") {
      typeImg = success;
    } else if (type === "error") {
      typeImg = error;
    } else if (type === "info") {
      typeImg = info;
    } else if (type === "connection") {
      typeImg = connection;
    }

    return typeImg;
  };

  /** auto removing toast start. */
  useEffect(() => {
    /** useEffect is created by mistake. search: "closer react js" */
    if (toastRemoving) {
      removeToast(toastRemoving);
      setToastRemoving("");
    }
  }, [toastRemoving]);

  useEffect(() => {
    const time = setTimeout(() => {
      setToastRemoving(id);
    }, (timeOut + 1.5) * 1000);

    return () => clearTimeout(time);
  }, []);
  /** auto removing toast end. */

  return (
    <div key={id} className="toast-body" id={id}>
      <div className="row main">
        <div className="toast-image">
          <img src={getImage(type)} />
        </div>
        <div className="toast-message">{message}</div>
        <div className="toast-button">
          <button
            className="small-button transparent-button"
            onClick={() => removeToast(id)}
          >
            <u>{t("toast_component.dismiss")}</u>
          </button>
          <button className="small-button transparent-button">
            <u></u>
          </button>
        </div>
      </div>
      <div
        className="toast-progress-bar"
        style={{ animation: `progress-bar ${timeOut}s 1.5s linear forwards` }}
      ></div>
    </div>
  );
};

export default React.memo(ToastComponent);
