import {
  GET_PATIENT_TESTS_FAIL,
  GET_PATIENT_TESTS_SUCCESS,
} from "../actions/patients";

const initialState = {
  obj_list: [],
  count: 0,
  page: 0,
  num_pages: 0,
};

const patientTests = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_TESTS_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_TESTS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientTests;
