import MessageModal from "../messageModal";
import { useEffect, useState } from "react";
import Spinner from "../spinner";
import { addCollaborator } from "../../redux/actions/institutions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormValidation } from "../../hooks/useFormValidation";

const AddCollaborator = ({ closeModal, getCollaboratorsList }) => {
  const { institution_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const addCollaboratorRequest = (e) => {
    setIsLoading(true);
    dispatch(addCollaborator(institution_id, collaboratorInfo)).finally(() => {
      setIsLoading(false);
      closeModal();
      getCollaboratorsList();
    });
  };

  const {
    handleSubmit: handleSubmit,
    handleChange: handleInputChange,
    data: collaboratorInfo,
    errors: errors,
  } = useFormValidation({
    validations: {
      email: {
        required: {
          value: true,
          message: t("form_erros.required"),
        },
        pattern: {
          value: "^[a-zA-Z0-9._:$!%-+]+@[a-zA-Z0-9.-]+.[a-zA-Z]$",
          message: t("my_area.institutions.invalid_email"),
        },
      },
      user_type_id: {
        required: {
          value: true,
          message: t("form_erros.required"),
        },
      },

      email_body: {
        required: {
          value: false,
          message: t("form_erros.required"),
        },
      },
    },
    initialValues: { email: "", user_type_id: 1, email_body: "" },
    onSubmit: addCollaboratorRequest,
  });

  const isButtonEnable =
    Object.keys(collaboratorInfo).length > 0 &&
    collaboratorInfo.hasOwnProperty("email") &&
    collaboratorInfo.hasOwnProperty("user_type_id") &&
    collaboratorInfo.email !== "" &&
    collaboratorInfo.user_type_id !== "";

  const AddCollaborator = (
    <form>
      <div className="regular-text mb-4">
        {t("my_area.institutions.you_can_add_collaborator")}
      </div>

      <label className="input-label-text">
        {t("user_info.email")}{" "}
        {errors.hasOwnProperty("email") && errors.email !== "" && (
          <span className="warning-color">* {errors.email}</span>
        )}
      </label>
      <input
        autoComplete="off"
        className={`form-input ${
          errors.hasOwnProperty("email") && errors.email !== "" && "error"
        }`}
        onChange={handleInputChange("email")}
        value={collaboratorInfo.email}
        name="email"
        type="email"
        id="email"
        placeholder={t("placeholders.email")}
      />
      <label className="input-label-text">{t("roles.role")}</label>
      <select
        value={collaboratorInfo.user_type_id}
        onChange={handleInputChange("user_type_id")}
        className={`w-100 dropdown`}
        name="user_type_id"
        id="user_type_id"
      >
        <option value={1}>{t("roles.admin")}</option>
        <option value={2}>{t("roles.clinician")}</option>
        <option value={3}>{t("roles.secretary")}</option>
      </select>
      <label className="input-label-text">{t("forms.message_optional")}</label>
      <textarea
        as="textarea"
        rows="3"
        autoComplete="off"
        onChange={handleInputChange("email_body")}
        value={collaboratorInfo.email_body}
        className={`form-input`}
        name="email_body"
      />
    </form>
  );

  return (
    <MessageModal
      headerText={t("buttons.add_collaborator")}
      body={AddCollaborator}
      footerButtons={[
        <button
          onClick={closeModal}
          type="button"
          className="small-button transparent-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <div className="input-image-wrapper m-0">
          <input
            disabled={isLoading || !isButtonEnable}
            onClick={handleSubmit}
            type="submit"
            className={`small-button secondary-button ${
              isLoading && "transparent"
            }`}
            value={t("buttons.send_invite")}
          />
          {isLoading && <Spinner type={"small"} />}
        </div>,
      ]}
    />
  );
};

export default AddCollaborator;
