import imagePlaceholder from "../../../../assets/images/blue-image-placeholder.svg";
import videoPlaceholder from "../../../../assets/images/blue-video-placeholder.svg";
import arrowUp from "../../../../assets/images/arrow-up.svg";
import arrowDown from "../../../../assets/images/arrow-down.svg";

import { useTranslation } from "react-i18next";

const ExistingExercise = ({
  exercise,
  isExerciseInfoOpen,
  showOrHideInfo,
  selectExercise,
  index,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      key={exercise.id}
      className={`exercise-item-list ${index !== 0 ? "mt-2" : ""}`}
    >
      <div className="row align-items-center">
        <div className="col regular-text medium">{exercise.name}</div>
        <div className="col-auto flex-d-row">
          <button
            onClick={showOrHideInfo}
            className="quaternary-button small-button-2 header-button"
          >
            {t("buttons.info")}
            <img
              alt="Person exercise"
              className="exercise-info-button-img"
              src={isExerciseInfoOpen ? arrowUp : arrowDown}
            />
          </button>
          <button
            onClick={selectExercise}
            className="primary-button small-button-2 header-button"
          >
            {t("buttons.select")}
          </button>
        </div>
      </div>
      {isExerciseInfoOpen && (
        <>
          {" "}
          <div className="mt-2 regular-text smaller">
            {t("patient.exercises.exercise_description")}
          </div>
          <div className="mt-1 regular-text ">{exercise.description}</div>
          <div className="flex-d-row mt-2 regular-text smaller">
            {exercise.image && (
              <div className="flex-d-row mr-3">
                {t("files.image")}:&nbsp;
                <img
                  style={{ marginLeft: "2px" }}
                  alt="placeholder"
                  src={imagePlaceholder}
                />
              </div>
            )}
            {exercise.video && (
              <div className="flex-d-row mr-3">
                {t("files.video")}:&nbsp;{" "}
                <img
                  style={{ marginLeft: "2px" }}
                  alt="placeholder"
                  src={videoPlaceholder}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ExistingExercise;
