//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { baseURL } from "../../../redux/store";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../../../components/messageModal";
import InsertImageModal from "../../../components/insertImageModal";
import Spinner from "../../../components/spinner";
//* ************************************** GLOBALS ***************************************
import {
  getPersonalData,
  getProfileWidgetInfo,
  setNewPersonalData,
} from "../../../redux/actions/user";
import {
  getLanguageString,
  getLanguageID,
  changeLanguage,
} from "../../../utils/language";
import lock_input_input from "../../../assets/images/lock-input.svg";
import edit_blue from "../../../assets/images/edit-blue-round.svg";
import placeholder_photo from "../../../assets/images/profile-placeholder.svg";
import NavBar from "../../../components/navbar";

const personalDataInitialState = {
  photo: null,
  username: "",
  email: "",
  full_name: "",
  phone: "",
  language_id: null,
};

const PersonalData = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.personal_data);

  const [isSavePersonalInfoModalOpen, setIsSavePersonalInfoModalOpen] =
    useState(false);

  const [isInsertImageModalOpen, setIsInsertImageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [personalData, setPersonalData] = useState(personalDataInitialState);
  const [personalDataErrors, setPersonalDataErrors] = useState({});

  const { t, i18n } = useTranslation();
  const languageNames = {
    "pt-PT": t("languages.portuguese"),
    "en-GB": t("languages.english"),
  };

  //* ************************************** SETUP **************************************

  useEffect(() => {
    dispatch(getPersonalData())
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, []);

  //* ************************************** HANDLERS **************************************
  const saveNewPersonalInfo = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(setNewPersonalData(personalData))
      .then((res) => {
        // For some reason using userInfo.language_id produces mismatched results
        changeLanguage(res.payload.data.data.language_id);
        dispatch(getProfileWidgetInfo());
        goBackToShow();
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setPersonalDataErrors(data.form_errors);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const inputsHandler = (e) => {
    delete personalDataErrors[e.target.name];

    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
  };

  const showEdit = () => {
    setPersonalData({
      ...userInfo,
    });
    setIsEditOpen(true);
  };

  const goBackToShow = () => {
    setPersonalData({ ...personalDataInitialState });
    setIsEditOpen(false);
    setPersonalDataErrors({});
  };

  const cancelEdit = () => {
    if (hasUserMadeChangeWhileEditing) {
      setIsSavePersonalInfoModalOpen(true);
    } else {
      goBackToShow();
    }
  };

  const onSaveEditedImage = (photo) => {
    setPersonalData({ ...personalData, photo });
    setIsInsertImageModalOpen(false);
  };

  const onDeleteEditedImage = () => {
    setPersonalData({ ...personalData, photo: null });
    setIsInsertImageModalOpen(false);
  };

  /* const changeLanguage = (id) => {
    i18n
      .changeLanguage(getLanguageString(parseInt(id)))
      .then(() =>
        console.log("Changed language to: " + getLanguageString(parseInt(id)))
      );
  }; */

  const profilePhoto = isEditOpen
    ? personalData.photo === null
      ? placeholder_photo
      : personalData.photo
    : userInfo.photo === null
    ? placeholder_photo
    : userInfo.photo;

  const hasUserMadeChangeWhileEditing =
    personalData.full_name !== userInfo.full_name ||
    parseInt(personalData.language_id) !== userInfo.language_id ||
    personalData.birthdate !== userInfo.birthdate ||
    personalData.photo !== userInfo.photo;

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <div className="personal-data-container">
        <NavBar title={t("titles.personal_data")} />
      </div>
      <div className="profile-mandatory-fields-wrapper">
        <span className="regular-text profile-mandatory-fields-wrapper">
          {t("forms.all_fields")}{" "}
          <span className="warning-color">{t("star")}</span>{" "}
          {t("forms.are_mandatory")}
        </span>
      </div>
      <div className="profile-personal-data">
        <form onSubmit={saveNewPersonalInfo}>
          <div className="row header">
            <div className="col">
              <span className="name">{userInfo.full_name}</span>
            </div>

            <div className="col-auto flex-d-row">
              {isEditOpen ? (
                <div className="flex-d-row">
                  <button
                    type="button"
                    onClick={cancelEdit}
                    className="small-wider-button transparent-button"
                  >
                    <u> {t("buttons.cancel")} </u>
                  </button>
                  <div className="input-image-wrapper image-right m-0">
                    <input
                      value={t("buttons.save")}
                      disabled={!hasUserMadeChangeWhileEditing || isLoading}
                      type="submit"
                      className={`edit-button secondary-button ${
                        isLoading && "transparent"
                      }`}
                    />
                    {isLoading && <Spinner type={"small"} />}
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={showEdit}
                  className="secondary-button edit-button"
                >
                  {t("buttons.edit")}
                </button>
              )}
            </div>
          </div>
          <div className="row body">
            <div className="col-auto left-wrapper">
              <div className="username-image-wrapper">
                <div className="position-relative">
                  <img
                    alt="Profile"
                    className="profile-image"
                    src={profilePhoto}
                  ></img>
                  {isEditOpen && (
                    <div className="edit-image">
                      <img
                        onClick={() => setIsInsertImageModalOpen(true)}
                        src={edit_blue}
                        alt="Edit Button"
                      />
                    </div>
                  )}
                </div>
                <span className="username">@{userInfo.username}</span>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <label className="input-label-text">
                    <span>
                      {t("user_info.username")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                  </label>
                  <div className="input-image-wrapper image-right">
                    <input
                      value={userInfo.username}
                      disabled={true}
                      autoComplete="off"
                      className={`${
                        isEditOpen ? "form-input" : "form-input-2"
                      }`}
                      name="username"
                      id="username"
                      placeholder={t("placeholders.username")}
                    />
                    {isEditOpen && (
                      <img
                        alt="Lock"
                        className="input-right-image default_cursor"
                        src={lock_input_input}
                      />
                    )}
                  </div>
                </div>

                <div className="col">
                  <label className="input-label-text">
                    <span>
                      {t("user_info.email")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                  </label>
                  <div className="input-image-wrapper image-right">
                    <input
                      value={userInfo.email}
                      disabled={true}
                      autoComplete="off"
                      className={`${
                        isEditOpen ? "form-input" : "form-input-2"
                      }`}
                      name="email"
                      id="email"
                      placeholder={t("placeholders.email")}
                    />
                    {isEditOpen && (
                      <img
                        alt="Lock"
                        className="input-right-image default_cursor"
                        src={lock_input_input}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label
                    htmlFor="full_name"
                    className="input-label-text d-flex justify-content-between"
                  >
                    <span>
                      {t("user_info.full_name")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    {personalDataErrors.full_name && (
                      <span className="warning-color">
                        {personalDataErrors.full_name}
                      </span>
                    )}
                  </label>
                  <input
                    value={
                      isEditOpen ? personalData.full_name : userInfo.full_name
                    }
                    onChange={inputsHandler}
                    disabled={!isEditOpen || isLoading}
                    autoComplete="off"
                    className={`form-input-2 ${
                      personalDataErrors.full_name && "error"
                    }`}
                    name="full_name"
                    id="full_name"
                    placeholder={t("placeholders.full_name")}
                  />
                </div>

                <div className="col">
                  <label className="input-label-text">
                    <span>
                      {t("user_info.phone")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                  </label>
                  <div className="input-image-wrapper image-right">
                    <input
                      value={userInfo.phone ? userInfo.phone : "––"}
                      disabled={true}
                      autoComplete="off"
                      className={`${
                        isEditOpen ? "form-input" : "form-input-2"
                      }`}
                      name="phone"
                      id="phone"
                      placeholder={t("placeholders.phone")}
                    />
                    {isEditOpen && (
                      <>
                        <img
                          alt="Lock"
                          data-tip
                          data-for="phone"
                          className="input-right-image"
                          src={lock_input_input}
                        />
                        <ReactTooltip
                          className="tooltip"
                          id="phone"
                          place="top"
                          effect="solid"
                        >
                          {t("my_area.personal_data.phone_two_auth")}
                        </ReactTooltip>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                {userInfo.birthdate && (
                  <div className="col">
                    <label
                      htmlFor="birthdate"
                      className="input-label-text d-flex justify-content-between"
                    >
                      <span>
                        {t("user_info.birthday")}{" "}
                        <span className="warning-color">{t("star")}</span>
                      </span>
                      {personalDataErrors.birth_date && (
                        <span className="warning-color">
                          {personalDataErrors.birth_date}
                        </span>
                      )}
                    </label>
                    <div className="input-image-wrapper image-right">
                      <input
                        value={
                          isEditOpen
                            ? personalData.birthdate
                            : userInfo.birthdate
                        }
                        disabled={true}
                        autoComplete="off"
                        onChange={inputsHandler}
                        className={`${
                          isEditOpen ? "form-input" : "form-input-2"
                        }`}
                        name="birthdate"
                        id="birthdate"
                        placeholder={t("placeholders.date")}
                      />
                      {isEditOpen && (
                        <>
                          <img
                            alt="Lock"
                            className="input-right-image"
                            src={lock_input_input}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className="col">
                  <label className="input-label-text">
                    <span>
                      {t("my_area.personal_data.language")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                  </label>
                  {isEditOpen ? (
                    <select
                      value={personalData.language_id}
                      disabled={isLoading}
                      onChange={inputsHandler}
                      className={`w-100 dropdown1 form-input-2`}
                      name="language_id"
                      id="language_id"
                    >
                      <option value={1}>{t("languages.portuguese")}</option>
                      <option value={2}>{t("languages.english")}</option>
                    </select>
                  ) : (
                    <input
                      value={t(
                        languageNames[getLanguageString(userInfo.language_id)]
                      )}
                      disabled={!isEditOpen}
                      autoComplete="off"
                      className={`form-input-2`}
                      name="language_id"
                    />
                  )}
                </div>
                {!userInfo.birthdate && (
                  <div className="col">
                    <div className="empty-col"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      {isSavePersonalInfoModalOpen && (
        <MessageModal
          headerText={t("my_area.personal_data.dismiss_header")}
          body={t("my_area.personal_data.dismiss_body")}
          footerButtons={[
            <button
              onClick={() => setIsSavePersonalInfoModalOpen(false)}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={() => {
                setIsSavePersonalInfoModalOpen(false);
                goBackToShow();
              }}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {isInsertImageModalOpen && (
        <InsertImageModal
          deletePhoto={onDeleteEditedImage}
          setPhoto={onSaveEditedImage}
          currPhoto={personalData.photo}
          headerText="Edit Photo"
          closeModal={() => setIsInsertImageModalOpen(false)}
        />
      )}
    </div>
  );
};

export default React.memo(PersonalData);
