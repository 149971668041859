//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

import InstitutionInfo from "./partials/institutionInfo";
import Preferences from "./partials/preferences";
import Collaborators from "./partials/collaborators";
import ManagePlans from "./partials/managePlans";
//* ************************************** GLOBALS ***************************************
import { getCurrentInstitution } from "../../../../redux/actions/institutions";
import backButton from "../../../../assets/images/back-button.svg";
import NavBar from "../../../../components/navbar";

const EditInstitution = (props) => {
  const dispatch = useDispatch();
  const [currStep, setCurrStep] = useState(1);
  const { institution_id } = useParams();

  useEffect(() => {
    dispatch(getCurrentInstitution()).catch((error) => console.log(error));
    if (props.history.action === "POP") {
      setCurrStep(3);
    }
  }, []);

  const institutionInfo = useSelector(
    (state) => state.institutions.current_institution
  );

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************

  const goBack = () => {
    if (props.location.cameFromApp) {
      props.history.goBack();
    } else {
      props.history.replace(`/my_area/institutions/`);
    }
  };
  //* ************************************** PARTIALS **************************************

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.institutions")} />
      <div className="profile-institution">
        <div className="header-wrapper row">
          <div className="col">
            <div onClick={goBack} className="button back-button-wrapper">
              <img src={backButton} alt="backButton" />
              {t("buttons.back")}
            </div>
          </div>
          <div className="col small-title flex-d-column align-items-center">
            {institutionInfo.name}
          </div>

          <div className="col" />
        </div>
      </div>
      <div className="mt-3">
        <div className="form-title-container-2">
          <div
            role="button"
            onClick={() => setCurrStep(1)}
            className="col form-title-step"
          >
            {t("my_area.institutions.info")}
          </div>
          <div
            role="button"
            onClick={() => setCurrStep(2)}
            className="col form-title-step"
          >
            {t("my_area.institutions.preferences")}
          </div>
          <div
            role="button"
            onClick={() => setCurrStep(3)}
            className="col form-title-step"
          >
            {t("my_area.institutions.collaborators")}
          </div>
          <div
            role="button"
            onClick={() => setCurrStep(4)}
            className="col form-title-step"
          >
            {t("my_area.institutions.manage")}
          </div>

          <motion.div
            className="form-underline"
            initial={{ width: "25%" }}
            animate={{
              x: currStep !== 1 ? (currStep - 1) * 100 + "%" : 0,
            }}
            transition={{ duration: 0.3 }}
          />
        </div>
        {currStep === 1 ? (
          <InstitutionInfo />
        ) : currStep === 2 ? (
          <Preferences />
        ) : currStep === 3 ? (
          <Collaborators
            goToUser={(user_id) =>
              props.history.push({
                pathname: `/my_area/institutions/${institution_id}/users/${user_id}`,
                cameFromApp: true,
              })
            }
          />
        ) : (
          <ManagePlans />
        )}
      </div>
    </div>
  );
};

export default React.memo(EditInstitution);
