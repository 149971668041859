//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import {
  markAsRead,
  markAsArchive,
  resetNotifications,
  getReadNotificationList,
  getUnreadNotificationList,
} from "../../redux/actions/notifications";
//* ************************************* OUR COMPONENTS *************************************
import NotificationCard from "./partials/notificationCard";
import Spinner from "../spinner";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** ASSETS *****************************************
import gearIcon from "assets/images/notification/gear-icon.svg";
import tickIcon from "assets/images/home/tick-mark-icon.svg";

const Notifications = ({
  show,
  closeNotificationMenu,
  notificationsRef,
  patientInfo,
  showRiskAlert,
  showConnectionRequestModal,
  setInstitutionIdToBeChange,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const notificationFilters = {
    all: "all",
    allNotifications: "all-notification",
    institution: "institution",
    archive: "archive",
    exercises: "Exercises",
    medication: "Medication",
    programs: "Programs",
    myCareTeam: "My Care Team",
    riskAssessement: "Risk Assessement",
    mKinetikos: "mKinetikos",
  };

  const newNotifications = useSelector(
    (state) => state.notifications?.newNotifications
  );
  const oldNotifications = useSelector(
    (state) => state.notifications?.oldNotifications
  );
  const notificationCount = useSelector(
    (state) => state.notifications?.notificationCount
  );
  const current_institution = useSelector(
    (state) => state.institutions?.current_institution
  );
  const nextPageOld = useSelector(
    (state) => state.notifications?.nextPageForOld
  );
  const nextPageNew = useSelector(
    (state) => state.notifications?.nextPageForNew
  );
  const userInstitutions = useSelector(
    (state) => state.notifications?.notificationCount?.by_institution
  );
  const newTotalPageCount = useSelector(
    (state) => state.notifications?.newNotificationstotalPage
  );
  const oldTotalPageCount = useSelector(
    (state) => state.notifications?.oldNotificationstotalPage
  );
  const totalUnreadCount = useSelector(
    (state) => state.notifications?.newCount
  );

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(
    notificationFilters.allNotifications
  );
  const [currentFilterSelected, setCurrentFilterSelected] = useState(
    notificationFilters.institution
  );
  const [isMarkAsLoading, setIsMarkAsLoading] = useState(false);
  const [isArchiveSelected, setIsArchiveSelected] = useState(false);
  const [readPageNumber, setReadPageNumber] = useState(1);
  const [unreadPageNumber, setUnreadPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [institutionId, setInstitutionId] = useState(current_institution.id);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [isMoreUnreadLoading, setIsMoreUnreadLoading] = useState(false);

  const selectedInstitution = useSelector(
    (state) =>
      state.notifications?.notificationCount?.by_institution?.filter(
        (institution) =>
          institution.id === (institutionId || current_institution.id)
      )[0]
  );

  const selectedInstitutionNotificationCount = useSelector(
    (state) =>
      state.notifications?.notificationCount?.by_institution?.filter(
        (institution) =>
          institution.id === (institutionId || current_institution.id)
      )[0]?.total
  );

  const otherInstitutions = userInstitutions?.filter(
    (institution) =>
      institution.id !== (institutionId || current_institution.id)
  );

  const otherInstitutionsNotificationCount = otherInstitutions?.reduce(
    (prev, current) => (prev = prev + current?.total),
    0
  );

  const loadMoreCount = Math.max(
    totalUnreadCount - newNotifications?.length,
    0
  );

  //* ************************************** PARTIALS ****************************************
  useEffect(() => {
    setInstitutionId(current_institution.id);
  }, [current_institution]);

  useEffect(() => {
    if (show) {
      setReadPageNumber(1);
      setUnreadPageNumber(1);
      getNotifications();
    }
  }, [
    currentFilter,
    institutionId,
    isArchiveSelected,
    notificationCount?.total,
    show,
  ]);

  useEffect(() => {
    getNotifications({ getMoreUnread: true });
  }, [unreadPageNumber]);

  const getNotifications = (
    { isScrolling = false, getMoreUnread = false } = {
      isScrolling: false,
      getMoreUnread: false,
    }
  ) => {
    let institution = institutionId || current_institution.id || undefined;
    let archive = isArchiveSelected || false;
    let readPageNo = isScrolling ? readPageNumber : 1;
    let unreadPageNo = getMoreUnread ? unreadPageNumber : 1;
    let filter_value =
      currentFilter === notificationFilters.all ||
      currentFilter === notificationFilters.allNotifications ||
      currentFilter === ""
        ? undefined
        : currentFilter;

    let dataForRead = {
      read: true,
      institution_id: institution,
      archived: archive,
      page: readPageNo,
      notification_type__tag__name: filter_value,
    };

    let dataForUnread = {
      read: false,
      institution_id: institution,
      archived: archive,
      page: unreadPageNo,
      notification_type__tag__name: filter_value,
    };

    if (!isScrolling && !getMoreUnread) dispatch(resetNotifications());

    if (!isScrolling && !getMoreUnread) setIsLoading(true);
    if (isScrolling) setIsMoreLoading(true);
    if (getMoreUnread) setIsMoreUnreadLoading(true);

    if (readPageNumber <= Math.ceil(oldTotalPageCount) && !getMoreUnread) {
      dispatch(getReadNotificationList(dataForRead)).finally(() => {
        if (!isScrolling) setIsLoading(false);
        if (isScrolling) setIsMoreLoading(false);
      });
    }
    if (unreadPageNumber <= Math.ceil(newTotalPageCount) && !isScrolling) {
      dispatch(getUnreadNotificationList(dataForUnread)).finally(() => {
        if (!isScrolling) setIsLoading(false);
        if (isScrolling) setIsMoreLoading(false);
        if (getMoreUnread) setIsMoreUnreadLoading(false);
      });
    } else {
      if (!isScrolling) setIsLoading(false);
      if (isScrolling) setIsMoreLoading(false);
      if (getMoreUnread) setIsMoreUnreadLoading(false);
    }
  };

  const markAllRead = () => {
    const notificationIds = newNotifications.map(
      (notification) => notification.id
    );

    if (notificationIds.length > 0) {
      setIsMarkAsLoading(true);
      setReadPageNumber(1);
      setUnreadPageNumber(1);
      dispatch(markAsRead(notificationIds, true))
        .then(() => {
          getNotifications();
        })
        .finally(() => setIsMarkAsLoading(false));
    }
  };

  const markAsReadorUnread = (notification_id, status) => {
    setIsLoading(true);
    setReadPageNumber(1);
    setUnreadPageNumber(1);
    dispatch(markAsRead(notification_id, status)).then(() => {
      getNotifications();
    });
  };

  const markAsArchiveOrUnarchive = (notification_id, status) => {
    setReadPageNumber(1);
    setUnreadPageNumber(1);
    dispatch(markAsArchive(notification_id, status)).then(() => {
      dispatch(getNotifications());
    });
  };

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
  };

  const onScroll = () => {
    if (notificationsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        notificationsRef.current;

      if (Math.floor(scrollTop + clientHeight) === Math.floor(scrollHeight)) {
        if (
          nextPageOld !== null &&
          readPageNumber <= Math.ceil(oldTotalPageCount)
        ) {
          setReadPageNumber(readPageNumber + 1);
          getNotifications({ isScrolling: true });
        } else if (readPageNumber > Math.ceil(oldTotalPageCount)) {
          setReadPageNumber(1);
        }
      }
    }
  };

  const closeFilterMenu = () => {
    setIsBurgerMenuOpen(false);
  };

  //* *************************************** RENDER *****************************************
  const __renderFilter = () => {
    return (
      <div className="filter-container pl-2 pr-2">
        <div className="filter-menu-container">
          <div
            className={`red-dot ${
              !isBurgerMenuOpen &&
              otherInstitutionsNotificationCount > 0 &&
              "show"
            }`}
          ></div>
          {!isBurgerMenuOpen ? (
            <div className="menu">
              <span
                className={`filter-menu ${
                  institutionId === selectedInstitution?.id &&
                  currentFilterSelected === notificationFilters.institution &&
                  "filter-menu-active"
                }`}
                onClick={() => {
                  setCurrentFilterSelected(notificationFilters.institution);
                  // setPageNumber(1);
                  setInstitutionId(selectedInstitution?.id);
                  setIsArchiveSelected(false);
                }}
              >
                {selectedInstitution?.name}
                {selectedInstitution &&
                selectedInstitutionNotificationCount > 0 ? (
                  <span
                    className={`circle ${
                      currentFilterSelected ===
                        notificationFilters.institution &&
                      institutionId === selectedInstitution?.id &&
                      "circle-active"
                    }`}
                  >
                    {selectedInstitutionNotificationCount}
                  </span>
                ) : (
                  ""
                )}
              </span>
              <span className="separator"></span>
              <span
                className={`filter-menu ${
                  currentFilterSelected === notificationFilters.archive &&
                  "filter-menu-active"
                }`}
                onClick={() => {
                  setCurrentFilterSelected(notificationFilters.archive);
                  setIsArchiveSelected(true);
                }}
              >
                {t("notifications.archive_noun")}
                {!isEmpty(notificationCount) &&
                  notificationCount?.archived > 0 && (
                    <span
                      className={`circle ${
                        currentFilterSelected === notificationFilters.archive &&
                        "circle-active"
                      }`}
                    >
                      {notificationCount?.archived}
                    </span>
                  )}
              </span>
            </div>
          ) : (
            <span className="title">{t("notifications.filter")}</span>
          )}
          <div
            className={`burger-menu ${
              isBurgerMenuOpen && "burger-menu-active"
            }`}
            onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {isBurgerMenuOpen && (
          <div className={`filter-body filter-active`}>
            <span className="pb-1">
              {t("notifications.filter_by_location")}
            </span>

            {userInstitutions?.map((institution) => (
              <span
                className={`filter-options ${
                  institutionId === institution.id &&
                  currentFilterSelected === notificationFilters.institution &&
                  "filter-options-active"
                }`}
                onClick={() => {
                  setInstitutionId(institution.id);
                  setIsArchiveSelected(false);
                  setCurrentFilterSelected(notificationFilters.institution);
                  closeFilterMenu();
                }}
                key={institution.id}
              >
                {institution.name}{" "}
                {institution.total > 0 ? (
                  <span
                    className={`circle ${
                      institutionId === institution.id &&
                      currentFilterSelected ===
                        notificationFilters.institution &&
                      "circle-active"
                    }`}
                  >
                    {institution.total}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ))}
            <span
              className={`filter-options ${
                currentFilterSelected === notificationFilters.archive &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilterSelected(notificationFilters.archive);
                setIsArchiveSelected(true);
                closeFilterMenu();
              }}
            >
              {t("notifications.archived")}
              {!isEmpty(notificationCount) &&
                notificationCount?.archived > 0 && (
                  <span
                    className={`circle ${
                      currentFilterSelected === notificationFilters.archive &&
                      "circle-active"
                    }`}
                  >
                    {notificationCount?.archived}
                  </span>
                )}
            </span>
            <hr className="hr-border" />
            <span className="pb-1">{t("notifications.filter_by_type")}</span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.all &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.all);
                closeFilterMenu();
              }}
            >
              {t("notifications.all")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.exercises &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.exercises);
                closeFilterMenu();
              }}
            >
              {t("notifications.Exercises")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.medication &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.medication);
                closeFilterMenu();
              }}
            >
              {t("notifications.Medication")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.programs &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.programs);
                closeFilterMenu();
              }}
            >
              {t("notifications.Programs")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.myCareTeam &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.myCareTeam);
                closeFilterMenu();
              }}
            >
              {t("notifications.My Care Team")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.riskAssessement &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.riskAssessement);
                closeFilterMenu();
              }}
            >
              {t("notifications.Risk Assessement")}
            </span>
            <span
              className={`filter-options ${
                currentFilter === notificationFilters.mKinetikos &&
                "filter-options-active"
              }`}
              onClick={() => {
                setCurrentFilter(notificationFilters.mKinetikos);
                closeFilterMenu();
              }}
            >
              {t("notifications.mKinetikos")}
            </span>

            <div className="bottom-close-button">
              <span onClick={() => setIsBurgerMenuOpen(false)}>
                {t("buttons.close")}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`${
        show
          ? "notification-container noti-show"
          : "notification-container noti-hide"
      } `}
      ref={notificationsRef}
      onScroll={onScroll}
    >
      <div className="row-space-between" disabled={isMarkAsLoading}>
        <span className="notification-text">
          {t("notifications.notifications")}
        </span>
        <img
          src={gearIcon}
          alt="gear icon"
          className="ml-1 cursor-pointer"
          onClick={() => history.push("/my_area/risk_alert")}
        />
      </div>
      <hr className="hr-border" />
      {__renderFilter()}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center position-relative h-50">
          <Spinner type={"big-blue"} />
        </div>
      ) : (
        <div>
          {newNotifications && !isEmpty(newNotifications) && (
            <div className="notifications">
              <div className="row-space-between">
                <span>{t("notifications.new")}</span>
                <span
                  className="notification-link-text"
                  onClick={() => markAllRead()}
                >
                  <img src={tickIcon} alt="tick icon" className="mr-1" />
                  {t("notifications.mark_all_as_read")}
                </span>
              </div>
              <div className="mb-2"></div>
              {newNotifications?.map((notification) => {
                return (
                  <NotificationCard
                    notification={notification}
                    key={notification.id}
                    markAsReadorUnread={markAsReadorUnread}
                    markAsArchiveUnarchive={markAsArchiveOrUnarchive}
                    isArchived={isArchiveSelected}
                    patientInfo={patientInfo}
                    showRiskAlert={showRiskAlert}
                    showConnectionRequestModal={showConnectionRequestModal}
                    setInstitutionIdToBeChange={setInstitutionIdToBeChange}
                  />
                );
              })}
              <div className="flex-d-row mt-1 center-justify">
                <span
                  className="link-text"
                  onClick={() => {
                    setUnreadPageNumber((prev) => prev + 1);
                  }}
                  disabled={loadMoreCount <= 0}
                >
                  {isMoreUnreadLoading ? (
                    <div
                      className={"position-relative d-flex center-justify p-2"}
                    >
                      <Spinner type={"small-blue"} />
                    </div>
                  ) : (
                    t("notifications.load_more") + `(${loadMoreCount})`
                  )}
                </span>
              </div>
            </div>
          )}
          {oldNotifications && !isEmpty(oldNotifications) && (
            <div className="notifications">
              <span>{t("notifications.old")}</span>
              <div className="mb-2"></div>
              {oldNotifications?.map((notification) => {
                return (
                  <NotificationCard
                    notification={notification}
                    key={notification.id}
                    markAsReadorUnread={markAsReadorUnread}
                    markAsArchiveUnarchive={markAsArchiveOrUnarchive}
                    isArchived={isArchiveSelected}
                    patientInfo={patientInfo}
                    showRiskAlert={showRiskAlert}
                    showConnectionRequestModal={showConnectionRequestModal}
                    setInstitutionIdToBeChange={setInstitutionIdToBeChange}
                    closeNotificationMenu={closeNotificationMenu}
                  />
                );
              })}
            </div>
          )}
          {isEmpty(newNotifications) && isEmpty(oldNotifications) && (
            <div className="regular-text text-center p-5">
              {t("notifications.no_notification")}
            </div>
          )}

          {isMoreLoading && (
            <div className={"position-relative d-flex center-justify p-2"}>
              <Spinner type={"small-blue"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Notifications);
