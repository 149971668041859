export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_SIGNUP = "USER_SIGNUP";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_IS_LOGGEDIN = "USER_IS_LOGGEDIN";
export const USER_IS_LOGGEDIN_SUCCESS = "USER_IS_LOGGEDIN_SUCCESS";

export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_FORGOT_USERNAME = "USER_FORGOT_USERNAME";

export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";

export const USER_CSRF_TOKEN = "USER_CSRF_TOKEN";

export const GET_PERSONAL_DATA = "GET_PERSONAL_DATA";
export const GET_PERSONAL_DATA_SUCCESS = "GET_PERSONAL_DATA_SUCCESS";
export const GET_PERSONAL_DATA_FAIL = "GET_PERSONAL_DATA_FAIL";

export const SET_PERSONAL_DATA = "SET_PERSONAL_DATA";
export const SET_PERSONAL_DATA_SUCCESS = "SET_PERSONAL_DATA_SUCCESS";
export const SET_PERSONAL_DATA_FAIL = "SET_PERSONAL_DATA_FAIL";

export const GET_TERMS_CONDITIONS = "GET_TERMS_CONDITIONS";

export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";

export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";

export const GET_PROFILE_WIDGET_INFO = "GET_PROFILE_WIDGET_INFO";

export const GET_PROFILE_WIDGET_INFO_SUCCESS =
  "GET_PROFILE_WIDGET_INFO_SUCCESS";

export const GET_PROFILE_WIDGET_INFO_FAIL = "GET_PROFILE_WIDGET_INFO_FAIL";

export const ACCEPT_TERMS_AND_CONDITIONS = "GET_PROFILE_WIDGET_INFO_FAIL";

export const GET_TIME_TO_EXPAIR_PASSWORD = "GET_TIME_TO_EXPAIR_PASSWORD";

export const POST_RESEND_ACTIVATE_ACCOUNT_EMAIL =
  "POST_RESEND_ACTIVATE_ACCOUNT_EMAIL";

export const login = (data) => ({
  type: USER_LOGIN,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/login",
      data: data,
    },
  },
});

export const activateAccount = (activation_key) => ({
  type: ACTIVATE_ACCOUNT,
  payload: {
    request: {
      method: "get",
      url: `/cdss/account/confirm/${activation_key}`,
    },
  },
});

export const userLogout = () => ({
  type: USER_LOGOUT,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/logout",
    },
  },
});

export const signup = (data) => ({
  type: USER_SIGNUP,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/signup",
      data: data,
    },
  },
});

export const csrfToken = () => ({
  type: USER_CSRF_TOKEN,
  payload: {
    request: {
      method: "get",
      url: "/cdss/account/csrf_cookie",
    },
  },
});

export const isUserAuthenticated = () => ({
  type: USER_IS_LOGGEDIN,
  payload: {
    request: {
      method: "get",
      url: "/cdss/account/authenticated",
    },
  },
});

export const changePassword = (data) => ({
  type: USER_CHANGE_PASSWORD,
  payload: {
    request: {
      method: "post",
      url: "cdss/account/change_password",
      data: data,
    },
  },
});

export const forgotPassword = (email) => ({
  type: USER_FORGOT_PASSWORD,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/forgot_password",
      data: { email },
    },
  },
});

export const forgotPasswordConfirm = (uidb64, token) => ({
  type: USER_FORGOT_PASSWORD,
  payload: {
    request: {
      method: "get",
      url: `/cdss/account/forgot_password_confirm/${uidb64}/${token}`,
    },
  },
});

export const forgotUsername = (email) => ({
  type: USER_FORGOT_USERNAME,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/forgot_username",
      data: { email },
    },
  },
});

export const forgotUsernameConfirm = (uidb64, token) => ({
  type: USER_FORGOT_USERNAME,
  payload: {
    request: {
      method: "get",
      url: `/cdss/account/forgot_username_confirm/${uidb64}/${token}`,
    },
  },
});

export const resetPassword = (new_password1, new_password2) => ({
  type: USER_RESET_PASSWORD,
  payload: {
    request: {
      method: "post",
      url: `/cdss/account/forgot_password_set`,
      data: { new_password1, new_password2 },
    },
  },
});

export const getPersonalData = () => ({
  type: GET_PERSONAL_DATA,
  payload: {
    request: {
      method: "get",
      url: "/cdss/my_area/personal_data",
    },
  },
});

export const setNewPersonalData = (data) => ({
  type: SET_PERSONAL_DATA,
  payload: {
    request: {
      method: "post",
      url: "/cdss/my_area/personal_data",
      data: data,
    },
  },
});

export const getTermsAndConditions = (language_id) => ({
  type: GET_TERMS_CONDITIONS,
  payload: {
    request: {
      method: "get",
      url: "/cdss/privacy/terms",
      params: { language_id },
    },
  },
});

export const acceptTermsAndConditions = () => ({
  type: ACCEPT_TERMS_AND_CONDITIONS,
  payload: {
    request: {
      method: "put",
      url: "/cdss/userinfo/terms_accept",
    },
  },
});

export const getPrivacyPolicy = (language_id) => ({
  type: GET_PRIVACY_POLICY,
  payload: {
    request: {
      method: "get",
      url: "/cdss/privacy/privacy_policy",
      params: { language_id },
    },
  },
});

export const getProfileWidgetInfo = () => ({
  type: GET_PROFILE_WIDGET_INFO,
  payload: {
    request: {
      method: "get",
      url: "/cdss/userinfo/profile_widget",
    },
  },
});

export const getPasswordExpireTime = () => ({
  type: GET_TIME_TO_EXPAIR_PASSWORD,
  payload: {
    request: {
      method: "get",
      url: "/cdss/userinfo/time_to_update_password",
    },
  },
});

export const resendActivateAcconutEmail = (data) => ({
  type: POST_RESEND_ACTIVATE_ACCOUNT_EMAIL,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/activation/email/",
      data: data,
    },
  },
});
