import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import crossIcon from "../../../assets/images/textarea-cross-button.svg";

import { getEvaluationTags } from "../../../redux/actions/evaluations";

import { useDispatch } from "react-redux";

const AddTagsModal = ({ onCancel, onSubmit, evaluationTags }) => {
  const allTags = useSelector((state) => state.evaluationTags);

  const [currentSelectedTags, setCurrentSelectedTags] =
    useState(evaluationTags);

  const [isGetTagsLoading, setIsGetTagsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const tagSelectHandler = (tag) => {
    const index = currentSelectedTags.map((tag) => tag.id).indexOf(tag.id);
    let auxTagList = [...currentSelectedTags];
    if (index === -1) {
      auxTagList = [...currentSelectedTags, tag];
    } else {
      auxTagList.splice(index, 1);
    }
    setCurrentSelectedTags(auxTagList);
  };

  useEffect(() => {
    setIsGetTagsLoading(true);
    dispatch(
      getEvaluationTags((res) => {
        console.log(res);
        setIsGetTagsLoading(false);
      }),
      (err) => console.log(err)
    );
  }, []);

  return (
    <div className="create-eval-modal-wrapper">
      <div className="create-eval-modal-sidebar-margin" />
      <div className="create-eval-modal-shadow">
        <div className="create-eval-modal-container">
          <div className="title-text">
            {"Add Tags"}
            <img
              alt="Close Programs Filter"
              className="programs-filter-close-btn"
              src={crossIcon}
              style={{
                width: 14,
                height: 14,
                display: "inline-block",
                float: "right",
                cursor: "pointer",
              }}
              onClick={onCancel}
            />
          </div>
          <label className="input-label-text">
            {t("patient.evaluations.selected_tags")}
          </label>
          {allTags.length !== 0 && (
            <div className="tags-wrapper regular-text black-color pointer">
              {isGetTagsLoading &&
                allTags.map((tag) => (
                  <div
                    key={tag.id}
                    onClick={() => {
                      tagSelectHandler(tag);
                    }}
                    className={`tag-container ${
                      currentSelectedTags.map((tag) => tag.id).includes(tag.id)
                        ? "active"
                        : ""
                    }`}
                  >
                    {tag.name}
                  </div>
                ))}
            </div>
          )}
          <div className="footer-wrapper">
            <button
              onClick={onCancel}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>
            <button
              onClick={() => {
                onSubmit(currentSelectedTags);
              }}
              className="small-button secondary-button"
            >
              {t("buttons.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagsModal;
