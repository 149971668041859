//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import { markAsRead } from "../../../redux/actions/notifications";

//* ************************************* OUR COMPONENTS *************************************
import DropdownOptions from "../../dropdownOptions";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************
import { getTimeDateDiff } from "../../../utils/dataAndTime";
import uuid from "../../../utils/uuid";
import { getAvatarWithInitials } from "../../../utils/avatar";

//* ***************************************** ASSETS *****************************************
import arrowDown from "../../../assets/images/notification/arrow-down.svg";
import arrowDownActive from "../../../assets/images/notification/arrow-down-active.svg";
import link from "../../../assets/images/notification/link.svg";

const NotificationCard = ({
  notification,
  markAsReadorUnread,
  markAsArchiveUnarchive,
  isArchived,
  patientInfo,
  showConnectionRequestModal,
  showRiskAlert,
  setInstitutionIdToBeChange,
  closeNotificationMenu,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isOptionsClick, setIsOptionsClick] = useState(null);

  const currentInstitution = useSelector((state) => state.institutions.current_institution);

  const name = notification.cdss_patient?.name;
  const newConnectionRequestsCount = notification.license_requests?.new_requests_count;
  const count = notification?.hasOwnProperty("chat") ? notification.chat.new_messages_count : 1;
  const hour = 72;
  const expiredMedicinesCount = notification?.expired_medicines_count;

  // Add new notification types here
  const notificationTypes = {
    newMessage: 1,
    highRiskAlert: 2,
    adverseEvent: 3,
    noSmartphoneData: 4,
    noSurvey: 5,
    noTest: 6,
    connectionRequest: 7,
    expiredMedication: 8,
  };

  const onClickHandler = () => {
    if (currentInstitution.id === notification?.institution) {
      if (messages[notification.notification_type.id]?.goTo !== "")
        history.push(messages[notification.notification_type.id]?.goTo);

      if (notification.notification_type.id === notificationTypes.highRiskAlert) {
        showRiskAlert(true);
        patientInfo({
          ...notification.cdss_patient,
          time: getTimeDateDiff(notification.creation_elapsed_time),
        });
      }

      if (notification.notification_type.id === notificationTypes.connectionRequest) {
        showConnectionRequestModal(true);
      }

      if (!notification.read) markAsReadorUnread([notification.id], !notification.read);
    } else {
      setInstitutionIdToBeChange(notification?.institution);
    }
    closeNotificationMenu();
  };

  const messages = {
    [notificationTypes.newMessage]: {
      message: (
        <Trans i18nKey={"notifications.name_sent_you_message"} count={count}>
          <b>{{ name }}</b>
          <b>{{ count }}</b>
        </Trans>
      ),
      goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_my_care_team`,
    },
    [notificationTypes.highRiskAlert]: {
      message: (
        <Trans i18nKey={"notifications.reached_high_risk_alert"}>
          <b>{{ name }}</b>
          <b></b>
        </Trans>
      ),
      goTo: "",
    },
    [notificationTypes.adverseEvent]: {
      message: (
        <Trans i18nKey={"notifications.registered_an_adverse_event"}>
          <b>{{ name }}</b>
          <b></b>
        </Trans>
      ),
      goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_report`,
    },
    [notificationTypes.noSmartphoneData]: {
      message: (
        <Trans i18nKey={"notifications.smartphone_did_not_collect_data"} count={count}>
          <b>{{ name }}</b>
          <b>{{ hour }}</b>
        </Trans>
      ),
      goTo: "",
    },
    [notificationTypes.noSurvey]: {
      message: (
        <Trans i18nKey={"notifications.did_not_complete_survey"}>
          <b>{{ name }}</b>
          <b></b>
        </Trans>
      ),
      goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_surveys`,
    },
    [notificationTypes.noTest]: {
      message: (
        <Trans i18nKey={"notifications.did_not_complete_test"}>
          <b>{{ name }}</b>
          <b></b>
        </Trans>
      ),
      goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_tests`,
    },
    [notificationTypes.connectionRequest]: {
      message: (
        <Trans i18nKey={"notifications.new_connection_requests"} count={newConnectionRequestsCount}>
          <b>{{ newConnectionRequestsCount }}</b>
          <b></b>
        </Trans>
      ),
      goTo: "",
    },
    [notificationTypes.expiredMedication]: {
      message: (
        <Trans i18nKey={"notifications.expired_medicines_msg"} count={expiredMedicinesCount}>
          <b>{{ name }}</b>
          <b>{{ expiredMedicinesCount }}</b>
        </Trans>
      ),
      goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_medicines`,
    },
  };

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <>
      <div
        className={`notification-card noth-c ${notification.read && "notification-active"}`}
        onMouseLeave={() => setIsOptionsClick(false)}
        key={uuid()}
      >
        {notification.cdss_patient?.photo ? (
          <img
            src={
              notification.cdss_patient.photo
                ? notification.cdss_patient.photo
                : getAvatarWithInitials(36, notification.cdss_patient.name)
            }
            onError={(e) => {
              e.target.src = getAvatarWithInitials(36, notification.cdss_patient.name);
            }}
            alt="pp"
          />
        ) : (
          <img src={link} alt="pp" className="notification-image-link" />
        )}
        <div className="notification-card-body cursor-pointer" onClick={() => onClickHandler()}>
          <div className="notification-message" data-i18n="[html]content.body">
            {/* <b>{notification.cdss_patient.name} </b> */}
            {messages[notification.notification_type.id]?.message}
          </div>
          <div className="meta-info">
            {getTimeDateDiff(notification.creation_elapsed_time)} •{" "}
            {t("notifications." + notification.notification_type.tag)}
          </div>
        </div>
        <div
          className={`options-menu ${isOptionsClick && "options-menu-active"}`}
          onClick={() => setIsOptionsClick(!isOptionsClick)}
        >
          <img src={isOptionsClick ? arrowDownActive : arrowDown} />

          {isOptionsClick && (
            <div className="dropdown-options-container">
              <DropdownOptions
                buttons={[
                  {
                    text: (
                      <span className="regular-text">
                        {notification.read
                          ? t("buttons.mark_as_unread")
                          : t("buttons.mark_as_read")}
                      </span>
                    ),
                    action: () => {
                      markAsReadorUnread([notification.id], !notification.read);
                    },
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {isArchived ? t("buttons.unarchived") : t("notifications.archive_noun")}
                      </span>
                    ),
                    action: () => {
                      markAsArchiveUnarchive([notification.id], !isArchived);
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className="bottom-border"></div>
    </>
  );
};

export default React.memo(NotificationCard);
