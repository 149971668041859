//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Collaborators from "./partials/collaborators";

//* ************************************** GLOBALS ***************************************
import { getInstitution } from "../../../../redux/actions/institutions";
import backButton from "../../../../assets/images/back-button.svg";

import homeBlue from "../../../../assets/images/home-blue.svg";
import phoneBlue from "../../../../assets/images/phone-blue.svg";
import institution_default from "../../../../assets/images/institution-default.svg";
import CopyIcon from "assets/images/copy.svg";
import CopyHoverIcon from "assets/images/copy-hover.svg";

import { useParams } from "react-router";
import { getPackagePlanName } from "../../../../constants/general";
import NavBar from "../../../../components/navbar";

const ViewInstitutionDetails = (props) => {
  //* ************************************** SETUP **************************************

  const dispatch = useDispatch();
  const { institution_id } = useParams();
  const [copyIcon, setCopyIcon] = useState(CopyIcon);

  const institutionInfo = useSelector(
    (state) => state.institutions.viewing_institution
  );

  const canUserEdit = institutionInfo.role_id === 1 && institutionInfo.current;

  useEffect(() => {
    dispatch(getInstitution(institution_id))
      .then(() => {})
      .catch(() => {});
  }, []);

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************

  const goBack = () => {
    if (props.location.cameFromInstitutions) {
      props.history.goBack();
    } else {
      props.history.replace("/my_area/institutions");
    }
  };

  const copyLicenseKey = () => {
    navigator.clipboard.writeText(institutionInfo.license_request_code);
  };

  const toggleCopyIcon = (mode) => {
    setCopyIcon(mode === "enter" ? CopyHoverIcon : CopyIcon);
  };
  //* ************************************** PARTIALS **************************************

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.institutions")} />
      <div className="profile-institution">
        <div className="header-wrapper row">
          <div className="col">
            <div onClick={goBack} className="button back-button-wrapper">
              <img src={backButton} alt="backButton" />
              {t("buttons.back")}
            </div>
          </div>
          <div className="col small-title flex-d-column align-items-center">
            {institutionInfo.name}
          </div>

          <div className="col d-flex justify-content-end">
            {canUserEdit && (
              <button
                onClick={() =>
                  props.history.push({
                    pathname: `/my_area/institutions/${institution_id}/edit`,
                    cameFromApp: true,
                  })
                }
                className="small-wider-button quaternary-button"
              >
                {t("buttons.edit")}
              </button>
            )}
          </div>
        </div>
        <div className="row profile-view-institution-body">
          <Collaborators
            canUserEdit={canUserEdit}
            goToUser={(user_id) =>
              props.history.push(
                `/my_area/institutions/${institution_id}/users/${user_id}`
              )
            }
          />
          <div className="col-auto pl-4 mt-3 align-items-center">
            <img
              src={
                institutionInfo.photo === null
                  ? institution_default
                  : institutionInfo.photo
              }
              className="profile-image mb-3"
              alt="Institution"
            />
            <div className="contact-info">
              <div className="title">{t("my_area.institutions.contacts")}</div>
              <div className="contact row">
                <span className="at-sign col-auto pr-1">@</span>
                <span className="col p-0">
                  {institutionInfo.email
                    ? institutionInfo.email
                    : t("my_area.institutions.no_info")}
                </span>
              </div>
              <div className="contact row">
                <img
                  style={{ marginRight: "1.5px" }}
                  className="pr-1 col-auto"
                  src={phoneBlue}
                  alt="Phone Blue"
                />
                <span className="col p-0">
                  {institutionInfo.telephone
                    ? institutionInfo.telephone
                    : t("my_area.institutions.no_info")}
                </span>
              </div>
              <div className="contact row">
                <img
                  className="pr-1 col-auto"
                  src={homeBlue}
                  alt="Phone Blue"
                />
                <span className="col p-0">
                  {institutionInfo.location.address
                    ? institutionInfo.location.address
                    : t("my_area.institutions.no_info")}
                </span>
              </div>

              <div className="row regular-text black-color mt-3 align-items-center">
                <div className="col-auto pr-1">
                  {t("my_area.institutions.plan")}
                </div>
                <div className="col-auto p-0">
                  <div
                    className={`plan-wrapper ${institutionInfo.package_plan_name}`}
                  >
                    {getPackagePlanName[institutionInfo.package_plan_name]}
                  </div>
                </div>
              </div>
            </div>
            {institutionInfo.license_request_code && (
              <div className="contact-info mt-4">
                <div className="title">
                  {t("my_area.institutions.institution_code")}
                </div>
                <div className="contact row mt-1">
                  <span className="col-8 dark-grey-color">
                    {t("my_area.institutions.share_with_patients")}
                  </span>
                </div>
                <div className="contact row mt-2 align-items-center">
                  <span className="col-1 dark-grey-color">
                    {t("my_area.institutions.code")}
                  </span>
                  <span className="col-auto surface-black-color">
                    {institutionInfo.license_request_code}
                  </span>
                  <div className={`col-6 position-relative`}>
                    <img
                      src={copyIcon}
                      alt="Copy Info"
                      onMouseEnter={() => toggleCopyIcon("enter")}
                      onMouseLeave={() => toggleCopyIcon("leave")}
                      onClick={() => {
                        copyLicenseKey();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInstitutionDetails;
