//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState } from "react";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

import { getTicketInfo, updateTicketReply } from "redux/actions/tickets";
import { getTicketReplies } from "redux/actions/tickets";
import { icons } from "components/icon/icons";
import Icon from "components/icon";
import Navbar from "components/navbar";
import Spinner from "components/spinner";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************
import { capitalizeFirstLetter } from "utils/string";

//* ***************************************** ASSETS *****************************************

const SupportEdit = (props) => {
  //* **************************************** SETUP *****************************************
  const [isTicketInfoLoading, setIsTicketInfoLoading] = useState(true);
  const [isTicketReplyListLoading, setIsTicketReplyListLoading] =
    useState(true);

  const [changeTicketInfo, setChangeTicketInfo] = useState({
    message: "",
  });

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { support_ticket_id } = useParams();

  const dispatch = useDispatch();

  const ticketInfo = useSelector((state) => state.tickets.ticketInfo);
  const ticketReplies = useSelector((state) => state.tickets.ticketReplies);

  const messages = ticketReplies.obj_list;

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************

  const goBack = () => {
    props.history.goBack();
  };

  const getTicketInfoData = () => {
    setIsTicketInfoLoading(true);
    dispatch(getTicketInfo(support_ticket_id)).finally(() => {
      setIsTicketInfoLoading(false);
    });
    setChangeTicketInfo({
      message: messages[messages.length - 1].message,
    });
  };

  const getTicketRepliesData = () => {
    setIsTicketReplyListLoading(true);
    dispatch(getTicketReplies(support_ticket_id)).finally(() => {
      setIsTicketReplyListLoading(false);
    });
  };

  const handleSubmit = () => {
    setIsTicketReplyListLoading(true);
    dispatch(
      updateTicketReply(
        support_ticket_id,
        messages[messages.length - 1].id,
        changeTicketInfo.message
      )
    ).finally(() => {
      props.history.push(`/my_area/support/ticket/${support_ticket_id}/detail`);
      setIsTicketReplyListLoading(false);
    });
  };

  const onChangeHandler = (e) => {
    setChangeTicketInfo({
      ...changeTicketInfo,
      [e.target.name]: e.target.value,
    });
  };

  const setStatusLabelBackground = (state) => {
    return state === 1
      ? "primary-label"
      : state === 2
      ? "secondary-label"
      : state === 3
      ? "tertiary-label"
      : null;
  };

  useEffect(() => {
    getTicketInfoData();
    getTicketRepliesData();
  }, []);

  return (
    <div className="col profile-body">
      <Navbar />
      <div className="col d-flex">
        <div className="col-12 body-wrapper">
          <div className="row support-edit-container">
            <div className="support-edit-header-container">
              <Icon
                icon={icons["back-icon"]}
                alt="back"
                onClick={goBack}
                className="support-edit-goback-icon"
              />
              <h4 className="support-edit-header-title">
                {t("titles.support_ticket")}
              </h4>
            </div>
            <div className="support-edit-header-actions-container">
              <button
                onClick={goBack}
                type="button"
                className="transparent-button support-edit-cancel-button"
              >
                {t("buttons.cancel")}
              </button>
              <div className="input-image-wrapper m-0 ml-3">
                <button
                  onClick={() => handleSubmit()}
                  className={`secondary-button small-wider-button ${
                    isTicketReplyListLoading && "transparent"
                  }`}
                  disabled={!changeTicketInfo.message}
                >
                  {t("buttons.submit")}
                </button>
                {isTicketReplyListLoading && <Spinner type="small" />}
              </div>
            </div>
            {isTicketInfoLoading ? (
              <div style={{ height: "100px" }}>
                <div className="loading-wrapper">
                  <Spinner type={"big-blue"} />
                </div>
              </div>
            ) : ticketReplies.count !== 0 ? (
              <div className="support-edit-details-container">
                <div className="col support-edit-item-list">
                  <div className="support-edit-item-container">
                    <p className="support-edit-item-title">
                      {t("support.subject")}
                    </p>
                    <p className="support-edit-item-value">
                      {capitalizeFirstLetter(ticketInfo.title)}
                    </p>
                  </div>
                  <div className="support-edit-item-container">
                    <p className="support-edit-item-title">
                      {t("support.status")}
                    </p>
                    <div
                      className={`label small-label primary-label rounded ${setStatusLabelBackground(
                        ticketInfo.support_state
                      )}`}
                    >
                      {ticketInfo.support_state === 1
                        ? t("labels.opened")
                        : ticketInfo.support_state === 2
                        ? t("labels.pending")
                        : ticketInfo.support_state === 3
                        ? t("labels.closed")
                        : t("labels.no_status")}
                    </div>
                  </div>
                  <div className="support-edit-item-container">
                    <p className="support-edit-item-title">
                      {t("support.describe_problem")}
                    </p>
                    <textarea
                      as="textarea"
                      className="support-edit-input-field"
                      placeholder={t("support.write_here")}
                      value={changeTicketInfo.message}
                      name="message"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-d-column no-data-text">
                <span>{t("support.no_ticket_information")}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportEdit;
