/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import MedicineModuleList from "./partials/medicineModuleList";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";

const PatientMkinetikosMedicines = (props) => {
  const { patient_id } = useParams("patient_id");
  const { t, i18n } = useTranslation();
  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container position-relative">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.push(`/patient/${patient_id}/evaluations`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.meds")}</h4>
      </div>
      <div className="regular-text">{t("patient.meds.view_mode")}</div>
      <div className="programs-filter-container">
        <div
          className="programs-filter-btn"
          onClick={() => {
            props.history.replace(
              `/patient/${patient_id}/mkinetikos_prescriptions`
            );
          }}
        >
          {t("patient.meds.prescriptions")}
        </div>
      </div>
      {<MedicineModuleList history={props.history} />}
    </div>
  );
};

export default React.memo(PatientMkinetikosMedicines);
