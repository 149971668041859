//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import VideoPlayer from "../../../../components/showMedia/index.js";
import ShowImage from "../../../../components/showMedia/index.js";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import { getExerciseDetails } from "../../../../redux/actions/patientExercises.js";
import { errors } from "../../../../constants/general.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import defaultExerciseImage from "assets/images/default_exercise.png";

const ShowExercise = (props) => {
  //* *************************************** SETUP ***************************************
  const { patient_id, exercise_id } = useParams();
  const exerciseInfo = useSelector(
    (state) => state.patientExercises.curr_exercise
  );

  const exerciseImageURL = useSelector((state) => {
    return state.patientExercises.curr_exercise.exercise.is_kinetikos_exercise
      ? state.patientExercises.curr_exercise.exercise.image
        ? `${process.env.REACT_APP_BACKEND_HOST}/static/${state.patientExercises.curr_exercise.exercise.image}`
        : defaultExerciseImage
      : state.patientExercises.curr_exercise.exercise.image;
  });

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        if (showImageModal) setShowImageModal(false);
        else if (showVideoModal) setShowVideoModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    dispatch(getExerciseDetails(patient_id, exercise_id)).catch(() => {
      alert(errors.service_error);
    });
  }, []);

  //* ************************************** HANDLERS **************************************
  const goBack = () =>
    props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container position-relative">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img onClick={goBack} alt="back Button" src={bigBackButton} />
        <h4>{exerciseInfo.exercise.name}</h4>
      </div>
      <div className="position-relative">
        <div className="create-exercise-box flex-d-column regular-text black-color">
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">{exerciseInfo.exercise.name}</div>
            {currentInstitutionId ===
              exerciseInfo.exercise_plan.created_by_institution_id && (
              <div className="mt-1">
                <button
                  onClick={() =>
                    props.history.push(
                      `/patient/${patient_id}/mkinetikos_exercises/${exercise_id}/edit`
                    )
                  }
                  className="primary-button small-button"
                >
                  {t("buttons.edit")}
                </button>
              </div>
            )}
          </div>

          <label className="input-label-text mt-3">
            {t("patient.exercises.prescribed_by")}
          </label>
          {exerciseInfo.exercise_plan.created_by ?? "Kinetikos"}
          <label className="input-label-text mt-4">
            {t("patient.exercises.exercise_name")}
          </label>
          {exerciseInfo.exercise.name}
          <label className="input-label-text mt-4">
            {t("patient.exercises.exercise_description")}
          </label>
          {exerciseInfo.exercise.description}
          <div className="row mt-4">
            {exerciseInfo.exercise.image && (
              <div className="col-lg col-md-12">
                <label className="input-label-text d-flex justify-content-between">
                  <span>{t("files.image")}</span>
                </label>
                <div className="exercise-show-img-video">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">my_image_file.jpg</div>
                    <div
                      onClick={() => setShowImageModal(true)}
                      role="button"
                      className="col-auto"
                    >
                      <u>{t("files.see_image")}</u>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {exerciseInfo.exercise.video && (
              <div className="col-lg col-md-12">
                <label className="input-label-text d-flex justify-content-between">
                  <span>{t("files.video")}</span>
                </label>

                <div className="exercise-show-img-video">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">video_example.mp4</div>
                    <div
                      onClick={() => setShowVideoModal(true)}
                      role="button"
                      className="col-auto"
                    >
                      <u>{t("files.watch_video")}</u>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="small-title mt-4">
            {t("patient.exercises.exercise_details")}
          </div>
          <label className="input-label-text mt-4">
            {t("patient.exercises.number_of_sets")}
          </label>
          {exerciseInfo.details.sets}
          {exerciseInfo.details.reps && (
            <>
              <label className="input-label-text mt-4">
                {t("patient.exercises.number_of_reps")}
              </label>
              {exerciseInfo.details.reps}
            </>
          )}
          {exerciseInfo.details.duration && (
            <>
              <label className="input-label-text mt-4">
                {t("patient.exercises.duration")}
              </label>
              {exerciseInfo.details.duration}{" "}
            </>
          )}

          <label className="input-label-text mt-4">
            <span>{t("patient.exercises.frequency")}</span>
          </label>
          <SelectWeekDays
            isStatic={true}
            disabled={true}
            activeDays={exerciseInfo.details.days}
          />
          <label className="input-label-text mt-4">
            {t("patient.exercises.end_date")}
          </label>
          {exerciseInfo.details.end_date ?? t("patient.exercises.indefinite")}
        </div>
      </div>
      {showVideoModal && (
        <VideoPlayer
          url={exerciseInfo.exercise.video}
          close={() => setShowVideoModal(false)}
        />
      )}
      {showImageModal && (
        <ShowImage
          url={exerciseImageURL}
          type="image"
          close={() => setShowImageModal(false)}
        />
      )}
    </div>
  );
};

export default ShowExercise;
