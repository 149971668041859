import {
  GET_PATIENT_PROGRAM_DETAILS_FAIL,
  GET_PATIENT_PROGRAM_DETAILS_SUCCESS,
  ADD_PROGRAM_PROTOCOL_FAIL,
  ADD_PROGRAM_PROTOCOL_SUCCESS,
} from "../actions/patientsPrograms";

const initialState = {
  program_id: null,
  program_name: "",
  start_date: "",
  end_date: null,
  active: true,
  protocols: [],
};

const patientProgramDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_PROGRAM_DETAILS_SUCCESS:
    case ADD_PROGRAM_PROTOCOL_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_PROGRAM_DETAILS_FAIL:
    case ADD_PROGRAM_PROTOCOL_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientProgramDetails;
