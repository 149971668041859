//import liraries
import React from "react";
import { motion } from "framer-motion";

const ScallingBallsAnimation = () => {
  return (
    <div className="d-flex row justify-content-center"
      style={{ width: "100%", height: "100%" }}
    >
      {[...Array(20)].map((el, i) => (
        <div
          key={"ballRow" + i}
          className="row d-flex align-items-center justify-content-center"
        >
          {[...Array(20)].map((el, i) => {
            const initialAndFinal = Math.random();
            return (
              <div
                key={"ballCol" + i}
                style={{ padding: "0px 5px" }}
                className="col d-flex align-items-center justify-content-center"
              >
                <motion.svg
                  animate={{
                    scale: [
                      initialAndFinal,
                      Math.random(),
                      Math.random(),
                      Math.random(),
                      initialAndFinal,
                    ],
                  }}
                  transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: "easeInOut",
                    times: [0, 0.2, 0.5, 0.8, 1],
                  }}
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="4" fill="#7695B1" />
                </motion.svg>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

// define your styles

//make this component available to the app
export default React.memo(ScallingBallsAnimation);
