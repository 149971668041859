//* ********************************** REACT IMPORTS **********************************
import { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import MessageBox from "../../../components/messageBox";
//* *************************************** STYLES ***************************************
//* ************************************** GLOBALS ***************************************
import { resetPassword } from "../../../redux/actions/user";
import Spinner from "../../../components/spinner";

import show_password_logo from "../../../assets/images/show-password.svg";
import hide_password_logo from "../../../assets/images/hide-password.svg";

const ResetPassword = ({ showErrorPage, changeToSuccessResetPassword }) => {
  const [passwords, setPasswords] = useState({
    newPassword1: "",
    newPassword2: "",
  });
  const [passwordsErrors, setPasswordsErrors] = useState([]);

  const [isNewPassword1Hidden, setIsNewPassword1Hidden] = useState(true);
  const [isNewPassword2Hidden, setIsNewPassword2Hidden] = useState(true);

  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   if (Object.keys(passwordsErrors).length > 0) {
  //     const newPasswordErrors = removeErrorsBasedOnNewPassword(
  //       passwordsErrors,
  //       passwords.newPassword1,
  //       passwords.newPassword2
  //     );
  //     setPasswordsErrors({ ...newPasswordErrors });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [passwords]);

  //* ************************************** HANDLERS *************************************

  const submitForgotPassword = (e) => {
    e.preventDefault();

    setIsResetPasswordLoading(true);
    dispatch(resetPassword(passwords.newPassword1, passwords.newPassword2))
      .then(async (res) => changeToSuccessResetPassword())
      .catch((error) => {
        if (error.error.response) {
          const { data } = error.error.response;
          if (data && data.form_errors) {
            if (
              data.form_errors.new_password2[0] ===
              "You can not use a password that is already used in this application."
            )
              setPasswordsErrors([
                t(
                  "authentication.reset_password." +
                    data.form_errors.new_password2[0]
                ),
              ]);
            else setPasswordsErrors(data.form_errors.new_password2);
          } else {
            showErrorPage();
          }
        }
      })
      .finally(() => setIsResetPasswordLoading(false));
  };

  const inputsHandler = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  // const checkForPasswordErrors = () => {
  //   if (passwords.newPassword1 === "") {
  //     setPasswordsErrors({});
  //   } else {
  //     const errors = getPasswordErrors(
  //       passwords.newPassword1,
  //       passwords.newPassword2
  //     );
  //     setPasswordsErrors(errors);
  //   }
  // };

  const canUserSubmit =
    passwords.newPassword1 !== "" && passwords.newPassword2 !== "";

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <h2>{t("titles.reset_password")}</h2>
      <p
        style={{ marginTop: "1.44em", marginBottom: "2.00em" }}
        className="input-label-text"
      >
        {t("authentication.reset_password.insert_new_password")}
      </p>
      <form onSubmit={submitForgotPassword}>
        <label className="input-label-text">
          {t("authentication.reset_password.new_password")}
        </label>
        <div className="input-image-wrapper image-right">
          <input
            // onBlur={checkForPasswordErrors}
            className="form-input"
            onChange={inputsHandler}
            value={passwords.newPassword1}
            name="newPassword1"
            id="newPassword1"
            placeholder={t("placeholders.new_password")}
            type={isNewPassword1Hidden ? "password" : "text"}
            autoComplete="off"
          />
          <img
            onClick={() => setIsNewPassword1Hidden(!isNewPassword1Hidden)}
            alt="Password Show and Hide"
            className="input-right-image"
            src={isNewPassword1Hidden ? show_password_logo : hide_password_logo}
          />
        </div>
        <label className="input-label-text">
          {t("authentication.reset_password.confirm_password")}
        </label>
        <div className="input-image-wrapper image-right">
          <input
            className="form-input"
            // onBlur={checkForPasswordErrors}
            onChange={inputsHandler}
            value={passwords.newPassword2}
            name="newPassword2"
            id="newPassword2"
            placeholder={t("placeholders.confirm_password")}
            type={isNewPassword2Hidden ? "password" : "text"}
            autoComplete="off"
          />
          <img
            onClick={() => setIsNewPassword2Hidden(!isNewPassword2Hidden)}
            alt="Password Show and Hide"
            className="input-right-image"
            src={isNewPassword2Hidden ? show_password_logo : hide_password_logo}
          />
        </div>

        {passwordsErrors.length > 0 && (
          <MessageBox type={"warning"} messages={passwordsErrors} />
        )}
        <div className="form-auth-bottom">
          <div className="input-image-wrapper m-0">
            <input
              disabled={!canUserSubmit}
              type="submit"
              value={isResetPasswordLoading ? "" : t("buttons.save")}
              onClick={submitForgotPassword}
              className="primary-button small-button w-100"
            />
            {isResetPasswordLoading && <Spinner type={"small"} />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
