import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { create_card } from "../../../utils/create_card";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

const SpiderChart = ({ details }) => {
  const svgRef = useRef(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();

  // const data = {
  //   evolution: [
  //     [
  //       {
  //         name: "Pain",
  //         value: null,
  //         label: "2022-01-28",
  //       },
  //       {
  //         name: "ROM",
  //         value: null,
  //         label: "2022-01-28",
  //       },
  //       {
  //         name: "Strength",
  //         value: null,
  //         label: "2022-01-28",
  //       },
  //       {
  //         name: "Functionality",
  //         value: null,
  //         label: "2022-01-28",
  //       },
  //       {
  //         name: "Kinematics",
  //         value: 76.8,
  //         label: "2022-01-28",
  //       },
  //     ],
  //   ],
  //   labels: {
  //     value: "Score: ",
  //     label: "Date",
  //     no_data: "No data",
  //   },
  // };
  // const details = {
  //   id: 3,
  //   type: 9,
  //   title: "Clinical evolution",
  //   description: "Overall vision of clinical data ",
  //   icon: '<i class="fa fa-area-chart" aria-hidden="true"></i>',
  //   color: "var(--mkinetikos-red-color)",
  //   span_row: 4,
  //   span_col: 5,
  // };

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_radar_chart(card, data, color) {
      let card_body = card.querySelector(".card-body"),
        wrapper_div = document.createElement("div"),
        actions_div = document.createElement("div"),
        formatTime = d3.timeFormat("%e %B, %Y"),
        radar_data = data.evolution,
        date_label = data.labels.label,
        value_label = data.labels.value,
        no_data_label = data.labels.no_data;
      wrapper_div.classList = "svg-div noselect";
      actions_div.classList = "interactive-plot-actions noselect";
      card_body.appendChild(wrapper_div);
      card_body.appendChild(actions_div);

      let rect = card_body.getBoundingClientRect(),
        wrapper = d3
          .select(`#${card.id} .svg-div`)
          .append("svg")
          .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
          .attr("preserveAspectRatio", "xMidYMin meet"),
        margin = { top: 0, right: 0, bottom: 0, left: 0 },
        inner_width = rect.width - margin.left - margin.right,
        inner_height = rect.height - margin.top - margin.bottom;
      let keys = ["name", "value", "label"], //Object.keys(radar_data[0][0]),
        // svg         = d3.select(`#${card.id} .card-body`).append('svg'),
        // rect        = svg.node().getBoundingClientRect(),
        // svgWidth    = rect.width,
        // svgHeight   = rect.height,
        // xPadding    = 0,
        // yPadding    = 0,
        series = 0,
        // general configurations of the radar plot
        cfg = {
          radius: 5,
          w: 600,
          h: 600,
          factor: 1,
          factorLegend: 0.85,
          levels: 4,
          maxValue: 100,
          radians: 2 * Math.PI,
          opacityArea: 0.3,
          ToRight: 5,
          TranslateX: 80,
          TranslateY: 30,
          ExtraWidthX: 100,
          ExtraWidthY: 100,
          color: d3
            .scaleOrdinal()
            .range(
              ["var(--kblue-color)", "var(--mkinetikos-red-color)"],
              "var(--kgreen-color)",
              "var(--kyellow-color)"
            ), //(["#6F257F", "#CA0D59"])
        },
        //the data categories (each radar line class)
        categories = radar_data[0].map(function (d) {
          return d[keys[0]];
        }),
        //the number os categories
        cat_count = categories.length,
        // the radius of the radar chart
        radius = (0.8 * Math.min(inner_width, inner_height)) / 2, //cfg.factor*Math.min(cfg.w/2, cfg.h/2),
        // the tooltip format. It will look like: ''
        format = d3.format("%"),
        //Create the group element that will old the pie chart
        g = wrapper
          .append("g")
          // .attr('width', inner_width)
          // .attr('height',inner_height)
          .attr(
            "transform",
            `translate(${inner_width / 2 - radius}, ${
              inner_height / 2 - radius
            })`
          );
      // Creating the web level lines
      for (var level = 1; level <= cfg.levels; level++) {
        g.selectAll(null)
          .data(categories)
          .enter()
          .append("line")
          // sin and cos are placed in this way so that the plot is build "clockwise" from the vertical line and not in the usual unitary circle manner
          // we devide the circle into the number of categories
          // then we jump to the correct slice of the circle by multiplying by i or i+1
          // We have then to multiply by level/total_levels
          // after that it is necessary to change the coordinate system from that of the unitary circle to the one of the g element, by adding or subtracting one
          // finaly we need to multiply by radius to scale the circle to the correct size
          .attr("x1", function (d, i) {
            return (
              ((Math.sin((2 * Math.PI * i) / cat_count) * level) / cfg.levels +
                1) *
              radius
            );
          })
          .attr("x2", function (d, i) {
            return (
              ((Math.sin((2 * Math.PI * (i + 1)) / cat_count) * level) /
                cfg.levels +
                1) *
              radius
            );
          })
          .attr("y1", function (d, i) {
            return (
              (1 -
                (Math.cos((2 * Math.PI * i) / cat_count) * level) /
                  cfg.levels) *
              radius
            );
          })
          .attr("y2", function (d, i) {
            return (
              (1 -
                (Math.cos((2 * Math.PI * (i + 1)) / cat_count) * level) /
                  cfg.levels) *
              radius
            );
          })
          // .attr("class", "line")
          .style("stroke", "grey")
          .style("stroke-opacity", "0.75")
          .style("stroke-width", "0.3px");

        g.append("svg:text")
          .attr("x", function (d) {
            return ((Math.sin(0) * level) / cfg.levels + 1) * radius;
          })
          .attr("y", function (d) {
            return (1 - (Math.cos(0) * level) / cfg.levels) * radius;
          })
          .attr("class", "legend")
          .style("font-family", "sans-serif")
          .style("font-size", "0.6em")
          .attr("dx", "0.5em")
          .attr("fill", "#737373")
          .text(((level * cfg.maxValue) / cfg.levels).toFixed(0));
      }
      // Create axis lines
      var axis = g
        .selectAll(".axis")
        .data(categories)
        .enter()
        .append("g")
        .attr("class", "axis");
      axis
        .append("line")
        .attr("x1", radius)
        .attr("x2", function (d, i) {
          return (Math.sin((2 * Math.PI * i) / cat_count) + 1) * radius;
        })
        .attr("y1", radius)
        .attr("y2", function (d, i) {
          return (1 - Math.cos((2 * Math.PI * i) / cat_count)) * radius;
        })
        .attr("class", "line")
        .style("stroke", "grey")
        .style("stroke-width", "1px");
      axis
        .append("text")
        .attr("class", "legend")
        .text(function (d) {
          return d;
        })
        .style("font-family", "sans-serif")
        .style("font-size", "0.8em")
        .attr("text-anchor", "middle")
        .attr("dy", ".25em")
        .attr("x", function (d, i) {
          return (Math.sin((2 * Math.PI * i) / cat_count) * 1.4 + 1) * radius;
        })
        .attr("y", function (d, i) {
          return (1 - Math.cos((2 * Math.PI * i) / cat_count) * 1.15) * radius;
        });

      var tooltip = d3
        .select(`#${card.id} .card-body`)
        .append("div")
        .attr("class", "plot-tooltip")
        .style("opacity", 0);

      //iterate trough all the series
      radar_data.forEach(function (data) {
        let area_values = [];
        //in the current series, iterate through the series data
        g.selectAll(null).data(data, function (d, i) {
          // getting the data points
          area_values.push([
            ((Math.sin((2 * Math.PI * i) / cat_count) * d[keys[1]]) /
              cfg.maxValue +
              1) *
              radius,
            (1 -
              (Math.cos((2 * Math.PI * i) / cat_count) * d[keys[1]]) /
                cfg.maxValue) *
              radius,
          ]);
        });
        // the last value is equal to the first one
        area_values.push(area_values[0]);

        // https://github.com/wbkd/d3-extended
        d3.selection.prototype.moveToFront = function () {
          return this.each(function () {
            this.parentNode.appendChild(this);
          });
        };
        d3.selection.prototype.moveToBack = function () {
          return this.each(function () {
            var firstChild = this.parentNode.firstChild;
            if (firstChild) {
              this.parentNode.insertBefore(this, firstChild);
            }
          });
        };

        // create the area for this series
        g.selectAll(".area")
          .data([area_values])
          .enter()
          .append("polygon")
          .attr("class", `radar-chart-serie${series}`)
          .style("stroke-width", "2px")
          .style("stroke", cfg.color(series))
          .attr("points", function (d) {
            var str = "";
            for (var pti = 0; pti < d.length; pti++) {
              str = str + d[pti][0] + "," + d[pti][1] + " ";
            }
            return str;
          })
          .style("fill", function (j, i) {
            return cfg.color(series);
          })
          .style("fill-opacity", cfg.opacityArea)
          .on("mouseover", function (d) {
            let z = `polygon.${d3.select(this).attr("class")}`;
            g.selectAll("polygon").transition(200).style("fill-opacity", 0.1);
            g.selectAll(z).transition(200).style("fill-opacity", 0.7);
          })
          .on("mouseout", function () {
            g.selectAll("polygon")
              .transition(200)
              .style("fill-opacity", cfg.opacityArea);
          })
          .on("click", function (d) {
            d3.select(this).moveToBack();
          });

        // adding the dots
        g.selectAll(null)
          .data(data)
          .enter()
          .append("svg:circle")
          .attr("class", `radar-chart-serie${series}`)
          .attr("r", cfg.radius)
          .attr("alt", function (d) {
            return Math.max(d[keys[1]], 0);
          })
          .attr("cx", function (d, i) {
            return area_values[i][0];
          }) //the center of the circles are the same as the area verticis
          .attr("cy", function (d, i) {
            return area_values[i][1];
          })
          .attr("data-id", function (d) {
            return d[keys[0]];
          })
          .attr("data-series", series)
          // .style('position', 'relative')
          .style("fill", "#fff")
          .style("stroke-width", "2px")
          .style("stroke", cfg.color(series))
          .style("fill-opacity", 0.9)
          .on("mouseover", function (d, i) {
            tooltip
              .transition()
              .duration(200)
              .style("opacity", 0.9)
              .style("border", `0.1em solid ${cfg.color(this.dataset.series)}`);
            tooltip.html(`  <p class="smallText">${d[keys[0]]}</p>
                                      <p class="smallText">${date_label}: <span class="smallText kinetikosBlue">${d[keys[2]]}</span></p>
                                      <p class="smallText">${value_label}: <span class="smallText kinetikosBlue"> ${d[keys[1]] === null ? no_data_label : parseFloat(d[keys[1]]).toFixed(2)}</span></p>`);

            let tool_rect = tooltip.node().getBoundingClientRect(),
              x =
                Number(d3.select(this).attr("cx")) +
                (inner_width / 2 - radius) -
                tool_rect.width / 2,
              y = d3.select(this).attr("cy") - tool_rect.height / 3 - 15;

            tooltip
              .style("left", `${x}px`) // + 4.8em)`)
              .style("top", `calc(${y}px + 1.8em)`);
          })
          .on("mouseout", function (d) {
            tooltip.transition().duration(200).style("opacity", 0);
          });
        series++;
      }); //foreach
    }

    if (data === null)
      create_card(
        svgRef.current,
        40,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    if (data) build_radar_chart(svgRef.current, data, details.color);
  }, [data]);

  return (
    <div ref={svgRef}>{isLoading && <Spinner type={"biggest-blue"} />} </div>
  );
};

export default React.memo(SpiderChart);
