//* ******************************** REACT NATIVE IMPORTS ********************************
import React from "react";

//* *********************************** EXTERNAL PACKAGES ********************************
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************
import reportsIcon from "../../../assets/images/patientSidebarIcons/reports_white.svg";

const HomeReports = ({ patientId }) => {
  //* *************************************** SETUP ****************************************
  const history = useHistory();
  const { t, i18n } = useTranslation();

  //* ************************************** RENDER **************************************
  return (
    <div className="data-card">
      <div className="row-space-between">
        <span className="small-title">{t("titles.reports")}</span>
        <button
          onClick={() => {
            history.push(`/patient/${patientId}/mkinetikos_report`);
          }}
          className="button small-button primary-button"
        >
          <img alt="New Evaluation" className="data-icon" src={reportsIcon} />
          <span>{t("buttons.full_reports")}</span>
        </button>
      </div>
    </div>
  );
};

export default HomeReports;
