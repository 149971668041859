import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";

import { useSelector } from "react-redux";
import pillRedHover from "../../../../assets/images/pill-hover-red.svg";
import pillBlueHover from "../../../../assets/images/pill-hover-blue.svg";
import pillDarkBlueHover from "../../../../assets/images/pill-hover-dark-blue.svg";
import pillGreenHover from "../../../../assets/images/pill-hover-green.svg";
import pillLightBlueHover from "../../../../assets/images/pill-hover-light-blue.svg";
import pillOrangeHover from "../../../../assets/images/pill-hover-orange.svg";
import pillPurpleHover from "../../../../assets/images/pill-hover-purple.svg";
import pillYellowHover from "../../../../assets/images/pill-hover-yellow.svg";
import pillRed from "../../../../assets/images/pill-red.svg";
import pillBlue from "../../../../assets/images/pill-blue.svg";
import pillDarkBlue from "../../../../assets/images/pill-dark-blue.svg";
import pillGreen from "../../../../assets/images/pill-green.svg";
import pillLightBlue from "../../../../assets/images/pill-light-blue.svg";
import pillOrange from "../../../../assets/images/pill-orange.svg";
import pillPurple from "../../../../assets/images/pill-purple.svg";
import pillYellow from "../../../../assets/images/pill-yellow.svg";

import { hourStringToTime, hours } from "../../../../utils/dataAndTime";
import { useTranslation } from "react-i18next";

const MKinetikosActivity = ({ activeMedicineCheckboxes, medicinesColors }) => {
  const { t, i18n } = useTranslation();

  const pillsImport = [
    { regular: pillBlue, hover: pillBlueHover, color: "#508CC0" },
    { regular: pillOrange, hover: pillOrangeHover, color: "#F5BD84" },
    { regular: pillRed, hover: pillRedHover, color: "#D76D68" },
    { regular: pillGreen, hover: pillGreenHover, color: "#4ba497" },
    { regular: pillDarkBlue, hover: pillDarkBlueHover, color: "#255986" },
    { regular: pillYellow, hover: pillYellowHover, color: "#FFD953" },
    { regular: pillPurple, hover: pillPurpleHover, color: "#B382F180" },
    { regular: pillLightBlue, hover: pillLightBlueHover, color: "#66CDE380" },
  ];

  const getMedicationColor = (medicationName) =>
    activeMedicineCheckboxes.find((med) => med.name === medicationName)?.color;

  const getPillImage = (medicationName) => {
    const color = getMedicationColor(medicationName);
    if (color) {
      return pillsImport.find((pill) => pill.color === color);
    } else {
      return pillsImport[0];
    }
  };

  const width = 750;
  const height = 400;
  const svgRef = useRef();
  const tooltipRef = useRef();

  const activity = useSelector((state) => state.patientsReports.activity);
  const filteredPrescriptions = useSelector(
    (state) => state.patientsReports.filtered_prescriptions
  );

  const [hoveredPill, setHoveredPill] = useState(null);

  const parseMedicationData = () => {
    const hourCount = new Map();
    let parsedData = [];
    parsedData = filteredPrescriptions.map((med) => {
      const [hour, min, sec] = hourStringToTime(med.time);
      if (!hourCount.has(hour)) {
        hourCount.set(hour, 1);
        return { ...med, index: 0 };
      } else {
        const currentCount = hourCount.get(hour);
        hourCount.set(hour, currentCount + 1);
        return { ...med, index: currentCount };
      }
    });
    return parsedData;
  };

  useEffect(() => {
    const parsedMedication = parseMedicationData(filteredPrescriptions);
    const maxMedsPerHour = d3.max(
      Object.values(parsedMedication).map((medList) => medList.index)
    );

    d3.selectAll(`#chart-activity`).html("");

    const currSteps = activity.map((step) => step.value);
    const maxValue = Math.max(...currSteps);
    const stepsValues = currSteps.map(
      (stepValue) => (stepValue / maxValue) * 100
    );

    var xScale = d3
      .scaleBand()
      .domain(d3.range(hours.length))
      .rangeRound([0, width])
      .paddingInner(0.2);
    const yStepScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    const yPillScale = d3
      .scaleBand()
      .domain(d3.range(d3.max([20, maxMedsPerHour])))
      .rangeRound([0, height])
      .paddingInner(0.2);

    const xAxis = d3.axisBottom(xScale);

    const yStepAxis = d3.axisLeft(yStepScale).ticks(10);

    const makeChart = () => {
      const chart = d3
        .select(svgRef.current)
        .attr("id", "chart-activity")

        .attr("width", width)
        .attr("height", height)
        .style("overflow", "visible");

      const prescriptionHoursToValue = (time) => {
        const [hour, _, _2] = hourStringToTime(time);
        return xScale(hour);
      };

      chart
        .append("text")
        .attr("class", "x-y-label")
        .attr("text-anchor", "end")
        .attr("x", 30)
        .attr("y", -20)
        .text(t("patient.reports.graph_text.average_steps"));

      chart
        .append("text")
        .attr("class", "x-y-label")
        .attr("text-anchor", "end")
        .attr("x", width + 30)
        .attr("y", height + 15)
        .text(t("patient.reports.graph_text.hour"));

      chart
        .selectAll()
        .data(stepsValues)
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("rx", 2)
        .attr("ry", 2)
        .attr("x", (s, i) => xScale(i))
        .attr("y", (s) => yStepScale(s))
        .attr("height", (s) => height - yStepScale(s))
        .attr("width", xScale.bandwidth());

      chart
        .append("g")
        .call(xAxis)
        .attr("class", "axis")
        .attr("transform", `translate(0, ${height})`);

      chart.append("g").call(yStepAxis).attr("class", "axis");

      if (parsedMedication && activeMedicineCheckboxes.length > 1) {
        const newTooltip = d3.select(tooltipRef.current);
        chart
          .selectAll(`.image-boy`)
          .data(parsedMedication)
          .enter()
          .append("svg:image")
          .attr("xlink:href", function (d, i) {
            return getPillImage(d.medicine_text).regular;
          })

          .attr("x", function (d, i) {
            return prescriptionHoursToValue(d.time);
          })
          .attr("y", function (d, i) {
            return yPillScale(d.index) - 8;
          })
          .on("mouseover", function (a, b, i) {
            setHoveredPill(a);
            const x = parseInt(d3.select(this).attr("x"));
            const y = parseInt(d3.select(this).attr("y"));
            d3.select(this).attr(
              "xlink:href",
              getPillImage(a.medicine_text).hover
            );
            chart
              .append("line")
              .attr("x1", x + 14)
              .attr("x2", x + 14) //making a line for legend
              .attr("y1", height - yPillScale(0))
              .attr("y2", y)
              .attr("class", `dash`)
              .style("stroke", getMedicationColor(a.medicine_text));

            newTooltip
              .select("#tooltip-title")
              .text(`${a.time.split(":", 2).join(":")}`);

            newTooltip
              .append("div")
              .attr("class", "tooltip-body")
              .text(
                `${a.medicine_text} - ${t(
                  "patient.reports.graph_text.intake"
                )} ${Math.round(a.intake_percentage)} %`
              )
              .append("div")
              .attr("class", "tooltip-footer")
              .text(
                `${t("patient.reports.graph_text.start_date")} ${
                  a.creation_date
                }`
              );

            newTooltip.style("opacity", 1);
            newTooltip.style("top", `${y + 10}px`);
            newTooltip.style("left", `${x + 10}px`);
          })
          .on("mouseout", function (a, b, i) {
            d3.select(this).attr(
              "xlink:href",
              getPillImage(a.medicine_text).regular
            );
            d3.selectAll("line").remove();
            d3.selectAll(".tooltip-body").remove();
            newTooltip.style("opacity", 0);
          });
      }
    };
    makeChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, activeMedicineCheckboxes, filteredPrescriptions]);

  return (
    <>
      <div
        ref={tooltipRef}
        className="tooltip-container"
        style={{
          borderColor: getMedicationColor(hoveredPill?.medicine_text),
        }}
        id="tooltip-test"
      >
        <div
          id="tooltip-title"
          className="tooltip-title"
          style={{ color: getMedicationColor(hoveredPill?.medicine_text) }}
        />
      </div>
      <div className="activity-chart">
        <svg ref={svgRef} />
      </div>
    </>
  );
};

export default React.memo(MKinetikosActivity);
