import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { errors, success } from "../../../../../constants/general";
import Spinner from "../../../../../components/spinner";
import {
  getInstitutionPreferences,
  updateInstitutionPreferences,
} from "../../../../../redux/actions/institutions";
import { getMkinetikosAccessPermission } from "../../../../../redux/actions/permissions";
import { initialState } from "../../../../../redux/reducers/institutions";
import { useTranslation } from "react-i18next";
import DropDownInputBox from "../../../../../components/dropDownInputBox";

const Preferences = () => {
  const { t, i18n } = useTranslation();

  const [preferences, setPreferences] = useState(
    initialState.editing_institution.institution_preferences
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const institutionPreferences = useSelector(
    (state) => state.institutions.editing_institution.institution_preferences
  );
  const { institution_id } = useParams();
  const dispatch = useDispatch();
  const inputsHandler = (e) => {
    setPreferences({ ...preferences, [e.target.name]: e.target.value });
  };

  const [canUseAppLicenses, setCanUseAppLicenses] = useState("");

  const data = [
    {
      description: t("roles.admin"),
      id: 1,
    },
    {
      description: t("roles.clinician"),
      id: 2,
    },
    {
      description: t("roles.secretary"),
      id: 3,
    },
  ];

  const config = {
    show: "description",
    set: "id",
    dispatchFunction: "",
    selector: data,
  };

  const getInitialValue = () => {
    setIsLoading(true);
    dispatch(getInstitutionPreferences(institution_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          setPreferences(res.payload.data.data);
          setCanUseAppLicenses(res.payload.data.data.can_use_app_licenses);
        }
      })
      .catch(() => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInitialValue();
  }, []);

  const canNotSave =
    (preferences.users_can_edit?.toString() === institutionPreferences.users_can_edit?.toString() &&
      preferences.users_can_see?.toString() === institutionPreferences.users_can_see?.toString() &&
      JSON.stringify(preferences.can_use_app_licenses?.map((value) => value.id)) ===
        JSON.stringify(
          canUseAppLicenses,
          canUseAppLicenses.length > 0 && canUseAppLicenses.sort()
        )) ||
    isSaving ||
    !canUseAppLicenses.length > 0 ||
    isLoading;

  const saveNewPreferences = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setPreferences({ ...preferences, can_use_app_licenses: canUseAppLicenses });
    dispatch(
      updateInstitutionPreferences(institution_id, {
        users_can_see: preferences.users_can_see,
        users_can_edit: preferences.users_can_edit,
        can_use_app_licenses: canUseAppLicenses,
      })
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setPreferences(res.payload.data.data);
          getInitialValue();
          dispatch(getMkinetikosAccessPermission());
        }
      })
      .catch(() => {})
      .finally(() => setIsSaving(false));
  };

  return (
    <div className="institution-form-body-wrapper">
      {isLoading ? (
        <div className="position-relative">
          <Spinner type={"big-blue"} />
        </div>
      ) : (
        <form onSubmit={saveNewPreferences}>
          <div className="regular-semibold-base-text mb-4">
            {t("my_area.institutions.general_permissions")}
          </div>
          <div className="row mb-5">
            <div className="col-6">
              <label className="input-label-text">
                {t("my_area.institutions.premium_patients")}
              </label>
              <div className="drop-down-wrapper">
                <DropDownInputBox
                  config={config}
                  getSelectedText={setCanUseAppLicenses}
                  multiSelect={true}
                  preSetValue={preferences["can_use_app_licenses"]}
                  placeholder={t("placeholders.select_roles")}
                />
              </div>
            </div>
          </div>
          <div className="regular-semibold-base-text">{t("my_area.institutions.define_role")}</div>
          <div className="mt-3 row">
            <div className="col">
              <label className="input-label-text">{t("my_area.institutions.consult_info")}</label>
              <select
                value={preferences.users_can_see}
                onChange={inputsHandler}
                className={`w-100 dropdown`}
                name="users_can_see"
                id="users_can_see"
              >
                <option value={1}>{t("my_area.institutions.all_patients")}</option>
                <option value={2}>{t("my_area.institutions.created_by_clinic")}</option>
              </select>
            </div>
            <div className="col">
              <label className="input-label-text">{t("my_area.institutions.edit_info_of")}</label>
              <select
                value={preferences.users_can_edit}
                onChange={inputsHandler}
                className={`w-100 dropdown`}
                name="users_can_edit"
                id="users_can_edit"
              >
                <option value={1}>{t("my_area.institutions.all_patients")}</option>
                <option value={2}>{t("my_area.institutions.created_by_clinic")}</option>
              </select>
            </div>
          </div>
          <div className="flex-d-row justify-content-end mt-3">
            <div className="input-image-wrapper m-0">
              <input
                disabled={canNotSave}
                value={t("buttons.save")}
                type="submit"
                className={`secondary-button small-wider-button ${isSaving && "transparent"}`}
              />
              {isSaving && <Spinner type={"small"} />}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Preferences;
