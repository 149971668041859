import React, { useState, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MessageModal from "../../../../../components/messageModal";
import DropdownOptions from "../../../../../components/dropdownOptions";
import Paginator from "../../../../../components/paginator";
import Spinner from "../../../../../components/spinner";
import RenewLicenceModal from "../../../../../components/renewLicenceModal";

import {
  getPatientsWithLicense,
  cancelInvitation,
  getLicenseType,
  cancelLicense,
  putAutoRenew,
} from "redux/actions/mKinetikos";

import useSearch from "hooks/useSearch";
import useToast from "hooks/useToast";

import { parseDateFromDatetime } from "utils/dataAndTime";
import useOnClickOutside from "hooks/useOnClickOutside";

import chevronUpSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronUpIcon from "assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronDownIcon from "assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";

import moreBlue from "assets/images/more-blue.svg";
import moreBlueSelected from "assets/images/more-blue-selected-grey.svg";
import searchIcon from "assets/images/search.svg";
import searchClearIcon from "assets/images/cross_darkgrey_selected.svg";

const ManagePatients = ({ props }) => {
  const { t, i18n } = useTranslation();

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const moreOptionsRef = useRef(null);
  const moreOptionsButtonRef = useRef(null);

  const dispatch = useDispatch();
  const showToast = useToast();

  const patients = useSelector((state) => state.licenseHandler.patientWithLicense);
  const currentInstitutionId = useSelector((state) => state.institutions.current_institution.id);
  const licenseType = useSelector((state) => state.licenseHandler.licenseType);

  // variables
  // ################################################################# //
  const [patientLicenseId, setPatientLicenseId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [checkedRadioButtonIndex, setCheckedRadioButtonIndex] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [autoRenew, setAutoRenew] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(null);
  const [confirmModalConfig, setConfirmModalConfig] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(null);

  // end variables
  // ################################################################## //

  // Event handle functions
  // ################################################################### //

  useOnClickOutside([dropDownRef, ...optionsRef.current], () => setPatientLicenseId(null));

  useOnClickOutside([moreOptionsRef, moreOptionsButtonRef], () => setShowMoreOptions(false));

  // checkbox select funciton
  const selectUsers = (id) => {
    if (id === "all") {
      if (!selectAll) {
        setSelectedUsers(
          patients.obj_list
            .filter((patient) => patient.status !== "pending")
            .map((patient) => patient.license_id)
        );

        setSelectAll(true);
      } else {
        setSelectedUsers([]);
        setSelectAll(false);
      }
    } else {
      if (selectedUsers.includes(id)) {
        setSelectedUsers(
          selectedUsers.filter(function (user_id) {
            return user_id !== id;
          })
        );
      } else {
        setSelectedUsers([...selectedUsers, id]);
      }
    }

    if (selectedUsers.length === 1) {
      let license = licenseType.filter(
        (license) =>
          license.mkinetikos_license_type__duration ===
          patients.obj_list.filter((patient) => patient.license_id === selectedUsers[0])[0]
            .license_duration
      );
    }
  };

  const getPatients = () => {
    setIsPatientLoading(true);
    setSelectAll(false);
    setSelectedUsers([]);
    dispatch(
      getPatientsWithLicense(searchOptions.page, searchOptions.orderBy, searchOptions.q)
    ).finally(() => {
      setIsPatientLoading(false);
    });
  };

  //   Cancel Patient License function
  const cancelPatientLicense = (institution_mkinetikos_license_id) => {
    setIsPatientLoading(true);
    setSelectAll(false);
    setSelectedUsers([]);

    dispatch(
      cancelInvitation(currentInstitutionId, {
        license_ids: [institution_mkinetikos_license_id],
      })
    )
      .then(() => {
        getPatients();
        dispatch(getLicenseType());
      })
      .finally(() => {
        setIsPatientLoading(false);
      });
  };

  //   Open Patient function
  const openPatient = (patient) => {
    if (patient.status === "in use" && !patient.patient_id) {
      setShowConfirmModal(true);
      setModalConfig(
        t("my_area.mKinetikos.patient_not_connected_header"),
        t("my_area.mKinetikos.patient_not_connected_body"),
        [
          <button
            onClick={() => {
              setShowConfirmModal(false);
            }}
            className="transparent-button small-button"
          >
            <u> {t("buttons.cancel")} </u>
          </button>,
        ]
      );
    } else if (patient.patient_id) {
      props.history.push(`/patient/${patient.patient_id}/evaluations`);
    } else {
      showToast({ message: t("authentication.error.something_wrong"), type: "error" });
    }
  };

  // reset form
  const resetForm = () => {
    setAutoRenew();
    setCheckedRadioButtonIndex();
    setSelectedUsers([]);
    setSelectAll(false);
  };

  // cancel auto renew handle
  const handleAutoRenew = (licenceId, autoRenew) => {
    setShowConfirmModal(false);
    setIsPatientLoading(true);
    dispatch(putAutoRenew(currentInstitutionId, licenceId ? licenceId : selectedUsers, autoRenew))
      .then(
        (res) => {
          getPatients();
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setIsPatientLoading(false);
      });
  };

  // cancel auto renew
  const changeAutoRenew = (body, licenceId = null, autoRenew = false) => {
    setModalConfig(t("my_area.mKinetikos.change_auto_renew_title"), body, [
      <button
        onClick={() => {
          setShowConfirmModal(false);
          resetForm();
        }}
        className="transparent-button small-button"
      >
        <u> {t("buttons.cancel")} </u>
      </button>,
      <button
        onClick={() => {
          handleAutoRenew(licenceId, autoRenew);
        }}
        className="secondary-button small-button"
      >
        {t("yes")}
      </button>,
    ]);
  };

  // cancel Licence Handle
  const handleCancelLicence = () => {
    setShowConfirmModal(false);
    setIsPatientLoading(true);
    dispatch(
      cancelLicense(currentInstitutionId, {
        license_ids: selectedUsers,
      })
    )
      .then(
        (res) => {
          getPatients();
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setIsPatientLoading(false);
      });
  };

  // cancel Licence
  const cancelLicence = () => {
    setModalConfig(
      t("my_area.mKinetikos.cancel_licence"),
      <Trans i18nKey={"my_area.mKinetikos.cancel_licence_msg"}>
        {selectedUsers.length.toString()}
      </Trans>,
      [
        <button
          onClick={() => {
            setShowConfirmModal(false);
            resetForm();
          }}
          className="transparent-button small-button"
        >
          <u> {t("buttons.cancel")} </u>
        </button>,
        <button
          onClick={() => {
            handleCancelLicence();
          }}
          className="secondary-button small-button"
        >
          {t("yes")}
        </button>,
      ]
    );
  };

  const onClickPatientOptions = (e, patient) => {
    e.stopPropagation();
    patientLicenseId === patient.license_id
      ? setPatientLicenseId(null)
      : setPatientLicenseId(patient.license_id);
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(
    getPatients,
    300
  );

  // End Event handle functions
  // ################################################## //

  // Renders
  // ################################################## //

  //   Renders DropdownOptions Buttons
  const _renderButtons = (patient) => {
    let buttons = [];
    buttons =
      patient.status !== "pending"
        ? [
            {
              text: <span className="regular-text">{t("my_area.mKinetikos.open_patient")}</span>,
              action: () => {
                openPatient(patient);
              },
            },
            {
              text: <span className="regular-text">{t("my_area.mKinetikos.renew_licence")}</span>,
              action: () => {
                setSelectedUsers([patient.license_id]);
                setShowModal(true);
              },
            },
            patient.status !== "expired" && {
              text: patient.auto_renew ? (
                <span className="regular-text">
                  {t("my_area.mKinetikos.cancel_auto_renew_btn")}
                </span>
              ) : (
                <span className="regular-text">
                  {t("my_area.mKinetikos.active_auto_renew_btn")}
                </span>
              ),
              action: () => {
                changeAutoRenew(
                  t("my_area.mKinetikos.auto_renew_body_for_patient"),
                  [patient.license_id],
                  !patient.auto_renew
                );
              },
            },
          ]
        : [
            {
              text: (
                <span className="regular-text warning-color">
                  {t("my_area.mKinetikos.cancel_invite")}
                </span>
              ),
              action: () => {
                // show modale
                setModalConfig(
                  t("my_area.mKinetikos.cancel_invite"),
                  t("my_area.mKinetikos.cancel_invite_msg"),
                  [
                    <button
                      onClick={() => {
                        setShowConfirmModal(false);
                        resetForm();
                      }}
                      className="transparent-button small-button"
                    >
                      <u> {t("buttons.cancel")} </u>
                    </button>,
                    <button
                      onClick={() => {
                        if (patient.license_id) {
                          cancelPatientLicense(patient.license_id);
                          setShowConfirmModal(false);
                        }
                      }}
                      className="secondary-button small-button"
                    >
                      {t("yes")}
                    </button>,
                  ]
                );
                // show module end
              },
            },
          ];

    return buttons;
  };

  // Renders top more buttons
  const _renderMoreOptionsButtons = () => {
    let buttons = [];
    buttons = [
      {
        text: (
          <span className="regular-text warning-color">
            {t("my_area.mKinetikos.cancel_licence")}
          </span>
        ),
        action: () => {
          setShowMoreOptions(false);
          cancelLicence();
        },
      },
      {
        text: (
          <span className="regular-text warning-color">
            {t("my_area.mKinetikos.cancel_autorenew")}
          </span>
        ),
        action: () => {
          setShowMoreOptions(false);
          changeAutoRenew(t("my_area.mKinetikos.auto_renew_body_for_bulk"));
        },
      },
    ];

    return buttons;
  };

  // Render confirm module
  const setModalConfig = (headerText, body, footerButtons) => {
    setConfirmModalConfig({
      headerText: headerText,
      body: body,
      footerButtons: footerButtons,
    });
    setShowConfirmModal(true);
  };

  // End Renders
  // ##################################################### //

  return (
    <div className="manage-patients-wrapper mt-6">
      <div className="row manage_patients_header">
        <div className="row-space-between title-wrapper">
          <h2 className="small-title mb-3">{t("my_area.mKinetikos.manage_patients")}</h2>
          <div className="row-space-between">
            {selectedUsers.length > 0 && (
              <button
                className="button small-button primary-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("buttons.renew_licence")} ({selectedUsers.length})
              </button>
            )}
            <div className="col-auto center-align">
              <div className="d-flex align-items-center">
                <div className="more-image-wrapper">
                  <img
                    ref={moreOptionsButtonRef}
                    className={`image-cursor ${
                      selectedUsers.length > 0 ? "more-btn-active" : "more-btn"
                    }`}
                    alt="More Blue"
                    onMouseOver={(e) => (e.currentTarget.src = moreBlueSelected)}
                    onMouseOut={(e) => (e.currentTarget.src = moreBlue)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMoreOptions(!showMoreOptions);
                    }}
                    src={showMoreOptions ? moreBlueSelected : moreBlue}
                  />
                  {showMoreOptions && selectedUsers.length > 0 && (
                    <DropdownOptions ref={moreOptionsRef} buttons={_renderMoreOptionsButtons()} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row search-wrapper p-1">
        <div className="col">
          <div style={{ position: "relative" }}>
            <input
              value={searchOptions.q || ""}
              onChange={(e) => updateSearchText(e.target.value)}
              type="text"
              className="search-input w-100"
              placeholder={t("placeholders.search_for_program")}
            />
            <img alt="search-icon" className="search-icon" src={searchIcon} />
            {searchOptions.q && (
              <img
                alt="search-clear-icon"
                className="search-clear-icon"
                src={searchClearIcon}
                onClick={() => {
                  updateSearchText(null);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="patients-list">
        <div className="row list-label">
          <div className="col-1 d-flex mt-1 check-box-width">
            <div
              //   key={symptom.name + index.toString()}
              className="check-box-wrapper"
            >
              <div className="input-wrapper col-md-auto">
                <input
                  disabled={false}
                  name={"some_name"}
                  checked={selectAll}
                  onChange={() => selectUsers("all")}
                  type={"checkbox"}
                />
              </div>
            </div>
          </div>
          <div className="col-4 d-flex center-align">
            <div
              className="d-flex pointer"
              onClick={() => {
                updateOrderBy(
                  searchOptions.orderBy === "name"
                    ? "-name"
                    : searchOptions.orderBy === "-name"
                    ? "status"
                    : "name"
                );
              }}
            >
              <div>{t("my_area.mKinetikos.name")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === "name" || searchOptions.orderBy === "-name"
                    ? "active"
                    : ""
                }`}
              >
                {searchOptions.orderBy !== "name" && (
                  <img
                    src={searchOptions.orderBy === "-name" ? chevronUpSelectedIcon : chevronUpIcon}
                    alt="Order by Name"
                  />
                )}
                {searchOptions.orderBy !== "-name" && (
                  <img
                    src={
                      searchOptions.orderBy === "name" ? chevronDownSelectedIcon : chevronDownIcon
                    }
                    alt="Order by Name Invert"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-3 d-flex center-align">
            <div
              className="d-flex pointer"
              onClick={() => {
                updateOrderBy(
                  searchOptions.orderBy === "-expiration_date"
                    ? "expiration_date"
                    : "-expiration_date"
                );
              }}
            >
              <div>{t("my_area.mKinetikos.expiring_licence_dates")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === "expiration_date" ||
                  searchOptions.orderBy === "-expiration_date"
                    ? "active"
                    : ""
                }`}
              >
                {searchOptions.orderBy !== "expiration_date" && (
                  <img
                    src={
                      searchOptions.orderBy === "-expiration_date"
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by licence dates"
                  />
                )}
                {searchOptions.orderBy !== "-expiration_date" && (
                  <img
                    src={
                      searchOptions.orderBy === "expiration_date"
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by licence dates Invert"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-2 d-flex center-align">
            <div
              className="d-flex pointer"
              onClick={() => {
                updateOrderBy(searchOptions.orderBy === "-status" ? "status" : "-status");
              }}
            >
              <div>{t("my_area.mKinetikos.status")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === "status" || searchOptions.orderBy === "-status"
                    ? "active"
                    : ""
                }`}
              >
                {searchOptions.orderBy !== "status" && (
                  <img
                    src={
                      searchOptions.orderBy === "-status" ? chevronUpSelectedIcon : chevronUpIcon
                    }
                    alt="Order by status"
                  />
                )}
                {searchOptions.orderBy !== "-status" && (
                  <img
                    src={
                      searchOptions.orderBy === "status" ? chevronDownSelectedIcon : chevronDownIcon
                    }
                    alt="Order by status Invert"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col d-flex center-align">
            <div
              className="d-flex pointer"
              onClick={() => {
                updateOrderBy(
                  searchOptions.orderBy === "-auto_renew" ? "auto_renew" : "-auto_renew"
                );
              }}
            >
              <div>{t("my_area.mKinetikos.autorenew")}</div>
              <div
                className={`order-img-container ${
                  searchOptions.orderBy === "auto_renew" || searchOptions.orderBy === "-auto_renew"
                    ? "active"
                    : ""
                }`}
              >
                {searchOptions.orderBy !== "auto_renew" && (
                  <img
                    src={
                      searchOptions.orderBy === "-auto_renew"
                        ? chevronUpSelectedIcon
                        : chevronUpIcon
                    }
                    alt="Order by auto renew"
                  />
                )}
                {searchOptions.orderBy !== "-auto_renew" && (
                  <img
                    src={
                      searchOptions.orderBy === "auto_renew"
                        ? chevronDownSelectedIcon
                        : chevronDownIcon
                    }
                    alt="Order by auto renew Invert"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isPatientLoading ? (
          <div className="loading-container position-relative">
            <Spinner type={"big-blue"} />
          </div>
        ) : patients.obj_list && patients.obj_list.length > 0 ? (
          patients.obj_list.map((patient, index) => {
            return (
              <div key={patient.license_id} className="row patients-info-wrapper">
                <div className="col-1 d-flex mt-1 check-box-width">
                  <div className="check-box-wrapper">
                    <div className="input-wrapper col-md-auto">
                      <input
                        id={patient.license_id}
                        disabled={patient.status == "pending" ? true : false}
                        name={"some_name"}
                        checked={selectedUsers.includes(patient.license_id)}
                        onChange={() => selectUsers(patient.license_id)}
                        type={"checkbox"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 regular-text black-color align-items-center pointer text-truncate">
                  {patient.status !== "pending" ? (
                    patient.name ? (
                      patient.name
                    ) : (
                      t("my_area.mKinetikos.patient_didn_login")
                    )
                  ) : (
                    <>
                      <span className="regular-text">
                        {t("my_area.mKinetikos.not_registered_yet")}
                      </span>{" "}
                      ({patient.email})
                    </>
                  )}
                </div>
                <div className="col-3 regular-text black-color">
                  {parseDateFromDatetime(patient.expiring_date)}
                </div>
                <div className="col-2 tags-wrapper regular-text black-color">
                  <div
                    className={`tag-container ${
                      patient.status === "expiring soon"
                        ? "expiring-soon"
                        : patient.status === "in use"
                        ? "in-use"
                        : patient.status
                    }`}
                  >
                    {t("my_area.mKinetikos." + patient.status)}
                  </div>
                </div>
                <div className="col tags-wrapper regular-text black-color">
                  <div
                    className={`mkinetikos-wrapper-split ${
                      patient.status === "expired"
                        ? patient.status
                        : patient.auto_renew
                        ? "auto-renew-bg"
                        : "no-auto-renew"
                    }`}
                  ></div>
                </div>
                <div className="col-auto">
                  <div className="d-flex align-items-center">
                    <div className="more-image-wrapper">
                      <img
                        ref={(el) => (optionsRef.current[index] = el)}
                        className="image-cursor"
                        alt="More Blue"
                        onMouseOver={(e) =>
                          patientLicenseId !== patient.license_id &&
                          (e.currentTarget.src = moreBlueSelected)
                        }
                        onMouseOut={(e) =>
                          patientLicenseId !== patient.license_id &&
                          (e.currentTarget.src = moreBlue)
                        }
                        onClick={(e) => onClickPatientOptions(e, patient)}
                        src={patientLicenseId === patient.license_id ? moreBlueSelected : moreBlue}
                      />
                      {patientLicenseId === patient.license_id && (
                        <DropdownOptions ref={dropDownRef} buttons={_renderButtons(patient)} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <span className="regular-text p-4">{t("my_area.mKinetikos.no_patient_found")}</span>
        )}
        <Paginator
          currPage={patients.page}
          totalPages={patients.num_pages}
          maxPages={3}
          changePage={updatePage}
        />
      </div>
      {showModal && (
        <RenewLicenceModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          renewWithEmail={false}
          data={patients.obj_list.filter((patient) => {
            if (selectedUsers.includes(patient.license_id)) {
              return patient;
            }
          })}
          afterOnClickFunction={getPatients}
        />
      )}
      {showConfirmModal && (
        <MessageModal
          headerText={confirmModalConfig.headerText}
          body={confirmModalConfig.body}
          footerButtons={confirmModalConfig.footerButtons}
          hasCloseButton
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default React.memo(ManagePatients);
