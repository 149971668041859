//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import CompanyInfo from "components/companyInfo";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import { getPrivacyPolicy } from "redux/actions/user";
import { getLanguageID } from "utils/language";

//* ************************************** GLOBALS ***************************************
import backButton from "../../assets/images/back-button.svg";

const product_version = process.env.REACT_APP_PRODUCT_VERSION;

const PrivacyElement = ({ title, onGoBack, bodyText, isPublic = true }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    if (isPublic) {
      dispatch(getPrivacyPolicy(getLanguageID(i18n.language)))
        .then((res) => {
          if (res.payload && res.payload.data)
            setPrivacyPolicy(res.payload.data.data);
        })
        .catch((error) => console.error(error));
    }
  }, []);
  //* ************************************** HANDLERS **************************************

  const renderPublicPrivacy = () => {
    return (
      <div className="col profile-body">
        <div className="row-space-between">
          <Icon icon={icons["company-logo"]} alt="company-logo" />
          <div>
            <Icon icon={icons["serial-number-icon"]} alt="snIcon" />
            <span className="regular-text">
              {product_version === "" ? "Error" : product_version}
            </span>
          </div>
        </div>
        <div className="body-text-wrapper">
          <div className="title small-title">{title}</div>
          <div className="body-text regular-text black-color">
            {privacyPolicy}
          </div>
        </div>
        <div className="d-flex center-justify">
          <CompanyInfo />
        </div>
      </div>
    );
  };

  const renderAuthPrivacy = () => {
    return (
      <div className="col profile-body">
        <h4>{t("titles.privacy")}</h4>
        <div className="privacy-element">
          <div className="button back-button-wrapper" onClick={onGoBack}>
            <img src={backButton} alt="backButton" />
            {t("buttons.back")}
          </div>

          <div className="text-wrapper">
            <div className="title">{title}</div>
            <div className="body-text-wrapper">
              <div className="body-text">{bodyText}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //* ************************************** RENDER **************************************
  return isPublic ? renderPublicPrivacy() : renderAuthPrivacy();
};

export default React.memo(PrivacyElement);
