import {
  GET_NEW_NOTIFICATION_COUNT_SUCCESS,
  GET_NEW_NOTIFICATION_COUNT_FAIL,
  RESET_STORE,
  GET_READ_NOTIFICATION_LIST_SUCCESS,
  GET_UNREAD_NOTIFICATION_LIST_SUCCESS,
  SET_NOTIFICATIONS_COUNT,
} from "../actions/notifications";

const initialState = {
  allNotifications: [],
  newNotifications: [],
  oldNotifications: [],
  notificationCount: {},
  isRefreshNotification: false,
  nextPageForNew: 1,
  nextPageForOld: 1,
  newCount: 0,
  oldCount: 0,
  newNotificationstotalPage: 1,
  oldNotificationstotalPage: 1,
};

const notifications = (state = initialState, action) => {
  function uniqueById(items) {
    const set = new Set();
    return items.filter((item) => {
      const isDuplicate = set.has(item.id);
      set.add(item.id);
      return !isDuplicate;
    });
  }

  switch (action.type) {
    case RESET_STORE: {
      return {
        ...state,
        allNotifications: [],
        newNotifications: [],
        oldNotifications: [],
        nextPage: null,
        isLoading: false,
        nextPageForNew: 1,
        nextPageForOld: 1,
        newCount: 0,
        oldCount: 0,
        newNotificationstotalPage: 1,
        oldNotificationstotalPage: 1,
      };
    }
    case SET_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        notificationCount: action.payload,
      };
    }
    case GET_UNREAD_NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        newNotifications: uniqueById([
          ...state.newNotifications,
          ...action.payload.data.data.results,
        ]),
        nextPageForNew: action.payload.data.data.next,
        newCount: action.payload.data.data.count,
        newNotificationstotalPage: action.payload.data.data.total_pages,
      };
    }
    case GET_READ_NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        oldNotifications: uniqueById([
          ...state.oldNotifications,
          ...action.payload.data.data.results,
        ]),
        nextPageForOld: action.payload.data.data.next,
        oldCount: action.payload.data.data.count,
        oldNotificationstotalPage: action.payload.data.data.total_pages,
      };
    }
    case GET_NEW_NOTIFICATION_COUNT_SUCCESS: {
      let previousCount = state?.notificationCount?.total;
      return {
        ...state,
        notificationCount: action.payload.data.data,
        isRefreshNotification: action.payload.data.data.total !== previousCount,
      };
    }
    case GET_NEW_NOTIFICATION_COUNT_FAIL: {
      return { ...state, notificationCount: 0 };
    }

    default:
      return state;
  }
};

export default notifications;
