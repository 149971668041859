//* *********************************** REACT IMPORTS ************************************
import React from "react";
import ReactDOM from "react-dom";

//* ********************************* EXTERNAL PACKAGES **********************************

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import { Portal } from "./partials/portal";
import ToastComponent from "./partials/toastComponent";

//* ************************************** GLOBALS ***************************************

/**
 *
 * This is the parent of Toast Component.
 * This component is creating a 'div' with id "toast" and appending befor 'div' with id "root".
 * The main purpose of this component, is to put toast message on top of the application.
 * More: search: react js portal
 *
 */
export const Toast = (toastMessages) => {
  const isLoaded = Portal();

  return (
    isLoaded &&
    ReactDOM.createPortal(
      <ToastComponent
        key={toastMessages.toastMessages.id}
        message={toastMessages.toastMessages.message}
        id={toastMessages.toastMessages.id}
        type={toastMessages.toastMessages.type}
      />,
      document.getElementById("toast")
    )
  );
};

export default Toast;
