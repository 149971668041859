//* ******************************** REACT NATIVE IMPORTS ********************************
import React from "react";
import { useState, useRef, useEffect } from "react";

//* *********************************** EXTERNAL PACKAGES ************************************
import * as d3 from "d3";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import EventPopup from "./eventPopup";

//* ************************************** GLOBALS ***************************************
import { stringToDate } from "../../../../utils/dataAndTime";

const EventsMatrix = () => {
  //* ************************************** SETUP ***************************************
  const svgRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupCoords, setPopupCoords] = useState({ x: 0, y: 0 });

  const { t, i18n } = useTranslation();

  const _parseMatrixData = (data) => {
    const parsedData = [];

    // Build xLables to determine position
    const today = moment();
    const dayOfWeek = today.day();
    let labels = t("time.weekdays_short", { returnObjects: true });
    const remDays = labels.splice(dayOfWeek + 1);
    labels = [" "].concat(remDays).concat(labels).concat([" "]);

    // Visited nodes to remove redundancy
    const visitedNodes = [];

    // Actual parsing
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        value.forEach((event) => {
          const date = stringToDate(event.collection_date);
          const pos = 8 - today.diff(date, "days");
          let index = visitedNodes.indexOf(JSON.stringify([pos, key])); // hack pt1
          if (index === -1) {
            // Add the new coordinate if it doesn't exist already
            parsedData.push([
              pos,
              parseInt(key),
              {
                // Backend is returning [null] if there are no extra_symptoms for some reason
                symptoms: event.symptoms?.filter((s) => !!s) || [],
                extra_symptoms: event.extra_symptoms?.filter((s) => !!s) || [],
              },
            ]);
            visitedNodes.push(JSON.stringify([pos, key])); // hack pt2
          } else {
            // If the coordinate does exist, just append the symptoms
            parsedData[index][2].symptoms = parsedData[
              index
            ][2].symptoms.concat(event.symptoms?.filter((s) => !!s) || []);
            parsedData[index][2].extra_symptoms = parsedData[
              index
            ][2].extra_symptoms.concat(
              event.extra_symptoms?.filter((s) => !!s) || []
            );
          }
        });
      });
    }
    return { labels: labels, parsedData: parsedData };
  };
  const eventData = useSelector((state) => state.patientEvents.events);
  const { labels, parsedData } = _parseMatrixData(eventData);

  //* ************************************** HANDLERS ***************************************

  const handleClose = () => {
    setShowPopup(false);
    setPopupData(null);
    setPopupCoords({ x: 0, y: 0 });
  };

  useEffect(() => {
    const makeChart = () => {
      const margin = { top: 0, right: 0, bottom: 40, left: 40 };
      const parentDiv = document.getElementById("event-matrix");

      const width = parentDiv.clientWidth - margin.left - margin.right;
      const height = parentDiv.clientWidth - margin.top - margin.bottom;

      const chart = d3
        .select(svgRef.current)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "event-chart")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .on("click", () => {
          if (
            d3.event.target.class !== ".event-popup" &&
            d3.event.target.id !== "data-circle"
          ) {
            handleClose();
          }
        });

      // Allow me to select last element in selection
      d3.selection.prototype.last = function () {
        return d3.select(this.nodes()[this.size() - 2]);
      };

      // Set background as a rect element
      chart
        .append("rect")
        .attr("class", "background")
        .attr("width", width)
        .attr("height", height)
        .attr("rx", 5);

      // Add background to xAxis
      chart
        .append("rect")
        .attr("class", "background")
        .attr("width", width)
        .attr("height", 22)
        .attr("rx", 5)
        .attr("transform", `translate(0, ${height + 8})`);

      // Add background to yAxis
      chart
        .append("rect")
        .attr("class", "background")
        .attr("width", 22)
        .attr("height", height)
        .attr("rx", 5)
        .attr("transform", `translate(${-margin.left + 10}, 0)`);

      // Add circle at axis intersection

      const axisCircle = chart
        .append("g")
        .attr("transform", `translate(-20, ${height + margin.top + 20})`);

      axisCircle.append("circle").attr("class", "circle-axis background");

      axisCircle
        .append("text")
        .attr("class", "lable-text")
        .attr("text-anchor", "end")
        .attr("transform", "translate(-2, -1)")
        .style("font-size", 10)
        .text(t("time.hour_short"));

      axisCircle
        .append("text")
        .attr("class", "lable-text")
        .attr("text-anchor", "start")
        .attr("transform", "translate(2, 8)")
        .style("font-size", 10)
        .text(t("time.day_short"));

      axisCircle
        .append("line")
        .attr("class", "circle-axis-line")
        .attr("x1", -8)
        .attr("y1", 0)
        .attr("x2", 8)
        .attr("y2", 0)
        .attr("transform", "rotate(-60)");

      const xScale = d3
        .scaleLinear()
        .domain([8 - 7, 8 + 1])
        .range([0, width]);

      const yScale = d3.scaleLinear().domain([-1, 24]).range([height, 0]);

      // Add circle to last tick in xAxis
      chart
        .append("circle")
        .attr("class", "circle-lable")
        .attr("cx", xScale(8) + 0.5)
        .attr("cy", height + 18.5);

      const xAxisGenerator = d3
        .axisBottom(xScale)
        .ticks(8)
        .tickFormat((d, i) => labels[i])
        .tickSizeOuter(0)
        .tickSizeInner(0);

      const yAxisGenerator = d3
        .axisLeft(yScale)
        .tickFormat((d, i) => (d < 24 ? d : ""))
        .tickSizeOuter(0)
        .tickSizeInner(0);

      const xAxis = chart
        .append("g")
        .call(xAxisGenerator)
        .attr("class", "axis")
        .attr("id", "xaxis")
        .attr("transform", `translate(0, ${height + 12})`)
        .call((g) => g.select(".domain").remove());

      const xTickLabels = xAxis.selectAll("g text");

      xTickLabels.attr("class", "lable-text");
      xTickLabels.last().attr("class", "lable-text-last");

      const yAxis = chart
        .append("g")
        .call(yAxisGenerator)
        .attr("class", "axis")
        .attr("text-anchor", "middle")
        .attr("id", "yaxis")
        .attr("transform", `translate(${-17}, 0)`)
        .call((g) => g.select(".domain").remove());

      yAxis.selectAll("g text").attr("class", "lable-text");

      const chartData = chart
        .append("g")
        .selectAll("dot")
        .data(parsedData)
        .enter();

      // Draw data points
      chartData
        .append("circle")
        .attr("cx", function (d) {
          return xScale(d[0]);
        })
        .attr("cy", function (d) {
          return yScale(d[1]);
        })
        .attr("class", "inner-circle");

      chartData
        .append("text")
        .attr("class", "circle-text")
        .style("font-size", "12")
        .attr("dx", function (d) {
          return xScale(d[0]) - 3.5;
        })
        .attr("dy", function (d) {
          return yScale(d[1]) + 4.5;
        })
        .text((d) =>
          d[2].symptoms.length + d[2].extra_symptoms.length > 1
            ? d[2].symptoms.length + d[2].extra_symptoms.length
            : ""
        );

      // Transparent circle to add border with padding
      chartData
        .append("circle")
        .attr("class", "pointer")
        .attr("id", "data-circle")
        .attr("cx", function (d) {
          return xScale(d[0]);
        })
        .attr("cy", function (d) {
          return yScale(d[1]);
        })
        .attr("r", 8)
        .attr("fill", "transparent")
        .attr("stroke", "#508cc0")
        .on("click", function (d, i) {
          setShowPopup(false);
          const clickCoords = d3.mouse(d3.select("#event-chart").node());
          setPopupData(d);
          setPopupCoords({ x: clickCoords[0], y: clickCoords[1] });
          setShowPopup(true);
        });
    };

    makeChart();
  }, []);

  //* ************************************** RENDER ***************************************
  return (
    <div className="event-matrix mr-2" id="event-matrix">
      {parsedData && <svg ref={svgRef}></svg>}
      {showPopup && (
        <EventPopup
          data={popupData}
          position={popupCoords}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default EventsMatrix;
