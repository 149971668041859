import { memo } from "react";

const Separator = ({ id, name, content = "" }) => {
  return (
    <div
      id={id}
      name={name}
      className="ev-mod-separator"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default memo(Separator);
