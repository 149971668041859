//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************
import Spinner from "components/spinner";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const DownloadPdfButton = ({
  pdfFileName,
  pdfDownloadUrl,
  isPdfLoading,
  isDisabled = false,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch()

  const [isButtonHover, setIsButtonHover] = useState(false);

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <a
      className="button small-button tertiary-button"
      href={pdfDownloadUrl}
      download={pdfFileName}
      disabled={isDisabled}
      onMouseEnter={() => {
        setIsButtonHover(true);
      }}
      onMouseLeave={() => {
        setIsButtonHover(false);
      }}
    >
      {isPdfLoading ? (
        <div className="position-relative  p-1">
          <Spinner type={"small-blue"} />
        </div>
      ) : (
        <>
          <Icon
            icon={
              isButtonHover
                ? icons["download-icon-white"]
                : icons["download-icon"]
            }
            className={"mr-1"}
          />
          <span>{t("buttons.download")}</span>
        </>
      )}
    </a>
  );
};

export default DownloadPdfButton;
