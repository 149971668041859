export const MY_PERMISSION_FOR_MKINETIKOS_LICENSE =
  "MY_PERMISSION_FOR_MKINETIKOS_LICENSE";
export const MY_PERMISSION_FOR_MKINETIKOS_LICENSE_SUCCESS =
  "MY_PERMISSION_FOR_MKINETIKOS_LICENSE_SUCCESS";
export const MY_PERMISSION_FOR_MKINETIKOS_LICENSE_FAIL =
  "MY_PERMISSION_FOR_MKINETIKOS_LICENSE_FAIL";

export const getMkinetikosAccessPermission = (institution_id) => ({
  type: MY_PERMISSION_FOR_MKINETIKOS_LICENSE,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/manage/app-license/user-permission/`,
    },
  },
});
