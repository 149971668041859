//* ******************************** REACT NATIVE IMPORTS ********************************
import { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { motion } from "framer-motion";
import { useTranslation, Trans } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import Navbar from "../../components/navbar/";
import MessageModal from "../../components/messageModal";

import PersonalInfoForm from "./partials/personalInfoForm";
import ContactInfoForm from "./partials/contactInfoForm";
import ClinicalDataForm from "./partials/clinicalDataForm";
import IdentificationDocumentsForm from "./partials/identificationDocumentsForm";
import RenewLicenceModal from "../../components/renewLicenceModal";
import Spinner from "../../components/spinner";
import useToast from "hooks/useToast";

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import backButton from "../../assets/images/back-button.svg";
import crossIcon from "../../assets/images/home/close.svg";
import { createPatient } from "../../redux/actions/patients";
import { requestConnections } from "redux/actions/mKinetikos";
import validator from "validator";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

const CreatePatient = (props) => {
  //* *************************************** SETUP ****************************************
  const dispatch = useDispatch();
  const showToast = useToast();
  const { t, i18n } = useTranslation();

  const [currStep, setCurrStep] = useState(1);

  const [personalInfo, setPersonalInfo] = useState({
    full_name: "",
    birth_date: "",
    process_number: "",
    consent_check: false,
  });

  const [contactInfo, setContactInfo] = useState({
    email: "",
    nationality_id: "",
    phone: "",
    telephone: "",
    occupation: "",
    educational_level_id: "",
    marital_status_id: "",
    language_id: "",
  });

  const [clinicalData, setClinicalData] = useState({
    weight: "",
    height: "",
    dominant_side_id: "",
    gender_id: "",
  });

  const [identificationDocuments, setIdentificationDocuments] = useState([
    {
      document_type: "",
      document_id: "",
      text: "",
    },
  ]);

  const [patientId, setPatientId] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [emailModal, setEmailModal] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const [confirmPremium, setConfirmPremium] = useState(false);
  const [patientEmailForPremium, setPatientEmailForPremium] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isCheckingConnections, setIsCheckingConnections] = useState(false);

  const [showConfirmCreatePatientModal, setShowConfirmCreatePatientModal] =
    useState(false);

  const permissions = useSelector((state) => state.permissions);
  //* ************************************** HANDLERS **************************************

  const makePatientBodyRequest = () => {
    const requestBody = {
      ...personalInfo,
      ...contactInfo,
      ...clinicalData,
      ...makeIdentificationDocumentsRequestObject(),
    };

    return requestBody;
  };

  const makeIdentificationDocumentsRequestObject = () => {
    if (
      identificationDocuments.length === 1 &&
      identificationDocuments[0].document_id === "" &&
      identificationDocuments[0].document_type === "" &&
      identificationDocuments[0].text === ""
    ) {
      return { identification_docs: [] };
    } else {
      return { identification_docs: [...identificationDocuments] };
    }
  };

  const validatePatientFormRequest = (e, tabNumber = 1) => {
    e.preventDefault();
    if (showConfirmCreatePatientModal) {
      setShowConfirmCreatePatientModal(false);
    }
    let requestBody = makePatientBodyRequest();
    dispatch(createPatient(requestBody, false))
      .then(() => {
        setCurrStep(tabNumber);
        if (e.target.name === "continue" && currStep < 4) {
          setCurrStep(currStep + 1);
        } else if (e.target.name === "previous") {
          setCurrStep(currStep - 1);
        }
      })
      .catch((error) => {
        const { data } = error.error.response;
        if (data.form_errors) {
          setFormErrors(data.form_errors);
        }
      });
  };

  const createPatientRequest = (e) => {
    e.preventDefault();
    if (needsToShowConfirmCreatePatientModal()) {
      setShowConfirmCreatePatientModal(true);
    } else {
      if (showConfirmCreatePatientModal) {
        setShowConfirmCreatePatientModal(false);
      }
      let requestBody = makePatientBodyRequest();
      dispatch(createPatient(requestBody, true))
        .then((res) => {
          // props.history.push("/home");
          if (
            res.payload.data.data.patient_id &&
            permissions.accessPermissionForLicense
          ) {
            setPatientId(res.payload.data.data.patient_id);
            setConfirmPremium(true);
          } else {
            props.history.push("/home");
          }
        })
        .catch((error) => {
          const { data } = error.error.response;
          if (data.form_errors) {
            setFormErrors(data.form_errors);
          }
        });
    }
  };

  const needsToShowConfirmCreatePatientModal = () => {
    if (!showConfirmCreatePatientModal) {
      if (currStep === 1) {
        if (
          isContactInfoEmpty() ||
          isClinicalDataEmpty() ||
          isIdDocumentsEmpty()
        ) {
          return true;
        }
        return false;
      } else if (currStep === 2) {
        if (isClinicalDataEmpty() || isIdDocumentsEmpty()) {
          return true;
        }
        return false;
      } else {
        if (isIdDocumentsEmpty()) {
          return true;
        }
        return false;
      }
    }
  };

  const isContactInfoEmpty = () => {
    return [
      contactInfo.email === "",
      contactInfo.nationality_id === "",
      contactInfo.phone === "",
      contactInfo.telephone === "",
      contactInfo.occupation === "",
      contactInfo.educational_level_id === "",
      contactInfo.marital_status_id === "",
    ].every(Boolean);
  };

  const isClinicalDataEmpty = () => {
    return [
      clinicalData.weight === "",
      clinicalData.height === "",
      clinicalData.dominant_side_id === "",
      clinicalData.gender_id === "",
    ].every(Boolean);
  };

  const isIdDocumentsEmpty = () => {
    return [
      identificationDocuments[0].document_type === "",
      identificationDocuments[0].document_id === "",
      identificationDocuments[0].text === "",
    ].every(Boolean);
  };

  // PERSONAL INFO

  const personalInfoInputshandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const checkBoxHandler = (e) => {
    if (formErrors.hasOwnProperty("consent_check")) {
      delete formErrors["consent_check"];
    }

    setPersonalInfo({
      ...personalInfo,
      consent_check: e.target.checked,
    });
  };

  const changeBirthDate = (date, e) => {
    if (formErrors.hasOwnProperty("birth_date")) {
      delete formErrors["birth_date"];
    }

    const birth_date = moment(date).format("YYYY-MM-DD");
    setPersonalInfo({ ...personalInfo, birth_date });
  };

  // CONTACT INFO
  const contactInfoInputsHandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value,
    });
  };

  // CLINICAL DATA
  const clinicalDataInputsHandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setClinicalData({
      ...clinicalData,
      [e.target.name]: e.target.value,
    });
  };

  // IDENTIFICATION DOCUMENTS

  const identificationDocumentsInputsHandler = (e, docId) => {
    if (
      formErrors.docs &&
      formErrors.docs[docId] &&
      formErrors.docs[docId].hasOwnProperty(e.target.name)
    ) {
      delete formErrors.docs[docId][e.target.name];
    }

    const value =
      e.target.name === "text" ? e.target.value.slice(0, 20) : e.target.value;

    setIdentificationDocuments(
      identificationDocuments.map((doc, index) =>
        docId === index ? { ...doc, [e.target.name]: value } : doc
      )
    );
  };

  const addDocument = () => {
    setIdentificationDocuments([
      ...identificationDocuments,
      { document_type: "", document_id: "", observations: "" },
    ]);
  };

  const deleteDocument = (index) => {
    setIdentificationDocuments(
      identificationDocuments.filter((_, i) => i !== index)
    );
  };

  const handelPremiumEmailModal = () => {
    if (validator.isEmail(patientEmailForPremium)) {
      setIsValidEmail(true);
      setIsCheckingConnections(true);
      dispatch(requestConnections(patientId, patientEmailForPremium))
        .then(
          (res) => {
            setEmailModal(false);
            props.history.push("/home");
          },
          (err) => {
            // No mkinetikos user with the email provided
            if (
              err.error.response?.data?.msg ===
                "Max number of connections for this package plan" ||
              err.error.response?.data?.msg ===
                "mKinetikos user not found with given email"
            ) {
              setPremiumModal(true);
              setEmailModal(false);
            } else if (
              err.error.response?.data?.msg === "Patient is already connected"
            ) {
              showToast({
                message: t("my_area.mKinetikos.patient_already_connected"),
                type: "info",
              });
            }
          }
        )
        .finally(() => setIsCheckingConnections(false));
    } else {
      setIsValidEmail(false);
    }
  };

  const goToPrevious = (e) => {
    validatePatientFormRequest(e);
  };

  //* ************************************** PARTIALS **************************************

  const _renderCurrentStep = () => {
    if (currStep === 1) {
      return (
        <PersonalInfoForm
          personalInfo={personalInfo}
          personalInfoErrors={formErrors}
          inputsHandler={personalInfoInputshandler}
          checkBoxHandler={checkBoxHandler}
          changeBirthDate={changeBirthDate}
          createPatientRequest={createPatientRequest}
          goToPrevious={goToPrevious}
          validatePatientFormRequest={validatePatientFormRequest}
        />
      );
    } else if (currStep === 2) {
      return (
        <ContactInfoForm
          contactInfo={contactInfo}
          contactInfoErrors={formErrors}
          inputsHandler={contactInfoInputsHandler}
          createPatientRequest={createPatientRequest}
          goToPrevious={goToPrevious}
          validatePatientFormRequest={validatePatientFormRequest}
        />
      );
    } else if (currStep === 3) {
      return (
        <ClinicalDataForm
          clinicalData={clinicalData}
          clinicalDataErrors={formErrors}
          inputsHandler={clinicalDataInputsHandler}
          createPatientRequest={createPatientRequest}
          goToPrevious={goToPrevious}
          validatePatientFormRequest={validatePatientFormRequest}
        />
      );
    } else if (currStep === 4) {
      return (
        <IdentificationDocumentsForm
          identificationDocuments={identificationDocuments}
          identificationDocumentsErrors={formErrors}
          inputsHandler={identificationDocumentsInputsHandler}
          createPatientRequest={createPatientRequest}
          deleteDocument={deleteDocument}
          addDocument={addDocument}
          goToPrevious={goToPrevious}
        />
      );
    }
  };

  const __renderConfirmPremiumModal = () => {
    return (
      <MessageModal
        headerText={
          <>
            <div className="row-space-between p-1">
              <span>{t("home.add_new_patient.premium_licence_msg")}</span>
              <img
                className="cursor-pointer"
                src={crossIcon}
                alt="close pointer"
                onClick={() => {
                  setConfirmPremium(false);
                  props.history.push("/home");
                }}
              />
            </div>
            <div className="hr-border-base"></div>
          </>
        }
        body={t("create_patient.add_premium_msg")}
        footerButtons={[
          <button
            className="button small-button tertiary-button"
            onClick={() => {
              setPremiumModal(false);
              props.history.push("/home");
            }}
          >
            {t("buttons.without_premium")}
          </button>,
          <button
            className="button small-button primary-button"
            onClick={() => {
              setPatientEmailForPremium(contactInfo.email || "");
              setEmailModal(true);
            }}
          >
            {t("buttons.add_premium")}
          </button>,
        ]}
      />
    );
  };
  //* *************************************** RENDER ***************************************
  return (
    <div className="main-wrapper">
      <Navbar blueVersion={true} />
      <div className="row">
        <div className="col-1" />
        <div className="col body-wrapper flex-d-column">
          <h1 className="create_patient_title">
            {t("create_patient.create_header")}
          </h1>
          <div
            className="button back-button-wrapper mb-2 align-self-start d-flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <img alt="back Button" src={backButton} />
            {t("buttons.back")}
          </div>

          <div className="form-auth-wrapper-1 mt-1">
            <div className="form-title-container row">
              <div
                className="col form-title-step"
                onClick={(e) => {
                  validatePatientFormRequest(e, 1);
                }}
              >
                {t("create_patient.main_data.title")}
              </div>
              <div
                className="col form-title-step"
                onClick={(e) => {
                  validatePatientFormRequest(e, 2);
                }}
              >
                <span>
                  {t("create_patient.personal_data.title")}
                  <span className="input-label-text">
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>
              <div
                className="col form-title-step"
                onClick={(e) => {
                  validatePatientFormRequest(e, 3);
                }}
              >
                <span>
                  {t("create_patient.clinical_data.title")}
                  <span className="input-label-text">
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>
              <div
                className="col form-title-step"
                onClick={(e) => {
                  validatePatientFormRequest(e, 4);
                }}
              >
                <span>
                  {t("create_patient.documentation.title")}
                  <span className="input-label-text">
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>

              <motion.div
                className="form-underline"
                initial={{ width: "25%" }}
                animate={{ width: currStep * 25 + "%" }}
                transition={{ duration: 0.5 }}
              />
            </div>

            <div className="form-inside-container">
              <div className="regular-text mb-4">
                <span>
                  <Trans i18nKey={"create_patient.mandatory"}>
                    {[
                      "All fields with ",
                      <span className="warning-color">*</span>,
                      " are mandatory and have to be filled.",
                    ]}
                  </Trans>
                </span>
              </div>
              {_renderCurrentStep()}
            </div>
          </div>
        </div>
        <div className="col-1" />
      </div>
      {confirmPremium && __renderConfirmPremiumModal()}
      {premiumModal && (
        <RenewLicenceModal
          showModal={premiumModal}
          onClose={() => setPremiumModal(false)}
          data={{ patient_id: patientId, email: patientEmailForPremium }}
          renewWithEmail={true}
          customHead={
            <div className="row-space-between">
              <span>{t("home.add_new_patient.premium_licence")}</span>
              <img
                className="cursor-pointer"
                src={crossIcon}
                alt="close pointer"
                onClick={() => {
                  setPremiumModal(false);
                }}
              />
            </div>
          }
          btnName={t("buttons.create_patient")}
          afterOnClickFunction={() => props.history.push("/home")}
        />
      )}
      {showConfirmCreatePatientModal && (
        <MessageModal
          headerText={t("create_patient.create_header")}
          body={t("create_patient.modal_body")}
          footerButtons={[
            <button
              onClick={(e) => {
                validatePatientFormRequest(e, currStep);
              }}
              className="tertiary-button small-button"
            >
              {t("create_patient.add_info")}
            </button>,
            <button
              onClick={(e) => {
                createPatientRequest(e);
              }}
              className="secondary-button small-button"
            >
              {t("buttons.create_patient")}
            </button>,
          ]}
        />
      )}
      {emailModal && (
        <MessageModal
          headerText={
            <div className="row-space-between">
              <span>{t("home.add_new_patient.email_invite_msg")}</span>
              <Icon
                className={"cursor-pointer"}
                icon={icons["cross-icon"]}
                alt="close pointer"
                onClick={() => setEmailModal(false)}
              />
            </div>
          }
          body={
            <div className="email-form">
              <span>
                {isValidEmail ? (
                  t("home.add_new_patient.email_invite")
                ) : (
                  <span className="warning-color show-warning">
                    {t("home.add_new_patient.invalid_email")}
                  </span>
                )}
              </span>
              <input
                className={`form-input mt-1 ${!isValidEmail && "error"}`}
                placeholder={t("placeholders.email")}
                onChange={(e) => setPatientEmailForPremium(e.target.value)}
                value={patientEmailForPremium}
              />
            </div>
          }
          footerButtons={[
            <button
              className="transparent-button small-button"
              onClick={() => setEmailModal(false)}
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              className="button small-button primary-button"
              onClick={handelPremiumEmailModal}
            >
              {!isCheckingConnections ? (
                t("buttons.continue")
              ) : (
                <div className="position-relative p-1">
                  <Spinner type={"small"} />
                </div>
              )}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default CreatePatient;
