//* ****************************************** UTILS *****************************************
import { capitalizeFirstLetter } from "../../../src/utils/string";

const ChatBox = ({ message, sendDate, photo, rightAligned }) => {
  //Defensive;
  if (!message) return null;

  return (
    <div className={`chatbox-container ${rightAligned && "right-aligned"}`}>
      {!rightAligned && (
        <div className="message-icon">
          <img alt="User icon" src={photo} />
        </div>
      )}
      <div className={`message-container ${rightAligned && "right-aligned"}`}>
        <div className={`message ${rightAligned && "right-aligned"}`}>
          <p className={`message-title ${rightAligned && "right-aligned"}`}>
            {capitalizeFirstLetter(message)}
          </p>
        </div>
        <p className="message-sentdate">{sendDate}</p>
      </div>
      {rightAligned && (
        <div className="message-icon">
          <img alt="User icon" src={photo} />
        </div>
      )}
    </div>
  );
};

export default ChatBox;
