export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const GET_MEDICATION_ACTIVITY = "GET_MEDICATION_ACTIVITY";
export const GET_MEDICATION_ACTIVITY_SUCCESS =
  "GET_MEDICATION_ACTIVITY_SUCCESS";
export const GET_MEDICATION_ACTIVITY_FAIL = "GET_MEDICATION_ACTIVITY_FAIL";

export const GET_STEP_ACTIVITY = "GET_STEP_ACTIVITY";
export const GET_STEP_ACTIVITY_SUCCESS = "GET_STEP_ACTIVITY_SUCCESS";
export const GET_STEP_ACTIVITY_FAIL = "GET_STEP_ACTIVITY_FAIL";

export const FILTER_MEDICATION = "FILTER_MEDICATION";
export const RESET_FILTER = "RESET_FILTER";

export const getReport = (patient_id, start_date, end_date) => ({
  type: GET_REPORT,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/report/data/`,
      params: { end_date, start_date },
    },
  },
});

export const getMedicationActivity = (
  patient_id,
  start_date,
  end_date,
  institution_id
) => ({
  type: GET_MEDICATION_ACTIVITY,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/report/medicines/`,
      params: { start_date, end_date, institution_id },
    },
  },
});

export const getStepActivity = (patient_id, start_date, end_date) => ({
  type: GET_STEP_ACTIVITY,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/report/hourly-steps/`,
      params: { start_date, end_date },
    },
  },
});

export const filterMedication = (names) => ({
  type: FILTER_MEDICATION,
  payload: { names },
});
