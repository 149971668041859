/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import EvaluationsModule from "../../../components/patientEvaluationsModule";

//* ************************************** GLOBALS ***************************************

const PatientHome = (props) => {
  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-home-container">
      <EvaluationsModule itemsPerPage={4} history={props.history} />
    </div>
  );
};

export default React.memo(PatientHome);
