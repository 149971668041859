//* *********************************** REACT IMPORTS ************************************
import React from "react";
import { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../../../../components/messageModal";
import Spinner from "../../../../components/spinner";
//* ************************************** GLOBALS ***************************************
import {
  getInstitutionUserInfo,
  updateCollaborator,
  removeCollaborator,
} from "../../../../redux/actions/institutions";
import placeholder_photo from "../../../../assets/images/profile-placeholder.svg";
import backButton from "../../../../assets/images/back-button.svg";
import { errors } from "../../../../constants/general";
import NavBar from "../../../../components/navbar";

const collaboratorInitialState = {
  username: "",
  id: null,
  full_name: "",
  photo: null,
  email: "",
  phone: "",
  role_id: "",
  role_name: "",
  request_user_role: {
    current: false,
    default: false,
    role_id: "",
    role_name: "",
  },
};

const ViewCollaborator = (props) => {
  const dispatch = useDispatch();

  const { institution_id, user_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveCollaboratorModalOpen, setIsRemoveCollaboratorModalOpen] =
    useState(false);
  const [collaboratorData, setCollaboratorData] = useState(
    collaboratorInitialState
  );

  //* ************************************** SETUP **************************************

  useEffect(() => {
    dispatch(getInstitutionUserInfo(institution_id, user_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          setCollaboratorData(res.payload.data.data);
        }
      })
      .catch((error) => {});
  }, []);

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************
  const canEdit =
    collaboratorData.request_user_role.current &&
    collaboratorData.request_user_role.role_id === 1;

  const goBack = () => {
    if (props.location.cameFromApp) {
      props.history.goBack();
    } else {
      props.history.replace(`/my_area/institutions/${institution_id}`);
    }
  };

  const inputsHandler = (e) => {
    setCollaboratorData({ ...collaboratorData, role_id: e.target.value });
  };

  const setCollaboratorRole = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(
      updateCollaborator(institution_id, user_id, collaboratorData.role_id)
    )
      .then((res) => {})
      .catch((error) => {
        alert(errors.service_error);
      })
      .finally(() => setIsLoading(false));
  };

  const removeCollaboratorFromInstitution = () => {
    dispatch(removeCollaborator(institution_id, user_id))
      .then(() => {
        alert(
          "You successfully removed this collaborator from the institution"
        );
        setIsRemoveCollaboratorModalOpen(false);
        goBack();
      })
      .catch(() => {
        alert(errors.service_error);
      });
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.personal_data")} />

      <div style={{ marginTop: "75px" }}>
        <div onClick={goBack} className="button back-button-wrapper mb-1">
          <img src={backButton} alt="backButton" />
          {t("buttons.back")}
        </div>
        <div className="profile-personal-data">
          <form onSubmit={setCollaboratorRole}>
            <div className="row header">
              <div className="col">
                <span className="name">{collaboratorData.full_name}</span>
              </div>
              {canEdit && (
                <div className="col-auto">
                  <button
                    type="button"
                    disabled={isLoading}
                    onClick={() => setIsRemoveCollaboratorModalOpen(true)}
                    className="warning-button small-button"
                  >
                    {t("buttons.remove_from_institution")}
                  </button>
                </div>
              )}
            </div>
            <div className="row body">
              <div className="col-auto left-wrapper">
                <div className="username-image-wrapper">
                  <div className="position-relative">
                    <img
                      alt="Profile"
                      className="profile-image"
                      src={
                        collaboratorData.photo === null
                          ? placeholder_photo
                          : collaboratorData.photo
                      }
                    />
                  </div>
                  <span className="username">{collaboratorData.username}</span>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <label className="input-label-text">
                      <span>{t("user_info.full_name")}</span>
                    </label>
                    <input
                      value={collaboratorData.full_name}
                      disabled={true}
                      autoComplete="off"
                      className="form-input-2"
                      name="full_name"
                      id="full_name"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label className="input-label-text">
                      <span>{t("user_info.email")}</span>
                    </label>
                    <input
                      value={collaboratorData.email}
                      disabled={true}
                      autoComplete="off"
                      className="form-input-2"
                      name="email"
                      id="email"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="input-label-text">
                      <span>{t("user_info.phone")}</span>
                    </label>
                    <input
                      value={
                        collaboratorData.phone === null
                          ? ""
                          : collaboratorData.phone
                      }
                      disabled={true}
                      autoComplete="off"
                      className="form-input-2"
                      name="phone"
                      id="phone"
                    />
                  </div>
                </div>
              </div>
              {canEdit && (
                <div className="col">
                  <label className="input-label-text">
                    {t("user_info.role")}
                  </label>
                  <select
                    disabled={isLoading}
                    value={collaboratorData.role_id}
                    onChange={inputsHandler}
                    className="w-100 dropdown"
                    name="role_id"
                    id="role_id"
                  >
                    <option value={1}>{t("roles.admin")}</option>
                    <option value={2}>{t("roles.clinician")}</option>
                    <option value={3}>{t("roles.secretary")}</option>
                  </select>
                  <div className="flex-d-row align-items-center justify-content-end">
                    <button
                      disabled={isLoading}
                      onClick={goBack}
                      type="button"
                      className="transparent-button margin-right small-button"
                    >
                      <u>{t("buttons.cancel")}</u>
                    </button>
                    <div className="input-image-wrapper m-0">
                      <input
                        disabled={isLoading}
                        type="submit"
                        value={t("buttons.confirm")}
                        className={`secondary-button small-button ${
                          isLoading && "transparent"
                        }`}
                      />
                      {isLoading && <Spinner type={"small"} />}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      {canEdit && isRemoveCollaboratorModalOpen && (
        <MessageModal
          headerText={t("modals.remove_collaborator_header")}
          body={`${t("modals.remove_collaborator_body_1")} ${
            collaboratorData.full_name
          }${t("modals.remove_collaborator_body_2")}`}
          footerButtons={[
            <button
              onClick={() => setIsRemoveCollaboratorModalOpen(false)}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={removeCollaboratorFromInstitution}
              className="small-button warning-button"
            >
              {t("buttons.delete")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default ViewCollaborator;
