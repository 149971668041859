//* ********************************** REACT IMPORTS **********************************
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
import MessageBox from "../../../components/messageBox";

//* ************************************** GLOBALS ***************************************
import { sendSmsToken, multiAuthLogin, backupLogin } from "../../../redux/actions/multiFactorAuth";
import { getCurrentInstitution } from "../../../redux/actions/institutions";
import { getPasswordExpireTime } from "../../../redux/actions/user";
import { useFormValidation } from "../../../hooks/useFormValidation";
import useInterval from "../../../hooks/useInterval";
import { clearRedirectTo } from "redux/actions/redirect";

//* *************************************** STYLES ***************************************

const MultiFactorAuth = ({ navigateToHome, changeToLogin }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { method } = useParams("method");

  const [wasCodeSent, setWasCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [previousMethod] = useState(method);
  const [nextAttempt, setNextAttemp] = useState(30);

  const [isSMSError, setIsSMSError] = useState(false);
  const codeInput = useRef();
  const [backendError, setBackendError] = useState({});

  const redirectTo = useSelector((state) => state.redirectTo.url);

  let waitTime = 30;
  let attempt = 1;

  //* ************************************** HANDLERS **************************************

  useEffect(() => {
    if (method === "sms") {
      handleSendCodeBySms();
    }
    return;
  }, []);

  const autoFocus = () => {
    codeInput.current.focus();
  };

  useInterval(() => {
    if (!isSMSError) {
      if (nextAttempt > 0) {
        setNextAttemp(nextAttempt - 1);
      }
      if (nextAttempt === 0) {
        setWasCodeSent(false);
      }
    }
  }, 1000);

  const handleSendCodeBySms = () => {
    if (!isSendingCode) {
      setIsSendingCode(true);
      dispatch(sendSmsToken()).then(
        (res) => {
          setIsSMSError(false);
          attempt += 1;
          setNextAttemp(waitTime * attempt);
          console.log("setSmsDevice:", res);
        },
        (err) => {
          setIsSMSError(true);
          console.log("setSmsDevice Error:", err);
        }
      );
      setTimeout(() => {
        setIsSendingCode(false);
        setWasCodeSent(true);
      }, 1500);
    }
  };

  const passwordCheck = () => {
    return dispatch(getPasswordExpireTime()).then((res) => {
      if (res.payload.data) {
        const days = res.payload.data.days_until_update;
        /** also check multiFactorAuth.js (line: 66) */
        if (days <= 15) {
          history.push("/password_check", { days: days });
          throw new Error("Update password");
        } else {
          history.replace(redirectTo);
          dispatch(clearRedirectTo());
        }
      }
    });
  };

  const handleAuthSuccess = (res) => {
    passwordCheck()
      .then(() => dispatch(getCurrentInstitution()))
      .then((res) => {
        // Only go to home if there are no pending invitations, but this check should be made in the middleware
        if (res.payload.data.msg !== "user_pending_invitation") {
          navigateToHome();
        } else {
          console.warn("TODO: MOVE THIS CHECK TO THE MIDDLEWARE LATER TO AVOID REDUNDANCY!");
        }
      });
    /* dispatch(getCurrentInstitution());
    dispatch(getPasswordExpireTime()).then((res) => {
      if (res.payload.data) {
        const days = res.payload.data.days_until_update;
        // also check choose_institution (line: 48) 
        if (days <= 15) {
          history.push("/password_check", { days: days });
        } else {
          navigateToHome();
        }
      }
    }); */
  };

  const handleAuthFail = (err) => {
    if (method === "sms" || method === "app") {
      if (err?.error?.response?.status === 400 && err?.error?.response?.data?.msg)
        setBackendError({
          ...backendError,
          [method === "sms" ? "smsCode" : "code"]: t(`form_errors.${err.error.response.data.msg}`),
        });
      else if (err?.error?.response?.status === 404) {
        setBackendError({
          ...backendError,
          [method === "sms" ? "smsCode" : "code"]: t(`form_errors.invalid token or device`),
        });
      }
    } else {
      if (err?.error?.response?.status === 400 && err?.error?.response?.data?.msg)
        setBackendError({
          ...backendError,
          token: t(`form_errors.${err.error.response.data.msg}`),
        });
      else if (err?.error?.response?.status === 404) {
        setBackendError({
          ...backendError,
          token: t(`form_errors.invalid backup token`),
        });
      } else {
        setBackendError({
          ...backendError,
          token: t(`form_errors.generic`),
        });
      }
    }
  };

  const handleSmsAuth = () => {
    dispatch(multiAuthLogin(smsCodeData.smsCode, true)).then(
      (res) => {
        handleAuthSuccess(res);
        setBackendError({});
      },
      (err) => handleAuthFail(err)
    );
  };

  const handleAppAuth = () => {
    dispatch(multiAuthLogin(codeData.code, false)).then(
      (res) => {
        handleAuthSuccess(res);
        setBackendError({});
      },
      (err) => handleAuthFail(err)
    );
  };

  const handleBackupAuth = () => {
    dispatch(backupLogin(tokenData.token)).then(
      (res) => {
        handleAuthSuccess(res);
        setBackendError({});
      },
      (err) => handleAuthFail(err)
    );
  };

  const handleMultiFactorAuth = () => {
    if (method === "sms") handleSmsAuth();
    if (method === "app") handleAppAuth();
    if (method === "backup") handleBackupAuth();
  };

  const renderSmsOption = () => {
    return (
      <>
        <div className="smaller-title mb-3">{t("authentication.mfa.sms_auth")}</div>
        <div className="regular-text mt-3 mb-4">{t("authentication.mfa.send_sms_cord")}</div>
        <form
          onSubmit={(e) => {
            handleSmsCodeSubmit(e);
          }}
        >
          <div className="d-flex align-items-center mb-2">
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              <input
                value={smsCodeData.smsCode}
                autoComplete="off"
                className="form-input mb-0"
                name="smsCode"
                id="smsCode"
                placeholder={t("placeholders.code").toUpperCase()}
                onChange={handleSmsCodeChange("smsCode")}
                maxLength={6}
                style={{
                  cursor: wasCodeSent ? "auto" : "default",
                }}
                ref={codeInput}
                autoFocus
              />
              <div
                className={`regular-text send-code-btn ${
                  wasCodeSent ? (isSMSError ? "warning" : "secondary-color") : "primary-color"
                }`}
                onClick={() => {
                  if (!wasCodeSent) {
                    handleSendCodeBySms();
                  }
                }}
              >
                {isSendingCode ? (
                  <Spinner type="small-blue" />
                ) : wasCodeSent ? (
                  isSMSError ? (
                    t("authentication.mfa.send_failed")
                  ) : (
                    t("authentication.mfa.sent")
                  )
                ) : (
                  t("authentication.mfa.send_code")
                )}
              </div>
            </div>

            <button className="primary-button small-button ml-2" disabled={false} type="submit">
              {t("buttons.confirm")}
            </button>
          </div>
          {smsCodeErrors.smsCode && <MessageBox type={"warning"} message={smsCodeErrors.smsCode} />}
          {backendError.smsCode && <MessageBox type={"warning"} message={backendError.smsCode} />}
        </form>

        <div className="regular-text mt-1">
          {t("authentication.mfa.didnt_receive_sms")}{" "}
          <span
            className="link-text"
            onClick={() => {
              if (!wasCodeSent) {
                handleSendCodeBySms();
                autoFocus();
              } else if (isSMSError) {
                setIsSendingCode(false);
                handleSendCodeBySms();
                autoFocus();
              }
            }}
          >
            {wasCodeSent
              ? isSMSError
                ? t("authentication.mfa.try_again")
                : `${t("authentication.mfa.please_wait")} (${nextAttempt})`
              : t("authentication.mfa.send_again")}
          </span>
          .
        </div>
        <Link to={"/multifactor_auth/backup/"} className="regular-text primary-color">
          {t("authentication.mfa.backup_code")}
        </Link>
      </>
    );
  };

  const renderAppOption = () => {
    return (
      <>
        <div className="smaller-title mb-3">{t("authentication.mfa.app_authenticator")}</div>
        <div className="regular-text mt-3 mb-4">{t("authentication.mfa.open_app_auth")}</div>
        <form
          onSubmit={(e) => {
            handleCodeSubmit(e);
          }}
        >
          <div className="d-flex mt-4">
            <input
              value={codeData.code || ""}
              autoComplete="off"
              className="form-input mb-1"
              name="appCode"
              id="appCode"
              placeholder={t("placeholders.code").toUpperCase()}
              onChange={handleCodeChange("code")}
              maxLength={6}
              autoFocus
            />

            <button
              className="primary-button small-button mb-1 ml-2"
              disabled={false}
              type="submit"
            >
              {t("buttons.confirm")}
            </button>
          </div>
          {codeErrors.code && <MessageBox type={"warning"} message={codeErrors.code} />}
          {backendError.code && <MessageBox type={"warning"} message={backendError.code} />}
        </form>
        <Link to={"/multifactor_auth/backup/"} className="regular-text primary-color">
          {t("authentication.mfa.backup_code")}
        </Link>
      </>
    );
  };

  const renderBackupOption = () => {
    return (
      <>
        <div className="smaller-title mb-3">{t("authentication.mfa.backup_auth")}</div>
        <div className="regular-text mt-3 mb-4">{t("authentication.mfa.backup_codes")}</div>

        <div className="d-flex mt-4">
          <input
            value={tokenData.token}
            autoComplete="off"
            className="form-input mb-1"
            name="backupToken"
            id="backupToken"
            placeholder={t("placeholders.code").toUpperCase()}
            onChange={handleTokenChange("token")}
            maxLength={8}
            autoFocus
          />

          <button
            className="primary-button small-button mb-1 ml-2"
            disabled={false}
            onClick={(e) => {
              handleTokenSubmit(e);
            }}
          >
            {t("buttons.confirm")}
          </button>
        </div>
        {tokenErrors.token && <MessageBox type={"warning"} message={tokenErrors.token} />}
        {backendError.token && <MessageBox type={"warning"} message={backendError.token} />}
        <Link
          to={previousMethod === "sms" ? "/multifactor_auth/sms/" : "/multifactor_auth/app/"}
          className="regular-text primary-color"
        >
          {previousMethod === "sms"
            ? t("authentication.mfa.sms_auth_alt")
            : t("authentication.mfa.app_auth_alt")}
        </Link>
      </>
    );
  };

  const {
    handleSubmit: handleCodeSubmit,
    handleChange: handleCodeChange,
    data: codeData,
    errors: codeErrors,
  } = useFormValidation({
    validations: {
      code: {
        required: {
          value: true,
          message: t("form_erros.required"),
        },
        pattern: {
          value: "[0-9]{6}$",
          message: t("form_errors.six_digits"),
        },
      },
    },
    onSubmit: handleMultiFactorAuth,
  });

  const {
    handleSubmit: handleSmsCodeSubmit,
    handleChange: handleSmsCodeChange,
    data: smsCodeData,
    errors: smsCodeErrors,
  } = useFormValidation({
    validations: {
      smsCode: {
        pattern: {
          value: "[0-9]{6}$",
          message: t("form_errors.six_digits"),
        },
      },
    },
    onSubmit: handleMultiFactorAuth,
  });

  const {
    handleSubmit: handleTokenSubmit,
    handleChange: handleTokenChange,
    data: tokenData,
    errors: tokenErrors,
  } = useFormValidation({
    validations: {
      token: {
        required: {
          value: true,
          message: t("form_errors.required"),
        },
        pattern: {
          value: "[a-z2-9]{8}$",
          message: t("form_errors.eight_digits"),
        },
      },
    },
    onSubmit: handleMultiFactorAuth,
  });

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <h2 className="mb-4">{t("authentication.mfa.authentication")}</h2>
      {method === "sms"
        ? renderSmsOption()
        : method === "app"
        ? renderAppOption()
        : renderBackupOption()}
      <div
        className="d-flex justify-content-center smaller-title mt-4 cursor-pointer"
        onClick={changeToLogin}
      >
        <u>{t("buttons.logout")}</u>
      </div>
    </div>
  );
};

export default MultiFactorAuth;
