import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import lock_input from "../../../../../assets/images/lock-input.svg";
import lock_phone from "../../../../../assets/images/phone-blue.svg";
import institution_default from "../../../../../assets/images/institution-default.svg";
import edit_blue from "../../../../../assets/images/edit-blue-round.svg";
import InsertImageModal from "../../../../../components/insertImageModal";
import { initialState } from "../../../../../redux/reducers/institutions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentInstitution,
  getInstitutionInfo,
  updateInstitutionInfo,
} from "../../../../../redux/actions/institutions";
import { useParams } from "react-router-dom";
import { errors } from "../../../../../constants/general";
import Spinner from "../../../../../components/spinner";
import { useTranslation } from "react-i18next";

const InstitutionInfo = () => {
  const [institutionInfo, setInstitutionInfo] = useState(
    initialState.editing_institution.institution_info
  );

  const institutionReduxInfo = useSelector(
    (state) => state.institutions.editing_institution.institution_info
  );
  const { institution_id } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [institutionInfoErrors, setInstitutionInfoErrors] = useState({});

  const [isInsertImageModalOpen, setIsInsertImageModalOpen] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getInstitutionInfo(institution_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          setInstitutionInfo(res.payload.data.data);
        }
      })
      .catch(() => {
        alert(errors.service_error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const inputsHandler = (e) => {
    if (institutionInfoErrors.hasOwnProperty(e.target.name)) {
      delete institutionInfoErrors[e.target.name];
    }
    setInstitutionInfo({ ...institutionInfo, [e.target.name]: e.target.value });
  };

  // const onChangeCountryCode = (e) => {
  //   if (e.target.value) {
  //     console.log(e.target.value);
  //     setInstitutionInfo({
  //       ...institutionInfo,
  //       [e.target.name]: e.target.value,
  //     });
  //     console.log(institutionInfo);
  //   }
  // };

  const onSaveEditedImage = (photo) => {
    setInstitutionInfo({ ...institutionInfo, photo });
    setIsInsertImageModalOpen(false);
  };

  const onDeleteEditedImage = () => {
    setInstitutionInfo({ ...institutionInfo, photo: null });
    setIsInsertImageModalOpen(false);
  };

  const changeInstitutionInfo = (e) => {
    e.preventDefault();
    setIsSaving(true);
    dispatch(updateInstitutionInfo(institution_id, institutionInfo))
      .then((res) => {
        if (res.payload && res.payload.data) {
          setInstitutionInfo(res.payload.data.data);
          dispatch(getCurrentInstitution());
        }
      })
      .catch((error) => {
        if (error.error.response && error.error.response.data) {
          const { data } = error.error.response.data;
          if (data.form_errors) {
            setInstitutionInfoErrors(data.form_errors);
          }
        }
      })
      .finally(() => setIsSaving(false));
  };

  const canNotSave =
    JSON.stringify(institutionInfo) === JSON.stringify(institutionReduxInfo) ||
    isLoading ||
    isSaving ||
    institutionInfo.email === "";

  return (
    <div className="institution-form-body-wrapper">
      <form onSubmit={changeInstitutionInfo}>
        <div className="row">
          <div className="col">
            <label className="input-label-text">
              {t("institution_info.name")}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                value={institutionInfo.name ? institutionInfo.name : ""}
                disabled={true}
                autoComplete="off"
                className="form-input"
                name="name"
                id="name"
              />
              <img
                alt="Lock"
                className="input-right-image default_cursor"
                data-tip
                data-for="name"
                src={lock_input}
              />
              <ReactTooltip
                className="tooltip"
                id="name"
                place="top"
                effect="solid"
              >
                {t("my_area.institutions.change_name_contact")}
              </ReactTooltip>
            </div>
            <label
              className="d-flex justify-content-between input-label-text"
              htmlFor="birth_date"
            >
              <span>
                {t("institution_info.email")}&nbsp;
                <span className="warning-color">{t("star")}</span>{" "}
              </span>
              {institutionInfoErrors.email && (
                <span className="warning-color">
                  {institutionInfoErrors.email}
                </span>
              )}
            </label>

            <input
              value={institutionInfo.email ? institutionInfo.email : ""}
              onChange={inputsHandler}
              placeholder={t("placeholders.email")}
              type="email"
              autoComplete="off"
              className={`form-input ${institutionInfoErrors.email && "error"}`}
              name="email"
              id="email"
            />

            <label
              htmlFor="telephone"
              className="input-label-text d-flex justify-content-between"
            >
              <span>{t("institution_info.phone")}</span>
              {institutionInfoErrors.phone && (
                <span className="warning-color">
                  {institutionInfoErrors.phone}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right row-space-between">
              {/* <select
                className="country-code-dropdown"
                name="telephone"
                id="telephone_code"
                onChange={(e) => onChangeCountryCode(e)}
              >
                <option value="">----</option>
                <option value={"+351"}>+351</option>
                <option value={"+352"}>+352</option>
                <option value={"+353"}>+353</option>
              </select> */}
              <input
                value={
                  institutionInfo.telephone ? institutionInfo.telephone : ""
                }
                onChange={inputsHandler}
                type="text"
                placeholder={t("placeholders.phone")}
                autoComplete="off"
                className={`form-input ${
                  institutionInfoErrors.phone && "error"
                }`}
                name="telephone"
                id="telephone"
              />
              <img
                alt="Lock"
                className="input-right-image default_cursor"
                data-for="name"
                src={lock_phone}
              />
            </div>
            <label
              htmlFor="address"
              className="input-label-text d-flex justify-content-between"
            >
              <span>{t("institution_info.address")}</span>
              {institutionInfoErrors.address && (
                <span className="warning-color">
                  {institutionInfoErrors.address}
                </span>
              )}
            </label>
            <input
              value={institutionInfo.address ? institutionInfo.address : ""}
              onChange={inputsHandler}
              type="text"
              autoComplete="off"
              placeholder={t("placeholders.address")}
              className={`form-input ${
                institutionInfoErrors.address && "error"
              }`}
              name="address"
              id="address"
            />
            <div className="row">
              <div className="col">
                <label
                  htmlFor="zip_code"
                  className="input-label-text d-flex justify-content-between"
                >
                  <span>{t("institution_info.zip")}</span>
                  {institutionInfoErrors.zip_code && (
                    <span className="warning-color">
                      {institutionInfoErrors.zip_code}
                    </span>
                  )}
                </label>
                <input
                  placeholder={t("placeholders.zip")}
                  value={
                    institutionInfo.zip_code ? institutionInfo.zip_code : ""
                  }
                  onChange={inputsHandler}
                  type="text"
                  autoComplete="off"
                  className={`form-input ${
                    institutionInfoErrors.zip_code && "error"
                  }`}
                  name="zip_code"
                  id="zip_code"
                />
              </div>
              <div className="col">
                <label
                  htmlFor="county"
                  className="input-label-text d-flex justify-content-between"
                >
                  <span>{t("institution_info.county")}</span>
                  {institutionInfoErrors.county && (
                    <span className="warning-color">
                      {institutionInfoErrors.county}
                    </span>
                  )}
                </label>
                <input
                  placeholder={t("placeholders.county")}
                  value={institutionInfo.county}
                  onChange={inputsHandler}
                  type="text"
                  autoComplete="off"
                  className={`form-input ${
                    institutionInfoErrors.county && "error"
                  }`}
                  name="county"
                  id="county"
                />
              </div>
            </div>
            <label
              htmlFor="country"
              className="input-label-text d-flex justify-content-between"
            >
              {t("institution_info.country")}
              {institutionInfoErrors.country && (
                <span className="warning-color">
                  {institutionInfoErrors.country}
                </span>
              )}
            </label>
            <input
              placeholder={t("placeholders.country")}
              value={institutionInfo.country ? institutionInfo.country : ""}
              onChange={inputsHandler}
              type="text"
              autoComplete="off"
              className={`form-input ${
                institutionInfoErrors.country && "error"
              }`}
              name="country"
              id="country"
            />
          </div>

          <div className="col-sm-12 col-lg-6">
            <div className="row">
              <div className="mt-1 col flex-d-column d-flex align-items-center">
                <label className="input-label-text ">
                  {t("institution_info.photo")}
                </label>
                <div className="position-relative">
                  <img
                    className="profile-institution-photo"
                    alt="Institution"
                    src={
                      institutionInfo.photo === null
                        ? institution_default
                        : institutionInfo.photo
                    }
                  />

                  <div className="edit-image">
                    <img
                      onClick={() => setIsInsertImageModalOpen(true)}
                      src={edit_blue}
                      alt="Edit Button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-d-row justify-content-end mt-3">
            <div className="input-image-wrapper m-0">
              <input
                disabled={canNotSave}
                value={t("buttons.save")}
                type="submit"
                className={`secondary-button small-wider-button ${
                  isSaving && "transparent"
                }`}
              />
              {isSaving && <Spinner type={"small"} />}
            </div>
          </div>
        </div>
        {isInsertImageModalOpen && (
          <InsertImageModal
            deletePhoto={onDeleteEditedImage}
            setPhoto={onSaveEditedImage}
            currPhoto={institutionInfo.photo}
            headerText={t("modals.edit_photo")}
            closeModal={() => setIsInsertImageModalOpen(false)}
          />
        )}
      </form>
    </div>
  );
};

export default InstitutionInfo;
