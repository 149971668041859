//import liraries
import React from "react";

// create a component
const MessageBox = ({ type, message, messages }) => {
  return (
    <div className={`message-box ${type}`}>
      {message && <span className={`regular-text ${type}`}>{message}</span>}
      {messages && (
        <>
          {messages?.map(
            (message, index) =>
              message && (
                <div key={index}>
                  <span className={`regular-text ${type}`}>{message}</span>
                  <br />
                </div>
              )
          )}
        </>
      )}
    </div>
  );
};

//make this component available to the app
export default MessageBox;
