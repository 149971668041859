//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import plusBlue from "../../../../assets/images/plus-blue.svg";

const IdentificationDocumentsForm = ({
  identificationDocuments,
  identificationDocumentsErrors,
  inputsHandler,
  editPatientRequest,
  addDocument,
  deleteDocument,
  hasChangedDocs,
  setHasChangedDocs,
  goToPrevious,
}) => {
  const { t, i18n } = useTranslation();
  //* *************************************** RENDER ***************************************
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {identificationDocuments.map((identificationDocument, index) => (
        <div
          className={`${index !== 0 && "form-documents-container"}`}
          key={"identification" + index}
        >
          <div className="form-documents-header-container">
            <div className="semi-bold-medium-title">
              {`${t("create_patient.documentation.document_number")}${
                index + 1
              }`}
            </div>
            <div className="delete-container">
              <button
                type="button"
                onClick={() => {
                  deleteDocument(index);
                  setHasChangedDocs(true);
                }}
                className="button small-wider-button warning-button"
              >
                {t("buttons.delete")}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`document_type-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.document_type")}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].document_type && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].document_type}
                    </span>
                  )}
              </label>
              <select
                value={identificationDocument.document_type}
                onChange={(e) => {
                  inputsHandler(e, index);
                  setHasChangedDocs(true);
                }}
                className={`w-100 dropdown ${
                  identificationDocument.document_type === "" && "notSelected"
                }`}
                name="document_type"
                id={`document_type-${index + 1}`}
              >
                <option value="">{t("placeholders.option")}</option>
                <option value={1}>
                  {t("create_patient.documentation.type_national_id")}
                </option>
                <option value={2}>
                  {t("create_patient.documentation.type_tax_id")}
                </option>
                <option value={3}>
                  {t("create_patient.documentation.type_passport")}
                </option>
              </select>
            </div>
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`document_id-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.document_id")}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].document_id && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].document_id}
                    </span>
                  )}
              </label>
              <input
                autoComplete="off"
                disabled={identificationDocument.document_type === ""}
                onChange={(e) => {
                  inputsHandler(e, index);
                  setHasChangedDocs(true);
                }}
                value={identificationDocument.document_id}
                className={`form-input ${
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].document_id &&
                  "error"
                }`}
                name="document_id"
                id={`document_id-${index + 1}`}
                placeholder={t("placeholders.document_id")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`text-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.observations")}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].text && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].text}
                    </span>
                  )}
              </label>
              <textarea
                as="textarea"
                rows="3"
                autoComplete="off"
                disabled={identificationDocument.document_type === ""}
                onChange={(e) => {
                  inputsHandler(e, index);
                  setHasChangedDocs(true);
                }}
                value={identificationDocument.text}
                className={`form-input ${
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].text &&
                  "error"
                }`}
                name="text"
                id={`text-${index + 1}`}
                placeholder={t("placeholders.observations")}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="row">
        <div>
          <button
            type="button"
            onClick={addDocument}
            className="button small-button tertiary-button"
            disabled={
              identificationDocuments.length > 0 &&
              (identificationDocuments[identificationDocuments.length - 1]
                .document_id === "" ||
                identificationDocuments[identificationDocuments.length - 1]
                  .document_type === "") &&
              identificationDocuments[identificationDocuments.length - 1]
                .delete !== true
            }
          >
            <img
              style={{ marginRight: "13px" }}
              alt="Plus Button"
              src={plusBlue}
            />
            <span>{t("create_patient.documentation.add_document")}</span>
          </button>
        </div>
      </div>
      <div className="form-bottom-container">
        {/* <button
          type="button"
          style={{ marginRight: "1.5em" }}
          onClick={() => goToPrevious()}
          className="button small-button transparent-button"
        >
          <u> previous </u>
        </button> */}
        <button
          type="button"
          style={{ marginRight: "1.5em" }}
          onClick={() => goToPrevious()}
          className="button small-button transparent-button"
        >
          <u> {t("buttons.previous")} </u>
        </button>

        <button
          type="button"
          onClick={(e) => editPatientRequest(e)}
          className="button small-button secondary-button"
          disabled={
            !hasChangedDocs ||
            (identificationDocuments.length > 0 &&
              (identificationDocuments[identificationDocuments.length - 1]
                .document_type === "" ||
                identificationDocuments[identificationDocuments.length - 1]
                  .document_id === ""))
          }
        >
          {t("buttons.save_changes")}
        </button>
      </div>
    </form>
  );
};

export default IdentificationDocumentsForm;
