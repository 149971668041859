//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************

const ClinicalDataForm = ({
  clinicalData,
  clinicalDataErrors,
  inputsHandler,
  createPatientRequest,
  validatePatientFormRequest,
  goToPrevious,
}) => {
  //* *************************************** SETUP ****************************************
  const { t, i18n } = useTranslation();

  //* *************************************** RENDER ***************************************
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="row">
        <div className="col">
          <label className="d-flex justify-content-between" htmlFor="weight">
            <span className="input-label-text">{t("patient_info.weight")}</span>
            {clinicalDataErrors.weight && (
              <span className="input-label-text warning-color">
                {clinicalDataErrors.weight}
              </span>
            )}
          </label>
          <input
            autoComplete="off"
            onChange={inputsHandler}
            value={clinicalData.weight}
            className={`form-input ${clinicalDataErrors.weight && "error"}`}
            id="weight"
            name="weight"
            placeholder={t("placeholders.weight")}
          />
        </div>
        <div className="col">
          <label className="d-flex justify-content-between" htmlFor="height">
            <span className="input-label-text">{t("patient_info.height")}</span>
            {clinicalDataErrors.height && (
              <span className="input-label-text warning-color">
                {clinicalDataErrors.height}
              </span>
            )}
          </label>
          <input
            autoComplete="off"
            onChange={inputsHandler}
            value={clinicalData.height}
            className={`form-input ${clinicalDataErrors.height && "error"}`}
            id="height"
            name="height"
            placeholder={t("placeholders.height")}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label className="d-flex justify-content-between" htmlFor="gender_id">
            <span className="input-label-text">{t("patient_info.gender")}</span>
          </label>
          <select
            value={clinicalData.gender_id}
            onChange={inputsHandler}
            className={`w-100 dropdown ${
              clinicalData.gender_id === "" && "notSelected"
            }`}
            name="gender_id"
            id="gender_id"
          >
            <option value="" hidden>
              {t("placeholders.option")}
            </option>

            <option value={1}>
              {t("create_patient.clinical_data.gender_male")}
            </option>
            <option value={2}>
              {t("create_patient.clinical_data.gender_female")}
            </option>
            <option value={3}>
              {t("create_patient.clinical_data.gender_other")}
            </option>
          </select>
        </div>
        <div className="col">
          <label
            className="d-flex justify-content-between"
            htmlFor="dominant_side_id"
          >
            <span className="input-label-text">
              {t("patient_info.dominant_side")}
            </span>
          </label>

          <select
            value={clinicalData.dominant_side_id}
            onChange={inputsHandler}
            className={`w-100 dropdown ${
              clinicalData.dominant_side_id === "" && "notSelected"
            }`}
            name="dominant_side_id"
            id="dominant_side_id"
          >
            <option value="" hidden>
              {t("placeholders.option")}
            </option>
            <option value={1}>
              {t("create_patient.clinical_data.side_left")}
            </option>
            <option value={2}>
              {t("create_patient.clinical_data.side_right")}
            </option>
            <option value={3}>
              {t("create_patient.clinical_data.side_ambi")}
            </option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="form-bottom-container">
          <button
            name="previous"
            type="button"
            style={{ marginRight: "1.5em" }}
            onClick={(e) => {
              goToPrevious(e);
            }}
            className="button small-button transparent-button under-line"
          >
            {t("previous")}
          </button>
          <button
            name="continue"
            type="button"
            style={{ marginRight: "1em" }}
            onClick={(e) => validatePatientFormRequest(e)}
            className="button small-button tertiary-button"
          >
            {t("buttons.continue")}
          </button>

          <button
            type="button"
            onClick={(e) => createPatientRequest(e, false)}
            className="button small-button secondary-button"
          >
            {t("buttons.create_patient")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClinicalDataForm;
