import React, { useState, useEffect, useRef } from "react";
import search from "../../assets/images/search.svg";
import clinical_data from "../../assets/images/clinical-data.svg";
import questionnaries from "../../assets/images/questionnaries.svg";
import treatments from "../../assets/images/treatments.svg";
import backButton from "../../assets/images/back-button.svg";
import kinematic_tests from "../../assets/images/kinematic-tests.svg";
import { moduleTypes } from "../../constants/general";
import {
  getEvaluationTemplates,
  getEvaluationTemplate,
} from "../../redux/actions/evaluationTemplates";
import { useDispatch, useSelector } from "react-redux";
import { getEvaluationTemplatesModuleList } from "../../redux/actions/evaluationTemplates";

import { useTranslation } from "react-i18next";

const AddModule = ({
  currSelectedModuleType,
  setCurrSelectedModuleType,
  getCurrentModuleTypeImage,
  currModuleTypeList,
  addModule,
  evalutaionModules = false,
  templatesAvailables = true,
  addedModuleIDs,
}) => {
  const { t, i18n } = useTranslation();

  const [areTemplatesLoading, setAreTemplatedLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  const evaluationTemplatesInfo = useSelector(
    (state) => state.evaluationTemplates
  );

  useEffect(() => {
    if (selectedTemplateId) {
      setAreTemplatedLoading(true);
      dispatch(getEvaluationTemplate(selectedTemplateId))
        .then(() => {
          setCurrSelectedModuleType("template");
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => setAreTemplatedLoading(false));
    }
  }, [dispatch, selectedTemplateId]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      // Load template lists at first render
      getCurrEvaluationTemplates();
    } else {
      getModuleTypeList(currSelectedModuleType, searchInput);
    }
  }, [searchInput]);

  const getCurrEvaluationTemplates = () => {
    setAreTemplatedLoading(true);
    dispatch(getEvaluationTemplates())
      .catch((error) => {
        console.log("error");
      })
      .finally(() => setAreTemplatedLoading(false));
  };

  const getModuleTypeList = (moduleType, search) => {
    const newModuleType = moduleType === null ? moduleTypes.all : moduleType;
    dispatch(getEvaluationTemplatesModuleList(newModuleType, search))
      .then((res) => {
        console.log(res);
        if (searchInput === "" && currSelectedModuleType === moduleTypes.all) {
          setCurrSelectedModuleType(null);
        } else {
          setCurrSelectedModuleType(newModuleType);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  return (
    <div className="col">
      <span className="small-title">{t("my_area.templates.add_module")}</span>
      <div className="add-modules-box">
        <span className="regular-text">
          {t("my_area.templates.search_module")}
        </span>
        <div className="search-wrapper">
          <input
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            className="search-input w-100"
            placeholder={t("placeholders.search_for_module")}
          />
          <img alt="search-icon" className="search-icon" src={search} />
        </div>
        {currSelectedModuleType !== null ? (
          <div className="module-type-list">
            <div className="header">
              <div
                className="button back-button-wrapper pb-0"
                onClick={() => {
                  setCurrSelectedModuleType(null);
                  setSelectedTemplateId("");
                }}
              >
                <img src={backButton} alt="backButton" />
                {t("buttons.back")}
              </div>
              {currSelectedModuleType !== moduleTypes.all &&
                currSelectedModuleType !== "template" && (
                  <img
                    alt="Kinematics tests"
                    className="module-image"
                    src={getCurrentModuleTypeImage(currSelectedModuleType)}
                  />
                )}
            </div>
            <ul className="list">
              {currSelectedModuleType !== "template"
                ? currModuleTypeList &&
                  currModuleTypeList.map((module, index) => (
                    <li
                      key={module.name + index.toString()}
                      className="list-item"
                    >
                      {module.name}
                      <button
                        onClick={() => addModule(module)}
                        className="add-button"
                        disabled={
                          addedModuleIDs.includes(module.id) &&
                          !module.allow_multiple
                        }
                      >
                        {t("buttons.add")}
                      </button>
                    </li>
                  ))
                : evaluationTemplatesInfo.currTemplate.modules &&
                  evaluationTemplatesInfo.currTemplate.modules.map(
                    (module, index) => (
                      <li
                        key={module.name + index.toString()}
                        className="list-item"
                      >
                        {module.name}
                        <button
                          onClick={() => addModule(module)}
                          className="add-button"
                          disabled={
                            addedModuleIDs.includes(module.id) &&
                            !module.allow_multiple
                          }
                        >
                          {t("buttons.add")}
                        </button>
                      </li>
                    )
                  )}
              {evaluationTemplatesInfo.module_type_list.length === 0 && (
                <li key={"not found"} className="regular-text list-item">
                  {t("my_area.templates.module_not_found")}
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div>
            <div className="flex-wrap flex-d-row justify-content-center">
              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.kinematicTest, searchInput)
                }
                className="module-type"
              >
                <img alt="Kinematics tests" src={kinematic_tests} />
                <div className="text-bottom">
                  {t("my_area.templates.kinematics_tests")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.clinicalData, searchInput)
                }
                className="module-type"
              >
                <img alt="Clinical Data" src={clinical_data} />

                <div className="text-bottom">
                  {t("my_area.templates.clinical_data")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.questionnarie, searchInput)
                }
                className="module-type"
              >
                <img alt="Questionnaries" src={questionnaries} />
                <div className="text-bottom">
                  {t("my_area.templates.questionnaires")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.intervention, searchInput)
                }
                className="module-type"
              >
                <img alt="Treatments" src={treatments} />
                <div className="text-bottom">
                  {t("my_area.templates.treatments")}
                </div>
              </div>
            </div>
            {templatesAvailables && (
              <>
                <div className="pt-4 pb-2 flex-d-row d-flex justify-content-center regular-text">
                  {t("or")}
                </div>
                <label
                  className="d-flex justify-content-between"
                  htmlFor="prescription_id"
                >
                  <span className="input-label-text">
                    {t("patient.evaluations.select_template")}
                  </span>
                </label>
                <div className="position-relative">
                  <select
                    // onClick={getCurrEvaluationTemplates}
                    value={selectedTemplateId}
                    onChange={(e) => setSelectedTemplateId(e.target.value)}
                    className={`dropdown w-100 d-flex`}
                    name="prescription_id"
                    id="prescription_id"
                  >
                    <option value="">{t("placeholders.option")}</option>

                    {!areTemplatesLoading &&
                      evaluationTemplatesInfo.templates &&
                      evaluationTemplatesInfo.templates.obj_list.map(
                        (template) => (
                          <option
                            // onClick={() => getCurrEvaluationTemplate(template.id)}
                            value={template.id}
                          >
                            {template.name}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddModule);
