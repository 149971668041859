import React from "react";
import Slider from "rc-slider";
import arrow_up_primary from "../../../../../assets/images/arrow-up-primary.svg";
const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Handle value={value} {...restProps}>
      <div className="rc-slider-value-indicator justify-content-center align-items-center flex-d-column d-flex">
        <img alt="arrow" src={arrow_up_primary} />
        <div style={{ marginTop: "8px" }}>{value}s</div>
      </div>
    </Handle>
  );
};

const RiskOfFallSpinner = ({ value, onChange, disabled }) => (
  <>
    <div className="regular-text mr-2">5s</div>
    <div style={{ width: "400px" }} className="regular-text">
      <Slider
        min={5}
        max={20}
        disabled={disabled}
        value={value}
        onChange={(value) => onChange("tug_th", value)}
        defaultValue={0}
        handleStyle={{
          borderColor: "#508CC0",
          width: 20,
          height: 20,
          marginTop: -8,
          boxShadow: "none",
        }}
        trackStyle={{ backgroundColor: "#EFF3F6", height: "4px" }}
        railStyle={{ backgroundColor: "#508CC0", height: "4px" }}
        handle={handle}
      />
    </div>
    <div className="regular-text ml-2">20s</div>
  </>
);

export default React.memo(RiskOfFallSpinner);
