import { useState } from "react";
import MessageModal from "../messageModal";
import Spinner from "../spinner";

import { useTranslation } from "react-i18next";

const ChangeCollaboratorRole = ({
  changeCollaboratorRole,
  user,
  isChangeRoleLoading,
  closeModal,
}) => {
  const [roleId, setRoleId] = useState(user.role_id);
  const isChangeRoleDisabled =
    user.role_id === parseInt(roleId) || isChangeRoleLoading;

  const { t, i18n } = useTranslation();

  const MessageBody = (
    <div>
      <div className="regular-text">
        {t("my_area.institutions.assign_new_role")}
      </div>
      <div className="row mt-4">
        <div className="col-6">
          {t("my_area.institutions.collaborators")}
          <div className="regular-text black-color mt-2">{user.name}</div>
        </div>
        <div className="col">
          <label className="input-label-text">{t("roles.role")}</label>
          <select
            disabled={isChangeRoleLoading}
            value={roleId}
            onChange={(e) => setRoleId(e.target.value)}
            className="w-100 dropdown"
            name="role_id"
            id="role_id"
          >
            <option value={1}>{t("roles.admin")}</option>
            <option value={2}>{t("roles.clinician")}</option>
            <option value={3}>{t("roles.secretary")}</option>
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <MessageModal
      headerText={"Change collaborator role"}
      body={MessageBody}
      footerButtons={[
        <button
          onClick={closeModal}
          className="transparent-button small-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <div className="input-image-wrapper m-0">
          <button
            onClick={() => changeCollaboratorRole(roleId)}
            disabled={isChangeRoleDisabled}
            className="secondary-button small-button"
          >
            {t("buttons.change_role")}
          </button>
          {isChangeRoleLoading && <Spinner type={"small"} />}
        </div>,
      ]}
    />
  );
};

export default ChangeCollaboratorRole;
