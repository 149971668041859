//* *********************************** REACT IMPORTS ************************************
import React, { useEffect } from "react";
import { useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../../components/spinner";
import AddModule from "../../../../components/evaluationTemplates/addModule";
import CurrModules from "../../../../components/evaluationTemplates/currModules";
import SaveTemplateModal from "../../../../components/evaluationTemplates/saveTemplateModal";
import MessageModal from "../../../../components/messageModal";
//* ************************************** GLOBALS ***************************************
import { getPatientEvaluation } from "../../../../redux/actions/patients";
import calendar from "../../../../assets/images/calendar-small.svg";

import edit_blue from "../../../../assets/images/edit-blue.svg";
import clinical_data from "../../../../assets/images/clinical-data.svg";
import questionnaries from "../../../../assets/images/questionnaries.svg";
import treatments from "../../../../assets/images/treatments.svg";
import kinematic_tests from "../../../../assets/images/kinematic-tests.svg";
import big_arrow_left from "../../../../assets/images/big-left-arrow.svg";
import { dateToString } from "../../../../utils/dataAndTime";
import { errors, moduleTypes } from "../../../../constants/general";
const PatientEvaluationShow = (props) => {
  const dispatch = useDispatch();

  const evaluationDetailsInfo = useSelector(
    (state) => state.patientEvaluations.currEvaluation
  );

  const [isLoading, setIsLoading] = useState(false);

  const { patient_id, evaluation_id } = useParams();

  //* ************************************** HANDLERS **************************************
  useEffect(() => {
    dispatch(getPatientEvaluation(patient_id, evaluation_id)).then(
      (res) => {
        console.log("getPatientEvaluation:", res);
      },
      (err) => {
        console.log("getPatientEvaluation ERROR:", err);
      }
    );
  }, []);

  // const goBack = () => {
  //   if (props.location.cameFromEvaluationTemplateHome) {
  //     props.history.goBack();
  //   } else {
  //     props.history.replace("/my_area/evaluation_templates");
  //   }
  // };

  const getCurrentModuleTypeImage = (image) => {
    if (image === moduleTypes.clinicalData) return clinical_data;
    else if (image === moduleTypes.intervention) return treatments;
    else if (image === moduleTypes.kinematicTest) return kinematic_tests;
    else if (image === moduleTypes.questionnarie) return questionnaries;
    return null;
  };
  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body patient-evaluations">
      <div className="flex-d-row align-items-center">
        <img
          onClick={() => props.history.goBack()}
          alt="left arrow"
          className="left-arrow"
          src={big_arrow_left}
        />
        <h4 className="m-0">Show evaluation</h4>
      </div>
      <div className="evaluation-details">
        <div className="row row-space-between align-items-center">
          <div className="col-auto small-title">Evaluation Details</div>
          <div className="col-auto flex-d-row align-items-center">
            <button
              disabled={isLoading}
              // onClick={() => setIsDeleteTemplateModalOpen(true)}
              className="small-button secondary-button mr-1"
            >
              Edit
            </button>
            <button
              disabled={isLoading}
              // onClick={() => setIsDeleteTemplateModalOpen(true)}
              className="small-button primary-button"
            >
              Report
            </button>
          </div>
        </div>
        <div className="mt-2">
          <div className="box">
            <div className="regular-text black-color">
              <div className="row row-space-between">
                <div className="col-auto">
                  <div className="small-title">
                    {evaluationDetailsInfo.evaluation_details.name}
                  </div>
                </div>
              </div>
              <div className="mt-4 flex-d-row regular-text black-color">
                <div className="mr-3">
                  <label className="input-label-text" htmlFor="notes">
                    Creation date
                  </label>
                  <div>{evaluationDetailsInfo.evaluation_details.date}</div>
                </div>
                <div>
                  <label className="input-label-text" htmlFor="notes">
                    Creation by
                  </label>
                  <div>
                    {evaluationDetailsInfo.evaluation_details.created_by}
                  </div>
                </div>
              </div>
              <div className="mt-4 ">
                <label className="input-label-text" htmlFor="notes">
                  Select tags
                </label>
                <div className="days-buttons-wrapper">
                  {/* {evaluationDetailsInfo.tags.map((tag) => (
                    <div className="day-container">{tag.name}</div>
                  ))} */}
                </div>
              </div>
              <div>
                <label className="mt-4 input-label-text" htmlFor="notes">
                  Evaluation date
                </label>
                <div>{evaluationDetailsInfo.evaluation_details.date}</div>
              </div>
              <label className="mt-4 input-label-text" htmlFor="notes">
                Notes
              </label>
              <div> {evaluationDetailsInfo.evaluation_details.comments} </div>
            </div>
          </div>
        </div>

        <div className="row modules-body mt-6">
          <CurrModules
            withOptions={false}
            // deleteModule={(moduleId) => deleteModule(moduleId)}
            modules={evaluationDetailsInfo.summary_status}
            getCurrentModuleTypeImage={(image) =>
              getCurrentModuleTypeImage(image)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(PatientEvaluationShow);
