import {
  GET_PATIENT_EVALUATIONS_FAIL,
  GET_PATIENT_EVALUATIONS_SUCCESS,
  GET_PATIENT_EVALUATION_FAIL,
  GET_PATIENT_EVALUATION_SUCCESS,
  GET_PATIENT_EVALUATION_REPORT_DETAILS_SUCCESS,
  GET_PATIENT_EVALUATION_REPORT_DETAILS_FAIL,
  GET_PATIENT_REPORT_CARD_SUCCESS,
  GET_PATIENT_REPORT_CARD_FAIL,
  DELETE_PATIENT_EVALUATION,
  DELETE_PATIENT_EVALUATION_SUCCESS,
} from "../actions/patients";
import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  evaluations: { obj_list: [], count: 0, page: 0, num_pages: 0 },
  currEvaluation: {
    evaluation_details: {
      id: null,
      name: "",
      comments: "",
      date: "",

      tags: [],
    },
    summary_status: [],
  },
  reportDetails: {
    date: "",
    name: "",
    templates: [],
  },
};

const patientEvaluations = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_EVALUATIONS_SUCCESS: {
      return { ...state, evaluations: { ...action.payload.data.data } };
    }
    case GET_PATIENT_EVALUATIONS_FAIL: {
      return { ...state, evaluations: initialState.evaluations };
    }
    case GET_PATIENT_EVALUATION_SUCCESS: {
      return { ...state, currEvaluation: { ...action.payload.data.data } };
    }
    case GET_PATIENT_EVALUATION_FAIL: {
      return { ...state, currEvaluation: initialState.currEvaluation };
    }
    case GET_PATIENT_EVALUATION_REPORT_DETAILS_SUCCESS: {
      return { ...state, reportDetails: { ...action.payload.data.data } };
    }

    case GET_PATIENT_EVALUATION_REPORT_DETAILS_FAIL: {
      return { ...state, reportDetails: initialState.reportDetails };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default patientEvaluations;
