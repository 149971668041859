import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import ChevronDown from "../../../assets/images/evalModules/chevron-down_base.svg";
import ChevronUp from "../../../assets/images/evalModules/chevron-up_base.svg";
import Selected from "../../../assets/images/evalModules/ok_base.svg";
import RemoveOption from "../../../assets/images/evalModules/close_lightgrey.svg";

const DropList = ({
  id,
  name,
  multipleChoice = false,
  options = [],
  selected = "",
  selectOption = () => {},
  removeOption = () => {},
}) => {
  const [dropListContainerFocus, setDropListContainerFocus] = useState(false);
  const dropListContainerRef = useRef(null);
  const hiddenSelectRef = useRef(null);
  const [extraOptions, setExtraOptions] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // VERY IMPORTANT -> THIS useEffect checks if there was a new option added programatically
    // (via jQuery script) to the select element and adds it to a REACT meaningfull state

    const children = $(`#${id}`).children();
    let extra = [];

    let c = [];
    for (let i = 0; i < children.length; i++) {
      c.push([children[i].value, children[i].innerHTML]);
    }

    for (let i = 0; i < children.length; i++) {
      let exist = false;

      for (let j = 0; j < options.length; j++) {
        if (
          `${options[j][0]}` === `${children[i].value}` ||
          (`${options[j][0]}` === "null" && `${children[i].value}` === "") ||
          (`${options[j][0]}` === "" && `${children[i].value}` === "null") /* &&
          `${options[j][1]}` === `${children[i].innerHTML}` */
        ) {
          exist = true;
          break;
        }
      }

      for (let j = 0; j < extraOptions.length; j++) {
        if (
          `${extraOptions[j][0]}` === `${children[i].value}` ||
          (`${extraOptions[j][0]}` === "null" &&
            `${children[i].value}` === "") ||
          (`${extraOptions[j][0]}` === "" &&
            `${children[i].value}` === "null") /* &&
          `${extraOptions[j][1]}` === `${children[i].innerHTML}` */
        ) {
          exist = true;
          break;
        }
      }

      if (!exist) {
        extra.push([children[i].value, children[i].innerHTML]);
      }
    }

    if (extra.length > 0) {
      setExtraOptions([...extraOptions, ...extra]);
    }
  });

  return (
    <div
      ref={dropListContainerRef}
      onFocus={() => setDropListContainerFocus(true)}
      onBlur={() => setDropListContainerFocus(false)}
      tabIndex={0}
      className="drop-list-container"
      onMouseDown={() => {
        if (dropListContainerFocus) {
          setTimeout(() => {
            dropListContainerRef.current.blur();
          }, 0);
        }
      }}
    >
      <select
        hidden={true}
        id={id}
        value={selected}
        ref={hiddenSelectRef}
        name={name}
        multiple={multipleChoice}
        readOnly
      >
        {options.map((opt, index) => (
          <option key={"select-option-" + index} value={opt[0] ?? ""}>
            {opt[1]}
          </option>
        ))}
      </select>
      <div className="drop-list-selected-wrapper">
        {multipleChoice === false ? (
          <div className={`drop-list-selected-container`}>
            {options.filter((item) => item.includes(selected))?.[0]?.[1] ??
              extraOptions.filter((item) => item.includes(selected))?.[0]?.[1]}
          </div>
        ) : selected.length === 0 ? (
          <div className="drop-list-selected-container">&nbsp;</div>
        ) : (
          selected.map((selectedChoice, index) => (
            <div
              key={index}
              className={`drop-list-selected-container ${
                multipleChoice ? "multiple" : ""
              }`}
            >
              {options.filter((item) =>
                item.includes(selectedChoice)
              )?.[0]?.[1] ??
                extraOptions.filter((item) =>
                  item.includes(selectedChoice)
                )?.[0]?.[1]}
              {multipleChoice && (
                <img
                  alt="Remove Option"
                  src={RemoveOption}
                  className="d-l-remove-selected"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    removeOption(selectedChoice);
                  }}
                />
              )}
            </div>
          ))
        )}
      </div>
      <img
        alt="Open Options"
        src={dropListContainerFocus ? ChevronUp : ChevronDown}
        className="ml-1"
      />
      {dropListContainerFocus && (
        <div className="drop-list-options-container">
          {options.map((option) => (
            <div
              key={`${option[0]}`}
              className={`d-l-option ${
                option[0] === selected ? "selected" : ""
              }`}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (multipleChoice) {
                  if (!selected.includes(option[0])) {
                    selectOption(option);
                  } else {
                    removeOption(option);
                  }
                } else {
                  selectOption(option);
                  setDropListContainerFocus(false);
                }
                setTimeout(() => {
                  $(`#${id}`).trigger("change");
                }, 1);
              }}
            >
              {option[1]}
              {multipleChoice === false
                ? option[0] === selected && (
                    <img alt="Selected" src={Selected} />
                  )
                : selected.includes(option[0]) && (
                    <img alt="Selected" src={Selected} />
                  )}
            </div>
          ))}
          {extraOptions.map((option) => (
            <div
              key={`extra-${option[0]}`}
              className={`d-l-option ${
                option[0] === selected ? "selected" : ""
              }`}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (multipleChoice) {
                  if (!selected.includes(option[0])) {
                    selectOption(option);
                  } else {
                    removeOption(option);
                  }
                } else {
                  selectOption(option);
                }
                setTimeout(() => {
                  $(`#${id}`).trigger("change");
                }, 1);
              }}
            >
              {option[1]}
              {multipleChoice === false
                ? option[0] === selected && (
                    <img alt="Selected" src={Selected} />
                  )
                : selected.includes(option[0]) && (
                    <img alt="Selected" src={Selected} />
                  )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(DropList);
