export const GET_SAVED_EXERCISE_LIST = "GET_SAVED_EXERCISE_LIST";
export const GET_SAVED_EXERCISE_LIST_SUCCESS =
  "GET_SAVED_EXERCISE_LIST_SUCCESS";
export const GET_SAVED_EXERCISE_LIST_FAIL = "GET_SAVED_EXERCISE_LIST_FAIL";

export const GET_EXERCISE_LIST = "GET_EXERCISE_LIST";
export const GET_EXERCISE_LIST_SUCCESS = "GET_EXERCISE_LIST_SUCCESS";
export const GET_EXERCISE_LIST_FAIL = "GET_EXERCISE_LIST_FAIL";

export const GET_EXERCISE_DETAILS = "GET_EXERCISE_DETAILS";
export const GET_EXERCISE_DETAILS_SUCCESS = "GET_EXERCISE_DETAILS_SUCCESS";
export const GET_EXERCISE_DETAILS_FAIL = "GET_EXERCISE_DETAILS_FAIL";

export const CREATE_EXERCISE_DETAILS = "CREATE_EXERCISE_DETAILS";
export const CREATE_EXERCISE_DETAILS_SUCCESS =
  "CREATE_EXERCISE_DETAILS_SUCCESS";
export const CREATE_EXERCISE_DETAILS_FAIL = "CREATE_EXERCISE_DETAILS_FAIL";

export const UPDATE_EXERCISE_DETAILS = "UPDATE_EXERCISE_DETAILS";
export const UPDATE_EXERCISE_DETAILS_SUCCESS =
  "UPDATE_EXERCISE_DETAILS_SUCCESS";
export const UPDATE_EXERCISE_DETAILS_FAIL = "UPDATE_EXERCISE_DETAILS_FAIL";

export const DELETE_EXERCISE_DETAILS = "DELETE_EXERCISE_DETAILS";
export const DELETE_EXERCISE_DETAILS_SUCCESS =
  "DELETE_EXERCISE_DETAILS_SUCCESS";
export const DELETE_EXERCISE_DETAILS_FAIL = "DELETE_EXERCISE_DETAILS_FAIL";

export const getSavedExerciseList = (patient_id, q) => ({
  type: GET_SAVED_EXERCISE_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/exercises`,
      params: { q },
    },
  },
});

export const getExerciseList = (
  patient_id,
  { page = 1, q = "", filter_type = "all", order_by = "date" }
) => ({
  type: GET_EXERCISE_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/physiotherapy/`,
      params: { page, q, filter_type, order_by },
    },
  },
});

export const getExerciseDetails = (patient_id, exercise_details_id) => ({
  type: GET_EXERCISE_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/physiotherapy/${exercise_details_id}/`,
    },
  },
});

export const createExerciseDetails = (patient_id, data) => ({
  type: CREATE_EXERCISE_DETAILS,
  payload: {
    request: {
      method: "post",
      url: `/cdss/patients/${patient_id}/mkinetikos/physiotherapy/`,
      data: data,
      headers: { "Content-type": "multipart/form-data" },
    },
  },
});

export const updateExerciseDetails = (
  patient_id,
  exercise_details_id,
  data
) => ({
  type: UPDATE_EXERCISE_DETAILS,
  payload: {
    request: {
      method: "put",
      url: `/cdss/patients/${patient_id}/mkinetikos/physiotherapy/${exercise_details_id}/`,
      data: data,
    },
  },
});

export const deleteExerciseDetails = (patient_id, exercise_details_id) => ({
  type: DELETE_EXERCISE_DETAILS,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/patients/${patient_id}/mkinetikos/physiotherapy/${exercise_details_id}/`,
    },
  },
});
