//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************
import Styles from "./styles";

//* ***************************************** ASSETS *****************************************
import logo from "assets/images/logo-primary.png";

const BackupCodePdfTemplate = ({
  backupTokens,
  backupCodeGeneratedDateTime,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch()
  const companyName = "Kinetikos Driven Solutions, SA";

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <Document
      title={t("authentication.mfa.backup_codes_btn")}
      author={companyName}
    >
      <Page size="A4" style={Styles.page}>
        <Image src={logo} style={Styles.image} />

        <Text style={Styles.text}>
          {t("authentication.mfa.two_factor_enabled")}
        </Text>

        <View style={Styles.title}>
          <Text style={Styles.boldText}>
            {t("authentication.mfa.backup_codes_btn")}
          </Text>
          <Text style={Styles.subText}>
            {t("authentication.mfa.generated_on", {
              dateTime: backupCodeGeneratedDateTime,
            })}
          </Text>
        </View>
        <View style={Styles.section}>
          {backupTokens?.map((token, index) => {
            return (
              <>
                <Text key={index} style={Styles.backupTokenContainer}>
                  {token}
                </Text>
              </>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default BackupCodePdfTemplate;
