//* ********************************** REACT IMPORTS **********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************
import kinetikosColapsed from "../../../assets/images/logo-colapsed.svg";

const ErrorPage = ({ changeToLogin }) => {
  const { t, i18n } = useTranslation();
  //* ************************************** HANDLERS **************************************

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <h3>{t("authentication.error.something_wrong")}</h3>
      <img
        alt="Kinetikos information"
        className="kinetikos-colapsed"
        src={kinetikosColapsed}
      />

      <div className="form-auth-bottom text-center p-0">
        <div className="auth-bottom-element inline-block p-0">
          <span className="regular-text">
            {t("authentication.error.having_difficulties")}&nbsp;
          </span>
          <span onClick={changeToLogin} className="button link-text">
            {t("authentication.error.back_to_login")}
          </span>
        </div>

        <div className="auth-bottom-element inline-block">
          <a href="mailto:support@kinetikoshealth.com">
            <span className="regular-text">
              {t("authentication.error.problem_persists")}&nbsp;
            </span>
            <span className="button link-text">
              {t("authentication.error.support_center")}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
