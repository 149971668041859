import Routes from "./configs/router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { useEffect, Suspense } from "react";

import { csrfToken, isUserAuthenticated } from "./redux/actions/user";
import { store, persistor } from "./redux/store";
import ToastContextProvider from "./components/toast/context/toastContext";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";

const App = () => {
  useEffect(() => {
    if (
      localStorage.getItem("version") === undefined ||
      localStorage.getItem("version") !== process.env.REACT_APP_PRODUCT_VERSION
    ) {
      // Product version unexistent or different. Force cache clear
      localStorage.removeItem("persist:root");
      localStorage.setItem("version", process.env.REACT_APP_PRODUCT_VERSION);
      window.location.reload();
    }
    store
      .dispatch(csrfToken())
      .then((res) => {
        store
          .dispatch(isUserAuthenticated())
          .catch((error) => console.log(error));
      })
      .catch(() => console.log("error"));
  }, []);

  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <ErrorBoundary>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContextProvider>
              <Routes />
            </ToastContextProvider>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
    </Suspense>
  );
};

export default App;
