//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import { setCurrentInstitution } from "redux/actions/institutions";

//* ************************************* OUR COMPONENTS *************************************
import MessageModal from "components/messageModal";
import Spinner from "components/spinner";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const ChangeInstitutionModal = ({ closeModal, institutionId, goto }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const notificationInstitution = useSelector(
    (state) => state.notifications.notificationCount.by_institution
  );
  const changeToInstitutionName = notificationInstitution?.filter(
    (institution) => institution.id === institutionId
  )[0]?.name;

  //* ************************************** HANDLERS ****************************************
  const onClickYesBtn = () => {
    setIsLoading(true);
    dispatch(setCurrentInstitution(institutionId))
      .then(() => {
        closeModal();
        goto();
      })
      .finally(() => setIsLoading(false));
  };

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <MessageModal
      headerText={t("notifications.change_institution")}
      body={
        <Trans i18nKey={"notifications.change_institution_msg"}>
          <span className="regular-semibold-base-text ">{{ changeToInstitutionName }}</span>
        </Trans>
      }
      footerButtons={[
        <button
          onClick={() => closeModal()}
          type="button"
          className="small-button transparent-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <div className="input-image-wrapper m-0">
          <input
            disabled={isLoading}
            onClick={() => onClickYesBtn()}
            type="submit"
            className={`small-button secondary-button ${isLoading && "transparent"}`}
            value={t("buttons.confirm")}
          />
          {isLoading && <Spinner type={"small"} />}
        </div>,
      ]}
    />
  );
};

export default ChangeInstitutionModal;
