import { memo } from "react";

const SectionTitle = ({ id, name, content = "" }) => {
  return (
    <h6
      id={id}
      name={name}
      className="ev-mod-section-title"
    >{ content }</h6>
  );
};

export default memo(SectionTitle);
