//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************
import { capitalizeFirstLetter } from "utils/string";

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const RiskDetails = ({ riskKey, isTUGAvailable }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const eventList = t("home.events.event_list", { returnObjects: true });

  const riskLabels = [
    capitalizeFirstLetter(t(`home.risk_assessment.mds_risk_levels.normal`)),
    capitalizeFirstLetter(t(`home.risk_assessment.mds_risk_levels.slight`)),
    capitalizeFirstLetter(t(`home.risk_assessment.mds_risk_levels.mild`)),
    capitalizeFirstLetter(t(`home.risk_assessment.mds_risk_levels.moderate`)),
    capitalizeFirstLetter(t(`home.risk_assessment.mds_risk_levels.severe`)),
  ];

  //* ************************************** HANDLERS ****************************************

  //* ************************************** PARTIALS ****************************************
  const modalFields = {
    predicted_tug: {
      body:
        riskKey.val !== null
          ? t("home.risk_assessment.predicted_tug_body")
          : t("home.risk_assessment.no_walk_data_available"),
    },
    predicted_mds_fingertapping: {
      body:
        riskKey.val !== null
          ? `${t("home.risk_assessment.fingertapping_body")}`
          : t("home.risk_assessment.no_fingertapping_data_available"),
      htmlContent: riskKey.val && (
        <div className="regular-text list-container">
          {/* we dont need it for now */}

          {/* {t("home.risk_assessment.for_each_side")}: */}
          {/* <div className="mb-2">
            {riskLabels.map((label, index) => (
              <li
                className={`${
                  index === Math.round(riskKey.val) && "bold-text"
                }`}
              >
                {index}: {label}
              </li>
            ))}
          </div> */}

          {/* end */}
          <div className="citation">
            [1]: [MDS-UPDRS Part III | AMP-PD](
            <a href="https://amp-pd.org/mds-updrs-partIII" target="_blank">
              https://amp-pd.org/mds-updrs-partIII
            </a>
            )
          </div>
        </div>
      ),
    },
    risk_of_fall: {
      body:
        riskKey.val !== null
          ? t("home.risk_assessment.risk_of_fall_body")
          : t("home.risk_assessment.no_walk_data_available"),
    },
    medication_adherence_score: {
      body: t("home.risk_assessment.medication_adherence_body"),
      htmlContent: riskKey.val?.per_medicine && (
        <>
          {Object.keys(riskKey.val.per_medicine).length > 0 &&
            Object.entries(riskKey.val.per_medicine).map(
              ([medicine, score]) => (
                <li>
                  {medicine}: {score}%
                </li>
              )
            )}
        </>
      ),
    },
    predicted_mds_bradikinesya: {
      body:
        riskKey.val !== null
          ? `${t("home.risk_assessment.brady_body")}`
          : isTUGAvailable === null
          ? t("home.risk_assessment.no_walk_data_available")
          : t("home.risk_assessment.no_fingertapping_data_available"),
      htmlContent: riskKey.val && (
        <div className="col regular-text list-container">
          {/* we dont need it for now */}

          {/* <div className="mb-2">
            {riskLabels.map((label, index) => (
              <li
                className={`${
                  index === Math.round(riskKey.val) && "bold-text"
                }`}
                key={index}
              >
                {index}: {label}
              </li>
            ))}
          </div> */}

          {/* end */}
          <div className="citation">
            [1]: [MDS-UPDRS Part III | AMP-PD](
            <a href="https://amp-pd.org/mds-updrs-partIII" target="_blank">
              https://amp-pd.org/mds-updrs-partIII
            </a>
            )
          </div>
          <div className="citation">
            [2]: Erb, M. K. <i>et al.</i> (2020). mHealth and wearable
            technology should replace motor diaries to track motor fluctuations
            in Parkinson's disease. Npj Digital Medicine, 3(1).
          </div>
        </div>
      ),
    },
    numb_alarming_events: {
      body: t("home.risk_assessment.alarming_events_body"),
      htmlContent: (
        <>
          {/* This is always parsed on load regardless of the key, hence the isArray check */}
          {Array.isArray(riskKey.val) &&
            riskKey.val.map((event, index) => (
              <li key={index}>{capitalizeFirstLetter(eventList[event])}</li>
            ))}
        </>
      ),
    },
  };

  //* *************************************** RENDER *****************************************
  return (
    <div
      className={`mt-2 ml-1 regular-text risk-details-container ${
        riskKey.val && "surface-black-color"
      }`}
    >
      {modalFields[riskKey.key] && modalFields[riskKey.key].body}
      <div className="mt-2">
        {modalFields[riskKey.key] && modalFields[riskKey.key].htmlContent}
      </div>
    </div>
  );
};

export default RiskDetails;
