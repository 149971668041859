import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRiskSummary } from "../../../redux/actions/patientRiskSummary";
import Spinner from "../../../components/spinner";

import { Trans, useTranslation } from "react-i18next";
import RiskDetails from "./riskDetails";

import CopyIcon from "../../../assets/images/copy.svg";
import CopyHoverIcon from "../../../assets/images/copy-hover.svg";
import { capitalizeFirstLetter } from "../../../utils/string";
import ReactTooltip from "react-tooltip";
import useToast from "../../../hooks/useToast";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

const RiskAssessment = ({ patientId, color }) => {
  const [isAssessmentLoading, setIsAssessmentLoading] = useState(false);
  const [riskKey, setRiskKey] = useState(null);
  const { t, i18n } = useTranslation();

  const [copyIcon, setCopyIcon] = useState(CopyIcon);
  const [isClickDetails, setIsClickDetails] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const dispatch = useDispatch();
  const copyToast = useToast();

  const riskSummary = useSelector((state) => state.patientRiskSummary);

  const riskLevels = {
    0: "normal",
    1: "slight",
    2: "mild",
    3: "moderate",
    4: "severe",
  };

  const mdsTypes = {
    fingerTapping: "fingertapping",
    bradykinesia: "bradykinesia",
  };

  const riskAssessment = {
    tug: "predicted_tug",
    fingertapping: "predicted_mds_fingertapping",
    medicationScore: "medication_adherence_score",
    bradikinesya: "predicted_mds_bradikinesya",
    fall: "risk_of_fall",
    alarmingEvents: "numb_alarming_events",
  };

  const eventList = t("home.events.event_list", { returnObjects: true });
  const copyEnabled =
    !isAssessmentLoading && Object.keys(riskSummary).length !== 0;
  const isTUGAvailable = riskSummary?.metrics?.predicted_tug;

  useEffect(() => {
    setIsAssessmentLoading(true);
    if (patientId) {
      dispatch(getRiskSummary(patientId))
        .catch((error) => console.log(error))
        .finally(() => {
          setIsAssessmentLoading(false);
        });
    }
  }, [patientId]);

  const goBack = () => {
    setRiskKey(null);
    setIsClickDetails(false);
  };

  const getTitle = (key) => {
    switch (key) {
      case riskAssessment.tug:
        return t("home.risk_assessment.tug");
      case riskAssessment.fingertapping:
        return t("home.risk_assessment.fingertapping");
      case riskAssessment.medicationScore:
        return t("home.risk_assessment.medication_intake");
      case riskAssessment.bradikinesya: {
        return t("home.risk_assessment.brady");
      }
      case riskAssessment.fall: {
        return t("home.risk_assessment.risk_of_fall");
      }
      case riskAssessment.alarmingEvents: {
        return t("home.risk_assessment.alarming_events");
      }
      default:
        <li className="risk-item">{`${t(
          "home.risk_assessment.not_implemented"
        )} -- ${key}`}</li>;
    }
  };

  const copyRiskInfo = () => {
    let riskInfoString =
      "------------------------------------------KINETIKOS RISK ASSESSMENT SUMMARY-----------------------------------------\n\n";
    // Generate the bulk of the info
    riskInfoString += `${document.getElementById("risk-date").innerText}\n\n\n`;
    Object.entries(riskSummary.metrics).forEach(([key, value]) => {
      const element = document.getElementById(`item-${key}`);
      riskInfoString += element.innerText + "\n";
      if (
        (key === "predicted_mds_fingertapping" ||
          key === "predicted_mds_bradikinesya") &&
        value
      ) {
        riskInfoString += `\t${t(
          "home.risk_assessment.predicted_score"
        )} ${value}.\n`;
      }
      if (key === "numb_alarming_events" && value.length !== 0) {
        riskSummary.metrics.numb_alarming_events.forEach(
          (event) =>
            (riskInfoString += `\t${capitalizeFirstLetter(eventList[event])}\n`)
        );
      }
      riskInfoString += `\n`;
    });

    // Generate auxiliary info
    if (
      riskSummary.metrics.predicted_mds_bradikinesya ||
      riskSummary.metrics.predicted_mds_fingertapping
    ) {
      riskInfoString +=
        "-----------------------------------------------ADDITIONAL INFORMATION-----------------------------------------------\n\n";
      if (riskSummary.metrics.predicted_mds_fingertapping) {
        riskInfoString += `${t(
          "home.risk_assessment.fingertapping"
        ).toUpperCase()}\n`;
        riskInfoString += `\t${t(
          "home.risk_assessment.fingertapping_body"
        )} [1].\n`;
      }
      riskInfoString += "\n";
      if (riskSummary.metrics.predicted_mds_bradikinesya) {
        riskInfoString += `${t("home.risk_assessment.brady").toUpperCase()}\n`;
        riskInfoString += `\t${t("home.risk_assessment.brady_body")} [1, 2].\n`;
      }

      // we dont need it for now

      // riskInfoString += `\n${t(
      //   "home.risk_assessment.mds_scale"
      // ).toUpperCase()}\n`;
      // Object.entries(riskLevels).forEach(([key, level]) => {
      //   riskInfoString += `\t${key}: ${capitalizeFirstLetter(
      //     t(`home.risk_assessment.mds_risk_levels.${level}`)
      //   )}\n`;
      // });

      // end

      riskInfoString += "\n";
      riskInfoString += `\n${t(
        "home.risk_assessment.references"
      ).toUpperCase()}\n`;
      riskInfoString +=
        "\t[1]: [MDS-UPDRS Part III | AMP-PD](https://amp-pd.org/mds-updrs-partIII)\n";
      if (riskSummary.metrics.predicted_mds_bradikinesya) {
        riskInfoString +=
          "\t[2]: Erb, M. K. et al. (2020). mHealth and wearable technology should replace motor diaries to track motor fluctuations in Parkinson's disease. Npj Digital Medicine, 3(1).\n";
      }
    }
    navigator.clipboard.writeText(riskInfoString);

    if (!isCopied) {
      copyToast({
        message: t("home.risk_assessment.toast_message"),
        type: "info",
      });
    }
    setIsCopied(true);
  };

  const _calcMdsLevel = (val, type) => {
    switch (type) {
      case mdsTypes.fingerTapping:
        return capitalizeFirstLetter(
          t(
            `home.risk_assessment.mds_risk_levels.${
              riskLevels[Math.round(val / 2)]
            }`
          )
        );
      case mdsTypes.bradykinesia:
        return capitalizeFirstLetter(
          t(
            `home.risk_assessment.mds_risk_levels.${
              riskLevels[Math.round(val / 11)]
            }`
          )
        );
      default:
        return;
    }
  };

  const _renderRiskItem = (key, val) => {
    let riskHtml;
    switch (key) {
      case "predicted_tug":
        riskHtml =
          val && val === 1 ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.tug")}</span>: {val}{" "}
              {t("time.second")} {t("home.risk_assessment.predicted")}
            </li>
          ) : val && val !== null ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.tug")}</span>: {val}{" "}
              {t("time.seconds")} {t("home.risk_assessment.predicted_plural")}
            </li>
          ) : (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.tug")}</span>:{" "}
              {t("home.risk_assessment.no_estimates_made")}{" "}
            </li>
          );
        break;
      case "predicted_mds_fingertapping":
        riskHtml =
          val !== null ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.fingertapping")}</span>:{" "}
              <Trans i18nKey={"home.risk_assessment.level_mds"}>
                {_calcMdsLevel(val, mdsTypes.fingerTapping).toString()}
              </Trans>
            </li>
          ) : (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.fingertapping")}</span>:{" "}
              {t("home.risk_assessment.no_estimates_made")}{" "}
            </li>
          );
        break;
      case "medication_adherence_score":
        riskHtml =
          val && val.all !== null ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.medication_intake")}</span>:{" "}
              {`${val.all}%`}
            </li>
          ) : (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.medication_intake")}</span>:{" "}
              {t("home.risk_assessment.no_reported")}
            </li>
          );
        break;

      case "predicted_mds_bradikinesya": {
        riskHtml =
          val && val !== null ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.brady")}</span>:{" "}
              <Trans i18nKey={"home.risk_assessment.level_mds"}>
                {_calcMdsLevel(val, mdsTypes.bradykinesia).toString()}
              </Trans>
            </li>
          ) : (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.brady")}</span>:{" "}
              {t("home.risk_assessment.no_estimates_made")}
            </li>
          );
        break;
      }
      case "risk_of_fall": {
        const level = t(`home.risk_assessment.fall_risk_levels.${val}`);
        riskHtml =
          val && val !== null ? (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.risk_of_fall")}</span>:{" "}
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </li>
          ) : (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.risk_of_fall")}</span>:{" "}
              {t("home.risk_assessment.no_estimates_made")}
            </li>
          );
        break;
      }
      case "numb_alarming_events": {
        if (val && val.length === 1) {
          riskHtml = (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.alarming_events")}</span>:{" "}
              {val.length} {t("home.risk_assessment.reported")}
            </li>
          );
        } else {
          riskHtml = (
            <li className="risk-item" id={`item-${key}`}>
              <span>{t("home.risk_assessment.alarming_events")}</span>:{" "}
              {val.length} {t("home.risk_assessment.reported_plural")}
            </li>
          );
        }
        break;
      }
      default:
        riskHtml = (
          <li className="risk-item" id={`item-${key}`}>
            <span>Undefined case that should be fixed</span>:{" "}
          </li>
        );
    }

    return (
      riskHtml && (
        <div className="row-space-between mb-1">
          {riskHtml}

          <div
            className="regular-text details cursor-pointer"
            onClick={() => {
              setRiskKey({ key: key, val: val });
              setIsClickDetails(true);
            }}
          >
            {t("buttons.details")}
          </div>
        </div>
      )
    );
  };

  const _renderRiskList = (data) => {
    return (
      <div className="mt-2 ml-1 risk-details-container">
        <div className="regular-text mb-2" id="risk-date">
          {t("home.risk_assessment.following_assessment")}{" "}
          <span>
            {data.report_period.start_date === ""
              ? "---"
              : data.report_period.start_date}
          </span>{" "}
          {t("and")}{" "}
          <span>
            {data.report_period.end_date === ""
              ? "---"
              : data.report_period.end_date}
          </span>
        </div>

        {Object.entries(data.metrics).map(([key, val], i) => {
          return <div key={i}>{_renderRiskItem(key, val)}</div>;
        })}
      </div>
    );
  };

  const toggleCopyIcon = (mode) => {
    if (!isAssessmentLoading)
      setCopyIcon(mode === "enter" ? CopyHoverIcon : CopyIcon);
  };

  return (
    <div
      className="data-card risk-assessment"
      style={{ border: `2px solid ${color ? color : "transparent"}` }}
    >
      <div className="row-space-between">
        <span className="small-title">
          {isClickDetails && riskKey?.key ? (
            <div className="cursor-pointer" onClick={() => goBack()}>
              <Icon icon={icons["back-icon"]} className={"mr-1"} />
              {getTitle(riskKey?.key)}
            </div>
          ) : (
            t("home.risk_assessment.risk_assessment")
          )}
        </span>
        {!isClickDetails && (
          <div
            className={`${copyEnabled && "cursor-pointer"} position-relative`}
          >
            <img
              src={copyIcon}
              alt="Copy Info"
              onMouseEnter={() => toggleCopyIcon("enter")}
              onMouseLeave={() => {
                toggleCopyIcon("leave");
                setIsCopied(false);
              }}
              onClick={() => {
                if (copyEnabled) {
                  copyRiskInfo();
                }
              }}
              data-tip={t("home.risk_assessment.copy_tooltip")}
              data-for="help-tooltip"
            />

            {!isAssessmentLoading && (
              <ReactTooltip
                className="tooltip"
                id="help-tooltip"
                place="top"
                effect="solid"
                getContent={() =>
                  isCopied
                    ? t("home.risk_assessment.copied_tooltip")
                    : t("home.risk_assessment.copy_tooltip")
                }
              />
            )}
          </div>
        )}
      </div>

      {isAssessmentLoading ? (
        <div className="position-relative">
          <Spinner type={"small-blue"} />
        </div>
      ) : Object.keys(riskSummary).length === 0 ? (
        <div className="no-data-text">{t("home.no_data")}</div>
      ) : !isClickDetails ? (
        _renderRiskList(riskSummary)
      ) : (
        <RiskDetails
          riskKey={riskKey}
          goBack={() => setIsClickDetails(false)}
          isTUGAvailable={isTUGAvailable}
        />
      )}
    </div>
  );
};

export default RiskAssessment;
