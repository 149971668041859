import $ from "jquery";
import Spinner from "../components/spinner";
import editIcon from "../assets/images/edit-blue.svg";
import helpIcon from "../assets/images/help.svg";

export function create_card(
  new_card,
  template_order_index,
  card_db_id,
  card_type,
  card_color,
  card_title,
  card_description,
  span_row,
  span_col,
  debug_show,
  debug_url,
  hasEditButton = false
) {
  let card_header = document.createElement("div"),
    title_wrapper = document.createElement("div"),
    title = document.createElement("p"),
    actions = document.createElement("div"),
    help_icon_div = document.createElement("div"),
    help_icon_text = document.createElement("span"),
    debug_icon = document.createElement("span"),
    // delete_card         = document.createElement('span'),
    card_body = document.createElement("div");

  // EDIT BUTTON
  const editButton = document.createElement("div");
  const editImg = document.createElement("img");
  editImg.src = editIcon;
  editButton.appendChild(editImg);

  new_card.classList = "card report-card mb-1"; // + card_type;
  // new_card.setAttribute("position", "relative");
  // new_card.classList.add('block');
  new_card.id = "card-" + template_order_index; //this is important because every card needs an id for the drag and drop and for function calling
  card_header.classList = "line card-header row-space-between";

  // TITLE
  title_wrapper.classList = "flex-d-row small-title";
  title_wrapper.style.position = "relative";
  // title_wrapper.innerHTML = card_icon;
  title.innerText = card_title;
  actions.classList = "actions";
  card_body.classList = "card-body";

  // Help icon
  help_icon_div.classList = "ht ml-1";
  const help_icon = document.createElement("img");
  help_icon.id = "help";
  help_icon.src = helpIcon;
  help_icon_div.appendChild(help_icon);

  // help_icon_div.innerHTML = `<img src=${helpIcon}></img>`;
  /*   help_icon_text.classList = "tooltip";
  help_icon_text.innerText = card_description;
  help_icon_div.appendChild(help_icon_text); */
  // TODO: IMPORTANT NOTE: THIS CLASS IS FROM REPORT.CSS AND IS NOT ON SCSS FILES.
  // TODO: THE CSS MUST BE REVIEWED

  help_icon.addEventListener("mouseover", function (e) {
    const rect = e.target.getBoundingClientRect();
    toogleHelp(card_description, {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  });

  help_icon.addEventListener("mouseout", function (e) {
    toogleHelp(card_description);
  });
  /* help_icon_div
    .querySelector(".help")
    .addEventListener("mouseout", function (e) {
      toogleHelp(card_description);
    }); */

  // Tooltip
  const tooltip = document.createElement("div");
  tooltip.classList = "tooltip-test";
  /* tooltip.setAttribute(
    "style",
    "position: absolute; display:none; background-color:red; z-index: 10"
  ); */
  tooltip.addEventListener("mouseout", function (e) {
    toogleHelp(card_description);
  });
  help_icon_div.appendChild(tooltip);

  // if (debug_show) {
  //   debug_icon.innerHTML = '<i class="icon visible" aria-hidden="true"></i>';
  //   debug_icon.classList = "visible-icon";

  //   debug_icon.addEventListener("click", function (e) {
  //     window.location.href = debug_url;
  //   });
  //   debug_icon.addEventListener("touch", function (e) {
  //     window.location.href = debug_url;
  //   });
  // }

  //
  // delete_card.innerHTML   = '<i class="icon fa fa-times" aria-hidden="true"></i>';
  // delete_card.classList   = 'delete-card-button';
  // delete_card.addEventListener('click', function(e) {
  //     new_card.remove();
  // });
  // delete_card.addEventListener('touch', function(e) {
  //     new_card.remove();
  // });

  title_wrapper.appendChild(title);
  title_wrapper.appendChild(help_icon_div);
  card_header.appendChild(title_wrapper);
  actions.appendChild(debug_icon);
  card_header.appendChild(actions);
  // card_header.appendChild(help_icon_div);
  if (hasEditButton) card_header.appendChild(editButton);
  // new_card.appendChild(delete_card);
  new_card.appendChild(card_header);
  new_card.appendChild(card_body);

  new_card.dataset.card_type = card_type;
  new_card.dataset.card_id = card_db_id;
  // let style = card_color ? `grid-area: span ${span_row} / span ${span_col}; border-left-color: ${card_color}` : `grid-area: span ${span_row} / span ${span_col};`;
  let style = card_color
    ? `grid-area: span ${span_row} / span ${span_col};`
    : `grid-area: span ${span_row} / span ${span_col};`;
  new_card.setAttribute("style", style);

  function toogleHelp(message, state = undefined) {
    const currentTooltip = document
      //.querySelector(`#card-${template_order_index}`)
      .querySelector(`[data-card_id="${card_db_id}"]`)
      .querySelector(".tooltip-test");
    if (state) {
      currentTooltip.style.display = "block";
      currentTooltip.style.top = state.y + "px";
      currentTooltip.style.left = state.x + "px";
      currentTooltip.innerText = message;
    } else {
      currentTooltip.style.display = "none";
    }
  }
}

function build_no_info_card(card, data) {
  let card_body = card.querySelector(".card-body");
  let main_value = document.createElement("span");
  let division = document.createElement("span");
  let description = document.createElement("span");

  card.classList.add("error-card");
  main_value.classList = "main-value";
  division.classList = "division";
  description.classList = "description";

  // main_value.innerHTML = `<i class="icon fa fa-exclamation-triangle" aria-hidden="true"></i>`;
  description.innerText = data;

  card_body.appendChild(main_value);
  card_body.appendChild(division);
  card_body.appendChild(description);
}

function create_activities_button(
  actions_div,
  activity,
  hidden = false,
  disabled = false,
  off = true
) {
  let button = document.createElement("div");
  button.setAttribute("role", "button");
  button.classList = "action";
  // TODO: icons does not exist
  button.innerHTML = {
    zoom: `<i class="icon zoom-in" aria-hidden="true"></i>`,
    drag: `<i class="icon move" aria-hidden="true"></i>`,
    tag: `<i class="icon tag" aria-hidden="true"></i>`,
    save: `<i class="icon save" aria-hidden="true"></i>`,
    undo: `<i class="icon arrow-back" aria-hidden="true"></i>`,
    exit: `<i class="icon close" aria-hidden="true"></i>`,
    hide: `<i class="icon hidden" aria-hidden="true"></i>`,
    show: `<i class="icon visible" aria-hidden="true"></i>`,
    copy: `<i class="icon copy" aria-hidden="true"></i>`,
    chart: `<i class="icon pie-chart" aria-hidden="true"></i>`,
    list: `<i class="icon list" aria-hidden="true"></i>`,
    reset_zoom: `<i class="icon refresh" aria-hidden="true"></i>`,
    download: `<i class="icon download" aria-hidden="true"></i>`,
    delete: `<i class="icon delete" aria-hidden="true"></i>`,
  }[activity];

  if (hidden) button.style.display = "none";
  if (disabled) button.disabled = true;
  if (off) button.classList.add("disabled");

  actions_div.appendChild(button);
  return button;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* OVERLAY * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
// add the necessary event listeners, for scroll, touch and click to the overlay
$(document).ready(function () {
  // let overlay_card = document.getElementById("overlay-card");
  // overlay_card.addEventListener("click", toogleShow, false);
  // overlay_card.addEventListener("touch", toogleShow, false);
  // function toogleShow() {
  //   this.classList.toggle("closed");
  //   let container_overlay = "overlay-card-container";
  //   toogleOverlayByID(container_overlay);
  // }
});

function toogleOverlayByID(id) {
  // document.getElementById(id).classList.toggle('show');
  document.getElementById(id).classList.toggle("closed");
}

function append_card_to_overlay(details_card_id) {
  let container_id = "overlay-card-container",
    container = document.getElementById(container_id);
  container.innerHTML = "";
  // append_new_card(container, details_card_id, "overlay");
  let card = container.querySelector("div");
  card.addEventListener("click", stop_propagation, false);
  card.addEventListener("touch", stop_propagation, false);
  card.addEventListener("scroll", stop_propagation, false);

  toogleOverlayByID(container_id);
  let container_overlay = "overlay-card";
  toogleOverlayByID(container_overlay);
}

function stop_propagation(e) {
  e.stopPropagation();
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* LOADER FUNCTIONS  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
// function create_loader(card) {
//   let loader = document.createElement("div"),
//     innerLoader = document.createElement("div");
//   loader.classList = "loader-div";
//   innerLoader.classList = "loader";
//   loader.append(innerLoader);
//   card.append(loader);
// }

// function stop_loader(parent_div_id, elem_class) {
//   let loaders =
//     elem_class !== undefined
//       ? document.querySelectorAll(
//           `#${parent_div_id} .${elem_class} .loader-div`
//         )
//       : document.querySelectorAll(`#${parent_div_id} .loader-div`);
//   [].forEach.call(loaders, (loader) => {
//     loader.style.display = "none";
//   });
// }
