import React from "react";
import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { useTranslation, Trans } from "react-i18next";

import PasswordExpiredForm from "./partials/passwordExpiredForm";
import CustomMessage from "../authentication/partials/customMessage";
import CompanyInfo from "../../components/companyInfo";

import { getPasswordExpireTime } from "../../redux/actions/user";
import Spinner from "components/spinner";

import logo from "../../assets/images/logo-primary.svg";
import snIcon from "../../assets/images/sn.svg";
import backButton from "../../assets/images/back-button.svg";
import { getCurrentInstitution } from "redux/actions/institutions";

const product_version = process.env.REACT_APP_PRODUCT_VERSION;

const PasswordExpired = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [passwordExpireTime, setPasswordExpireTime] = useState(props.location.state?.days);
  const [changePassword, setChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectScreen = {
    timeReminder: "timeReminder",
    changePassword: "changePassword",
    errorPage: "errorPage",
    successResetPassword: "successResetPassword",
  };

  useEffect(() => {
    if (!passwordExpireTime) {
      setIsLoading(true);
      dispatch(getPasswordExpireTime())
        .then((res) => {
          if (res.payload.data) {
            setPasswordExpireTime(res.payload.data?.days_until_update);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const navigateToHome = () => props.history.push("/home");

  const onContinueToHome = () =>
    dispatch(getCurrentInstitution()).then((res) => {
      // Only go to home if there are no pending invitations, but this check should be made in the middleware
      if (res.payload.data.msg !== "user_pending_invitation") {
        navigateToHome();
      } else {
        console.warn("TODO: MOVE THIS CHECK TO THE MIDDLEWARE LATER TO AVOID REDUNDANCY!");
      }
    });

  return isLoading ? (
    <Spinner type={"big-blue"} />
  ) : (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        <div className="row justify-content-between">
          <div className="col-auto">
            <img className="logo" src={logo} alt="Logo" />
          </div>
          <div className="col-auto">
            <div>
              <img className="mr-1" alt="SN" src={snIcon} />
              <span className="regular-text text--grey-intense">{product_version}</span>
            </div>
          </div>
        </div>

        <div className="row create-institution-wrapper">
          <div className="col"></div>
          <div className="col-sm-8 col-lg-4">
            {changePassword ? (
              <div
                className="button back-button-wrapper pb-1"
                onClick={() => {
                  setChangePassword(false);
                }}
              >
                <img src={backButton} alt="backButton" />
                {t("buttons.back")}
              </div>
            ) : (
              <></>
            )}

            {changePassword ? (
              <PasswordExpiredForm showText={false} />
            ) : (
              <div className="create-institution-box">
                <div className="semi-bold-medium-title mb-5 text-center">
                  {passwordExpireTime && passwordExpireTime > 0 ? (
                    <>
                      {t("passwordExpired.msg")}
                      <u>
                        {passwordExpireTime?.toString()} {t("passwordExpired.days")}
                      </u>
                    </>
                  ) : (
                    props.history.push("/password_expired")
                  )}
                </div>
                {passwordExpireTime && passwordExpireTime > 0 && (
                  <button
                    className="mt-2 w-100 small-button primary-button"
                    onClick={onContinueToHome}
                  >
                    {t("passwordExpired.Continue_platform")}
                  </button>
                )}

                <button
                  className="mt-2 w-100 small-button background-button"
                  onClick={() => setChangePassword(true)}
                >
                  {t("authentication.reset_password.change_password")}
                </button>
              </div>
            )}
          </div>

          <div className="col"></div>
        </div>
        <div className="row h-100">
          <div className="col kinetikos-bottom-information-wrapper">
            <CompanyInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordExpired;
