import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FingerTapping from "./sliders/fingerTapping";
import BradykinesiaSlider from "./sliders/bradykinesia";
import TugSlider from "./sliders/tug";
import RiskOfFallSlider from "./sliders/riskOfFall";
import MedicationAdherenceSlider from "./sliders/medicationAdherence";
import {
  getPatientRiskAssesment,
  updatePatientRiskAssesment,
  removePatientRiskAssesment,
} from "../../../../redux/actions/riskAlert";
import { useDispatch } from "react-redux";
import { errors } from "../../../../constants/general";
import MessageModal from "../../../../components/messageModal";
import Spinner from "../../../../components/spinner";

import { useTranslation } from "react-i18next";

const PatientCustomization = ({ patientId, close }) => {
  const dispatch = useDispatch();

  const notificationsData = useSelector(
    (state) => state.riskAlert.customizing_patient_settings
  );

  const [isLoading, setIsLoading] = useState(true);
  const [openRemoveCustomizationModal, setOpenRemoveCustomizationModal] =
    useState(false);
  const [openCancelChangesModal, setOpenCancelChangesModal] = useState(false);

  const [thresholds, setThresholds] = useState(notificationsData.thresholds);
  const [selectedEvents, setSelectedEvents] = useState({
    alerts: [],
    symptoms: [],
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getPatientRiskAssesment(patientId))
      .catch((error) => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setThresholds(notificationsData.thresholds);
    setSelectedEvents(notificationsData.events);
  }, [notificationsData]);

  const updateRiskAlertSettings = () => {
    setIsLoading(true);

    const newALertsList = selectedEvents.alerts
      .filter((alert) => alert.is_selected && alert)
      .map((newAlert) => newAlert.id);

    const newSymptomsList = selectedEvents.symptoms
      .filter((symptom) => symptom.is_selected && symptom)
      .map((newSymptom) => newSymptom.id);

    const data = {
      ...thresholds,
      alerts: newALertsList,
      symptoms: newSymptomsList,
    };

    dispatch(updatePatientRiskAssesment(patientId, data))
      .then(() =>
        dispatch(getPatientRiskAssesment(patientId))
          .then(() => close())
          .catch(() => alert(errors.service_error))
      )
      .catch(() => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  };

  const removeCustomization = () => {
    setIsLoading(true);

    dispatch(removePatientRiskAssesment(patientId))
      .then(() =>
        dispatch(getPatientRiskAssesment(patientId)).catch(() =>
          alert(errors.service_error)
        )
      )
      .catch(() => alert(errors.service_error))
      .finally(() => {
        setOpenRemoveCustomizationModal(false);
        setIsLoading(false);
      });
  };

  const thresholdsInputHandler = (name, value) => {
    setThresholds({ ...thresholds, [name]: value });
  };

  const alertsHandler = (id) => {
    const newList = selectedEvents.alerts.map((alert) =>
      alert.id === id ? { ...alert, is_selected: !alert.is_selected } : alert
    );
    setSelectedEvents({ ...selectedEvents, alerts: newList });
  };

  const symptomsHandler = (id) => {
    const newList = selectedEvents.symptoms.map((symptom) =>
      symptom.id === id
        ? { ...symptom, is_selected: !symptom.is_selected }
        : symptom
    );
    setSelectedEvents({ ...selectedEvents, symptoms: newList });
  };

  const whereChangesmade =
    JSON.stringify(notificationsData.thresholds) !==
      JSON.stringify(thresholds) ||
    JSON.stringify(notificationsData.events) !== JSON.stringify(selectedEvents);

  return (
    <div className="background-modal-container">
      <div className="message-modal-container">
        <div className="profile-notification-customization">
          <div className="row align-items-center">
            <div className="col mt-1">
              <h4>
                {t("titles.customize_alerts")}
                <span className="regular-text medium">
                  <b>
                    {notificationsData.patient_name
                      ? ` > ${notificationsData.patient_name}`
                      : ""}
                  </b>
                </span>
              </h4>
            </div>
            <div className="col-auto justify-content-end mt-1">
              <button
                disabled={isLoading}
                onClick={() => {
                  if (whereChangesmade) {
                    setOpenCancelChangesModal(true);
                  } else {
                    close();
                  }
                }}
                type="button"
                className="transparent-button small-wider-button mr-1"
              >
                <u>{t("buttons.cancel")}</u>
              </button>
              <button
                disabled={isLoading || !whereChangesmade}
                type="button"
                className="secondary-button small-wider-button"
                onClick={updateRiskAlertSettings}
              >
                {t("buttons.save")}
              </button>
            </div>
          </div>
          <div className="regular-text mt-3">
            {t("my_area.risk.customize_alerts")}
          </div>

          <div className="mt-6">
            <div className="medium-semibold-text black-color">
              {t("my_area.risk.remove_customization")}
            </div>

            <button
              onClick={() => setOpenRemoveCustomizationModal(true)}
              disabled={notificationsData.tag === "default" || isLoading}
              className="mt-2 small-button warning-button"
            >
              {t("buttons.remove")}
            </button>
          </div>
          <div className="mt-6">
            <div className="medium-semibold-text black-color">
              {t("my_area.risk.finger_tapping")}
            </div>

            <div className="profile-notifications-box mt-2">
              <div className="regular-text">
                {t("my_area.risk.finger_tapping_explanation")}
                <a
                  href="https://www.nature.com/articles/s41746-019-0214-x"
                  className="link-text"
                >
                  {t("my_area.risk.here")}
                </a>
              </div>
              <FingerTapping
                disabled={isLoading}
                onChange={thresholdsInputHandler}
                value={thresholds.mds_fingertapping_th}
              />
            </div>
            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.brady")}
              </div>
              <div className="profile-notifications-box mt-2 ">
                <div className="regular-text">
                  {t("my_area.risk.brady_explanation")}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.nature.com/articles/s41746-019-0214-x"
                    className="link-text"
                  >
                    {t("my_area.risk.here")}
                  </a>
                </div>
                <div className="flex-d-row mt-4 mb-4">
                  <BradykinesiaSlider
                    disabled={isLoading}
                    onChange={thresholdsInputHandler}
                    value={thresholds.bradikinesia_th}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.tug")}
              </div>
              <div className="profile-notifications-box mt-2">
                <div className="flex-d-row mt-4 mb-4">
                  <TugSlider
                    disabled={isLoading}
                    onChange={thresholdsInputHandler}
                    value={thresholds.tug_th}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.risk_fall")}
              </div>

              <div className="profile-notifications-box mt-2">
                <div className="flex-d-row mt-4 mb-4">
                  <RiskOfFallSlider
                    disabled={isLoading}
                    onChange={thresholdsInputHandler}
                    value={thresholds.risk_of_fall_th}
                  />
                </div>
              </div>

              <div className="mt-6">
                <div className="medium-semibold-text black-color">
                  {t("my_area.risk.adherence")}
                </div>
                <div className="profile-notifications-box mt-2">
                  <div className="flex-d-row mt-4 mb-4">
                    <MedicationAdherenceSlider
                      disabled={isLoading}
                      onChange={thresholdsInputHandler}
                      value={thresholds.medication_adherence_th}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="medium-semibold-text black-color">
                  {t("my_area.risk.episodes")}
                </div>
                <div className="profile-notifications-box mt-2">
                  <div className="regular-text">
                    {t("my_area.risk.episodes_explanation")}
                  </div>
                  <div style={{ flexWrap: "wrap" }} className="flex-d-row mt-4">
                    {selectedEvents.alerts.map((episode, index) => (
                      <div
                        key={episode.name + index.toString()}
                        className="check-box-wrapper mr-4"
                      >
                        <div className="input-wrapper">
                          <input
                            disabled={isLoading}
                            name={episode.name}
                            checked={episode.is_selected}
                            onChange={() => alertsHandler(episode.id)}
                            type={"checkbox"}
                          />
                        </div>
                        <label htmlFor={episode.name} type="checkbox">
                          <span className="regular-text">
                            {t("my_area.risk." + episode.name)}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="medium-semibold-text black-color">
                  {t("my_area.risk.symptoms")}
                </div>
                <div className="profile-notifications-box mt-2">
                  <div className="regular-text">
                    {t("my_area.risk.symptoms_explanation")}
                  </div>

                  <div className="regular-text black-color mt-4">
                    {t("my_area.risk.symptoms_occurence")}
                  </div>

                  <div className="profile-notifications-input-group-plus-minus mt-2">
                    <input
                      disabled={isLoading}
                      type="button"
                      value="-"
                      onClick={(e) => {
                        if (parseInt(thresholds.events_count_th) > 0)
                          setThresholds({
                            ...thresholds,
                            events_count_th:
                              parseInt(thresholds.events_count_th) - 1,
                          });
                      }}
                      className="button-minus plus-minus"
                      data-field="quantity"
                    />
                    <input
                      style={{ backgroundColor: "white" }}
                      disabled={true}
                      type="number"
                      step="1"
                      min="0"
                      max="100"
                      value={thresholds.events_count_th}
                      name="events_count_th"
                      className="quantity-field text-center"
                    />
                    <input
                      disabled={isLoading}
                      onClick={() =>
                        setThresholds({
                          ...thresholds,
                          events_count_th:
                            parseInt(thresholds.events_count_th) + 1,
                        })
                      }
                      type="button"
                      value="+"
                      className="button-plus  plus-minus"
                      data-field="quantity"
                    />
                  </div>
                  <div className="regular-text black-color mt-4">
                    {t("my_area.risk.symptoms")}
                  </div>
                  <div style={{ flexWrap: "wrap" }} className="flex-d-row mt-2">
                    {selectedEvents.symptoms.map((symptom, index) => (
                      <div
                        key={symptom.name + index.toString()}
                        className="check-box-wrapper mr-4"
                      >
                        <div className="input-wrapper col-md-auto">
                          <input
                            disabled={isLoading}
                            name={symptom.name}
                            checked={symptom.is_selected}
                            onChange={() => symptomsHandler(symptom.id)}
                            type={"checkbox"}
                          />
                        </div>
                        <label htmlFor={symptom.name} type="checkbox">
                          <span className="regular-text">
                            {t("my_area.risk.symptoms_list." + symptom.name)}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openRemoveCustomizationModal && (
        <MessageModal
          headerText={t("modals.remove_customization_header")}
          body={t("modals.remove_customization_body")}
          footerButtons={[
            <button
              onClick={() => setOpenRemoveCustomizationModal(null)}
              className="transparent-button small-button"
            >
              {t("buttons.cancel")}
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                type="button"
                disabled={isLoading}
                value={t("buttons.confirm")}
                onClick={() => removeCustomization()}
                className={`small-wider-button secondary-button ${
                  isLoading && "transparent"
                }`}
              />
              {isLoading && <Spinner type={"small"} />}
            </div>,
          ]}
        />
      )}
      {openCancelChangesModal && (
        <MessageModal
          headerText={t("modals.cancel_changes_header")}
          body={t("modals.cancel_changes_body")}
          footerButtons={[
            <button
              onClick={() => setOpenCancelChangesModal(false)}
              className="transparent-button small-button"
            >
              {t("buttons.cancel")}
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                type="button"
                disabled={isLoading}
                value={t("buttons.confirm")}
                onClick={() => close()}
                className={`small-wider-button secondary-button ${
                  isLoading && "transparent"
                }`}
              />
              {isLoading && <Spinner type={"small"} />}
            </div>,
          ]}
        />
      )}
    </div>
  );
};
export default React.memo(PatientCustomization);
