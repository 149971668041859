export const removeRepetitions = (array) =>
  array.filter((item, pos) => array.indexOf(item) === pos);

export const removeRepetitionsByKey = (array, key) => {
  var seen = {};
  return array.filter((item) => {
    var k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
};
