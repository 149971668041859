export const GET_EVALUATION_TAGS = "GET_EVALUATIONS_TAGS";
export const GET_EVALUATION_TAGS_SUCCESS = "GET_EVALUATIONS_TAGS_SUCCESS";
export const GET_EVALUATION_TAGS_FAIL = "GET_EVALUATIONS_TAGS_FAIL";

export const getEvaluationTags = () => ({
  type: GET_EVALUATION_TAGS,
  payload: {
    request: {
      method: "get",
      url: "/cdss/evaluation/tags/",
    },
  },
});
