export const GET_RISK_ALERT = "GET_RISK_ALERT";
export const GET_RISK_ALERT_SUCCESS = "GET_RISK_ALERT_SUCCESS";
export const GET_RISK_ALERT_FAIL = "GET_RISK_ALERT_FAIL";

export const UPDATE_RISK_ALERT = "UPDATE_RISK_ALERT";
export const UPDATE_RISK_ALERT_SUCCESS = "UPDATE_RISK_ALERT_SUCCESS";
export const UPDATE_RISK_ALERT_FAIL = "UPDATE_RISK_ALERT_FAIL";

export const FOLLOW_PATIENT_SUCCESS = "FOLLOW_PATIENT_SUCCESS";
export const FOLLOW_PATIENT = "FOLLOW_PATIENT";
export const FOLLOW_PATIENT_FAIL = "FOLLOW_PATIENT_FAIL";

export const UNFOLLOW_PATIENT = "UNFOLLOW_PATIENT";
export const UNFOLLOW_PATIENT_SUCCESS = "UNFOLLOW_PATIENT_SUCCESS";
export const UNFOLLOW_PATIENT_FAIL = "UNFOLLOW_PATIENT_FAIL";

export const GET_RISK_ALERT_PATIENTS = "GET_RISK_ALERT_PATIENTS";
export const GET_RISK_ALERT_PATIENTS_SUCCESS =
  "GET_RISK_ALERT_PATIENTS_SUCCESS";
export const GET_RISK_ALERT_PATIENTS_FAIL = "GET_RISK_ALERT_PATIENTS_FAIL";

export const GET_RISK_ALERT_FOLLOW_PATIENTS = "GET_RISK_ALERT_FOLLOW_PATIENTS";
export const GET_RISK_ALERT_FOLLOW_PATIENTS_SUCCESS =
  "GET_RISK_ALERT_FOLLOW_PATIENTS_SUCCESS";
export const GET_RISK_ALERT_FOLLOW_PATIENTS_FAIL =
  "GET_RISK_ALERT_FOLLOW_PATIENTS_FAIL";

export const GET_RISK_ALERT_UNFOLLOW_PATIENTS =
  "GET_RISK_ALERT_UNFOLLOW_PATIENTS";
export const GET_RISK_ALERT_UNFOLLOW_PATIENTS_SUCCESS =
  "GET_RISK_ALERT_UNFOLLOW_PATIENTS_SUCCESS";
export const GET_RISK_ALERT_UNFOLLOW_PATIENTS_FAIL =
  "GET_RISK_ALERT_UNFOLLOW_PATIENTS_FAIL";

export const GET_PATIENT_RISK_ALERT = "GET_PATIENT_RISK_ALERT";
export const GET_PATIENT_RISK_ALERT_SUCCESS = "GET_PATIENT_RISK_ALERT_SUCCESS";
export const GET_PATIENT_RISK_ALERT_FAIL = "GET_PATIENT_RISK_ALERT_FAIL";

export const UPDATE_PATIENT_RISK_ALERT = "UPDATE_PATIENT_RISK_ALERT";
export const UPDATE_PATIENT_RISK_ALERT_SUCCESS =
  "UPDATE_PATIENT_RISK_ALERT_SUCCESS";
export const UPDATE_PATIENT_RISK_ALERT_FAIL = "UPDATE_PATIENT_RISK_ALERT_FAIL";

export const REMOVE_PATIENT_RISK_ALERT = "REMOVE_PATIENT_RISK_ALERT";
export const REMOVE_PATIENT_RISK_ALERT_SUCCESS =
  "REMOVE_PATIENT_RISK_ALERT_SUCCESS";
export const REMOVE_PATIENT_RISK_ALERT_FAIL = "REMOVE_PATIENT_RISK_ALERT_FAIL";

export const getRiskAssessment = () => ({
  type: GET_RISK_ALERT,
  payload: {
    request: {
      method: "get",
      url: "/cdss/clinical_risk_alert/",
    },
  },
});

export const updateRiskAssessment = (data) => ({
  type: UPDATE_RISK_ALERT,
  payload: {
    request: {
      method: "put",
      url: "/cdss/clinical_risk_alert/",
      data,
    },
  },
});

export const followPatients = (patient_id_list) => ({
  type: FOLLOW_PATIENT,
  payload: {
    request: {
      method: "post",
      url: `/cdss/clinical_risk_alert/patients/follow/`,
      data: { patient_id_list },
    },
  },
});

export const unfollowPatients = (patient_id_list) => ({
  type: UNFOLLOW_PATIENT,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/clinical_risk_alert/patients/follow/`,
      data: { patient_id_list },
    },
  },
});

export const getRiskAssementPatients = () => ({
  type: GET_RISK_ALERT_PATIENTS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/clinical_risk_alert/patients/`,
    },
  },
});

export const getRiskAssementFollowingPatients = (q) => ({
  type: GET_RISK_ALERT_FOLLOW_PATIENTS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/clinical_risk_alert/patients/follow`,
      params: { q: q },
    },
  },
});

export const getRiskAssementUnfollowingPatients = (q) => ({
  type: GET_RISK_ALERT_UNFOLLOW_PATIENTS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/clinical_risk_alert/patients/unfollow`,
      params: { q: q },
    },
  },
});

export const getPatientRiskAssesment = (patient_id) => ({
  type: GET_PATIENT_RISK_ALERT,
  payload: {
    request: {
      method: "get",
      url: `/cdss/clinical_risk_alert/patients/${patient_id}`,
    },
  },
});

export const updatePatientRiskAssesment = (patient_id, data) => ({
  type: UPDATE_PATIENT_RISK_ALERT,
  payload: {
    request: {
      method: "put",
      url: `/cdss/clinical_risk_alert/patients/${patient_id}/`,
      data,
    },
  },
});

export const removePatientRiskAssesment = (patient_id) => ({
  type: REMOVE_PATIENT_RISK_ALERT,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/clinical_risk_alert/patients/follow/${patient_id}/customization/`,
    },
  },
});
