//* ************************************** REACT IMPORTS *************************************
import React, { memo, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************
import { getPersonalData, userLogout } from "../../redux/actions/user";
import { getFlashMessage } from "../../redux/actions/navbar";
import { getProfileWidgetInfo } from "../../redux/actions/user";
import { clearSearchFilter } from "../../redux/actions/searchFilter";
import { clearRedirectTo } from "../../redux/actions/redirect";
import { getMkinetikosAccessPermission } from "../../redux/actions/permissions";
import { getPatients } from "../../redux/actions/patients";
import { getPatientConnectionRequests } from "redux/actions/patientConnectionRequests";
import {
  getInstitutions,
  setCurrentInstitution,
  getCurrentInstitution,
} from "../../redux/actions/institutions";

//* ************************************* OUR COMPONENTS *************************************
import DropdownOptions from "../../components/dropdownOptions";
import Notifications from "../notifications";
import MessageModal from "components/messageModal";
import RiskAssessment from "pages/home/partials/riskAssessment";
import ConnectionRequestsOverview from "pages/home/partials/connectionRequestsOverview";
import ChangeInstitutionModal from "components/notifications/partials/changeInstitutionModal";

//* ************************************** CUSTOM HOOKS **************************************
import useOnClickOutside from "../../hooks/useOnClickOutside";

//* ****************************************** UTILS *****************************************
import { getAvatarWithInitials } from "utils/avatar";
import { changeLanguage, getLanguageID } from "../../utils/language";

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import homeLogo from "../../assets/images/logo-secondary.svg";
import white_line from "../../assets/images/white-line.svg";
import line_blue from "../../assets/images/line-blue.svg";
import more_grey from "../../assets/images/more-grey.svg";
import more_blue from "../../assets/images/more-blue-2.svg";
import swap_horiz from "../../assets/images/swap-horiz.svg";
import logout_icon from "../../assets/images/logout.svg";
import my_area from "../../assets/images/my-area.svg";
import back_button from "../../assets/images/back-button.svg";
import info from "../../assets/images/toast/info.svg";
import success from "../../assets/images/toast/success.svg";
import error from "../../assets/images/toast/error.svg";
import bellCloseIcon from "../../assets/images/notification/bell-white.svg";
import bellOpenIcon from "../../assets/images/notification/bell-blue.svg";
import crossIcon from "../../assets/images/home/close.svg";

import { errors } from "../../constants/general";

const NavBar = ({ blueVersion = false, title = "" }) => {
  //* **************************************** SETUP *****************************************
  let history = useHistory();
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const notificationsButtonRef = useRef(null);
  const { t, i18n } = useTranslation();

  const filterButtonRef = useRef(null);
  const filterRef = useRef(null);
  const institutionsOptionsRef = useRef(null);

  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [showConnectionRequestModal, setShowConnectionRequestModal] = useState(false);
  const [institutionsOptionsOpen, setInstitutionsOptionsOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);
  const [dismissFlashMessage, setDismissFlashMessage] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [showRiskAlertModal, setShowRiskAlertModal] = useState(false);
  const [institutionIdToBeChange, setInstitutionIdToBeChange] = useState(null);
  const [patientInfo, setPatientInfo] = useState({});

  const current_institution = useSelector((state) => state.institutions.current_institution);
  const institutions = useSelector((state) => state.institutions.user_institutions);
  const personal_data = useSelector((state) => state.user.widget_info);
  const institutionId = current_institution.id;

  useOnClickOutside([notificationsRef, notificationsButtonRef], () => setIsNotificationOpen(null));

  const numOfNewNotification = useSelector((state) => state.notifications.notificationCount);

  useOnClickOutside([filterRef, filterButtonRef], () => setFilterOptionsOpen(false));

  useOnClickOutside([institutionsOptionsRef], () => setInstitutionsOptionsOpen(false));

  useEffect(() => {
    if (window.location.pathname.includes("home")) {
      dispatch(getFlashMessage(getLanguageID(i18n.language))).then(
        (res) => {
          setFlashMessage(res.payload.data.data);
          setDismissFlashMessage(false);
        },
        (err) => console.log(err)
      );
    }
    if (current_institution.id) {
      dispatch(getMkinetikosAccessPermission(current_institution.id));
    }
    dispatch(getProfileWidgetInfo());
    // Bad practice. Just assuring the language is consistent. This will get called often because the navbar is everywhere
    dispatch(getPersonalData()).then((res) => changeLanguage(res.payload.data.data.language_id));
    dispatch(getCurrentInstitution());
  }, []);

  //* ************************************** HANDLERS ****************************************
  const getFlashMessageIcon = (key) => {
    if (key === "info") return info;
    if (key === "success") return success;
    if (key === "error") return error;

    return info;
  };

  const goToMyArea = () => {
    history.push("/my_area");
  };

  const goToHome = () => {
    history.push("/home");
    window.location.reload(true);
  };

  const logout = () => {
    dispatch(userLogout()).finally(() => {
      history.replace("/login");
    });
  };

  const changeInstitutions = () => {
    setFilterOptionsOpen(false);
    setInstitutionsOptionsOpen(true);
    dispatch(getInstitutions()).catch(() => alert(errors.service_error));
  };

  const closeModal = () => {
    setInstitutionIdToBeChange(null);
  };

  const fetchPatientConnectionRequests = () => {
    setIsFetching(true);
    dispatch(
      getPatientConnectionRequests({
        current_institution_id: institutionId,
      })
    ).finally(() => setIsFetching(false));
  };

  const changeInstitution = (institution) => {
    dispatch(setCurrentInstitution(institution.id))
      .then(() => {
        setInstitutionsOptionsOpen(false);
        setFilterOptionsOpen(false);
        history.replace("/home");
        dispatch(getPatients(1));
        dispatch(getMkinetikosAccessPermission(current_institution.id));
        dispatch(clearSearchFilter());
      })
      .catch(() => alert(errors.service_error));
  };

  const closeInstitutionsOpenFilter = () => {
    setInstitutionsOptionsOpen(false);
    setFilterOptionsOpen(true);
  };

  //* ************************************** PARTIALS ****************************************

  const renderConnectionRequestModal = () => (
    <MessageModal
      hasCloseButton
      handleClose={() => setShowConnectionRequestModal(false)}
      body={
        <ConnectionRequestsOverview
          fetchRequests={() => {
            fetchPatientConnectionRequests();
          }}
          newOnly
          closeModal={() => setShowConnectionRequestModal(false)}
          isFetching={isFetching}
        />
      }
      customStyleClass={{
        messageModal: "message-modal--large",
        body: "connection-modal-body",
      }}
      headerText={t("home.approve_connections")}
    />
  );

  const renderRiskAlertModal = () => (
    <MessageModal
      customStyleClass={{ body: "pt-2 pb-2" }}
      headerText={
        <div className="row-space-between">
          <div className="risk-assessment-modal">
            <img
              src={
                patientInfo.photo
                  ? patientInfo.photo
                  : getAvatarWithInitials(36, patientInfo.name || "No Name")
              }
              onError={(e) => {
                e.target.src = getAvatarWithInitials(36, patientInfo.name || "No Name");
              }}
              alt="pp"
              className="img"
            />
            <div>
              <span className="black-color">{patientInfo.name}</span>
              <div className="meta-info">
                {patientInfo.time} • {t("notifications.Risk Assessement")}
              </div>
            </div>
          </div>
          <img
            className="cursor-pointer"
            src={crossIcon}
            alt="close pointer"
            onClick={() => setShowRiskAlertModal(false)}
          />
        </div>
      }
      body={
        <div className="grey-border p-2">
          <RiskAssessment patientId={patientInfo.id} />
        </div>
      }
      footerButtons={[
        <button
          onClick={() => setShowRiskAlertModal(false)}
          className="transparent-button small-button"
        >
          <u> {t("buttons.cancel")} </u>
        </button>,
        <button
          onClick={() => history.push(`/patient/${patientInfo.id}/evaluations`)}
          className="secondary-button small-button"
        >
          {t("buttons.visit_profile")}
        </button>,
      ]}
    />
  );

  const renderInstitutionsOptions = () => {
    const values = institutions.map((institution) => {
      return {
        html: (
          <button onClick={() => changeInstitution(institution)} key={institution.id}>
            <img
              alt="institution_info"
              className="institution-logo-listing mr-1"
              src={institution.photo}
            ></img>
            <span className="regular-text">{institution.name}</span>
          </button>
        ),
      };
    });

    const header = {
      html: (
        <button onClick={closeInstitutionsOpenFilter} className="back-button" key={"back-button"}>
          <img alt="institution_info" className="mr-1" src={back_button}></img>
          <span className="regular-text"></span>
        </button>
      ),
    };

    const newArray = [header].concat(values);

    return newArray;
  };

  const isFilterOrChangeInstitutionOpen = filterOptionsOpen || institutionsOptionsOpen;

  const filterController = filterOptionsOpen && (
    <DropdownOptions
      ref={filterRef}
      buttons={[
        {
          html: (
            <button onClick={changeInstitutions}>
              <img alt="Swap" className="mr-1" src={swap_horiz}></img>
              <span className="regular-text">{t("buttons.change_institution")}</span>
            </button>
          ),
        },
        {
          html: (
            <button onClick={goToMyArea}>
              <img alt="My Area" className="mr-1" src={my_area}></img>
              <span className="regular-text">{t("buttons.my_area")}</span>
            </button>
          ),
        },
        {
          html: (
            <button onClick={logout}>
              <img alt="Logout" className="mr-1" src={logout_icon}></img>
              <span className="regular-text">{t("buttons.logout")}</span>
            </button>
          ),
        },
      ]}
    />
  );

  const changeInstitutionController = institutionsOptionsOpen && (
    <DropdownOptions ref={institutionsOptionsRef} buttons={renderInstitutionsOptions()} />
  );

  //* *************************************** RENDER *****************************************
  return blueVersion ? (
    <>
      <nav className="navbar-wrapper nav-bar-components">
        <div className="flex-d-row justify-content-between align-items-center">
          <div>
            <img alt="Logo" className="navbar-logo" src={homeLogo} />
          </div>

          <div className="row-space-between position-relative">
            <div>
              <div
                className={`notification-icon-container ${
                  isNotificationOpen && "notification-active"
                }`}
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                ref={notificationsButtonRef}
              >
                <img src={isNotificationOpen ? bellOpenIcon : bellCloseIcon} alt="bell icon" />
                {numOfNewNotification && numOfNewNotification.total > 0 ? (
                  <span className="notification-number">{numOfNewNotification.total}</span>
                ) : (
                  ""
                )}
              </div>

              <Notifications
                show={isNotificationOpen}
                notificationsRef={notificationsRef}
                patientInfo={setPatientInfo}
                showRiskAlert={setShowRiskAlertModal}
                showConnectionRequestModal={setShowConnectionRequestModal}
                setInstitutionIdToBeChange={setInstitutionIdToBeChange}
              />
            </div>
            <div
              style={{
                backgroundColor: (filterOptionsOpen || institutionsOptionsOpen) && "#EFF3F6",
              }}
              ref={filterButtonRef}
              onClick={() => setFilterOptionsOpen(!filterOptionsOpen)}
              className="items-container nav-blue"
            >
              <img alt="Logo" className="institution-logo mr-1" src={current_institution.photo} />
              <img
                alt="Logo"
                className=" mr-1"
                src={isFilterOrChangeInstitutionOpen ? line_blue : white_line}
              />
              <img alt="Profile" className="profile-image mr-1" src={personal_data.photo} />
              <img alt="Logo" src={isFilterOrChangeInstitutionOpen ? more_blue : more_grey} />
              {filterController}
              {changeInstitutionController}
            </div>
          </div>
        </div>
      </nav>
      {window.location.pathname.includes("home") && flashMessage && !dismissFlashMessage && (
        <div className="flash-msg-wrapper">
          <img src={getFlashMessageIcon(flashMessage.key)} />
          <div className="col">
            <span>{flashMessage.message}</span>
          </div>
          <div className="col-2 btn-wrapper">
            {/* <button className="small-button transparent-button primary-color">
              <u>Update Now</u>
            </button> */}
            <button
              className="small-button transparent-button black-color"
              onClick={() => setDismissFlashMessage(true)}
            >
              <u>{t("buttons.dismiss")}</u>
            </button>
          </div>
        </div>
      )}
      {showRiskAlertModal && renderRiskAlertModal()}
      {showConnectionRequestModal && renderConnectionRequestModal()}
      {institutionIdToBeChange !== null && (
        <ChangeInstitutionModal
          closeModal={closeModal}
          institutionId={institutionIdToBeChange}
          goto={goToHome}
        />
      )}
    </>
  ) : (
    <>
      <div className="position-relative">
        <div className="nav-transparent nav-bar-components">
          <div
            className={`${
              title !== "" ? "row-space-between" : "flex-d-row justify-content-end"
            } align-items-center`}
          >
            {title !== "" && (
              <div>
                <h4>{title}</h4>
              </div>
            )}
            <div className="d-flex row-spece-between center-align position-relative">
              <div>
                <div
                  className={`notification-icon-container ${
                    isNotificationOpen && "notification-active-primary"
                  }`}
                  onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                  ref={notificationsButtonRef}
                >
                  <img src={isNotificationOpen ? bellCloseIcon : bellOpenIcon} alt="bell icon" />
                  {numOfNewNotification && numOfNewNotification.total > 0 ? (
                    <span className="notification-number">{numOfNewNotification.total}</span>
                  ) : (
                    ""
                  )}
                </div>

                <Notifications
                  show={isNotificationOpen}
                  closeNotificationMenu={() => setIsNotificationOpen(false)}
                  notificationsRef={notificationsRef}
                  patientInfo={setPatientInfo}
                  showRiskAlert={setShowRiskAlertModal}
                  showConnectionRequestModal={setShowConnectionRequestModal}
                  setInstitutionIdToBeChange={setInstitutionIdToBeChange}
                />
              </div>

              <div
                style={{
                  backgroundColor: (filterOptionsOpen || institutionsOptionsOpen) && "#255986",
                }}
                ref={filterButtonRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setFilterOptionsOpen(!filterOptionsOpen);
                }}
                className="items-container nav-white"
              >
                <img alt="Logo" className="institution-logo mr-1" src={current_institution.photo} />
                <img
                  alt="Logo"
                  className=" mr-1"
                  src={isFilterOrChangeInstitutionOpen ? white_line : line_blue}
                />
                <img alt="Profile" className="profile-image mr-1" src={personal_data.photo} />
                <img alt="Logo" src={isFilterOrChangeInstitutionOpen ? more_grey : more_blue} />
                {filterController}
                {changeInstitutionController}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRiskAlertModal && renderRiskAlertModal()}
      {showConnectionRequestModal && renderConnectionRequestModal()}
      {institutionIdToBeChange !== null && (
        <ChangeInstitutionModal
          closeModal={closeModal}
          institutionId={institutionIdToBeChange}
          goto={goToHome}
        />
      )}
    </>
  );
};

export default memo(NavBar);
