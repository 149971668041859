import {
  GET_FREQUENCIES_LIST_FAIL,
  GET_FREQUENCIES_LIST_SUCCESS,
} from "../actions/patientsPrograms";

const initialState = [];

const patientProtocolsFrequency = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREQUENCIES_LIST_SUCCESS: {
      return [...action.payload.data.data];
    }
    case GET_FREQUENCIES_LIST_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientProtocolsFrequency;
