import React from "react";
import { useState, useEffect } from "react";

import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import ExistingExercise from "./existingExercise";
import { useSelector } from "react-redux";
import { getSavedExerciseList } from "../../../../redux/actions/patientExercises";
import { errors } from "../../../../constants/general";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const animationDuration = 0.5;

const AddExerciseModal = ({ close, copyExercise }) => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);

  const [openInfoButtonIds, setOpenInfoButtonsIds] = useState([]);
  const exerciseList = useSelector(
    (state) => state.patientExercises.saved_exercises
  );
  const { patient_id } = useParams("patient_id");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getSavedExerciseList(patient_id)).catch(() =>
      alert(errors.service_error)
    );
  }, []);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => close(), animationDuration * 1000);
  };

  const showOrHideInfo = (exerciseId) => {
    if (openInfoButtonIds.includes(exerciseId))
      setOpenInfoButtonsIds(
        openInfoButtonIds.filter(
          (currExerciseId) => exerciseId !== currExerciseId
        )
      );
    else {
      setOpenInfoButtonsIds([...openInfoButtonIds, exerciseId]);
    }
  };

  return (
    <motion.div
      initial={{ right: -500 }}
      animate={{ right: isClosing ? -500 : 0 }}
      transition={{ duration: animationDuration }}
      className="exercises-add-existing-modal"
    >
      <div className="row align-items-center mb-5">
        <div className="col">
          <button onClick={closeModal} className="tertiary-button small-button">
            {t("buttons.close")}
          </button>
        </div>
        <div className="col-auto">
          <div className="small-title">
            {t("patient.exercises.add_existing_exercise")}
          </div>
        </div>
      </div>
      {exerciseList.obj_list.map((exercise) => (
        <ExistingExercise
          selectExercise={() => {
            copyExercise(exercise);
            closeModal();
          }}
          showOrHideInfo={() => showOrHideInfo(exercise.id)}
          exercise={exercise}
          isExerciseInfoOpen={openInfoButtonIds.includes(exercise.id)}
        />
      ))}
    </motion.div>
  );
};

export default React.memo(AddExerciseModal);
