//* ************************************** REACT IMPORTS ***************************************
import React from "react";
import { useEffect, useState } from "react";

//* ************************************** EXTERNAL PACKAGES ***********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* ************************************** OUR COMPONENTS **************************************

//* ************************************** GLOBALS *********************************************
import {
  getEvaluationTemplatesModuleList,
  getEvaluationTemplate,
} from "../../../redux/actions/evaluationTemplates";
import { moduleTypes } from "../../../constants/general";

import clinical_data from "../../../assets/images/clinical-data.svg";
import questionnaries from "../../../assets/images/questionnaries.svg";
import treatments from "../../../assets/images/treatments.svg";
import kinematic_tests from "../../../assets/images/kinematic-tests.svg";
import search from "../../../assets/images/search.svg";
import backButton from "../../../assets/images/back-button.svg";

function AddModule({
  currSelectedModuleType,
  setCurrSelectedModuleType,
  getCurrentModuleTypeImage,
  currModuleTypeList,
  addModule,
  hasAddedModules = false,
  templatesAvailable = true,
}) {
  //* ************************************** SETUP *********************************************
  const [searchInput, setSearchInput] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const evaluationTemplatesInfo = useSelector(
    (state) => state.evaluationTemplates
  );

  const [areTemplatesLoading, setAreTemplatesLoading] = useState(false);

  //* ************************************** HANDLERS *********************************************

  const getModuleTypeList = (moduleType, search) => {
    const newModuleType = moduleType === null ? moduleTypes.all : moduleType;
    dispatch(getEvaluationTemplatesModuleList(newModuleType, search))
      .then((res) => {
        if (searchInput === "" && currSelectedModuleType === moduleTypes.all) {
          setCurrSelectedModuleType(null);
        } else {
          setCurrSelectedModuleType(newModuleType);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {
    if (currSelectedModuleType || searchInput !== "") {
      getModuleTypeList(currSelectedModuleType, searchInput);
    }
  }, [searchInput]);

  useEffect(() => {
    if (selectedTemplateId) {
      setAreTemplatesLoading(true);
      dispatch(getEvaluationTemplate(selectedTemplateId))
        .then(() => {
          setCurrSelectedModuleType("template");
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => setAreTemplatesLoading(false));
    }
  }, [dispatch, selectedTemplateId]);

  //* ************************************** RENDER *********************************************
  return (
    <div className="col">
      <div className="row-space-between " style={{ minHeight: "32px" }}>
        {/* Quick misalignment fix */}
        <span className="small-title">{t("my_area.templates.add_module")}</span>
      </div>
      <div className="add-modules-box">
        <span className="regular-text">
          {t("my_area.templates.search_module")}
        </span>
        <div className="search-wrapper">
          <input
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            type="text"
            className="search-input w-100"
            placeholder={t("placeholders.search_for_module")}
          />
          <img alt="search-icon" className="search-icon" src={search} />
        </div>
        {currSelectedModuleType !== null ? (
          <div className="module-type-list">
            <div className="header">
              <div
                className="button back-button-wrapper pb-0"
                onClick={() => {
                  setCurrSelectedModuleType(null);
                  setSelectedTemplateId("");
                  setSearchInput("");
                }}
              >
                <img src={backButton} alt="backButton" />
                {t("buttons.back")}
              </div>
              {currSelectedModuleType !== moduleTypes.all &&
                currSelectedModuleType !== "template" && (
                  <img
                    alt="Kinematics tests"
                    className="module-image"
                    src={getCurrentModuleTypeImage(currSelectedModuleType)}
                  />
                )}
            </div>
            <ul className="list">
              {currSelectedModuleType !== "template" &&
              currModuleTypeList &&
              currModuleTypeList?.length > 0 ? (
                currModuleTypeList.map((module, index) => {
                  return (
                    <li
                      key={module.name + index.toString()}
                      className="list-item"
                    >
                      {`${module.name}`}
                      <button
                        onClick={() => addModule(module)}
                        className="add-button"
                      >
                        {t("buttons.add")}
                      </button>
                    </li>
                  );
                })
              ) : evaluationTemplatesInfo.currTemplate.modules &&
                evaluationTemplatesInfo.currTemplate.modules?.length > 0 ? (
                evaluationTemplatesInfo.currTemplate.modules.map(
                  (module, index) => (
                    <li
                      key={module.name + index.toString()}
                      className="list-item"
                    >
                      {module.name}
                      <button
                        onClick={() => addModule(module)}
                        className="add-button"
                      >
                        {t("buttons.add")}
                      </button>
                    </li>
                  )
                )
              ) : (
                <div className="p-5 regular-text black-color text-center">
                  {t("patient.evaluations.no_modules_available")}
                </div>
              )}
            </ul>
          </div>
        ) : (
          <div>
            <div className="flex-wrap flex-d-row justify-content-center">
              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.kinematicTest, searchInput)
                }
                className="module-type"
              >
                <img alt="Kinematics tests" src={kinematic_tests} />
                <div className="text-bottom">
                  {t("my_area.templates.kinematics_tests")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.clinicalData, searchInput)
                }
                className="module-type"
              >
                <img alt="Clinical Data" src={clinical_data} />

                <div className="text-bottom">
                  {t("my_area.templates.clinical_data")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.questionnarie, searchInput)
                }
                className="module-type"
              >
                <img alt="Questionnaries" src={questionnaries} />
                <div className="text-bottom">
                  {t("my_area.templates.questionnaires")}
                </div>
              </div>

              <div
                onClick={() =>
                  getModuleTypeList(moduleTypes.intervention, searchInput)
                }
                className="module-type"
              >
                <img alt="Treatments" src={treatments} />
                <div className="text-bottom">
                  {t("my_area.templates.treatments")}
                </div>
              </div>
            </div>
            {templatesAvailable && (
              <>
                <div className="pt-4 pb-2 flex-d-row d-flex justify-content-center regular-text">
                  {t("or")}
                </div>
                <label
                  className="d-flex justify-content-between"
                  htmlFor="prescription_id"
                >
                  <span className="input-label-text">
                    {t("patient.evaluations.select_template")}
                  </span>
                </label>
                <div className="position-relative">
                  <select
                    disabled={hasAddedModules}
                    // onClick={getCurrEvaluationTemplates}
                    value={selectedTemplateId}
                    onChange={(e) => {
                      setSelectedTemplateId(e.target.value);
                      // setSelectedTemplateId(e.target.value);
                    }}
                    className={`dropdown w-100 d-flex`}
                    name="prescription_id"
                    id="prescription_id"
                    style={{ opacity: `${hasAddedModules ? "60%" : "100%"}` }}
                  >
                    <option value="">{t("placeholders.option")}</option>

                    {evaluationTemplatesInfo &&
                      evaluationTemplatesInfo.templates &&
                      evaluationTemplatesInfo.templates.obj_list &&
                      evaluationTemplatesInfo.templates.obj_list.map(
                        (template) => (
                          <option
                            // onClick={() => getChosenTemplate(template.id)}
                            value={template.id}
                            key={template.id}
                          >
                            {template.name}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(AddModule);
