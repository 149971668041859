import moment from "moment";
import { useState, useRef } from "react";

import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import calendar from "../../../assets/images/calendar-small.svg";
import crossIcon from "../../../assets/images/textarea-cross-button.svg";

import MessageModal from "../../messageModal";
import Spinner from "components/spinner";

import { localeForDatePicker } from "../../../utils/language";

const CreateEvaluationModal = ({ onCancel, onSubmit, isButtonDisabled }) => {
  const { t, i18n } = useTranslation();
  const datePickerInput = useRef(null);

  const evaluationTags = useSelector((state) => state.evaluationTags);

  const [newEvaluationInfo, setNewEvaluationInfo] = useState({
    name: t("patient.evaluations.new_evaluation"),
    selectedTagsIds: [],
    date: new Date(),
    notes: "",
  });

  const [isNoteFieldHide, setIsNoteFieldHide] = useState(false);

  const textInputsHandler = (e) => {
    setNewEvaluationInfo({
      ...newEvaluationInfo,
      [e.target.name]: e.target.value,
    });
  };

  const tagSelectHandler = (tagId) => {
    let tagIdArray = [...newEvaluationInfo.selectedTagsIds];
    const index = tagIdArray.indexOf(tagId);

    if (index > -1) {
      tagIdArray.splice(index, 1);
    } else {
      tagIdArray = [...tagIdArray, tagId];
    }

    setNewEvaluationInfo({ ...newEvaluationInfo, selectedTagsIds: tagIdArray });
  };

  const changeDate = (newDate) => {
    setNewEvaluationInfo({ ...newEvaluationInfo, date: newDate });
  };

  return (
    <div className="create-eval-modal-wrapper">
      {/* <div className="create-eval-modal-sidebar-margin" /> */}
      <div className="create-eval-modal-shadow">
        <div className="create-eval-modal-container">
          <div className="title-text">
            {t("patient.evaluations.new_evaluation")}
            <img
              alt="Close Programs Filter"
              className="programs-filter-close-btn"
              src={crossIcon}
              style={{
                width: 14,
                height: 14,
                display: "inline-block",
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => {
                onCancel();
              }}
            />
          </div>

          <label className="input-label-text" htmlFor="name">
            {t("patient.evaluations.name")}
          </label>
          <input
            autoComplete="off"
            onChange={textInputsHandler}
            value={newEvaluationInfo.name}
            className={`form-input`}
            name="name"
            id="name"
            placeholder={t("placeholders.evaluation_name")}
          />
          <label className="input-label-text">{t("patient.evaluations.selected_tags")}</label>
          {evaluationTags && evaluationTags.length !== 0 && (
            <div className="tags-wrapper regular-text black-color pointer">
              {evaluationTags.map((tag) => (
                <div
                  key={tag.id}
                  onClick={() => {
                    tagSelectHandler(tag.id);
                  }}
                  className={`tag-container ${
                    newEvaluationInfo.selectedTagsIds.includes(tag.id) ? "active" : ""
                  }`}
                >
                  {tag.name}
                </div>
              ))}
            </div>
          )}
          <label className="input-label-text">{t("patient.evaluations.date")}</label>
          <div style={{ position: "relative" }}>
            <DatePicker
              ref={datePickerInput}
              autoComplete="off"
              className={`form-input w-100`}
              name="date"
              id="date"
              placeholderText="yyyy-mm-dd hh-mm"
              locale={localeForDatePicker(i18n.language)}
              dateFormat="yyyy-MM-dd HH:mm"
              timeInputLabel="Time:"
              showTimeInput
              selected={newEvaluationInfo.date}
              onChange={(date) => changeDate(date)}
            />
            <img
              onClick={() => datePickerInput.current.setOpen(true)}
              alt="calendar"
              className="input-calendar"
              src={calendar}
            />
          </div>

          <label className="input-label-text" htmlFor="notes">
            {t("patient.evaluations.notes")}
          </label>
          <div
            style={{
              position: "relative",
              display: isNoteFieldHide ? "block" : "none",
            }}
          >
            <textarea
              autoComplete="off"
              onChange={textInputsHandler}
              value={newEvaluationInfo.notes}
              className={`form-input`}
              name="notes"
              id="notes"
              placeholder={t("placeholders.notes")}
              rows="4"
            />
            <img
              src={crossIcon}
              style={{
                position: "absolute",
                width: 9.33,
                height: 9.33,
                top: 11,
                right: 11,
                cursor: "pointer",
              }}
              onClick={() => {
                setIsNoteFieldHide(!isNoteFieldHide);
                setNewEvaluationInfo({ ...newEvaluationInfo, notes: "" });
              }}
            />
          </div>

          <div>
            <button
              className="tertiary-button add-note-button"
              style={isNoteFieldHide ? { display: "none" } : { display: "block" }}
              onClick={() => {
                setIsNoteFieldHide(!isNoteFieldHide);
              }}
            >
              {isNoteFieldHide
                ? t("patient.evaluations.cancel_notes")
                : t("patient.evaluations.add_notes")}
            </button>
          </div>

          <div className="footer-wrapper">
            <button
              onClick={() => {
                onCancel();
              }}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>
            <button
              onClick={() => {
                onSubmit(
                  newEvaluationInfo.name,
                  newEvaluationInfo.selectedTagsIds,
                  moment(newEvaluationInfo.date).utc().format("YYYY-MM-DD HH:mm:ss"),
                  newEvaluationInfo.notes
                );
              }}
              className="small-button secondary-button"
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? (
                <div className="position-relative p-1">
                  <Spinner type={"small"} />
                </div>
              ) : (
                t("buttons.create_evaluation")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvaluationModal;
