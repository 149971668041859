import { useEffect, useState } from "react";
import { useStore } from "react-redux";

const useEventSource = (
  url,
  { onOpen, onMessage, onError, onClose, withCredentials, name },
  deps = []
) => {
  const { getState } = useStore();

  const { isAuthenticated } = getState().user;

  useEffect(() => {
    let eventSource = null;
    const handleOpen = () => {
      if (name) console.log("Stream has been opened for", name);
      if (onOpen) onOpen();
    };

    const handleMessage = (e) => {
      const message = JSON.parse(e.data);
      if (onMessage) onMessage(message);
    };

    const handleError = (event) => {
      console.log("Server side event error: " + event);
      if (onError) onError();
    };

    const handleClose = () => {
      console.log("Stream has been closed");
      if (onClose) onClose();
    };

    if (isAuthenticated || !withCredentials) {
      eventSource = new EventSource(url, { withCredentials });
      eventSource.onopen = handleOpen;
      eventSource.onmessage = handleMessage;
      eventSource.onerror = handleError;
    }

    return () => {
      if (isAuthenticated) {
        handleClose();
        eventSource.close();
      }
    };
  }, [url, isAuthenticated, name, onMessage, onOpen, onClose, onError, withCredentials, ...deps]);
};

export default useEventSource;
