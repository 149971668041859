import { combineReducers } from "redux";
import user from "./user";
import patients from "./patients";
import patientHome from "./patientHome";
import patientConnectionRequests from "./patientConnectionRequests";
import patientProfile from "./patientProfile";
import patientEvaluations from "./patientEvaluations";
import patientEvaluationModule from "./patientEvaluationModule";
import patientProtocols from "./patientProtocols";
import patientProtocolsFrequency from "./patientProtocolsFrequency";
import patientPrograms from "./patientPrograms";
import patientProgramDetails from "./patientProgramDetails";
import patientTests from "./patientTests";
import patientTestDetails from "./patientTestDetails";
import patientSurveys from "./patientSurveys";
import patientSurveyDetails from "./patientSurveyDetails";
import patientChatsList from "./patientChatsList";
import patientAvailableChatParticipants from "./patientAvailableChatParticipants";
import patientChat from "./patientChat";
import evaluationTags from "./evaluationTags";
import evaluationTemplates from "./evaluationTemplates";
import institutions from "./institutions";
import riskAlert from "./riskAlert";
import patientExercises from "./patientExercises";
import patientsPrescriptions from "./patientPrescriptions";
import patientsMedicines from "./patientMedicines";
import patientsReports from "./patientReports";
import multiFactorAuth from "./multiFactorAuth";
import medicineSummary from "./medicineSummary";
import patientEvents from "./patientEvents";
import patientRiskSummary from "./patientRiskSummary";
import messageHandler from "./messageHandler";
import licenseHandler from "./mKinetikos";
import searchFilter from "./searchFilter";
import permissions from "./permissions";
import tickets from "./tickets";
import redirectTo from "./redirect";
import notifications from "./notifications";

const appReducer = combineReducers({
  user,
  patients,
  patientHome,
  patientConnectionRequests,
  patientProfile,
  patientEvaluations,
  patientEvaluationModule,
  patientProtocols,
  patientProtocolsFrequency,
  patientPrograms,
  patientProgramDetails,
  patientTests,
  patientTestDetails,
  patientSurveys,
  patientSurveyDetails,
  patientChatsList,
  patientAvailableChatParticipants,
  patientChat,
  evaluationTags,
  evaluationTemplates,
  institutions,
  riskAlert,
  patientExercises,
  patientsPrescriptions,
  patientsMedicines,
  patientsReports,
  multiFactorAuth,
  medicineSummary,
  patientEvents,
  patientRiskSummary,
  messageHandler,
  licenseHandler,
  searchFilter,
  permissions,
  tickets,
  redirectTo,
  notifications,
});

export default appReducer;
