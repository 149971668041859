//* *********************************** REACT IMPORTS ************************************
import React, { useState, useRef, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import AddExerciseModal from "../partials/addExerciseModal.js";
import ProgressBar from "../../../../components/progressBar/index.js";
import ExistingExercise from "../partials/existingExercise.js";
import MessageModal from "../../../../components/messageModal/index.js";
import Spinner from "components/spinner/index.js";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import search from "../../../../assets/images/search.svg";
import upload_image_icon from "../../../../assets/images/upload-image.svg";
import calendar from "../../../../assets/images/calendar-small.svg";
import helpIcon from "../../../../assets/images/help.svg";
import { fileToDataUri } from "../../../../utils/files";

import {
  errors,
  fileAllowedExtensions,
  fileLimits,
} from "../../../../constants/general.js";

import { dateToString } from "../../../../utils/dataAndTime.js";

import {
  createExerciseDetails,
  getSavedExerciseList,
} from "../../../../redux/actions/patientExercises.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { localeForDatePicker } from "utils/language.js";

const CreateExercise = (props) => {
  //* *************************************** SETUP ***************************************

  const datePickerInput = useRef(null);
  const imageInput = useRef(null);
  const videoInput = useRef(null);
  const { patient_id } = useParams("patient_id");
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [savedExerciseList, setSavedExerciseList] = useState([]);

  const [isExerciseListOpen, setIsExerciseListOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [exercise, setExercise] = useState({
    name: "",
    description: "",
    image: null,
    video: null,
    save_for_institution: false,
  });
  const [exerciseDetails, setExerciseDetails] = useState({
    exercise_id: null,
    days: [],
    sets: "",
    reps: "",
    duration: "",
    end_date: null,
  });

  const [exerciseErrors, setExerciseErrors] = useState({});

  const [selectedExercise, setSelectedExercise] = useState({
    id: null,
    name: "",
    description: "",
    photo: null,
    video: null,
    public: false,
  });

  const [openInfoButtonIds, setOpenInfoButtonsIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************

  useEffect(() => {
    if (selectedExercise.id !== null) {
      if (
        isCurrentExerciseTheSelectedExercise &&
        exerciseDetails.exercise_id === null
      ) {
        setExercise({ ...exercise, save_for_institution: false });
        setExerciseDetails({
          ...exerciseDetails,
          exercise_id: selectedExercise.id,
        });
      } else if (
        !isCurrentExerciseTheSelectedExercise &&
        exerciseDetails.exercise_id !== null
      ) {
        setExerciseDetails({
          ...exerciseDetails,
          exercise_id: null,
        });
      }
    }
  }, [exercise]);

  const getSearchedExerciseList = (search) => {
    dispatch(getSavedExerciseList(patient_id, search))
      .then((res) => {
        if (res.payload && res.payload.data)
          setSavedExerciseList(res.payload.data.data.obj_list);
      })
      .catch((error) => {
        alert(errors.service_error);
      });
  };

  const saveExercise = () => {
    const details = exerciseDetails;
    let data = {
      exercise: exercise,
      image: exercise.image,
      video: exercise.video,
      details: details,
    };
    delete data.exercise.image;
    delete data.exercise.video;
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("video", data.video);
    formData.append("exercise", JSON.stringify(data.exercise));
    formData.append("details", JSON.stringify(data.details));
    // alert(data);
    setIsLoading(true);
    dispatch(createExerciseDetails(patient_id, formData))
      .then(() => {
        props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response.data;
          console.log(data);

          if (data.form_errors) {
            setExerciseErrors(data.form_errors);
          }
        } else {
          alert(errors.service_error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const changeSelectDays = (day) => {
    let newSelectedList = [...exerciseDetails.days];
    if (exerciseDetails.days.includes(day)) {
      newSelectedList = exerciseDetails.days.filter((item) => item !== day);
    } else {
      newSelectedList.push(day);
    }
    setExerciseDetails({ ...exerciseDetails, days: newSelectedList });
  };

  const changeDate = (newDate) => {
    setExerciseDetails({
      ...exerciseDetails,
      end_date: newDate ? dateToString(newDate) : null,
    });
  };

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onChangeImage = (file) => {
    delete exerciseErrors["image"];
    if (!file) {
      setExercise({ ...exercise, image: null });
    } else if (!fileAllowedExtensions.image.includes(file.type)) {
      alert("Invalid image format");
    } else if (file.size > fileLimits.video) {
      alert("Images cannot exceed 50Mb of max. size");
    } else {
      setExercise({ ...exercise, image: file });
      // fileToDataUri(file).then((dataUri) => {
      //   setExercise({ ...exercise, image: dataUri });
      // });
    }
  };

  const onChangeVideo = (file) => {
    delete exerciseErrors["video"];
    if (!file) {
      setExercise({ ...exercise, video: null });
    } else if (!fileAllowedExtensions.video.includes(file.type)) {
      alert("Invalid video format");
    } else if (file.size > fileLimits.video) {
      alert("Videos cannot exceed 50Mb of max. size");
    } else {
      setExercise({ ...exercise, video: file });
      // fileToDataUri(file).then((dataUri) => {
      //   setExercise({ ...exercise, video: dataUri });
      // });
    }
  };

  const checkBoxHandler = (e) => {
    setExercise({
      ...exercise,
      save_for_institution: e.target.checked,
    });
  };

  const inputsHandlerExercise = (e) => {
    delete exerciseErrors[e.target.name];
    setExercise({ ...exercise, [e.target.name]: e.target.value });
  };

  const inputsNumberHandlerExerciseDetails = (e) => {
    delete exerciseErrors[e.target.name];

    const regexp = /^[1-9]\d*$/;
    if (regexp.test(e.target.value) || e.target.value === "")
      setExerciseDetails({
        ...exerciseDetails,
        [e.target.name]: e.target.value,
      });
  };

  const showOrHideInfo = (exerciseId) => {
    if (openInfoButtonIds.includes(exerciseId))
      setOpenInfoButtonsIds(
        openInfoButtonIds.filter(
          (currExerciseId) => exerciseId !== currExerciseId
        )
      );
    else {
      setOpenInfoButtonsIds([...openInfoButtonIds, exerciseId]);
    }
  };

  const goBack = () => {
    if (
      exercise.name ||
      exercise.description ||
      exercise.image ||
      exercise.video
    ) {
      setIsCancelModalOpen(true);
    } else {
      props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);
    }
  };

  const copyExercise = (currExercise) => {
    setExercise({
      name: currExercise.name,
      description: currExercise.description,
      image: currExercise.image,
      video: currExercise.video,
    });

    setExerciseDetails({ ...exerciseDetails, exercise_id: currExercise.id });

    setSelectedExercise({ ...currExercise });
  };

  const areMandatoryFieldsFilled =
    exercise.name &&
    exerciseDetails.sets &&
    exerciseDetails.days.length > 0 &&
    (exerciseDetails.reps || exerciseDetails.duration);

  const isCurrentExerciseTheSelectedExercise =
    exercise.name === selectedExercise.name &&
    exercise.description === selectedExercise.description &&
    exercise.photo === selectedExercise.photo &&
    exercise.video === selectedExercise.video;

  const isButtonOrInputDisable = isLoading || isExerciseListOpen;

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img onClick={goBack} alt="back Button" src={bigBackButton} />
        <h4>{t("titles.new_exercise")}</h4>
      </div>
      <div className="position-relative">
        <div
          className={`create-exercise-box ${
            isExerciseListOpen ? "with-modal-open" : ""
          } `}
        >
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">
              {t("patient.exercises.add_new_exercise")}
            </div>
            <div className="d-flex mt-1">
              <button
                onClick={() => setIsCancelModalOpen(true)}
                className="transparent-button small-button mr-1"
              >
                <u>{t("buttons.cancel")}</u>
              </button>

              <div className="input-image-wrapper m-0">
                <input
                  disabled={isButtonOrInputDisable || !areMandatoryFieldsFilled}
                  onClick={saveExercise}
                  type="submit"
                  className={`small-button secondary-button ${
                    isLoading && "transparent"
                  }`}
                  value={t("buttons.save")}
                />
                {isLoading && <Spinner type={"small"} />}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col mt-2">
              <div className="position-relative">
                <input
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    getSearchedExerciseList(e.target.value);
                  }}
                  disabled={isButtonOrInputDisable}
                  type="text"
                  className="search-input w-100"
                  placeholder={t("placeholders.search_exercises")}
                />
                <img alt="search-icon" className="search-icon" src={search} />
              </div>
            </div>
            <div className="col-auto mt-2">
              <button
                disabled={isButtonOrInputDisable}
                onClick={() => setIsExerciseListOpen(true)}
                className="primary-button small-button"
              >
                {t("buttons.open_exercise_list")}
              </button>
            </div>
          </div>
          {savedExerciseList.length > 0 && searchText !== "" && (
            <>
              <div className="regular-text mt-4 mb-2">
                {t("patient.exercises.search_results")}
              </div>
              {savedExerciseList.map((exercise, index) => (
                <ExistingExercise
                  index={index}
                  selectExercise={() => copyExercise(exercise)}
                  showOrHideInfo={() => showOrHideInfo(exercise.id)}
                  exercise={exercise}
                  isExerciseInfoOpen={openInfoButtonIds.includes(exercise.id)}
                />
              ))}{" "}
            </>
          )}
          <div className="mt-4 flex-d-column">
            <label className="input-label-text d-flex justify-content-between">
              <span>
                {t("patient.exercises.exercise_name")}{" "}
                <span className="warning-color">{t("star")}</span>
              </span>
              {exerciseErrors.name && (
                <span className="warning-color">{exerciseErrors.name}</span>
              )}
            </label>
            <input
              disabled={isButtonOrInputDisable}
              onChange={inputsHandlerExercise}
              value={exercise.name}
              placeholder={t("placeholders.exercise_name")}
              autoComplete="off"
              className={`form-input with-input-sizing ${
                exerciseErrors.name ? "error" : ""
              }`}
              name="name"
              id="name"
            />
            <label className="input-label-text  d-flex justify-content-between mt-1">
              <span>
                {t("patient.exercises.exercise_description")}{" "}
                <span className="warning-color">{t("star")}</span>
              </span>
              {exerciseErrors.description && (
                <span className="warning-color">
                  {exerciseErrors.description}
                </span>
              )}
            </label>
            <textarea
              disabled={isButtonOrInputDisable}
              as="textarea"
              rows="5"
              autoComplete="off"
              onChange={inputsHandlerExercise}
              value={exercise.description}
              className={`form-input  ${
                exerciseErrors.description ? "error" : ""
              }`}
              name="description"
              placeholder={t("placeholders.write_observation")}
            />
            <div className="mt-2">
              <div className="row">
                <div className="col-lg col-md-12">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>{t("files.image")}</span>
                    {exerciseErrors.image && (
                      <span className="warning-color">
                        {exerciseErrors.image}
                      </span>
                    )}
                  </label>
                  {exercise.image ? (
                    <ProgressBar
                      deleteItem={() =>
                        setExercise({ ...exercise, image: null })
                      }
                      changeFile={() => imageInput.current.click()}
                      timeInterval={0.5}
                    />
                  ) : (
                    <div className="insert-image-modal-body cursor-pointer">
                      <div
                        accept="image/*"
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={(event) => {
                          if (!isButtonOrInputDisable) {
                            handleDrag(event);
                            onChangeImage(event.dataTransfer.files[0] || null);
                          }
                        }}
                        className="upload-wrapper"
                      >
                        <img
                          className="upload-image"
                          onClick={() => imageInput.current.click()}
                          src={upload_image_icon}
                          alt="Upload"
                        ></img>
                        <span className="details-text">
                          {t("files.upload")}
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    disabled={isButtonOrInputDisable}
                    type="file"
                    accept="image/*"
                    ref={imageInput}
                    hidden
                    onChange={(event) =>
                      onChangeImage(event.target.files[0] || null)
                    }
                  />
                </div>
                <div className="col-lg col-md-12">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>{t("files.video")}</span>
                    {exerciseErrors.image ? (
                      <span className="warning-color">
                        {exerciseErrors.image}
                      </span>
                    ) : (
                      <span>
                        <i style={{ color: "#8CA1B4" }}>
                          {t("files.max_size_50")}
                        </i>
                      </span>
                    )}
                  </label>
                  {exercise.video ? (
                    <ProgressBar
                      deleteItem={() =>
                        setExercise({ ...exercise, video: null })
                      }
                      changeFile={() => videoInput.current.click()}
                      timeInterval={3.0}
                    />
                  ) : (
                    <div className="insert-image-modal-body cursor-pointer">
                      <div
                        accept="video/mp4,video/x-m4v,video/*"
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={(event) => {
                          if (!isButtonOrInputDisable) {
                            handleDrag(event);
                            onChangeVideo(event.dataTransfer.files[0] || null);
                          }
                        }}
                        className="upload-wrapper"
                      >
                        <img
                          className="upload-image"
                          onClick={() => videoInput.current.click()}
                          src={upload_image_icon}
                          alt="Upload"
                        ></img>
                        <span className="details-text">
                          {t("files.upload")}
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    disabled={isButtonOrInputDisable}
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    ref={videoInput}
                    hidden
                    onChange={(event) =>
                      onChangeVideo(event.target.files[0] || null)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex-d-column">
              <div className="small-title">
                {t("patient.exercises.exercise_details")}
              </div>
              <label className="input-label-text mt-1">
                <span>
                  {t("patient.exercises.number_of_sets")}{" "}
                  <span className="warning-color">{t("star")}</span>
                </span>
              </label>
              <input
                type="text"
                disabled={isButtonOrInputDisable}
                onChange={inputsNumberHandlerExerciseDetails}
                placeholder={t("placeholders.number_of_sets")}
                autoComplete="off"
                className={`form-input  exercise-details-input ${
                  exerciseErrors.sets ? "error" : ""
                }`}
                name="sets"
                value={exerciseDetails.sets}
                id="sets"
              />
              <div className="row flex-d-row align-items-center">
                <div className="col-auto flex-d-column">
                  <label className="input-label-text mt-2">
                    <span>
                      {t("patient.exercises.number_of_reps")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    <>
                      <img
                        alt="Help"
                        data-tip={t("patient.exercises.reps_tip")}
                        data-for={`repetitions-metric`}
                        src={helpIcon}
                        className="help-img"
                      />
                      <ReactTooltip
                        className="tooltip"
                        id={`repetitions-metric`}
                        place="top"
                        effect="solid"
                      />
                    </>
                  </label>
                  <input
                    disabled={
                      isButtonOrInputDisable || exerciseDetails.duration !== ""
                    }
                    onChange={inputsNumberHandlerExerciseDetails}
                    placeholder={t("placeholders.number_of_reps")}
                    autoComplete="off"
                    className={`form-input  exercise-details-input ${
                      exerciseErrors.reps ? "error" : ""
                    }`}
                    name="reps"
                    id="reps"
                    value={exerciseDetails.reps}
                    type="text"
                  />
                </div>
                <div className="col-auto ml-6 regular-text mr-6 mt-2">
                  <u>{t("or")}</u>
                </div>
                <div className="col-auto flex-d-column">
                  <label className="input-label-text mt-1">
                    <span>
                      {t("patient.exercises.duration")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    <>
                      <img
                        alt="Help"
                        data-tip={t("patient.exercises.duration_tip")}
                        data-for={`repetitions-metric`}
                        src={helpIcon}
                        className="help-img"
                      />
                      <ReactTooltip
                        className="tooltip"
                        id={`repetitions-metric`}
                        place="top"
                        effect="solid"
                      />
                    </>
                  </label>
                  <input
                    onChange={inputsNumberHandlerExerciseDetails}
                    value={exerciseDetails.duration}
                    disabled={
                      isButtonOrInputDisable || exerciseDetails.reps !== ""
                    }
                    placeholder={t("placeholders.minutes")}
                    autoComplete="off"
                    className={`form-input  exercise-details-input ${
                      exerciseErrors.duration ? "error" : ""
                    }`}
                    name="duration"
                    id="duration"
                    type="text"
                  />
                </div>
              </div>
              <label className="input-label-text mt-2">
                <span>
                  {t("patient.exercises.select_frequency")}{" "}
                  <span className="warning-color">{t("star")}</span>
                </span>
              </label>
              <SelectWeekDays
                disabled={isButtonOrInputDisable}
                activeDays={exerciseDetails.days}
                changeSelectDays={changeSelectDays}
              />
              <label className="input-label-text mt-4">
                {t("patient.exercises.end_date")}
              </label>
              <div className="input-image-wrapper image-right exercise-details-input">
                <DatePicker
                  disabled={isButtonOrInputDisable}
                  ref={datePickerInput}
                  autoComplete="off"
                  className={`form-input  ${
                    exerciseErrors.end_date ? "error" : ""
                  }`}
                  name="date"
                  id="date"
                  placeholderText={t("placeholders.date")}
                  locale={localeForDatePicker(i18n.language)}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  selected={
                    exerciseDetails.end_date
                      ? new Date(exerciseDetails.end_date)
                      : null
                  }
                  onChange={(date) => changeDate(date)}
                />
                <img
                  disabled={isButtonOrInputDisable}
                  onClick={() => datePickerInput.current.setOpen(true)}
                  alt="calendar"
                  className="input-right-image"
                  src={calendar}
                />
              </div>
              <div className="check-box-wrapper mt-4">
                <div className="input-wrapper col-md-auto">
                  <input
                    disabled={
                      isButtonOrInputDisable ||
                      exerciseDetails.exercise_id !== null
                    }
                    checked={exercise.save_for_institution}
                    onChange={checkBoxHandler}
                    name="save_for_institution"
                    type={"checkbox"}
                    id="checkbox"
                  />
                </div>

                <label type="checkbox" htmlFor="checkbox">
                  <span className="regular-text">
                    {t("patient.exercises.add_exercise_to_list")}
                  </span>
                  <>
                    <img
                      alt="Help"
                      data-tip={t("patient.exercises.add_exercise_tip")}
                      data-for={`save-exercise`}
                      src={helpIcon}
                      className="help-img"
                    />
                    <ReactTooltip
                      className="tooltip"
                      id={`save-exercise`}
                      place="top"
                      effect="solid"
                    />
                  </>
                </label>
              </div>
            </div>
          </div>
        </div>
        {isExerciseListOpen && (
          <AddExerciseModal
            copyExercise={(newExercise) => copyExercise(newExercise)}
            close={() => setIsExerciseListOpen(false)}
          />
        )}
      </div>
      {isCancelModalOpen && (
        <MessageModal
          headerText={t("modals.cancel_add_action_header")}
          body={t("modals.cancel_add_action_body")}
          footerButtons={[
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={() =>
                props.history.replace(
                  `/patient/${patient_id}/mkinetikos_exercises`
                )
              }
              className="small-button secondary-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default CreateExercise;
