//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";
import { useState } from "react";

//* *********************************** OUR COMPONENTS ***********************************

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import plusBlue from "../../../assets/images/plus-blue.svg";
import plusWhite from "../../../assets/images/plus-white.svg";

const IdentificationDocumentsForm = ({
  identificationDocuments,
  identificationDocumentsErrors,
  inputsHandler,
  createPatientRequest,
  addDocument,
  deleteDocument,
  goToPrevious,
}) => {
  //* *************************************** SETUP ****************************************
  const { t, i18n } = useTranslation();
  const [onHover, setOnHover] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const onChangeHandler = (e, index) => {
    inputsHandler(e, index);
    if (e.target.value !== "") {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  //* *************************************** RENDER ***************************************
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {identificationDocuments.map((identificationDocument, index) => (
        <div
          className={`${index !== 0 && "form-documents-container"}`}
          key={"identification" + index}
        >
          <div className="form-documents-header-container">
            <div className="semi-bold-medium-title">
              {`${t("create_patient.documentation.document_number")}${
                index + 1
              }`}
            </div>
            {identificationDocuments.length > 1 && (
              <div className="delete-container">
                <button
                  type="button"
                  onClick={() => deleteDocument(index)}
                  className="button small-wider-button warning-button"
                >
                  {t("buttons.delete")}
                </button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`document_type-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.document_type")}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].document_type && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].document_type}
                    </span>
                  )}
              </label>
              <select
                value={identificationDocument.document_type}
                onChange={(e) => inputsHandler(e, index)}
                className={`w-100 dropdown ${
                  identificationDocument.document_type === "" && "notSelected"
                }`}
                name="document_type"
                id={`document_type-${index + 1}`}
              >
                <option value="">{t("placeholders.option")}</option>
                <option value={1}>
                  {t("create_patient.documentation.type_national_id")}
                </option>
                <option value={2}>
                  {t("create_patient.documentation.type_tax_id")}
                </option>
                <option value={3}>
                  {t("create_patient.documentation.type_passport")}
                </option>
              </select>
            </div>
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`document_id-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.document_id")}&nbsp;
                  {identificationDocuments[identificationDocuments.length - 1]
                    .document_type != "" ? (
                    <span className="input-label-text warning-color">
                      {t("star")}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].document_id && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].document_id}
                    </span>
                  )}
              </label>
              <input
                autoComplete="off"
                disabled={identificationDocument.document_type === ""}
                onChange={(e) => onChangeHandler(e, index)}
                value={identificationDocument.document_id}
                className={`form-input ${
                  identificationDocuments[identificationDocuments.length - 1]
                    .document_type != "" &&
                  isEmpty &&
                  "error"
                }`}
                name="document_id"
                id={`document_id-${index + 1}`}
                placeholder={t("placeholders.document_id")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label
                className="d-flex justify-content-between"
                htmlFor={`text-${index + 1}`}
              >
                <span className="input-label-text">
                  {t("patient_info.observations")}
                </span>
                {identificationDocumentsErrors &&
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].text && (
                    <span className="input-label-text warning-color">
                      {identificationDocumentsErrors[index].text}
                    </span>
                  )}
              </label>
              <textarea
                as="textarea"
                rows="3"
                autoComplete="off"
                disabled={identificationDocument.document_type === ""}
                onChange={(e) => inputsHandler(e, index)}
                value={identificationDocument.text}
                className={`form-input ${
                  identificationDocumentsErrors[index] &&
                  identificationDocumentsErrors[index].text &&
                  "error"
                }`}
                name="text"
                id={`text-${index + 1}`}
                placeholder={t("placeholders.observations")}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="row">
        <div>
          <button
            type="button"
            onClick={addDocument}
            className="button small-button tertiary-button"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            disabled={
              identificationDocuments[identificationDocuments.length - 1]
                .document_id === "" ||
              identificationDocuments[identificationDocuments.length - 1]
                .document_type === ""
            }
          >
            <img
              style={{ marginRight: "13px" }}
              alt="Plus Button"
              src={onHover ? plusWhite : plusBlue}
            />
            <span>{t("create_patient.documentation.add_document")}</span>
          </button>
        </div>
      </div>
      <div className="form-bottom-container">
        <button
          name="previous"
          type="button"
          style={{ marginRight: "1.5em" }}
          onClick={(e) => goToPrevious(e)}
          className="button small-button transparent-button under-line"
          disabled={
            (identificationDocuments[identificationDocuments.length - 1]
              .document_id === "" &&
              identificationDocuments[identificationDocuments.length - 1]
                .document_type != "") ||
            (identificationDocuments[identificationDocuments.length - 1]
              .document_id != "" &&
              identificationDocuments[identificationDocuments.length - 1]
                .document_type === "")
          }
        >
          {t("previous")}
        </button>
        <button
          type="button"
          onClick={(e) => createPatientRequest(e, false)}
          className="button small-button secondary-button"
          disabled={
            (identificationDocuments[identificationDocuments.length - 1]
              .document_id === "" &&
              identificationDocuments[identificationDocuments.length - 1]
                .document_type != "") ||
            (identificationDocuments[identificationDocuments.length - 1]
              .document_id != "" &&
              identificationDocuments[identificationDocuments.length - 1]
                .document_type === "")
          }
        >
          {t("buttons.create_patient")}
        </button>
      </div>
    </form>
  );
};

export default IdentificationDocumentsForm;
