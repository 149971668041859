import React from "react";

import { useTranslation } from "react-i18next";

const SelectWeekDays = ({
  activeDays,
  changeSelectDays,
  disabled,
  isStatic = false,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="days-buttons-wrapper">
      {t("time.weekdays", { returnObjects: true }).map((day, index) => (
        <div
          onClick={() => !disabled && changeSelectDays(index)}
          key={index}
          className={`${isStatic ? "day-container-static" : "day-container "} ${
            activeDays.includes(index) ? "active" : ""
          }`}
        >
          {day}
        </div>
      ))}
    </div>
  );
};
export default React.memo(SelectWeekDays);
