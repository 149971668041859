import {
  GET_RISK_ALERT_SUCCESS,
  GET_RISK_ALERT_FAIL,
  UPDATE_RISK_ALERT_FAIL,
  GET_RISK_ALERT_PATIENTS_SUCCESS,
  GET_RISK_ALERT_PATIENTS_FAIL,
  GET_RISK_ALERT_FOLLOW_PATIENTS_SUCCESS,
  GET_RISK_ALERT_FOLLOW_PATIENTS_FAIL,
  GET_RISK_ALERT_UNFOLLOW_PATIENTS_SUCCESS,
  GET_RISK_ALERT_UNFOLLOW_PATIENTS_FAIL,
  GET_PATIENT_RISK_ALERT_SUCCESS,
  GET_PATIENT_RISK_ALERT_FAIL,
  REMOVE_PATIENT_RISK_ALERT_SUCCESS,
} from "../actions/riskAlert";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  global_settings: {
    notification_preferences: {
      receive_notifications: false,
    },
    thresholds: {
      risk_of_fall_th: 1,
      mds_fingertapping_th: 8,
      medication_adherence_th: 75,
      bradikinesia_th: 22,
      events_count_th: 0,
      tug_th: 11.0,
    },
    events: {
      alerts: [],
      symptoms: [],
    },
    following_list: [],
    unfollowing_list: [],
  },
  customizing_patient_settings: {
    thresholds: {
      risk_of_fall_th: 2,
      mds_fingertapping_th: 8,
      medication_adherence_th: 100,
      bradikinesia_th: 44,
      events_count_th: 0,
      tug_th: 20.0,
    },
    events: {
      alerts: [],
      symptoms: [],
    },
    tag: "default",
  },
};

const RiskALert = (state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_ALERT_SUCCESS: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          ...action.payload.data.data,
        },
      };
    }
    case GET_RISK_ALERT_FAIL: {
      return { ...state, global_settings: initialState.global_settings };
    }
    case GET_RISK_ALERT_PATIENTS_SUCCESS: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          ...action.payload.data.data,
        },
      };
    }
    case GET_RISK_ALERT_PATIENTS_FAIL: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          following_list: [],
          unfollowing_list: [],
        },
      };
    }

    case GET_RISK_ALERT_FOLLOW_PATIENTS_SUCCESS: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          following_list: action.payload.data.data,
        },
      };
    }
    case GET_RISK_ALERT_FOLLOW_PATIENTS_FAIL: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          following_list: [],
        },
      };
    }
    case GET_RISK_ALERT_UNFOLLOW_PATIENTS_SUCCESS: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          unfollowing_list: action.payload.data.data,
        },
      };
    }
    case GET_RISK_ALERT_UNFOLLOW_PATIENTS_FAIL: {
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          unfollowing_list: [],
        },
      };
    }
    case GET_PATIENT_RISK_ALERT_SUCCESS: {
      return {
        ...state,
        customizing_patient_settings: action.payload.data.data,
      };
    }
    case GET_PATIENT_RISK_ALERT_FAIL: {
      return {
        ...state,
        customizing_patient_settings: initialState.customizing_patient_settings,
      };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default RiskALert;
