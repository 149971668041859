import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMfaDevices } from "../../../../redux/actions/multiFactorAuth";
import {useTranslation} from "react-i18next";

const MultiFactor = ({ goToChangeMethod, goToBackupTokens }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const multifactorauth = useSelector((state) => state.multiFactorAuth);

  useEffect(() => {
    dispatch(getMfaDevices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="password-wrapper" style={{ position: "relative" }}>
      <span className="small-title">{t("authentication.mfa.two_factor_method")}</span>
      <div className="password-body">
        <label className="input-label-text">{t("authentication.mfa.current_method")}</label>

        <input
          disabled={true}
          name="password"
          id="password"
          type={"password"}
          placeholder={
            multifactorauth.available_methods.includes("sms")
              ? t("authentication.mfa.phone_number")
              : multifactorauth.available_methods.includes("generator")
              ? t("authentication.mfa.app_authenticator")
              : "-----"
          }
          className="form-input"
        />
      </div>
      <div className="password-footer">
        <button
          onClick={goToChangeMethod}
          className="small-button primary-button"
        >
          {t("authentication.mfa.change_method")}
        </button>
      </div>
      <div
        className="position-absolute regular-text primary-color"
        style={{
          bottom: "32px",
          left: "50%",
          transform: "translateX(-50%)",
          cursor: "pointer",
        }}
        onClick={goToBackupTokens}
      >
        {t("authentication.mfa.backup_codes_btn")}
      </div>
    </div>
  );
};

export default MultiFactor;
