import React from "react";

const Icon = ({ alt, className, icon, size, ...props }) => {
  // Defensive
  if (!icon) return null;

  const classNames = `icon-container icon-container-${size} ${className}`;

  return (
    <img
      className={classNames}
      role="img"
      alt={alt ? alt : icon}
      src={icon}
      {...props}
    />
  );
};

export default Icon;
