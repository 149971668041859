import React from "react";

const TextArea = ({
  value = "",
  onChange = () => {},
  id,
  name,
  placeholder = "",
  disabled = false,
}) => {
  return (
    <textarea
      as="textarea"
      rows="5"
      autoComplete="off"
      value={value}
      onChange={onChange}
      className={`ev-mod-text-area`}
      id={id}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default React.memo(TextArea);
