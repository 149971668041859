/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************
import uuid from "../../utils/uuid";

// TODO: make it more dynamic and clean (redesign it please!!)
const Breadcrumbs = (props) => {
  //* ************************************** SETUP **************************************
  const {
    patient_id,
    evaluation_id,
    eval_module_id,
    medicine_id,
    prescription_id,
    program_id,
    test_id,
    survey_id,
    exercise_id,
  } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const url_params = window.location.pathname.split("/");

  const patientName = useSelector((state) => state.patientHome.name);
  const currentInstitution = useSelector((state) => state.institutions.current_institution);

  const crumbs = [];

  url_params.map((value, i) => {
    if (value === "patient") {
      crumbs.push({
        name: patientName,
        link: patient_id && `/patient/${patient_id}/evaluations`,
      });
    } else if (value === "evaluations") {
      crumbs.push({
        name: t("titles.evaluations"),
        link: patient_id && evaluation_id && `/patient/${patient_id}/evaluations/`,
      });
    } else if (value === "reports" || value === "mkinetikos_report" || value === "report") {
      crumbs.push({
        name: t("titles.reports"),
        link: patient_id && `/patient/${patient_id}/reports`,
      });
    } else if (value === "edit") {
      crumbs.push({
        name: t("titles.edit"),
        link: "#",
      });
    } else if (value === "create") {
      crumbs.push({
        name: t("titles.create"),
        link: "#",
      });
    } else if (value === "show") {
      crumbs.push({
        name: t("titles.view"),
        link: "#",
      });
    } else if (value === "mkinetikos_programs") {
      crumbs.push({
        name: t("titles.programs"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_programs`,
      });
      if (program_id) {
        crumbs.push({
          name: t("titles.edit"),
          link: "#",
        });
      }
    } else if (value === "mkinetikos_medicines") {
      crumbs.push({
        name: t("titles.medicines"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_medicines`,
      });
    } else if (value === "mkinetikos_my_care_team") {
      crumbs.push({
        name: t("titles.my_care_team"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_my_care_team`,
      });
    } else if (value === "mkinetikos_exercises") {
      crumbs.push({
        name: t("titles.exercises"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_exercises`,
      });
    } else if (value === "mkinetikos_tests") {
      crumbs.push({
        name: t("titles.programs"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_programs`,
      });
      crumbs.push({
        name: t("titles.tests"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_tests`,
      });
    } else if (value === "mkinetikos_surveys") {
      crumbs.push({
        name: t("titles.programs"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_programs`,
      });
      crumbs.push({
        name: t("titles.surveys"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_surveys`,
      });
    } else if (value === "mkinetikos_prescriptions") {
      crumbs.push({
        name: t("titles.medicines"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_medicines`,
      });
      crumbs.push({
        name: t("titles.prescriptions"),
        link: patient_id && `/patient/${patient_id}/mkinetikos_prescriptions`,
      });
    }
  });

  //* ************************************** RENDER **************************************
  return (
    <span className="regular-text breadcrumbs-container">
      {currentInstitution && (
        <span className="cursor-pointer" onClick={() => history.push(`/`)}>
          {currentInstitution.name + " > "}
        </span>
      )}
      {crumbs.map((crumb, i) =>
        crumbs.length === i + 1 ? (
          <span className="small-title" style={{ fontSize: "0.75rem" }} key={uuid()}>
            {crumb.name}{" "}
          </span>
        ) : (
          <span className="cursor-pointer" onClick={() => history.push(crumb.link)} key={uuid()}>
            {crumb.name + " > "}
          </span>
        )
      )}
    </span>
  );
};

export default React.memo(Breadcrumbs);
