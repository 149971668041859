import {
  GET_MFA_DEVICES_FAIL,
  GET_MFA_DEVICES_SUCCESS,
} from "../actions/multiFactorAuth";

const initialState = { available_methods: [] };

const multiFactorAuth = (state = initialState, action) => {
  switch (action.type) {
    case GET_MFA_DEVICES_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_MFA_DEVICES_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default multiFactorAuth;
