import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-primary.svg";
import sn from "../../assets/images/sn.svg";
import backButton from "../../assets/images/back-button.svg";
import Spinner from "../../components/spinner";
import { createInstitution } from "../../redux/actions/institutions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CompanyInfo from "../../components/companyInfo";

const CreateInstitution = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [institutionInfo, setInstitutionInfo] = useState({
    name: "",
    email: "",
  });

  const [institutionInfoErrors, setInstitutionInfoErrors] = useState({});

  const product_version = process.env.REACT_APP_PRODUCT_VERSION;
  const isCameFromMyArea = props.location.cameFromMyArea;

  const inputsHandler = (e) => {
    setInstitutionInfo({
      ...institutionInfo,
      [e.target.name]: e.target.value,
    });
  };

  const createNewInstitution = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(createInstitution(institutionInfo.name, institutionInfo.email))
      .then((res) => {
        if (isCameFromMyArea) {
          props.history.push("/my_area/institutions");
        } else {
          props.history.push("/choose_institution");
        }
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setInstitutionInfoErrors(data.form_errors);
          }
        } else {
          alert("Something went wrong while creating the Institution");
        }
      })
      .finally(() => setIsLoading(false));
  };
  console.log(isCameFromMyArea);
  const goBack = () => {
    if (props.location.cameFromApp || isCameFromMyArea) {
      props.history.goBack();
    } else {
      props.history.replace("/choose_institution");
    }
  };

  return (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        <div className="row justify-content-between">
          <div className="col-auto">
            <img className="logo" src={logo} alt="Logo" />
          </div>
          <div className="col-auto">
            <div>
              <img style={{ marginRight: "4px" }} alt="SN" src={sn} />
              <span className="regular-text text--grey-intense">
                {product_version}
              </span>
            </div>
          </div>
        </div>

        <div className="row create-institution-wrapper">
          <div className="col"></div>
          <div className="col-sm-8 col-lg-4">
            <div className="button back-button-wrapper pb-1" onClick={goBack}>
              <img src={backButton} alt="backButton" />
              {t("buttons.back")}
            </div>
            <div className="create-institution-box">
              <form onSubmit={createNewInstitution}>
                <h3>
                  {isCameFromMyArea
                    ? t("institution.create_an_institution")
                    : t("institution.create")}
                </h3>
                <div className="regular-text mt-4">
                  {t("create_institution.support_notification")}
                </div>
                <div className="regular-text mt-2">
                  {t("forms.all_fields")}{" "}
                  <span className="warning-color">{t("star")}</span>{" "}
                  {t("forms.are_mandatory")}
                </div>
                <label
                  htmlFor="name"
                  className="input-label-text d-flex justify-content-between mt-4"
                >
                  <span>
                    {t("institution_info.name")}{" "}
                    <span className="warning-color">{t("star")}</span>
                  </span>
                  {institutionInfoErrors.name && (
                    <span className="warning-color">
                      {institutionInfoErrors.name}
                    </span>
                  )}
                </label>
                <input
                  onChange={inputsHandler}
                  value={institutionInfo.name}
                  name="name"
                  type="text"
                  id="name"
                  autoComplete="off"
                  placeholder={t("placeholders.institution_name")}
                  className={`form-input ${
                    institutionInfoErrors.name && "error"
                  }`}
                />

                <label
                  htmlFor="email"
                  className="input-label-text d-flex justify-content-between"
                >
                  <span>
                    {t("institution_info.email")}{" "}
                    <span className="warning-color">{t("star")}</span>
                  </span>
                  {institutionInfoErrors.email && (
                    <span className="warning-color">
                      {institutionInfoErrors.email}
                    </span>
                  )}
                </label>
                <input
                  onChange={inputsHandler}
                  value={institutionInfo.email}
                  name="email"
                  type="email"
                  id="email"
                  autoComplete="off"
                  placeholder={t("placeholders.email")}
                  className={`form-input ${
                    institutionInfoErrors.email && "error"
                  }`}
                />
                <div className="position-relative mt-1">
                  <input
                    value={isLoading ? "" : t("buttons.create_institution")}
                    type="submit"
                    disabled={
                      isLoading ||
                      institutionInfo.email === "" ||
                      institutionInfo.name === ""
                    }
                    className="small-wider-button primary-button w-100"
                  />
                  {isLoading && <Spinner type={"small"} />}
                </div>

                <div className="mt-4 text-center regular-text">
                  {t("support.contact")}
                  <Link
                    to={{
                      pathname: "mailto:support@kinetikoshealth.com",
                    }}
                    target={"_blank"}
                  >
                    <span className="link-text">
                      &nbsp;{t("support.center")}
                    </span>
                  </Link>
                </div>
              </form>
            </div>
          </div>

          <div className="col"></div>
        </div>
        <div className="row h-100">
          <div className="col kinetikos-bottom-information-wrapper">
            <CompanyInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInstitution;
