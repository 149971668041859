import React from "react";
import { useEffect, useState } from "react";

import ReactTooltip from "react-tooltip";

import helpIcon from "../../assets/images/help.svg";

const RadioButton = ({
  options = {
    text: "",
    textTop: "",
    tip: "",
    disabled: false,
    onClickText: "",
    onClickFunction: "",
  },
  index = 0,
  vertical = false,
  checkedIndex = null,
  getSelectedIndex = () => {},
}) => {
  const [text, setText] = useState("");
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (checkedIndex === index) {
      setIsChecked(true);
      if (options.onClickText) {
        setText(options.onClickText);
      }
      if (options.onClickFunction && options.onClickFunction !== "") {
        options.onClickFunction();
      }
    } else {
      setIsChecked(false);
      setText(options.text);
    }
  }, [checkedIndex]);

  return (
    <div key={index} className="radio-buttons-container">
      {options.textTop && options.textTop !== "" && (
        <div className="rd-btn-question">{options.textTop}</div>
      )}
      <div className={`rd-btn-options-wrapper ${vertical ? "vertical" : ""}`}>
        <div
          key={index}
          className={`rd-btn-option-container-center ${
            options.disabled ? "disabled" : ""
          } ${isChecked ? "checked" : ""}`}
          onClick={() => getSelectedIndex(index)}
        >
          {text}
          {options.tip && options.tip !== "" && (
            <>
              <img
                alt="Help"
                data-tip={options.tip}
                data-for={`option-${index}`}
                src={helpIcon}
                className="help-img"
              />
              <ReactTooltip
                className="tooltip"
                id={`option-${index}`}
                place="right"
                effect="solid"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(RadioButton);
