import { GET_PATIENTS_FAIL, GET_PATIENTS_SUCCESS } from "../actions/patients";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  obj_list: [],
  count: 0,
  page: 0,
  num_pages: 0,
};

const Patients = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENTS_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENTS_FAIL: {
      return initialState;
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default Patients;
