/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect, memo, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../components/spinner";
//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import OptionsButton from "../../../../components/optionsButton";
import SelectedWeekDays from "../../../../components/selectedWeekDays";
import DropdownOptions from "../../../../components/dropdownOptions";

//* ************************************** HOOKS *****************************************
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************
// import { getPatientSurveyDetails } from "../../../../redux/actions/patients";

//* ************************************** GLOBALS ***************************************
import filter_list_blue from "../../../../assets/images/filter-list-blue.svg";
import filter_list_white from "../../../../assets/images/filter-list-white.svg";
import person_exercise from "../../../../assets/images/person-exercise.svg";

import search from "../../../../assets/images/search.svg";
import moment from "moment";
import { dateToString } from "../../../../utils/dataAndTime";
import chevronUpSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";

const order = {
  name: "name",
  name_inv: "name_inv",
  date: "date",
  date_inv: "date_inv",
};

const ExercisesModule = ({
  deleteExercise,
  isExerciseListLoading,
  searchOptions,
  updateSearchText,
  updateFilterType,
  updateOrderBy,
}) => {
  //* ************************************** HANDLERS **************************************
  const isExpired = (exercise) => {
    return (
      moment(exercise.end_date).isBefore(moment(dateToString(new Date()))) ||
      !exercise.active
    );
  };

  const dropDownRef = useRef(null);
  const filterRef = useRef(null);
  const filterButtonRef = useRef(null);
  const optionsRef = useRef([]);

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setSelectedExerciseId(null)
  );

  useOnClickOutside([filterRef, filterButtonRef], () =>
    setFilterOptionsOpen(false)
  );

  const filterImage = useRef(null);
  const { patient_id } = useParams("patient_id");

  const { t, i18n } = useTranslation();

  const exercise_filter_types = {
    all: t("filters.all"),
    inst: t("filters.current_institution"),
    inst_inv: t("filters.other_institutions"),
    expired: t("filters.expired"),
  };

  // const [isExerciseListLoading, setIsExerciseListLoading] = useState(false);
  const [selectedExerciseId, setSelectedExerciseId] = useState(null);
  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);

  const history = useHistory();

  const currInstitution = useSelector(
    (state) => state.institutions.current_institution
  );

  // const searchOptions = useSelector((state) => state.searchFilter);
  const exercisesInfo = useSelector(
    (state) => state.patientExercises.exercise_list
  );
  const exerciseList = exercisesInfo.obj_list;

  const goToCreate = () =>
    history.replace(`/patient/${patient_id}/mkinetikos_exercises/create`);

  const goToDetails = (exercise) =>
    history.push(
      `/patient/${patient_id}/mkinetikos_exercises/${exercise.id}/show`
    );

  const goToEdit = (exercise) =>
    history.push(
      `/patient/${patient_id}/mkinetikos_exercises/${exercise.id}/edit`
    );

  return (
    <>
      <div className="mkinetikos-exercises-modules-box">
        <div className="row align-items-center">
          <div className="col mt-1">
            <div className="small-title">{t("titles.exercises")}</div>
          </div>
          <div className="col-auto mt-1">
            <button
              onClick={goToCreate}
              className="button primary-button small-button"
            >
              <img
                alt="Person exercise"
                className="button-icon"
                src={person_exercise}
              />
              {t("patient.exercises.add_new_exercise")}
            </button>
          </div>
        </div>
        <div className="row search-filter-wrapper">
          <div className="col">
            <div style={{ position: "relative" }}>
              <input
                value={searchOptions?.q}
                onChange={(e) => updateSearchText(e.target.value)}
                type="text"
                className="search-input w-100"
                placeholder={t("placeholders.search_for_exercise")}
              />
              <img alt="search-icon" className="search-icon" src={search} />
            </div>
          </div>
          <div className="col-auto filter-button">
            <button
              ref={filterButtonRef}
              onClick={(e) => {
                e.stopPropagation();
                setFilterOptionsOpen(!filterOptionsOpen);
              }}
              onMouseOver={() =>
                !filterOptionsOpen &&
                (filterImage.current.src = filter_list_white)
              }
              onMouseLeave={() =>
                !filterOptionsOpen &&
                (filterImage.current.src = filter_list_blue)
              }
              className={`button justify-content-center small-button ${
                filterOptionsOpen ? "marked-button" : "tertiary-button"
              }`}
            >
              <img
                ref={filterImage}
                alt="plus-icon"
                className="button-icon"
                src={filterOptionsOpen ? filter_list_white : filter_list_blue}
              />
              {t("buttons.filter")}
            </button>
            {filterOptionsOpen && (
              <DropdownOptions
                ref={filterRef}
                buttons={[
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.all_exercises")}
                      </span>
                    ),
                    action: () => updateFilterType("all"),
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.from_current_institution")}
                      </span>
                    ),
                    action: () => updateFilterType("inst"),
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.from_other_institution")}
                      </span>
                    ),
                    action: () => updateFilterType("inst_inv"),
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("filters.expired_exercises")}
                      </span>
                    ),
                    action: () => updateFilterType("expired"),
                  },
                ]}
              />
            )}
          </div>
          <div className="filter-by-text">
            {`${t("filters.filtered_by")} ${
              exercise_filter_types[
                searchOptions?.filterType ? searchOptions.filterType : "all"
              ]
            }`}
          </div>
        </div>

        <div className="row mt-4 list-label">
          <div className="col-3 flex-d-row">
            {t("patient.exercises.name_short")}
            <div
              className={`order-img-container ${
                searchOptions?.orderBy === order.name ||
                searchOptions?.orderBy === order.name_inv
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                updateOrderBy(
                  searchOptions?.orderBy === order.name
                    ? order.name_inv
                    : order.name
                )
              }
            >
              {searchOptions?.orderBy !== order.name && (
                <img
                  src={chevronUpSelectedIcon}
                  alt="Sort by evaluation date"
                />
              )}
              {searchOptions?.orderBy !== order.name_inv && (
                <img
                  src={chevronDownSelectedIcon}
                  alt="Sort by evaluation date descending"
                />
              )}
            </div>
          </div>

          <div className="col-4">{t("patient.exercises.days")}</div>
          <div className="col-2 flex-d-row">
            {t("patient.exercises.end_date")}
            <div
              className={`order-img-container ${
                searchOptions?.orderBy === order.date ||
                searchOptions?.orderBy === order.date_inv
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                updateOrderBy(
                  searchOptions?.orderBy === order.date
                    ? order.date_inv
                    : order.date
                )
              }
            >
              {searchOptions?.orderBy !== order.date && (
                <img
                  src={chevronUpSelectedIcon}
                  alt="Sort by evaluation date"
                />
              )}
              {searchOptions?.orderBy !== order.date_inv && (
                <img
                  src={chevronDownSelectedIcon}
                  alt="Sort by evaluation date descending"
                />
              )}
            </div>
          </div>
          <div className="col-3">{t("patient.exercises.added_by")}</div>
        </div>
        {isExerciseListLoading ? (
          <div style={{ height: "100px" }}>
            <div className="loading-wrapper">
              <Spinner type="big-blue" />
            </div>
          </div>
        ) : exerciseList.length === 0 ? (
          <div className="medium-base-color-text message-wrapper">
            <p>{t("patient.exercises.none_available")} </p>
            <p>{t("patient.exercises.can_create_new")}</p>
          </div>
        ) : (
          <div className="regular-text black-color mt-3">
            {exerciseList.map((exercise, index) => (
              <div
                key={exercise.id + index.toString()}
                className="row exercise"
              >
                <div className="col-3">
                  <span
                    className="cursor-pointer"
                    onClick={() => goToDetails(exercise)}
                  >
                    {exercise.name}
                  </span>
                </div>
                <div
                  className={`col-4 ${
                    isExpired(exercise) && "disabled-container"
                  }`}
                >
                  <SelectedWeekDays activeDays={exercise.days} />
                </div>
                <div className="col-2">
                  {moment(exercise.end_date).isBefore(
                    moment(dateToString(new Date()))
                  ) || !exercise.active ? (
                    <div className={`expired-date `}>
                      {" "}
                      {t("patient.meds.expired")}{" "}
                    </div>
                  ) : (
                    exercise.end_date
                  )}
                </div>{" "}
                <div className="col-3">
                  <div className="d-flex flex-direction-row justify-content-between align-items-center">
                    <div
                      className={`${
                        isExpired(exercise) && "disabled-container"
                      }`}
                    >
                      {exercise.exercise_plan.created_by ?? "Kinetikos"}
                    </div>
                    <div className="more-image-wrapper">
                      <OptionsButton
                        ref={(el) => (optionsRef.current[index] = el)}
                        isSelected={selectedExerciseId === exercise.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          selectedExerciseId === exercise.id
                            ? setSelectedExerciseId(null)
                            : setSelectedExerciseId(exercise.id);
                        }}
                      />
                      {selectedExerciseId === exercise.id && (
                        <DropdownOptions
                          ref={dropDownRef}
                          buttons={
                            exercise.exercise_plan.created_by_institution_id ===
                            currInstitution.id
                              ? [
                                  {
                                    text: (
                                      <span className="regular-text">
                                        {t("buttons.edit")}
                                      </span>
                                    ),
                                    action: () => goToEdit(exercise),
                                  },
                                  {
                                    text: (
                                      <span className="regular-text">
                                        {t("buttons.view_details")}
                                      </span>
                                    ),
                                    action: () => goToDetails(exercise),
                                  },
                                  {
                                    text: (
                                      <span className="regular-text warning">
                                        {t("buttons.delete")}
                                      </span>
                                    ),
                                    action: () => deleteExercise(exercise.id),
                                  },
                                ]
                              : [
                                  {
                                    text: (
                                      <span className="regular-text">
                                        {t("buttons.view_details")}
                                      </span>
                                    ),
                                    action: () => goToDetails(exercise),
                                  },
                                ]
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(ExercisesModule);
