/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import EvaluationsModule from "../../../components/patientEvaluationsModule";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";

const PatientEvaluations = (props) => {
  //* ************************************** SETUP **************************************
  const { patient_id } = useParams("patient_id");
  const { t, i18n } = useTranslation();

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container position-relative">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.goBack();
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.evaluations")}</h4>
      </div>
      <EvaluationsModule
        itemsPerPage={10}
        history={props.history}
        headerText={t("titles.evaluations")}
        rightMenueButtons={["report", "edit", "delete"]}
        nameTextLink={"edit"}
      />
    </div>
  );
};

export default React.memo(PatientEvaluations);
