/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect, memo, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import DropdownOptions from "../../../../components/dropdownOptions";
import Paginator from "../../../../components/paginator";
import Spinner from "components/spinner";

//* ************************************** HOOKS *****************************************
import useSearch from "hooks/useSearch";
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************
import { getPatientTests } from "../../../../redux/actions/patientsPrograms";

//* ************************************** ASSETS ****************************************
import chevronUpIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronUpSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronDownIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "../../../../assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import search from "../../../../assets/images/search.svg";
import filter_list_blue from "../../../../assets/images/filter-list-blue.svg";
import filter_list_white from "../../../../assets/images/filter-list-white.svg";
import crossIcon from "../../../../assets/images/cross_darkgrey.svg";
import crossSelectedIcon from "../../../../assets/images/cross_darkgrey_selected.svg";
import { parseDateFromDatetime } from "../../../../utils/dataAndTime";

const TestsModule = ({ history, itemsPerPage }) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");
  const { activity_id } = useParams("activity_id");
  const protocolName = history.location.state?.protocolName || null;

  const dropDownRef = useRef(null);
  const optionsRef = useRef(null);

  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);
  const [isTestLoading, setIsTesttLoading] = useState(true);

  const { t, i18n, ready } = useTranslation();

  const filterTypes = {
    all: t("filters.all"),
    last_month: t("filters.last_month"),
    last_year: t("filters.last_year"),
    last_week: t("filters.last_week"),
  };

  const filterImage = useRef(null);

  const patientTests = useSelector((state) => state.patientTests);

  const getTests = () => {
    setIsTesttLoading(true);
    dispatch(
      getPatientTests(patient_id, activity_id, {
        page: searchOptions.page,
        q: searchOptions.q,
        order_by: searchOptions.orderBy,
        filter_type: searchOptions.filterType
          ? searchOptions.filterType
          : "all",
        items_per_page: searchOptions.itemsPerPage,
      })
    )
      .then(
        (res) => {},
        (err) => {}
      )
      .finally(() => setIsTesttLoading(false));
  };

  const {
    searchOptions,
    updateSearchText,
    updatePage,
    updateOrderBy,
    updateFilterType,
  } = useSearch(getTests, 300, "tests", [patient_id, activity_id]);

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setFilterOptionsOpen(false)
  );

  const onClickCloseResults = () => {
    history.push(`/patient/${patient_id}/mkinetikos_tests`);
  };

  return (
    <>
      <div className="tests-module-wrapper">
        <div className="tests-module-container">
          <div className="row-space-between">
            <span className="small-title">{t("patient.programs.tests")}</span>
          </div>
          <div className="row search-wrapper">
            <div className="col">
              <div style={{ position: "relative" }}>
                <input
                  value={searchOptions?.q}
                  onChange={(e) => updateSearchText(e.target.value)}
                  type="text"
                  className="search-input w-100"
                  placeholder={t("placeholders.search_test")}
                />
                <img alt="search-icon" className="search-icon" src={search} />
              </div>
            </div>
            <div className="col-auto filter-button">
              <button
                ref={optionsRef}
                onClick={() => setFilterOptionsOpen(!filterOptionsOpen)}
                onMouseOver={() =>
                  !filterOptionsOpen &&
                  (filterImage.current.src = filter_list_white)
                }
                onMouseLeave={() =>
                  !filterOptionsOpen &&
                  (filterImage.current.src = filter_list_blue)
                }
                className={`button justify-content-center small-button ${
                  filterOptionsOpen ? "marked-button" : "tertiary-button"
                }`}
              >
                <img
                  ref={filterImage}
                  alt="plus-icon"
                  className="button-icon"
                  src={filterOptionsOpen ? filter_list_white : filter_list_blue}
                />
                {t("buttons.filter")}
              </button>
              {filterOptionsOpen && (
                <DropdownOptions
                  ref={dropDownRef}
                  buttons={[
                    {
                      text: (
                        <span className="regular-text">{t("filters.all")}</span>
                      ),
                      action: () => {
                        updateFilterType("all");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: (
                        <span className="regular-text">
                          {t("filters.last_week")}
                        </span>
                      ),
                      action: () => {
                        updateFilterType("last_week");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: (
                        <span className="regular-text">
                          {t("filters.last_month")}
                        </span>
                      ),
                      action: () => {
                        updateFilterType("last_month");
                        setFilterOptionsOpen(false);
                      },
                    },
                    {
                      text: (
                        <span className="regular-text">
                          {t("filters.last_year")}
                        </span>
                      ),
                      action: () => {
                        updateFilterType("last_year");
                        setFilterOptionsOpen(false);
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
          <div className="filter-by-wrapper">
            <div className="mt-1">
              {protocolName && (
                <>
                  <span className="regular-text">
                    {t("patient.programs.showing_tests_for")}
                    <span className="link-text-regular">{protocolName}.</span>
                  </span>
                  <img
                    alt="Close result"
                    className="cursor-pointer"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = crossSelectedIcon)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = crossIcon)}
                    src={crossIcon}
                    onClick={onClickCloseResults}
                  />
                </>
              )}
            </div>
            <div className="filter-by-text">
              {t("filters.filtered_by")}
              {ready && filterTypes[searchOptions?.filterType]}
            </div>
          </div>
          <div className="tests-list">
            <div className="row list-label tests-list-labels-wrapper">
              <div className="col-4 d-flex align-item-center">
                <div>{t("patient.programs.program_name")}</div>
                <div
                  className={`order-img-container ${
                    searchOptions?.orderBy === "name" ||
                    searchOptions?.orderBy === "name_inv"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateOrderBy(
                      searchOptions?.orderBy === "name" ? "name_inv" : "name"
                    );
                  }}
                >
                  {searchOptions?.orderBy !== "name" && (
                    <img
                      src={
                        searchOptions?.orderBy === "name_inv"
                          ? chevronUpSelectedIcon
                          : chevronUpIcon
                      }
                      alt="Order by Name"
                    />
                  )}
                  {searchOptions?.orderBy !== "name_inv" && (
                    <img
                      src={
                        searchOptions?.orderBy === "name"
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                      alt="Order by Name Invert"
                    />
                  )}
                </div>
              </div>
              <div className="col-2 d-flex align-item-center">
                <div>{t("patient.programs.submission_date")}</div>
                <div
                  className={`order-img-container ${
                    searchOptions?.orderBy === "date" ||
                    searchOptions?.orderBy === "date_inv"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateOrderBy(
                      searchOptions?.orderBy === "date_inv"
                        ? "date"
                        : "date_inv"
                    );
                  }}
                >
                  {searchOptions?.orderBy !== "date_inv" && (
                    <img
                      src={
                        searchOptions?.orderBy === "date"
                          ? chevronUpSelectedIcon
                          : chevronUpIcon
                      }
                      alt="Order from Old to New"
                    />
                  )}
                  {searchOptions?.orderBy !== "date" && (
                    <img
                      src={
                        searchOptions?.orderBy === "date_inv"
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                      alt="Order from New to Old"
                    />
                  )}
                </div>
              </div>
              <div className="col-1 d-flex align-item-center">
                <div>{t("patient.programs.results")}</div>

                <div
                  className={`order-img-container ${
                    searchOptions?.orderBy === "results" ||
                    searchOptions?.orderBy === "results_inv"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateOrderBy(
                      searchOptions?.orderBy === "results"
                        ? "results_inv"
                        : "results"
                    );
                  }}
                >
                  {searchOptions?.orderBy !== "results" && (
                    <img
                      src={
                        searchOptions?.orderBy === "results_inv"
                          ? chevronUpSelectedIcon
                          : chevronUpIcon
                      }
                      alt="Order by results"
                    />
                  )}
                  {searchOptions?.orderBy !== "results_inv" && (
                    <img
                      src={
                        searchOptions?.orderBy === "results"
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                      alt="Order by results Invert"
                    />
                  )}
                </div>
              </div>
              <div className="col-2 d-flex align-item-center">
                <div>{t("patient.programs.status")}</div>
              </div>
            </div>
            {isTestLoading ? (
              <div className="loading-wrapper p-4">
                <Spinner type="big-blue" />
              </div>
            ) : patientTests.obj_list.length === 0 ? (
              <div className="medium-base-color-text message-wrapper">
                <p>{t("patient.programs.no_tests_match")}</p>
              </div>
            ) : (
              patientTests.obj_list.map((test, index) => (
                <div
                  key={test.id + index.toString()}
                  className="row test-info-wrapper"
                >
                  <div className="col-4 regular-text black-color text-truncate">
                    {test.name}
                  </div>
                  <div className="col-2 regular-text black-color">
                    {parseDateFromDatetime(test.submission_date)}
                  </div>
                  <div className="col-1 regular-text black-color">
                    {test.result || test.result === 0
                      ? test.result /* .toFixed(2) */
                      : "-----"}
                  </div>
                  <div className="col-2 ">
                    <span
                      className="tag-container regular-text black-color"
                      id={`${test.status}`}
                    >
                      {t(`patient.programs.status_${test.status}`)}
                    </span>
                  </div>
                  <div className="col-2">
                    <div
                      onClick={() => {
                        history.push(
                          `/patient/${patient_id}/mkinetikos_tests/${test.id}`
                        );
                      }}
                      className="quaternary-button small-wider-button regular-text black-color text-center"
                    >
                      {t("patient.programs.results")}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <Paginator
          currPage={patientTests.page}
          totalPages={patientTests.num_pages}
          maxPages={3}
          changePage={updatePage}
        />
      </div>
    </>
  );
};

export default memo(TestsModule);
