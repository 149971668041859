import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";

import MessageModal from "../../../components/messageModal";
import RenewLicenceModal from "../../../components/renewLicenceModal";

import {
  getLicenseType,
  getClinicians,
  requestConnectionsWithEmail,
} from "../../../redux/actions/mKinetikos";
import { getCurrentInstitution } from "../../../redux/actions/institutions";

import useToast from "hooks/useToast";

import manualFilling from "../../../assets/images/home/manual-filling.svg";
import emailInvite from "../../../assets/images/home/email-invite.svg";
import cross from "../../../assets/images/home/close.svg";

const AddNewPatientModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const emailFieldRef = useRef(null);
  const emailErrorRef = useRef(null);
  const showToast = useToast();

  const [showModal, setShowModal] = useState(null);
  const [premiumModal, setPremiumModal] = useState(null);
  const [modalConfig, setModalConfig] = useState({});

  const [patientEmail, setPatientEmail] = useState();

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const permissions = useSelector((state) => state.permissions);

  useEffect(() => {
    dispatch(getLicenseType());
    dispatch(getClinicians(currentInstitutionId));
  }, []);

  useEffect(() => {
    if (emailFieldRef.current) {
      if (patientEmail) {
        dispatch(requestConnectionsWithEmail(patientEmail)).then(
          (res) => {
            setShowModal(false);
          },
          (err) => {
            if (
              err.error.response?.data?.msg ===
                "Max number of connections for this package plan" ||
              err.error.response?.data?.msg ===
                "mKinetikos user not found with given email"
            ) {
              setPremiumModal(true);
            } else if (
              err.error.response?.data?.msg === "Patient is already connected"
            ) {
              showToast({
                message: t("my_area.mKinetikos.patient_already_connected"),
                type: "info",
              });
            }
          }
        );
      } else {
        emailFieldRef.current.className = "form-input error";
        emailErrorRef.current.className = "warning-color show-warning";
      }
    }
  }, [patientEmail]);

  const __renderEmailModal = () => {
    setShowModal(true);
    setModalConfig({
      title: (
        <div className="row-space-between">
          <span>{t("home.add_new_patient.email_invite_msg")}</span>
          <img
            className="cursor-pointer"
            src={cross}
            alt="close pointer"
            onClick={() => setShowModal(false)}
          />
        </div>
      ),
      body: (
        <div className="email-form">
          <span>
            {t("home.add_new_patient.email_invite")}{" "}
            <span className="hide-warning" ref={emailErrorRef}>
              {t("home.add_new_patient.invalid_email")}
            </span>
          </span>
          <input
            className={`form-input`}
            placeholder={t("placeholders.email")}
            ref={emailFieldRef}
          />
        </div>
      ),
      buttons: [
        <button
          className="transparent-button small-button"
          onClick={() => setShowModal(false)}
        >
          <u> {t("buttons.cancel")} </u>
        </button>,
        <button
          className="button small-button primary-button"
          onClick={() => {
            setPatientEmail(
              validator.isEmail(emailFieldRef.current.value)
                ? emailFieldRef.current.value
                : null
            );
          }}
        >
          {t("buttons.continue")}
        </button>,
      ],
    });
  };

  return (
    <MessageModal
      headerText={
        <div className="row-space-between">
          <span>{t("home.add_new_patient.new_patient")}</span>{" "}
          <img
            className="cursor-pointer"
            src={cross}
            alt="close pointer"
            onClick={() => closeModal()}
          />
        </div>
      }
      body={
        <div className="new-patient-modal-wrapper">
          <div className="add-patient-modal">
            <div
              className="border-box"
              onClick={() => history.push("/create_patient")}
            >
              <img src={manualFilling} alt="manual filling" />
            </div>
            <div
              className="border-box"
              disabled={!permissions.accessPermissionForLicense}
              onClick={() => {
                __renderEmailModal();
              }}
            >
              <img src={emailInvite} alt="email invite" />
            </div>
          </div>
          <div className="row label-wrapper">
            <div className="col-3 text-center">
              <span>{t("buttons.manual_filling")}</span>
            </div>
            <div className="col-2"></div>
            <div className="col-3 text-center">
              <span>{t("buttons.invite_by_email")}</span>
            </div>
          </div>
          {showModal && (
            <MessageModal
              headerText={modalConfig.title}
              body={modalConfig.body}
              footerButtons={modalConfig.buttons}
            />
          )}
          {premiumModal && (
            <RenewLicenceModal
              showModal={premiumModal}
              onClose={() => {
                setPremiumModal(false);
                setShowModal(false);
                setPatientEmail();
                closeModal();
              }}
              renewWithEmail={true}
              data={{ email: patientEmail }}
              customHead={
                <div className="row-space-between">
                  <span>{t("home.add_new_patient.premium_licence")}</span>
                  <img
                    className="cursor-pointer"
                    src={cross}
                    alt="close pointer"
                    onClick={() => {
                      setPremiumModal(false);
                      setShowModal(false);
                      setPatientEmail();
                    }}
                  />
                </div>
              }
              btnName={t("buttons.send_invite")}
            />
          )}
        </div>
      }
    />
  );
};

export default AddNewPatientModal;
