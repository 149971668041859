import React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import check_circle from "../../assets/images/check-circle-blue.svg";
import { useState } from "react";

import { useTranslation } from "react-i18next";

const ProgressBar = ({ changeFile, deleteItem, timeInterval = 3.0 }) => {
  const [isProgressBarSuccess, setIsProgressBarSuccess] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setTimeout(() => setIsProgressBarSuccess(true), timeInterval * 1000);
  });

  return (
    <div>
      {isProgressBarSuccess ? (
        <div className="progress-bar-success">
          <div className="row align-items-center">
            <div className="col align-items-center">
              <span className="mr-1"> {t("files.upload_success")}</span>
              <img src={check_circle} alt="Check Circle" />
            </div>
            <div className="col-auto flex-d-row">
              <button
                onClick={changeFile}
                className="transparent-button small-button base-color"
              >
                {t("buttons.change_file")}
              </button>
              <button
                onClick={deleteItem}
                className="transparent-button small-button warning-color"
              >
                {t("buttons.delete")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="progress-bar-default">
          <div className="row">
            <div className="col flex-d-row justify-content-end align-items-center">
              <button
                style={{ zIndex: 10 }}
                className="transparent-button small-button"
              >
                <u>{t("buttons.cancel")}</u>
              </button>
            </div>
          </div>
          <motion.div
            initial={{ width: "0" }}
            animate={{ width: "100%" }}
            transition={{ duration: timeInterval }}
            className="progress-bar-loading"
          ></motion.div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProgressBar);
