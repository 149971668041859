//* *********************************** REACT IMPORTS ************************************
import React, { useState, useRef, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import MessageModal from "../../../../components/messageModal/index.js";
import PrescriptionDropdown from "../partials/prescriptionDropdown.js";
import MaxDailyIntake from "../partials/maxDailyIntake.js";

//* ************************************** GLOBALS ***************************************

import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import calendar from "../../../../assets/images/calendar-small.svg";
import plusBlue from "../../../../assets/images/plus-blue.svg";
import plusWhite from "../../../../assets/images/plus-white.svg";
import timerIcon from "../../../../assets/images/timer.svg";

import helpIcon from "../../../../assets/images/help.svg";
import { errors } from "../../../../constants/general.js";
import { dateToString } from "../../../../utils/dataAndTime.js";
import Spinner from "../../../../components/spinner";
import { getPrescriptionsList } from "../../../../redux/actions/patientPrescriptions.js";
import { createMedicine } from "../../../../redux/actions/patientMedicines.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { localeForDatePicker } from "utils/language.js";

const CreateMedicine = (props) => {
  //* *************************************** SETUP ***************************************

  const datePickerInput = useRef(null);

  const { patient_id } = useParams("patient_id");
  const dispatch = useDispatch();

  const prescriptions = useSelector(
    (state) => state.patientsPrescriptions.prescription_list.obj_list
  );

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const [searchText, setSearchText] = useState("");
  const [selectedPrescriptionID, setSelectedPrescriptionId] = useState();

  const [medicineDetails, setMedicineDetails] = useState(
    props.location.state && props.location.state.medicineDetails
      ? props.location.state.medicineDetails
      : {
          text: "",
          notes: "",
          end_date: null,
          is_sos: false,
          days: [],
          times: [""],
          max_daily_intake: null,
        }
  );

  const [mediceDetailsErrors, setMedicineDetailsErrors] = useState({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [openInfoButtonIds, setOpenInfoButtonsIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrescriptions, setIsLoadingPrescriptions] = useState(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const addImage = useRef(null);

  //* ************************************** HANDLERS **************************************

  const saveMedicine = (e) => {
    e.preventDefault();
    const newMedicineDetails = {
      ...medicineDetails,
      times: medicineDetails.times.filter((time) => time !== "" && time),
    };
    setIsLoading(true);

    dispatch(
      createMedicine(patient_id, newMedicineDetails, selectedPrescriptionID)
    )
      .then(
        () => {
          props.history.replace(`/patient/${patient_id}/mkinetikos_medicines`);
        },
        (error) => {
          if (error.error && error.error.response) {
            const data = error.error.response.data;
            console.log(data.form_errors);

            if (data.form_errors) {
              setMedicineDetailsErrors(data.form_errors);
            }
          } else {
            alert(errors.service_error);
          }
        }
      )
      .finally(() => setIsLoading(false));
  };

  const getCurrentPrescriptions = () => {
    setIsLoadingPrescriptions(true);
    dispatch(
      getPrescriptionsList(patient_id, {
        filter_type: "curr_inst",
        items_per_page: 200,
      })
    )
      .then(
        () => {},
        (err) => console.log(err)
      )
      .finally(() => setIsLoadingPrescriptions(false));
  };

  const changeSelectDays = (day) => {
    let newSelectedList = [...medicineDetails.days];
    if (medicineDetails.days.includes(day)) {
      newSelectedList = medicineDetails.days.filter((item) => item !== day);
    } else {
      newSelectedList.push(day);
    }
    setMedicineDetails({ ...medicineDetails, days: newSelectedList });
  };

  const changeDate = (newDate) => {
    setMedicineDetails({
      ...medicineDetails,
      end_date: newDate ? dateToString(newDate) : null,
    });
  };

  const inputsHandlerMedicine = (e) => {
    delete mediceDetailsErrors[e.target.name];
    setMedicineDetails({ ...medicineDetails, [e.target.name]: e.target.value });
  };

  const goBack = () => {
    if (medicineDetails.text || selectedPrescriptionID) {
      setIsCancelModalOpen(true);
    } else {
      props.history.replace(`/patient/${patient_id}/mkinetikos_medicines`);
    }
  };

  const changeSelectedTime = (time, index) => {
    let newTimes = [...medicineDetails.times];
    if (time === null) {
      newTimes[index] = "";
      setMedicineDetails({ ...medicineDetails, times: newTimes });
    } else {
      newTimes[index] = moment(time).format("HH:mm:ss");
      setMedicineDetails({ ...medicineDetails, times: newTimes });
    }
  };

  const removeTime = (index) => {
    let timesCopy = [...medicineDetails.times];
    timesCopy.splice(index, 1);
    setMedicineDetails({ ...medicineDetails, times: timesCopy });
  };

  const maxIntakeCounter = (value) => {
    if (value > 0) {
      setMedicineDetails({ ...medicineDetails, max_daily_intake: value });
    } else {
      setMedicineDetails({ ...medicineDetails, max_daily_intake: null });
    }
  };

  const resetMaxIntake = () => {
    setMedicineDetails({ ...medicineDetails, max_daily_intake: null });
  };

  const areMandatoryFieldsFilled =
    medicineDetails.text !== "" &&
    selectedPrescriptionID &&
    medicineDetails.end_date !== "" &&
    ((medicineDetails.days.length > 0 && medicineDetails.times[0] !== "") ||
      medicineDetails.is_sos);

  const isButtonOrInputDisable = isLoading;

  useEffect(() => {
    getCurrentPrescriptions();
  }, []);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img onClick={goBack} alt="back Button" src={bigBackButton} />
        <h4>{t("titles.create_med")}</h4>
      </div>
      <form onSubmit={saveMedicine}>
        <div className="position-relative">
          <div className={`create-exercise-box`}>
            {isLoadingPrescriptions ? (
              <div>
                <Spinner type={"big-blue"} />
              </div>
            ) : (
              <>
                <div className="flex-d-row d-flex justify-content-between align-items-center">
                  <div className="small-title mt-1">
                    {t("patient.meds.new_med")}
                  </div>
                  <div className="mt-1 flex-d-row">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={() => setIsCancelModalOpen(true)}
                      className="transparent-button small-button mr-1"
                    >
                      <u>{t("buttons.cancel")}</u>
                    </button>
                    <div className="input-image-wrapper m-0">
                      <input
                        type="submit"
                        value={t("buttons.save")}
                        disabled={
                          isButtonOrInputDisable || !areMandatoryFieldsFilled
                        }
                        className={`secondary-button small-button ${
                          isLoading && "transparent"
                        }`}
                      />
                      {isLoading && <Spinner type={"small"} />}
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex-d-column">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>
                      {t("patient.meds.med_name")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    {mediceDetailsErrors.text && (
                      <span className="warning-color">
                        {mediceDetailsErrors.text}
                      </span>
                    )}
                  </label>
                  <input
                    disabled={isLoading}
                    onChange={inputsHandlerMedicine}
                    value={medicineDetails.text}
                    placeholder={t("placeholders.med_name")}
                    autoComplete="off"
                    className={`form-input with-input-sizing ${
                      mediceDetailsErrors.text ? "error" : ""
                    }`}
                    name="text"
                    id="text"
                  />
                  <div className="col">
                    <label
                      className="d-flex justify-content-between"
                      htmlFor="prescription_id"
                    >
                      <span className="input-label-text">
                        {t("patient.meds.prescription")}{" "}
                        <span className="warning-color">{t("star")}</span>
                      </span>
                    </label>
                    <div className="position-relative">
                      <select
                        disabled={isLoading}
                        // onClick={getCurrentPrescriptions}
                        value={selectedPrescriptionID}
                        onChange={(e) =>
                          setSelectedPrescriptionId(e.target.value)
                        }
                        className={`dropdown with-input-sizing`}
                        name="prescription_id"
                        id="prescription_id"
                      >
                        <option value="">{t("placeholders.option")}</option>

                        {prescriptions &&
                          prescriptions.map((prescription) => {
                            return (
                              prescription.active && (
                                <option value={prescription.prescription_id}>
                                  {prescription.prescription_name ||
                                    t("patient.meds.unnamed_prescription")}
                                </option>
                              )
                            );
                          })}
                      </select>
                      <button
                        type="button"
                        disabled={isLoading}
                        onClick={() =>
                          history.push(
                            `/patient/${patient_id}/mkinetikos_prescriptions/create`,
                            {
                              medicineDetails: medicineDetails,
                            }
                          )
                        }
                        className="ml-1 tertiary-button small-button mb-3"
                      >
                        {t("buttons.add_prescription")}
                      </button>
                    </div>
                  </div>
                  <div className="check-box-wrapper mt-2">
                    <div className="input-wrapper col-md-auto">
                      <input
                        disabled={isLoading}
                        checked={medicineDetails.is_sos}
                        onChange={(e) =>
                          setMedicineDetails({
                            ...medicineDetails,
                            is_sos: e.target.checked,
                            days: e.target.checked ? [] : medicineDetails.days,
                            times: e.target.checked
                              ? [""]
                              : medicineDetails.times,
                          })
                        }
                        name="save_for_institution"
                        type={"checkbox"}
                        id="checkbox"
                      />
                    </div>
                    <label type="checkbox" htmlFor="checkbox">
                      <span className="regular-text">
                        {t("patient.meds.set_as_sos")}
                      </span>
                      <>
                        <img
                          alt="Help"
                          data-tip={t("patient.meds.set_as_sos_tip")}
                          data-for={`save-exercise`}
                          src={helpIcon}
                          className="help-img"
                        />
                        <ReactTooltip
                          className="tooltip"
                          id={`save-exercise`}
                          place="top"
                          effect="solid"
                        />
                      </>
                    </label>
                  </div>
                  {
                    <MaxDailyIntake
                      medicineDetails={medicineDetails}
                      maxIntakeCounter={maxIntakeCounter}
                      resetMaxIntake={resetMaxIntake}
                    />
                  }
                  <div
                    className={`${
                      medicineDetails.is_sos ? "disabled-container" : ""
                    }`}
                  >
                    <div className="mt-4 flex-d-column">
                      <label className="input-label-text">
                        <span>
                          {t("patient.meds.select_frequency")}{" "}
                          <span className="warning-color">{t("star")}</span>
                        </span>
                      </label>
                      <SelectWeekDays
                        disabled={isLoading || medicineDetails.is_sos}
                        activeDays={medicineDetails.days}
                        changeSelectDays={changeSelectDays}
                      />
                    </div>
                    <label className="input-label-text mt-4">
                      {t("patient.meds.select_time") + " "}
                      <span className="warning-color">{t("star")}</span>
                    </label>
                    {medicineDetails.times.map((time, index) => (
                      <div className="flex-d-row align-items-center">
                        <div className="input-image-wrapper image-right exercise-details-input position-relative">
                          <TimePicker
                            disabled={isLoading || medicineDetails.is_sos}
                            onChange={(e) => changeSelectedTime(e, index)}
                            showSecond={false}
                            value={
                              time !== "" ? moment(time, "HH:mm:ss") : time
                            }
                            clearIcon={<img src={timerIcon} />}
                          />
                        </div>
                        {index === 0 ? (
                          <button
                            type="button"
                            disabled={isLoading || medicineDetails.is_sos}
                            onMouseEnter={() =>
                              (addImage.current.src = plusWhite)
                            }
                            onMouseLeave={() =>
                              (addImage.current.src = plusBlue)
                            }
                            onClick={() =>
                              setMedicineDetails({
                                ...medicineDetails,
                                times: [...medicineDetails.times, ""],
                              })
                            }
                            className="ml-1 tertiary-button small-button mb-3"
                          >
                            <img
                              disabled={isButtonOrInputDisable}
                              alt="Plus Blue"
                              className="mr-1"
                              src={plusBlue}
                              ref={addImage}
                            />
                            {t("buttons.add_another_time")}
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={isLoading || medicineDetails.is_sos}
                            onClick={() => removeTime(index)}
                            className="warning-button small-button mb-3 ml-1"
                          >
                            {t("buttons.remove")}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <label className="input-label-text mt-1">
                    {t("patient.meds.end_date")}
                  </label>
                  <div className="input-image-wrapper image-right exercise-details-input">
                    <DatePicker
                      disabled={isButtonOrInputDisable}
                      ref={datePickerInput}
                      autoComplete="off"
                      className={`form-input  ${
                        mediceDetailsErrors.end_date ? "error" : ""
                      }`}
                      name="date"
                      id="date"
                      placeholderText={t("placeholders.date")}
                      locale={localeForDatePicker(i18n.language)}
                      minDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => changeDate(date)}
                      emptyLabel={t("placeholders.date")}
                      selected={
                        medicineDetails.end_date
                          ? new Date(medicineDetails.end_date)
                          : null
                      }
                    />
                    <img
                      disabled={isButtonOrInputDisable}
                      onClick={() => datePickerInput.current.setOpen(true)}
                      alt="calendar"
                      className="input-right-image"
                      src={calendar}
                    />
                  </div>
                </div>

                <label className="input-label-text  d-flex justify-content-between mt-1">
                  <span>{t("patient.meds.observations")}</span>
                  {mediceDetailsErrors.notes && (
                    <span className="warning-color">
                      {mediceDetailsErrors.notes}
                    </span>
                  )}
                </label>
                <textarea
                  disabled={isButtonOrInputDisable}
                  as="textarea"
                  rows="5"
                  autoComplete="off"
                  onChange={inputsHandlerMedicine}
                  value={medicineDetails.notes}
                  className={`form-input  ${
                    mediceDetailsErrors.notes ? "error" : ""
                  }`}
                  name="notes"
                  placeholder={t("placeholders.write_observation")}
                />
              </>
            )}
          </div>
        </div>
      </form>
      {isCancelModalOpen && (
        <MessageModal
          headerText={t("modals.cancel_add_action_header")}
          body={t("modals.cancel_add_action_body")}
          footerButtons={[
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={() =>
                props.history.replace(
                  `/patient/${patient_id}/mkinetikos_medicines`
                )
              }
              className="small-button secondary-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default CreateMedicine;
