//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState } from "react";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** ASSETS *****************************************
import logo from "../../assets/images/logo-primary.svg";
import icon404 from "../../assets/images/404/404.svg";

const PageNotFound = (props) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch()

  const isAuth = useSelector((state) => state.user.isAuthenticated);

  const goToHome = () => {
    props.history.push("/home");
  };
  //* ************************************** PARTIALS ****************************************
  // main-wrapper
  //* *************************************** RENDER *****************************************
  return (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        <div className="row">
          <div className="col-auto">
            <img className="logo" src={logo} alt="Logo" />
          </div>
        </div>
        <div className="row not-found-container">
          <div className="container-body">
            <div className="container-head">
              <span className="big-text">404</span>
              <span>{t("page_not_found.header_msg")}</span>
            </div>
            <img src={icon404} alt="404 image" />

            <p className="regular-text mt-5">{t("page_not_found.body_msg")}</p>
            <button
              className="button small-button tertiary-button mt-1"
              onClick={() => goToHome()}
            >
              {isAuth
                ? t("page_not_found.go_to_home")
                : t("page_not_found.go_to_login")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
