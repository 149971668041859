import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as d3 from "d3";
import * as d3ScaleChromatic from "d3-scale-chromatic";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import {
  create_activities_button,
  getTransformation,
} from "../../../utils/report";
import { create_card } from "../../../utils/create_card";
import Spinner from "../../spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";
import zoomIcon from "../../../assets/images/zoom-in.svg";
import resetIcon from "../../../assets/images/reset_icon.svg";
import tagIcon from "../../../assets/images/tag.svg";

const EventsChart = ({ details }) => {
  const { patient_id, evaluation_id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const reff = useRef(null);
  // const data = {
  //   evaluation_id: "730c7ef8dba39386408883a38fcc26f7c784cede",
  //   module_id: 443,
  //   sequence_id: 1,
  //   question_id: false,
  //   event_files: {
  //     left: null,
  //     right: null,
  //     marker: null,
  //     event_data: `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/event_data/`,
  //     save_data: "/report/api/save_event_data/",
  //     event_norms: [
  //       {
  //         path: "/media/normative_data/knee_flex.tsv",
  //         label: "Knee Flexion",
  //         id: "knee_angle",
  //       },
  //       {
  //         path: "/media/normative_data/ankle_flex.tsv",
  //         label: "Ankle Flexion",
  //         id: "ankle_angle",
  //       },
  //       {
  //         path: "/media/normative_data/hip_flex.tsv",
  //         label: "Hip Flexion",
  //         id: "hip_flexion",
  //       },
  //       {
  //         path: "/media/normative_data/hip_add.tsv",
  //         label: "Hip Adduction",
  //         id: "hip_adduction",
  //       },
  //       {
  //         path: "/media/normative_data/hip_rot.tsv",
  //         label: "Hip Rotation",
  //         id: "hip_rotation",
  //       },
  //     ],
  //   },
  //   data_file_name:
  //     "/media/730c7ef8dba39386408883a38fcc26f7c784cede_1_Gait.sto",
  //   labels: {
  //     left: "Left",
  //     right: "Right",
  //   },
  // };
  // const details = {
  //   id: 70,
  //   type: 10,
  //   title: "Gait cycles",
  //   description: "Gait cycles compared to the norm",
  //   icon: '<i class="fa fa-file-o" aria-hidden="true"></i>',
  //   color: "#BDDA57",
  //   span_row: 7,
  //   span_col: 10,
  // };

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_events_plot(card, data) {
      let exam_id = data.evaluation_id,
        module_id = data.module_id,
        sequence_id = data.sequence_id,
        question_id = data.question_id,
        event_files = data.event_files,
        data_file_name = data.data_file_name,
        filename = data.event_files.filename,
        parsed_data_file = undefined,
        card_body = card.querySelector(".card-body"),
        left_div = document.createElement("div"),
        right_div = document.createElement("div"),
        left_dropdown_div = document.createElement("div"),
        right_dropdown_div = document.createElement("div"),
        left_dropdown_span = document.createElement("span"),
        right_dropdown_span = document.createElement("span"),
        left_dropdown = document.createElement("select"),
        right_dropdown = document.createElement("select"),
        left_wrapper_div = document.createElement("div"),
        right_wrapper_div = document.createElement("div"),
        left_actions_row_div = document.createElement("div"),
        right_actions_row_div = document.createElement("div"),
        left_labels_div = document.createElement("div"),
        right_labels_div = document.createElement("div"),
        left_actions_div = document.createElement("div"),
        right_actions_div = document.createElement("div"),
        left_div_select = document.createElement("div"),
        right_div_select = document.createElement("div"),
        left_div_cycle = document.createElement("div"),
        right_div_cycle = document.createElement("div"),
        t = d3.transition().duration(500),
        event_limits_aux = [[], []],
        event_in_edition = [undefined, undefined],
        event_in_edition_name = [undefined, undefined];

      left_div.classList = "events-plot-side-wrapper";
      right_div.classList = "events-plot-side-wrapper";
      left_div.id = "events-plot-left-wrapper";
      right_div.id = "events-plot-right-wrapper";
      left_div.dataset.side = 0;
      right_div.dataset.side = 1;

      left_div_cycle.classList = "regular-text mb-2 mt-4";
      left_div_select.classList = "regular-text mb-2";
      right_div_cycle.classList = "regular-text mb-2 mt-4";
      right_div_select.classList = "regular-text mb-2";

      left_dropdown_div.classList = "dropdown-div";
      right_dropdown_div.classList = "dropdown-div";

      left_dropdown.classList = "Form-input selectInput plot-dropdown";
      right_dropdown.classList = "Form-input selectInput plot-dropdown";

      left_wrapper_div.classList = "svg-div noselect";
      right_wrapper_div.classList = "svg-div noselect";

      left_wrapper_div.id = "left-event-plot";
      right_wrapper_div.id = "right-event-plot";

      left_actions_row_div.classList = "row-space-between";
      right_actions_row_div.classList = "row-space-between";

      left_labels_div.classList = "events-plot-labels noselect";
      right_labels_div.classList = "events-plot-labels noselect";

      left_actions_div.classList = "plot-actions noselect";
      right_actions_div.classList = "plot-actions noselect";
      left_actions_div.id = "left-plot-actions";
      right_actions_div.id = "right-plot-actions";

      left_dropdown_span.classList = "title";
      right_dropdown_span.classList = "title";
      left_dropdown_span.innerText = data.labels.left.toUpperCase();
      right_dropdown_span.innerText = data.labels.right.toUpperCase();
      left_dropdown_div.appendChild(left_dropdown_span);
      right_dropdown_div.appendChild(right_dropdown_span);
      left_dropdown_div.appendChild(left_dropdown);
      right_dropdown_div.appendChild(right_dropdown);

      left_actions_row_div.appendChild(left_labels_div);
      left_actions_row_div.appendChild(left_actions_div);
      right_actions_row_div.appendChild(right_labels_div);
      right_actions_row_div.appendChild(right_actions_div);

      left_div.appendChild(left_div_select);
      left_div.appendChild(left_dropdown_div);
      left_div.appendChild(left_div_cycle);
      left_div.appendChild(left_actions_row_div);
      left_div.appendChild(left_wrapper_div);
      // left_div.appendChild(left_actions_div);

      right_div.appendChild(right_div_select);
      right_div.appendChild(right_dropdown_div);
      right_div.appendChild(right_div_cycle);
      right_div.appendChild(right_actions_row_div);
      right_div.appendChild(right_wrapper_div);
      // right_div.appendChild(right_actions_div);

      card_body.appendChild(left_div);
      card_body.appendChild(right_div);

      left_div_select.innerText = "Select joint movement";
      right_div_select.innerText = "Select joint movement";

      left_div_cycle.innerText = "Select cycle";
      right_div_cycle.innerText = "Select cycle";

      event_files.event_norms.forEach((movement_type, i) => {
        let left_drop_item = document.createElement("option"),
          right_drop_item = document.createElement("option");
        left_drop_item.innerText = right_drop_item.innerText =
          movement_type.label;
        left_drop_item.value = right_drop_item.value = i;
        left_dropdown.appendChild(left_drop_item);
        right_dropdown.appendChild(right_drop_item);
      });

      let tick_num = 5,
        rl = (side) => {
          return { 0: "left", 1: "right" }[side];
        },
        plot_l = get_plot_object(
          left_wrapper_div.getBoundingClientRect(),
          left_wrapper_div.id
        ),
        plot_r = get_plot_object(
          right_wrapper_div.getBoundingClientRect(),
          right_wrapper_div.id
        );

      // Begin creating labels

      const left_all_label = document.createElement("div");

      left_all_label.id = -1;
      left_all_label.classList = `events-plot-label-container deselect-button`;

      left_all_label.innerText = "All";

      const right_all_label = document.createElement("div");

      right_all_label.id = -1;
      right_all_label.classList = `events-plot-label-container deselect-button`;

      right_all_label.innerText = "All";

      /* label_container.addEventListener("click", label_click);
          label_container.addEventListener("mouseenter", label_mouseenter);
          label_container.addEventListener("mouseout", label_mouseout); */

      left_labels_div.appendChild(left_all_label);
      right_labels_div.appendChild(right_all_label);

      // variable that controls the zoom capability, enabling it or disabling it
      plot_l.zoomable = false;
      plot_r.zoomable = false;

      // Zoom button

      const left_zoom_button = document.createElement("div");
      left_zoom_button.classList =
        "events-plot-label-container deselect-button plot-disabled";
      left_zoom_button.innerHTML = `<img src=${zoomIcon} >`;

      const right_zoom_button = document.createElement("div");
      right_zoom_button.classList =
        "events-plot-label-container deselect-button plot-disabled";
      right_zoom_button.innerHTML = `<img src=${zoomIcon} >`;

      // left_actions_div.appendChild(left_zoom_button);
      // right_actions_div.appendChild(right_zoom_button);

      /* plot_l.zoom_button = create_activities_button(left_actions_div, "zoom");
      plot_r.zoom_button = create_activities_button(right_actions_div, "zoom"); */

      /* plot_l.hide_button = create_activities_button(
        left_actions_div,
        "hide",
        false,
        false,
        false
      );
      plot_r.hide_button = create_activities_button(
        right_actions_div,
        "hide",
        false,
        false,
        false
      ); */
      // plot_l.save_button = create_activities_button(
      //   left_actions_div,
      //   "save",
      //   true,
      //   true
      // );
      // plot_l.save_button.addEventListener(
      //   "click",
      //   () => {
      //     save(exam_id, 0, plot_l);
      //   },
      //   false
      // );
      // plot_l.save_button.addEventListener(
      //   "touch",
      //   () => {
      //     save(exam_id, 0, plot_l);
      //   },
      //   false
      // );
      // plot_r.save_button = create_activities_button(
      //   right_actions_div,
      //   "save",
      //   true,
      //   true
      // );
      // plot_r.save_button.addEventListener(
      //   "click",
      //   () => {
      //     save(exam_id, 1, plot_r);
      //   },
      //   false
      // );
      // plot_r.save_button.addEventListener(
      //   "touch",
      //   () => {
      //     save(exam_id, 1, plot_r);
      //   },
      //   false
      // );

      // plot_l.delete_button = create_activities_button(
      //   left_actions_div,
      //   "delete",
      //   true,
      //   true
      // );
      // plot_l.delete_button.addEventListener(
      //   "click",
      //   () => {
      //     delete_event(0, plot_l, left_div);
      //   },
      //   false
      // );
      // plot_l.delete_button.addEventListener(
      //   "touch",
      //   () => {
      //     delete_event(0, plot_l, left_div);
      //   },
      //   false
      // );

      // plot_r.delete_button = create_activities_button(
      //   right_actions_div,
      //   "delete",
      //   true,
      //   true
      // );
      // plot_r.delete_button.addEventListener(
      //   "click",
      //   () => {
      //     delete_event(1, plot_r, right_div);
      //   },
      //   false
      // );
      // plot_r.delete_button.addEventListener(
      //   "touch",
      //   () => {
      //     delete_event(1, plot_r, right_div);
      //   },
      //   false
      // );

      // plot_l.undo_button = create_activities_button(
      //   left_actions_div,
      //   "reset_zoom",
      //   true,
      //   true
      // );
      // plot_l.undo_button.addEventListener("click", () => {
      //   let evt = document.createEvent("HTMLEvents");
      //   evt.initEvent("change", false, true);
      //   left_dropdown.dispatchEvent(evt);
      // });
      // plot_l.undo_button.addEventListener("touch", () => {
      //   let evt = document.createEvent("HTMLEvents");
      //   evt.initEvent("change", false, true);
      //   left_dropdown.dispatchEvent(evt);
      // });
      // plot_r.undo_button = create_activities_button(
      //   right_actions_div,
      //   "reset_zoom",
      //   true,
      //   true
      // );
      // plot_r.undo_button.addEventListener("click", () => {
      //   let evt = document.createEvent("HTMLEvents");
      //   evt.initEvent("change", false, true);
      //   right_dropdown.dispatchEvent(evt);
      // });
      // plot_r.undo_button.addEventListener("touch", () => {
      //   let evt = document.createEvent("HTMLEvents");
      //   evt.initEvent("change", false, true);
      //   right_dropdown.dispatchEvent(evt);
      // });

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* CALL THE PLOT CUNSTRUCTION  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      for (let side = 0; side < 2; side++) {
        let plot = side == 0 ? plot_l : plot_r,
          side_id =
            side == 0
              ? "events-plot-left-wrapper"
              : "events-plot-right-wrapper";

        /* plot.zoom_button.addEventListener("click", () => {
          plot.zoom_button.classList.toggle("plot-disabled");
          control_zoom_functionality();
        });
        plot.zoom_button.addEventListener("touch", () => {
          plot.zoom_button.classList.toggle("plot-disabled");
          control_zoom_functionality();
        }); */

        side === 0
          ? left_zoom_button.addEventListener(
              "click",
              () => {
                control_zoom_functionality();
                left_zoom_button.classList.toggle("plot-disabled");
              },
              false
            )
          : right_zoom_button.addEventListener(
              "click",
              () => {
                control_zoom_functionality();
                right_zoom_button.classList.toggle("plot-disabled");
              },
              false
            );

        // activate or deactivate the zoom functionality
        function control_zoom_functionality() {
          if (!plot.zoomable) {
            plot.xzoom_area.call(plot.xzoom).on("dblclick.zoom", null);
            plot.yzoom_area.call(plot.yzoom).on("dblclick.zoom", null);
          } else {
            plot.xzoom_area.on(".zoom", null);
            plot.yzoom_area.on(".zoom", null);
          }
          plot.zoomable = !plot.zoomable;
        }

        side === 0
          ? left_all_label.addEventListener("click", hide_all, false)
          : right_all_label.addEventListener("click", hide_all, false);
        // plot.hide_button.addEventListener("touch", hide_all, false);
        // plot.hide = true;
        function hide_all() {
          d3.selectAll(`#${card.id} #${side_id} .event-plot-line`).classed(
            "hide-svg-element",
            plot.hide
          );
          // d3.selectAll(`#${card.id} .tag-per-line`).classed("hide-tags", hide);

          let labels = card.querySelectorAll(
            `#${side_id} .events-plot-labels .events-plot-label-container`
          );
          [...labels]
            .slice(1)
            .map((label_elem) =>
              plot.hide
                ? label_elem.classList.add("plot-disabled")
                : label_elem.classList.remove("plot-disabled")
            );
          /* this.innerHTML = plot.hide
            ? `<i class="icon visible" aria-hidden="true"></i>`
            : `<i class="icon hidden" aria-hidden="true"></i>`; */
          plot.hide = !plot.hide;
        }

        build_plot(side, 0, plot);
      } // FOR SIDE

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* DROPDOWN MANAGEMENT * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      left_dropdown.addEventListener("change", dropdown_change, false);
      right_dropdown.addEventListener("change", dropdown_change, false);

      function dropdown_change() {
        let wrapper = getClosest(this, ".events-plot-side-wrapper");
        let svg = d3.select(`#${wrapper.id} svg`);
        svg.selectAll("*").remove();

        let side = Number(wrapper.dataset.side),
          plot = side == 0 ? plot_l : plot_r,
          labels = card.querySelectorAll(
            `[data-side="${side}"] .events-plot-label-container:not(.deselect-button)`
          );
        [...labels].forEach((label_elem, key) => {
          if (event_limits_aux[side][key][4] === "visible")
            label_elem.classList.remove("plot-disabled");
        });
        // disable_edit_buttons(plot);

        /* plot.zoom_button.style.display = "inherit";
        plot.hide_button.style.display = "inherit"; */
        plot.wrapper.on(".zoom", null);
        plot.new_x_scale = null;
        plot.new_y_scale = null;
        plot.x_scale = d3.scaleLinear().rangeRound([0, plot.inner_width]);
        plot.y_scale = d3.scaleLinear().rangeRound([plot.inner_height, 0]);
        plot.x_axis = d3.axisBottom().scale(plot.x_scale).ticks(tick_num);
        plot.y_axis = d3.axisLeft().scale(plot.y_scale).ticks(tick_num);
        plot.zoomable = false;
        // plot.zoom_button.classList.add("plot-disabled");
        build_plot(side, this.value, plot);
      }

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* MAIN FUNTION  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      function build_plot(side, movement_type, plot) {
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* EVENT MARKER  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        //        d3.json(event_files.marker, (error, marker_data) => {
        //                if (error) throw error;
        //
        //                let markers = marker_data.map((marker) => {
        //                        return {
        //                            time: marker.time,
        //                            event: marker.event
        //                        };
        //                    }); // MAP
        //                // console.log('markers');
        //                // console.log(markers);
        //            }); //EVENT MARKER
        event_limits_aux[side].length = 0;
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* EVENTS LIMITS * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

        // d3.tsv(event_files[rl(side)], (error, event_limits) => {
        // if (error) throw error;
        // // console.log(event_limits);
        // event_limits.forEach( (event) => {
        //     event_limits_aux[side].push([
        //         //the data needs to be parsed to a numeric value, because it comes as a string from the file.
        //             +event.event,
        //             +event.heel_s,
        //             +event.toe_o,
        //             +event.heel_e,
        //             +event.edit
        //         ]);

        //     }); //FOREACH

        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* EVENT DATA  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
        axios
          .get(
            process.env.REACT_APP_BACKEND_URL +
              `/cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/events_data/`,
            {
              params: {
                movement: event_files.event_norms[movement_type].id,
                side: side === 0 ? "l" : "r",
                module_id: module_id,
                sequence_id: sequence_id,
                question_id: question_id,
              },
            }
          )
          .then((event_data_obj) => {
            let event_data = event_data_obj.data.data;
            event_limits_aux[side] = event_data_obj.data.events;
            // the key will be an index
            plot.color_scale.domain(
              d3.keys(event_data).map((i) => {
                return i;
              })
            );
            let chart_data = plot.color_scale.domain().map((key) => {
              add_label_to_card(
                side,
                key,
                plot,
                event_limits_aux[side][key],
                event_files.event_norms[movement_type].id,
                event_data_obj.offset
              );

              const label = card.querySelector(
                `[data-side="${side}"] #events-label-${key}`
              );

              return {
                // in this category (represented by the key) for each line, we will parse a time and value
                key: key,
                visibility:
                  event_limits_aux[side][key][4] === 1 &&
                  !label.classList.contains("plot-disabled") // Only draw if lable is enabled in case you change plots
                    ? "visible"
                    : "hidden",
                values: event_data[key].map((d) => {
                  return { time: d.time, value: d.val };
                }),
              };
            });

            /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
            /* EVENT NORM  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
            /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

            axios
              .get(
                process.env.REACT_APP_BACKEND_HOST +
                  event_files.event_norms[movement_type].path
              )
              .then(
                (d) => {
                  const split_data = d.data.split("\n");
                  const norm = split_data.slice(1, -1).map((data) => {
                    const split_line = data.split("\t");
                    return {
                      time: Number(split_line[0]),
                      lower: Number(split_line[1]),
                      median: Number(split_line[2]),
                      upper: Number(split_line[3]),
                    };
                  });

                  for (var key in d) {
                    d[key] = +d[key];
                  }
                  d.time++; //time is given in miliseconds, so we need to make them a datetime value

                  return norm;
                },
                (err) => {
                  console.log("ERROR");
                  console.log(err);
                }
              )
              .then((norm) => {
                // if (error) throw error;

                // the x and y scales are already created, we just need to add domains to them
                plot.x_scale.domain([
                  d3.min(chart_data, function (c) {
                    return d3.min(c.values, function (v) {
                      return v.time;
                    });
                  }),
                  d3.max(chart_data, function (c) {
                    return d3.max(c.values, function (v) {
                      return v.time;
                    });
                  }),
                ]);
                plot.y_scale.domain([
                  d3.min([
                    d3.min(chart_data, function (c) {
                      return d3.min(c.values, function (v) {
                        return v.value;
                      });
                    }),
                    d3.min(norm, function (d) {
                      return d.median - (d.median - d.lower) * 2;
                    }),
                  ]),
                  d3.max([
                    d3.max(chart_data, function (c) {
                      return d3.max(c.values, function (v) {
                        return v.value;
                      });
                    }),
                    d3.max(norm, function (d) {
                      return d.median + (d.upper - d.median) * 2;
                    }),
                  ]),
                ]);
                //create the line constructor
                // This is done here, so that it is updated everytime the domain changes
                plot.line_const = d3
                  .line()
                  .curve(d3.curveMonotoneX)
                  .x((d) => {
                    return plot.x_scale(d.time);
                  })
                  // the key value will be created by us, it doesn't come in the tsv
                  .y((d) => {
                    return plot.y_scale(d.value);
                  });

                // append the axis to the svg
                plot.x_appended_axis = plot.wrapper
                  .append("g")
                  .attr(
                    "transform",
                    `translate(${plot.margin.left}, ${
                      plot.inner_height + plot.margin.top
                    })`
                  )
                  .attr("class", "axis")
                  .call(plot.x_axis);
                plot.y_appended_axis = plot.wrapper
                  .append("g")
                  .attr(
                    "transform",
                    `translate(${plot.margin.left}, ${plot.margin.top})`
                  )
                  .attr("class", "axis")
                  .call(plot.y_axis);
                // add the X gridlines
                plot.wrapper
                  .append("g")
                  .attr(
                    "transform",
                    `translate(${plot.margin.left}, ${
                      plot.inner_height + plot.margin.top
                    })`
                  )
                  .attr("class", "grid")
                  .call(
                    d3
                      .axisBottom(plot.x_scale)
                      .ticks(tick_num)
                      .tickSize(-plot.inner_height)
                      .tickFormat("")
                  );

                // add the Y gridlines
                plot.wrapper
                  .append("g")
                  .attr(
                    "transform",
                    `translate(${plot.margin.left}, ${plot.margin.top})`
                  )
                  .attr("class", "grid")
                  .call(
                    d3
                      .axisLeft(plot.y_scale)
                      .ticks(tick_num)
                      .tickSize(-plot.inner_width)
                      .tickFormat("")
                  );

                place_clippath(card.id, plot);
                plot.norm_group = place_norm(plot, norm);
                plot.lines_group = place_lines(
                  chart_data,
                  plot.wrapper,
                  plot.inner_width,
                  plot.inner_height,
                  plot.margin.top,
                  plot.margin.left,
                  plot.line_const,
                  plot.color_scale
                );

                /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
                /* ZOOM STUFF  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
                /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
                plot.xzoom = d3.zoom().on("zoom", perform_xzoom);
                plot.yzoom = d3.zoom().on("zoom", perform_yzoom);
                // append the zoom detecting areas to the svg
                plot.xzoom_area = plot.wrapper
                  .append("svg:rect")
                  .attr("class", "zoom xy box")
                  .attr("width", plot.inner_width)
                  .attr("height", plot.margin.top + plot.margin.bottom)
                  .attr(
                    "transform",
                    `translate(${plot.margin.left}, ${
                      plot.inner_height + plot.margin.top
                    })`
                  )
                  .style("visibility", "hidden")
                  .attr("pointer-events", "all");

                plot.yzoom_area = plot.wrapper
                  .append("svg:rect")
                  .attr("class", "zoom y box")
                  .attr("width", 40)
                  .attr("height", plot.inner_height)
                  .attr(
                    "transform",
                    `translate(${-40 / 2}, ${plot.margin.top})`
                  )
                  .style("visibility", "hidden")
                  .attr("pointer-events", "all");

                plot.xzoom_area
                  .on("mouseover", function () {
                    if (plot.zoomable) {
                      d3.select(this).style("cursor", "col-resize");
                    }
                  })
                  .on("mouseout", function () {
                    d3.select(this).style("cursor", "default");
                  })
                  .on("dblclick", function () {
                    plot.xzoom.transform(plot.xzoom_area, d3.zoomIdentity);
                  });

                plot.yzoom_area
                  .on("mouseover", function () {
                    if (plot.zoomable) {
                      d3.select(this).style("cursor", "row-resize");
                    }
                  })
                  .on("mouseout", function () {
                    d3.select(this).style("cursor", "default");
                  })
                  .on("dblclick", function () {
                    plot.yzoom.transform(plot.yzoom_area, d3.zoomIdentity);
                  });

                plot.new_x_scale = plot.x_scale;
                function perform_xzoom() {
                  var transform = d3.event.transform;
                  plot.new_x_scale = transform.rescaleX(plot.x_scale);
                  plot.x_appended_axis.call(
                    plot.x_axis.scale(plot.new_x_scale)
                  );

                  var transf = getTransformation(
                    plot.lines_group.attr("transform")
                  );
                  plot.lines_group.attr(
                    "transform",
                    `translate(${plot.margin.left + transform.x}, ${
                      transf.translateY
                    }) scale(${transform.k}, ${transf.scaleY})`
                  );
                  plot.norm_group
                    .selectAll("path")
                    .attr(
                      "transform",
                      `translate(${plot.margin.left + transform.x}, ${
                        transf.translateY
                      }) scale(${transform.k}, ${transf.scaleY})`
                    );
                }

                plot.new_y_scale = plot.y_scale;
                function perform_yzoom() {
                  var transform = d3.event.transform;
                  plot.new_y_scale = transform.rescaleY(plot.y_scale);
                  plot.y_appended_axis.call(
                    plot.y_axis.scale(plot.new_y_scale)
                  );

                  var transf = getTransformation(
                    plot.lines_group.attr("transform")
                  );
                  plot.lines_group.attr(
                    "transform",
                    `translate(${transf.translateX}, ${
                      plot.margin.top + transform.y
                    }) scale(${transf.scaleX}, ${transform.k})`
                  );
                  plot.norm_group
                    .selectAll("path")
                    .attr(
                      "transform",
                      `translate(${transf.translateX}, ${
                        plot.margin.top + transform.y
                      }) scale(${transf.scaleX}, ${transform.k})`
                    );
                }
              });
          });
      }

      function get_plot_object(rect, plot_id) {
        let margin = { top: 20, right: 0, bottom: 20, left: 20 },
          inner_width = rect.width - margin.left - margin.right,
          inner_height = rect.height - margin.top - margin.bottom,
          x_scale = d3.scaleLinear().rangeRound([0, inner_width]),
          y_scale = d3.scaleLinear().rangeRound([inner_height, 0]);

        return {
          wrapper: d3
            .select(`#${card.id} #${plot_id}`)
            .append("svg")
            .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
            .attr("preserveAspectRatio", "xMidYMin meet"),
          margin: margin,

          inner_width: inner_width,
          inner_height: inner_height,

          x_scale: x_scale,
          y_scale: y_scale,
          x_axis: d3.axisBottom().scale(x_scale),
          y_axis: d3.axisLeft().scale(y_scale),
          // create color scale and domain
          color_scale: d3.scaleOrdinal(d3ScaleChromatic.schemeTableau10), // d3.scaleOrdinal().range(["#6F257F", "#CA0D59"])
        };
      }

      function place_lines(
        chart_data,
        svg,
        inner_width,
        inner_height,
        margin_top,
        margin_left,
        line,
        color_scale
      ) {
        // create the data group that olds the chart_data and that is within the clipPath
        var lines_group = svg
          .selectAll(".event-plot-lines-group")
          .data(chart_data)
          .enter()
          .append("g")
          .attr("clip-path", `url(#${card.id}-clip)`)
          .attr("class", "event-plot-lines-group");

        // add all the lines paths
        return lines_group
          .append("path")
          .attr("class", "event-plot-line")
          .attr("id", function (d) {
            return `event-line-${d.key}`;
          })
          .attr("d", (d) => {
            return line(d.values);
          })
          .attr("fill", "none")
          .attr("transform", `translate(${margin_left}, ${margin_top})`)
          .classed("hide-svg-element", (d) => d.visibility === "hidden")
          .style("stroke", (d) => {
            return color_scale(d.key);
          });
      }

      function place_norm(plot, norm) {
        let upper_area = d3
            .area()
            // .curve(d3.curveMonotoneX)
            .curve(d3.curveBasisOpen)
            .x((d) => {
              return plot.x_scale(d.time);
            })
            .y0((d) => {
              return plot.y_scale(d.median);
            })
            .y1((d) => {
              return (
                plot.y_scale(d.median) +
                (plot.y_scale(d.upper) - plot.y_scale(d.median)) * 2
              );
            }),
          lower_area = d3
            .area()
            // .curve(d3.curveMonotoneX)
            .curve(d3.curveBasisOpen)
            .x((d) => {
              return plot.x_scale(d.time);
            })
            .y0((d) => {
              return plot.y_scale(d.median);
            })
            .y1((d) => {
              return (
                plot.y_scale(d.median) -
                (plot.y_scale(d.median) - plot.y_scale(d.lower)) * 2
              );
            }),
          median_line = d3
            .line()
            .curve(d3.curveMonotoneX)
            .x((d) => {
              return plot.x_scale(d.time);
            })
            .y((d) => {
              return plot.y_scale(d.median);
            }),
          norm_group = plot.wrapper
            .selectAll(".event-normative-line")
            .data([norm])
            .enter()
            .append("g")
            .attr("clip-path", `url(#${card.id}-clip)`)
            .attr("class", "event-normative-line");

        norm_group
          .append("path")
          .attr("class", "event-plot-normal-area")
          // .attr("id",function(d){ return `event-line-${d.key}`; })
          .attr("d", (d) => {
            return lower_area(d);
          })
          .attr(
            "transform",
            `translate(${plot.margin.left}, ${plot.margin.top})`
          );

        norm_group
          .append("path")
          .attr("class", "event-plot-normal-area")
          .attr("title", "Normal Area")
          // .attr("id",function(d){ return `event-line-${d.key}`; })
          .attr("d", (d) => {
            return upper_area(d);
          })
          .attr(
            "transform",
            `translate(${plot.margin.left}, ${plot.margin.top})`
          );

        norm_group
          .append("path")
          .attr("class", "event-plot-normal-line")
          .attr("title", "Normal Area")
          // .attr("id",function(d){ return `event-line-${d.key}`; })
          .attr("d", (d) => {
            return median_line(d);
          })
          .attr(
            "transform",
            `translate(${plot.margin.left}, ${plot.margin.top})`
          )
          .attr("fill", "none");
        return norm_group;
      }

      function place_clippath(card_id, plot) {
        //adding a clipPath to the svg that is the exact size of the visible area, to avid lines overflow on zoom
        plot.wrapper
          .append("defs")
          .append("clipPath")
          .attr("id", `${card_id}-clip`)
          .append("rect")
          .attr("width", plot.inner_width)
          .attr("height", plot.inner_height)
          .attr(
            "transform",
            `translate(${plot.margin.left}, ${plot.margin.top})`
          );
      }

      function add_label_to_card(
        side,
        data_key,
        plot,
        event_limits,
        movement_type,
        event_offset
      ) {
        let card = side == 0 ? left_div : right_div,
          label_color = plot.color_scale(data_key),
          label_div_id = `events-label-${data_key}`;

        if (card.querySelector(`#${label_div_id}`) !== null) {
          return;
        }

        let label_div = create_label_div(card, label_div_id, data_key), //if the div exists get it, if not, create it
          label_span = document.createElement("span"); //create the element that will be clickable and colored in accordance to the line color

        /* label_span.innerText = data_key;
        label_span.style.background = label_color; */
        if (event_limits[4] == 0) {
          label_span.classList.add("lightgray");
        }
        // add event listeners
        label_span.addEventListener("click", label_click);
        label_span.addEventListener("touch", label_click);
        label_span.addEventListener("mouseenter", label_mouseenter);
        label_span.addEventListener("mouseout", label_mouseout);

        // label_div.appendChild(label_span);

        function create_label_div(card, label_id, label_text) {
          let label_container = document.createElement("div");

          label_container.id = label_id;
          label_container.classList = `events-plot-label-container ${
            event_limits[4] === 0 ? "plot-disabled" : ""
          }`;

          label_container.style.background = label_color;
          label_container.innerText = data_key;

          label_container.addEventListener("click", label_click);
          label_container.addEventListener("dblclick", label_dbclick);
          label_container.addEventListener("mouseenter", label_mouseenter);
          label_container.addEventListener("mouseout", label_mouseout);

          card
            .querySelector(".events-plot-labels")
            .appendChild(label_container);
          return label_container;
        }

        // the button is clicked. Remeber that it is already on the state of mouse enter
        function label_click() {
          if (!this.classList.contains("wait-mouseout")) {
            // the button was clicked but the mouse is still on top of it
            if (!this.classList.contains("plot-disabled")) {
              d3.select(`#${card.id} #event-line-${data_key}`).classed(
                "hide-svg-element",
                true
              );
              this.classList.add("wait-mouseout");
            }
          } else {
            if (this.classList.contains("plot-disabled")) {
              d3.select(`#${card.id} #event-line-${data_key}`).classed(
                "hide-svg-element",
                false
              );
            } else {
              d3.select(`#${card.id} #event-line-${data_key}`).classed(
                "hide-svg-element",
                true
              );
            }
          }
          this.classList.toggle("plot-disabled");
        }

        function label_dbclick() {
          // Hide all plots
          d3.selectAll(`#${card.id} .event-plot-line`).classed(
            "hide-svg-element",
            true
          );

          // Show intended plot
          d3.selectAll(`#${card.id} #event-line-${data_key}`).classed(
            "hide-svg-element",
            false
          );

          const labels = card.querySelectorAll(`.events-plot-label-container`);
          [...labels].slice(1).map((label) => {
            label.classList.add("plot-disabled");
          });
          card
            .querySelector(`#events-label-${data_key}`)
            .classList.remove("plot-disabled");
          /* d3.selectAll(`#${card.id} .event-plot-line`).toggle(
            "hide-svg-element"
          ); */
          /* event_in_edition[side] = data_key;
            event_in_edition_name[side] = movement_type;
            let event_limits = event_limits_aux[side][event_in_edition[side]],
                line_in_edition = d3.select(`#${card.id} .edit-event-line`);
            if(line_in_edition.empty()){
                enable_edit_buttons(plot, event_limits[4]);
                let lines = d3.selectAll(`#${card.id} .event-plot-line`)
                            .classed("hide-svg-element", true);
                // d3.select(`#${card.id} #event-line-${data_key}`).classed("hide-svg-element", false);
            } else{
                line_in_edition.remove();
            }
            let labels = card.querySelectorAll(`.events-plot-labels span`);
            [...labels].map( label_elem => label_elem.classList.add('disabled'));
            this.classList.remove('disabled');
        
            activate_event_edition(side, event_limits, movement_type, plot, label_color, event_offset); */
        }

        function label_mouseenter() {
          d3.select(`#${card.id} #event-line-${data_key}`).classed(
            "bold-line",
            true
          );
          if (this.classList.contains("plot-disabled")) {
            d3.select(`#${card.id} #event-line-${data_key}`).classed(
              "hide-svg-element",
              false
            );
          }
        }

        function label_mouseout() {
          d3.select(`#${card.id} #event-line-${data_key}`).classed(
            "bold-line",
            false
          );
          if (this.classList.contains("plot-disabled")) {
            d3.select(`#${card.id} #event-line-${data_key}`).classed(
              "hide-svg-element",
              true
            );
            this.classList.remove("wait-mouseout");
          }
        }
      } // Labels Functions

      // function toogle_edit_buttons(plot) {
      //   plot.save_button.style.display === "none"
      //     ? (plot.save_button.style.display = "inherit")
      //     : (plot.save_button.style.display = "none");
      //   plot.save_button.classList.toggle("disabled");
      //   plot.save_button.classList.toggle("green");

      //   plot.undo_button.style.display === "none"
      //     ? (plot.undo_button.style.display = "inherit")
      //     : (plot.undo_button.style.display = "none");
      //   plot.undo_button.classList.toggle("disabled");
      //   plot.undo_button.classList.toggle("blue");

      //   plot.undo_button.style.display === "none"
      //     ? (plot.delete_button.style.display = "inherit")
      //     : (plot.delete_button.style.display = "none");
      //   plot.undo_button.classList.toggle("disabled");
      //   plot.undo_button.classList.toggle("blue");
      // }
      // function enable_edit_buttons(plot, visible) {
      //   plot.save_button.style.display = "inherit";
      //   plot.save_button.classList.remove("disabled");
      //   plot.save_button.classList.add("green");

      //   plot.undo_button.style.display = "inherit";
      //   plot.undo_button.classList.remove("disabled");
      //   plot.undo_button.classList.add("blue");

      //   plot.delete_button.style.display = "inherit";
      //   plot.delete_button.classList.remove("disabled");
      //   if (visible == 1) {
      //     plot.delete_button.innerHTML = `<i class="icon close" aria-hidden="true"></i>`;
      //     plot.delete_button.classList.add("red");
      //   } else {
      //     plot.delete_button.innerHTML = `<i class="icon check" aria-hidden="true"></i>`;
      //     plot.delete_button.classList.add("green");
      //   }
      // }
      function disable_edit_buttons(plot) {
        plot.save_button.style.display = "none";
        plot.save_button.classList.add("plot-disabled");
        plot.save_button.classList.remove("green");

        plot.undo_button.style.display = "none";
        plot.undo_button.classList.add("plot-disabled");
        plot.undo_button.classList.remove("blue");

        plot.delete_button.style.display = "none";
        plot.delete_button.classList.add("plot-disabled");
        plot.delete_button.classList.remove("red");
      }

      // function activate_event_edition(
      //   side,
      //   event_limits,
      //   movement_type,
      //   plot,
      //   label_color,
      //   event_offset
      // ) {
      //   plot.zoom_button.style.display = "none";
      //   plot.hide_button.style.display = "none";
      //   restore_zoom(plot);
      //   let movement_key =
      //     side === 0 ? movement_type + "_l" : movement_type + "_r";
      //   // start parsing the tsv file
      //   if (parsed_data_file === undefined) {
      //     d3.text(file_name, function (tsv_file) {
      //       // remove comment rows with regex - not fully tested, but should work
      //       tsv_file = tsv_file.replace(/[\w=.*\n]+(time)/g, "time");

      //       var resample = d3.tsvParse(tsv_file, (d) => {
      //         // the tsv lines are composed of strings. so we need to parse them to ints
      //         for (var key in d) {
      //           if (key !== "time") {
      //             let polarity = key.includes("knee") ? -1 : 1;
      //             d[key] = ((+d[key] * 180) / 3.1415926) * polarity;
      //           } else {
      //             d[key] = +d[key];
      //           }
      //         }
      //         return d;
      //       });

      //       function draw_resample(data) {
      //         parsed_data_file = Object.keys(data[0])
      //           .filter((key) => {
      //             return key !== "time";
      //           })
      //           .map((key) => {
      //             // in this category (represented by the key) for each line, we will parse a time and value
      //             return {
      //               key: key,
      //               values: data.map((d) => {
      //                 return { time: d.time, value: d[key] + event_offset };
      //               }),
      //             };
      //           });
      //         event_edition(
      //           side,
      //           event_limits,
      //           movement_key,
      //           parsed_data_file,
      //           plot,
      //           label_color
      //         );
      //       }
      //       draw_resample(resample);
      //     });
      //   } else {
      //     event_edition(
      //       side,
      //       event_limits,
      //       movement_key,
      //       parsed_data_file,
      //       plot,
      //       label_color
      //     );
      //   }
      // }

      // function delete_event(side, plot, side_div) {
      //   event_limits_aux[side][event_in_edition[side]][4] =
      //     event_limits_aux[side][event_in_edition[side]][4] == 1 ? 0 : 1;
      //   if (event_limits_aux[side][event_in_edition[side]][4] == 1) {
      //     plot.delete_button.innerHTML = `<i class="icon close" aria-hidden="true"></i>`;
      //     plot.delete_button.classList.remove("green");
      //     plot.delete_button.classList.add("red");
      //     side_div
      //       .querySelector(`.events-plot-labels span:not(.disabled)`)
      //       .classList.remove("lightgray");
      //   } else {
      //     plot.delete_button.innerHTML = `<i class="icon check" aria-hidden="true"></i>`;
      //     plot.delete_button.classList.remove("red");
      //     plot.delete_button.classList.add("green");
      //     side_div
      //       .querySelector(`.events-plot-labels span:not(.disabled)`)
      //       .classList.add("lightgray");
      //   }
      // }

      // function event_edition(
      //   side,
      //   event_limits,
      //   movement_key,
      //   parsed_data_file,
      //   plot,
      //   label_color
      // ) {
      //   let line_data = parsed_data_file.find((movement_data) => {
      //       return movement_data.key == movement_key;
      //     }).values,
      //     data_line_group = plot.wrapper
      //       .selectAll(".edit-event-line")
      //       .data([line_data])
      //       .enter()
      //       .append("g")
      //       .attr("clip-path", `url(#${card.id}-clip)`)
      //       .attr("class", "edit-event-line");

      //   // console.log(plot.x_appended_axis);
      //   plot.x_scale.domain([
      //     d3.min(line_data, (d) => {
      //       return d.time;
      //     }),
      //     d3.max(line_data, (d) => {
      //       return d.time;
      //     }),
      //   ]);

      //   plot.y_scale.domain([
      //     d3.min(line_data, (d) => {
      //       return d.value;
      //     }),
      //     d3.max(line_data, (d) => {
      //       return d.value;
      //     }),
      //   ]);

      //   //create the line constructor
      //   // This is done here, so that it is updated everytime the domain changes
      //   let line_const = d3
      //     .line()
      //     .curve(d3.curveMonotoneX)
      //     .x((d) => {
      //       return plot.x_scale(d.time);
      //     })
      //     // the key value will be created by us, it doesn't come in the tsv
      //     .y((d) => {
      //       return plot.y_scale(d.value);
      //     });

      //   // add all the lines paths
      //   data_line_group
      //     .append("path")
      //     .attr("class", "event-plot-line")
      //     .attr("id", `event-line-${movement_key}`)
      //     .attr("d", (d) => {
      //       return line_const(d);
      //     })
      //     .attr("fill", "none")
      //     .attr(
      //       "transform",
      //       `translate(${plot.margin.left}, ${plot.margin.top})`
      //     )
      //     .style("stroke", label_color);

      //   let zoom_event = d3.zoom().on("zoom", perform_event_zoom);

      //   function perform_event_zoom() {
      //     let transform = d3.event.transform;
      //     plot.new_x_scale = transform.rescaleX(plot.x_scale);
      //     plot.x_appended_axis.call(plot.x_axis.scale(plot.new_x_scale));

      //     data_line_group.selectAll("path").attr(
      //       "d",
      //       line_const.x((d) => {
      //         return plot.new_x_scale(d.time);
      //       })
      //     );
      //     event_limits_aux[side][event_in_edition[side]][1] = Math.round(
      //       plot.new_x_scale.domain()[0]
      //     );
      //     event_limits_aux[side][event_in_edition[side]][3] = Math.round(
      //       plot.new_x_scale.domain()[1]
      //     );
      //     // event_limits_aux[side][event_in_edition[side]][4] = 1;
      //     // console.log(event_limits_aux[side][event_in_edition[side]])
      //   }

      //   plot.x_appended_axis.call(plot.x_axis);

      //   // plot.wrapper.call(zoom_event).transition(t)
      //   plot.xzoom.transform(plot.xzoom_area, d3.zoomIdentity);

      //   plot.xzoom_area
      //     .call(zoom_event)
      //     .on("dblclick.zoom", null)
      //     .transition(t)
      //     .call(
      //       zoom_event.transform,
      //       d3.zoomIdentity
      //         .scale(
      //           plot.inner_width /
      //             (plot.x_scale(event_limits[3]) -
      //               plot.x_scale(event_limits[1]))
      //         )
      //         .translate(-plot.x_scale(event_limits[1]), 0)
      //     );

      //   // plot.xzoom_area

      //   plot.xzoom_area
      //     .on("mouseover", function () {
      //       d3.select(this).style("cursor", "col-resize");
      //     })
      //     .on("mouseout", function () {
      //       d3.select(this).style("cursor", "default");
      //     })
      //     .on("dblclick", function () {
      //       zoom_event.transform(plot.xzoom_area, d3.zoomIdentity);
      //     });
      // }

      // function save(exam_id, side, plot) {
      //   // console.log(event_limits_aux[side])
      //   d3.json(event_files.save_data)
      //     .header("X-CSRFToken", getCookie("csrftoken"))
      //     .header("Content-Type", "application/json")
      //     .post(
      //       JSON.stringify({
      //         evaluation_id: exam_id,
      //         movement: event_in_edition_name[side],
      //         side: side,
      //         events: event_limits_aux[side],
      //         module_id: module_id,
      //         sequence_id: sequence_id,
      //         question_id: question_id,
      //       }),
      //       (error, result) => {
      //         // if (result.success) {
      //         //   // toogleDone(true);
      //         //   // plot.undo_button.click();
      //         //   // location.reload(true);
      //         //   refesh_card(card.getAttribute("data-card_id"));
      //         //   refesh_card(22);
      //         // } else {
      //         //   toogleDone(false, result.message);
      //         // }
      //         // //Display the done pop up, and then populate it with the right content according to the result
      //         // toogleDone(data !== null);
      //         // plot.undo_button.click();
      //       }
      //     );
      // }
      //   function restore_zoom(plot) {
      //     // deactivate the zoom functionality
      //     plot.zoom_button.classList.add("disabled");
      //     plot.zoomable = false;
      //     plot.xzoom_area.on(".zoom", null);
      //     plot.yzoom_area.on(".zoom", null);

      //     //restore the norm and lines original position
      //     plot.lines_group.attr(
      //       "transform",
      //       `translate(${plot.margin.left}, ${plot.margin.top}) scale(1, 1)`
      //     );
      //     plot.norm_group
      //       .selectAll("path")
      //       .attr(
      //         "transform",
      //         `translate(${plot.margin.left}, ${plot.margin.top}) scale(1, 1)`
      //       );
      //   }
      // }

      // function getCookie(name) {
      //   let cookieValue = null;
      //   if (document.cookie && document.cookie !== "") {
      //     let cookies = document.cookie.split(";");
      //     for (let i = 0; i < cookies.length; i++) {
      //       let cookie = jQuery.trim(cookies[i]);
      //       // Does this cookie string begin with the name we want?
      //       if (cookie.substring(0, name.length + 1) === name + "=") {
      //         cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
      //         break;
      //       }
      //     }
      //   }
      //   return cookieValue;
      // }

      var getClosest = function (elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
          Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
              var matches = (
                  this.document || this.ownerDocument
                ).querySelectorAll(s),
                i = matches.length;
              while (--i >= 0 && matches.item(i) !== this) {}
              return i > -1;
            };
        }
        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
          if (elem.matches(selector)) return elem;
        }
        return null;
      };
    }
    if (data === null)
      create_card(
        reff.current,
        20,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    if (data) build_events_plot(reff.current, data);
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default EventsChart;
