import React, { useEffect } from "react";
import $ from "jquery";

const TextInput = ({
  value = "",
  onChange = () => {},
  id,
  name,
  placeholder = "",
  disabled = false,
}) => {
  useEffect(() => {
    setTimeout(() => {
      const jsValue = `${$(`#${id}`)[0].value}`;
      if (jsValue !== value) {
        onChange({ target: { value: jsValue } });
      }
    }, 1);
  });
  return (
    <input
      autoComplete="off"
      value={value}
      onChange={onChange}
      className={`ev-mod-text-input`}
      id={id}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default React.memo(TextInput);
