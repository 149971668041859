import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_IS_LOGGEDIN_SUCCESS,
  GET_PERSONAL_DATA_SUCCESS,
  GET_PERSONAL_DATA_FAIL,
  SET_PERSONAL_DATA_SUCCESS,
  GET_PROFILE_WIDGET_INFO_SUCCESS,
} from "../actions/user";

const initialState = {
  personal_data: {
    username: "",
    email: "",
    full_name: "",
    phone: "",
    birthdate: "",
    language_id: null,
    photo: null,
  },
  isAuthenticated: false,
  widget_info: {
    username: "",
    name: "",
    photo: "",
  },
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case USER_IS_LOGGEDIN_SUCCESS: {
      return { ...state, ...action.payload.data };
    }
    case USER_LOGIN_SUCCESS: {
      return { ...state, isAuthenticated: true };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS:
      return { ...initialState };
    case GET_PERSONAL_DATA_SUCCESS:
      return { ...state, personal_data: action.payload.data.data };
    case SET_PERSONAL_DATA_SUCCESS:
      return { ...state, personal_data: action.payload.data.data };
    case GET_PERSONAL_DATA_FAIL:
      return { ...state, personal_data: initialState.personal_data };
    case GET_PROFILE_WIDGET_INFO_SUCCESS: {
      return { ...state, widget_info: action.payload.data.data };
    }
    default:
      return state;
  }
};

export default User;
