//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import MedicinesModulesList from "../partials/medicinesModuleList.js";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import { getPrescriptionsDetails } from "../../../../redux/actions/patientPrescriptions.js";
import { errors } from "../../../../constants/general.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";

const ShowPrescription = (props) => {
  //* *************************************** SETUP ***************************************
  const { patient_id, prescription_id } = useParams();
  const prescriptionInfo = useSelector(
    (state) => state.patientsPrescriptions.curr_prescription
  );

  const dispatch = useDispatch();

  const currentInstitutionId = useSelector(
    (state) => state.institutions.current_institution.id
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getPrescriptionsDetails(patient_id, prescription_id)).catch(() => {
      alert(errors.service_error);
    });
  }, []);

  //* ************************************** HANDLERS **************************************
  const goBack = () =>
    props.history.replace(`/patient/${patient_id}/mkinetikos_prescriptions`);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img onClick={goBack} alt="back Button" src={bigBackButton} />
        <h4>{prescriptionInfo.name}</h4>
      </div>
      <div className="position-relative">
        <div className="create-exercise-box flex-d-column regular-text black-color">
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">{t("patient.meds.details")}</div>
            {currentInstitutionId === prescriptionInfo.inst_id && (
              <div className="mt-1">
                <button
                  onClick={() =>
                    props.history.push(
                      `/patient/${patient_id}/mkinetikos_prescriptions/${prescription_id}/edit`
                    )
                  }
                  className="primary-button small-button"
                >
                  {t("buttons.edit")}
                </button>
              </div>
            )}
          </div>
          <div className="input-label-text mt-4">
            {t("patient.meds.activate_deactivate")}
          </div>
          <div className="regular-text  flex-d-row align-items-center disabled-container">
            <span className="mr-1">{t("patient.meds.deactivate")}</span>
            <div className="form-check form-switch">
              <input
                disabled={true}
                className="form-check-input shadow-none"
                checked={prescriptionInfo.active}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
            </div>
            <span className="ml-1">{t("patient.meds.activate")}</span>
          </div>
          <label className="input-label-text mt-3">
            {" "}
            {t("patient.meds.prescription_name")}{" "}
          </label>
          <div className="regular-text black-color mt-1">
            {prescriptionInfo.name || t("patient.meds.patient_prescription")}
          </div>
          <div className="check-box-wrapper mt-3 disabled-container">
            <div className="input-wrapper col-md-auto">
              <input
                disabled={true}
                checked={prescriptionInfo.user_can_edit}
                name="save_for_institution"
                type={"checkbox"}
                id="checkbox"
              />
            </div>

            <label type="checkbox" htmlFor="checkbox">
              <span className="regular-text">
                {t("patient.meds.allow_edit")}
              </span>
            </label>
          </div>
          <div className="regular-text mt-4">{t("patient.meds.meds")}</div>

          <div className="mt-2" disabled={!prescriptionInfo.active}>
            <MedicinesModulesList
              currentInstId={currentInstitutionId}
              medicines={prescriptionInfo.medicines}
              optionsEnabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPrescription;
