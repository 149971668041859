//* ********************************** REACT IMPORTS **********************************
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
import MessageBox from "../../../components/messageBox";
//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import show_password_logo from "../../../assets/images/show-password.svg";
import hide_password_logo from "../../../assets/images/hide-password.svg";
import {
  getPasswordExpireTime,
  getPersonalData,
  isUserAuthenticated,
  login,
  userLogout,
} from "../../../redux/actions/user";
import { getCurrentInstitution } from "../../../redux/actions/institutions";
import { clearRedirectTo } from "../../../redux/actions/redirect";
import { changeLanguage } from "../../../utils/language";
import validator from "validator";

const Login = ({
  navigateToHome,
  changeToSignUp,
  changeToForgotAccount,
  showErrorPage,
  changeToActivateAccount,
  userInfoCallback,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const redirectTo = useSelector((state) => state.redirectTo.url);

  const MAX_ATTEMP = 4;

  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [showWrongCredentialsError, setShowWrongCredentialsError] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isCheckingAuthenticated, setIsCheckingAuthenticated] = useState(false);
  const [remainingLoginAttempts, setRemainingLoginAttempts] = useState(null);

  const responses = {
    userNotActive: "User not active",
  };

  //* ************************************** HANDLERS **************************************
  const inputsHandler = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const passwordCheck = () => {
    return dispatch(getPasswordExpireTime()).then((res) => {
      if (res.payload.data) {
        const days = res.payload.data.days_until_update;
        /** also check multiFactorAuth.js (line: 66) */
        if (days <= 15) {
          history.push("/password_check", { days: days });
          throw new Error("Update password");
        } else {
          history.replace(redirectTo);
          dispatch(clearRedirectTo());
        }
      }
    });
  };

  // We need to move this to the middleware
  const afterLoginActions = () => {
    // This will ensure MFA
    dispatch(getPersonalData())
      .then((res) => changeLanguage(res.payload.data.data.language_id))
      .then(passwordCheck)
      .then(() => dispatch(getCurrentInstitution()))
      .then((res) => {
        // Only go to home if there are no pending invitations, but this check should be made in the middleware
        if (res.payload.data.msg !== "user_pending_invitation") {
          navigateToHome();
        } else {
          console.warn("TODO: MOVE THIS CHECK TO THE MIDDLEWARE LATER TO AVOID REDUNDANCY!");
        }
      });
    /* dispatch(getCurrentInstitution()).then((res) => {
      // Only go to home if there are no pending invitations, but this check should be made in the middleware
      if (res.payload.data.msg !== "user_pending_invitation") {
        navigateToHome();
      } else {
        console.warn("TODO: MOVE THIS CHECK TO THE MIDDLEWARE LATER TO AVOID REDUNDANCY!");
      }
    }); */
  };

  const submitLogin = (e) => {
    e.preventDefault();
    if (showWrongCredentialsError) setShowWrongCredentialsError(false);
    setIsLoginLoading(true);

    // preparing data for login
    let data = {
      email: validator.isEmail(loginCredentials.username) ? loginCredentials.username : undefined,
      username: !validator.isEmail(loginCredentials.username)
        ? loginCredentials.username
        : undefined,
      password: loginCredentials.password,
    };
    userInfoCallback(data.email ? { email: data.email } : { username: data.username });

    dispatch(login(data))
      .then(afterLoginActions)
      .catch((error) => {
        if (error.error.response) {
          const { status, data } = error.error.response;
          if (data?.data?.remaining_attempts) {
            setRemainingLoginAttempts(error.error.response.data.data.remaining_attempts);
          }
          if (status === 401) {
            if (data?.error === responses.userNotActive) {
              changeToActivateAccount();
            } else {
              setShowWrongCredentialsError(true);
            }
          } else {
            showErrorPage();
          }
        }
      })
      .finally(() => {
        setIsLoginLoading(false);
      });
  };

  const isLoginDisable =
    isCheckingAuthenticated ||
    loginCredentials.username === "" ||
    loginCredentials.password === "" ||
    isLoginLoading;

  useEffect(() => {
    // Quick hack to prevent sending back through login with an active session, which will log out the user.
    // Exceptions should be made, such as if there are actions which require an active session
    const shouldLogoutUser =
      !window.location.pathname.includes("password_expired") &&
      !window.location.pathname.includes("setup_multifactor_auth");

    if (shouldLogoutUser && isAuthenticated) {
      setIsCheckingAuthenticated(true);
      dispatch(userLogout())
        .then((res) => {
          setIsCheckingAuthenticated(false);
        })
        .finally(() => setIsCheckingAuthenticated(false));
    }
  }, []);

  const __renderWrongCredentialsMessage = () => {
    return [
      remainingLoginAttempts <= MAX_ATTEMP && t("authentication.login.invalid_username_password"),
      remainingLoginAttempts &&
        (remainingLoginAttempts === "max_attempts_reached" ? (
          t("authentication.login.max_login_attempts")
        ) : (
          <Trans i18nKey={"authentication.login.remaining_attempts"}>
            {remainingLoginAttempts?.toString()}
          </Trans>
        )),
    ];
  };

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <h2>{t("titles.login")}</h2>

      <form style={{ marginTop: "1.44em" }} onSubmit={submitLogin}>
        <label className="input-label-text">{t("user_info.username_or_email")}</label>
        <input
          onChange={inputsHandler}
          value={loginCredentials.username}
          name="username"
          type="text"
          id="username"
          placeholder={t("placeholders.username")}
          className="form-input"
        />
        <label className="input-label-text">{t("authentication.password.password")}</label>
        <div className="input-image-wrapper image-right">
          <input
            onChange={inputsHandler}
            autoComplete={"off"}
            value={loginCredentials.password}
            name="password"
            id="password"
            type={isPasswordHidden ? "password" : "text"}
            placeholder={t("placeholders.password")}
            className="form-input"
          />
          <img
            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
            alt="Password Show and Hide"
            className="input-right-image"
            src={isPasswordHidden ? show_password_logo : hide_password_logo}
          />
        </div>
        {showWrongCredentialsError && (
          <MessageBox type={"warning"} messages={__renderWrongCredentialsMessage()} />
        )}
        <div className="form-auth-bottom">
          <div className="input-image-wrapper m-0">
            <input
              disabled={isLoginDisable}
              value={isLoginLoading ? "" : t("buttons.log_in")}
              type="submit"
              className="primary-button small-button w-100"
            />
            {isLoginLoading && <Spinner type={"small"} />}
          </div>

          <div className="auth-bottom-element">
            <span className="regular-text">{t("authentication.login.no_account")}&nbsp;</span>
            <span onClick={changeToSignUp} className="link-text">
              {t("authentication.login.signup")}
            </span>
          </div>
          <div className="auth-bottom-element">
            <span onClick={changeToForgotAccount} className="link-text">
              {t("authentication.login.forgot_account")}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
