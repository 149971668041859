// Don't change the description fields since they're used as keys in the translation.json files for posterior translation
// If you really have to change these fields, make sure you change the corresponding keys in the translation files

const Nationalities = [
  {
    id: 1,
    description: "Albanian",
  },
  {
    id: 2,
    description: "Emirian",
  },
  {
    id: 3,
    description: "Afghan",
  },
  {
    id: 4,
    description: "Antiguans, Barbudans",
  },
  {
    id: 5,
    description: "Andorran",
  },
  {
    id: 6,
    description: "Armenian",
  },
  {
    id: 7,
    description: "Angolan",
  },
  {
    id: 8,
    description: "Argentinean",
  },
  {
    id: 9,
    description: "Austrian",
  },
  {
    id: 10,
    description: "Australian",
  },
  {
    id: 11,
    description: "Azerbaijani",
  },
  {
    id: 12,
    description: "Bosnian, Herzegovinian",
  },
  {
    id: 13,
    description: "Barbadian",
  },
  {
    id: 14,
    description: "Bangladeshi",
  },
  {
    id: 15,
    description: "Belgian",
  },
  {
    id: 16,
    description: "Burkinabe",
  },
  {
    id: 17,
    description: "Bulgarian",
  },
  {
    id: 18,
    description: "Bahraini",
  },
  {
    id: 19,
    description: "Burundian",
  },
  {
    id: 20,
    description: "Beninese",
  },
  {
    id: 21,
    description: "Bruneian",
  },
  {
    id: 22,
    description: "Bolivian",
  },
  {
    id: 23,
    description: "Brazilian",
  },
  {
    id: 24,
    description: "Bahamian",
  },
  {
    id: 25,
    description: "Bhutanese",
  },
  {
    id: 26,
    description: "Batswana",
  },
  {
    id: 27,
    description: "Belarusian",
  },
  {
    id: 28,
    description: "Belizean",
  },
  {
    id: 29,
    description: "Canadian",
  },
  {
    id: 30,
    description: "Congolese",
  },
  {
    id: 32,
    description: "Swiss",
  },
  {
    id: 33,
    description: "Ivorian",
  },
  {
    id: 34,
    description: "Cameroonian",
  },
  {
    id: 35,
    description: "Chinese",
  },
  {
    id: 36,
    description: "Colombian",
  },
  {
    id: 37,
    description: "Costa Rican",
  },
  {
    id: 38,
    description: "Cypriot",
  },
  {
    id: 39,
    description: "Czech",
  },
  {
    id: 40,
    description: "German",
  },
  {
    id: 41,
    description: "Djibouti",
  },
  {
    id: 42,
    description: "Danish",
  },
  {
    id: 43,
    description: "Dominican",
  },
  {
    id: 45,
    description: "Algerian",
  },
  {
    id: 46,
    description: "Ecuadorean",
  },
  {
    id: 47,
    description: "Estonian",
  },
  {
    id: 48,
    description: "Egyptian",
  },
  {
    id: 49,
    description: "Eritrean",
  },
  {
    id: 50,
    description: "Spanish",
  },
  {
    id: 51,
    description: "Ethiopian",
  },
  {
    id: 52,
    description: "Finnish",
  },
  {
    id: 53,
    description: "Fijian",
  },
  {
    id: 54,
    description: "French",
  },
  {
    id: 55,
    description: "Gabonese",
  },
  {
    id: 56,
    description: "Grenadian",
  },
  {
    id: 57,
    description: "Georgian",
  },
  {
    id: 58,
    description: "Ghanaian",
  },
  {
    id: 59,
    description: "Gambian",
  },
  {
    id: 60,
    description: "Guinean",
  },
  {
    id: 61,
    description: "Equatorial Guinean",
  },
  {
    id: 62,
    description: "Guatemalan",
  },
  {
    id: 63,
    description: "Guinea-Bissauan",
  },
  {
    id: 64,
    description: "Guyanese",
  },
  {
    id: 65,
    description: "Hong Kong",
  },
  {
    id: 66,
    description: "Honduran",
  },
  {
    id: 67,
    description: "Croatian",
  },
  {
    id: 68,
    description: "Haitian",
  },
  {
    id: 69,
    description: "Hungarian",
  },
  {
    id: 70,
    description: "Indonesian",
  },
  {
    id: 71,
    description: "Irish",
  },
  {
    id: 72,
    description: "Israeli",
  },
  {
    id: 73,
    description: "Indian",
  },
  {
    id: 74,
    description: "Iraqi",
  },
  {
    id: 75,
    description: "Iranian",
  },
  {
    id: 76,
    description: "Icelander",
  },
  {
    id: 77,
    description: "Italian",
  },
  {
    id: 78,
    description: "Jamaican",
  },
  {
    id: 79,
    description: "Jordanian",
  },
  {
    id: 80,
    description: "Japanese",
  },
  {
    id: 81,
    description: "Kenyan",
  },
  {
    id: 82,
    description: "Kirghiz",
  },
  {
    id: 83,
    description: "Cambodian",
  },
  {
    id: 84,
    description: "I-Kiribati",
  },
  {
    id: 85,
    description: "Comoran",
  },
  {
    id: 86,
    description: "Kittian and Nevisian",
  },
  {
    id: 87,
    description: "North Korean",
  },
  {
    id: 88,
    description: "South Korean",
  },
  {
    id: 89,
    description: "Kuwaiti",
  },
  {
    id: 90,
    description: "Central African",
  },
  {
    id: 91,
    description: "Kazakhstani",
  },
  {
    id: 92,
    description: "Laotian",
  },
  {
    id: 93,
    description: "Lebanese",
  },
  {
    id: 94,
    description: "Saint Lucian",
  },
  {
    id: 95,
    description: "Liechtensteiner",
  },
  {
    id: 96,
    description: "Sri Lankan",
  },
  {
    id: 97,
    description: "Liberian",
  },
  {
    id: 98,
    description: "Mosotho",
  },
  {
    id: 99,
    description: "Lithuanian",
  },
  {
    id: 100,
    description: "Luxembourger",
  },
  {
    id: 101,
    description: "Latvian",
  },
  {
    id: 102,
    description: "Libyan",
  },
  {
    id: 103,
    description: "Moroccan",
  },
  {
    id: 104,
    description: "Monegasque",
  },
  {
    id: 105,
    description: "Moldovan",
  },
  {
    id: 106,
    description: "Malagasy",
  },
  {
    id: 107,
    description: "Marshallese",
  },
  {
    id: 108,
    description: "Micronesian",
  },
  {
    id: 109,
    description: "Macedonian",
  },
  {
    id: 110,
    description: "Malian",
  },
  {
    id: 111,
    description: "Burmese",
  },
  {
    id: 112,
    description: "Mauritanian",
  },
  {
    id: 113,
    description: "Maltese",
  },
  {
    id: 114,
    description: "Maldivan",
  },
  {
    id: 115,
    description: "Malawian",
  },
  {
    id: 116,
    description: "Mexican",
  },
  {
    id: 117,
    description: "Malaysian",
  },
  {
    id: 118,
    description: "Mozambican",
  },
  {
    id: 119,
    description: "Namibian",
  },
  {
    id: 120,
    description: "Nigerien",
  },
  {
    id: 121,
    description: "Nigerian",
  },
  {
    id: 122,
    description: "Nicaraguan",
  },
  {
    id: 123,
    description: "Dutch",
  },
  {
    id: 124,
    description: "Norwegian",
  },
  {
    id: 125,
    description: "Nepalese",
  },
  {
    id: 126,
    description: "Nauruan",
  },
  {
    id: 127,
    description: "New Zealander",
  },
  {
    id: 128,
    description: "Omani",
  },
  {
    id: 129,
    description: "Panamanian",
  },
  {
    id: 130,
    description: "Peruvian",
  },
  {
    id: 131,
    description: "Papua New Guinean",
  },
  {
    id: 132,
    description: "Filipino",
  },
  {
    id: 133,
    description: "Pakistani",
  },
  {
    id: 134,
    description: "Polish",
  },
  {
    id: 135,
    description: "Portuguese",
  },
  {
    id: 136,
    description: "Palauan",
  },
  {
    id: 137,
    description: "Paraguayan",
  },
  {
    id: 138,
    description: "Qatari",
  },
  {
    id: 139,
    description: "Romanian",
  },
  {
    id: 140,
    description: "Serbian",
  },
  {
    id: 141,
    description: "Russian",
  },
  {
    id: 142,
    description: "Rwandan",
  },
  {
    id: 143,
    description: "Saudi Arabian",
  },
  {
    id: 144,
    description: "Solomon Islander",
  },
  {
    id: 145,
    description: "Seychellois",
  },
  {
    id: 146,
    description: "Sudanese",
  },
  {
    id: 147,
    description: "Swedish",
  },
  {
    id: 148,
    description: "Singaporean",
  },
  {
    id: 149,
    description: "Slovene",
  },
  {
    id: 150,
    description: "Slovak",
  },
  {
    id: 151,
    description: "Sierra Leonean",
  },
  {
    id: 152,
    description: "Sammarinese",
  },
  {
    id: 153,
    description: "Senegalese",
  },
  {
    id: 154,
    description: "Somali",
  },
  {
    id: 155,
    description: "Surinamer",
  },
  {
    id: 156,
    description: "Sao Tomean",
  },
  {
    id: 157,
    description: "Salvadoran",
  },
  {
    id: 158,
    description: "Syrian",
  },
  {
    id: 159,
    description: "Swazi",
  },
  {
    id: 160,
    description: "Chadian",
  },
  {
    id: 161,
    description: "Togolese",
  },
  {
    id: 162,
    description: "Thai",
  },
  {
    id: 163,
    description: "Tadzhik",
  },
  {
    id: 164,
    description: "East Timorese",
  },
  {
    id: 165,
    description: "Turkmen",
  },
  {
    id: 166,
    description: "Tunisian",
  },
  {
    id: 167,
    description: "Tongan",
  },
  {
    id: 168,
    description: "Turkish",
  },
  {
    id: 169,
    description: "Trinidadian",
  },
  {
    id: 170,
    description: "Tuvaluan",
  },
  {
    id: 171,
    description: "Taiwanese",
  },
  {
    id: 172,
    description: "Tanzanian",
  },
  {
    id: 173,
    description: "Ukrainian",
  },
  {
    id: 174,
    description: "Ugandan",
  },
  {
    id: 175,
    description: "British",
  },
  {
    id: 176,
    description: "American",
  },
  {
    id: 177,
    description: "Uruguayan",
  },
  {
    id: 178,
    description: "Uzbekistani",
  },
  {
    id: 179,
    description: "Vatican City",
  },
  {
    id: 180,
    description: "Venezuelan",
  },
  {
    id: 181,
    description: "Chilean",
  },
  {
    id: 182,
    description: "Vietnamese",
  },
  {
    id: 183,
    description: "Ni-Vanuatu",
  },
  {
    id: 184,
    description: "Samoan",
  },
  {
    id: 185,
    description: "Yemeni",
  },
  {
    id: 186,
    description: "South African",
  },
  {
    id: 187,
    description: "Zambian",
  },
  {
    id: 188,
    description: "Zimbabwean",
  },
];

export default Nationalities;
