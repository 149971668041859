//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
//* *********************************** OUR COMPONENTS ***********************************
import Icon from "components/icon";
import { icons } from "components/icon/icons";

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import { localeForDatePicker } from "utils/language";
import calendar from "../../../../assets/images/calendar-small.svg";
import * as PatientActions from "../../../../redux/actions/patients";

const PersonalInfoForm = ({
  personalInfo,
  medicalConditions,
  personalInfoErrors,
  inputsHandler,
  checkBoxHandler,
  changeBirthDate,
  editPatientRequest,
  moveToNext,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const patientProfile = useSelector((state) => state.patientProfile);

  const getPatientConsentPdf = (e) => {
    e.preventDefault();
    dispatch(
      PatientActions.getPatientConsentPdf({
        name: personalInfo.full_name,
        birthdate: personalInfo.birth_date,
      })
    )
      .then((response) => {
        var blob = new Blob([response.payload.data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "consent_" + personalInfo.full_name + ".pdf";
        link.click();
      })
      .catch((error) => {
        console.log("ERROR GETTING PDF");
        alert("Error getting pdf");
      });
  };

  const hasChanges = () => {
    return (
      personalInfo.full_name !== patientProfile.full_name ||
      personalInfo.birth_date !== patientProfile.birth_date ||
      personalInfo.process_number !== patientProfile.process_number ||
      personalInfo.consent_check !== patientProfile.consent_check
    );
  };

  const isButtonDisabled = () =>
    personalInfo.full_name !== "" &&
    personalInfo.full_name.trim().split(" ").length > 1 &&
    personalInfo.birth_date !== "" &&
    personalInfo.consent_check;

  //* *************************************** RENDER ***************************************
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="row">
        <div className="col">
          <label className="d-flex justify-content-between" htmlFor="full_name">
            <span className="input-label-text">
              {t("patient_info.full_name")}&nbsp;
              <span className="input-label-text warning-color">
                {t("star")}
              </span>
            </span>
            {personalInfoErrors.full_name && (
              <span className="input-label-text warning-color">
                {personalInfoErrors.full_name}
              </span>
            )}
          </label>
          <input
            autoComplete="off"
            onChange={inputsHandler}
            value={personalInfo.full_name}
            className={`form-input ${personalInfoErrors.full_name && "error"}`}
            name="full_name"
            id="full_name"
            placeholder={t("patient_info.full_name")}
          />
        </div>
        <div className="col">
          <label
            className="d-flex justify-content-between"
            htmlFor="birth_date"
          >
            <span className="input-label-text">
              {t("patient_info.birthday")}&nbsp;
              <span className="input-label-text warning-color">
                {t("star")}
              </span>
            </span>
            {personalInfoErrors.birth_date && (
              <span className="input-label-text warning-color">
                {personalInfoErrors.birth_date}
              </span>
            )}
          </label>
          <div style={{ position: "relative" }}>
            <DatePicker
              id="birth_date"
              autoComplete="off"
              maxDate={new Date()}
              className={`form-input ${
                personalInfoErrors.birth_date && "error"
              }`}
              name="birth_date"
              placeholderText="yyyy-mm-dd"
              locale={localeForDatePicker(i18n.language)}
              dateFormat="yyyy-MM-dd"
              selected={
                personalInfo.birth_date === ""
                  ? ""
                  : new Date(personalInfo.birth_date)
              }
              onChange={(date, e) => {
                if (date) changeBirthDate(date, e);
              }}
            />
            <img alt="calendar" className="input-calendar" src={calendar} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label
            className="d-flex justify-content-between"
            htmlFor="process_number"
          >
            <span className="input-label-text">
              {t("patient_info.internal_process")}
            </span>
            {personalInfoErrors.process_number && (
              <span className="input-label-text warning-color">
                {personalInfoErrors.process_number}
              </span>
            )}
          </label>

          <input
            autoComplete="off"
            value={personalInfo.process_number}
            onChange={inputsHandler}
            className={`form-input ${
              personalInfoErrors.process_number && "error"
            }`}
            name="process_number"
            id="process_number"
            placeholder={t("placeholders.internal_process")}
          />
        </div>
        <div className="col">
          <label
            className="d-flex justify-content-between"
            htmlFor="process_number"
          >
            <span className="input-label-text">
              {t("patient_info.medical_condition")}
            </span>
          </label>

          <div className="input-image-wrapper image-right">
            <input
              autoComplete="off"
              value={
                medicalConditions?.length > 0
                  ? medicalConditions.map((condition, index) => {
                      return (
                        (index ? " " : "") +
                        t(`patient_info.medical_conditions.${condition}`)
                      );
                    })
                  : "-----"
              }
              onChange={inputsHandler}
              className={`form-input ${
                personalInfoErrors.process_number && "error"
              }`}
              name="medical_condition"
              id="medical_condition"
              placeholder={t("patient_info.medical_condition")}
              disabled
            />
            <Icon icon={icons["lock-icon"]} className={"input-right-image"} />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="check-box-wrapper mt-4">
          <div className="input-wrapper col-md-auto">
            <input
              checked={personalInfo.consent_check}
              readOnly
              className={`${personalInfoErrors.consent_check && "error"}`}
              name="consent_check"
              type={"checkbox"}
              id="checkbox"
              onChange={checkBoxHandler}
            />
          </div>

          <label type="checkbox" htmlFor="checkbox">
            <span className="regular-text">
              <span>{t("create_patient.main_data.consent_text")}&nbsp;</span>
              <span
                className="primary-hover-color button" /* link-text */
                onClick={(e) => getPatientConsentPdf(e)}
              >
                {t("create_patient.main_data.available")}
              </span>
              <span className="warning-color"> {t("star")} </span>
              {personalInfoErrors.consent_check && (
                <span className="warning-color">
                  {personalInfoErrors.consent_check}
                </span>
              )}
            </span>
          </label>
        </div>
      </div>

      <div className="row">
        <div className="form-bottom-container">
          {/* <button
            type="button"
            style={{ marginRight: "1em" }}
            onClick={(e) => validatePatientFormRequest(e)}
            className="button small-button tertiary-button"
          >
            Continue
          </button> */}

          <button
            name="continue"
            type="button"
            style={{ marginRight: "1em" }}
            onClick={(e) => moveToNext(e)}
            className="button small-button tertiary-button"
          >
            {t("buttons.continue")}
          </button>

          <button
            type="button"
            onClick={(e) => editPatientRequest(e)}
            className="button small-button secondary-button"
            disabled={!hasChanges() || !isButtonDisabled()}
          >
            {t("buttons.save_changes")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PersonalInfoForm;
