//* ******************************** REACT NATIVE IMPORTS ********************************
import { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import { getPatients } from "redux/actions/patients";
import { getReadNotificationByInstitutionId } from "redux/actions/notifications";
import { getNewNotificationCount } from "redux/actions/notifications";

import AddNewPatientModal from "./partials/addNewPatientModal";
import EvaluationsModule from "components/patientEvaluationsModule";
import EventSymptomsInfo from "./partials/eventSymptomsInfo";
import HomeReports from "./partials/reports";
import Navbar from "components/navbar/";
import Paginator from "components/paginator";
import PatientWeeklyMedicineSummary from "./partials/patientWeeklyMedicineSummary";
import RiskAssessment from "./partials/riskAssessment";
import Spinner from "components/spinner";
import NotificationModal from "./partials/notificationModal";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

import { getPatientConnectionRequests } from "redux/actions/patientConnectionRequests";

import useSearch from "hooks/useSearch";

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import arrowLeft from "assets/images/arrow-left.svg";
import arrowRight from "assets/images/arrow-right.svg";
import chevronDownSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import chevronUpSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import plusWhite from "assets/images/plus-white.svg";

import close from "assets/images/back-button.svg";
import search from "assets/images/search.svg";
import sos from "assets/images/warn-bell.svg";
import user from "assets/images/buttonIcons/user.svg";

import { parseDate } from "utils/dataAndTime";
import ConnectionRequestsOverview from "./partials/connectionRequestsOverview";
import { clearSearchFilter } from "redux/actions/searchFilter";
import { clearRedirectTo } from "redux/actions/redirect";
import { getPasswordExpireTime } from "../../redux/actions/user";
import ChangeInstitutionModal from "components/notifications/partials/changeInstitutionModal";

const triageColors = {
  6: "#E5E5E5",
  5: "#508CC0",
  4: "#508CC0",
  3: "#7BD389",
  2: "#FFEA31",
  1: "#FF9F1C",
  0: "#DB3627",
};

// Query strings for ordering when it's implemented in the backend
const order = {
  name: "name",
  nameInv: "-name",
  process: "process_number",
  processInv: "-process_number",
  lastEval: "last_evaluation",
  lastEvalInv: "-last_evaluation",
  mkinetikos: "mkinetikos",
  mkinetikosInv: "-mkinetikos",
  triage: "triage",
  triageInv: "-triage",
};

const Home = (props) => {
  //* *************************************** SETUP ****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { institution_new_notifications } = useParams();

  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [hasMkinetikos, setHasMkinetikos] = useState(false);
  const [isUSPatient, setIsUSPatient] = useState(false);
  const [hasAccessToRiskAssessment, setHasAccessToRiskAssessment] = useState(false);
  const [selectedPatientName, setSelectedPatientName] = useState(null);
  const [hasPermissionConnectionRequests, setHasPermissionConnectionRequests] = useState(false);
  const [selectedPatientColor, setSelectedPatientColor] = useState(null);
  const [isPatientLoading, setIsPatientLoading] = useState(true);
  const [clickInfo, setClickInfo] = useState({ count: 0, lastClick: "" });
  const [isPatientConnectionsRequestsLoading, setIsPatientConnectionsRequestsLoading] =
    useState(true);
  const [addNewPatientModal, setAddNewPatientModal] = useState(null);
  const [showNotification, setShowNotification] = useState(null);
  const [showPatientConnectionRequests, setShowPatientConnectionRequests] = useState(null);
  const [notificationsData, setNotificationsData] = useState(null);
  const [changeInstitutionForNewNotification, setChangeInstitutionForNewNotification] =
    useState(null);

  const patients = useSelector((state) => state.patients);
  const patientConnectionRequests = useSelector((state) => state.patientConnectionRequests);
  const currentInstitution = useSelector((state) => state.institutions.current_institution);
  const redirectTo = useSelector((state) => state.redirectTo.url);
  const institutionId = currentInstitution.id;
  const permission = useSelector((state) => state.permissions.accessPermissionForLicense);
  const totalConnectionRequests = useSelector(
    (state) => state.notifications.notificationCount.connection_requests
  );

  useEffect(() => {
    if (institution_new_notifications && institutionId) {
      if (institution_new_notifications === institutionId) {
        dispatch(getReadNotificationByInstitutionId(institution_new_notifications)).then(
          (res) => {
            if (res.payload.data.data.results?.length > 0) {
              setNotificationsData(res.payload.data.data.results);
              setShowNotification(true);
            } else {
              props.history.replace("/home");
            }
          },
          (err) => {
            props.history.replace("/home");
          }
        );
      } else {
        setChangeInstitutionForNewNotification(institution_new_notifications);
      }
    }
  }, [institutionId, institution_new_notifications]);

  useEffect(() => {
    passwordCheck();
  }, []);

  // An exact zero is most likely an unassessed score and thus show be given no importance
  const determineTriageLevel = (patient) => {
    if (patient.triage) {
      return Math.floor(patient.triage / 20);
    } else {
      return 6;
    }
  };

  //* ************************************** HANDLERS **************************************

  const getPatientList = () => {
    setIsPatientLoading(true);
    dispatch(
      getPatients({
        page: searchOptions?.page,
        q: searchOptions?.q,
        order_by: searchOptions?.orderBy,
      })
    ).finally(() => {
      setIsPatientLoading(false);
    });
  };

  const passwordCheck = () => {
    /* dispatch(getPasswordExpireTime()).then((res) => {
      if (res.payload.data) {
        const days = res.payload.data.days_until_update;
        // also check multiFactorAuth.js (line: 66)
        if (days < 0) {
          props.history.push("/password_check", { days: days });
        } else {
          props.history.replace(redirectTo);
          dispatch(clearRedirectTo());
        }
      }
    }); */
  };

  const fetchPatientConnectionRequests = () => {
    if (institutionId) {
      setIsPatientConnectionsRequestsLoading(true);
      dispatch(
        getPatientConnectionRequests({
          page: searchOptionsConnections?.page,
          search: searchOptionsConnections?.q,
          current_institution_id: institutionId,
        })
      ).finally(() => {
        setIsPatientConnectionsRequestsLoading(false);
      });
    }
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(
    getPatientList,
    300,
    "home",
    [institutionId]
  );

  const {
    searchOptions: searchOptionsConnections,
    updateSearchText: updateSearchTextConnections,
    updatePage: updatePageConnections,
  } = useSearch(fetchPatientConnectionRequests, 300, "requests");

  const clickCountHandler = (order) => {
    setClickInfo({
      count: clickInfo.lastClick === order ? clickInfo.count + 1 : 1,
      lastClick: order,
    });
  };

  const resetFiltering = () => {
    updateOrderBy(null);
    setClickInfo({ count: 0, lastClick: "" });
  };

  const changeSelectedPatient = (patient) => {
    if (patient) {
      if (selectedPatientId === patient.id) {
        setSelectedPatientId(null);
        setSelectedPatientColor(null);
        setSelectedPatientName(null);
        setHasMkinetikos(null);
        setIsUSPatient(null);
        setHasAccessToRiskAssessment(null);
      } else {
        setSelectedPatientId(patient.id);
        setSelectedPatientName(patient.name);
        setSelectedPatientColor(triageColors[determineTriageLevel(patient)]);
        setHasMkinetikos(patient.has_mkinetikos);
        setIsUSPatient(patient.product_market === "US");
        setHasAccessToRiskAssessment(
          patient.medical_conditions.includes(1) || patient.medical_conditions.includes(8)
        );
      }
    }
  };

  const onSortClickHandler = (order, orderInv) => {
    if (clickInfo.count === 2 && clickInfo.lastClick === order) {
      resetFiltering();
    } else {
      updateOrderBy(searchOptions?.orderBy === order ? orderInv : order);
      clickCountHandler(order);
    }
  };

  const patientConnectionRequestHandler = () => {
    if (showPatientConnectionRequests) {
      setShowPatientConnectionRequests(false);
    } else {
      setSelectedPatientId(null);
      fetchPatientConnectionRequests();
      setShowPatientConnectionRequests(true);
    }
  };

  const getNotificationCount = () => {
    const controller = new AbortController();
    dispatch(getNewNotificationCount({ signal: controller.signal }))
      .then((res) => {
        const hasPermission = res.payload.data.data.connection_requests !== null ? true : false;
        setHasPermissionConnectionRequests(hasPermission);
      })
      .finally(() => setIsPatientConnectionsRequestsLoading(false));
    return () => controller.abort();
  };

  useEffect(() => {
    let abortRequest = null;
    if (institutionId) {
      abortRequest = getNotificationCount();
      setSelectedPatientId(null);
    }
    return () => {
      if (institutionId) abortRequest();
    };
  }, [institutionId]);

  useEffect(() => {
    if (props.location.cameFromNotificationsModal) {
      getPatientList();
      setShowPatientConnectionRequests(true);
    }
  }, [props.location.cameFromNotificationsModal]);

  //* ************************************** PARTIALS **************************************

  const _renderPatient = (patient, index) => {
    const triageLevel = determineTriageLevel(patient);
    const isUSPatient = patient.product_market === "US";
    const hasAccessToRiskAssessment =
      patient.medical_conditions?.includes(1) || patient.medical_conditions?.includes(8);

    const hideTriage = !patient.has_mkinetikos || isUSPatient || !hasAccessToRiskAssessment;

    return (
      <div key={"patient" + patient.id} className="patient-wrapper">
        <div
          onClick={() => {
            currentInstitution &&
              currentInstitution.role_id !== 3 &&
              changeSelectedPatient(patient);
          }}
          className={`row patient ${patient.id === selectedPatientId && "background-color"}`}
        >
          <div className="col-5">
            <div className="flex-d-row patient-name">
              <div
                className="triage"
                style={{
                  backgroundColor: hideTriage ? undefined : triageColors[triageLevel],
                }}
              >
                &nbsp;
              </div>
              <span
                onClick={() => {
                  if (currentInstitution && currentInstitution.role_id !== 3) {
                    props.history.push(`patient/${patient.id}/evaluations`);
                  } else {
                    props.history.push(`patient/${patient.id}/edit`);
                  }
                }}
                className="patient-info-text"
              >
                {patient.name}
              </span>
              {patient.alert && <img src={sos} alt="alert" />}
              {patient.is_new && <span className="new-patinet-tag">{t("home.new_tag")}</span>}
            </div>
          </div>
          <div className="col-2">
            <span className="patient-info-text">{patient.process}</span>
          </div>

          <div className="col-2">
            <span className="patient-info-text">
              {patient.last_eval ? parseDate(patient.last_eval.split("T")[0]) : ""}
            </span>
          </div>

          {currentInstitution && currentInstitution.role_id !== 3 && (
            <div className="col d-flex justify-content-between align-items-center flex-wrap">
              <div
                className={`${
                  selectedPatientId === null ? "mkinetikos-wrapper" : "mkinetikos-wrapper-split"
                } ${patient.has_mkinetikos ? "light-green-bg" : "light-gray-bg"}`}
              >
                {!selectedPatientId
                  ? patient.has_mkinetikos
                    ? t("home.connected")
                    : t("home.not_connected")
                  : ""}
              </div>

              {patient.id === selectedPatientId ? (
                <img className="arrow" alt="arrowLeft" src={arrowLeft} />
              ) : (
                <img className="arrow" alt="arrowRight" src={arrowRight} />
              )}
            </div>
          )}
        </div>
        {patient.id !== selectedPatientId && index + 1 !== patients.obj_list.length && (
          <div className="row border-wrapper">
            <hr className="hr-border" />
          </div>
        )}
      </div>
    );
  };

  //* *************************************** RENDER ***************************************

  return (
    <>
      <div className="main-wrapper">
        <Navbar blueVersion={true} />
        <div className="col d-flex">
          <div className="col-1" />
          <div className="col body-wrapper">
            <div style={{ width: "100%" }}>
              <button
                // onClick={() => props.history.push("/create_patient")}
                onClick={() =>
                  permission ? setAddNewPatientModal(true) : props.history.push("/create_patient")
                }
                className="button small-button secondary-button"
              >
                <img alt="plus-icon" className="button-icon" src={plusWhite} />
                {t("buttons.new_patient")}
              </button>
              <div className="row">
                <motion.div
                  initial={{ width: "100%" }}
                  animate={{ width: selectedPatientId ? "50%" : "100%" }}
                  transition={{ duration: 0.4 }}
                >
                  <div className="col patient-list-wrapper">
                    <div className="row title-search-wrapper">
                      <div className="col">
                        <span className="text-semi-bold text bigger-normal base-color">
                          {t("home.patients")}
                        </span>
                      </div>
                      <div className="col-5">
                        <div style={{ position: "relative" }}>
                          <input
                            value={
                              showPatientConnectionRequests
                                ? searchOptionsConnections?.q
                                : searchOptions?.q
                            }
                            onChange={(e) =>
                              showPatientConnectionRequests
                                ? updateSearchTextConnections(e.target.value)
                                : updateSearchText(e.target.value)
                            }
                            type="text"
                            className="search-input w-100"
                            placeholder={
                              showPatientConnectionRequests
                                ? t("placeholders.patient_connections_search")
                                : t("placeholders.patient_search")
                            }
                          />
                          <img alt="search-icon" className="search-icon" src={search} />
                        </div>
                      </div>
                    </div>

                    {hasPermissionConnectionRequests === true && (
                      <div className="row patient-connection-requests-wrapper">
                        <div className="connection-button-container">
                          {showPatientConnectionRequests && (
                            <span
                              className="input-label-text pointer mt-1"
                              onClick={() => setShowPatientConnectionRequests(false)}
                            >
                              <Icon icon={icons["back-icon"]} className={"pointer"} />
                              <span className="ml-1 mr-2">{t("buttons.back")}</span>
                            </span>
                          )}
                          <button
                            disabled={isPatientConnectionsRequestsLoading}
                            onClick={() => patientConnectionRequestHandler()}
                            className={`patient-connection-requests-button ${
                              showPatientConnectionRequests &&
                              "patient-connection-requests-button--clicked"
                            }`}
                            style={{ position: "relative" }}
                          >
                            <span
                              style={{
                                opacity: isPatientConnectionsRequestsLoading ? 0 : 1,
                              }}
                            >
                              {t("buttons.patient_connection_requests")}
                            </span>
                            {!isPatientConnectionsRequestsLoading && (
                              <div className="patient_connection_requests_count_container">
                                <span className="patient_connection_requests_count">
                                  {totalConnectionRequests}
                                </span>
                              </div>
                            )}
                            {isPatientConnectionsRequestsLoading && <Spinner type={"small-blue"} />}
                          </button>
                        </div>
                      </div>
                    )}

                    {showPatientConnectionRequests && (
                      <ConnectionRequestsOverview
                        fetchRequests={() => {
                          getNotificationCount();
                          fetchPatientConnectionRequests();
                        }}
                        pageChange={updatePageConnections}
                      />
                    )}

                    {!showPatientConnectionRequests && (
                      <>
                        {!searchOptions?.q && (
                          <div className="row patient-labels">
                            <div className="col-1 d-flex align-items-center" style={{ width: "0" }}>
                              <div
                                className={`order-img-container ${
                                  searchOptions?.orderBy === order.triage ||
                                  searchOptions?.orderBy === order.triageInv
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  onSortClickHandler(order.triage, order.triageInv);
                                }}
                              >
                                {searchOptions?.orderBy !== order.triage && (
                                  <img src={chevronUpSelectedIcon} alt="Sort by triage " />
                                )}
                                {searchOptions?.orderBy !== order.triageInv && (
                                  <img
                                    src={chevronDownSelectedIcon}
                                    alt="Sort by triage descending"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-5 d-flex align-items-center col-w-39">
                              <div className="list-label">{t("patient_info.name")}</div>
                              <div
                                className={`order-img-container ${
                                  searchOptions?.orderBy === order.name ||
                                  searchOptions?.orderBy === order.nameInv
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  onSortClickHandler(order.name, order.nameInv);
                                }}
                              >
                                {searchOptions?.orderBy !== order.name && (
                                  <img src={chevronUpSelectedIcon} alt="Sort by name " />
                                )}
                                {searchOptions?.orderBy !== order.nameInv && (
                                  <img
                                    src={chevronDownSelectedIcon}
                                    alt="Sort by name descending"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-2 d-flex align-items-center ml-1">
                              <div className="list-label ipm-ml-8">
                                {!selectedPatientId
                                  ? t("patient_info.internal_process")
                                  : t("patient_info.internal_process_short")}
                              </div>
                              <div
                                className={`order-img-container ${
                                  searchOptions?.orderBy === order.process ||
                                  searchOptions?.orderBy === order.processInv
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  onSortClickHandler(order.process, order.processInv);
                                }}
                              >
                                {searchOptions?.orderBy !== order.process && (
                                  <img src={chevronUpSelectedIcon} alt="Sort by Process Number" />
                                )}
                                {searchOptions?.orderBy !== order.processInv && (
                                  <img
                                    src={chevronDownSelectedIcon}
                                    alt="Sort by Process Number descending"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-2 d-flex align-items-center">
                              <div className="list-label">{t("patient_info.last_eval")}</div>
                              <div
                                className={`order-img-container ${
                                  searchOptions?.orderBy === order.lastEval ||
                                  searchOptions?.orderBy === order.lastEvalInv
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  onSortClickHandler(order.lastEval, order.lastEvalInv);
                                }}
                              >
                                {searchOptions?.orderBy !== order.lastEval && (
                                  <img src={chevronUpSelectedIcon} alt="Sort by evaluation date" />
                                )}
                                {searchOptions?.orderBy !== order.lastEvalInv && (
                                  <img
                                    src={chevronDownSelectedIcon}
                                    alt="Sort by evaluation date descending"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col d-flex align-items-center">
                              <div className="list-label">{t("mKinetikos")}</div>
                              <div className={`order-img-container`}>
                                <div
                                  className={`order-img-container ${
                                    searchOptions?.orderBy === order.mkinetikos ||
                                    searchOptions?.orderBy === order.mkinetikosInv
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    onSortClickHandler(order.mkinetikos, order.mkinetikosInv);
                                  }}
                                >
                                  {searchOptions?.orderBy !== order.mkinetikos && (
                                    <img src={chevronUpSelectedIcon} alt="Sort by mkinetikos" />
                                  )}
                                  {searchOptions?.orderBy !== order.mkinetikosInv && (
                                    <img
                                      src={chevronDownSelectedIcon}
                                      alt="Sort by mkinetikos descending"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {isPatientLoading ? (
                          <div style={{ height: "100px" }}>
                            <div className="loading-wrapper">
                              <Spinner type={"big-blue"} />
                            </div>
                          </div>
                        ) : searchOptions?.q === "" ? (
                          patients.count !== 0 ? (
                            <div className="row patient-list d-flex">
                              {patients.obj_list.map((patient, index) =>
                                _renderPatient(patient, index)
                              )}
                            </div>
                          ) : (
                            <div className="flex-d-column no-data-text">
                              <span>{t("home.no_patients")}</span>
                            </div>
                          )
                        ) : patients.count !== 0 ? (
                          <div className="row patient-list d-flex">
                            {patients.obj_list.map((patient, index) =>
                              _renderPatient(patient, index)
                            )}
                          </div>
                        ) : (
                          <div className="flex-d-column no-data-text">
                            <span>{t("home.patient_not_found")}</span>
                            <span>{t("home.different_search_term")}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {patients.obj_list.length > 0 &&
                    !isPatientLoading &&
                    !showPatientConnectionRequests && (
                      <div className="pagination-wrapper">
                        <Paginator
                          currPage={patients.page}
                          totalPages={patients.num_pages}
                          maxPages={3}
                          changePage={updatePage}
                        />
                      </div>
                    )}

                  {showPatientConnectionRequests &&
                    !isPatientConnectionsRequestsLoading &&
                    patientConnectionRequests?.total_pages > 1 && (
                      <div className="pagination-wrapper">
                        <Paginator
                          currPage={
                            patientConnectionRequests.next
                              ? patientConnectionRequests.next - 1
                              : patientConnectionRequests.previous + 1
                          }
                          totalPages={patientConnectionRequests.total_pages}
                          maxPages={3}
                          changePage={updatePageConnections}
                        />
                      </div>
                    )}
                </motion.div>
                {selectedPatientId && !showPatientConnectionRequests && (
                  <motion.div
                    initial={{ width: "0%" }}
                    animate={{ width: selectedPatientId ? "50%" : "0%" }}
                    transition={{ duration: 0.4 }}
                  >
                    <div className="patient-data flex-d-column">
                      <div className="row-space-between">
                        <span
                          className="input-label-text pointer"
                          onClick={() => setSelectedPatientId(null)}
                        >
                          <img src={close} className="mr-1" />
                          {t("buttons.close")}
                        </span>
                      </div>
                      <div className="data-card flex-d-column">
                        <div className="row-space-between">
                          <span className="text-semi-bold text bigger-normal base-color">
                            {selectedPatientName}
                          </span>
                          {/* <div className="col-3">
                                <button
                                  className="button small-button warning-button-with-bg"
                                >
                                  <img alt="New Evaluation" className="data-icon" src={contactMedic} />
                                  <span>Contact Medic</span>
                                </button>
                              </div> */}
                          <button
                            className="button small-button primary-button"
                            onClick={() =>
                              props.history.push(`patient/${selectedPatientId}/evaluations`)
                            }
                          >
                            <img alt="Open profile" className="data-icon" src={user} />
                            <span>{t("buttons.open_full_profile")}</span>
                          </button>
                        </div>
                      </div>

                      {hasMkinetikos && (
                        <>
                          {!isUSPatient && hasAccessToRiskAssessment && (
                            <RiskAssessment
                              patientId={selectedPatientId}
                              color={
                                selectedPatientColor === triageColors[5]
                                  ? null
                                  : selectedPatientColor
                              }
                              colsToShow={["name", "date"]}
                              isSearchBar={false}
                              isPaginator={false}
                              isFullRowClickable={true}
                              extraStyleClass={"data-card"}
                              spinnerType={"small-blue"}
                            />
                          )}
                          <PatientWeeklyMedicineSummary patientId={selectedPatientId} />
                          <EventSymptomsInfo patientId={selectedPatientId} />
                          <HomeReports patientId={selectedPatientId} />
                        </>
                      )}
                      <EvaluationsModule
                        history={props.history}
                        itemsPerPage={5}
                        headerText={t("home.past_evaluations")}
                        patientId={selectedPatientId}
                        colsToShow={["name", "date"]}
                        isSearchBar={false}
                        isPaginator={false}
                        isFullRowClickable={true}
                        extraStyleClass={"data-card"}
                        spinnerType={"small-blue"}
                      />
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>

          <div className="col-1" />
        </div>
        {addNewPatientModal && (
          <AddNewPatientModal closeModal={() => setAddNewPatientModal(false)} />
        )}

        {notificationsData?.length > 0 && showNotification && (
          <NotificationModal
            closeNotification={() => {
              setShowNotification(false);
              props.history.replace("/home");
            }}
            notifications={notificationsData}
          />
        )}
      </div>
      {changeInstitutionForNewNotification !== null && (
        <ChangeInstitutionModal
          institutionId={changeInstitutionForNewNotification}
          closeModal={() => setChangeInstitutionForNewNotification(null)}
          goto={() =>
            props.history.replace(
              `/home/institution_new_notifications/${changeInstitutionForNewNotification}/`
            )
          }
        />
      )}
    </>
  );
};

export default Home;
