import { useContext } from "react";
import { ToastContext } from "../components/toast/context/toastContext";

/**
 * Hook for adding the toast.
 * ex:
 *  addToast = useToast()
 *  addToast({message: "some text!!", type: "info"})
 * @returns function addToast
 */
const useToast = () => {
  const { addToast, _ } = useContext(ToastContext);
  return addToast;
};

export default useToast;
