//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

export const RenderActivateAccountFooter = ({
  onClickResendEmail,
  changeToLogin,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="activate-account-footer">
      <button
        className="primary-button small-button w-100"
        onClick={onClickResendEmail}
      >
        {t("authentication.login.resend_email_btn")}
      </button>
      <span className="regular-text mt-3">
        {t("authentication.login.if_you_want")}
        <span className="link-text" onClick={changeToLogin}>
          {t("authentication.login.go_back_login")}
        </span>
      </span>
    </div>
  );
};
