//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import Nationalities from "../../../../constants/nationalities";

const ContactInfoForm = ({
  contactInfo,
  contactInfoErrors,
  inputsHandler,
  editPatientRequest,
  moveToNext,
  goToPrevious,
}) => {
  //* *************************************** SETUP ****************************************
  const patientProfile = useSelector((state) => state.patientProfile);
  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************

  const hasChanges = () => {
    return (
      contactInfo.email !== (patientProfile.email ?? "") ||
      contactInfo.nationality_id !==
        (patientProfile.nationality_id
          ? "" + patientProfile.nationality_id
          : "") ||
      contactInfo.phone !== (patientProfile.phone ?? "") ||
      contactInfo.telephone !== (patientProfile.telephone ?? "") ||
      contactInfo.occupation !== (patientProfile.occupation ?? "") ||
      contactInfo.educational_level_id !==
        (patientProfile.educational_level_id
          ? "" + patientProfile.educational_level_id
          : "") ||
      contactInfo.marital_status_id !==
        (patientProfile.marital_status_id
          ? "" + patientProfile.marital_status_id
          : "") ||
      contactInfo.language_id !==
        (patientProfile.language_id ? "" + patientProfile.language_id : "")
    );
  };

  //* *************************************** RENDER ***************************************
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="row">
        <div className="col">
          <div className="smaller-title" style={{ marginBottom: "24px" }}>
            {t("create_patient.personal_data.contact_info")}
          </div>
          <label className="d-flex justify-content-between" htmlFor="email">
            <span className="input-label-text">{t("patient_info.email")}</span>
            {contactInfoErrors.email && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.email}
              </span>
            )}
          </label>
          <input
            onChange={inputsHandler}
            value={contactInfo.email}
            className={`form-input ${contactInfoErrors.email && "error"}`}
            name="email"
            id="email"
            placeholder={t("placeholders.email")}
          />
          <label className="d-flex justify-content-between" htmlFor="phone">
            <span className="input-label-text">{t("patient_info.phone")}</span>
            {contactInfoErrors.phone && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.phone}
              </span>
            )}
          </label>
          <input
            onChange={inputsHandler}
            value={contactInfo.phone}
            className={`form-input ${contactInfoErrors.phone && "error"}`}
            name="phone"
            id="phone"
          />
          <label className="d-flex justify-content-between" htmlFor="telephone">
            <span className="input-label-text">
              {t("patient_info.mobile_phone")}
            </span>
            {contactInfoErrors.phone && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.telephone}
              </span>
            )}
          </label>
          <input
            onChange={inputsHandler}
            value={contactInfo.telephone}
            className={`form-input ${contactInfoErrors.telephone && "error"}`}
            name="telephone"
            id="telephone"
          />
        </div>
        <div className="col">
          <div className="smaller-title" style={{ marginBottom: "24px" }}>
            {t("create_patient.personal_data.general_info")}
          </div>
          <label
            className="d-flex justify-content-between"
            htmlFor="nationality_id"
          >
            <span className="input-label-text">
              {t("patient_info.nationality")}
            </span>
            {contactInfoErrors.nationality_id && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.nationality_id}
              </span>
            )}
          </label>
          <select
            value={contactInfo.nationality_id}
            onChange={inputsHandler}
            className={`w-100 form-dropdown ${
              contactInfo.nationality_id === "" && "notSelected"
            }`}
            name="nationality_id"
            id="nationality_id"
          >
            <option value="">{t("placeholders.option")}</option>
            {Nationalities.map((nationality) => (
              <option
                key={nationality.description + nationality.id}
                value={nationality.id}
              >
                {t(`nationalities.${nationality.description}`)}
              </option>
            ))}
          </select>
          <label
            className="d-flex justify-content-between"
            htmlFor="occupation"
          >
            <span className="input-label-text">
              {t("patient_info.occupation")}
            </span>
            {contactInfoErrors.occupation && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.occupation}
              </span>
            )}
          </label>
          <input
            onChange={inputsHandler}
            value={contactInfo.occupation}
            className={`form-input ${contactInfoErrors.occupation && "error"}`}
            name="occupation"
            id="occupation"
            placeholder={t("placeholders.occupation")}
          />
          <label
            className="d-flex justify-content-between"
            htmlFor="educational_level_id"
          >
            <span className="input-label-text">
              {t("patient_info.education_level")}
            </span>
            {contactInfoErrors.educational_level_id && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.educational_level_id}
              </span>
            )}
          </label>
          <select
            value={contactInfo.educational_level_id}
            onChange={inputsHandler}
            className={`w-100 form-dropdown ${
              contactInfo.educational_level_id === "" && "notSelected"
            }`}
            name="educational_level_id"
            id="educational_level_id"
          >
            <option value="">{t("placeholders.option")}</option>
            <option value={1}>
              {t("create_patient.personal_data.education_none")}
            </option>
            <option value={2}>
              {t("create_patient.personal_data.education_primary")}
            </option>
            <option value={3}>
              {t("create_patient.personal_data.education_secondary")}
            </option>
            <option value={4}>
              {t("create_patient.personal_data.education_tertiary")}
            </option>
          </select>
          <label
            className="d-flex justify-content-between"
            htmlFor="marital_status_id"
          >
            <span className="input-label-text">
              {t("patient_info.marital_status")}
            </span>
            {contactInfoErrors.marital_status_id && (
              <span className="input-label-text warning-color">
                {contactInfoErrors.marital_status_id}
              </span>
            )}
          </label>

          <select
            value={contactInfo.marital_status_id}
            onChange={inputsHandler}
            className={`w-100 dropdown ${
              contactInfo.marital_status_id === "" && "notSelected"
            }`}
            name="marital_status_id"
            id="marital_status_id"
          >
            <option value="">{t("placeholders.option")}</option>

            <option value={1}>
              {t("create_patient.personal_data.status_not")}
            </option>
            <option value={2}>
              {t("create_patient.personal_data.status_married")}
            </option>
            <option value={3}>
              {t("create_patient.personal_data.status_divorced")}
            </option>
            <option value={4}>
              {t("create_patient.personal_data.status_widow")}
            </option>
          </select>
        </div>
      </div>
      {
        // <label className="input-label-text">
        //   Language
        //   {contactInfoErrors.language_id && (
        //     <span className="warning-color">
        //       {contactInfoErrors.language_id}
        //     </span>
        //   )}
        // </label>
        // <select
        //   value={contactInfo.language_id}
        //   onChange={inputsHandler}
        //   className={`w-100 dropdown ${
        //     contactInfo.language_id === "" && "notSelected"
        //   }`}
        //   name="language_id"
        //   id="language_id"
        // >
        //   <option value="">---- Select your option ----</option>
        //   <option value={1}>Portuguese</option>
        //   <option value={2}>English</option>
        // </select>
      }

      <div className="form-bottom-container">
        <button
          type="button"
          style={{ marginRight: "1.5em" }}
          onClick={() => goToPrevious()}
          className="button small-button transparent-button"
        >
          <u> {t("buttons.previous")} </u>
        </button>

        <button
          name="continue"
          type="button"
          style={{ marginRight: "1em" }}
          onClick={(e) => moveToNext(e)}
          className="button small-button tertiary-button"
        >
          {t("buttons.continue")}
        </button>

        <button
          type="button"
          onClick={(e) => editPatientRequest(e)}
          className="button small-button secondary-button"
          disabled={!hasChanges()}
        >
          {t("buttons.save_changes")}
        </button>
      </div>
    </form>
  );
};

export default ContactInfoForm;
