//import liraries
import React, { forwardRef } from "react";
import uuid from "../../utils/uuid";
import ReactTooltip from "react-tooltip";

// create a component
const DropdownOptions = forwardRef(({ buttons = [], tooltip }, ref) => {
  return (
    <div
      className="dropdown-options"
      ref={ref}
      data-tip={tooltip?.dataTip}
      data-for={tooltip?.dataFor}
    >
      {buttons.map((button, index) =>
        button.html
          ? button.html
          : button &&
            button !== "" && (
              <button
                className={`${
                  button.disabled === undefined
                    ? ""
                    : button.disabled
                    ? "disabled"
                    : ""
                }`}
                key={uuid()}
                onClick={(e) => {
                  e.stopPropagation();
                  button.action();
                }}
              >
                {button.text}
              </button>
            )
      )}
      <ReactTooltip
        key={uuid()}
        className="tooltip-btn"
        id={tooltip?.dataFor}
        place={tooltip?.place}
        effect="solid"
        arrowColor="#255986"
        multiline={true}
        clickable={true}
        delayHide={1000}
      >
        {tooltip?.dataTip}
      </ReactTooltip>
    </div>
  );
});

//make this component available to the app
export default DropdownOptions;
