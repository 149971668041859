//* ********************************** REACT IMPORTS **********************************
import React from "react";
import { useState } from "react";

//* ********************************** EXTERNAL PACKAGES ******************************
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";

//* ********************************** OUR COMPONENTS *********************************
import MessageBox from "../../../components/messageBox";
import Spinner from "../../../components/spinner";

//* ********************************** GLOBALS **********************************
import expired_timer from "../../../assets/images/expired-timer.svg";
import show_password_logo from "../../../assets/images/show-password.svg";
import hide_password_logo from "../../../assets/images/hide-password.svg";
import { login } from "../../../redux/actions/user";
import { getCurrentInstitution } from "../../../redux/actions/institutions";
import validator from "validator";

const SessionExpired = ({
  navigateToHome,
  changeToSignUp,
  showErrorPage,
  changeToForgotAccount,
  changeToActivateAccount,
  userInfoCallback,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const MAX_ATTEMP = 4;

  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [showWrongCredentialsError, setShowWrongCredentialsError] =
    useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [remainingLoginAttempts, setRemainingLoginAttempts] = useState(null);

  const responses = {
    userNotActive: "User not active",
  };

  //* ************************************** HANDLERS **************************************
  const inputsHandler = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const submitLogin = (e) => {
    e.preventDefault();
    if (showWrongCredentialsError) setShowWrongCredentialsError(false);

    // preparing data for login
    let data = {
      email: validator.isEmail(loginCredentials.username)
        ? loginCredentials.username
        : undefined,
      username: !validator.isEmail(loginCredentials.username)
        ? loginCredentials.username
        : undefined,
      password: loginCredentials.password,
    };
    userInfoCallback(
      data.email ? { email: data.email } : { username: data.username }
    );

    setIsLoginLoading(true);
    dispatch(login(data))
      .then((res) => {
        dispatch(getCurrentInstitution())
          .then(() => navigateToHome())
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        if (error.error.response) {
          const { status, data } = error.error.response;
          if (data?.data?.remaining_attempts) {
            setRemainingLoginAttempts(
              error.error.response.data.data.remaining_attempts
            );
          }
          if (status === 401) {
            if (data?.error === responses.userNotActive) {
              changeToActivateAccount();
            } else {
              setShowWrongCredentialsError(true);
            }
          } else {
            showErrorPage();
          }
        }
      })
      .finally(() => {
        setIsLoginLoading(false);
      });
  };

  const __renderWrongCredentialsMessage = () => {
    return [
      remainingLoginAttempts <= MAX_ATTEMP &&
        t("authentication.login.invalid_username_password"),
      remainingLoginAttempts &&
        (remainingLoginAttempts === "max_attempts_reached" ? (
          t("authentication.login.max_login_attempts")
        ) : (
          <Trans i18nKey={"authentication.login.remaining_attempts"}>
            {remainingLoginAttempts?.toString()}
          </Trans>
        )),
    ];
  };

  const isLoginDisable =
    loginCredentials.username === "" ||
    loginCredentials.password === "" ||
    isLoginLoading;

  return (
    <div className="form-auth-wrapper">
      <h2>{t("titles.session_expired")}</h2>
      <form style={{ marginTop: "1.44em" }} onSubmit={submitLogin}>
        <div className="input-image-wrapper image-right">
          <MessageBox
            type={"warning"}
            messages={[
              t("authentication.session_expired.expired_inactivity"),
              t("authentication.session_expired.login_again"),
            ]}
          />
          <img className="input-right-image" src={expired_timer} alt="" />
        </div>
        <label className="input-label-text">
          {t("user_info.username_or_email")}
        </label>
        <input
          onChange={inputsHandler}
          value={loginCredentials.username}
          name="username"
          type="text"
          id="username"
          placeholder={t("placeholders.username")}
          className="form-input"
        ></input>
        <label className="input-label-text">
          {t("authentication.password.password")}
        </label>
        <div className="input-image-wrapper image-right">
          <input
            onChange={inputsHandler}
            autoComplete={"off"}
            value={loginCredentials.password}
            name="password"
            id="password"
            type={isPasswordHidden ? "password" : "text"}
            placeholder={t("placeholders.password")}
            className="form-input"
          />
          <img
            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
            alt="Password Show and Hide"
            className="input-right-image"
            src={isPasswordHidden ? show_password_logo : hide_password_logo}
          />
        </div>
        {showWrongCredentialsError && (
          <MessageBox
            type={"warning"}
            messages={__renderWrongCredentialsMessage()}
          />
        )}
        <div className="form-auth-bottom">
          <div className="input-image-wrapper m-0">
            <input
              disabled={isLoginDisable}
              value={isLoginLoading ? "" : t("buttons.log_in")}
              type="submit"
              className="primary-button small-button w-100"
            />
            {isLoginLoading && <Spinner type={"small"} />}
          </div>

          <div className="auth-bottom-element">
            <span className="regular-text">
              {t("authentication.login.no_account")}&nbsp;
            </span>
            <span onClick={changeToSignUp} className="link-text">
              {t("authentication.login.signup")}
            </span>
          </div>
          <div className="auth-bottom-element">
            <span onClick={changeToForgotAccount} className="link-text">
              {t("authentication.login.forgot_account")}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SessionExpired;
