export const ADD_EVALUATION_MODULE = "ADD_EVALUATION_MODULE";

export const DELETE_EVALUATION_MODULE = "DELETE_EVALUATION_MODULE";

export const GET_EVALUATION_MODULE_FORM = "GET_EVALUATION_MODULE_FORM";
export const GET_EVALUATION_MODULE_FORM_SUCCESS =
  "GET_EVALUATION_MODULE_FORM_SUCCESS";
export const GET_EVALUATION_MODULE_FORM_FAIL =
  "GET_EVALUATION_MODULE_FORM_FAIL";

export const SEND_EVALUATION_MODULE_FORM = "SEND_EVALUATION_MODULE_FORM";

export const addEvaluationModule = (evaluation_id, eval_mod_id) => ({
  type: GET_EVALUATION_MODULE_FORM,
  payload: {
    request: {
      method: "post",
      url: `/cdss/evaluation/${evaluation_id}/evalmod/`,
      data: { module_id: eval_mod_id },
    },
  },
});

export const deleteEvaluationModule = (evaluation_id, eval_mod_id, remove) => ({
  type: GET_EVALUATION_MODULE_FORM,
  payload: {
    request: {
      method: "delete",
      url: `cdss/evaluation/${evaluation_id}/evalmod/${eval_mod_id}/`,
      data: { remove_eval_mod: remove },
    },
  },
});

export const getEvaluationModuleForm = (evaluation_id, eval_module_id) => ({
  type: GET_EVALUATION_MODULE_FORM,
  payload: {
    request: {
      method: "get",
      url: `cdss/evaluation/${evaluation_id}/evalmod/${eval_module_id}/`,
    },
  },
});

export const sendEvaluationModuleForm = (
  evaluation_id,
  eval_module_id,
  formData
) => ({
  type: SEND_EVALUATION_MODULE_FORM,
  payload: {
    request: {
      method: "post",
      url: `cdss/evaluation/${evaluation_id}/evalmod/${eval_module_id}/`,
      data: formData,
      headers: { "Content-type": "multipart/form-data" },
    },
  },
});
