import $ from "jquery";

const scripts = {
  222: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q11").css({ display: "none" });
      $("#group-id_q12").css({ display: "none" });
      $("#group-id_q13").css({ display: "none" });
      $("#group-id_q14").css({ display: "none" });
      $("#group-id_q19").css({ display: "none" });
      $("#group-id_q20").css({ display: "none" });
      $("#group-id_q21").css({ display: "none" });
      $("#group-id_q22").css({ display: "none" });
      $("#group-id_q23").css({ display: "none" });
      $("#group-id_q24").css({ display: "none" });
      $("#group-id_q25").css({ display: "none" });
      $("#group-id_q26").css({ display: "none" });
      $("#group-id_q27").css({ display: "none" });
      $("#group-id_q32").css({ display: "none" });
      $("#group-id_q33").css({ display: "none" });
      $("#group-id_q34").css({ display: "none" });
      $("#group-id_q35").css({ display: "none" });
      $("#group-id_q36").css({ display: "none" });
      $("#group-id_q37").css({ display: "none" });
      $("#group-id_q38").css({ display: "none" });
      $("#group-id_q43").css({ display: "none" });
      $("#group-id_q44").css({ display: "none" });
      $("#group-id_q45").css({ display: "none" });
      $("#group-id_q46").css({ display: "none" });
      $("#group-id_q47").css({ display: "none" });
      $("#group-id_q48").css({ display: "none" });
      $("#group-id_q49").css({ display: "none" });
      $("#group-id_q50").css({ display: "none" });
      $("#group-id_q51").css({ display: "none" });
      $("#group-id_q52").css({ display: "none" });

      // define event handler for Phase Droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        $("#group-id_q12").css({ display: "none" });
        $("#group-id_q13").css({ display: "none" });
        $("#group-id_q14").css({ display: "none" });
        $("#group-id_q19").css({ display: "none" });
        $("#group-id_q20").css({ display: "none" });
        $("#group-id_q21").css({ display: "none" });
        $("#group-id_q22").css({ display: "none" });
        $("#group-id_q23").css({ display: "none" });
        $("#group-id_q24").css({ display: "none" });
        $("#group-id_q25").css({ display: "none" });
        $("#group-id_q26").css({ display: "none" });
        $("#group-id_q27").css({ display: "none" });
        $("#group-id_q32").css({ display: "none" });
        $("#group-id_q33").css({ display: "none" });
        $("#group-id_q34").css({ display: "none" });
        $("#group-id_q35").css({ display: "none" });
        $("#group-id_q36").css({ display: "none" });
        $("#group-id_q37").css({ display: "none" });
        $("#group-id_q38").css({ display: "none" });
        $("#group-id_q43").css({ display: "none" });
        $("#group-id_q44").css({ display: "none" });
        $("#group-id_q45").css({ display: "none" });
        $("#group-id_q46").css({ display: "none" });
        $("#group-id_q47").css({ display: "none" });
        $("#group-id_q48").css({ display: "none" });
        $("#group-id_q49").css({ display: "none" });
        $("#group-id_q50").css({ display: "none" });
        $("#group-id_q51").css({ display: "none" });
        $("#group-id_q52").css({ display: "none" });

        var selection = $(this).val();
        // Pre
        if (selection === "0") {
          $("#group-id_q2").css({ display: "block" });
          $("#group-id_q4").css({ display: "block" });
          $("#group-id_q5").css({ display: "block" });
          $("#group-id_q6").css({ display: "block" });
          $("#group-id_q7").css({ display: "block" });
          $("#group-id_q8").css({ display: "block" });
          $("#group-id_q10").css({ display: "block" });
          $("#group-id_q19").css({ display: "block" });
          $("#group-id_q21").css({ display: "block" });
          $("#group-id_q32").css({ display: "block" });
          $("#group-id_q34").css({ display: "block" });
          $("#group-id_q43").css({ display: "block" });
          $("#id_q21").change();
        }

        // Phase 1
        else if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
          $("#group-id_q4").css({ display: "block" });
          $("#group-id_q5").css({ display: "block" });
          $("#group-id_q6").css({ display: "block" });
          $("#group-id_q7").css({ display: "block" });
          $("#group-id_q8").css({ display: "block" });
          $("#group-id_q11").css({ display: "block" });
          $("#group-id_q19").css({ display: "block" });
          $("#group-id_q21").css({ display: "block" });
          $("#group-id_q32").css({ display: "block" });
          $("#group-id_q35").css({ display: "block" });
          $("#group-id_q43").css({ display: "block" });
          $("#id_q21").change();
        }

        // Phase 2
        else if (selection === "2") {
          $("#group-id_q3").css({ display: "block" });
          $("#group-id_q4").css({ display: "block" });
          $("#group-id_q5").css({ display: "block" });
          $("#group-id_q6").css({ display: "block" });
          $("#group-id_q7").css({ display: "block" });
          $("#group-id_q9").css({ display: "block" });
          $("#group-id_q12").css({ display: "block" });
          $("#group-id_q20").css({ display: "block" });
          $("#group-id_q22").css({ display: "block" });
          $("#group-id_q33").css({ display: "block" });
          $("#group-id_q36").css({ display: "block" });
          $("#group-id_q44").css({ display: "block" });
          $("#group-id_q45").css({ display: "block" });
          $("#group-id_q48").css({ display: "block" });
          $("#group-id_q49").css({ display: "block" });
          $("#group-id_q50").css({ display: "block" });
          $("#group-id_q51").css({ display: "block" });
          $("#group-id_q52").css({ display: "block" });
          $("#id_q22").change();
        }
        // Phase 3
        else if (selection === "3") {
          $("#group-id_q13").css({ display: "block" });
          $("#group-id_q20").css({ display: "block" });
          $("#group-id_q23").css({ display: "block" });
          $("#group-id_q33").css({ display: "block" });
          $("#group-id_q37").css({ display: "block" });
          $("#group-id_q44").css({ display: "block" });
          $("#group-id_q46").css({ display: "block" });
          $("#group-id_q48").css({ display: "block" });
          $("#group-id_q49").css({ display: "block" });
          $("#group-id_q50").css({ display: "block" });
          $("#group-id_q51").css({ display: "block" });
          $("#group-id_q52").css({ display: "block" });
          $("#id_q23").change();
        }
        // Phase 4
        else if (selection === "4") {
          $("#group-id_q14").css({ display: "block" });
          $("#group-id_q20").css({ display: "block" });
          $("#group-id_q24").css({ display: "block" });
          $("#group-id_q33").css({ display: "block" });
          $("#group-id_q38").css({ display: "block" });
          $("#group-id_q44").css({ display: "block" });
          $("#group-id_q47").css({ display: "block" });
          $("#group-id_q48").css({ display: "block" });
          $("#group-id_q49").css({ display: "block" });
          $("#group-id_q50").css({ display: "block" });
          $("#group-id_q51").css({ display: "block" });
          $("#group-id_q52").css({ display: "block" });
          $("#id_q24").change();
        }
      });

      // define event handler for Strength Droplist
      $("#id_q21").change(function (event) {
        $("#group-id_q25").css({ display: "none" });
        $("#group-id_q26").css({ display: "none" });
        $("#group-id_q27").css({ display: "none" });

        var selection = $(this).val();
        $("#group-id_q25").css({ display: "block" });
      });

      // define event handler for Strength Droplist
      $("#id_q22").change(function (event) {
        $("#group-id_q25").css({ display: "none" });
        $("#group-id_q26").css({ display: "none" });
        $("#group-id_q27").css({ display: "none" });

        var selection = $(this).val();
        $("#group-id_q25").css({ display: "block" });
      });

      // define event handler for Strength Droplist
      $("#id_q23").change(function (event) {
        $("#group-id_q25").css({ display: "none" });
        $("#group-id_q26").css({ display: "none" });
        $("#group-id_q27").css({ display: "none" });

        var selection = $(this).val();
        // bands
        if (selection === "4") {
          $("#group-id_q26").css({ display: "block" });
        }
        // bicycle
        else if (selection === "6") {
          $("#group-id_q27").css({ display: "block" });
        }
        // others
        else {
          $("#group-id_q25").css({ display: "block" });
        }
      });

      // define event handler for Strength Droplist
      $("#id_q24").change(function (event) {
        $("#group-id_q25").css({ display: "none" });
        $("#group-id_q26").css({ display: "none" });
        $("#group-id_q27").css({ display: "none" });

        var selection = $(this).val();
        $("#group-id_q25").css({ display: "block" });
      });

      $("#id_q1").change();
    });
  },
  157: () => {
    $(document).ready(function () {
      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #group-id_q16, #id_q17, #id_q18"
      ).change(function () {
        var selection2 = parseInt(
          $("#group-id_q2").find("input:checked").val()
        );
        var selection3 = parseInt(
          $("#group-id_q3").find("input:checked").val()
        );
        var selection4 = parseInt(
          $("#group-id_q4").find("input:checked").val()
        );
        var selection5 = parseInt(
          $("#group-id_q5").find("input:checked").val()
        );
        var selection6 = parseInt(
          $("#group-id_q6").find("input:checked").val()
        );
        var selection7 = parseInt(
          $("#group-id_q7").find("input:checked").val()
        );
        var selection8 = parseInt(
          $("#group-id_q8").find("input:checked").val()
        );
        var selection9 = parseInt(
          $("#group-id_q9").find("input:checked").val()
        );
        var selection10 = parseInt(
          $("#group-id_q10").find("input:checked").val()
        );
        var selection11 = parseInt(
          $("#group-id_q11").find("input:checked").val()
        );
        var selection12 = parseInt(
          $("#group-id_q12").find("input:checked").val()
        );
        var selection13 = parseInt(
          $("#group-id_q13").find("input:checked").val()
        );
        var selection14 = parseInt(
          $("#group-id_q14").find("input:checked").val()
        );
        var selection15 = parseInt(
          $("#group-id_q15").find("input:checked").val()
        );
        var selection16 = parseInt(
          $("#group-id_q16").find("input:checked").val()
        );

        document.getElementById("id_q17").value =
          selection2 +
          selection5 +
          selection6 +
          selection7 +
          selection11 +
          selection12 +
          selection15;

        document.getElementById("id_q18").value =
          selection3 +
          selection4 +
          selection8 +
          selection9 +
          selection10 +
          selection13 +
          selection14 +
          selection16;
      });

      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #group-id_q16, #id_q17, #id_q18"
      ).change();
    });
  },
  128: () => {
    $(document).ready(function () {
      $(
        "#group-id_q2, #group-id_q4, #group-id_q8, #group-id_q11, #id_q12"
      ).change(function () {
        var left = parseInt(document.getElementById("id_q8").value);
        var right = parseInt(document.getElementById("id_q11").value);
        var score_3 = 0;

        if (left < right) {
          score_3 = left;
        } else {
          score_3 = right;
        }

        document.getElementById("id_q12").value =
          parseInt(document.getElementById("id_q2").value) +
          parseInt(document.getElementById("id_q4").value) +
          score_3;
      });

      $(
        "#group-id_q14, #group-id_q16, #group-id_q18, #group-id_q19, #id_q20"
      ).change(function () {
        var left = parseInt(document.getElementById("id_q18").value);
        var right = parseInt(document.getElementById("id_q19").value);
        var score_6 = 0;

        if (left < right) {
          score_6 = left;
        } else {
          score_6 = right;
        }

        document.getElementById("id_q20").value =
          parseInt(document.getElementById("id_q14").value) +
          parseInt(document.getElementById("id_q16").value) +
          score_6;
      });

      $("#group-id_q23, #group-id_q26, #group-id_q29, #id_q30").change(
        function () {
          document.getElementById("id_q30").value =
            parseInt(document.getElementById("id_q23").value) +
            parseInt(document.getElementById("id_q26").value) +
            parseInt(document.getElementById("id_q29").value);
        }
      );

      $(
        "#group-id_q32, #group-id_q34, #group-id_q36, #group-id_q38, #group-id_q40, #group-id_q41, #group-id_q42, #id_q43"
      ).change(function () {
        var task = parseInt(document.getElementById("id_q40").value);
        var dual = parseInt(document.getElementById("id_q41").value);
        var partial = dual / task;
        var score_14 = 0;

        if (partial > 1.1) {
          score_14 = -1;
        }

        document.getElementById("id_q43").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q34").value) +
          parseInt(document.getElementById("id_q36").value) +
          parseInt(document.getElementById("id_q38").value) +
          parseInt(document.getElementById("id_q42").value) +
          score_14;
      });

      $(
        "#group-id_q2, #group-id_q4, #group-id_q8, #group-id_q11, #id_q12"
      ).change();
      $(
        "#group-id_q14, #group-id_q16, #group-id_q18, #group-id_q19, #id_q20"
      ).change();
      $("#group-id_q23, #group-id_q26, #group-id_q29, #id_q30").change();
      $(
        "#group-id_q32, #group-id_q34, #group-id_q36, #group-id_q38, #group-id_q40, #group-id_q41, #group-id_q42, #id_q43"
      ).change();
    });
  },
  259: () => {
    $(document).ready(function () {
      // document.getElementById('id_q3').disabled = true
      // document.getElementById('id_q12').disabled = true
      // document.getElementById('id_q21').disabled = true
      // document.getElementById('id_q31').disabled = true
      // document.getElementById('id_q40').disabled = true
      // document.getElementById('id_q49').disabled = true

      $("#group-id_q2, #id_q3").change(function () {
        var selection = $("#group-id_q2").find("input:checked").val();
        document.getElementById("id_q3").value = selection;
      });

      $(
        "#group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12"
      ).change(function () {
        var change = 0;
        if (document.getElementById("id_q5_0").checked) {
          document.getElementById("id_q12").value = 1;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q6_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q6_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q6_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q12").value = 2;
          change = 1;
        }
        posturalCounter = 0;
        if (document.getElementById("id_q7_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q7_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q7_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q12").value = 3;
          change = 1;
        }
        posturalCounter = 0;
        if (document.getElementById("id_q8_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q8_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q8_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q12").value = 4;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q9_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q9_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q9_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q12").value = 5;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q10_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q10_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q10_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q12").value = 6;
          change = 1;
        }
        var posturalCounter_seven = 0;
        if (document.getElementById("id_q11_0").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q11_1").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q11_2").checked) {
          posturalCounter_seven++;
        }
        if (posturalCounter_seven > 1 && posturalCounter == 3) {
          document.getElementById("id_q12").value = 7;
          change = 1;
        }
        if (change == 0) {
          document.getElementById("id_q12").value = "";
        }
      });

      $(
        "#group-id_q14, #group-id_q15, #group-id_q16, #group-id_q17, #group-id_q18, #group-id_q19, #group-id_q20, #id_q21"
      ).change(function () {
        var change = 0;
        if (document.getElementById("id_q14_0").checked) {
          document.getElementById("id_q21").value = 1;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q15_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q15_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q15_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q21").value = 2;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q16_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q16_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q16_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q21").value = 3;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q17_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q17_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q17_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q21").value = 4;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q18_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q18_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q18_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q21").value = 5;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q19_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q19_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q19_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q21").value = 6;
          change = 1;
        }
        var posturalCounter_seven = 0;
        if (document.getElementById("id_q20_0").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q20_1").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q20_2").checked) {
          posturalCounter_seven++;
        }
        if (posturalCounter_seven > 1 && posturalCounter == 3) {
          document.getElementById("id_q21").value = 7;
          change = 1;
        }
        if (change == 0) {
          document.getElementById("id_q21").value = "";
        }
      });

      $(
        "#group-id_q24, #group-id_q25, #group-id_q26, #group-id_q27, #group-id_q28, #group-id_q29, #group-id_q30, #id_q31"
      ).change(function () {
        var change = 0;
        if (document.getElementById("id_q24_0").checked) {
          document.getElementById("id_q31").value = 1;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q25_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q25_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q25_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q31").value = 2;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q26_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q26_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q26_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q31").value = 3;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q27_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q27_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q27_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q31").value = 4;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q28_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q28_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q28_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q31").value = 5;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q29_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q29_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q29_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q31").value = 6;
          change = 1;
        }
        var posturalCounter_seven = 0;
        if (document.getElementById("id_q30_0").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q30_1").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q30_2").checked) {
          posturalCounter_seven++;
        }
        if (posturalCounter_seven > 1 && posturalCounter == 3) {
          document.getElementById("id_q31").value = 7;
          change = 1;
        }
        if (change == 0) {
          document.getElementById("id_q31").value = "";
        }
      });

      $(
        "#group-id_q33, #group-id_q34, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #group-id_q39, #id_q40"
      ).change(function () {
        var change = 0;
        if (document.getElementById("id_q33_0").checked) {
          document.getElementById("id_q40").value = 1;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q34_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q34_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q34_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q40").value = 2;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q35_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q35_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q35_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q40").value = 3;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q36_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q36_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q36_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q40").value = 4;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q37_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q37_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q37_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q40").value = 5;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q38_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q38_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q38_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q40").value = 6;
          change = 1;
        }
        var posturalCounter_seven = 0;
        if (document.getElementById("id_q39_0").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q39_1").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q39_2").checked) {
          posturalCounter_seven++;
        }
        if (posturalCounter_seven > 1 && posturalCounter == 3) {
          document.getElementById("id_q40").value = 7;
          change = 1;
        }
        if (change == 0) {
          document.getElementById("id_q40").value = "";
        }
      });

      $(
        "#group-id_q42, #group-id_q43, #group-id_q44, #group-id_q45, #group-id_q46, #group-id_q47, #group-id_q48, #id_q49"
      ).change(function () {
        var change = 0;
        if (document.getElementById("id_q42_0").checked) {
          document.getElementById("id_q49").value = 1;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q43_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q43_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q43_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q49").value = 2;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q44_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q44_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q44_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q49").value = 3;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q45_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q45_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q45_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q49").value = 4;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q46_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q46_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q46_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q49").value = 5;
          change = 1;
        }
        var posturalCounter = 0;
        if (document.getElementById("id_q47_0").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q47_1").checked) {
          posturalCounter++;
        }
        if (document.getElementById("id_q47_2").checked) {
          posturalCounter++;
        }
        if (posturalCounter > 1) {
          document.getElementById("id_q49").value = 6;
          change = 1;
        }
        var posturalCounter_seven = 0;
        if (document.getElementById("id_q48_0").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q48_1").checked) {
          posturalCounter_seven++;
        }
        if (document.getElementById("id_q48_2").checked) {
          posturalCounter_seven++;
        }
        if (posturalCounter_seven > 1 && posturalCounter == 3) {
          document.getElementById("id_q49").value = 7;
          change = 1;
        }
        if (change == 0) {
          document.getElementById("id_q49").value = "";
        }
      });

      $("#group-id_q2, #id_q3").change();
      $(
        "#group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12"
      ).change();
      $(
        "#group-id_q14, #group-id_q15, #group-id_q16, #group-id_q17, #group-id_q18, #group-id_q19, #group-id_q20, #id_q21"
      ).change();
      $(
        "#group-id_q33, #group-id_q34, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #group-id_q39, #id_q40"
      ).change();
      $(
        "#group-id_q42, #group-id_q43, #group-id_q44, #group-id_q45, #group-id_q46, #group-id_q47, #group-id_q48, #id_q49"
      ).change();
    });
  },
  253: () => {
    $(document).ready(function () {
      document.getElementById("group-id_separator0").style.backgroundColor =
        "#e0e0e0";
      document.getElementById("group-id_q1").style.backgroundColor = "#e0e0e0";
      document.getElementById("group-id_q2").style.backgroundColor = "#e0e0e0";
      document.getElementById("group-id_q3").style.backgroundColor = "#e0e0e0";

      var last_question = 90;

      if (parseInt($("#group-id_q1").find("input:checked").val()) == 0) {
        for (var i = 2; i <= last_question; i++) {
          $("#group-id_q" + i).css({ display: "none" });
        }
        $("#group-id_q100").css({ display: "block" });
      } else {
        for (var i = 2; i <= last_question; i++) {
          $("#group-id_q" + i).css({ display: "block" });
        }
        $("#group-id_q100").css({ display: "none" });
      }

      // Event handler for test
      $("#group-id_q1").change(function () {
        if (parseInt($("#group-id_q1").find("input:checked").val()) == 0) {
          for (var i = 2; i <= last_question; i++) {
            $("#group-id_q" + i).css({ display: "none" });
          }
          $("#group-id_q100").css({ display: "block" });
        } else {
          for (var i = 2; i <= last_question; i++) {
            $("#group-id_q" + i).css({ display: "block" });
          }
          $("#group-id_q100").css({ display: "none" });
        }
      });
      $("#group-id_q1").change();
    });
  },
  330: () => {
    $(document).ready(function () {
      var actual_max_score = 0,
        selection2_flag = false,
        selection3_flag = false,
        selection4_flag = false,
        selection5_flag = false,
        selection6_flag = false,
        selection7_flag = false,
        selection8_flag = false,
        selection9_flag = false,
        selection10_flag = false,
        selection11_flag = false;

      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12, #id_q13"
      ).change(function () {
        if ($("#group-id_q2").find("input:checked").val() == null) {
          var selection2 = 0;
        } else {
          var selection2 = parseInt(
            $("#group-id_q2").find("input:checked").val()
          );
          if (selection2_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection2_flag = true;
          }
        }

        if ($("#group-id_q3").find("input:checked").val() == null) {
          var selection3 = 0;
        } else {
          var selection3 = parseInt(
            $("#group-id_q3").find("input:checked").val()
          );
          if (selection3_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection3_flag = true;
          }
        }

        if ($("#group-id_q4").find("input:checked").val() == null) {
          var selection4 = 0;
        } else {
          var selection4 = parseInt(
            $("#group-id_q4").find("input:checked").val()
          );
          if (selection4_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection4_flag = true;
          }
        }

        if ($("#group-id_q5").find("input:checked").val() == null) {
          var selection5 = 0;
        } else {
          var selection5 = parseInt(
            $("#group-id_q5").find("input:checked").val()
          );
          if (selection5_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection5_flag = true;
          }
        }

        if ($("#group-id_q6").find("input:checked").val() == null) {
          var selection6 = 0;
        } else {
          var selection6 = parseInt(
            $("#group-id_q6").find("input:checked").val()
          );
          if (selection6_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection6_flag = true;
          }
        }

        if ($("#group-id_q7").find("input:checked").val() == null) {
          var selection7 = 0;
        } else {
          var selection7 = parseInt(
            $("#group-id_q7").find("input:checked").val()
          );
          if (selection7_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection7_flag = true;
          }
        }

        if ($("#group-id_q8").find("input:checked").val() == null) {
          var selection8 = 0;
        } else {
          var selection8 = parseInt(
            $("#group-id_q8").find("input:checked").val()
          );
          if (selection8_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection8_flag = true;
          }
        }

        if ($("#group-id_q9").find("input:checked").val() == null) {
          var selection9 = 0;
        } else {
          var selection9 = parseInt(
            $("#group-id_q9").find("input:checked").val()
          );
          if (selection9_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection9_flag = true;
          }
        }

        if ($("#group-id_q10").find("input:checked").val() == null) {
          var selection10 = 0;
        } else {
          var selection10 = parseInt(
            $("#group-id_q10").find("input:checked").val()
          );
          if (selection10_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection10_flag = true;
          }
        }

        if ($("#group-id_q11").find("input:checked").val() == null) {
          var selection11 = 0;
        } else {
          var selection11 = parseInt(
            $("#group-id_q11").find("input:checked").val()
          );
          if (selection11_flag == false) {
            actual_max_score = actual_max_score + 5;
            selection11_flag = true;
          }
        }

        if (actual_max_score == 0) {
          actual_max_score = 1;
        }
        var score =
          (selection2 +
            selection3 +
            selection4 +
            selection5 +
            selection6 +
            selection7 +
            selection8 +
            selection9 +
            selection10 +
            selection11) /
          actual_max_score;
        var percent_score = parseInt(score * 100);

        document.getElementById("id_q12").value = percent_score;

        setTimeout(() => {
          if (percent_score < 21) {
            document.getElementById("id_q13").value = "Minimal disability";
          } else if (percent_score < 41) {
            document.getElementById("id_q13").value = "Moderate disability";
          } else if (percent_score < 61) {
            document.getElementById("id_q13").value = "Severe disability";
          } else if (percent_score < 81) {
            document.getElementById("id_q13").value = "Crippled";
          } else {
            document.getElementById("id_q13").value =
              "Bed-bound or exaggerating symptoms";
          }
        }, 0);
      });

      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12, #id_q13"
      ).change();
    });
  },
  371: () => {
    $(document).ready(function () {
      var physical = "";
      var mental = "";
      var euro = "";

      $(
        "#group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #id_q50, #id_q51, #id_q52"
      ).change(function () {
        // if ($('#group-id_q6').find('input:checked').val() != null){console.log('jpp1')}
        //     if ($('#group-id_q7').find('input:checked').val() != null){console.log('jpp2')}
        //         if ($('#group-id_q8').find('input:checked').val() != null){console.log('jpp3')}
        //             if ($('#group-id_q9').find('input:checked').val() != null){console.log('jpp4')}
        //                 if ($('#group-id_q10').find('input:checked').val() != null){console.log('jpp5')}
        //                     if ($('#group-id_q11').find('input:checked').val() != null){console.log('jpp6')}
        //                         if ($('#group-id_q12').find('input:checked').val() != null){console.log('jpp7')}
        //                             if ($('#group-id_q13').find('input:checked').val() != null){console.log('jpp8')}
        //                                 if ($('#group-id_q14').find('input:checked').val() != null){console.log('jpp9')}
        //                                     if ($('#group-id_q15').find('input:checked').val() != null){console.log('jpp10')}
        //     // console.log($('#group-id_q7').find('input:checked').val())
        //     // console.log($('#group-id_q8').find('input:checked').val())
        //     // console.log($('#group-id_q9').find('input:checked').val())
        //     // console.log($('#group-id_q10').find('input:checked').val())
        //     // console.log($('#group-id_q11').find('input:checked').val())
        //     // console.log($('#group-id_q12').find('input:checked').val())
        //     // console.log($('#group-id_q13').find('input:checked').val())
        //     // console.log($('#group-id_q14').find('input:checked').val())
        //     // console.log($('#group-id_q15').find('input:checked').val())

        if (
          $("#group-id_q6").find("input:checked").val() != null &&
          $("#group-id_q7").find("input:checked").val() != null &&
          $("#group-id_q8").find("input:checked").val() != null &&
          $("#group-id_q9").find("input:checked").val() != null &&
          $("#group-id_q10").find("input:checked").val() != null &&
          $("#group-id_q11").find("input:checked").val() != null &&
          $("#group-id_q12").find("input:checked").val() != null &&
          $("#group-id_q13").find("input:checked").val() != null &&
          $("#group-id_q14").find("input:checked").val() != null &&
          $("#group-id_q15").find("input:checked").val() != null
        ) {
          var selection1 = parseInt(
            $("#group-id_q6").find("input:checked").val()
          );
          var selection2 = parseInt(
            $("#group-id_q7").find("input:checked").val()
          );
          var selection3 = parseInt(
            $("#group-id_q8").find("input:checked").val()
          );
          var selection4 = parseInt(
            $("#group-id_q9").find("input:checked").val()
          );
          var selection5 = parseInt(
            $("#group-id_q10").find("input:checked").val()
          );
          var selection6 = parseInt(
            $("#group-id_q11").find("input:checked").val()
          );
          var selection7 = parseInt(
            $("#group-id_q12").find("input:checked").val()
          );
          var selection8 = parseInt(
            $("#group-id_q13").find("input:checked").val()
          );
          var selection9 = parseInt(
            $("#group-id_q14").find("input:checked").val()
          );
          var selection10 = parseInt(
            $("#group-id_q15").find("input:checked").val()
          );

          // Carefull in the ICHOM score explanation globalo not same order

          //Globalo 7
          if (selection10 == 10) {
            var ReSelection10 = 1;
          } else {
            if (selection10 > 6) {
              ReSelection10 = 2;
            } else {
              if (selection10 > 3) {
                ReSelection10 = 3;
              } else {
                if (selection10 > 0) {
                  ReSelection10 = 4;
                } else {
                  ReSelection10 = 5;
                }
              }
            }
          }

          //Globalo 8
          if (selection9 == 1) {
            var ReSelection9 = 5;
          } else {
            if (selection9 == 2) {
              ReSelection9 = 4;
            } else {
              if (selection9 == 3) {
                ReSelection9 = 3;
              } else {
                if (selection9 == 4) {
                  ReSelection9 = 2;
                } else {
                  ReSelection9 = 1;
                }
              }
            }
          }

          //Globalo 10
          if (selection8 == 1) {
            var ReSelection8 = 5;
          } else {
            if (selection8 == 2) {
              ReSelection8 = 4;
            } else {
              if (selection8 == 3) {
                ReSelection8 = 3;
              } else {
                if (selection8 == 4) {
                  ReSelection8 = 2;
                } else {
                  ReSelection8 = 1;
                }
              }
            }
          }

          physical = selection3 + selection7 + ReSelection10 + ReSelection9;
          mental = selection2 + selection4 + selection5 + ReSelection8;
          euro =
            0.19123 +
            0.00672 * selection2 +
            0.00527 * selection3 +
            0.0083 * selection4 +
            0.0455 * selection7 +
            0.02713 * ReSelection10 +
            0.01305 * ReSelection9 +
            0.00613 * selection6 +
            0.02502 * ReSelection8;
        }

        document.getElementById("id_q50").value = physical;
        document.getElementById("id_q51").value = mental;
        document.getElementById("id_q52").value = euro;
      });

      $(
        "#group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #id_q50, #id_q51, #id_q52"
      ).change();
    });
  },
  210: () => {
    $(document).ready(function () {
      $("#group-id_h1").css({ display: "none" });
      $("#id_q1").change(function (event) {
        var selection = $(this).val();
        $("#id_h1").val(selection);
        $("#group-id_q1 .help-block").text($("#id_h1 option:selected").text());
      });
      $("#id_q1").change();
    });
  },
  262: () => {
    $(document).ready(function () {
      $("#group-id_q3, #group-id_q4, #id_q5").change(function () {
        var selection1 = $("#group-id_q3").find("input:checked").val();
        var selection2 = $("#group-id_q4").find("input:checked").val();

        document.getElementById("id_q5").value =
          parseInt(selection1) + parseInt(selection2);
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $(
        "#group-id_q8, #group-id_q9, #group-id_q10, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #id_q16"
      ).change(function () {
        var selection8 = parseInt(
          $("#group-id_q8").find("input:checked").val()
        );
        var selection9 = parseInt(
          $("#group-id_q9").find("input:checked").val()
        );
        var selection10 = parseInt(
          $("#group-id_q10").find("input:checked").val()
        );
        var selection12 = parseInt(
          $("#group-id_q12").find("input:checked").val()
        );
        var selection13 = parseInt(
          $("#group-id_q13").find("input:checked").val()
        );
        var selection14 = parseInt(
          $("#group-id_q14").find("input:checked").val()
        );
        var selection15 = parseInt(
          $("#group-id_q15").find("input:checked").val()
        );

        document.getElementById("id_q16").value =
          selection8 +
          selection9 +
          selection10 +
          selection12 +
          selection13 +
          selection14 +
          selection15;
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $("#group-id_q18, #group-id_q19, #id_q20").change(function () {
        var selection18 = parseInt(
          $("#group-id_q18").find("input:checked").val()
        );
        var selection19 = parseInt(
          $("#group-id_q19").find("input:checked").val()
        );

        document.getElementById("id_q20").value = selection18 + selection19;
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $("#group-id_q22, #group-id_q23, #id_q24").change(function () {
        var selection22 = parseInt(
          $("#group-id_q22").find("input:checked").val()
        );
        var selection23 = parseInt(
          $("#group-id_q23").find("input:checked").val()
        );

        document.getElementById("id_q24").value = selection22 + selection23;
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $("#group-id_q26, #id_q27").change(function () {
        var selection26 = parseInt(
          $("#group-id_q26").find("input:checked").val()
        );

        document.getElementById("id_q27").value = selection26;
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $("#id_q28").change(function () {
        document.getElementById("id_q28").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q16").value) +
          parseInt(document.getElementById("id_q20").value) +
          parseInt(document.getElementById("id_q24").value) +
          parseInt(document.getElementById("id_q27").value);
      });

      $("#group-id_q30, #group-id_q31, #group-id_q32, #id_q33").change(
        function () {
          var selection30 = parseInt(
            $("#group-id_q30").find("input:checked").val()
          );
          var selection31 = parseInt(
            $("#group-id_q31").find("input:checked").val()
          );
          var selection32 = parseInt(
            $("#group-id_q32").find("input:checked").val()
          );

          document.getElementById("id_q33").value =
            selection30 + selection31 + selection32;
          document.getElementById("id_q34").value =
            parseInt(document.getElementById("id_q28").value) +
            parseInt(document.getElementById("id_q33").value);
        }
      );

      $("#id_q34").change(function () {
        document.getElementById("id_q34").value =
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q33").value);
      });

      $(
        "#group-id_q37, #group-id_q38, #group-id_q40, #group-id_q41, #group-id_q42, #group-id_q43, #id_q44"
      ).change(function () {
        var selection37 = parseInt(
          $("#group-id_q37").find("input:checked").val()
        );
        var selection38 = parseInt(
          $("#group-id_q38").find("input:checked").val()
        );
        var selection40 = parseInt(
          $("#group-id_q40").find("input:checked").val()
        );
        var selection41 = parseInt(
          $("#group-id_q41").find("input:checked").val()
        );
        var selection42 = parseInt(
          $("#group-id_q42").find("input:checked").val()
        );
        var selection43 = parseInt(
          $("#group-id_q43").find("input:checked").val()
        );

        document.getElementById("id_q44").value =
          selection37 +
          selection38 +
          selection40 +
          selection41 +
          selection42 +
          selection43;
      });

      $(
        "#group-id_q47, #group-id_q48, #group-id_q49 , #group-id_q50, #group-id_q52, #group-id_q53, #group-id_q55, #group-id_q56, #group-id_q58, #group-id_q59, #id_q60"
      ).change(function () {
        var selection47 = parseInt(
          $("#group-id_q47").find("input:checked").val()
        );
        var selection48 = parseInt(
          $("#group-id_q48").find("input:checked").val()
        );
        var selection49 = parseInt(
          $("#group-id_q49").find("input:checked").val()
        );
        var selection50 = parseInt(
          $("#group-id_q50").find("input:checked").val()
        );
        var selection52 = parseInt(
          $("#group-id_q52").find("input:checked").val()
        );
        var selection53 = parseInt(
          $("#group-id_q53").find("input:checked").val()
        );
        var selection55 = parseInt(
          $("#group-id_q55").find("input:checked").val()
        );
        var selection56 = parseInt(
          $("#group-id_q56").find("input:checked").val()
        );
        var selection58 = parseInt(
          $("#group-id_q58").find("input:checked").val()
        );
        var selection59 = parseInt(
          $("#group-id_q59").find("input:checked").val()
        );

        document.getElementById("id_q60").value =
          selection47 +
          selection48 +
          selection49 +
          selection50 +
          selection52 +
          selection53 +
          selection55 +
          selection56 +
          selection58 +
          selection59;
      });

      $(
        "#group-id_q63, #group-id_q64, #group-id_q65, #group-id_q66, #group-id_q68, #group-id_q69, #group-id_q71, #group-id_q72, #group-id_q74, #group-id_q75, #id_q76"
      ).change(function () {
        var selection63 = parseInt(
          $("#group-id_q63").find("input:checked").val()
        );
        var selection64 = parseInt(
          $("#group-id_q64").find("input:checked").val()
        );
        var selection65 = parseInt(
          $("#group-id_q65").find("input:checked").val()
        );
        var selection66 = parseInt(
          $("#group-id_q66").find("input:checked").val()
        );
        var selection68 = parseInt(
          $("#group-id_q68").find("input:checked").val()
        );
        var selection69 = parseInt(
          $("#group-id_q69").find("input:checked").val()
        );
        var selection71 = parseInt(
          $("#group-id_q71").find("input:checked").val()
        );
        var selection72 = parseInt(
          $("#group-id_q72").find("input:checked").val()
        );
        var selection74 = parseInt(
          $("#group-id_q74").find("input:checked").val()
        );
        var selection75 = parseInt(
          $("#group-id_q75").find("input:checked").val()
        );

        document.getElementById("id_q76").value =
          selection63 +
          selection64 +
          selection65 +
          selection66 +
          selection68 +
          selection69 +
          selection71 +
          selection72 +
          selection74 +
          selection75;
      });

      $("#group-id_q3, #group-id_q4, #id_q5").change();
      $(
        "#group-id_q8, #group-id_q9, #group-id_q10, #group-id_q12, #group-id_q13, #group-id_q14, #group-id_q15, #id_q16"
      ).change();
      $("#group-id_q18, #group-id_q19, #id_q20").change();
      $("#group-id_q22, #group-id_q23, #id_q24").change();
      $("#group-id_q26, #id_q27").change();
      $("#id_q28").change();
      $("#group-id_q30, #group-id_q31, #group-id_q32, #id_q33").change();
      $("#id_q34").change();
      $(
        "#group-id_q37, #group-id_q38, #group-id_q40, #group-id_q41, #group-id_q42, #group-id_q43, #id_q44"
      ).change();
      $(
        "#group-id_q47, #group-id_q48, #group-id_q49, #group-id_q50, #group-id_q52, #group-id_q53, #group-id_q55, #group-id_q56, #group-id_q58, #group-id_q59, #id_q60"
      ).change();
      $(
        "#group-id_q63, #group-id_q64, #group-id_q65, #group-id_q66, #group-id_q68, #group-id_q69, #group-id_q71, #group-id_q72, #group-id_q74, #group-id_q75, #id_q76"
      ).change();
    });
  },
  261: () => {
    $(document).ready(function () {
      $("#group-id_q3, #group-id_q4, #id_q5").change(function () {
        var selection1 = $("#group-id_q3").find("input:checked").val();
        var selection2 = $("#group-id_q4").find("input:checked").val();

        document.getElementById("id_q5").value =
          parseInt(selection1) + parseInt(selection2);
        document.getElementById("id_q32").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q18").value) +
          parseInt(document.getElementById("id_q23").value) +
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q31").value);
        document.getElementById("id_q55").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q39").value) +
          parseInt(document.getElementById("id_q49").value) +
          parseInt(document.getElementById("id_q54").value);
      });

      $(
        "#group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q15, #group-id_q16, #group-id_q17, #id_q18"
      ).change(function () {
        var selection8 = parseInt(
          $("#group-id_q8").find("input:checked").val()
        );
        var selection9 = parseInt(
          $("#group-id_q9").find("input:checked").val()
        );
        var selection10 = parseInt(
          $("#group-id_q10").find("input:checked").val()
        );
        var selection11 = parseInt(
          $("#group-id_q11").find("input:checked").val()
        );
        var selection12 = parseInt(
          $("#group-id_q12").find("input:checked").val()
        );
        var selection13 = parseInt(
          $("#group-id_q13").find("input:checked").val()
        );
        var selection15 = parseInt(
          $("#group-id_q15").find("input:checked").val()
        );
        var selection16 = parseInt(
          $("#group-id_q16").find("input:checked").val()
        );
        var selection17 = parseInt(
          $("#group-id_q17").find("input:checked").val()
        );

        document.getElementById("id_q18").value =
          selection8 +
          selection9 +
          selection10 +
          selection11 +
          selection12 +
          selection13 +
          selection15 +
          selection16 +
          selection17;
        document.getElementById("id_q32").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q18").value) +
          parseInt(document.getElementById("id_q23").value) +
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q31").value);
        document.getElementById("id_q55").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q39").value) +
          parseInt(document.getElementById("id_q49").value) +
          parseInt(document.getElementById("id_q54").value);
      });

      $("#group-id_q20, #group-id_q21, #group-id_q22, #id_q23").change(
        function () {
          var selection20 = parseInt(
            $("#group-id_q20").find("input:checked").val()
          );
          var selection21 = parseInt(
            $("#group-id_q21").find("input:checked").val()
          );
          var selection22 = parseInt(
            $("#group-id_q22").find("input:checked").val()
          );

          document.getElementById("id_q23").value =
            selection20 + selection21 + selection22;
          document.getElementById("id_q32").value =
            parseInt(document.getElementById("id_q5").value) +
            parseInt(document.getElementById("id_q18").value) +
            parseInt(document.getElementById("id_q23").value) +
            parseInt(document.getElementById("id_q28").value) +
            parseInt(document.getElementById("id_q31").value);
          document.getElementById("id_q55").value =
            parseInt(document.getElementById("id_q32").value) +
            parseInt(document.getElementById("id_q39").value) +
            parseInt(document.getElementById("id_q49").value) +
            parseInt(document.getElementById("id_q54").value);
        }
      );

      $("#group-id_q25, #group-id_q26, #group-id_q27, #id_q28").change(
        function () {
          var selection25 = parseInt(
            $("#group-id_q25").find("input:checked").val()
          );
          var selection26 = parseInt(
            $("#group-id_q26").find("input:checked").val()
          );
          var selection27 = parseInt(
            $("#group-id_q27").find("input:checked").val()
          );

          document.getElementById("id_q28").value =
            selection25 + selection26 + selection27;
          document.getElementById("id_q32").value =
            parseInt(document.getElementById("id_q5").value) +
            parseInt(document.getElementById("id_q18").value) +
            parseInt(document.getElementById("id_q23").value) +
            parseInt(document.getElementById("id_q28").value) +
            parseInt(document.getElementById("id_q31").value);
          document.getElementById("id_q55").value =
            parseInt(document.getElementById("id_q32").value) +
            parseInt(document.getElementById("id_q39").value) +
            parseInt(document.getElementById("id_q49").value) +
            parseInt(document.getElementById("id_q54").value);
        }
      );

      $("#group-id_q30, #id_q31").change(function () {
        var selection30 = parseInt(
          $("#group-id_q30").find("input:checked").val()
        );

        document.getElementById("id_q31").value = selection30;
        document.getElementById("id_q32").value =
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q18").value) +
          parseInt(document.getElementById("id_q23").value) +
          parseInt(document.getElementById("id_q28").value) +
          parseInt(document.getElementById("id_q31").value);
        document.getElementById("id_q55").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q39").value) +
          parseInt(document.getElementById("id_q49").value) +
          parseInt(document.getElementById("id_q54").value);
      });

      $("#id_q5, #id_q18, #id_q23, #id_q28, #id_q31, #id_q32").change(
        function () {
          document.getElementById("id_q32").value =
            parseInt(document.getElementById("id_q5").value) +
            parseInt(document.getElementById("id_q18").value) +
            parseInt(document.getElementById("id_q23").value) +
            parseInt(document.getElementById("id_q28").value) +
            parseInt(document.getElementById("id_q31").value);
          document.getElementById("id_q55").value =
            parseInt(document.getElementById("id_q32").value) +
            parseInt(document.getElementById("id_q39").value) +
            parseInt(document.getElementById("id_q49").value) +
            parseInt(document.getElementById("id_q54").value);
        }
      );

      $(
        "#group-id_q35, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #id_q39"
      ).change(function () {
        var selection34 = parseInt(
          $("#group-id_q34").find("input:checked").val()
        );
        var selection35 = parseInt(
          $("#group-id_q35").find("input:checked").val()
        );
        var selection36 = parseInt(
          $("#group-id_q36").find("input:checked").val()
        );
        var selection37 = parseInt(
          $("#group-id_q37").find("input:checked").val()
        );
        var selection38 = parseInt(
          $("#group-id_q38").find("input:checked").val()
        );

        document.getElementById("id_q39").value =
          selection34 + selection35 + selection36 + selection37 + selection38;
        document.getElementById("id_q55").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q39").value) +
          parseInt(document.getElementById("id_q49").value) +
          parseInt(document.getElementById("id_q54").value);
      });

      $(
        "#group-id_q41, #group-id_q42, #group-id_q44, #group-id_q45, #group-id_q46, #group-id_q47, #group-id_q48, #id_q49"
      ).change(function () {
        var selection41 = parseInt(
          $("#group-id_q41").find("input:checked").val()
        );
        var selection42 = parseInt(
          $("#group-id_q42").find("input:checked").val()
        );
        var selection44 = parseInt(
          $("#group-id_q44").find("input:checked").val()
        );
        var selection45 = parseInt(
          $("#group-id_q45").find("input:checked").val()
        );
        var selection46 = parseInt(
          $("#group-id_q46").find("input:checked").val()
        );
        var selection47 = parseInt(
          $("#group-id_q47").find("input:checked").val()
        );
        var selection48 = parseInt(
          $("#group-id_q48").find("input:checked").val()
        );

        document.getElementById("id_q49").value =
          selection41 +
          selection42 +
          selection44 +
          selection45 +
          selection46 +
          selection47 +
          selection48;
        document.getElementById("id_q55").value =
          parseInt(document.getElementById("id_q32").value) +
          parseInt(document.getElementById("id_q39").value) +
          parseInt(document.getElementById("id_q49").value) +
          parseInt(document.getElementById("id_q54").value);
      });

      $("#group-id_q51, #group-id_q52, #group-id_q53, #id_q54, #id_q55").change(
        function () {
          var selection51 = parseInt(
            $("#group-id_q51").find("input:checked").val()
          );
          var selection52 = parseInt(
            $("#group-id_q52").find("input:checked").val()
          );
          var selection53 = parseInt(
            $("#group-id_q53").find("input:checked").val()
          );

          document.getElementById("id_q54").value =
            selection51 + selection52 + selection53;
          document.getElementById("id_q55").value =
            parseInt(document.getElementById("id_q32").value) +
            parseInt(document.getElementById("id_q39").value) +
            parseInt(document.getElementById("id_q49").value) +
            parseInt(document.getElementById("id_q54").value);
        }
      );

      $(
        "#group-id_q58, #group-id_q59, #group-id_q61, #group-id_q62, #group-id_q63, #group-id_q64, #id_q65"
      ).change(function () {
        var selection58 = parseInt(
          $("#group-id_q58").find("input:checked").val()
        );
        var selection59 = parseInt(
          $("#group-id_q59").find("input:checked").val()
        );
        var selection61 = parseInt(
          $("#group-id_q61").find("input:checked").val()
        );
        var selection62 = parseInt(
          $("#group-id_q62").find("input:checked").val()
        );
        var selection63 = parseInt(
          $("#group-id_q63").find("input:checked").val()
        );
        var selection64 = parseInt(
          $("#group-id_q64").find("input:checked").val()
        );

        document.getElementById("id_q65").value =
          selection58 +
          selection59 +
          selection61 +
          selection62 +
          selection63 +
          selection64;
      });

      $(
        "#group-id_q68, #group-id_q69, #group-id_q70, #group-id_q71, #group-id_q73, #group-id_q74, #group-id_q76, #group-id_q77, #group-id_q79, #group-id_q80, #group-id_q82, #group-id_q83, #id_q84"
      ).change(function () {
        var selection68 = parseInt(
          $("#group-id_q68").find("input:checked").val()
        );
        var selection69 = parseInt(
          $("#group-id_q69").find("input:checked").val()
        );
        var selection70 = parseInt(
          $("#group-id_q70").find("input:checked").val()
        );
        var selection71 = parseInt(
          $("#group-id_q71").find("input:checked").val()
        );
        var selection73 = parseInt(
          $("#group-id_q73").find("input:checked").val()
        );
        var selection74 = parseInt(
          $("#group-id_q74").find("input:checked").val()
        );
        var selection76 = parseInt(
          $("#group-id_q76").find("input:checked").val()
        );
        var selection77 = parseInt(
          $("#group-id_q77").find("input:checked").val()
        );
        var selection79 = parseInt(
          $("#group-id_q79").find("input:checked").val()
        );
        var selection80 = parseInt(
          $("#group-id_q80").find("input:checked").val()
        );
        var selection82 = parseInt(
          $("#group-id_q82").find("input:checked").val()
        );
        var selection83 = parseInt(
          $("#group-id_q83").find("input:checked").val()
        );

        document.getElementById("id_q84").value =
          selection68 +
          selection69 +
          selection70 +
          selection71 +
          selection73 +
          selection74 +
          selection76 +
          selection77 +
          selection79 +
          selection80 +
          selection82 +
          selection83;
      });

      $(
        "#group-id_q87, #group-id_q88, #group-id_q89, #group-id_q90, #group-id_q92, #group-id_q93, #group-id_q95, #group-id_q96, #group-id_q98, #group-id_q99, #group-id_q101, #group-id_q102, #id_q103"
      ).change(function () {
        var selection87 = parseInt(
          $("#group-id_q87").find("input:checked").val()
        );
        var selection88 = parseInt(
          $("#group-id_q88").find("input:checked").val()
        );
        var selection89 = parseInt(
          $("#group-id_q89").find("input:checked").val()
        );
        var selection90 = parseInt(
          $("#group-id_q90").find("input:checked").val()
        );
        var selection92 = parseInt(
          $("#group-id_q92").find("input:checked").val()
        );
        var selection93 = parseInt(
          $("#group-id_q93").find("input:checked").val()
        );
        var selection95 = parseInt(
          $("#group-id_q95").find("input:checked").val()
        );
        var selection96 = parseInt(
          $("#group-id_q96").find("input:checked").val()
        );
        var selection98 = parseInt(
          $("#group-id_q98").find("input:checked").val()
        );
        var selection99 = parseInt(
          $("#group-id_q99").find("input:checked").val()
        );
        var selection101 = parseInt(
          $("#group-id_q101").find("input:checked").val()
        );
        var selection102 = parseInt(
          $("#group-id_q102").find("input:checked").val()
        );

        document.getElementById("id_q103").value =
          selection87 +
          selection88 +
          selection89 +
          selection90 +
          selection92 +
          selection93 +
          selection95 +
          selection96 +
          selection98 +
          selection99 +
          selection101 +
          selection102;
      });

      $("#group-id_q3, #group-id_q4, #id_q5").change();
      $(
        "#group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #group-id_q12, #group-id_q13, #group-id_q15, #group-id_q16, #group-id_q17, #id_q18"
      ).change();
      $("#group-id_q20, #group-id_q21, #group-id_q22, #id_q23").change();
      $("#group-id_q25, #group-id_q26, #group-id_q27, #id_q28").change();
      $("#group-id_q30, #id_q31").change();
      $("#id_q5, #id_q18, #id_q23, #id_q28, #id_q31, #id_q32").change();
      $(
        "#group-id_q35, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #id_q39"
      ).change();
      $(
        "#group-id_q41, #group-id_q42, #group-id_q44, #group-id_q45, #group-id_q46, #group-id_q47, #group-id_q48, #id_q49"
      ).change();
      $(
        "#group-id_q51, #group-id_q52, #group-id_q53, #id_q54, #id_q55"
      ).change();
      $(
        "#group-id_q58, #group-id_q59, #group-id_q61, #group-id_q61, #group-id_q62, #group-id_q63, #group-id_q64, #id_q65"
      ).change();
      $(
        "#group-id_q68, #group-id_q69, #group-id_q70, #group-id_q71, #group-id_q73, #group-id_q74, #group-id_q76, #group-id_q77, #group-id_q79, #group-id_q80, #group-id_q82, #group-id_q83, #id_q84"
      ).change();
      $(
        "#group-id_q87, #group-id_q88, #group-id_q89, #group-id_q90, #group-id_q92, #group-id_q93, #group-id_q95, #group-id_q96, #group-id_q98, #group-id_q99, #group-id_q101, #group-id_q102, #id_q103"
      ).change();
    });
  },
  260: () => {
    $(document).ready(function () {
      $(document).keypress(function (event) {
        if (event.which == "13") {
          event.preventDefault();
        }
      });

      // document.getElementById('id_q12').disabled = true
      // document.getElementById('id_q21').disabled = true

      var bonus = 0;
      var first_prompt = 0;
      var age = 0;

      $(
        "#id_q2, #id_q3, #id_q4, #id_q5, #id_q6, #id_q7, #id_q8, #id_q9, #id_q10, #id_q11, #id_q14, #id_q15, #id_q16, #id_q17, #id_q18, #id_q19"
      ).change(function (event) {
        if (first_prompt == 33) {
          var age = prompt("Please enter the patient age");
        }
        first_prompt++;
      });

      $("#id_q19").change(function (event) {
        if (first_prompt > 33) {
          var age = prompt("Please enter the patient age");
        }
      });

      $(
        "#id_q2, #id_q3, #id_q4, #id_q5, #id_q6, #id_q7, #id_q8, #id_q9, #id_q10, #id_q11, #id_q12"
      ).change(function (event) {
        document.getElementById("id_q12").value =
          parseInt(document.getElementById("id_q2").value) +
          parseInt(document.getElementById("id_q3").value) +
          parseInt(document.getElementById("id_q4").value) +
          parseInt(document.getElementById("id_q5").value) +
          parseInt(document.getElementById("id_q6").value) +
          parseInt(document.getElementById("id_q7").value) +
          parseInt(document.getElementById("id_q8").value) +
          parseInt(document.getElementById("id_q9").value) +
          parseInt(document.getElementById("id_q10").value) +
          parseInt(document.getElementById("id_q11").value);
      });

      $("#id_q14, #id_q15, #id_q16, #id_q17, #id_q18, #id_q19").change(
        function (event) {
          if (first_prompt > 33) {
            if (
              age < 70 &&
              parseInt(document.getElementById("id_q19").value) > 96
            ) {
              bonus = 2;
            } else {
              if (
                age >= 70 &&
                parseInt(document.getElementById("id_q19").value) > 84
              ) {
                bonus = 2;
              }
            }

            document.getElementById("id_q21").value =
              parseInt(document.getElementById("id_q14").value) +
              parseInt(document.getElementById("id_q15").value) +
              parseInt(document.getElementById("id_q16").value) +
              parseInt(document.getElementById("id_q17").value) +
              parseInt(document.getElementById("id_q18").value) +
              bonus;
          }
        }
      );

      $(
        "#id_q2, #id_q3, #id_q4, #id_q5, #id_q6, #id_q7, #id_q8, #id_q9, #id_q10, #id_q11, #id_q12"
      ).change();
      $("#id_q14, #id_q15, #id_q16, #id_q17, #id_q18, #id_q19").change();
      $(
        "#id_q2, #id_q3, #id_q4, #id_q5, #id_q6, #id_q7, #id_q8, #id_q9, #id_q10, #id_q11, #id_q14, #id_q15, #id_q16, #id_q17, #id_q18, #id_q19"
      ).change();
      $("#id_q19").change();
    });
  },
  47: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_h2").css({ display: "none" });
      $("#id_q2").change(function (event) {
        var selection = $(this).val();
        $("#id_h2").val(selection);
        $("#group-id_q2 .help-block").text($("#id_h2 option:selected").text());
      });

      $("#id_q2").change();
    });
  },
  208: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        $("#id_q1 :selected").each(function () {
          if ($(this).val() === "17") {
            $("#group-id_q2").css({ display: "block" });
          }
        });
      });
      $("#id_q1").change();
    });
  },
  148: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        $("#id_q1 :selected").each(function () {
          if ($(this).val() === "5") {
            $("#group-id_q2").css({ display: "block" });
          }
        });
      });
      $("#id_q1").change();
    });
  },
  123: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  116: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  124: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  188: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  189: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  207: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  187: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  185: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  183: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  115: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "4") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  117: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "4") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  186: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // Other
        if (selection === "6") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  30: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for Modality
      $("#id_q1").change(function () {
        // Hide all optional fields

        // Only change if involved = specific
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        // If Other
        if (selection === "4") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  176: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        $("#id_q1 :selected").each(function () {
          if ($(this).val() === "10") {
            $("#group-id_q2").css({ display: "block" });
          }
        });
      });
      $("#id_q1").change();
    });
  },
  74: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  70: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  68: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  69: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  75: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  38: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "10") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  175: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "19") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  66: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  71: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  73: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  67: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  65: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  72: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  64: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  63: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q2").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  332: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        var selection = $(this).val();
        // Traumatic
        if (selection === "0") {
          $("#group-id_q2").css({ display: "block" });
        }
        // Non-traumatic
        else if (selection === "1") {
          $("#group-id_q3").css({ display: "block" });
        } else {
          $("#group-id_q4").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  41: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });

      // Event handler for test
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q2").css({ display: "block" });
        }
        if (selection === "1") {
          $("#group-id_q3").css({ display: "block" });
        }
        if (selection === "2") {
          $("#group-id_q4").css({ display: "block" });
        }
        if (selection === "3") {
          $("#group-id_q5").css({ display: "block" });
        }
        if (selection === "4") {
          $("#group-id_q6").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  37: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q15").css({ display: "none" });
      $("#group-id_q16").css({ display: "none" });
      $("#group-id_q19").css({ display: "none" });
      $("#group-id_q20").css({ display: "none" });
      $("#group-id_h19").css({ display: "none" });
      $("#group-id_h20").css({ display: "none" });

      // Event handler for Structure
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q2").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q5").css({ display: "block" });
        } else if (selection === "2") {
          $("#group-id_q8").css({ display: "block" });
        }
      });

      // Event handler for Cervical Articulations
      $("#id_q2").change(function () {
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q4").css({ display: "block" });
        } else if (selection === "6") {
          $("#group-id_q3").css({ display: "block" });
        }
      });

      // Event handler for Upper Limb
      $("#id_q5").change(function () {
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "4") {
          $("#group-id_q7").css({ display: "block" });
        } else if (selection === "5") {
          $("#group-id_q6").css({ display: "block" });
        }
      });

      // Event handler for Lower Limb
      $("#id_q8").change(function () {
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q10").css({ display: "block" });
        } else if (selection === "4") {
          $("#group-id_q9").css({ display: "block" });
        }
      });

      // Event handler for Instrument
      $("#id_q12").change(function () {
        $("#group-id_q15").css({ display: "none" });
        $("#group-id_q16").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q16").css({ display: "block" });
        } else {
          $("#group-id_q15").css({ display: "block" });
        }
      });
      // Event handler for End Feel
      $("#id_q18").change(function () {
        $("#group-id_q19").css({ display: "none" });
        $("#group-id_q20").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q19").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q20").css({ display: "block" });
        }
      });

      // Event handler for Cyriax
      $("#id_q19").change(function () {
        var selection = $(this).val();
        $("#id_h19").val(selection);
        $("#group-id_q19 .help-block").text(
          $("#id_h19 option:selected").text()
        );
      });

      // Event handler for Kaltenborn
      $("#id_q20").change(function () {
        var selection = $(this).val();
        $("#id_h20").val(selection);
        $("#group-id_q20 .help-block").text(
          $("#id_h20 option:selected").text()
        );
      });

      $("#id_q1").change();
      $("#id_q12").change();
      $("#id_q18").change();
      $("#id_q19").change();
      $("#id_q20").change();
    });
  },
  18: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      // define event handler for Respiratory Exercise droplists
      $("#id_q1").change(function (event) {
        $("#group-id_q2").css({ display: "none" });
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        var selection = $(this).val();
        // If "Expansion Exercises; Diaphragmatic breathing control" display set of controls
        if (selection === "0" || selection === "1") {
          $("#group-id_q2").css({ display: "block" });
          $("#group-id_q3").css({ display: "block" });
          $("#group-id_q4").css({ display: "block" });
          $("#group-id_q5").css({ display: "block" });
          $("#group-id_q6").css({ display: "block" });
        } else {
          $("#group-id_q7").css({ display: "block" });
        }
      });
      $("#id_q1").change();
    });
  },
  297: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });

      // Event handler for Medication
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });

      // Event handler for Exams
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "4") {
          $("#group-id_q4").css({ display: "block" });
        }
        if (selection !== "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });

      // Event handler for General Health
      $("#id_q6").change(function () {
        $("#group-id_q7").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "11") {
          $("#group-id_q7").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q3").change();
      $("#id_q6").change();
    });
  },
  36: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });

      // Event handler for Medication
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q2").css({ display: "block" });
        }
      });

      // Event handler for Exams
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "4") {
          $("#group-id_q4").css({ display: "block" });
        }
        if (selection !== "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });

      // Event handler for General Health
      $("#id_q6").change(function () {
        $("#group-id_q7").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "11") {
          $("#group-id_q7").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q3").change();
      $("#id_q6").change();
    });
  },
  177: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q2").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q35").css({ display: "none" });

      // Event handler for body region
      $("#id_q1").change(function () {
        $("#group-id_q2").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "16") {
          $("#group-id_q2").css({ display: "block" });
        }
      });

      // Event handler for body area
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q5").css({ display: "block" });
        }
        if (selection === "6") {
          $("#group-id_q6").css({ display: "block" });
        }
      });

      $("#id_q8").change(function () {
        $("#group-id_q9").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "9") {
          $("#group-id_q9").css({ display: "block" });
        }
      });

      // Event handler for body area
      $("#id_q20").change(function () {
        var el = $("#id_q21");
        var list = [];
        try {
          list = JSON.parse($("#id_jsonfield").val());
        } catch {}
        el.empty();
        $("#id_q20 :selected").each(function () {
          //console.log($(this).text());
          if (list.indexOf($(this).val()) > -1) {
            el.append(
              $("<option></option>")
                .attr("value", $(this).val())
                .attr("selected", "selected")
                .text($(this).text())
            );
          } else {
            el.append(
              $("<option></option>")
                .attr("value", $(this).val())
                .text($(this).text())
            );
          }
        });
        //el.append($('<option></option>').attr('value', 99).text('adding manually'));
      });

      $("#id_q18").change(function () {
        $("#group-id_q19").css({ display: "none" });
        $("#group-id_q20").css({ display: "none" });
        $("#group-id_q21").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q19").css({ display: "block" });
        }
        if (selection === "3") {
          $("#group-id_q20").css({ display: "block" });
          $("#group-id_q21").css({ display: "block" });
        }
      });

      // Event handler for pain relief factors
      $("#id_q34").change(function () {
        $("#group-id_q35").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "4") {
          $("#group-id_q35").css({ display: "block" });
        }
      });

      $("#id_q1").change();
      $("#id_q4").change();
      $("#id_q18").change();
      $("#id_q34").change();
      $("#id_q8").change();
      $("#id_q20").change();
    });
  },
  274: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12"
      ).change(function () {
        var selection2 = parseInt(
          $("#group-id_q2").find("input:checked").val()
        );
        var selection3 = parseInt(
          $("#group-id_q3").find("input:checked").val()
        );
        var selection4 = parseInt(
          $("#group-id_q4").find("input:checked").val()
        );
        var selection5 = parseInt(
          $("#group-id_q5").find("input:checked").val()
        );
        var selection6 = parseInt(
          $("#group-id_q6").find("input:checked").val()
        );
        var selection7 = parseInt(
          $("#group-id_q7").find("input:checked").val()
        );
        var selection8 = parseInt(
          $("#group-id_q8").find("input:checked").val()
        );
        var selection9 = parseInt(
          $("#group-id_q9").find("input:checked").val()
        );
        var selection10 = parseInt(
          $("#group-id_q10").find("input:checked").val()
        );
        var selection11 = parseInt(
          $("#group-id_q11").find("input:checked").val()
        );

        document.getElementById("id_q12").value =
          selection2 +
          selection3 +
          selection4 +
          selection5 +
          selection6 +
          selection7 +
          selection8 +
          selection9 +
          selection10 +
          selection11;
      });

      $(
        "#group-id_q14, #group-id_q15, #group-id_q16, #group-id_q17, #group-id_q18, #group-id_q19, #group-id_q20, #group-id_q21, #group-id_q22, #group-id_q23, #group-id_q24, #group-id_q25, #group-id_q26, #group-id_q27, #group-id_q28, #group-id_q29, #group-id_q30, #group-id_q31, #group-id_q32, #group-id_q33, #group-id_q34, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #group-id_q39, #group-id_q40, #group-id_q41, #group-id_q42, #group-id_q43, #id_q44"
      ).change(function () {
        var selection14 = parseInt(
          $("#group-id_q14").find("input:checked").val()
        );
        var selection15 = parseInt(
          $("#group-id_q15").find("input:checked").val()
        );
        var selection16 = parseInt(
          $("#group-id_q16").find("input:checked").val()
        );
        var selection17 = parseInt(
          $("#group-id_q17").find("input:checked").val()
        );
        var selection18 = parseInt(
          $("#group-id_q18").find("input:checked").val()
        );
        var selection19 = parseInt(
          $("#group-id_q19").find("input:checked").val()
        );
        var selection20 = parseInt(
          $("#group-id_q20").find("input:checked").val()
        );
        var selection21 = parseInt(
          $("#group-id_q21").find("input:checked").val()
        );
        var selection22 = parseInt(
          $("#group-id_q22").find("input:checked").val()
        );
        var selection23 = parseInt(
          $("#group-id_q23").find("input:checked").val()
        );
        var selection24 = parseInt(
          $("#group-id_q24").find("input:checked").val()
        );
        var selection25 = parseInt(
          $("#group-id_q25").find("input:checked").val()
        );
        var selection26 = parseInt(
          $("#group-id_q26").find("input:checked").val()
        );
        var selection27 = parseInt(
          $("#group-id_q27").find("input:checked").val()
        );
        var selection28 = parseInt(
          $("#group-id_q28").find("input:checked").val()
        );
        var selection29 = parseInt(
          $("#group-id_q29").find("input:checked").val()
        );
        var selection30 = parseInt(
          $("#group-id_q30").find("input:checked").val()
        );
        var selection31 = parseInt(
          $("#group-id_q31").find("input:checked").val()
        );
        var selection32 = parseInt(
          $("#group-id_q32").find("input:checked").val()
        );
        var selection33 = parseInt(
          $("#group-id_q33").find("input:checked").val()
        );
        var selection34 = parseInt(
          $("#group-id_q34").find("input:checked").val()
        );
        var selection35 = parseInt(
          $("#group-id_q35").find("input:checked").val()
        );
        var selection36 = parseInt(
          $("#group-id_q36").find("input:checked").val()
        );
        var selection37 = parseInt(
          $("#group-id_q37").find("input:checked").val()
        );
        var selection38 = parseInt(
          $("#group-id_q38").find("input:checked").val()
        );
        var selection39 = parseInt(
          $("#group-id_q39").find("input:checked").val()
        );
        var selection40 = parseInt(
          $("#group-id_q40").find("input:checked").val()
        );
        var selection41 = parseInt(
          $("#group-id_q41").find("input:checked").val()
        );
        var selection42 = parseInt(
          $("#group-id_q42").find("input:checked").val()
        );
        var selection43 = parseInt(
          $("#group-id_q43").find("input:checked").val()
        );

        document.getElementById("id_q44").value =
          selection14 +
          selection15 +
          selection16 +
          selection17 +
          selection18 +
          selection19 +
          selection20 +
          selection21 +
          selection22 +
          selection23 +
          selection24 +
          selection25 +
          selection26 +
          selection27 +
          selection28 +
          selection29 +
          selection30 +
          selection31 +
          selection32 +
          selection33 +
          selection34 +
          selection35 +
          selection36 +
          selection37 +
          selection38 +
          selection39 +
          selection40 +
          selection41 +
          selection42 +
          selection43;
      });

      $(
        "#group-id_q2, #group-id_q3, #group-id_q4, #group-id_q5, #group-id_q6, #group-id_q7, #group-id_q8, #group-id_q9, #group-id_q10, #group-id_q11, #id_q12"
      ).change();
      $(
        "#group-id_q14, #group-id_q15, #group-id_q16, #group-id_q17, #group-id_q18, #group-id_q19, #group-id_q20, #group-id_q21, #group-id_q22, #group-id_q23, #group-id_q24, #group-id_q25, #group-id_q26, #group-id_q27, #group-id_q28, #group-id_q29, #group-id_q30, #group-id_q31, #group-id_q32, #group-id_q33, #group-id_q34, #group-id_q35, #group-id_q36, #group-id_q37, #group-id_q38, #group-id_q39, #group-id_q40, #group-id_q41, #group-id_q42, #group-id_q43, #id_q44"
      ).change();
    });
  },
  238: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });

      $("#id_q2").change(function (event) {
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "1") {
          $("#group-id_q3").css({ display: "block" });
        } else if (selection === "2") {
          $("#group-id_q4").css({ display: "block" });
        }
      });

      $("#id_q2").change();
    });
  },
  111: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });

      // define event handler for Type droplist
      $("#id_q2").change(function (event) {
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        var selection = $(this).val();
        // Traumatic
        if (selection === "0") {
          $("#group-id_q3").css({ display: "block" });
        }
        // Non-traumatic
        else if (selection === "1") {
          $("#group-id_q4").css({ display: "block" });
        } else {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q2").change();
    });
  },
  226: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });

      // Event handler for Injury type
      $("#id_q2").change(function () {
        // Hide all optional fields
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });

        var selection = $(this).val();
        // If Traumatic
        if (selection === "0") {
          $("#group-id_q3").css({ display: "block" });
        } else {
          $("#group-id_q5").css({ display: "block" });
        }
      });

      // Event handler for Traumatic
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "4") {
          $("#group-id_q4").css({ display: "block" });
        }
      });

      // Event handler for Non-Traumatic
      $("#id_q5").change(function () {
        $("#group-id_q6").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "7") {
          $("#group-id_q6").css({ display: "block" });
        }
      });

      $("#id_q2").change();
    });
  },
  178: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });

      // Event handler for traumatic
      $("#id_q2").change(function () {
        $("#group-id_q3").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q3").css({ display: "block" });
        }
      });

      // Event handler for traumatic
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q2").change();
      $("#id_q4").change();
    });
  },
  108: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });

      // define event handler
      $("#id_q2").change(function (event) {
        $("#group-id_q3").css({ display: "none" });
        var selection = $(this).val();
        // Traumatic
        if (selection === "4") {
          $("#group-id_q3").css({ display: "block" });
        }
      });

      // define event handler
      $("#id_q5").change(function (event) {
        $("#group-id_q6").css({ display: "none" });
        var selection = $(this).val();
        // Traumatic
        if (selection === "11") {
          $("#group-id_q6").css({ display: "block" });
        }
      });

      $("#id_q2").change();
      $("#id_q5").change();
    });
  },
  180: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });

      // Event handler for body region
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        var el = $("#id_q5");
        var selection = $(this).val();
        if (selection === "7") {
          $("#group-id_q4").css({ display: "block" });
        }
        el.empty();
        $("#id_q3 :selected").each(function () {
          if ($(this).val() === "7") {
            $("#group-id_q4").css({ display: "block" });
          }
          el.append(
            $("<option></option>")
              .attr("value", $(this).val())
              .text($(this).text())
          );
        });
      });
      $("#id_q3").change();
    });
  },
  249: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });

      // Event handler for test
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q4").css({ display: "block" });
        }
      });
      $("#id_q3").change();
    });
  },
  254: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q11").css({ display: "none" });

      // Event handler for Global/Analytical
      $("#id_q1").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q6").css({ display: "block" });
        }
        $("#id_q2").change();
      });

      // Event handler for Region
      $("#id_q2").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        global = $("#id_q1").val();
        var selection = $(this).val();
        if (global === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else {
          if (selection === "0") {
            $("#group-id_q6").css({ display: "block" });
          } else if (selection === "1") {
            $("#group-id_q7").css({ display: "block" });
          } else if (selection === "2" || selection === "3") {
            $("#group-id_q8").css({ display: "block" });
          } else if (
            selection === "4" ||
            selection === "5" ||
            selection === "6" ||
            selection === "7" ||
            selection === "8"
          ) {
            $("#group-id_q9").css({ display: "block" });
          } else if (
            selection === "9" ||
            selection === "10" ||
            selection === "11" ||
            selection === "12" ||
            selection === "13"
          ) {
            $("#group-id_q10").css({ display: "block" });
          }
        }
      });

      // Event handler for Group
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q2").change();
    });
  },
  255: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q11").css({ display: "none" });

      // Event handler for Global/Analytical
      $("#id_q1").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q6").css({ display: "block" });
        }
        $("#id_q2").change();
      });

      // Event handler for Region
      $("#id_q2").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        global = $("#id_q1").val();
        var selection = $(this).val();
        if (global === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else {
          if (selection === "0") {
            $("#group-id_q6").css({ display: "block" });
          } else if (selection === "1") {
            $("#group-id_q7").css({ display: "block" });
          } else if (selection === "2" || selection === "3") {
            $("#group-id_q8").css({ display: "block" });
          } else if (
            selection === "4" ||
            selection === "5" ||
            selection === "6" ||
            selection === "7" ||
            selection === "8"
          ) {
            $("#group-id_q9").css({ display: "block" });
          } else if (
            selection === "9" ||
            selection === "10" ||
            selection === "11" ||
            selection === "12" ||
            selection === "13"
          ) {
            $("#group-id_q10").css({ display: "block" });
          }
        }
      });

      // Event handler for Group
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q2").change();
    });
  },
  258: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q11").css({ display: "none" });

      // Event handler for Global/Analytical
      $("#id_q1").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q6").css({ display: "block" });
        }
        $("#id_q2").change();
      });

      // Event handler for Region
      $("#id_q2").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        global = $("#id_q1").val();
        var selection = $(this).val();
        if (global === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else {
          if (selection === "0") {
            $("#group-id_q6").css({ display: "block" });
          } else if (selection === "1") {
            $("#group-id_q7").css({ display: "block" });
          } else if (selection === "2" || selection === "3") {
            $("#group-id_q8").css({ display: "block" });
          } else if (
            selection === "4" ||
            selection === "5" ||
            selection === "6" ||
            selection === "7" ||
            selection === "8"
          ) {
            $("#group-id_q9").css({ display: "block" });
          } else if (
            selection === "9" ||
            selection === "10" ||
            selection === "11" ||
            selection === "12" ||
            selection === "13"
          ) {
            $("#group-id_q10").css({ display: "block" });
          }
        }
      });

      // Event handler for Group
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q2").change();
    });
  },
  50: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q9").css({ display: "none" });
      $("#group-id_q10").css({ display: "none" });
      $("#group-id_q11").css({ display: "none" });
      $("#group-id_q13").css({ display: "none" });
      $("#group-id_q14").css({ display: "none" });
      $("#group-id_q15").css({ display: "none" });

      // Event handler for Global/Analytical
      $("#id_q1").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q6").css({ display: "block" });
        }
        $("#id_q2").change();
      });

      // Event handler for Region
      $("#id_q2").change(function () {
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        $("#group-id_q9").css({ display: "none" });
        $("#group-id_q10").css({ display: "none" });
        $("#group-id_q11").css({ display: "none" });
        global = $("#id_q1").val();
        var selection = $(this).val();
        if (global === "0") {
          $("#group-id_q4").css({ display: "block" });
        } else {
          if (selection === "0") {
            $("#group-id_q6").css({ display: "block" });
          } else if (selection === "1") {
            $("#group-id_q7").css({ display: "block" });
          } else if (selection === "2" || selection === "3") {
            $("#group-id_q8").css({ display: "block" });
          } else if (
            selection === "4" ||
            selection === "5" ||
            selection === "6" ||
            selection === "7" ||
            selection === "8"
          ) {
            $("#group-id_q9").css({ display: "block" });
          } else if (
            selection === "9" ||
            selection === "10" ||
            selection === "11" ||
            selection === "12" ||
            selection === "13"
          ) {
            $("#group-id_q10").css({ display: "block" });
          }
        }
      });

      // Event handler for Grade
      $("#id_q12").change(function () {
        $("#group-id_q13").css({ display: "none" });
        $("#group-id_q14").css({ display: "none" });
        $("#group-id_q15").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "0") {
          $("#group-id_q13").css({ display: "block" });
        } else if (selection === "1") {
          $("#group-id_q14").css({ display: "block" });
        } else if (selection === "2") {
          $("#group-id_q15").css({ display: "block" });
        }
      });

      // Event handler for Group
      $("#id_q4").change(function () {
        $("#group-id_q5").css({ display: "none" });

        var selection = $(this).val();
        if (selection === "5") {
          $("#group-id_q5").css({ display: "block" });
        }
      });
      $("#id_q1").change();
      $("#id_q2").change();
      $("#id_q12").change();
    });
  },
  21: () => {
    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      // define event handler for Bioefeedback system droplist
      $("#id_q6").change(function (event) {
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });
        var selection = $(this).val();
        // If "Hoeh & Yahr", show textbox
        if (selection === "0") {
          $("#group-id_q7").css({ display: "block" });
        } else {
          $("#group-id_q8").css({ display: "block" });
        }
      });
      $("#id_q6").change();
    });
  },
  257: () => {
    $(document).ready(function () {
      if (document.getElementById("id_q1").value === "") {
        document.getElementById("id_q3").selectedIndex = "1";
        document.getElementById("id_q4").selectedIndex = "1";
      }
      if (document.getElementById("id_q5").value === "") {
        document.getElementById("id_q7").selectedIndex = "1";
        document.getElementById("id_q8").selectedIndex = "1";
      }
      if (document.getElementById("id_q9").value === "") {
        document.getElementById("id_q11").selectedIndex = "1";
        document.getElementById("id_q12").selectedIndex = "1";
      }
      if (document.getElementById("id_q13").value === "") {
        document.getElementById("id_q15").selectedIndex = "1";
        document.getElementById("id_q16").selectedIndex = "1";
      }

      // document.getElementById('id_q17').disabled = true;
      // document.getElementById('id_q18').disabled = true;

      $(
        "#id_q2, #id_q3, #id_q4, #id_q6, #id_q7, #id_q8, #id_q10, #id_q11, #id_q12, #id_q14, #id_q15, #id_q16, #id_q17, #id_q18"
      ).change(function (event) {
        var w1 = parseInt(document.getElementById("id_q2").value);
        var w2 = parseInt(document.getElementById("id_q6").value);
        var w3 = parseInt(document.getElementById("id_q10").value);
        var w4 = parseInt(document.getElementById("id_q14").value);

        var b1 = parseInt(document.getElementById("id_q3").value);
        var b2 = parseInt(document.getElementById("id_q7").value);
        var b3 = parseInt(document.getElementById("id_q11").value);
        var b4 = parseInt(document.getElementById("id_q15").value);

        var o1 = parseInt(document.getElementById("id_q4").value);
        var o2 = parseInt(document.getElementById("id_q8").value);
        var o3 = parseInt(document.getElementById("id_q12").value);
        var o4 = parseInt(document.getElementById("id_q16").value);

        document.getElementById("id_q17").value =
          50 +
          (10 * (w1 * b1 + w2 * b2 + w3 * b3 + w4 * b4)) /
            Math.sqrt(
              0.7 *
                (Math.pow(w1, 2) +
                  Math.pow(w2, 2) +
                  Math.pow(w3, 2) +
                  Math.pow(w4, 2))
            ) +
          0.3 * Math.pow(w1 + w2 + w3 + w4, 2);

        document.getElementById("id_q18").value =
          50 +
          (10 * (w1 * o1 + w2 * o2 + w3 * o3 + w4 * o4)) /
            Math.sqrt(
              0.7 *
                (Math.pow(w1, 2) +
                  Math.pow(w2, 2) +
                  Math.pow(w3, 2) +
                  Math.pow(w4, 2))
            ) +
          0.3 * Math.pow(w1 + w2 + w3 + w4, 2);
      });

      $(
        "#id_q2, #id_q3, #id_q4, #id_q6, #id_q7, #id_q8, #id_q10, #id_q11, #id_q12, #id_q14, #id_q15, #id_q16, #id_q17, #id_q18"
      ).change();
    });
  },
  33: () => {
    // "BTB Autograft LCA Accelerated Rehabilitation Protocol
    // Exercises for swelling management (only apear if phase preoperative rehabilitation, phase I or phase II selected)

    $(document).ready(function () {
      // Default - hide all optional fields on document load
      $("#group-id_q3").css({ display: "none" });
      $("#group-id_q4").css({ display: "none" });
      $("#group-id_q5").css({ display: "none" });
      $("#group-id_q6").css({ display: "none" });
      $("#group-id_q7").css({ display: "none" });
      $("#group-id_q8").css({ display: "none" });
      $("#group-id_q12").css({ display: "none" });

      // Event handler for Injury type
      $("#id_q2").change(function () {
        // Hide all optional fields
        $("#group-id_q3").css({ display: "none" });
        $("#group-id_q4").css({ display: "none" });
        $("#group-id_q5").css({ display: "none" });
        $("#group-id_q6").css({ display: "none" });
        $("#group-id_q7").css({ display: "none" });
        $("#group-id_q8").css({ display: "none" });

        var selection = $(this).val();
        // If Traumatic
        if (selection === "0") {
          // $('#group-id_q3').css({'display': 'block'});
          $("#group-id_q5").css({ display: "block" });
        } else {
          $("#group-id_q7").css({ display: "block" });
        }
      });

      // Event handler for Traumatic
      $("#id_q3").change(function () {
        $("#group-id_q4").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "3") {
          $("#group-id_q4").css({ display: "block" });
        }
      });

      // Event handler for Mechanism
      $("#id_q5").change(function () {
        $("#group-id_q6").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "20") {
          $("#group-id_q6").css({ display: "block" });
        }
      });

      // Event handler for Non-Traumatic
      $("#id_q7").change(function () {
        $("#group-id_q8").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "2") {
          $("#group-id_q8").css({ display: "block" });
        }
      });

      // Event handler for Other signs and Symptoms
      $("#id_q11").change(function () {
        $("#group-id_q12").css({ display: "none" });
        var selection = $(this).val();
        if (selection === "7") {
          $("#group-id_q12").css({ display: "block" });
        }
      });

      $("#id_q2, #id_q3, #id_q5, #id_q7, #id_q11").change();
    });
  },
};

export default scripts;
