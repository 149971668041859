import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  GET_FREQ_LIST_SUCCESS,
  GET_FREQ_LIST_FAIL,
} from "../actions/patientEvents";

const initialState = {
  events: {},
  freqList: {},
  extraSymptoms: {},
};

const patientEvents = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload.data.data,
      };
    }
    case GET_EVENTS_FAIL: {
      return {
        ...state,
        events: initialState.events,
      };
    }
    case GET_FREQ_LIST_SUCCESS: {
      return {
        ...state,
        freqList: action.payload.data.data.symptoms,
        extraSymptoms: action.payload.data.data.open_symptoms,
      };
    }
    case GET_FREQ_LIST_FAIL: {
      return {
        ...state,
        freqList: initialState.freqList,
        extraSymptoms: initialState.extraSymptoms,
      };
    }
    default:
      return state;
  }
};

export default patientEvents;
