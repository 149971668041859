import React, { useCallback, useEffect, useRef } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import { history } from "../utils/history";
import { store } from "../redux/store";
import AcceptByCollaborator from "../pages/authentication/partials/acceptByCollaborator";
import AcceptInstituteInvitation from "pages/home/partials/acceptInstituteInvitation";
import ChooseInstitution from "../pages/choose_institution";
import CreateEvaluationTemplates from "../pages/my_area/evaluation_templates/create";
import CreateExercise from "../pages/patient/mkinetikos_exercises/create";
import CreateInstitution from "../pages/create_institution";
import CreateMedicine from "../pages/patient/mkinetikos_medicines/create";
import CreateOrEditProgram from "../pages/patient/mkinetikos_programs/createOrEdit";
import CreatePatient from "../pages/create_patient";
import CreatePrescription from "../pages/patient/mkinetikos_prescriptions/create";
import EditDetail from "../pages/my_area/support/edit";
import EditEvaluationTemplates from "../pages/my_area/evaluation_templates/edit";
import EditExercise from "../pages/patient/mkinetikos_exercises/edit";
import EditInstitution from "../pages/my_area/institutions/edit";
import EditMedicine from "../pages/patient/mkinetikos_medicines/edit";
import EditPrescription from "../pages/patient/mkinetikos_prescriptions/edit";
import EvaluationModule from "../pages/patient/evaluations/eval_module";
import EvaluationTemplates from "../pages/my_area/evaluation_templates";
import Home from "../pages/home/index";
import Institutions from "../pages/my_area/institutions";
import Login from "../pages/authentication/index";
import MKinetikos from "../pages/my_area/mKinetikos";
import MyAreaSidebar from "../components/myAreaSidebar";
import PasswordExpired from "../pages/password_expired";
import PatientEdit from "../pages/patient/edit";
import PatientEvaluationEdit from "../pages/patient/evaluations/edit";
import PatientEvaluations from "../pages/patient/evaluations";
import PatientEvaluationShow from "../pages/patient/evaluations/show";
import PatientEvaluationsReports from "../pages/patient/evaluations_reports";
import PatientHome from "../pages/patient/home";
import PatientMkinetikosExercises from "../pages/patient/mkinetikos_exercises";
import PatientMkinetikosMedicines from "../pages/patient/mkinetikos_medicines";
import PatientMkinetikosMyCareTeam from "../pages/patient/mkinetikos_my_care_team";
import PatientMkinetikosPrescriptions from "../pages/patient/mkinetikos_prescriptions";
import PatientMkinetikosPrograms from "../pages/patient/mkinetikos_programs";
import PatientMKinetikosReport from "../pages/patient/mkinetikos_report";
import PatientMkinetikosSurveys from "../pages/patient/mkinetikos_surveys";
import PatientMkinetikosTests from "../pages/patient/mkinetikos_tests";
import PatientSidebar from "../components/patientSidebar";
import PersonalData from "../pages/my_area/personal_data";
import Privacy from "../pages/my_area/privacy";
import PrivacyPolicy from "../pages/my_area/privacy/privacy_policy";
import PublicPrivacyPolicy from "pages/publicPrivacyPolicy";
import Report from "../pages/patient/report";
import RiskAlert from "../pages/my_area/risk_alert";
import Security from "../pages/my_area/security";
import ShowExercise from "../pages/patient/mkinetikos_exercises/show";
import ShowMedicine from "../pages/patient/mkinetikos_medicines/show";
import ShowPrescription from "../pages/patient/mkinetikos_prescriptions/show";
import SupportDetail from "../pages/my_area/support/detail";
import SupportOverview from "../pages/my_area/support";
import TermsAndConditions from "../pages/my_area/privacy/terms_and_conditions";
import TermsAndConditionsUpdate from "../pages/termsAndConditionsUpdate";
import ViewCollaborator from "../pages/my_area/institutions/view_collaborator";
import ViewInstitutionDetails from "../pages/my_area/institutions/view_details";
import PageNotFound from "../pages/page_not_found";
import { setRedirectTo } from "../redux/actions/redirect";
import useEventSource from "hooks/useEventSource";
import { setNotificationsCount } from "redux/actions/notifications";
import { getNewNotificationCount } from "redux/actions/notifications";
import { clearRedirectTo } from "redux/actions/redirect";

import AssetLinksHandler from "components/assetLinksHandler";
import AppleAppSiteAssociationContent from "components/appleAppSiteAssociationHandler";

const AuthRoute = ({ component: Component, requiresAuth, computedMatch, ...rest }) => {
  const dispatch = useDispatch();
  const firstVisit = useRef(true);

  const { isAuthenticated } = store.getState().user;

  const onOpenEventSource = useCallback(() => dispatch(getNewNotificationCount()), [dispatch]);
  const onEventSourceMessage = useCallback(
    (data) => {
      if (isAuthenticated) dispatch(setNotificationsCount(data));
    },
    [dispatch, isAuthenticated]
  );

  useEventSource(
    process.env.REACT_APP_BACKEND_URL + `/stream/cdss/notifications/counter/`,
    {
      onOpen: onOpenEventSource,
      onMessage: onEventSourceMessage,
      withCredentials: true,
      name: "notifications",
    },
    []
  );

  useEffect(() => {
    if (!firstVisit.current && !isAuthenticated) {
      dispatch(clearRedirectTo());
      localStorage.removeItem("persist:root");
    }
    firstVisit.current = false;
  }, [isAuthenticated]);

  useEffect(() => {
    // If the user isn't authenticated, but the path requires it, set the redirection variable
    if (!isAuthenticated && requiresAuth) {
      store.dispatch(setRedirectTo(computedMatch.url));
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        (requiresAuth && isAuthenticated === true) ||
        (!requiresAuth && isAuthenticated === false) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: isAuthenticated ? "/home" : "/login",
            }}
          />
        )
      }
    />
  );
};

const MyAreaRouter = () => (
  <MyAreaSidebar currentScreen={window.location.pathname}>
    <Route exact path="/my_area/">
      <Redirect to="/my_area/personal_data" />
    </Route>
    <Route path="/my_area/privacy" exact component={Privacy} />
    <Route path="/my_area/privacy/terms_and_conditions" exact component={TermsAndConditions} />
    <Route path="/my_area/privacy/privacy_policy" exact component={PrivacyPolicy} />
    <Route path="/my_area/institutions" exact component={Institutions} />
    <Route path="/my_area/institutions/:institution_id" exact component={ViewInstitutionDetails} />
    <Route path="/my_area/institutions/:institution_id/edit" exact component={EditInstitution} />
    <Route
      path="/my_area/institutions/:institution_id/users/:user_id"
      exact
      component={ViewCollaborator}
    />
    <Route path="/my_area/personal_data" exact component={PersonalData} />
    <Route path="/my_area/security" exact component={Security} />
    <Route path="/my_area/evaluation_templates" exact component={EvaluationTemplates} />
    <Route
      path="/my_area/evaluation_templates/create"
      exact
      component={CreateEvaluationTemplates}
    />

    <Route
      path="/my_area/evaluation_templates/:id/edit"
      exact
      component={EditEvaluationTemplates}
    />
    <Route path="/my_area/risk_alert" exact component={RiskAlert} />
    <Route path="/my_area/mKinetikos" exact component={MKinetikos} />
    <Route path="/my_area/support" exact component={SupportOverview} />
    <Route
      path="/my_area/support/ticket/:support_ticket_id/detail"
      exact
      component={SupportDetail}
    />
    <Route path="/my_area/support/ticket/:support_ticket_id/edit" exact component={EditDetail} />
  </MyAreaSidebar>
);

const PatientRouter = (props) => (
  <PatientSidebar {...props} currentScreen={window.location.pathname}>
    <Switch>
      <Route exact path="/patient">
        <Redirect to="/home" />
      </Route>
      <Route path="/patient/:patient_id" exact component={PatientHome} />
      <Route path="/patient/:patient_id/edit" exact component={PatientEdit} />
      <Route exact path="/patient/:patient_id/evaluations" component={PatientEvaluations} />
      <Route
        path="/patient/:patient_id/evaluations/:evaluation_id"
        exact
        component={(props) => {
          return (
            <div className="col">
              <h1>
                PATIENT EVALUATION - {props.match.params.evaluation_id} - PAGE - 505 Not Implemented
              </h1>
            </div>
          );
        }}
      />
      <Route
        exact
        path="/patient/:patient_id/evaluations/:evaluation_id/report/"
        component={Report}
      />
      <Route
        path="/patient/:patient_id/evaluations/:evaluation_id/edit"
        exact
        component={PatientEvaluationEdit}
      />
      <Route
        path="/patient/:patient_id/evaluations/:evaluation_id/show"
        exact
        component={PatientEvaluationShow}
      />
      <Route
        path="/patient/:patient_id/evaluations/:evaluation_id/modules/:eval_module_id"
        exact
        component={EvaluationModule}
      />
      <Route
        exact
        path="/patient/:patient_id/evaluations/:evaluation_id/report"
        component={(props) => {
          return (
            <div className="col">
              <h1>👨🏼‍💻 👨🏼‍💻 Man at Work 👨🏼‍💻 👨🏼‍💻</h1>
            </div>
          );
        }}
      />
      <Route exact path="/patient/:patient_id/reports" component={PatientEvaluationsReports} />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_programs"
        component={PatientMkinetikosPrograms}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_programs/create"
        component={CreateOrEditProgram}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_programs/:program_id"
        component={CreateOrEditProgram}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_tests"
        component={PatientMkinetikosTests}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_tests/:test_id"
        component={PatientMkinetikosTests}
      />
      <Route
        path="/patient/:patient_id/mkinetikos_tests/activity/:activity_id"
        component={PatientMkinetikosTests}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_surveys"
        component={PatientMkinetikosSurveys}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_surveys/:survey_id"
        component={PatientMkinetikosSurveys}
      />
      <Route
        path="/patient/:patient_id/mkinetikos_surveys/activity/:activity_id"
        component={PatientMkinetikosSurveys}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_exercises/create"
        component={CreateExercise}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_exercises/:exercise_id/show"
        component={ShowExercise}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_exercises/:exercise_id/edit"
        component={EditExercise}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_exercises"
        component={PatientMkinetikosExercises}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_prescriptions"
        component={PatientMkinetikosPrescriptions}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_prescriptions/create"
        component={CreatePrescription}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_medicines"
        component={PatientMkinetikosMedicines}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_medicines/create"
        component={CreateMedicine}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/medicines/:medicine_id/edit"
        component={EditMedicine}
      />
      <Route
        exact
        // path="/patient/:patient_id/mkinetikos_medicines/:medicine_id/show"
        path="/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/medicines/:medicine_id/show"
        component={ShowMedicine}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/edit"
        component={EditPrescription}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/show"
        component={ShowPrescription}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_my_care_team"
        component={PatientMkinetikosMyCareTeam}
      />
      <Route
        exact
        path="/patient/:patient_id/mkinetikos_report"
        component={PatientMKinetikosReport}
      />
    </Switch>
  </PatientSidebar>
);

const Routing = () => (
  <Router history={history}>
    <Switch>
      <AuthRoute exact path="/" requiresAuth={true} component={Home} />
      <Route exact path="/.well-known/assetlinks.json" component={AssetLinksHandler} />
      <Route
        exact
        path="/.well-known/apple-app-site-association"
        component={AppleAppSiteAssociationContent}
      />
      <Route exact path="/activate_account/:activate_account_token" component={Login} />
      <Route exact path="/logout" component={Login} />
      <AuthRoute
        exact
        path="/accept_invite/:invite_token"
        component={AcceptByCollaborator}
        requiresAuth={true}
      />
      <AuthRoute
        path="/home/:invitation_token?/:institution_new_notifications?"
        requiresAuth={true}
        component={Home}
      />
      <AuthRoute path="/create_patient" exact requiresAuth={true} component={CreatePatient} />
      <AuthRoute path="/login" exact requiresAuth={false} component={Login} />
      <AuthRoute path="/signup" exact requiresAuth={false} component={Login} />

      <AuthRoute
        path="/forgot_password/:uidb64/:token"
        exact
        requiresAuth={false}
        component={Login}
      />
      <AuthRoute path="/setup_multifactor_auth" exact requiresAuth={true} component={Login} />
      <AuthRoute path="/multifactor_auth/:method/" exact requiresAuth={true} component={Login} />
      <AuthRoute path="/session_expired" exact requiresAuth={false} component={Login} />
      <AuthRoute path="/password_expired" exact requiresAuth={true} component={Login} />
      <AuthRoute path="/my_area" requiresAuth={true} component={MyAreaRouter} />
      <AuthRoute
        path="/choose_institution"
        exact
        requiresAuth={true}
        component={ChooseInstitution}
      />
      <AuthRoute
        path="/create_institution"
        exact
        requiresAuth={true}
        component={CreateInstitution}
      />
      <AuthRoute
        exact
        path={[
          "/patient",
          "/patient/:patient_id",
          "/patient/:patient_id/edit",
          "/patient/:patient_id/evaluations",
          "/patient/:patient_id/evaluations/:evaluation_id",
          "/patient/:patient_id/evaluations/:evaluation_id/report/",
          "/patient/:patient_id/evaluations/:evaluation_id/edit",
          "/patient/:patient_id/evaluations/:evaluation_id/show",

          "/patient/:patient_id/evaluations/:evaluation_id/modules/:eval_module_id",
          "/patient/:patient_id/evaluations/:evaluation_id/report",
          "/patient/:patient_id/reports",
          "/patient/:patient_id/mkinetikos_programs",
          "/patient/:patient_id/mkinetikos_programs/create",
          "/patient/:patient_id/mkinetikos_programs/:program_id",
          "/patient/:patient_id/mkinetikos_tests",
          "/patient/:patient_id/mkinetikos_tests/:test_id",
          "/patient/:patient_id/mkinetikos_tests/activity/:activity_id",
          "/patient/:patient_id/mkinetikos_surveys",
          "/patient/:patient_id/mkinetikos_surveys/:survey_id",
          "/patient/:patient_id/mkinetikos_surveys/activity/:activity_id",
          "/patient/:patient_id/mkinetikos_exercises",
          "/patient/:patient_id/mkinetikos_exercises/:exercise_id/show",
          "/patient/:patient_id/mkinetikos_exercises/:exercise_id/edit",
          "/patient/:patient_id/mkinetikos_exercises/create",
          "/patient/:patient_id/mkinetikos_medicines",
          "/patient/:patient_id/mkinetikos_medicines/create",
          "/patient/:patient_id/mkinetikos_prescriptions",
          "/patient/:patient_id/mkinetikos_prescriptions/create",
          "/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/edit",
          "/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/show",
          "/patient/:patient_id/mkinetikos_my_care_team",
          "/patient/:patient_id/mkinetikos_report",
          "/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/medicines/:medicine_id/edit",
          "/patient/:patient_id/mkinetikos_prescriptions/:prescription_id/medicines/:medicine_id/show",
        ]}
        requiresAuth={true}
        component={PatientRouter}
      />
      <AuthRoute path="/password_check" exact requiresAuth={true} component={PasswordExpired} />
      <AuthRoute
        path="/update_terms_and_conditions"
        exact
        requiresAuth={true}
        component={TermsAndConditionsUpdate}
      />
      <AuthRoute path="/terms_and_conditions" exact component={TermsAndConditionsUpdate} />
      <AuthRoute path="my_area/support" exact requiresAuth={true} component={SupportOverview} />
      <AuthRoute
        path="/pending_invitation"
        exact
        requiresAuth={true}
        component={AcceptInstituteInvitation}
      />
      <AuthRoute path="/400" exact requiresAuth={false} component={() => <h1>404 Not found</h1>} />
      <Route path="/privacy-policy" exact component={PublicPrivacyPolicy} />
      {/* <AuthRoute requiresAuth={true} component={PageNotFound} /> */}
      <Route path="/404" component={PageNotFound} />
      <Redirect from="*" to="/404" />
    </Switch>
  </Router>
);

export default Routing;
