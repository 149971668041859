/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import { useState, useEffect, memo } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
/* import Spinner from "../spinner"; */
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import { usePdf } from "hooks/usePdf";
import ResultPdfTemplate from "pdfTemplates/testAndSurveysResult/resultPdfTemplate";
import DownloadPdfButton from "components/downloadPdfButton";
import MessageModal from "components/messageModal";

//* ************************************** REDUX *****************************************
import { getPatientSurveyDetails } from "../../../../redux/actions/patientsPrograms";

//* ************************************** GLOBALS ***************************************
import { parseDateFromDatetime } from "../../../../utils/dataAndTime";

const SurveyResultsModule = ({ history }) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");
  const { survey_id } = useParams("survey_id");

  const { t, i18n } = useTranslation();

  const survey = useSelector((state) => state.patientSurveyDetails);
  const patientInfo = useSelector((state) => state.patientHome);
  const patientEmail = useSelector((state) => state.patientProfile.email);
  const fullName = useSelector((state) => state.user.personal_data.full_name);

  const [hasModalOpen, setHasModalOpen] = useState(false);

  const patientInfoForPdf = {
    name: patientInfo.name,
    mkinetikos_photo: patientInfo.mkinetikos_photo,
    email: patientEmail,
  };

  const pdfFileName = `${patientInfoForPdf.name} (${survey.name}) - ${parseDateFromDatetime(
    survey.submission_date
  )}`;

  const { isPdfLoading, pdfDownloadUrl, pdfUpdateInstance } = usePdf(
    <ResultPdfTemplate
      patientInfo={patientInfoForPdf}
      data={survey}
      option={"survey"}
      downloadedBy={{
        fullName: fullName,
        date: new Date(),
      }}
    />
  );

  useEffect(() => {
    pdfUpdateInstance();
  }, [patientInfo, survey]);

  useEffect(() => {
    dispatch(getPatientSurveyDetails(patient_id, survey_id));
  }, []);

  const __renderSurveyAnswer = (answers) => {
    return (
      <div className="col regular-text black-color">
        {answers && answers?.length > 0
          ? Array.isArray(answers)
            ? answers?.map((answer, index) => {
                return index < answers.length - 1 ? answer + ", " : answer;
              })
            : answers
          : "-----"}
      </div>
    );
  };

  const __renderHelpModal = () => {
    return (
      hasModalOpen && (
        <MessageModal
          hasCloseButton={true}
          handleClose={() => setHasModalOpen(false)}
          headerText={t("patient.programs.help_modal_header")}
          body={<div dangerouslySetInnerHTML={{ __html: survey.score_interpretation }}></div>}
          footerButtons={[
            [
              <div className="input-image-wrapper m-0">
                <input
                  onClick={() => setHasModalOpen(false)}
                  type="submit"
                  className={`button small-button tertiary-button`}
                  value={t("buttons.close")}
                />
              </div>,
            ],
          ]}
        />
      )
    );
  };

  //* ************************************** HANDLERS **************************************

  return (
    <>
      <div className="surveys-module-wrapper">
        <div className="surveys-module-container">
          <div className="survey-info row-space-between stretch-align">
            <div className="flex-d-one">
              <div className="row list-label surveys-list-labels-wrapper">
                <div className="col-5">
                  <div>{t("patient.programs.survey_name")}</div>
                </div>
                <div className="col-3">
                  <div>{t("patient.programs.submission_date")}</div>
                </div>
                <div className="col-2">
                  <div>{t("patient.programs.results")}</div>
                </div>
                <div className="col">
                  <div>{t("patient.programs.raw_score")}</div>
                </div>
                <div className="col"></div>
              </div>
              <div className="row survey-info-wrapper">
                <div className="col-5 regular-text black-color align-items-center text-truncate">
                  {survey.name}
                </div>
                <div className="col-3 tags-wrapper regular-text black-color">
                  {parseDateFromDatetime(survey.submission_date)}
                </div>
                <div className="col-2 regular-text black-color">
                  {survey.result !== null && survey.result !== undefined
                    ? survey.result.toFixed(2)
                    : "-----"}
                </div>
                <div className="col regular-text black-color">
                  {survey.absolute_value !== null && survey.absolute_value !== undefined
                    ? survey.absolute_value
                    : "-----"}
                </div>
                {survey?.score_interpretation && (
                  <div className="col regular-text black-color">
                    <Icon
                      icon={icons["help-icon-dark"]}
                      className={"cursor-pointer"}
                      onClick={() => setHasModalOpen(true)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="col">
                <DownloadPdfButton
                  pdfFileName={pdfFileName}
                  pdfDownloadUrl={pdfDownloadUrl}
                  isPdfLoading={isPdfLoading}
                />
              </div>
            </div>
          </div>
          <div className="row-space-between">
            <span className="small-title">{t("patient.programs.results")}</span>
          </div>
          <div className="surveys-list">
            <div className="row list-label surveys-list-labels-wrapper">
              <div className="col-7">
                <div>{t("patient.programs.question")}</div>
              </div>
              <div className="col">
                <div>{t("patient.programs.answer")}</div>
              </div>
            </div>
            {survey.result_questions.map((question, index) => (
              <div key={question.id + index.toString()} className="row survey-info-wrapper">
                <div className="col-7 d-flex regular-text black-color align-items-start overflow-visible">
                  {question.question}
                </div>
                {__renderSurveyAnswer(question?.answer)}
              </div>
            ))}
          </div>
        </div>
      </div>
      {__renderHelpModal()}
    </>
  );
};

export default memo(SurveyResultsModule);
