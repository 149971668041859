export const GET_INSTITUTIONS = "GET_INSTITUTIONS";
export const GET_INSTITUTIONS_SUCCESS = "GET_INSTITUTIONS_SUCCESS";
export const GET_INSTITUTIONS_FAIL = "GET_INSTITUTIONS_FAIL";

export const GET_INSTITUTION = "GET_INSTITUTION";
export const GET_INSTITUTION_SUCCESS = "GET_INSTITUTION_SUCCESS";
export const GET_INSTITUTION_FAIL = "GET_INSTITUTION_FAIL";

export const CREATE_INSTITUTION = "CREATE_INSTITUTION";

export const SET_CURRENT_INSTITUTION = "SET_CURRENT_INSTITUTION";
export const SET_CURRENT_INSTITUTION_SUCCESS =
  "SET_CURRENT_INSTITUTION_SUCCESS";

export const GET_CURRENT_INSTITUTION = "GET_CURRENT_INSTITUTION";
export const GET_CURRENT_INSTITUTION_SUCCESS =
  "GET_CURRENT_INSTITUTION_SUCCESS";
export const GET_CURRENT_INSTITUTION_FAIL = "GET_CURRENT_INSTITUTION_FAIL";

export const SET_DEFAULT_INSTITUTION = "SET_DEFAULT_INSTITUTION";
export const SET_DEFAULT_INSTITUTION_SUCCESS =
  "SET_DEFAULT_INSTITUTION_SUCCESS";

export const REMOVE_DEFAULT_INSTITUTION = "REMOVE_DEFAULT_INSTITUTION";
export const REMOVE_DEFAULT_INSTITUTION_SUCCESS =
  "REMOVE_DEFAULT_INSTITUTION_SUCCESS";

export const GET_INSTITUTION_COLLABORATORS = "GET_INSTITUTION_COLLABORATORS";
export const GET_INSTITUTION_COLLABORATORS_SUCCESS =
  "GET_INSTITUTION_COLLABORATORS_SUCCESS";
export const GET_INSTITUTION_COLLABORATORS_FAIL =
  "GET_INSTITUTION_COLLABORATORS_FAIL";

export const GET_INSTITUTION_COLLABORATOR = "GET_INSTITUTION_COLLABORATOR";

export const SET_INSTITUTION_COLLABORATOR = "SET_INSTITUTION_COLLABORATOR";
export const SET_INSTITUTION_COLLABORATORS_SUCCESS =
  "SET_INSTITUTION_COLLABORATOR_SUCCESS";
export const SET_INSTITUTION_COLLABORATORS_FAIL =
  "SET_INSTITUTION_COLLABORATOR_FAIL";

export const UPDATE_INSTITUTION_COLLABORATOR =
  "UPDATE_INSTITUTION_COLLABORATOR";
export const UPDATE_INSTITUTION_COLLABORATOR_SUCCESS =
  "UPDATE_INSTITUTION_COLLABORATOR_SUCCESS";
export const UPDATE_INSTITUTION_COLLABORATOR_FAIL =
  "UPDATE_INSTITUTION_COLLABORATOR_FAIL";

export const DELETE_INSTITUTION_COLLABORATOR =
  "DELETE_INSTITUTION_COLLABORATOR";
export const DELETE_INSTITUTION_COLLABORATOR_SUCCESS =
  "DELETE_INSTITUTION_COLLABORATOR_SUCCESS";
export const DELETE_INSTITUTION_COLLABORATOR_FAIL =
  "DELETE_INSTITUTION_COLLABORATORS_FAIL";

export const GET_INSTITUTION_INFO = "GET_INSTITUTION_INFO";
export const GET_INSTITUTION_INFO_SUCCESS = "GET_INSTITUTION_INFO_SUCCESS";
export const GET_INSTITUTION_INFO_FAIL = "GET_INSTITUTION_INFO_FAIL";

export const UPDATE_INSTITUTION_INFO = "UPDATE_INSTITUTION_INFO";
export const UPDATE_INSTITUTION_INFO_SUCCESS =
  "UPDATE_INSTITUTION_INFO_SUCCESS";

export const GET_INSTITUTION_PREFERENCES = "GET_INSTITUTION_PREFERENCES";
export const GET_INSTITUTION_PREFERENCES_SUCCESS =
  "GET_INSTITUTION_PREFERENCES_SUCCESS";
export const GET_INSTITUTION_PREFERENCES_FAIL =
  "GET_INSTITUTION_PREFERENCES_FAIL";

export const UPDATE_INSTITUTION_PREFERENCES = "UPDATE_INSTITUTION_PREFERENCES";
export const UPDATE_INSTITUTION_PREFERENCES_SUCCESS =
  "UPDATE_INSTITUTION_PREFERENCES_SUCCESS";
export const UPDATE_INSTITUTION_PREFERENCES_FAIL =
  "UPDATE_INSTITUTION_PREFERENCES_FAIL";

export const INVITE_COLLABORATOR = "INVITE_COLLABORATOR";
export const INVITE_COLLABORATOR_SUCCESS = "INVITE_COLLABORATOR_SUCCESS";
export const INVITE_COLLABORATOR_FAIL = "INVITE_COLLABORATOR_FAIL";

export const ACCEPT_BY_COLLABORATOR = "ACCEPT_BY_COLLABORATOR";
export const ACCEPT_BY_COLLABORATOR_SUCCESS = "ACCEPT_BY_COLLABORATOR_SUCCESS";
export const ACCEPT_BY_COLLABORATOR_FAIL = "ACCEPT_BY_COLLABORATOR_FAIL";

export const REMOVE_MYSELF_INSTITUTION = "REMOVE_MYSELF_INSTITUTION";
export const REMOVE_MYSELF_INSTITUTION_SUCCESS =
  "REMOVE_MYSELF_INSTITUTION_SUCCESS";

export const CANCEL_INVITATION = "CANCEL_INVITATION";
export const CANCEL_INVITATION_SUCCESS = "CANCEL_INVITATION_SUCCESS";

export const PENDING_INSTITUTIONS = "PENDING_INSTITUTIONS";

export const createInstitution = (name, email) => ({
  type: CREATE_INSTITUTION,
  payload: {
    request: {
      method: "post",
      url: "/cdss/institutions/create/",
      data: {
        name,
        email,
      },
    },
  },
});

export const getCurrentInstitution = () => ({
  type: GET_CURRENT_INSTITUTION,
  payload: {
    request: {
      method: "get",
      url: "/cdss/institutions/current/",
    },
  },
});

export const setCurrentInstitution = (institution_id) => ({
  type: SET_CURRENT_INSTITUTION,
  payload: {
    request: {
      method: "put",
      url: "/cdss/institutions/current/",
      data: { institution_id },
    },
  },
  institution_id: institution_id,
});

export const setDefaultInstitution = (institution_id) => ({
  type: SET_DEFAULT_INSTITUTION,
  payload: {
    request: {
      method: "put",
      url: "/cdss/institutions/default/",
      data: { institution_id },
    },
  },
  institution_id: institution_id,
});

export const removeDefaultInstitution = (institution_id) => ({
  type: REMOVE_DEFAULT_INSTITUTION,
  payload: {
    request: {
      method: "delete",
      url: "/cdss/institutions/default/",
      data: { institution_id },
    },
  },
  institution_id: institution_id,
});

export const getInstitutions = () => ({
  type: GET_INSTITUTIONS,
  payload: {
    request: {
      method: "get",
      url: "/cdss/institutions/",
    },
  },
});

export const getInstitution = (institution_id) => ({
  type: GET_INSTITUTION,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}`,
    },
  },
});

export const getPendingInstitutions = () => ({
  type: PENDING_INSTITUTIONS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/pending_invites/`,
    },
  },
});

export const getCollaborators = (
  institution_id,
  {
    page = 1,
    search = undefined,
    order_by = "status",
    items_per_page = 10,
  } = {}
) => ({
  type: GET_INSTITUTION_COLLABORATORS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/users`,
      params: { page, search, order_by, items_per_page },
    },
  },
});

export const addCollaborator = (institution_id, data) => ({
  type: SET_INSTITUTION_COLLABORATOR,
  payload: {
    request: {
      method: "post",
      url: `/cdss/institutions/${institution_id}/manage/collaborators/`,
      data: data,
    },
  },
});

export const cancelInvitation = (invitation_token) => ({
  type: CANCEL_INVITATION,
  payload: {
    request: {
      method: "post",
      url: `/cdss/cancel_invite/${invitation_token}/`,
    },
  },
});

export const updateCollaborator = (
  institution_id,
  collaborator_id,
  user_type_id
) => ({
  type: UPDATE_INSTITUTION_COLLABORATOR,
  payload: {
    request: {
      method: "put",
      url: `/cdss/institutions/${institution_id}/manage/collaborators/${collaborator_id}/`,
      data: { user_type_id },
    },
  },
  collaborator_id: collaborator_id,
});

export const removeCollaborator = (institution_id, collaborator_id) => ({
  type: DELETE_INSTITUTION_COLLABORATOR,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/institutions/${institution_id}/manage/collaborators/${collaborator_id}`,
    },
  },
  collaborator_id: collaborator_id,
});

export const getInstitutionUserInfo = (institution_id, user_id) => ({
  type: GET_INSTITUTION_COLLABORATOR,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/users/${user_id}`,
    },
  },
});

export const getInstitutionInfo = (institution_id) => ({
  type: GET_INSTITUTION_INFO,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/manage/metainfo`,
    },
  },
});

export const updateInstitutionInfo = (institution_id, data) => ({
  type: UPDATE_INSTITUTION_INFO,
  payload: {
    request: {
      method: "put",
      url: `/cdss/institutions/${institution_id}/manage/metainfo/`,
      data: data,
    },
  },
});

export const getInstitutionPreferences = (institution_id) => ({
  type: GET_INSTITUTION_PREFERENCES,
  payload: {
    request: {
      method: "get",
      url: `/cdss/institutions/${institution_id}/manage/preferences`,
    },
  },
});

export const updateInstitutionPreferences = (institution_id, data) => ({
  type: UPDATE_INSTITUTION_PREFERENCES,
  payload: {
    request: {
      method: "put",
      url: `/cdss/institutions/${institution_id}/manage/preferences/`,
      data: data,
    },
  },
});

export const acceptByCollaborator = (invite_token) => ({
  type: ACCEPT_BY_COLLABORATOR,
  payload: {
    request: {
      method: "post",
      url: `/cdss/accept_invite/${invite_token}/`,
    },
  },
});

export const removeMyselfInstitution = (institution_id) => ({
  type: REMOVE_MYSELF_INSTITUTION,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/institutions/${institution_id}/`,
    },
  },
  institution_id,
});
