/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* ************************************** REDUX *****************************************
import {
  getEvaluationModuleForm,
  sendEvaluationModuleForm,
} from "../../../../redux/actions/patientEvaluationModule";
//* *********************************** OUR COMPONENTS ***********************************
import EvaluationModuleController from "./eval_module_controller";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import NavBar from "../../../../components/navbar";
import Breadcrumbs from "../../../../components/breadcrumbs";

const EvaluationModule = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const patientEvaluationModule = useSelector(
    (state) => state.patientEvaluationModule
  );

  const history = useHistory();

  const { patient_id } = useParams("patient_id");
  const { evaluation_id } = useParams("evaluation_id");
  const { eval_module_id } = useParams("eval_module_id");

  const [isLoadingModuleForm, setIsLoadingModuleForm] = useState(true);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(getEvaluationModuleForm(evaluation_id, eval_module_id)).then(
      (res) => {
        console.log("getEvaluationModuleForm:", res);
        setIsLoadingModuleForm(false);
      },
      (err) => {
        console.log("getEvaluationModuleForm Error:", err);
        setIsLoadingModuleForm(false);
      }
    );
  }, []);

  const sendFormAnswers = (formData) => {
    dispatch(
      sendEvaluationModuleForm(evaluation_id, eval_module_id, formData)
    ).then(
      (res) => {
        console.log("sendEvaluationModuleForm:", res);
        setFormErrors({});
        setIsSendingForm(false);
        history.push(
          `/patient/${patient_id}/evaluations/${evaluation_id}/edit`
        );
      },
      (err) => {
        console.log("sendEvaluationModuleForm ERROR:", err);
        setFormErrors(err.error.response?.data?.form_errors ?? {});
        setIsSendingForm(false);
      }
    );
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.push(
              `/patient/${patient_id}/evaluations/${evaluation_id}/edit`
            );
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.evaluations")}</h4>
      </div>
      <EvaluationModuleController
        isLoading={isLoadingModuleForm}
        isSendingForm={isSendingForm}
        setIsSendingForm={setIsSendingForm}
        evaluationModule={patientEvaluationModule}
        sendFormAnswers={sendFormAnswers}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </div>
  );
};

export default React.memo(EvaluationModule);
