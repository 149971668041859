import {
  CLEAR_SEARCH_FILTER,
  CREATE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTER,
} from "../actions/searchFilter";

const initialState = {
  filters: {
    default: {
      q: "",
      page: 1,
      filterType: "all",
      orderBy: null,
      itemsPerPage: 10,
    },
  },
};

const searchFilter = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: initialState.filters.default,
        },
      };
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filterParams,
        },
      };
    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: initialState.filters.default,
        },
      };
    default:
      return state;
  }
};

export default searchFilter;
