import { StyleSheet, Font } from "@react-pdf/renderer";

const Styles = StyleSheet.create({
  page: {
    padding: 24,
    paddingBottom: 80,
    backgroundColor: "#fdfdfe",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 28,
  },
  userInfoContainer: {},
  profileImageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 12,
  },
  profileImage: {
    width: 39,
    height: 39,
    borderRadius: 19.5,
    backgroundColor: "#EFF3F6",
    justifyContent: "center",
    alignItems: "center",
    color: "#255986",
  },
  patinetName: {
    fontSize: 12,
    color: "#255986",
    marginBottom: 4,
  },
  patinetEmail: {
    fontSize: 11,
    color: "#4C657A",
  },
  logo: {
    width: 100,
    height: 36,
  },
  evaluationSection: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    maxWidth: "25%",
  },
  separator: {
    borderBottomColor: "#EFF3F6",
    borderBottomWidth: 1,
    marginTop: 12,
    marginBottom: 12,
  },
  boldText: {
    fontSize: 11,
    fontWeight: "700",
    color: "#071E31",
    marginTop: 8,
    // marginBottom: 8,
    fontFamily: "Helvetica-Bold",
  },
  normalText: {
    fontSize: 12,
    fontWeight: "400",
    color: "#071E31",
    fontFamily: "Helvetica",
    marginTop: 8,
  },
  sectionTitle: {
    marginTop: 24,
    marginBottom: 24,
  },
  questionAnswerSection: {
    marginTop: 8,
  },
  questionAnswer: {},
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: 24,
  },
  footerText: {
    fontSize: 10,
    color: "#4C657A",
  },
  downloadByContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: 13,
    left: 0,
    right: 0,
    paddingHorizontal: 24,
  },
  downloadByText: {
    fontSize: 8,
    color: "#4C657A",
  },
});

export default Styles;
