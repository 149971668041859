export const generateAndroidAssetLinks = () => {
  const sha256Fingerprints = [
    "76:38:6E:66:CE:9E:F3:68:45:63:7D:A1:96:62:FC:F4:3C:91:84:38:F0:3C:A3:A4:F0:BF:43:B1:07:D7:35:66",
  ];
  const applicationId = "com.kinetikos.pdtraker";

  const assetlinks = new Array();
  assetlinks.push("[");
  sha256Fingerprints.forEach((sha256Fingerprint, index) => {
    if (index > 0) {
      assetlinks.push(",");
    }
    assetlinks.push(`{
        "relation": ["delegate_permission/common.handle_all_urls"],
        "target" : { "namespace": "android_app", "package_name": "${applicationId}",
                     "sha256_cert_fingerprints": ["${sha256Fingerprint}"] }
      }\n`);
  });
  assetlinks.push("]");
  return assetlinks.join("");
};

export const generateAppleAppSiteAssociation = () => {
  return JSON.stringify(
    {
      applinks: {
        apps: [],
        details: [
          {
            appIDs: "L54X9FNNNU.com.kinetikoshealth.mkinetikos.app",
            paths: ["*", "/"],
          },
        ],
      },
    },
    null,
    2
  );
};
