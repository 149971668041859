import React from "react";
import RadioButton from "../radioButton";

const LicenseCard = ({
  index,
  headerText = "",
  minValue = "",
  maxValue = "",
  activeIndex = "",
  checkedRadioButtonIndex = "",
  radioButtonOption = "",
  setCheckedRadioButtonIndex = "",
  isRadioButton = false,
}) => {
  const newRadioBtnOption = { text: radioButtonOption.text, disabled: true };

  return (
    <div className={`card-container`} key={index}>
      <div
        className={`license-card ${
          index === activeIndex ? "active-license" : ""
        } ${minValue === 0 && "disable"}`}
      >
        <span>{headerText}</span>
        <hr className="hr-border" />
        <span className="license-limit">
          <span className="available-license">{minValue}</span>/ {maxValue}
        </span>
      </div>
      {isRadioButton && (
        <div className="radio-container">
          <RadioButton
            checkedIndex={checkedRadioButtonIndex}
            options={minValue === 0 ? newRadioBtnOption : radioButtonOption}
            getSelectedIndex={setCheckedRadioButtonIndex}
            index={index}
            defaultChecked={false}
          />
        </div>
      )}
    </div>
  );
};

export default LicenseCard;
