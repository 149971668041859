//* ******************************** REACT NATIVE IMPORTS ********************************
import { useCallback, useEffect, useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import ReactTooltip from "react-tooltip";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../messageModal";
import RenewLicenceModal from "../renewLicenceModal";
import NewSupportTicketModal from "components/newSupportTicketModal";
import SidebarHamburger from "components/sidebarHamburger";
import uuid from "utils/uuid";

//* ************************************** GLOBALS ***************************************
import {
  getPatientHome,
  getPatientPersonalData,
  getPatientContactInfo,
  getPatientClinicalData,
  getPatientIdentification,
  createPatientMkinetikosConnection,
  deletePatientMkinetikosConnection,
  deletePatient,
} from "../../redux/actions/patients";
import { followPatients, unfollowPatients } from "../../redux/actions/riskAlert";
import { requestConnections } from "redux/actions/mKinetikos";
import { parseDate } from "../../utils/dataAndTime";
import { getNationality } from "../../utils/countries";

//* ************************************** ASSETS ****************************************
import kinetikosIcon from "../../assets/images/logo-secondary.svg";
import homeIcon from "../../assets/images/patientSidebarIcons/home_white.svg";
import editIcon from "../../assets/images/patientSidebarIcons/edit_white.svg";
import closeIcon from "../../assets/images/patientSidebarIcons/close_white.svg";
import evaluationsIcon from "../../assets/images/patientSidebarIcons/evaluations_white.svg";
import evaluationsSelectedIcon from "../../assets/images/patientSidebarIcons/evaluations_base.svg";
import reportsIcon from "../../assets/images/patientSidebarIcons/reports_white.svg";
import reportsSelectedIcon from "../../assets/images/patientSidebarIcons/reports_base.svg";
import programsIcon from "../../assets/images/patientSidebarIcons/programs_white.svg";
import programsSelectedIcon from "../../assets/images/patientSidebarIcons/programs_base.svg";
import chevronUpIcon from "../../assets/images/patientSidebarIcons/chevron-up_white.svg";
import chevronDownIcon from "../../assets/images/patientSidebarIcons/chevron-down_white.svg";
import chevronUpSelectedIcon from "../../assets/images/patientSidebarIcons/chevron-up_base.svg";
import chevronDownSelectedIcon from "../../assets/images/patientSidebarIcons/chevron-down_base.svg";
import testsIcon from "../../assets/images/patientSidebarIcons/tests_white.svg";
import testsSelectedIcon from "../../assets/images/patientSidebarIcons/tests_base.svg";
import surveysIcon from "../../assets/images/patientSidebarIcons/surveys_white.svg";
import surveysSelectedIcon from "../../assets/images/patientSidebarIcons/surveys_base.svg";
import exercisesIcon from "../../assets/images/patientSidebarIcons/exercises_white.svg";
import exercisesSelectedIcon from "../../assets/images/patientSidebarIcons/exercises_base.svg";
import medicinesIcon from "../../assets/images/patientSidebarIcons/medicines_white.svg";
import medicinesSelectedIcon from "../../assets/images/patientSidebarIcons/medicines_base.svg";
import prescriptionsIcon from "../../assets/images/patientSidebarIcons/prescriptions_white.svg";
import prescriptionsSelectedIcon from "../../assets/images/patientSidebarIcons/prescriptions_base.svg";
import myCareTeamIcon from "../../assets/images/patientSidebarIcons/my_care_team_white.svg";
import myCareTeamSelectedIcon from "../../assets/images/patientSidebarIcons/my_care_team_base.svg";
import settingsIcon from "../../assets/images/patientSidebarIcons/settings_white.svg";
import optionsIcon from "../../assets/images/patientSidebarIcons/settings-icon.svg";
import mkIcon from "../../assets/images/patientSidebarIcons/mk-icon.svg";
import dissociateIcon from "../../assets/images/patientSidebarIcons/dissociate-icon.svg";
import deleteIcon from "../../assets/images/patientSidebarIcons/delete-icon.svg";
import deleteIconWhite from "../../assets/images/patientSidebarIcons/delete-icon-white.svg";
import contactSuportIcon from "../../assets/images/patientSidebarIcons/contact-support_white.svg";
import cross from "../../assets/images/home/close.svg";
import { getAvatarWithInitials } from "../../utils/avatar";
import validator from "validator";
import PatientCustomization from "../../pages/my_area/risk_alert/partials/patientCustomization";
import useToast from "hooks/useToast";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

import useInterval from "hooks/useInterval";

const Sidebar = ({ currentScreen, children, history }) => {
  //* ************************************** HANDLERS **************************************
  const selectScreen = {
    evaluations: "evaluations",
    reports: "reports",
    mkinetikosPrograms: "mkinetikos_programs",
    mkinetikosTests: "mkinetikos_tests",
    mkinetikosSurveys: "mkinetikos_surveys",
    mkinetikosExercises: "mkinetikos_exercises",
    mkinetikosMedicines: "mkinetikos_medicines",
    mkinetikosPrescriptions: "mkinetikos_prescriptions",
    mkinetikosMyCareTeam: "mkinetikos_my_care_team",
    mkinetikosReport: "mkinetikos_report",
  };

  const { patient_id } = useParams();

  const [isPatientInfoOpen, setIsPatientInfoOpen] = useState(false);
  const [isProgramsOpen, setIsProgramsOpen] = useState(false);
  const [isMedicinesOpen, setIsMedicinesOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [showPatientSidebar, setShowPatientSidebar] = useState(false);

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isConnInvSent, setIsConnInvSent] = useState(false);
  const [isEmailInvSent, setIsEmailInvSent] = useState(false);
  const [hasMaxNumber, setHasMaxNumber] = useState(false);
  const [licenseModalHasMaxNumber, setLicenseModalHasMaxNumber] = useState(null);
  const [hasPendingInvitation, setHasPendingInvitation] = useState(false);
  const [hasConnAlready, setHasConnAlready] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(true);
  const [showRenewLicenceModal, setShowRenewLicenceModal] = useState(false);
  const [confirmDeletePatient, setConfirmDeletePatient] = useState(false);

  const [emailInput, setEmailInput] = useState("");
  const [connCodeInput, setConnCodeInput] = useState("");
  const [connCodeInputError, setConnCodeInputError] = useState("\u00A0");

  const [isRiskAlertCostumOpen, setIsRiskAlertCostumOpen] = useState(false);
  const [upgradePremium, setUpgradePremium] = useState(false);
  const [offerPremium, setOfferPremium] = useState(false);
  const [isSupportButtonClick, setIsSupportButtonClick] = useState(false);
  const [tooltipRef, setTooltipRef] = useState(null);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const patientHome = useSelector((state) => state.patientHome);
  const patientProfile = useSelector((state) => state.patientProfile);
  const hasOpenedPatientMedicinesPage = useSelector(
    (state) => state.patientsMedicines.medicine_list.has_opened_patient_medicines_page
  );

  const currentInstitutionName = useSelector(
    (state) => state.institutions.current_institution.name
  );

  const permissions = useSelector((state) => state.permissions);
  const adminRole = useSelector((state) => state.institutions.current_institution.role_id);

  const showToast = useToast();

  const showExpiringInWarning =
    patientHome.license_expiring_in !== null && patientHome.license_expiring_in >= 0;

  const showExpiredWarning =
    patientHome.license_expiring_in !== null && patientHome.license_expiring_in < 0;

  const hasShowTooltip =
    isMedicinesOpen && !hasOpenedPatientMedicinesPage && !patientHome.is_followed;

  const expirationText = showExpiringInWarning
    ? t("patient.sidebar.licence_expire_msg", {
        days: patientHome.license_expiring_in,
        count: patientHome.license_expiring_in,
      })
    : t("patient.sidebar.licence_expired_msg");

  useEffect(() => {
    setIsPatientLoading(true);
    dispatch(getPatientHome(patient_id))
      .then(() => getPatientInfo())
      .finally(() => setIsPatientLoading(false));
  }, [patient_id, dispatch]);

  useInterval(() => {
    ReactTooltip.hide(tooltipRef);
  }, 30000);

  useEffect(() => {
    openTooltipForFollowPatient();
  }, [hasOpenedPatientMedicinesPage, isMedicinesOpen]);

  const openTooltipForFollowPatient = () => {
    if (hasShowTooltip) ReactTooltip.show(tooltipRef);
  };

  const handleDeletePatient = () => {
    dispatch(deletePatient(patient_id)).then(() => history.push("/home"));
  };

  const getPatientInfo = () => {
    dispatch(getPatientPersonalData(patient_id));
    dispatch(getPatientContactInfo(patient_id));
    dispatch(getPatientClinicalData(patient_id));
    dispatch(getPatientIdentification(patient_id));
  };

  const isScreenSelected = (screen) => {
    if (screen === selectScreen.mkinetikosMedicines) {
      if (
        currentScreen.includes(selectScreen.mkinetikosPrescriptions) &&
        currentScreen.includes("medicines")
      ) {
        return true;
      } else {
        return currentScreen.includes(screen);
      }
    } else if (screen === selectScreen.mkinetikosPrescriptions) {
      if (
        currentScreen.includes(selectScreen.mkinetikosPrescriptions) &&
        !currentScreen.includes("medicines")
      ) {
        return true;
      }
    } else {
      return currentScreen.includes(screen);
    }
  };

  const refreshPatientInfo = () => dispatch(getPatientHome(patient_id));

  const handleConnCodeInputChange = (e) => {
    if (connCodeInputError !== "\u00A0") {
      setConnCodeInputError("\u00A0");
    }

    setConnCodeInput(e.target.value);
  };

  const handleConnectionButton = () => {
    setIsConnInvSent(false);
    setIsEmailInvSent(false);
    setEmailInput("");
    setConnCodeInput("");
    setConnCodeInputError("\u00A0");
    if (patientHome.has_mkinetikos) {
      setIsDisconnectModalOpen(true);
    } else {
      setIsConnectModalOpen(true);
    }
  };

  const handleSendConnInvite = () => {
    if (emailInput && !patientHome.is_premium) {
      dispatch(requestConnections(patient_id, emailInput)).then(
        (res) => {
          setIsConnectModalOpen(false);
        },
        (err) => {
          if (
            err.error.response?.data?.msg === "Max number of connections for this package plan" ||
            err.error.response?.data?.msg === "mKinetikos user not found with given email"
          ) {
            setOfferPremium(true);
            setIsConnectModalOpen(false);
          } else if (err.error.response?.data?.msg === "Patient is already connected") {
            showToast({
              message: t("my_area.mKinetikos.patient_already_connected"),
              type: "info",
            });
          }
        }
      );
    } else {
      dispatch(createPatientMkinetikosConnection(patient_id, connCodeInput, emailInput)).then(
        ({ payload }) => {
          if (payload.data) {
            switch (payload.data.msg) {
              case "Email to install the mKinetikos app sent":
                setIsEmailInvSent(true);
                break;
              case "A connection request was sent to the patient":
                setIsConnInvSent(true);
                break;
              case "Max number of connections for this package plan":
                setLicenseModalHasMaxNumber(true);
                setHasMaxNumber(true);
                break;
              case "There is a pending invitation for this patient":
                setHasPendingInvitation(true);
                break;
              case "Connection already exists":
                setHasConnAlready(true);
                break;
              default:
                break;
            }
          }
        },
        ({ error }) => {
          if (error.response && error.response.data) {
            if (error.response.data.msg === "Invalid connection key") {
              setConnCodeInputError("Invalid connection key");
            } else if (
              error.response.data.msg === "Max number of connections for this package plan"
            ) {
              setLicenseModalHasMaxNumber(true);
              setIsConnectModalOpen(false);
            }
          }
        }
      );
    }
  };

  const handleDeleteConn = () => {
    dispatch(deletePatientMkinetikosConnection(patient_id)).then(() => {
      dispatch(getPatientHome(patient_id));
      setIsDisconnectModalOpen(false);
    });
  };

  const renderPatientInfo = () => {
    if (isPatientInfoOpen) {
      return (
        <>
          <div className="d-flex align-self-center" style={{ gap: "12px" }}>
            <Link to={`/patient/${patient_id}/edit`}>
              <div className="patient-info-wrapper closed">
                <img alt="Edit Patient Info" src={editIcon} />
                {t("buttons.edit")}
              </div>
            </Link>
            <div
              className="patient-info-wrapper closed"
              onClick={() => {
                if (isPatientInfoOpen) {
                  setIsPatientInfoOpen(false);
                }
              }}
            >
              <img alt="Close Patient Info" src={closeIcon} />
              {t("buttons.close")}
            </div>
          </div>
          <div className="info-field-title">
            {t("patient_info.conditions")} <Icon icon={icons["lock-icon"]} className={"ml-1"} />
          </div>
          <div className="info-field-text">
            {patientProfile?.medical_conditions?.length > 0
              ? patientProfile?.medical_conditions.map((condition) => {
                  return (
                    <li className="medical-conditions ml-1">
                      {t(`patient_info.medical_conditions.${condition}`)}
                    </li>
                  );
                })
              : "-----"}
          </div>
          <div className="info-field-title">{t("patient_info.mobile_phone")}</div>
          <div className="info-field-text">
            {patientProfile.telephone === null || patientProfile.telephone === ""
              ? "-----"
              : patientProfile.telephone}
          </div>
          <div className="info-field-title">{t("patient_info.email")}</div>
          <div className="info-field-text">
            {patientProfile.email === null || patientProfile.email === ""
              ? "-----"
              : patientProfile.email}
          </div>
          <div className="info-field-title">{t("patient_info.birthday")}</div>
          <div className="info-field-text">
            {patientProfile.birth_date === null || patientProfile.birth_date === ""
              ? "-----"
              : parseDate(patientProfile.birth_date)}
          </div>
          <div className="info-field-title">{t("patient_info.nationality")}</div>
          <div className="info-field-text">
            {patientProfile.nationality_id === null || patientProfile.nationality_id === ""
              ? "-----"
              : t(`nationalities.${getNationality(patientProfile.nationality_id)}`)}
          </div>
          <div className="info-field-title">{t("patient_info.occupation")}</div>
          <div className="info-field-text">
            {patientProfile.occupation === null || patientProfile.occupation === ""
              ? "-----"
              : patientProfile.occupation}
          </div>
          <div className="info-field-title">{t("patient_info.education_level")}</div>
          <div className="info-field-text">
            {patientProfile.educational_level_id === null ||
            patientProfile.educational_level_id === ""
              ? "-----"
              : ["None", "Primary", "Secondary", "Tertiary"][
                  patientProfile.educational_level_id - 1
                ]}
          </div>
          <div className="info-field-title">{t("patient_info.marital_status")}</div>
          <div className="info-field-text">
            {patientProfile.marital_status_id === null || patientProfile.marital_status_id === ""
              ? "-----"
              : [
                  t("create_patient.personal_data.status_not"),
                  t("create_patient.personal_data.status_married"),
                  t("create_patient.personal_data.status_divorced"),
                  t("create_patient.personal_data.status_widow"),
                ][patientProfile.marital_status_id - 1]}
          </div>
        </>
      );
    } else {
      return t("patient_info.patient_info");
    }
  };

  const renderConnectModal = () =>
    !isConnInvSent &&
    !isEmailInvSent &&
    !hasMaxNumber &&
    !hasPendingInvitation &&
    !hasConnAlready ? (
      <MessageModal
        headerText={`${t("patient.sidebar.associate_header")} ${patientHome.name}`}
        body={
          <Trans i18nKey={"patient.sidebar.associate_body"}>
            {patientHome.name}
            {currentInstitutionName ? currentInstitutionName : "this"}
          </Trans>
        }
        htmlContent={
          <>
            <div className="row">
              <div className="col">
                <label className="d-flex" htmlFor="connCodeInput">
                  <span className="d-flex input-label-text black-color">
                    {t("patient.sidebar.insert_code_provided")}
                  </span>
                </label>
                <input
                  value={connCodeInput}
                  autoComplete="off"
                  className={`form-input ${connCodeInputError !== "\u00A0" ? "error" : ""}`}
                  name="connCodeInput"
                  id="connCodeInput"
                  onChange={handleConnCodeInputChange}
                />
                <div
                  className="input-label-text warning-color text-end"
                  style={{ marginTop: "-20px" }}
                >
                  {connCodeInputError}
                </div>
              </div>
              <div className="col-1 d-flex flex-column align-items-center">
                <div className="regular-text mt-4">
                  <u>{t("or")}</u>
                </div>
              </div>
              <div className="col">
                <label className="d-flex" htmlFor="emailInput">
                  <span className="input-label-text black-color">
                    {t("patient.sidebar.invite_via_email")}
                  </span>
                </label>
                <input
                  value={emailInput}
                  autoComplete="off"
                  className="form-input"
                  name="emailInput"
                  id="emailInput"
                  onChange={(e) => setEmailInput(e.target.value)}
                />
              </div>
            </div>
          </>
        }
        footerButtons={[
          <button
            onClick={() => {
              setIsConnectModalOpen(false);
              setIsConnInvSent(false);
              setIsEmailInvSent(false);
            }}
            className="transparent-button small-button"
          >
            <u> {t("buttons.cancel")} </u>
          </button>,
          <button
            disabled={connCodeInput === "" && !validator.isEmail(emailInput)}
            onClick={handleSendConnInvite}
            className="secondary-button small-button"
          >
            {t("buttons.confirm")}
          </button>,
        ]}
      />
    ) : (
      <MessageModal
        headerText={
          hasMaxNumber || hasPendingInvitation || hasConnAlready
            ? t("patient.sidebar.connection_not_sent_header")
            : isConnInvSent
            ? t("patient.sidebar.connection_sent_header")
            : t("patient.sidebar.email_sent")
        }
        body={
          hasMaxNumber ? (
            t("patient.sidebar.max_connections_body")
          ) : hasPendingInvitation ? (
            t("patient.sidebar.pending_invitation_body")
          ) : hasConnAlready ? (
            t("patient.sidebar.already_associated_body")
          ) : isConnInvSent ? (
            <Trans i18nKey={"patient.sidebar.receive_invitation_app_body"}>
              {patientHome.name}
              {currentInstitutionName}
            </Trans>
          ) : (
            <Trans i18nKey={"patient.sidebar.receive_invitation_email_body"}>
              {patientHome.name}
              {currentInstitutionName}
            </Trans>
          )
        }
        footerButtons={[
          <button
            onClick={() => {
              setIsConnectModalOpen(false);
              setIsConnInvSent(false);
              setIsEmailInvSent(false);
              setHasMaxNumber(false);
              setHasPendingInvitation(false);
              setHasConnAlready(false);
            }}
            className="primary-button small-button"
          >
            {t("buttons.ok")}
          </button>,
        ]}
      />
    );

  const renderDisconnectModal = () => (
    <MessageModal
      headerText={t("patient.sidebar.dissociate_mkinetikos_header")}
      body={`${t("patient.sidebar.dissociate_mkinetikos_body_1")} ${patientHome.name}${t(
        "patient.sidebar.dissociate_mkinetikos_body_2"
      )}`}
      footerButtons={[
        <button
          onClick={() => setIsDisconnectModalOpen(false)}
          className="transparent-button small-button"
        >
          <u> {t("buttons.cancel")} </u>
        </button>,
        <button onClick={handleDeleteConn} className="secondary-button small-button warning-button">
          {t("buttons.confirm")}
        </button>,
      ]}
    />
  );

  //* *************************************** RENDER ***************************************
  return (
    <div className="main-wrapper">
      <div className="row g-0">
        <div
          className={`col-auto patient-sidebar ${showPatientSidebar && "show"}`}
          onScroll={openTooltipForFollowPatient}
        >
          <div className="col-auto w-100 flex-d-row justify-content-between">
            <Link to={"/home"}>
              <img alt="authBackground" className="logo cursor-pointer" src={kinetikosIcon} />
            </Link>
            <SidebarHamburger
              showLeftRadius
              onClick={() => setShowPatientSidebar(!showPatientSidebar)}
            />
          </div>
          <div className="back-home-container">
            <Link to={"/home"}>
              <button>
                <img alt="Back to Dashboard" src={homeIcon} />
                <span>{t("buttons.back_to_dashboard")}</span>
              </button>
            </Link>
          </div>
          <div className="patient-avatar-name">
            <img
              alt="Patient Avatar"
              src={
                isPatientLoading
                  ? getAvatarWithInitials(64, "No Name")
                  : patientHome.mkinetikos_photo
                  ? patientHome.mkinetikos_photo
                  : getAvatarWithInitials(64, patientHome.name)
              }
              onError={(e) => {
                e.target.src = getAvatarWithInitials(64, patientHome.name);
              }}
            />
            <div className="patient-name">{isPatientLoading ? "------" : patientHome.name}</div>
          </div>
          {adminRole !== 3 && (
            <div
              className={`patient-info-wrapper ${isPatientInfoOpen ? "open" : "closed"}`}
              style={isPatientLoading ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
              onClick={() => {
                if (!isPatientInfoOpen) {
                  dispatch(getPatientPersonalData(patient_id));
                  dispatch(getPatientContactInfo(patient_id));
                  dispatch(getPatientClinicalData(patient_id));
                  dispatch(getPatientIdentification(patient_id));
                  setIsPatientInfoOpen(true);
                }
              }}
            >
              {renderPatientInfo()}
            </div>
          )}
          {!isPatientInfoOpen && adminRole !== 3 && (
            <>
              <div className="patient-nav-section">
                <div className="section-title">{t("kinetikos_caps")}</div>
                <Link
                  to={`/patient/${patient_id}/${selectScreen.evaluations}`}
                  className="section-item"
                >
                  <button
                    name={selectScreen.evaluations}
                    className={`section-item ${
                      isScreenSelected(selectScreen.evaluations) && "active"
                    }`}
                  >
                    <img
                      alt="Evaluations"
                      src={
                        isScreenSelected(selectScreen.evaluations)
                          ? evaluationsSelectedIcon
                          : evaluationsIcon
                      }
                    ></img>
                    {t("titles.evaluations")}
                  </button>
                </Link>
                <Link to={`/patient/${patient_id}/${selectScreen.reports}`}>
                  <button
                    name={selectScreen.reports}
                    className={`section-item ${isScreenSelected(selectScreen.reports) && "active"}`}
                  >
                    <img
                      alt="Reports"
                      src={
                        isScreenSelected(selectScreen.reports) ? reportsSelectedIcon : reportsIcon
                      }
                    />
                    {t("titles.reports")}
                  </button>
                </Link>
              </div>
              {patientHome.has_mkinetikos && !isPatientLoading && adminRole !== 3 && (
                <div className="patient-nav-section">
                  <div className="d-flex align-items-start">
                    <div className="section-title">{t("mkinetikos_caps")}</div>
                    <div
                      onClick={() => {
                        dispatch(
                          !patientHome.is_followed
                            ? followPatients([patient_id])
                            : unfollowPatients([patient_id])
                        ).then(() => {
                          dispatch(getPatientHome(patient_id));
                        });
                      }}
                      className="btn-follow"
                      data-tip={t("patient.sidebar.follow_patient_tooltip")}
                      data-for={"follow_tooltip"}
                      // data-event="click"
                      data-tooltip-position-strategy="absolute"
                      ref={(ref) => setTooltipRef(ref)}
                    >
                      {patientHome.is_followed ? t("buttons.unfollow") : t("buttons.follow")}
                    </div>
                    {patientHome.is_followed && (
                      <img
                        onClick={() => {
                          setIsRiskAlertCostumOpen(true);
                        }}
                        alt="Settings"
                        src={settingsIcon}
                      />
                    )}
                    {!patientHome.is_followed && hasShowTooltip && isMedicinesOpen && (
                      <ReactTooltip
                        className="tooltip-btn follow-tooltip"
                        id={"follow_tooltip"}
                        place={"right"}
                        effect="solid"
                        arrowColor="#255986"
                        multiline={true}
                        clickable={false}
                        positionStrategy="fixed"
                        events={["hover"]}
                      >
                        {t("patient.sidebar.follow_patient_tooltip")}
                      </ReactTooltip>
                    )}
                  </div>
                  {(showExpiringInWarning || showExpiredWarning) && (
                    <div
                      className={`autorenew-reminder ${
                        showExpiredWarning && "background-red text-white"
                      }`}
                    >
                      {expirationText}
                      {permissions.accessPermissionForLicense && (
                        <span
                          className={`renew-btn ${showExpiredWarning && "text-white"}`}
                          onClick={() => setShowRenewLicenceModal(true)}
                        >
                          {t("buttons.renew")}
                        </span>
                      )}
                    </div>
                  )}
                  <button
                    name={selectScreen.mkinetikosPrograms}
                    className={`section-item ${
                      isScreenSelected(selectScreen.mkinetikosPrograms) && "active"
                    }`}
                    onClick={() => {
                      history.push(`/patient/${patient_id}/${selectScreen.mkinetikosPrograms}`);
                      setIsProgramsOpen(true);
                      setIsMedicinesOpen(false);
                    }}
                  >
                    <img
                      alt="mKinetikos Programs"
                      src={
                        isScreenSelected(selectScreen.mkinetikosPrograms)
                          ? programsSelectedIcon
                          : programsIcon
                      }
                    />
                    <span className="flex-grow-1">{t("titles.programs")}</span>
                    <img
                      className="collapse-img"
                      onClick={(e) => {
                        setIsProgramsOpen(!isProgramsOpen);
                        e.stopPropagation();
                      }}
                      alt="mKinetikos Programs Collapse"
                      src={
                        isProgramsOpen
                          ? isScreenSelected(selectScreen.mkinetikosPrograms)
                            ? chevronUpSelectedIcon
                            : chevronUpIcon
                          : isScreenSelected(selectScreen.mkinetikosPrograms)
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                    />
                  </button>
                  <div className={`programs-expandable-container ${isProgramsOpen ? "open" : ""}`}>
                    <Link
                      to={`/patient/${patient_id}/${selectScreen.mkinetikosTests}`}
                      onClick={() => {
                        setIsMedicinesOpen(false);
                      }}
                    >
                      <button
                        name={selectScreen.mkinetikosTests}
                        className={`section-item ${
                          isScreenSelected(selectScreen.mkinetikosTests) && "active"
                        }`}
                      >
                        <img
                          className="xmargin-img"
                          alt="mKinetikos Tests"
                          src={
                            isScreenSelected(selectScreen.mkinetikosTests)
                              ? testsSelectedIcon
                              : testsIcon
                          }
                        />
                        {t("titles.tests")}
                      </button>
                    </Link>
                    <Link
                      to={`/patient/${patient_id}/${selectScreen.mkinetikosSurveys}`}
                      onClick={() => {
                        setIsMedicinesOpen(false);
                      }}
                    >
                      <button
                        name={selectScreen.mkinetikosSurveys}
                        className={`section-item ${
                          isScreenSelected(selectScreen.mkinetikosSurveys) && "active"
                        }`}
                      >
                        <img
                          className="xmargin-img"
                          alt="mKinetikos Surveys"
                          src={
                            isScreenSelected(selectScreen.mkinetikosSurveys)
                              ? surveysSelectedIcon
                              : surveysIcon
                          }
                        />
                        {t("titles.surveys")}
                      </button>
                    </Link>
                  </div>
                  <Link
                    to={`/patient/${patient_id}/${selectScreen.mkinetikosExercises}`}
                    onClick={() => {
                      setIsProgramsOpen(false);
                      setIsMedicinesOpen(false);
                    }}
                  >
                    <button
                      name={selectScreen.mkinetikosExercises}
                      className={`section-item ${
                        isScreenSelected(selectScreen.mkinetikosExercises) && "active"
                      }`}
                    >
                      <img
                        alt="mKinetikos Exercises"
                        src={
                          isScreenSelected(selectScreen.mkinetikosExercises)
                            ? exercisesSelectedIcon
                            : exercisesIcon
                        }
                      />
                      {t("titles.exercises")}
                    </button>
                  </Link>
                  <button
                    name={selectScreen.mkinetikosMedicines}
                    className={`section-item ${
                      isScreenSelected(selectScreen.mkinetikosMedicines) && "active"
                    }`}
                    onClick={() => {
                      history.push(`/patient/${patient_id}/${selectScreen.mkinetikosMedicines}`);
                      setIsMedicinesOpen(true);
                    }}
                  >
                    <img
                      alt="mKinetikos Medicines"
                      src={
                        isScreenSelected(selectScreen.mkinetikosMedicines)
                          ? medicinesSelectedIcon
                          : medicinesIcon
                      }
                    />
                    <span className="flex-grow-1">{t("titles.meds")}</span>
                    <img
                      className="collapse-img"
                      onClick={(e) => {
                        setIsMedicinesOpen(!isMedicinesOpen);
                        e.stopPropagation();
                      }}
                      alt="mKinetikos Medicines Collapse"
                      src={
                        isMedicinesOpen
                          ? isScreenSelected(selectScreen.mkinetikosMedicines)
                            ? chevronUpSelectedIcon
                            : chevronUpIcon
                          : isScreenSelected(selectScreen.mkinetikosMedicines)
                          ? chevronDownSelectedIcon
                          : chevronDownIcon
                      }
                    />
                  </button>
                  <div
                    className={`medicines-expandable-container ${isMedicinesOpen ? "open" : ""}`}
                  >
                    <Link to={`/patient/${patient_id}/${selectScreen.mkinetikosPrescriptions}`}>
                      <button
                        name={selectScreen.mkinetikosPrescriptions}
                        className={`section-item ${
                          isScreenSelected(selectScreen.mkinetikosPrescriptions) && "active"
                        }`}
                      >
                        <img
                          className="xmargin-img"
                          alt="mKinetikos PrescrimkinetikosPrescriptions"
                          src={
                            isScreenSelected(selectScreen.mkinetikosPrescriptions)
                              ? prescriptionsSelectedIcon
                              : prescriptionsIcon
                          }
                        />
                        {t("titles.prescriptions")}
                      </button>
                    </Link>
                  </div>
                  <Link
                    to={`/patient/${patient_id}/${selectScreen.mkinetikosMyCareTeam}`}
                    onClick={() => {
                      setIsProgramsOpen(false);
                      setIsMedicinesOpen(false);
                    }}
                  >
                    <button
                      name={selectScreen.mkinetikosMyCareTeam}
                      className={`section-item ${
                        isScreenSelected(selectScreen.mkinetikosMyCareTeam) && "active"
                      }`}
                    >
                      <img
                        alt="mKinetikos My Care Team"
                        src={
                          isScreenSelected(selectScreen.mkinetikosMyCareTeam)
                            ? myCareTeamSelectedIcon
                            : myCareTeamIcon
                        }
                      />
                      {t("titles.my_care_team")}
                    </button>
                  </Link>
                  <Link
                    to={`/patient/${patient_id}/${selectScreen.mkinetikosReport}`}
                    onClick={() => {
                      setIsProgramsOpen(false);
                      setIsMedicinesOpen(false);
                    }}
                  >
                    <button
                      name={selectScreen.mkinetikosReport}
                      className={`section-item ${
                        isScreenSelected(selectScreen.mkinetikosReport) && "active"
                      }`}
                    >
                      <img
                        alt="mKinetikos Reports"
                        src={
                          isScreenSelected(selectScreen.mkinetikosReport)
                            ? reportsSelectedIcon
                            : reportsIcon
                        }
                      />
                      {t("titles.reports")}
                    </button>
                  </Link>
                </div>
              )}
            </>
          )}

          {!isPatientInfoOpen && (
            <>
              <div className="flex-grow-1"></div>
              <div className={`options-wrapper`}>
                <div
                  className={`options-content ${isOptionsOpen ? "options-open" : "options-close"}`}
                >
                  {!isPatientLoading && permissions.accessPermissionForLicense && (
                    <>
                      {patientHome.has_mkinetikos && !patientHome.is_premium && (
                        <button onClick={() => setUpgradePremium(true)}>
                          <img alt="Connection mKinetikos" src={mkIcon} />
                          {t("patient.sidebar.upgrade_premium")}
                        </button>
                      )}
                      <button
                        onClick={() => {
                          handleConnectionButton();
                        }}
                      >
                        <img
                          alt="Connection mKinetikos"
                          src={patientHome.has_mkinetikos ? dissociateIcon : mkIcon}
                        />
                        {patientHome.has_mkinetikos
                          ? t("patient.sidebar.dissociate_mkinetikos")
                          : t("patient.sidebar.associate_mkinetikos")}
                      </button>
                    </>
                  )}
                  <button
                    onClick={() => setConfirmDeletePatient(true)}
                    onMouseOver={(e) =>
                      (e.currentTarget.getElementsByTagName("img")[0].src = deleteIconWhite)
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.getElementsByTagName("img")[0].src = deleteIcon)
                    }
                    className="delete-patient"
                  >
                    <img alt="Connection mKinetikos" src={deleteIcon} />
                    <span className="flex-grow-1 warning-color">
                      {t("patient.sidebar.delete_patient")}
                    </span>
                  </button>
                </div>
                <div className="option-btn">
                  <button
                    className={`${isOptionsOpen && "opitons-active"}`}
                    onClick={() => {
                      setIsOptionsOpen(!isOptionsOpen);
                    }}
                  >
                    <img alt="Connection mKinetikos" src={optionsIcon} />
                    <span className="flex-grow-1">{t("buttons.options")}</span>
                    <img
                      className="right-arrow"
                      alt="right arrow"
                      src={isOptionsOpen ? chevronUpIcon : chevronDownIcon}
                    />
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="bottom-border"></div>
          <div className="contact-wrapper">
            <button onClick={() => setIsSupportButtonClick(true)}>
              <img alt="t('buttons.contact_support')" src={contactSuportIcon} />
              {t("buttons.contact_support")}
            </button>
          </div>
        </div>
        <div className="sidebar-hamburger-container">
          <SidebarHamburger onClick={() => setShowPatientSidebar(!showPatientSidebar)} />
        </div>
        {children}
      </div>
      <div className="patient-sidebar-modals-wrapper">
        {isConnectModalOpen && renderConnectModal()}
        {isDisconnectModalOpen && renderDisconnectModal()}
        {isRiskAlertCostumOpen && (
          <PatientCustomization
            close={() => setIsRiskAlertCostumOpen(false)}
            patientId={patient_id}
          />
        )}
      </div>
      {(offerPremium || licenseModalHasMaxNumber) && !patientHome.is_premium && (
        <RenewLicenceModal
          onClose={() => {
            setOfferPremium(false);
            setLicenseModalHasMaxNumber(false);
          }}
          showModal={offerPremium || licenseModalHasMaxNumber}
          data={
            offerPremium
              ? { email: emailInput, patient_id: patient_id }
              : { patient_id: patient_id, connection_key: connCodeInput }
          }
          customHead={
            <div className="row-space-between">
              <span>{t("patient.sidebar.upgrade_premium")}</span>
              <img
                className="cursor-pointer"
                src={cross}
                alt="close pointer"
                onClick={() => {
                  setOfferPremium(false);
                  setLicenseModalHasMaxNumber(false);
                }}
              />
            </div>
          }
          renewWithEmail={offerPremium ? true : false}
          withPatientID={licenseModalHasMaxNumber ? true : false}
          afterOnClickFunction={refreshPatientInfo}
          btnName={t("buttons.send_invite")}
        />
      )}
      {patientHome.has_mkinetikos && !patientHome.is_premium && upgradePremium && (
        <RenewLicenceModal
          onClose={() => setUpgradePremium(false)}
          showModal={upgradePremium}
          data={{ patient_id: patient_id }}
          customHead={
            <div className="row-space-between">
              <span>{t("patient.sidebar.upgrade_premium")}</span>
              <img
                className="cursor-pointer"
                src={cross}
                alt="close pointer"
                onClick={() => {
                  setUpgradePremium(false);
                }}
              />
            </div>
          }
          afterOnClickFunction={refreshPatientInfo}
          withPatientID={true}
          btnName={t("buttons.upgrade")}
        />
      )}

      {showRenewLicenceModal && (
        <RenewLicenceModal
          onClose={() => setShowRenewLicenceModal(false)}
          showModal={showRenewLicenceModal}
          data={[
            {
              name: patientHome.name,
              license_duration: patientHome.license_duration,
              license_id: patientHome.license_id,
            },
          ]}
          customHead={
            <div className="row-space-between">
              <span>{t("my_area.mKinetikos.renew_licence")}</span>
              <img
                className="cursor-pointer"
                src={cross}
                alt="close pointer"
                onClick={() => {
                  setShowRenewLicenceModal(false);
                }}
              />
            </div>
          }
          afterOnClickFunction={refreshPatientInfo}
        />
      )}
      {confirmDeletePatient && (
        <MessageModal
          headerText={t("patient.sidebar.delete_patient")}
          body={<Trans i18nKey={"patient.sidebar.delete_patient_body"}>{patientHome.name}</Trans>}
          footerButtons={[
            <button
              onClick={() => setConfirmDeletePatient(false)}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={handleDeletePatient}
              className="secondary-button small-button warning-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {isSupportButtonClick && (
        <NewSupportTicketModal onClose={() => setIsSupportButtonClick(false)} />
      )}
    </div>
  );
};

export default Sidebar;
