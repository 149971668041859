//* ********************************* EXTERNAL PACKAGES **********************************
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ReactTooltip from "react-tooltip";

//* *************************************** STYLES ***************************************
import { getMkinetikosAccessPermission } from "../../redux/actions/permissions";
import uuid from "../../utils/uuid";

//* *********************************** OUR COMPONENTS ***********************************
import NewSupportTicketModal from "components/newSupportTicketModal";
import SidebarHamburger from "components/sidebarHamburger";

//* ************************************** GLOBALS ***************************************
import kinetikosIcon from "../../assets/images/logo-primary.svg";
import homeIcon from "../../assets/images/buttonIcons/home.svg";
import personalDataIcon from "../../assets/images/buttonIcons/person.svg";
import institutionIcon from "../../assets/images/buttonIcons/institution.svg";
import evaluationsIcon from "../../assets/images/buttonIcons/evaluation.svg";
import securityIcon from "../../assets/images/buttonIcons/security.svg";
import notificationsIcon from "../../assets/images/buttonIcons/notifications-active.svg";
import privacyIcon from "../../assets/images/buttonIcons/lock.svg";
import mKinetikosIcon from "../../assets/images/buttonIcons/mk-logo-landing.svg";
import deleteIcon from "../../assets/images/buttonIcons/delete.svg";
import contactSuportIcon from "../../assets/images/buttonIcons/contact-support.svg";
import supportTicketsIcon from "../../assets/images/buttonIcons/support-tickets.svg";

import personalDataSelectedIcon from "../../assets/images/buttonIconsInverted/person.svg";
import institutionSelectedIcon from "../../assets/images/buttonIconsInverted/institution.svg";
import evaluationsSelectedIcon from "../../assets/images/buttonIconsInverted/evaluation.svg";
import securitySelectedIcon from "../../assets/images/buttonIconsInverted/security.svg";
import privacySelectedIcon from "../../assets/images/buttonIconsInverted/lock.svg";
import mKinetikosSelectedIcon from "../../assets/images/buttonIconsInverted/mk-logo-landing.svg";
import notificationsSelectedIcon from "../../assets/images/buttonIconsInverted/notifications-active.svg";
import supportTicketsSelectedIcon from "../../assets/images/buttonIconsInverted/support-tickets.svg";

const Sidebar = ({ currentScreen, setNewScreen, children, history }) => {
  const { t, i18n } = useTranslation();
  //* ************************************** HANDLERS **************************************
  const permissions = useSelector((state) => state.permissions);

  const [isSupportButtonClick, setIsSupportButtonClick] = useState(false);
  const [showMyAreaSidebar, setShowMyAreaSidebar] = useState(false);

  const selectScreen = {
    personalData: "personal_data",
    institutions: "institutions",
    evaluationTemplates: "evaluation_templates",
    security: "security",
    risk_alert: "risk_alert",
    viewMode: "view_mode",
    privacy: "privacy",
    mKinetikos: "mKinetikos",
    support: "support",
  };

  const isScreenSelected = (screen) => currentScreen.includes(screen);
  //* *************************************** RENDER ***************************************
  return (
    <div className="main-container">
      <div className="row">
        <div
          className={`col-auto profile-sidebar ${showMyAreaSidebar && "show"}`}
        >
          <div className="row">
            <div className="col-auto w-100 flex-d-row justify-content-between">
              <Link to={"/home"}>
                <img
                  alt="authBackground"
                  className="logo-primary p-0"
                  src={kinetikosIcon}
                />
              </Link>
              <SidebarHamburger
                showLeftRadius
                onClick={() => setShowMyAreaSidebar(!showMyAreaSidebar)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="back-home-container">
                <Link to={"/home"}>
                  <button>
                    <img alt="Back to Dashboard" src={homeIcon}></img>
                    {t("buttons.back_to_dashboard")}
                  </button>
                </Link>
              </div>
              <div className="options-container">
                <Link to={`/my_area/${selectScreen.personalData}`}>
                  <button
                    name={selectScreen.personalData}
                    className={`${
                      isScreenSelected(selectScreen.personalData) && "active"
                    }`}
                  >
                    <img
                      alt="Personal Data"
                      src={
                        isScreenSelected(selectScreen.personalData)
                          ? personalDataSelectedIcon
                          : personalDataIcon
                      }
                    ></img>
                    {t("titles.personal_data")}
                  </button>
                </Link>

                <Link to={`/my_area/${selectScreen.institutions}`}>
                  <button
                    name={selectScreen.institutions}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.institutions) && "active"
                    }`}
                  >
                    <img
                      alt="Institutions"
                      src={
                        isScreenSelected(selectScreen.institutions)
                          ? institutionSelectedIcon
                          : institutionIcon
                      }
                    ></img>
                    {t("titles.institutions")}
                  </button>
                </Link>
                <Link to={`/my_area/${selectScreen.evaluationTemplates}`}>
                  <button
                    name={selectScreen.evaluationTemplates}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.evaluationTemplates) &&
                      "active"
                    }`}
                  >
                    <img
                      alt="Evaluation Templates"
                      src={
                        isScreenSelected(selectScreen.evaluationTemplates)
                          ? evaluationsSelectedIcon
                          : evaluationsIcon
                      }
                    ></img>
                    {t("titles.evaluation_templates")}
                  </button>
                </Link>
                <Link to={`/my_area/${selectScreen.security}`}>
                  <button
                    name={selectScreen.security}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.security) && "active"
                    }`}
                  >
                    <img
                      alt="Security"
                      src={
                        isScreenSelected(selectScreen.security)
                          ? securitySelectedIcon
                          : securityIcon
                      }
                    ></img>
                    {t("titles.security")}
                  </button>
                </Link>
                <Link to={`/my_area/${selectScreen.risk_alert}`}>
                  <button
                    name={selectScreen.risk_alert}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.risk_alert) && "active"
                    }`}
                  >
                    <img
                      alt="Icon"
                      src={
                        isScreenSelected(selectScreen.risk_alert)
                          ? notificationsSelectedIcon
                          : notificationsIcon
                      }
                    ></img>
                    {t("titles.risk_alert")}
                  </button>
                </Link>
                <Link to="/my_area/privacy">
                  <button
                    name={selectScreen.privacy}
                    // onClick={() => history.push("/my_area/privacy")}
                    className={`${
                      isScreenSelected(selectScreen.privacy) && "active"
                    }`}
                  >
                    <img
                      alt="Privacy"
                      src={
                        isScreenSelected(selectScreen.privacy)
                          ? privacySelectedIcon
                          : privacyIcon
                      }
                    ></img>
                    {t("titles.privacy")}
                  </button>
                </Link>
                <Link
                  to="/my_area/mKinetikos"
                  data-tip={t("my_area.mKinetikos.permission_tooltip")}
                >
                  <button
                    name={selectScreen.mKinetikos}
                    disabled={!permissions.accessPermissionForLicense}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.mKinetikos) && "active"
                    }`}
                  >
                    <img
                      alt="mKinetikos"
                      src={
                        isScreenSelected(selectScreen.mKinetikos)
                          ? mKinetikosSelectedIcon
                          : mKinetikosIcon
                      }
                    ></img>
                    mKinetikos
                  </button>
                  {!permissions.accessPermissionForLicense && (
                    <ReactTooltip
                      className="tooltip-btn"
                      place="bottom"
                      effect="solid"
                      arrowColor="#255986"
                      key={uuid()}
                    />
                  )}
                </Link>
                <Link to={`/my_area/${selectScreen.support}`}>
                  <button
                    name={selectScreen.support}
                    onClick={setNewScreen}
                    className={`${
                      isScreenSelected(selectScreen.support) && "active"
                    }`}
                  >
                    <img
                      alt="Support Tickets"
                      src={
                        isScreenSelected(selectScreen.support)
                          ? supportTicketsSelectedIcon
                          : supportTicketsIcon
                      }
                    ></img>
                    {t("titles.support_ticket")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row flex-grow-1">
            <div className="col d-flex align-items-end">
              <div className="delete-container">
                {/* <button>
                  <img alt="Delete account" src={deleteIcon}></img>
                  {t("buttons.delete_account")}
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-12 contact-container">
            <button onClick={() => setIsSupportButtonClick(true)}>
              <img
                alt='t("buttons.contact_support")'
                src={contactSuportIcon}
              ></img>
              {t("buttons.contact_support")}
            </button>
          </div>
        </div>
        <div className="sidebar-hamburger-container">
          <SidebarHamburger
            onClick={() => setShowMyAreaSidebar(!showMyAreaSidebar)}
          />
        </div>
        {children}
      </div>

      {isSupportButtonClick && (
        <NewSupportTicketModal onClose={() => setIsSupportButtonClick(false)} />
      )}
    </div>
  );
};

export default Sidebar;
