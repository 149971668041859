import {
  GET_SAVED_EXERCISE_LIST_SUCCESS,
  GET_SAVED_EXERCISE_LIST_FAIL,
  GET_EXERCISE_LIST_SUCCESS,
  GET_EXERCISE_LIST_FAIL,
  GET_EXERCISE_DETAILS_SUCCESS,
  GET_EXERCISE_DETAILS_FAIL,
  UPDATE_EXERCISE_DETAILS_SUCCESS,
  UPDATE_EXERCISE_DETAILS_FAIL,
  DELETE_EXERCISE_DETAILS_SUCCESS,
} from "../actions/patientExercises";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  saved_exercises: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
    links: {
      has_next: false,
      has_previous: false,
    },
  },
  exercise_list: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
    links: {
      has_next: false,
      has_previous: false,
    },
  },
  curr_exercise: {
    exercise: {
      id: null,
      name: "",
      description: "",
      image: null,
      video: null,
    },
    details: {
      id: null,
      days: [],
      sets: 0,
      reps: 0,
      end_date: "",
      active: true,
    },
    exercise_plan: {
      id: null,
      created_by: "",
      created_by_institution_id: "",
      start_date: "",
      end_date: "",
      active: true,
    },
  },
};

const PatientsExercises = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAVED_EXERCISE_LIST_SUCCESS: {
      return { ...state, saved_exercises: action.payload.data.data };
    }
    case GET_SAVED_EXERCISE_LIST_FAIL: {
      return { ...state, saved_exercises: initialState.saved_exercises };
    }
    case GET_EXERCISE_LIST_SUCCESS: {
      return { ...state, exercise_list: action.payload.data.data };
    }
    case GET_EXERCISE_LIST_FAIL: {
      return { ...state, exercise_list: initialState.exercise_list };
    }
    case GET_EXERCISE_DETAILS_SUCCESS:
    case UPDATE_EXERCISE_DETAILS_SUCCESS: {
      return { ...state, curr_exercise: action.payload.data.data };
    }
    case GET_EXERCISE_DETAILS_FAIL:
    case UPDATE_EXERCISE_DETAILS_FAIL: {
      return { ...state, curr_exercise: initialState.curr_exercise };
    }
    // case DELETE_EXERCISE_DETAILS_SUCCESS: {
    //   const exercise_details_id =
    //     action.meta.previousAction.exercise_details_id;
    //   return {
    //     ...state,
    //     exercise_list: {
    //       ...state.exercise_list,
    //       obj_list: state.exercise_list.filter(
    //         (exercise) => exercise.id !== exercise_details_id && exercise
    //       ),
    //     },
    //   };
    // }

    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default PatientsExercises;
