export const GET_MEDICINE_SUMMARY = "GET_MEDICINE_SUMMARY"
export const GET_MEDICINE_SUMMARY_SUCCESS = "GET_MEDICINE_SUMMARY_SUCCESS"
export const GET_MEDICINE_SUMMARY_FAIL = "GET_MEDICINE_SUMMARY_FAIL"

export const getMedicineSummary = (patient_id) => ({
    type: GET_MEDICINE_SUMMARY,
    payload: {
        request: {
            method: "get",
            url: `/cdss/patients/${patient_id}/mkinetikos/prescriptions/week_summary/`,
        },
    },
});