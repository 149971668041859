export const GET_PATIENTS = "GET_PATIENTS";
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL";

export const CREATE_PATIENT = "CREATE_PATIENT";

export const GET_PATIENT_HOME = "GET_PATIENT_HOME";
export const GET_PATIENT_HOME_SUCCESS = "GET_PATIENT_HOME_SUCCESS";
export const GET_PATIENT_HOME_FAIL = "GET_PATIENT_HOME_FAIL";

export const GET_PATIENT_PERSONAL_DATA = "GET_PATIENT_PERSONAL_DATA";
export const GET_PATIENT_PERSONAL_DATA_SUCCESS =
  "GET_PATIENT_PERSONAL_DATA_SUCCESS";
export const GET_PATIENT_PERSONAL_DATA_FAIL = "GET_PATIENT_PERSONAL_DATA_FAIL";

export const GET_PATIENT_CONTACT_INFO = "GET_PATIENT_CONTACT_INFO";
export const GET_PATIENT_CONTACT_INFO_SUCCESS =
  "GET_PATIENT_CONTACT_INFO_SUCCESS";
export const GET_PATIENT_CONTACT_INFO_FAIL = "GET_PATIENT_CONTACT_INFO_FAIL";

export const GET_PATIENT_CLINICAL_DATA = "GET_PATIENT_CLINICAL_DATA";
export const GET_PATIENT_CLINICAL_DATA_SUCCESS =
  "GET_PATIENT_CLINICAL_DATA_SUCCESS";
export const GET_PATIENT_CLINICAL_DATA_FAIL = "GET_PATIENT_CLINICAL_DATA_FAIL";

export const GET_PATIENT_IDENTIFICATION = "GET_PATIENT_IDENTIFICATION";
export const GET_PATIENT_IDENTIFICATION_SUCCESS =
  "GET_PATIENT_IDENTIFICATION_SUCCESS";
export const GET_PATIENT_IDENTIFICATION_FAIL =
  "GET_PATIENT_IDENTIFICATION_FAIL";

export const EDIT_PATIENT_PERSONAL_DATA = "EDIT_PATIENT_PERSONAL_DATA";
export const EDIT_PATIENT_CONTACT_INFO = "EDIT_PATIENT_CONTACT_INFO";
export const EDIT_PATIENT_CLINICAL_DATA = "EDIT_PATIENT_CLINICAL_DATA";
export const EDIT_PATIENT_IDENTIFICATION = "EDIT_PATIENT_IDENTIFICATION";

export const GET_PATIENT_EVALUATIONS = "GET_PATIENT_EVALUATIONS";
export const GET_PATIENT_EVALUATIONS_SUCCESS =
  "GET_PATIENT_EVALUATIONS_SUCCESS";
export const GET_PATIENT_EVALUATIONS_FAIL = "GET_PATIENT_EVALUATIONS_FAIL";

export const GET_PATIENT_EVALUATION = "GET_PATIENT_EVALUATION";
export const GET_PATIENT_EVALUATION_SUCCESS = "GET_PATIENT_EVALUATION_SUCCESS";
export const GET_PATIENT_EVALUATION_FAIL = "GET_PATIENT_EVALUATION_FAIL";

export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";

export const DELETE_PATIENT_EVALUATION = "DELETE_PATIENT_EVALUATION";
export const DELETE_PATIENT_EVALUATION_SUCCESS =
  "DELETE_PATIENT_EVALUATION_SUCCESS";

export const CREATE_PATIENT_EVALUATION = "CREATE_PATIENT_EVALUATION";
export const EDIT_PATIENT_EVALUATION = "EDIT_PATIENT_EVALUATION";

export const GET_PATIENT_CONSENT_PDF = "GET_PATIENT_CONSENT_PDF";

export const GET_PATIENT_TESTS = "GET_PATIENT_TESTS";
export const GET_PATIENT_TESTS_SUCCESS = "GET_PATIENT_TESTS_SUCCESS";
export const GET_PATIENT_TESTS_FAIL = "GET_PATIENT_TESTS_FAIL";

export const GET_PATIENT_TEST_DETAILS = "GET_PATIENT_TEST_DETAILS";
export const GET_PATIENT_TEST_DETAILS_SUCCESS =
  "GET_PATIENT_TEST_DETAILS_SUCCESS";
export const GET_PATIENT_TEST_DETAILS_FAIL = "GET_PATIENT_TEST_DETAILS_FAIL";

export const GET_PATIENT_SURVEYS = "GET_PATIENT_SURVEYS";
export const GET_PATIENT_SURVEYS_SUCCESS = "GET_PATIENT_SURVEYS_SUCCESS";
export const GET_PATIENT_SURVEYS_FAIL = "GET_PATIENT_SURVEYS_FAIL";

export const GET_PATIENT_SURVEY_DETAILS = "GET_PATIENT_SURVEY_DETAILS";
export const GET_PATIENT_SURVEY_DETAILS_SUCCESS =
  "GET_PATIENT_SURVEY_DETAILS_SUCCESS";
export const GET_PATIENT_SURVEY_DETAILS_FAIL =
  "GET_PATIENT_SURVEY_DETAILS_FAIL";

export const GET_PATIENT_CHATS_LIST = "GET_PATIENT_CHATS_LIST";
export const GET_PATIENT_CHATS_LIST_SUCCESS = "GET_PATIENT_CHATS_LIST_SUCCESS";
export const GET_PATIENT_CHATS_LIST_FAIL = "GET_PATIENT_CHATS_LIST_FAIL";

export const GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS =
  "GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS";
export const GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_SUCCESS =
  "GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_SUCCESS";
export const GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_FAIL =
  "GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS_FAIL";

export const GET_PATIENT_CHAT = "GET_PATIENT_CHAT";
export const GET_PATIENT_CHAT_SUCCESS = "GET_PATIENT_CHAT_SUCCESS";
export const GET_PATIENT_CHAT_FAIL = "GET_PATIENT_CHAT_FAIL";

export const CREATE_PATIENT_CHAT = "CREATE_PATIENT_CHAT";

export const ADD_PATIENT_CHAT_PARTICIPANTS = "ADD_PATIENT_CHAT_PARTICIPANTS";

export const ADD_PATIENT_CHAT_MESSAGE = "ADD_PATIENT_CHAT_MESSAGE";

export const CREATE_PATIENT_MKINETIKOS_CONNECTION =
  "CREATE_PATIENT_MKINETIKOS_CONNECTION";

export const DELETE_PATIENT_MKINETIKOS_CONNECTION =
  "DELETE_PATIENT_MKINETIKOS_CONNECTION";

export const GET_PATIENT_EVALUATION_REPORT_DETAILS =
  "GET_PATIENT_EVALUATION_REPORT_DETAILS";

export const GET_PATIENT_EVALUATION_REPORT_DETAILS_SUCCESS =
  "GET_PATIENT_EVALUATION_REPORT_DETAILS_SUCCESS";

export const GET_PATIENT_EVALUATION_REPORT_DETAILS_FAIL =
  "GET_PATIENT_EVALUATION_REPORT_DETAILS_FAIL";

export const GET_PATIENT_REPORT_CARD = "GET_PATIENT_REPORT_CARD";

export const GET_PATIENT_REPORT_CARD_SUCCESS =
  "GET_PATIENT_REPORT_CARD_SUCCESS";

export const GET_PATIENT_REPORT_CARD_FAIL = "GET_PATIENT_REPORT_CARD_FAIL";

export const POST_COMMENT_TO_REPORT = "POST_COMMENT_TO_REPORT";

export const PUT_PATIENT_CONSENT_CHECK = "PUT_PATIENT_CONSENT_CHECK";
export const PUT_PATIENT_CONSENT_CHECK_SUCCESS =
  "PUT_PATIENT_CONSENT_CHECK_SUCCESS";

export const GET_PATIENT_INSTITUTIONS = "GET_PATIENT_INSTITUTIONS";
export const GET_PATIENT_INSTITUTIONS_SUCCESS =
  "GET_PATIENT_INSTITUTIONS_SUCCESS";
export const GET_PATIENT_INSTITUTIONS_FAIL = "GET_PATIENT_INSTITUTIONS_FAIL";

export const getPatients = ({
  page = 1,
  q = undefined,
  order_by = "",
} = {}) => ({
  type: GET_PATIENTS,
  payload: {
    request: {
      method: "get",
      url: "/cdss/homepage/patient_list",
      params: { page, q, order_by },
    },
  },
});

export const createPatient = (data, commit) => ({
  type: CREATE_PATIENT,
  payload: {
    request: {
      method: "post",
      url: "/cdss/patient/",
      data: { commit, ...data },
    },
  },
});

export const deletePatient = (patient_id) => ({
  type: DELETE_PATIENT,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/patient/${patient_id}`,
    },
  },
});

export const getPatientConsentPdf = (data) => ({
  type: GET_PATIENT_CONSENT_PDF,
  payload: {
    request: {
      method: "post",
      url: "cdss/patient/consent/",
      data,
    },
  },
});

export const getPatientHome = (patient_id) => ({
  type: GET_PATIENT_HOME,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/home/`,
    },
  },
});

export const getPatientPersonalData = (patient_id) => ({
  type: GET_PATIENT_PERSONAL_DATA,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/personal_info/`,
    },
  },
});

export const getPatientContactInfo = (patient_id) => ({
  type: GET_PATIENT_CONTACT_INFO,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/contact_info/`,
    },
  },
});

export const getPatientClinicalData = (patient_id) => ({
  type: GET_PATIENT_CLINICAL_DATA,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/clinical_data/`,
    },
  },
});

export const getPatientIdentification = (patient_id) => ({
  type: GET_PATIENT_IDENTIFICATION,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/identification/`,
    },
  },
});

export const editPatientPersonalData = (patient_id, data) => ({
  type: EDIT_PATIENT_PERSONAL_DATA,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/${patient_id}/personal_info/`,
      data,
    },
  },
});

export const editPatientContactInfo = (patient_id, data) => ({
  type: EDIT_PATIENT_CONTACT_INFO,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/${patient_id}/contact_info/`,
      data,
    },
  },
});

export const editPatientClinicalData = (patient_id, data) => ({
  type: EDIT_PATIENT_CLINICAL_DATA,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/${patient_id}/clinical_data/`,
      data,
    },
  },
});

export const editPatientIdentification = (patient_id, data) => ({
  type: EDIT_PATIENT_IDENTIFICATION,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/${patient_id}/identification/`,
      data,
    },
  },
});

export const getPatientEvaluations = (
  patient_id,
  { page = 1, q = undefined, order_by = "date", items_per_page = 10 } = {}
) => ({
  type: GET_PATIENT_EVALUATIONS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/evaluations/`,
      params: { page, q, order_by, items_per_page },
    },
  },
});

export const getPatientEvaluation = (patient_id, evaluation_id) => ({
  type: GET_PATIENT_EVALUATION,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/details/?summary_status=true`,
    },
  },
});

export const deletePatientEvaluation = (patient_id, evaluation_id) => ({
  type: DELETE_PATIENT_EVALUATION,
  payload: {
    request: {
      method: "delete",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/`,
    },
    evaluation_id: evaluation_id,
  },
});

export const createPatientEvaluation = (patient_id, data) => ({
  type: CREATE_PATIENT_EVALUATION,
  payload: {
    request: {
      method: "post",
      url: `cdss/patient/${patient_id}/evaluations/`,
      data,
    },
  },
});

export const editPatientEvaluation = (patient_id, evaluation_id, data) => ({
  type: EDIT_PATIENT_EVALUATION,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/`,
      data,
    },
  },
});

export const getPatientChatsList = (patient_id, { signal = undefined }) => ({
  type: GET_PATIENT_CHATS_LIST,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/`,
      signal: signal,
    },
  },
});

export const getPatientAvailableChatParticipants = (
  patient_id,
  { q = undefined }
) => ({
  type: GET_PATIENT_AVAILABLE_CHAT_PARTICIPANTS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/available_chat_participants/`,
      params: { q },
    },
  },
});

export const getPatientChat = (
  patient_id,
  chat_id,
  { update = false, signal = undefined }
) => ({
  type: GET_PATIENT_CHAT,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/${chat_id}/`,
      params: { update },
      signal: signal,
    },
  },
});

export const createPatientChat = (patient_id, participants_id_list = []) => ({
  type: CREATE_PATIENT_CHAT,
  payload: {
    request: {
      method: "post",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/`,
      data: { participant_id_list: participants_id_list },
    },
  },
});

export const addPatientChatParticipant = (
  patient_id,
  chat_id,
  participants_id_list = []
) => ({
  type: ADD_PATIENT_CHAT_PARTICIPANTS,
  payload: {
    request: {
      method: "put",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/${chat_id}/`,
      data: { participant_id_list: participants_id_list },
    },
  },
});

export const addPatientChatMessage = (patient_id, chat_id, text = "") => ({
  type: ADD_PATIENT_CHAT_MESSAGE,
  payload: {
    request: {
      method: "post",
      url: `cdss/patients/${patient_id}/mkinetikos/chats/${chat_id}/`,
      data: { text },
    },
  },
});

export const createPatientMkinetikosConnection = (
  patient_id,
  connection_key = "",
  email = ""
) => ({
  type: CREATE_PATIENT_MKINETIKOS_CONNECTION,
  payload: {
    request: {
      method: "post",
      url: `cdss/patients/${patient_id}/mkinetikos/connections/`,
      data: connection_key !== "" ? { connection_key } : { email },
    },
  },
});

export const deletePatientMkinetikosConnection = (patient_id) => ({
  type: DELETE_PATIENT_MKINETIKOS_CONNECTION,
  payload: {
    request: {
      method: "delete",
      url: `cdss/patients/${patient_id}/mkinetikos/connections/`,
    },
  },
});

export const getPatientEvaluationReportDetails = (
  patient_id,
  evaluation_id
) => ({
  type: GET_PATIENT_EVALUATION_REPORT_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/`,
    },
  },
});

export const getPatientEvaluatioCardDetails = (
  patient_id,
  evaluation_id,
  card_id,
  lang_id = 1
  // url = `cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/card/${card_id}/`
) => ({
  type: GET_PATIENT_REPORT_CARD,
  payload: {
    request: {
      method: "get",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/cards/${card_id}?lang_id=${lang_id}`,
    },
  },
});

export const saveCommentToReport = (
  patient_id,
  evaluation_id,
  card_id,
  comment_text
) => ({
  type: POST_COMMENT_TO_REPORT,
  payload: {
    request: {
      method: "post",
      url: `cdss/patient/${patient_id}/evaluations/${evaluation_id}/report/cards/${card_id}/notes/`,
      data: {
        comment_text,
      },
    },
  },
});

export const setConsentCheck = (patient_id) => ({
  type: PUT_PATIENT_CONSENT_CHECK,
  payload: {
    request: {
      method: "put",
      url: `cdss/patient/consent_check/${patient_id}`,
    },
  },
});

export const getPatientInstitutions = (patient_id) => ({
  type: GET_PATIENT_INSTITUTIONS,
  payload: {
    request: {
      method: "GET",
      url: `cdss/patients/${patient_id}/institutions/`,
    },
  },
});
