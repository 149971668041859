//* *********************************** REACT IMPORTS ************************************
import { useState, useEffect } from "react";
//* ********************************* EXTERNAL PACKAGES **********************************
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

import FingerTapping from "./partials/sliders/fingerTapping";
import BradykinesiaSlider from "./partials/sliders/bradykinesia";
import TugSlider from "./partials/sliders/tug";
import RiskOfFallSlider from "./partials/sliders/riskOfFall";
import MedicationAdherenceSlider from "./partials/sliders/medicationAdherence";
import PatientFollow from "./partials/patientFollow";
import PatientCustomization from "./partials/patientCustomization";
import MessageModal from "../../../components/messageModal";

//* ************************************** GLOBALS ***************************************
import {
  getRiskAssessment,
  updateRiskAssessment,
} from "../../../redux/actions/riskAlert";
import { errors } from "../../../constants/general";
import NavBar from "../../../components/navbar";

const RiskAlert = () => {
  //* ************************************** SETUP **************************************
  const dispatch = useDispatch();

  const notificationsData = useSelector(
    (state) => state.riskAlert.global_settings
  );

  const [isLoading, setIsLoading] = useState(false);

  const [enabledRiskAlert, setEnabledRiskAlert] = useState(
    notificationsData.notification_preferences.receive_notifications
  );
  const [thresholds, setThresholds] = useState(notificationsData.thresholds);
  const [selectedEvents, setSelectedEvents] = useState(
    notificationsData.events
  );

  const [openCancelChangesModal, setOpenCancelChangesModal] = useState(false);
  const [openRiskAlertModal, setOpenRiskAlertModal] = useState(false);
  const [isFollowPatientOpen, setIsFollowPatientOpen] = useState(false);
  const [selectedCustomizePatientId, setSelectedCustomizePatientOpen] =
    useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setEnabledRiskAlert(
      notificationsData.notification_preferences.receive_notifications
    );
    setThresholds(notificationsData.thresholds);
    setSelectedEvents(notificationsData.events);
  }, [
    notificationsData.notification_preferences,
    notificationsData.events,
    notificationsData.thresholds,
  ]);

  useEffect(() => {
    dispatch(getRiskAssessment())
      .catch(() => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  }, []);

  //* ************************************** HANDLERS **************************************

  const updateRiskAlertSettings = () => {
    setIsLoading(true);

    const newALertsList = selectedEvents.alerts
      .filter((alert) => alert.is_selected && alert)
      .map((newAlert) => newAlert.id);

    const newSymptomsList = selectedEvents.symptoms
      .filter((symptom) => symptom.is_selected && symptom)
      .map((newSymptom) => newSymptom.id);

    const data = {
      receive_notifications: enabledRiskAlert,
      ...thresholds,
      alerts: newALertsList,
      symptoms: newSymptomsList,
    };

    dispatch(updateRiskAssessment(data))
      .then(() =>
        dispatch(getRiskAssessment()).catch(() => alert(errors.service_error))
      )
      .catch(() => alert(errors.service_error))
      .finally(() => setIsLoading(false));
  };

  const thresholdsInputHandler = (name, value) => {
    setThresholds({ ...thresholds, [name]: value });
  };

  const alertsHandler = (id) => {
    const newList = selectedEvents.alerts.map((alert) =>
      alert.id === id ? { ...alert, is_selected: !alert.is_selected } : alert
    );
    setSelectedEvents({ ...selectedEvents, alerts: newList });
  };

  const symptomsHandler = (id) => {
    const newList = selectedEvents.symptoms.map((symptom) =>
      symptom.id === id
        ? { ...symptom, is_selected: !symptom.is_selected }
        : symptom
    );
    setSelectedEvents({ ...selectedEvents, symptoms: newList });
  };

  const changeRiskAlertValue = () => {
    if (enabledRiskAlert) {
      setOpenRiskAlertModal(true);
    }
    setEnabledRiskAlert(!enabledRiskAlert);
  };

  const cancelChanges = () => {
    setThresholds(notificationsData.thresholds);
    setEnabledRiskAlert(
      notificationsData.notification_preferences.receive_notifications
    );
    setSelectedEvents(notificationsData.events);
  };

  const whereChangesmade =
    JSON.stringify(notificationsData.thresholds) !==
      JSON.stringify(thresholds) ||
    notificationsData.notification_preferences.receive_notifications !==
      enabledRiskAlert ||
    JSON.stringify(notificationsData.events) !== JSON.stringify(selectedEvents);

  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.risk_alert")} />
      <div className="profile-mandatory-fields-wrapper">
        <span className="regular-text profile-mandatory-fields-wrapper">
          {t("my_area.risk.risk_alert_is")}
        </span>
      </div>

      <div className="mt-4">
        <div className="medium-semibold-text black-color">
          {t("my_area.risk.how_it_works")}
        </div>
        <div className="regular-text mt-2">
          {t("my_area.risk.you_can_define")}
        </div>
      </div>

      <div className="horizontal-line mt-4"></div>

      <div className="mt-5">
        <div className="row medium-semibold-text black-color">
          <div className="col">{t("my_area.risk.enable")}</div>
          <div className="col flex-d-row align-items-center justify-content-end">
            <button
              onClick={() => setOpenCancelChangesModal(true)}
              disabled={isLoading || !whereChangesmade}
              className="transparent-button small-wider-button mr-1"
            >
              <u>{t("buttons.cancel_changes")}</u>
            </button>
            <button
              onClick={updateRiskAlertSettings}
              disabled={isLoading || !whereChangesmade}
              className="secondary-button small-wider-button"
            >
              {t("buttons.save")}
            </button>
          </div>
        </div>
        <div className="regular-text mt-2 flex-d-row align-items-center">
          <span className="mr-1">{t("no")}</span>
          <div className="form-check form-switch">
            <input
              disabled={isLoading}
              onChange={changeRiskAlertValue}
              className="form-check-input shadow-none"
              checked={enabledRiskAlert}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
            />
          </div>
          <span className="ml-1">{t("yes")}</span>
        </div>
      </div>
      <div className={`${!enabledRiskAlert ? "disabled-container" : ""}`}>
        <div className="mt-6">
          <div className="medium-semibold-text black-color">
            {t("my_area.risk.follow_customize")}
          </div>

          <div className="profile-notifications-box mt-2 w-100">
            <div className="row align-items-center">
              <div className="col regular-text">
                {t("my_area.risk.here_you_can")}
              </div>
              <div className="col-auto">
                {isFollowPatientOpen ? (
                  <button
                    disabled={!enabledRiskAlert}
                    onClick={() => setIsFollowPatientOpen(false)}
                    className="quaternary-button small-button"
                  >
                    {t("buttons.close")}
                  </button>
                ) : (
                  <button
                    disabled={!enabledRiskAlert}
                    onClick={() => setIsFollowPatientOpen(true)}
                    className="primary-button small-button"
                  >
                    {t("buttons.open")}
                  </button>
                )}
              </div>
            </div>
          </div>
          {isFollowPatientOpen && (
            <PatientFollow
              setSelectedCustomizePatientOpen={(id) =>
                setSelectedCustomizePatientOpen(id)
              }
            />
          )}
        </div>

        <div className="mt-6">
          <div className="medium-semibold-text black-color">
            {t("my_area.risk.finger_tapping")}
          </div>

          <div className="profile-notifications-box mt-2">
            <div className="regular-text">
              {t("my_area.risk.finger_tapping_explanation")}
              <a
                target="_blank"
                href="https://www.nature.com/articles/s41746-019-0214-x"
                className="link-text"
              >
                {t("my_area.risk.here")}
              </a>
            </div>
            <FingerTapping
              disabled={isLoading || !enabledRiskAlert}
              onChange={thresholdsInputHandler}
              value={thresholds.mds_fingertapping_th}
            />
          </div>
          <div className="mt-6">
            <div className="medium-semibold-text black-color">
              {t("my_area.risk.brady")}
            </div>
            <div className="profile-notifications-box mt-2 ">
              <div className="regular-text">
                {t("my_area.risk.brady_explanation")}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.nature.com/articles/s41746-019-0214-x"
                  className="link-text"
                >
                  {t("my_area.risk.here")}
                </a>
              </div>
              <div className="flex-d-row mt-4 mb-4">
                <BradykinesiaSlider
                  disabled={isLoading || !enabledRiskAlert}
                  onChange={thresholdsInputHandler}
                  value={thresholds.bradikinesia_th}
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="medium-semibold-text black-color">
              {t("my_area.risk.tug")}
            </div>
            <div className="profile-notifications-box mt-2">
              <div className="flex-d-row mt-4 mb-4">
                <TugSlider
                  disabled={isLoading || !enabledRiskAlert}
                  onChange={thresholdsInputHandler}
                  value={thresholds.tug_th}
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="medium-semibold-text black-color">
              {t("my_area.risk.risk_fall")}
            </div>

            <div className="profile-notifications-box mt-2">
              <div className="flex-d-row mt-4 mb-4">
                <RiskOfFallSlider
                  disabled={isLoading || !enabledRiskAlert}
                  onChange={thresholdsInputHandler}
                  value={thresholds.risk_of_fall_th}
                />
              </div>
            </div>

            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.adherence")}
              </div>
              <div className="profile-notifications-box mt-2">
                <div className="flex-d-row mt-4 mb-4">
                  <MedicationAdherenceSlider
                    disabled={isLoading || !enabledRiskAlert}
                    onChange={thresholdsInputHandler}
                    value={thresholds.medication_adherence_th}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.episodes")}
              </div>
              <div className="profile-notifications-box mt-2">
                <div className="regular-text">
                  {t("my_area.risk.episodes_explanation")}
                </div>
                <div style={{ flexWrap: "wrap" }} className="flex-d-row mt-4">
                  {selectedEvents.alerts.map((episode, index) => (
                    <div
                      key={episode.name + index.toString()}
                      className="check-box-wrapper mr-4"
                    >
                      <div className="input-wrapper">
                        <input
                          disabled={isLoading || !enabledRiskAlert}
                          name={episode.name}
                          checked={episode.is_selected}
                          onChange={() => alertsHandler(episode.id)}
                          type={"checkbox"}
                        />
                      </div>
                      <label htmlFor={episode.name} type="checkbox">
                        <span className="regular-text">
                          {t("my_area.risk." + episode.name)}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="medium-semibold-text black-color">
                {t("my_area.risk.symptoms")}
              </div>
              <div className="profile-notifications-box mt-2">
                <div className="regular-text">
                  {t("my_area.risk.episodes_explanation")}
                </div>

                <div className="regular-text black-color mt-4">
                  {t("my_area.risk.symptoms_occurence")}
                </div>

                <div className="profile-notifications-input-group-plus-minus mt-2">
                  <input
                    disabled={isLoading || !enabledRiskAlert}
                    type="button"
                    value="-"
                    onClick={(e) => {
                      if (parseInt(thresholds.events_count_th) > 0) {
                        setThresholds({
                          ...thresholds,
                          events_count_th:
                            parseInt(thresholds.events_count_th) - 1,
                        });
                      }
                    }}
                    className="button-minus plus-minus"
                    data-field="quantity"
                  />
                  <input
                    style={{ backgroundColor: "white" }}
                    disabled={true}
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    value={thresholds.events_count_th}
                    name="events_count_th"
                    className="quantity-field text-center"
                  />
                  <input
                    disabled={isLoading || !enabledRiskAlert}
                    onClick={() =>
                      setThresholds({
                        ...thresholds,
                        events_count_th:
                          parseInt(thresholds.events_count_th) + 1,
                      })
                    }
                    type="button"
                    value="+"
                    className="button-plus  plus-minus"
                    data-field="quantity"
                  />
                </div>
                <div className="regular-text black-color mt-4">
                  {t("my_area.risk.symptoms")}
                </div>
                <div style={{ flexWrap: "wrap" }} className="flex-d-row mt-2">
                  {selectedEvents.symptoms.map((symptom, index) => (
                    <div
                      key={symptom.name + index.toString()}
                      className="check-box-wrapper mr-4"
                    >
                      <div className="input-wrapper col-md-auto">
                        <input
                          disabled={isLoading || !enabledRiskAlert}
                          name={symptom.name}
                          checked={symptom.is_selected}
                          onChange={() => symptomsHandler(symptom.id)}
                          type={"checkbox"}
                        />
                      </div>
                      <label htmlFor={symptom.name} type="checkbox">
                        <span className="regular-text">
                          {t("my_area.risk.symptoms_list." + symptom.name)}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openRiskAlertModal && (
        <MessageModal
          headerText={t("modals.disable_alert_header")}
          body={t("modals.disable_alert_body")}
          footerButtons={[
            <button
              onClick={() => {
                setEnabledRiskAlert(!enabledRiskAlert);
                setOpenRiskAlertModal(false);
              }}
              className="transparent-button small-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={() => {
                setOpenRiskAlertModal(false);
                setIsFollowPatientOpen(false);
              }}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {selectedCustomizePatientId && (
        <PatientCustomization
          close={() => setSelectedCustomizePatientOpen(null)}
          patientId={selectedCustomizePatientId}
        />
      )}
      {openCancelChangesModal && (
        <MessageModal
          headerText={t("modals.cancel_changes_header")}
          body={t("modals.cancel_changes_body")}
          footerButtons={[
            <button
              onClick={() => {
                setOpenCancelChangesModal(false);
              }}
              className="transparent-button small-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={() => {
                cancelChanges();
                setOpenCancelChangesModal(false);
              }}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default RiskAlert;
