export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const GET_FREQ_LIST = "GET_FREQ_LIST";
export const GET_FREQ_LIST_SUCCESS = "GET_FREQ_LIST_SUCCESS";
export const GET_FREQ_LIST_FAIL = "GET_FREQ_LIST_FAIL";

export const getEvents = (patient_id) => ({
  type: GET_EVENTS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/events/week_summary`,
    },
  },
});

export const getFreqList = (patient_id) => ({
  type: GET_FREQ_LIST,
  payload: {
    request: {
      method: "get",
      url: `/cdss/patients/${patient_id}/mkinetikos/events/events_freq_list`,
    },
  },
});
