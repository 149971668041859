/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import { useState, useEffect, memo } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../../components/spinner";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import ResultPdfTemplate from "pdfTemplates/testAndSurveysResult/resultPdfTemplate";
import DownloadPdfButton from "components/downloadPdfButton";

//* ************************************** HOOKS *****************************************
import { usePdf } from "hooks/usePdf";

//* ************************************** REDUX *****************************************
import { getPatientTestDetails } from "../../../../redux/actions/patientsPrograms";

//* ************************************** GLOBALS ***************************************
import helpIcon from "../../../../assets/images/help.svg";
import { parseDateFromDatetime } from "../../../../utils/dataAndTime";

const TestResultsModule = ({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { patient_id } = useParams("patient_id");
  const { test_id } = useParams("test_id");

  const { t, i18n } = useTranslation();

  const test = useSelector((state) => state.patientTestDetails);

  const patientInfo = useSelector((state) => state.patientHome);
  const patientEmail = useSelector((state) => state.patientProfile.email);
  const fullName = useSelector((state) => state.user.personal_data.full_name);

  const patientInfoForPdf = {
    name: patientInfo.name,
    mkinetikos_photo: patientInfo.mkinetikos_photo,
    email: patientEmail,
  };

  const pdfFileName = `${patientInfoForPdf.name} (${test.name}) - ${parseDateFromDatetime(
    test.submission_date
  )}`;

  const { isPdfLoading, pdfDownloadUrl, pdfUpdateInstance } = usePdf(
    <ResultPdfTemplate
      patientInfo={patientInfoForPdf}
      data={test}
      option={"test"}
      downloadedBy={{
        fullName: fullName,
        date: new Date(),
      }}
    />
  );

  useEffect(() => {
    pdfUpdateInstance();
  }, [patientInfo, test]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getPatientTestDetails(patient_id, test_id))
      .then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => setIsLoading(false));
  }, []);

  //* ************************************** HANDLERS **************************************

  return isLoading ? (
    <Spinner type={"big-blue"} />
  ) : (
    <>
      <div className="tests-module-wrapper">
        <div className="tests-module-container">
          <div className="test-info row-space-between stretch-align">
            <div className="flex-d-one">
              <div className="row list-label tests-list-labels-wrapper">
                <div className="col-5">
                  <div>{t("patient.programs.program_name")}</div>
                </div>
                <div className="col-2">
                  <div>{t("patient.programs.submission_date")}</div>
                </div>
                <div className="col">
                  <div>{t("patient.programs.results")}</div>
                </div>
              </div>
              <div className="row test-info-wrapper">
                <div className="col-5 regular-text black-color align-items-center text-truncate">
                  {test.name}
                </div>
                <div className="col-2 tags-wrapper regular-text black-color">
                  {parseDateFromDatetime(test.submission_date)}
                </div>
                <div className="col regular-text black-color">
                  {test.result !== null && test.result !== undefined
                    ? test.result /* .toFixed(2) */
                    : "-----"}
                </div>
              </div>
            </div>
            <div>
              <div className="col">
                <DownloadPdfButton
                  pdfFileName={pdfFileName}
                  pdfDownloadUrl={pdfDownloadUrl}
                  isPdfLoading={isPdfLoading}
                  isDisabled={test?.result_metrics?.length === 0}
                />
              </div>
            </div>
          </div>
          <div className="row-space-between">
            <span className="small-title">{t("patient.programs.results")}</span>
          </div>
          <div className="tests-list">
            <div className="row list-label tests-list-labels-wrapper">
              <div className="col-5">
                <div>{t("patient.programs.metric")}</div>
              </div>
              <div className="col-2">
                <div>{t("patient.programs.value")}</div>
              </div>
              <div className="col">
                <div>{t("patient.programs.std")}</div>
              </div>
            </div>
            {test.result_metrics.map((metric, index) => (
              <div key={metric.id + index.toString()} className="row test-info-wrapper">
                <div className="col-5 d-flex regular-text black-color align-items-start overflow-visible">
                  {metric.metric}
                  {metric.help_text && metric.help_text !== "" && (
                    <>
                      <img
                        alt="Help"
                        data-tip={metric.help_text}
                        data-for={`metric-${index}`}
                        src={helpIcon}
                        className="help-img"
                      />
                      <ReactTooltip
                        className="tooltip"
                        id={`metric-${index}`}
                        place="right"
                        effect="solid"
                      />
                    </>
                  )}
                </div>
                <div className="col-2 tags-wrapper regular-text black-color">
                  {Array.isArray(metric.value) ? (
                    <span>
                      {metric.value.map((value, index) =>
                        metric.value.length - 1 === index
                          ? `{${Number(value).toFixed(2)}} `
                          : `{${Number(value).toFixed(2)}}, `
                      )}
                    </span>
                  ) : metric.value !== null && metric.value !== undefined ? (
                    Number(metric.value).toFixed(2)
                  ) : (
                    "-----"
                  )}{" "}
                  {metric.units ? metric.units : ""}
                </div>
                <div className="col regular-text black-color">
                  {metric.std ? Number(metric.std).toFixed(2) : "-----"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TestResultsModule);
