import { useEffect } from "react";
import debounce from "lodash.debounce";

/**
 * This hook acts as a delayed useEffect that only calls after a set time has passed.
 * @param {callbackFunction}: The function to be run once dependencies change
 * @param {deps}: Hook dependencies that will trigger a new debounce, cancelling previous queued ones
 * @param {delay}: Debounce delay in ms
 * @param {options}: Additional options to pass to the lodash debounce method
 *
 * EXAMPLE
 *
 * Increments a counter 0.5 seconds after
 *
 * const [counter, setCounter] = useState(0)
 * const incrementCounter = () => setCounter(counter +1)
 * useDebounce(incrementCounter, [], 500)
 *
 */

export const useDebounce = (callbackFunction, deps, delay, options = null) => {
  const debouncedFunction = debounce(callbackFunction, delay, options);

  useEffect(() => {
    debouncedFunction();
    return () => debouncedFunction.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};
