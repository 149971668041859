//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState, useRef } from "react";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************
import { createTicketReply, getTicketInfo } from "redux/actions/tickets";
import { getTicketReplies } from "redux/actions/tickets";
import Accordion from "components/accordion";
import ChatBox from "components/chatBox";
import Navbar from "components/navbar";
import Spinner from "components/spinner";
import Icon from "components/icon";
import { icons } from "components/icon/icons";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************
import { capitalizeFirstLetter } from "utils/string";
import {
  parseDateFromDatetime,
  transformDateToTodayOrYesterday,
} from "utils/dataAndTime";

//* ***************************************** ASSETS *****************************************
import supportTicketsIcon from "assets/images/buttonIcons/support-tickets.svg";

const SupportDetail = () => {
  //* **************************************** SETUP *****************************************
  const [isTicketInfoLoading, setIsTicketInfoLoading] = useState(true);
  const [isTicketReplyListLoading, setIsTicketReplyListLoading] =
    useState(true);
  const [isNewMessageLoading, setIsNewMessageLoading] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(0);

  const firstMessageRef = useRef(null);
  const lastMessageRef = useRef(null);

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { support_ticket_id } = useParams();

  const dispatch = useDispatch();

  const personal_data = useSelector((state) => state.user.widget_info);
  const ticketInfo = useSelector((state) => state.tickets.ticketInfo);
  const ticketReplies = useSelector((state) => state.tickets.ticketReplies);

  const messages = ticketReplies.obj_list;

  //* ************************************** PARTIALS ****************************************

  const _renderMessagesLabel = () => (
    <>
      {t("support.messages")}
      <span className="support-detail-accordion-title-gray">
        {" "}
        {`(${messages.length - 1})`}
      </span>
    </>
  );

  //* *************************************** RENDER *****************************************

  const goBack = () => {
    history.push(`/my_area/support`);
  };

  const goEdit = () => {
    history.push(`/my_area/support/ticket/${support_ticket_id}/edit`);
  };

  const getTicketInfoData = () => {
    setIsTicketInfoLoading(true);
    dispatch(getTicketInfo(support_ticket_id)).finally(() => {
      setIsTicketInfoLoading(false);
    });
  };

  const getTicketRepliesData = () => {
    setIsTicketReplyListLoading(true);
    dispatch(getTicketReplies(support_ticket_id)).finally(() => {
      setIsTicketReplyListLoading(false);
    });
  };

  const sendMessage = (message) => {
    setIsNewMessageLoading(true);
    dispatch(createTicketReply(support_ticket_id, message)).finally(() => {
      setNewMessage(null);
      getTicketRepliesData();
      setIsNewMessageLoading(false);
    });
  };

  const onClickAccordionDetailsHandler = () => {
    if (expandedAccordionIndex === 0) {
      setExpandedAccordionIndex(null);
    } else {
      setExpandedAccordionIndex(0);
    }
  };

  const onClickAccordionMessagesHandler = () => {
    if (expandedAccordionIndex === 1) {
      setExpandedAccordionIndex(null);
    } else {
      setExpandedAccordionIndex(1);
    }
  };

  const setStatusLabelBackground = (state) => {
    return state === 1
      ? "primary-label"
      : state === 2
      ? "secondary-label"
      : state === 3
      ? "tertiary-label"
      : null;
  };

  const keyboardSupport = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      sendMessage(newMessage);
    }
  };

  const handleClickScroll = (direction, isSmooth) => {
    let element = null;

    if (direction === "down") {
      element = lastMessageRef.current;
    } else if (direction === "up") {
      element = firstMessageRef.current;
    }
    if (element) {
      element.scrollIntoView({ behavior: isSmooth && "smooth" });
    }
  };

  const handleVisibilityScrollButtons = () => {
    const scrollContainer = document.getElementById("accordion-content");
    const scrollableArea = document.getElementById("scrollable-area");

    if (scrollContainer) {
      scrollContainer.onscroll = function () {
        setScrollPercentage(
          scrollContainer.scrollTop /
            (scrollableArea.clientHeight - scrollContainer.offsetHeight)
        );
      };
    }
  };

  const checkIsScrollable = () => {
    const scrollContainer = document.getElementById("accordion-content");
    const scrollableArea = document.getElementById("scrollable-area");

    const isScrollable =
      scrollableArea.clientHeight > scrollContainer.offsetHeight;
    setIsScrollable(isScrollable);
  };

  const getTranslatedSendDate = (string) => {
    if (string === "Today" || string === "Yesterday") {
      {
        return t(string.toLowerCase());
      }
    } else {
      return string;
    }
  };

  useEffect(() => {
    getTicketInfoData();
    getTicketRepliesData();
  }, []);

  useEffect(() => {
    messages.length > 1 && setExpandedAccordionIndex(1);
  }, []);

  useEffect(() => {
    expandedAccordionIndex === 1 &&
      setTimeout(() => {
        // Scrolls down after rendering the page
        handleClickScroll("down");
        checkIsScrollable();
        handleVisibilityScrollButtons();
      }, 0);
  }, [isTicketReplyListLoading, expandedAccordionIndex]);

  return (
    <div
      className="col profile-body"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Navbar />
      <div className="row support-detail-container">
        <div className="support-detail-header-container">
          <>
            <Icon
              icon={icons["back-icon"]}
              alt="arrow left"
              className="support-detail-goback-icon"
              onClick={goBack}
            />
            <h4 className="support-detail-header-title">
              {t("titles.support_ticket")}
            </h4>
          </>
          <button
            onClick={goEdit}
            className="secondary-button small-wider-button support-edit-submit-button"
          >
            {t("buttons.edit")}
          </button>
        </div>
        {isTicketInfoLoading || isTicketReplyListLoading ? (
          <div style={{ height: "100px" }}>
            <div className="loading-wrapper">
              <Spinner type={"big-blue"} />
            </div>
          </div>
        ) : ticketReplies.count !== 0 ? (
          <>
            <div className="support-detail-content-container">
              <div className="support-detail-details-container">
                <Accordion
                  label={t("support.details")}
                  onClick={onClickAccordionDetailsHandler}
                  expanded={expandedAccordionIndex === 0}
                >
                  <div className="col support-detail-item-list">
                    <div className="support-detail-item-container">
                      <p className="support-detail-item-title">
                        {t("support.subject")}
                      </p>
                      <p className="support-detail-item-value">
                        {capitalizeFirstLetter(ticketInfo.title)}
                      </p>
                    </div>
                    <div className="support-detail-item-container">
                      <p className="support-detail-item-title">
                        {t("support.status")}
                      </p>
                      <div
                        className={`label small-label primary-label rounded ${setStatusLabelBackground(
                          ticketInfo.support_state
                        )}`}
                      >
                        {ticketInfo.support_state === 1
                          ? t("labels.opened")
                          : ticketInfo.support_state === 2
                          ? t("labels.pending")
                          : ticketInfo.support_state === 3
                          ? t("labels.closed")
                          : t("labels.no_status")}
                      </div>
                    </div>
                    <div className="support-detail-item-container">
                      <p className="support-detail-item-title">
                        {t("support.describe_problem")}
                      </p>
                      <p className="support-detail-item-value">
                        {capitalizeFirstLetter(
                          messages[messages.length - 1].message
                        )}
                      </p>
                    </div>
                  </div>
                </Accordion>
              </div>
              <div className="support-detail-messages-container">
                <Accordion
                  onClick={onClickAccordionMessagesHandler}
                  label={_renderMessagesLabel()}
                  expanded={expandedAccordionIndex === 1}
                >
                  {isNewMessageLoading ? (
                    <div style={{ height: "100px" }}>
                      <div className="loading-wrapper">
                        <Spinner type={"big-blue"} />
                      </div>
                    </div>
                  ) : messages.length > 1 ? (
                    // Renders messages
                    <ul
                      className="support-detail-messages-list-container"
                      id="scrollable-area"
                    >
                      {messages
                        .slice(0, messages.length - 1)
                        .reverse()
                        .map((message, i) => {
                          return (
                            <li
                              className="support-detail-messages-list-item"
                              id={`Message: ${i}`}
                              key={`Message: ${i}`}
                              ref={
                                i === 0
                                  ? firstMessageRef
                                  : i === messages.length - 2
                                  ? lastMessageRef
                                  : undefined
                              }
                            >
                              {i === 1 && (
                                <button
                                  onClick={() =>
                                    handleClickScroll("down", true)
                                  }
                                  className="support-detail-scroll-down-button"
                                  style={{
                                    opacity: isScrollable
                                      ? 1 - scrollPercentage
                                      : 0,
                                  }}
                                >
                                  <Icon icon={icons["arrow-down"]} />
                                </button>
                              )}
                              {i === messages.length - 2 && (
                                <button
                                  onClick={() => handleClickScroll("up", true)}
                                  className="support-detail-scroll-up-button"
                                  style={{
                                    opacity: isScrollable
                                      ? scrollPercentage
                                      : 0,
                                  }}
                                >
                                  <Icon icon={icons["arrow-up"]} />
                                </button>
                              )}
                              {message.staff_name ? (
                                // Renders message sent by Kinetikos
                                <ChatBox
                                  message={message.message}
                                  photo={supportTicketsIcon}
                                  sendDate={getTranslatedSendDate(
                                    transformDateToTodayOrYesterday(
                                      parseDateFromDatetime(message.send_date)
                                    )
                                  )}
                                />
                              ) : (
                                // Renders message sent by platform user
                                <ChatBox
                                  message={message.message}
                                  photo={personal_data.photo}
                                  sendDate={getTranslatedSendDate(
                                    transformDateToTodayOrYesterday(
                                      parseDateFromDatetime(message.send_date)
                                    )
                                  )}
                                  rightAligned
                                />
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    // Renders 'start message' button when there are no messages yet
                    <div className="support-detail-nomessages-container">
                      <div className="support-detail-nomessages-top">
                        <p className="support-detail-nomessages-title">
                          {t("support.no_messages")}
                        </p>
                        {showCancelButton ? (
                          <button
                            onClick={() => setShowCancelButton(false)}
                            type="button"
                            className="transparent-button"
                          >
                            {t("buttons.cancel")}
                          </button>
                        ) : (
                          <button
                            onClick={() => setShowCancelButton(true)}
                            className="primary-button small-wider-button min-content"
                          >
                            {t("buttons.write_message")}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
            <div className="support-detail-reply-container">
              {
                // If there are no messages
                ((!(messages.length > 1) &&
                  expandedAccordionIndex === 1 &&
                  showCancelButton) ||
                  // If there are messages
                  (messages.length > 1 && expandedAccordionIndex === 1)) && (
                  <>
                    <input
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      type="text"
                      placeholder={t("support.reply_support")}
                      className="form-input support-detail-reply-input"
                      onKeyDown={newMessage && keyboardSupport}
                      disabled={isNewMessageLoading ? true : false}
                    />
                    <Icon
                      icon={icons["send-icon"]}
                      alt="send-icon"
                      className="support-detail-send-icon"
                      onClick={() => newMessage && sendMessage(newMessage)}
                    />
                  </>
                )
              }
            </div>
          </>
        ) : (
          <div className="flex-d-column no-data-text">
            <span>{t("support.no_ticket_information")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportDetail;
