import { generateAndroidAssetLinks } from "utils/appLinks";

const AssetLinksHandler = () => {
  const headers = {
    "Content-Type": "application/json",
  };

  return (
    <pre
      style={{
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        fontFamily: "monospace",
      }}
      headers={headers}
    >
      {generateAndroidAssetLinks()}
    </pre>
  );
};

export default AssetLinksHandler;
