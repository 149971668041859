import {
  GET_PROTOCOLS_LIST_FAIL,
  GET_PROTOCOLS_LIST_SUCCESS,
} from "../actions/patientsPrograms";

const initialState = { tests: [], surveys: [] };

const patientProtocols = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROTOCOLS_LIST_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PROTOCOLS_LIST_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientProtocols;
