import ReactTooltip from "react-tooltip";

import helpIcon from "../../assets/images/help.svg";

const RadioButtons = ({
  question = "",
  options = [{ text: "", tip: "", disabled: false }],
  vertical = false,
  checkedIndex = null,
  onOptionClick = () => {},
}) => {
  return (
    <div className="radio-buttons-container">
      {question !== "" && <div className="rd-btn-question">{question}</div>}
      <div className={`rd-btn-options-wrapper ${vertical ? "vertical" : ""}`}>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={`rd-btn-option-container ${
                option.disabled ? "disabled" : ""
              } ${checkedIndex === index ? "checked" : ""}`}
              onClick={() => {
                onOptionClick(index);
              }}
            >
              {option.text}
              {option.tip && option.tip !== "" && (
                <>
                  <img
                    alt="Help"
                    data-tip={option.tip}
                    data-for={`option-${index}`}
                    src={helpIcon}
                    className="help-img"
                  />
                  <ReactTooltip
                    className="tooltip"
                    id={`option-${index}`}
                    place="right"
                    effect="solid"
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtons;
