import { useEffect } from "react";

const useOnClickOutside = (refs, handler = () => {}) => {
  useEffect(
    () => {
      const listener = (event) => {
        const reformattedArray = refs.flatMap((ref) => {
          if (!ref?.current) {
            return [];
          } else if (Array.isArray(ref.current)) {
            return ref.current.filter((current) => current !== undefined);
          } else {
            return [ref.current];
          }
        });

        // Do nothing if clicking ref's element or descendent elements
        if (!reformattedArray.some((ref) => ref.contains(event.target))) {
          handler();
        }
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler]
  );
};

export default useOnClickOutside;
