import { useEffect, useRef, useState } from "react";
import { create_card } from "../../../utils/create_card";
import React from "react";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";
import rightArrow from "../../../assets/images/arrow-right.svg";

const DonnutChart = ({ showInfo, details }) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  // alert("hello");
  // const data = {
  //   value: 71.1,
  //   text: 71.1,
  //   percentage: true,
  //   url: "/patient/bc222443239b2d269de444488f1e96fffc07cfd9/evaluation/0f5479be6ee523629eb339128897a056bfaa986c/",
  //   details_card_id: null,
  // };
  // const details = {
  //   id: 7,
  //   type: 4,
  //   title: "Kinematics",
  //   description:
  //     "Score of the kinematic evaluation performed. The higher, the better the evaluation.",
  //   icon: '<i class="fa fa-area-chart" aria-hidden="true"></i>',
  //   color: "var(--korange-color)",
  //   span_row: 3,
  //   span_col: 2,
  // };

  const reff = useRef(null);

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    function create_circle_scores(data) {
      let scaler = 5.34;
      // var svgs = document.getElementsByClassName("circle-plot");
      const svg = document.getElementById("circle-plot-" + details.id);
      //[].forEach.call(svgs, function (svg) {
      svg.setAttribute("viewBox", "0 0 180 180");
      svg.setAttribute("preserveAspectRatio", "xMidYMid meet");
      svg.setAttribute("width", "80%");
      svg.style.display = "inherit";
      // if (data.url)
      //   svg.parentElement.querySelector(".edit").style.display = "inherit";

      let perc = data.value;
      var svgns = "http://www.w3.org/2000/svg",
        cx = 90,
        cy = 90,
        r = 80;
      var back_circle = document.createElementNS(svgns, "circle");
      back_circle.setAttributeNS(null, "cx", cx);
      back_circle.setAttributeNS(null, "cy", cy);
      back_circle.setAttributeNS(null, "r", r);
      back_circle.id = "background";
      svg.appendChild(back_circle);

      if (perc !== undefined) {
        var perc_circle = document.createElementNS(svgns, "circle");
        perc_circle.setAttributeNS(null, "cx", cx);
        perc_circle.setAttributeNS(null, "cy", cy);
        perc_circle.setAttributeNS(null, "r", r);
        perc_circle.id = "percentage-" + details.id;
        svg.appendChild(perc_circle);

        perc_circle.setAttribute("stroke-dasharray", perc * scaler + ", 534");
        perc_circle.setAttribute("stroke-dashoffset", perc * scaler);
        svg.querySelector("text").innerHTML = perc
          ? perc.toFixed(2) + '<tspan class="indicator-sign">%</tspan>'
          : "NO DATA";
        perc_circle.getBoundingClientRect();
        // Define our transition
        perc_circle.style.transition = perc_circle.style.WebkitTransition =
          "stroke-dashoffset 0.5s ease-in-out";
        // Go!
        perc_circle.style.strokeDashoffset = "0";
      } else {
        svg.parentNode.classList.add("no-data");
        svg.querySelector("text").innerHTML = "NO DATA";
      }
      // });
    }

    function build_circlular_percentage_metric(card, data, color) {
      var svg = create_metric_svg(card, data);
      data.value === null &&
        populate_circle_score(
          svg,
          data.value,
          data.text,
          data.percentage,
          color
        );
      // data.value === null ? set_edit_button(card, data) : populate_circle_score(svg, data.percentage, data.text, data.percentage, 'red');
    }

    function create_metric_svg(card, data) {
      const svgns = "http://www.w3.org/2000/svg";
      let card_body = card.querySelector(".card-body"),
        // i           = document.createElement('i'),
        svg = document.createElementNS(svgns, "svg"),
        text = document.createElementNS(svgns, "text");

      card_body.classList.add("circle-div");

      svg.classList = "circle-plot";
      svg.setAttribute("id", "circle-plot-" + details.id);
      svg.setAttribute("viewBox", "0 0 180 180");
      svg.setAttribute("preserveAspectRatio", "xMidYMid meet");
      svg.setAttribute("width", "10%");
      svg.setAttribute("width", "80%");
      svg.style.display = "inherit";

      text.setAttributeNS(null, "x", "50%");
      text.setAttributeNS(null, "y", "50%");
      // text.setAttributeNS(null, 'font-size','4em');
      text.setAttributeNS(null, "dy", "0.4em");
      text.setAttributeNS(null, "text-anchor", "middle");
      text.classList = "indicator-value";
      svg.appendChild(text);

      card_body.appendChild(svg);

      let actions_div = document.createElement("div");

      let button = document.createElement("div");
      button.classList = "details-button";

      if (data.details_card_id === null) {
        if (data.url != null) {
          // button.innerHTML = `<i class="icon edit" aria-hidden="true"></i>`;
          // button.addEventListener(
          //   "click",
          //   () => {
          //     document.location.href = data.url;
          //   },
          //   false
          // );
          // button.addEventListener(
          //   "touch",
          //   () => {
          //     document.location.href = data.url;
          //   },
          //   false
          // );
          // button.style.display = "inherit";
          // actions_div.appendChild(button);
        }
      } else {
        actions_div.classList = "plot-actions noselect";
        actions_div.style.background = "transparent";

        const buttonContent = document.createElement("div");
        buttonContent.classList = "row-space-between";
        buttonContent.innerHTML = `<div class="mr-1">Details</div> <img src=${rightArrow} >`;

        // button.innerHTML = `<i class="icon info" aria-hidden="true"></i>`;
        // button.innerHTML = "Details >";
        button.appendChild(buttonContent);
        button.addEventListener(
          "click",
          () => {
            showInfo(data.details_card_id, data.url);
          },
          false
        );
        button.addEventListener(
          "touch",
          () => {
            showInfo(data.details_card_id, data.url);
          },
          false
        );
        button.style.display = "inherit";
        actions_div.appendChild(button);
      }
      card_body.appendChild(actions_div);
      return svg;
    }

    function populate_circle_score(svg, perc, text, is_perc, color) {
      perc = Math.round(perc * 100) / 100;
      const scaler = 5, //5.34
        svgns = "http://www.w3.org/2000/svg",
        cx = 90,
        cy = 90,
        r = 80;
      let back_circle = document.createElementNS(svgns, "circle"),
        units = is_perc ? "%" : "";

      back_circle.setAttributeNS(null, "cx", cx);
      back_circle.setAttributeNS(null, "cy", cy);
      back_circle.setAttributeNS(null, "r", r);
      back_circle.setAttribute("stroke-dasharray", "565.48");
      back_circle.id = "background";
      svg.appendChild(back_circle);
      if (perc !== undefined) {
        var perc_circle = document.createElementNS(svgns, "circle");
        perc_circle.setAttributeNS(null, "cx", cx);
        perc_circle.setAttributeNS(null, "cy", cy);
        perc_circle.setAttributeNS(null, "r", r);
        perc_circle.id = "percentage";
        svg.appendChild(perc_circle);

        perc_circle.setAttribute("stroke-dasharray", `${perc * scaler}, 534`);
        perc_circle.setAttribute("stroke-dashoffset", perc * scaler);
        if (color) {
          perc_circle.setAttribute("stroke", color);
        }
        if (String(text).length > 3) {
          // svg.querySelector("text").setAttributeNS(null, 'font-size','3em');
        }
        svg.querySelector(
          "text"
        ).innerHTML = `${text}<tspan class="indicator-sign">${units}</tspan>`;
        perc_circle.getBoundingClientRect();
        // Define our transition
        perc_circle.style.transition = perc_circle.style.WebkitTransition =
          "stroke-dashoffset 0.5s ease-in-out";
        // Go!
        perc_circle.style.strokeDashoffset = "0";
      } else {
        svg.parentNode.classList.add("no-data");
      }
    }

    function set_edit_button(card, data) {
      if (data.url != null) {
        card.querySelector(".action").style.visibility = "visible";
      }
    }

    function hide_elements(data) {
      var divs = document.getElementsByClassName("circle-div");
      [].forEach.call(divs, function (div) {
        if (!data.hasOwnProperty(div.id)) {
          div.style.display = "none";
        }
      });
    }
    if (data === null)
      create_card(
        reff.current,
        details.id,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );

    if (data !== null) {
      build_circlular_percentage_metric(reff.current, data, details.color);
      create_circle_scores(data);
    }
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default React.memo(DonnutChart);
