import {
  SET_MESSAGE_SUCCESS,
  DELETE_MESSAGE_SUCCESS,
  GET_INFO_MSG,
} from "../actions/messageHandler";

const initialState = {
  message: "",
};

const messageHandler = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_SUCCESS: {
      return { ...action.payload.msg };
    }
    case DELETE_MESSAGE_SUCCESS: {
      return { initialState };
    }
    default:
      return state;
  }
};

export default messageHandler;
