import {
  GET_PATIENT_SURVEY_DETAILS_FAIL,
  GET_PATIENT_SURVEY_DETAILS_SUCCESS,
} from "../actions/patients";

const initialState = {
  id: null,
  name: "",
  submission_date: "",
  result: null,
  result_questions: [],
};

const patientSurveyDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_SURVEY_DETAILS_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_SURVEY_DETAILS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientSurveyDetails;
