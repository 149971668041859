import React from "react";

import $ from "jquery";

const RadioButtons = ({
  id,
  name,
  question = "",
  options = [],
  vertical = false,
  checked = null,
  onOptionClick = () => {},
}) => {
  return (
    <div className="radio-buttons-container">
      {question !== "" && <div className="rd-btn-question">{question}</div>}
      <div className={`rd-btn-options-wrapper ${vertical ? "vertical" : ""}`}>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className={`rd-btn-option-container ${
                checked === option[0] ? "checked" : ""
              }`}
              onClick={() => {
                onOptionClick(option);
                setTimeout(() => {
                  $(`#group-${id}`).trigger("change");
                }, 1);
              }}
            >
              {option[1]}
            </div>
          );
        })}
      </div>
      {options.map((option, index) => {
        return (
          <input
            readOnly
            hidden
            key={`${id}_${option[0]}`}
            id={`${id}_${option[0]}`}
            name={name}
            type="radio"
            value={option[0]}
            checked={checked === option[0]}
          />
        );
      })}
    </div>
  );
};

export default React.memo(RadioButtons);
