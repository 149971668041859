import React, { useState, useEffect, useCallback } from "react";

import Icon from "components/icon";

import { icons } from "components/icon/icons";

const Accordion = ({ onClick, children, expanded, label }) => {
  const onClickHandler = useCallback(
    (e) => {
      onClick();
    },
    [onClick]
  );

  //Defensive;
  if (!children) return null;

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div className="accordion-summary" onClick={onClickHandler}>
          <p className="accordion-title">{label}</p>
          <Icon
            alt="accordion"
            icon={icons[expanded ? "arrow-up" : "arrow-down"]}
            className="accordion-icon"
          />
        </div>
        {expanded && (
          <div className="accordion-content" id="accordion-content">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
