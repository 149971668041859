import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SelectedWeekDays = ({ activeDays }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="days-wrapper">
      {t("time.weekdays_short", { returnObjects: true }).map((day, index) => (
        <div
          key={index}
          className={`day-container ${
            activeDays.includes(index) ? "active" : ""
          }`}
        >
          {day}
        </div>
      ))}
    </div>
  );
};
export default React.memo(SelectedWeekDays);
