import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "../../../../components/spinner";
import { getBackupTokens } from "../../../../redux/actions/multiFactorAuth";
import {useTranslation} from "react-i18next";

const BackupTokens = ({ cancelBackupTokens }) => {
  const { t, i18n } = useTranslation();
  const [backupTokens, setBackupTokens] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBackupTokens()).then(
      (res) => {
        if (res.payload.data && res.payload.data.data) {
          setBackupTokens(res.payload.data.data);
        }
      },
      (err) => {
        console.log("getBackupTokens Error:", err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="change-password-wrapper">
      <span className="small-title">{t("authentication.mfa.save_backup_codes")}</span>
      <div className="change-password-body">
        <div className="regular-text mb-4">
          {t("authentication.mfa.save_backup_codes_body")}{" "}
          <span className="smaller-title dark-grey-color">
            {t("authentication.mfa.save_backup_codes_note")}
          </span>
        </div>
        <div className="smaller-title mb-2">{t("authentication.mfa.codes")}</div>
        <div
          className={`backup-tokens-wrapper ${
            backupTokens.length === 0 ? "loading" : ""
          }`}
        >
          {backupTokens.length !== 0 ? (
            backupTokens.map((token, index) => (
              <div key={index} className="backup-token-container">
                {token}
              </div>
            ))
          ) : (
            <Spinner type="small-blue" />
          )}
        </div>

        <div className="password-footer">
          <button
            onClick={cancelBackupTokens}
            className="small-wider-button transparent-button"
          >
            <u>{t("buttons.cancel")}</u>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackupTokens;
