//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import isEmail from "validator/lib/isEmail";

//* ***************************************** ACTIONS ****************************************
import { forgotPassword, forgotUsername } from "redux/actions/user";

//* ************************************* OUR COMPONENTS *************************************
import Spinner from "components/spinner";
import RadioButtons from "components/radioButtons";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const ForgotAccount = ({ changeToCheckEmail, showErrorPage }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState({});
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [checkedRadioButtonIndex, setCheckedRadioButtonIndex] = useState(1);

  const isSendPasswordDisabled = email === "" || isRequestLoading;

  const radioButtonOptions = [
    { key: "username", text: t("authentication.forgot_account.username") },
    {
      key: "password",
      text: t("authentication.forgot_account.password"),
    },
  ];

  //* ************************************** HANDLERS ******************************************
  const onClickSendBtn = (e) => {
    console.log(radioButtonOptions[checkedRadioButtonIndex].key);

    if (isEmail(email)) {
      setIsRequestLoading(true);
      dispatch(
        radioButtonOptions[checkedRadioButtonIndex].key === "password"
          ? forgotPassword(email)
          : forgotUsername(email)
      )
        .then(async (res) => changeToCheckEmail())
        .catch((error) => {
          if (error.error.response) showErrorPage();
        })
        .finally(() => {
          setIsRequestLoading(false);
        });
    } else {
      setForgotPasswordErrors({
        ...forgotPasswordErrors,
        email: t("authentication.forgot_password.correct_email"),
        error: true,
      });
    }
  };

  const inputsHandler = (e) => {
    if (forgotPasswordErrors.email) {
      delete forgotPasswordErrors.email;
      setForgotPasswordErrors({ error: false });
    }
    setEmail(e.target.value);
  };

  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <div className="form-auth-wrapper">
      <h2>{t("authentication.forgot_account.forgot_account")}</h2>
      <p className="input-label-text mt-4 mb-4">
        {t("authentication.forgot_account.msg")}
      </p>
      <div className="regular-text surface-black-color mb-2">
        {t("authentication.forgot_account.password_or_username")}
      </div>
      <RadioButtons
        checkedIndex={checkedRadioButtonIndex}
        options={radioButtonOptions}
        onOptionClick={setCheckedRadioButtonIndex}
      />
      <div className="mt-4">
        <label
          className="d-flex justify-content-between input-label-text"
          htmlFor="email"
        >
          <span> {t("user_info.email")}</span>
          {forgotPasswordErrors.email && (
            <span className="warning-color">{forgotPasswordErrors.email}</span>
          )}
        </label>
        <div className="row-space-between">
          <input
            autoComplete="off"
            value={email}
            name="email"
            onChange={inputsHandler}
            placeholder={t("placeholders.email")}
            type="text"
            className={
              !forgotPasswordErrors.error ? "form-input" : "form-input error"
            }
          />
          <button
            className="primary-button small-button mb-3 ml-1"
            disabled={isSendPasswordDisabled}
            onClick={() => onClickSendBtn()}
          >
            <div className="position-relative">
              {isRequestLoading && <Spinner type={"small"} />}
              {t("buttons.send")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotAccount;
