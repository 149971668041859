//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import { createTicket } from "redux/actions/tickets";

//* ************************************* OUR COMPONENTS *************************************
import MessageModal from "components/messageModal";
import Spinner from "components/spinner";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const NewSupportTicketModal = ({ onClose }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isCreateTicketLoading, setIsCreateTicketLoading] = useState(false);
  const [newTicket, setNewTicket] = useState({
    subject: "",
    message: "",
  });

  const onChangeHandler = (e) => {
    setNewTicket({
      ...newTicket,
      [e.target.name]: e.target.value,
    });
  };

  const createNewTicketHandler = () => {
    setIsCreateTicketLoading(true);
    dispatch(createTicket(newTicket.subject, newTicket.message)).finally(() => {
      setNewTicket((newTicket.subject = ""), (newTicket.message = null));
      setIsCreateTicketLoading(false);
      closeCreateTicketModal(false);
    });
  };

  const closeCreateTicketModal = () => {
    setNewTicket("");
    if (onClose) onClose();
  };

  //* ************************************** PARTIALS ****************************************

  const messageBody = (
    <div>
      <div className="support-detail-item-container">
        <p className="support-detail-item-title-no-margin-bottom">
          {t("support.any_problems")}
        </p>
      </div>
      <div className="support-detail-item-container">
        <p className="support-detail-item-title">
          {t("support.subject")}&nbsp;
          <span className="warning-color">{t("star")}</span>
        </p>
        <textarea
          as="textarea"
          className="support-edit-input-field support-edit-input-field-single-line"
          placeholder={t("support.subject_ticket")}
          value={newTicket.subject}
          name="subject"
          onChange={onChangeHandler}
        />
      </div>
      <div className="support-detail-item-container">
        <p className="support-detail-item-title">
          {t("support.describe_problem")}
        </p>
        <textarea
          as="textarea"
          className="support-edit-input-field support-edit-input-field-double-line"
          placeholder={t("support.write_here")}
          value={newTicket.message}
          name="message"
          onChange={onChangeHandler}
        />
      </div>
      <div className="support-detail-item-container">
        <p className="support-detail-item-title-no-margin-bottom">
          {t("support.disclaimer")}
        </p>
      </div>
    </div>
  );
  //* *************************************** RENDER *****************************************
  return (
    <MessageModal
      body={messageBody}
      headerText={t("support.create_ticket")}
      footerButtons={[
        <button
          onClick={() => closeCreateTicketModal()}
          className="small-button transparent-button"
        >
          <u>{t("buttons.cancel")}</u>
        </button>,
        <div className="input-image-wrapper m-0">
          <button
            onClick={() => createNewTicketHandler()}
            className={`secondary-button small-wider-button ${
              isCreateTicketLoading && "transparent"
            }`}
            disabled={!newTicket.subject || !newTicket.message}
          >
            {t("buttons.submit")}
          </button>
          {isCreateTicketLoading && <Spinner type="small" />}
        </div>,
      ]}
    />
  );
};

export default NewSupportTicketModal;
