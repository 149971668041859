import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-primary.svg";
import sn from "../../assets/images/sn.svg";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import CompanyInfo from "../../components/companyInfo";

import {
  getInstitutions,
  setCurrentInstitution,
  setDefaultInstitution,
} from "../../redux/actions/institutions";
import { clearRedirectTo } from "../../redux/actions/redirect";
import { getPasswordExpireTime, userLogout } from "../../redux/actions/user";
import { errors } from "../../constants/general";
import { useTranslation } from "react-i18next";

const ChooseInstitution = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [isEntering, setIsEntering] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDefaultSelected, setIsDefaultSelected] = useState(false);

  const product_version = process.env.REACT_APP_PRODUCT_VERSION;

  const institutions = useSelector((state) => state.institutions.user_institutions);
  const redirectTo = useSelector((state) => state.redirectTo.url);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getInstitutions()).finally(() => setIsLoading(false));
  }, []);

  const isInstitutionSelected = selectedInstitutionId !== null;

  const passwordCheck = () => {
    return dispatch(getPasswordExpireTime()).then((res) => {
      if (res.payload.data) {
        const days = res.payload.data.days_until_update;
        /** also check multiFactorAuth.js (line: 66) */
        if (days <= 15) {
          props.history.push("/password_check", { days: days });
        } else {
          props.history.replace(redirectTo);
          dispatch(clearRedirectTo());
        }
      }
    });
  };

  const onEnterSelected = (e) => {
    e.preventDefault();
    setIsEntering(true);
    dispatch(setCurrentInstitution(selectedInstitutionId))
      .then(() => {
        if (isDefaultSelected) {
          dispatch(setDefaultInstitution(selectedInstitutionId));
        }
      })
      .then(() => {
        props.history.replace(redirectTo);
        dispatch(clearRedirectTo());
      })
      .finally(() => {
        setIsEntering(false);
      });
  };

  const checkBoxHandler = (e) => {
    setIsDefaultSelected(e.target.checked);
  };

  return (
    <div className="main-wrapper">
      <div className="p-2 h-100 flex-d-column">
        <div className="row justify-content-between">
          <div className="col-auto">
            <img className="logo" src={logo} alt="Logo" />
          </div>
          <div className="col-auto">
            <div>
              <img style={{ marginRight: "4px" }} alt="SN" src={sn} />
              <span className="regular-text text--grey-intense">{product_version}</span>
            </div>
          </div>
        </div>

        <div className="row choose-institution-wrapper">
          {isLoading ? (
            <div className="col-sm-8 col-lg-4 institution-loading-box">
              <Spinner type={"big-blue"} />
            </div>
          ) : (
            <div className="col-sm-8 col-lg-4 choose-institution-box">
              {institutions.length > 0 ? (
                <div>
                  <div
                    style={{ opacity: isInstitutionSelected && "20%" }}
                    className="big-semibold-base-text"
                  >
                    {t("institution.choose")}
                  </div>
                  <div
                    style={{ opacity: isInstitutionSelected && "20%" }}
                    className="regular-text mt-2 mb-3"
                  >
                    {t("institution.select")}
                  </div>
                  {institutions.map((institution, index) => (
                    <div
                      onClick={() => setSelectedInstitutionId(institution.id)}
                      key={institution.id}
                      className={`institution ${
                        selectedInstitutionId === institution.id
                          ? "isSelected"
                          : selectedInstitutionId !== null
                          ? "hidden"
                          : ""
                      } mb-2`}
                    >
                      <img src={institution.photo} className="photo" alt="Home Blue" />
                      {institution.name}
                    </div>
                  ))}
                  {selectedInstitutionId === null ? (
                    <>
                      <div className="regular-text mt-6">{t("institution.create")}</div>
                      <button
                        onClick={() =>
                          props.history.push({
                            pathname: "/create_institution",
                            cameFromApp: true,
                          })
                        }
                        className="mt-2 w-100 small-button background-button"
                      >
                        {t("buttons.create_institution")}
                      </button>
                      <div className="mt-4 text-center regular-text">
                        {t("support.contact")}{" "}
                        <Link
                          to={{
                            pathname: "mailto:support@kinetikoshealth.com",
                          }}
                          target={"_blank"}
                        >
                          <span className="link-text">&nbsp;{t("support.center")}</span>
                        </Link>
                        <div className="mt-4 text-center regular-text">
                          <span
                            className="link-text"
                            onClick={() => {
                              dispatch(userLogout()).finally(() => {
                                props.history.push("/login");
                              });
                            }}
                          >
                            <u>{t("buttons.logout")}</u>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="check-box-wrapper mt-4">
                        <div className="input-wrapper col-md-auto">
                          <input
                            checked={isDefaultSelected}
                            onChange={checkBoxHandler}
                            name="consent_check"
                            type={"checkbox"}
                            id="checkbox"
                          />
                        </div>
                        <label type="checkbox" htmlFor="checkbox">
                          <span className="regular-text black-color">
                            {t("institution.default")}
                          </span>
                        </label>
                      </div>
                      <div className="regular-text">{t("institution.change_default")}</div>
                      <div className="bottom-buttons">
                        <button
                          disabled={isEntering}
                          onClick={() => setSelectedInstitutionId(null)}
                          className="small-wider-button transparent-button margin-right"
                        >
                          <u>{t("buttons.cancel")}</u>
                        </button>
                        <div className="position-relative">
                          <input
                            disabled={isEntering}
                            onClick={onEnterSelected}
                            type="submit"
                            value={t("buttons.enter")}
                            className={`small-wider-button primary-button ${
                              isEntering && "transparent"
                            }`}
                          />

                          {isEntering && <Spinner type={"small"} />}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="no-institutions-found">
                  <h3>{t("institution.not_found")}</h3>
                  <div className="regular-text black-color mt-3 mb-3">
                    {t("institution.contact")}
                  </div>
                  <div className="line-border" />
                  <div className="big-semibold-base-text mt-3 mb-4">
                    {t("institution.none_create")}
                  </div>
                  <div className="regular-text mb-4">{t("institution.accepted_period")}</div>
                  <button
                    onClick={() =>
                      props.history.push({
                        pathname: "/create_institution",
                        cameFromChooseInstitution: true,
                      })
                    }
                    className="small-button primary-button w-100"
                  >
                    {t("buttons.create_institution")}
                  </button>
                  <div className="mt-4 text-center regular-text">
                    {t("support.contact")}
                    <Link
                      to={{
                        pathname: "mailto:support@kinetikoshealth.com",
                      }}
                      target={"_blank"}
                    >
                      <span className="link-text">&nbsp;{t("support.center")}</span>
                    </Link>
                  </div>
                  <div className="mt-4 text-center regular-text">
                    <span
                      className="link-text"
                      onClick={() => {
                        dispatch(userLogout()).finally(() => {
                          props.history.push("/login");
                        });
                      }}
                    >
                      <u>{t("buttons.logout")}</u>
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="row h-100">
          <div className="col kinetikos-bottom-information-wrapper">
            <CompanyInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseInstitution;
