import {
  GET_RISK_SUMMARY_SUCCESS,
  GET_RISK_SUMMARY_FAIL,
} from "../actions/patientRiskSummary";

const initialState = {};

const patientRiskSummary = (state = initialState, action) => {
  switch (action.type) {
    case GET_RISK_SUMMARY_SUCCESS: {
      return action.payload.data.summary_report;
    }
    case GET_RISK_SUMMARY_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientRiskSummary;
