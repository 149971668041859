//* *********************************** REACT IMPORTS ************************************
import React, { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************

import MessageModal from "../../../../components/messageModal/index.js";
//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import { createPrescription } from "../../../../redux/actions/patientPrescriptions.js";
import { errors } from "../../../../constants/general.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import Spinner from "../../../../components/spinner/index.js";
import { formatDate, getToday } from "../../../../utils/dataAndTime.js";

const CreatePrescription = (props) => {
  //* *************************************** SETUP ***************************************
  const { t, i18n } = useTranslation();

  const { patient_id } = useParams("patient_id");
  const dispatch = useDispatch();

  const [prescriptionName, setPrescriptionName] = useState("");
  const [prescriptionNameErrors, setPrescriptionNameErrors] = useState([]);
  const [allowEditPrescription, setAllowEditPrescription] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const history = useHistory();

  //* ************************************** HANDLERS **************************************

  const goBackToPrescriptionList = () =>
    history.replace(`/patient/${patient_id}/mkinetikos_prescriptions`);

  const goBackToCreateMedicine = () =>
    history.replace(`/patient/${patient_id}/mkinetikos_medicines/create`, {
      medicineDetails: props.location.state.medicineDetails,
    });

  const goToEditPrescription = (prescriptionId) =>
    history.replace(
      `/patient/${patient_id}/mkinetikos_prescriptions/${prescriptionId}/edit`
    );

  const savePrescription = () => {
    const data = {
      name: prescriptionName
        ? prescriptionName
        : t("patient.meds.new_prescription") +
          " - " +
          formatDate(getToday(), "YYYY.MM.DD"),
      user_can_edit: allowEditPrescription,
      active: true,
    };
    setIsSaving(true);
    dispatch(createPrescription(patient_id, data))
      .then((res) => {
        if (props.location.state && props.location.state.medicineDetails) {
          goBackToCreateMedicine();
        } else {
          const prescriptionId = res.payload.data.data.id;
          goToEditPrescription(prescriptionId);
        }
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response.data;
          if (data.form_errors) {
            setPrescriptionNameErrors(data.form_errors);
          }
        } else {
          console.log(error);
          alert(errors.service_error);
        }
      });
  };

  const updatePrescriptionName = (name) => setPrescriptionName(name);

  // In case we need a validator at some point
  const isNameValid = prescriptionName.trim() !== "";

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={goBackToPrescriptionList}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.new_prescription")}</h4>
      </div>
      <div className="position-relative">
        <div className={`create-exercise-box`}>
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">
              {t("patient.meds.new_prescription")}
            </div>
            <div className="mt-1 flex-d-row">
              <button
                onClick={() => setIsCancelModalOpen(true)}
                className="transparent-button small-button mr-1"
              >
                <u>{t("buttons.cancel")}</u>
              </button>
              <div className="input-image-wrapper m-0">
                <button
                  onClick={savePrescription}
                  disabled={isSaving || !isNameValid}
                  className={`secondary-button small-button ${
                    isSaving && "transparent"
                  }`}
                >
                  {t("buttons.save")}
                </button>
                {isSaving && <Spinner type={"small"} />}
              </div>
            </div>
          </div>
          <div className="mt-5 flex-d-column">
            <label className="input-label-text d-flex justify-content-between">
              <span>
                {t("patient.meds.prescription_name")}{" "}
                <span className="warning-color">{t("star")}</span>
              </span>
              {prescriptionNameErrors.name && (
                <span className="warning-color">
                  {prescriptionNameErrors.name}
                </span>
              )}
            </label>
            <input
              onChange={(e) => updatePrescriptionName(e.target.value)}
              value={prescriptionName}
              placeholder={t("placeholders.prescription_name")}
              autoComplete="off"
              className={`form-input with-input-sizing ${
                prescriptionNameErrors.name ? "error" : ""
              }`}
              name="name"
              id="name"
            />
            <div className="check-box-wrapper mt-1">
              <div className="input-wrapper col-md-auto">
                <input
                  //   disabled={
                  //     isButtonOrInputDisable ||
                  //     exerciseDetails.exercise_id !== null
                  //   }
                  checked={allowEditPrescription}
                  onChange={(e) => setAllowEditPrescription(e.target.checked)}
                  name="save_for_institution"
                  type={"checkbox"}
                  id="checkbox"
                />
              </div>

              <label type="checkbox" htmlFor="checkbox">
                <span className="regular-text">
                  {t("patient.meds.allow_edit")}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      {isCancelModalOpen && (
        <MessageModal
          headerText={t("modals.cancel_add_action_header")}
          body={t("modals.cancel_add_action_body")}
          footerButtons={[
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={goBackToPrescriptionList}
              className="small-button secondary-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default CreatePrescription;
