import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { create_card } from "../../../utils/create_card";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";
const GaugeChart = ({ details }) => {
  const reff = useRef(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  // const data = {
  //   value: 20.0,
  //   text: "Low",
  // };
  // const details = {
  //   id: 94,
  //   type: 24,
  //   title: "Risk of Injury",
  //   description: "Prediction of injury risk according to the test results.",
  //   icon: '<i class="fa fa-tachometer" aria-hidden="true"></i>',
  //   color: "var(--kred-color)",
  //   span_row: 3,
  //   span_col: 2,
  // };

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    /*Downloaded from https://www.codeseek.co/agasthyanavaneeth/gauge-chart-for-reporting-purpose-btyus */
    function build_gauge_plot(card, data, color) {
      let card_body = card.querySelector(".card-body"),
        span = document.createElement("span"),
        div_gauge = document.createElement("div"),
        div_text = document.createElement("div");

      // Create gauge
      let gaugeProperties = {
        radius: 70,
        gaugeWidth: 10,
        percentage: parseFloat(data.value),
        color: "var(--kred-color)",
        animationTime: 1500,
        // fontSize: "50px",
        // fontColor: "#7A7A7A",
        // fontFamily: "Segoe ui light"
      };

      var gauge = new AwesomeCharts.Gauge(gaugeProperties);
      gauge.RenderChart(div_gauge);

      // Card append the gauge
      card_body.style.setProperty("--color", color);
      span.classList = "title";
      // span.style.setProperty('font-weight', 'bold');
      // span.style.setProperty('font-size', '2em');
      // span.style.setProperty('text-align', 'center');
      // span.style.setProperty('align', 'center');
      // span.style.setProperty('display', 'block');
      // span.style.setProperty('margin', 'auto');
      // span.style.setProperty('margin-top', '1em');
      span.innerHTML = data.text;
      div_gauge.classList = "line";
      div_gauge.style.setProperty("padding-top", "15px");
      div_text.classList = "line";
      div_text.appendChild(span);
      card_body.appendChild(div_gauge);
      card_body.appendChild(div_text);
    }

    var AwesomeCharts;
    (function (AwesomeCharts) {
      var Gauge = (function () {
        function Gauge(gaugeProperties) {
          this.nmspace = "http://www.w3.org/2000/svg";
          this.properties = gaugeProperties;
          this.boxWidth =
            2 * (this.properties.radius + this.properties.gaugeWidth);
          this.boxHeight = this.properties.radius + this.properties.gaugeWidth;
          this.center = this.properties.radius + this.properties.gaugeWidth;
          this.x = this.properties.radius / Math.sqrt(2);
          this.shortSide = this.x / 10;
          this.relativeZero = 135;
          this.instantiate();
        }
        Gauge.prototype.easeinCubic = function (t) {
          return t < 0.5
            ? 16 * t * t * t * t * t
            : 1 + 16 * --t * t * t * t * t;
        };
        Gauge.prototype.timerForZero = function (t) {
          return t > 0.5 ? 1 - t * t : t * t + 0.0000000005;
        };
        Gauge.prototype.instantiate = function () {
          this.svg = document.createElementNS(this.nmspace, "svg");
          this.svg.setAttributeNS(null, "width", this.boxWidth.toString());
          this.svg.setAttributeNS(
            null,
            "height",
            (this.boxHeight + this.shortSide).toString()
          );
          this.svg.setAttributeNS(
            null,
            "viewBox",
            "0 0 " + this.boxWidth + " " + (this.boxHeight + this.shortSide)
          );
          if (this.properties.percentage > 100) {
            this.properties.percentage = 100;
          }
          if (this.properties.percentage < 0) {
            this.properties.percentage = 0;
          }
        };
        Gauge.prototype.getangleFromPercentage = function (percentage) {
          var prcnt =
            !!percentage && percentage >= 0 && percentage <= 100
              ? percentage
              : 100;
          return (Math.PI * prcnt) / 50;
        };
        Gauge.prototype.createMarker = function () {
          this.marker = document.createElementNS(this.nmspace, "path"); //obs: creating an SVG element requires casting
          this.marker.setAttributeNS(null, "fill", "#7f8c8d");
          this.marker.setAttributeNS(
            null,
            "d",
            "M" +
              this.center +
              " " +
              this.center +
              "l" +
              this.shortSide +
              " 0 l" +
              (this.x - this.shortSide) +
              " " +
              this.x +
              " l-" +
              this.x +
              " -" +
              (this.x - this.shortSide) +
              "l0 -" +
              this.shortSide +
              "z"
          );
          this.marker.setAttributeNS(
            null,
            "transform",
            "rotate(" +
              this.relativeZero +
              ", " +
              this.center +
              ", " +
              this.center +
              ")"
          );
          this.svg.appendChild(this.marker);
        };
        Gauge.prototype.getArc = function (
          x,
          y,
          r,
          startAngle,
          endAngle,
          color,
          animateTime
        ) {
          var arcObj = document.createElementNS(this.nmspace, "path"),
            start = startAngle,
            radius = r + this.properties.gaugeWidth / 2,
            end = endAngle - 0.000001,
            cosStart = Math.cos(start),
            sinStart = Math.sin(start),
            cosEnd = Math.cos(end),
            sinEnd = Math.sin(end),
            longArc = endAngle - startAngle < Math.PI ? 0 : 1,
            path,
            that = this;
          arcObj.setAttributeNS(null, "fill", "#FFF");
          arcObj.setAttributeNS(null, "stroke", color);
          arcObj.setAttributeNS(
            null,
            "stroke-width",
            this.properties.gaugeWidth.toString()
          );
          arcObj.setAttributeNS(null, "fill-opacity", "0");
          this.svg.appendChild(arcObj);
          if (!!animateTime) {
            var FrameRefreshRate = 1000 / 60,
              startTime = FrameRefreshRate,
              stopAngle =
                start +
                this.easeinCubic(startTime / animateTime) * (end - start),
              timerId = window.setInterval(function () {
                if (stopAngle >= end) {
                  clearInterval(timerId);

                  path =
                    "M " +
                    (x + radius * cosStart) +
                    ", " +
                    (y + radius * sinStart) +
                    " A " +
                    radius +
                    "," +
                    radius +
                    " 0 " +
                    longArc +
                    ", 1 " +
                    (x + radius * cosEnd) +
                    ", " +
                    (y + radius * sinEnd);
                } else {
                  path =
                    "M " +
                    (x + radius * cosStart) +
                    ", " +
                    (y + radius * sinStart) +
                    " A " +
                    radius +
                    "," +
                    radius +
                    " 0 " +
                    (stopAngle - startAngle < Math.PI ? 0 : 1) +
                    ", 1 " +
                    (x + radius * Math.cos(stopAngle)) +
                    ", " +
                    (y + radius * Math.sin(stopAngle));
                }
                arcObj.setAttributeNS(null, "d", path);
                startTime += FrameRefreshRate;
                stopAngle =
                  start +
                  that.easeinCubic(startTime / animateTime) * (end - start);
              }, FrameRefreshRate);
          } else {
            path =
              "M " +
              (x + radius * cosStart) +
              ", " +
              (y + radius * sinStart) +
              " A " +
              radius +
              "," +
              radius +
              " 0 " +
              longArc +
              ", 1 " +
              (x + radius * cosEnd) +
              ", " +
              (y + radius * sinEnd);
            arcObj.setAttributeNS(null, "d", path);
          }
          return arcObj;
        };
        Gauge.prototype.getRadiansFromDegrees = function (deg) {
          return deg * (Math.PI / 180);
        };
        Gauge.prototype.getValidatedColor = function (clr) {
          return !!clr &&
            clr.match(/^#(?:[1-9 A-F a-f]{3,3}|[1-9 A-F a-f]{6,6})/g)
            ? clr
            : "#000000";
        };
        Gauge.prototype.animateMarker = function () {
          var end = (this.properties.percentage / 100) * 180,
            start = 0,
            timer = this.easeinCubic,
            timerTwo = this.timerForZero,
            that = this;
          if (!!this.properties.animationTime) {
            var FrameRefreshRate = 1000 / 60,
              startTime = FrameRefreshRate,
              stopAngle =
                start +
                timer(startTime / this.properties.animationTime) *
                  (end - start),
              timerId = setInterval(function () {
                if (stopAngle >= end) {
                  clearInterval(timerId);
                  that.marker.setAttributeNS(
                    null,
                    "transform",
                    "rotate(" +
                      (that.relativeZero + end) +
                      ", " +
                      that.center +
                      ", " +
                      that.center +
                      ")"
                  );
                } else {
                  that.marker.setAttributeNS(
                    null,
                    "transform",
                    "rotate(" +
                      (that.relativeZero + stopAngle) +
                      ", " +
                      that.center +
                      ", " +
                      that.center +
                      ")"
                  );
                }
                startTime += FrameRefreshRate;
                stopAngle =
                  start +
                  timer(startTime / that.properties.animationTime) *
                    (end - start);
              }, FrameRefreshRate);
          } else {
            this.marker.setAttributeNS(
              null,
              "transform",
              "rotate(" +
                (this.relativeZero + end) +
                ", " +
                this.center +
                ", " +
                this.center +
                ")"
            );
          }
        };
        Gauge.prototype.RenderChart = function (aux_div) {
          var theta1 = Math.PI,
            theta2 = this.getRadiansFromDegrees(223),
            theta3 = this.getRadiansFromDegrees(227),
            theta4 = this.getRadiansFromDegrees(268),
            theta5 = this.getRadiansFromDegrees(272),
            theta6 = this.getRadiansFromDegrees(313),
            theta7 = this.getRadiansFromDegrees(317),
            theta8 = this.getRadiansFromDegrees(360),
            that = this;
          this.svg.style.setProperty("display", "block");
          this.svg.style.setProperty("margin", "auto");
          aux_div.appendChild(this.svg);
          var animationTime = 400;
          this.getArc(
            this.center,
            this.center,
            this.properties.radius,
            theta1,
            theta2,
            "#3BA316",
            animationTime
          );
          this.getArc(
            this.center,
            this.center,
            this.properties.radius,
            theta3,
            theta4,
            "#FCD116",
            animationTime
          );
          this.getArc(
            this.center,
            this.center,
            this.properties.radius,
            theta5,
            theta6,
            "#FF8C00",
            animationTime
          );
          this.getArc(
            this.center,
            this.center,
            this.properties.radius,
            theta7,
            theta8,
            "#E81123",
            animationTime
          );
          window.setTimeout(function () {
            that.createMarker();
            that.animateMarker();
          }, animationTime);
        };
        return Gauge;
      })();
      AwesomeCharts.Gauge = Gauge;
    })(AwesomeCharts || (AwesomeCharts = {}));
    if (data === null) {
      create_card(
        reff.current,
        30,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    } else {
      build_gauge_plot(reff.current, data, details.color);
    }
  }, [data]);
  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default GaugeChart;
