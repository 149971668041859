//* *********************************** REACT NATIVE IMPORTS *********************************
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//* *********************************** EXTERNAL COMPONENTS **********************************
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";

//* ***************************************** ACTIONS ****************************************
import { markAsRead, markAsArchive } from "redux/actions/notifications";

//* ************************************* OUR COMPONENTS *************************************
import MessageModal from "../../../components/messageModal";

//* ***************************************** STYLES *****************************************

//* ****************************************** UTILS *****************************************
import { getAvatarWithInitials } from "utils/avatar";
import { getTimeDateDiff } from "utils/dataAndTime";

//* ***************************************** ASSETS *****************************************
import crossIcon from "../../../assets/images/home/close.svg";
import archiveIcon from "../../../assets/images/home/archive-icon.svg";
import tickIcon from "../../../assets/images/home/tick-mark-icon.svg";
import link from "../../../assets/images/notification/link.svg";

const NotificationModal = ({ closeNotification, notifications }) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const hour = 72;

  // Add new notification types here
  const notificationTypes = {
    newMessage: 1,
    highRiskAlert: 2,
    adverseEvent: 3,
    noSmartphoneData: 4,
    noSurvey: 5,
    noTest: 6,
    connectionRequest: 7,
  };

  //* ************************************** PARTIALS ****************************************
  const __renderNotification = (notification) => {
    const [isRead, setIsRead] = useState(false);
    const [isArchived, setIsArchived] = useState(false);

    const name = notification.cdss_patient?.name;
    const count = notification?.chat?.new_messages_count ?? 1;
    const requestCount = notification.license_requests?.new_requests_count;

    // Add new notification type handling here
    const messages = {
      [notificationTypes.newMessage]: {
        message: (
          <Trans i18nKey={"notifications.send_you_message"} count={count}>
            <b>{{ name }}</b>
            <b>{{ count }}</b>
          </Trans>
        ),
        goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_my_care_team`,
        button_text: t("notifications.reply_to") + name?.split(" ")[0],
      },
      [notificationTypes.highRiskAlert]: {
        message: (
          <Trans i18nKey={"notifications.reached_high_risk_alert"}>
            <b>{{ name }}</b>
            <b></b>
          </Trans>
        ),
        goTo: "",
        button_text: "",
      },
      [notificationTypes.adverseEvent]: {
        message: (
          <Trans i18nKey={"notifications.registered_an_adverse_event"}>
            <b>{{ name }}</b>
            <b></b>
          </Trans>
        ),
        goTo: "",
        button_text: "",
      },
      [notificationTypes.noSmartphoneData]: {
        message: (
          <Trans
            i18nKey={"notifications.smartphone_did_not_collect_data"}
            count={count}
          >
            <b>{{ name }}</b>
            <b>{{ hour }}</b>
          </Trans>
        ),
        goTo: "",
        button_text: "",
      },
      [notificationTypes.noSurvey]: {
        message: (
          <Trans i18nKey={"notifications.did_not_complete_survey"}>
            <b>{{ name }}</b>
            <b></b>
          </Trans>
        ),
        goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_surveys`,
        button_text: t("notifications.go_to_survey_page"),
      },
      [notificationTypes.noTest]: {
        message: (
          <Trans i18nKey={"notifications.did_not_complete_test"}>
            <b>{{ name }}</b>
            <b></b>
          </Trans>
        ),
        goTo: `/patient/${notification?.cdss_patient?.id}/mkinetikos_tests`,
        button_text: t("notifications.go_to_test_page"),
      },
      [notificationTypes.connectionRequest]: {
        message: (
          <Trans
            i18nKey={"notifications.new_connection_requests"}
            count={requestCount}
          >
            <b>{{ new_connection_requests_count: requestCount }}</b>
            <b></b>
          </Trans>
        ),
        goTo: "",
      },
    };

    const markNotificationRead = () => {
      dispatch(markAsRead([notification.id], true)).then(() => {
        setIsRead(true);
      });
    };

    const markNotificationArchive = () => {
      dispatch(markAsArchive([notification.id], true)).then(() => {
        setIsArchived(true);
      });
    };

    const onClickHandler = () => {
      if (!isRead || !notification.read) markNotificationRead();

      if (messages[notification.notification_type.id]?.goTo)
        history.push(messages[notification.notification_type.id]?.goTo);
    };

    return (
      <div className="notification-modal" key={notification.id}>
        <div className="row-space-between">
          <div className="patient-info">
            <img
              className={
                notification.notification_type.id ===
                notificationTypes.connectionRequest
                  ? "notification-image-link"
                  : "img"
              }
              src={
                notification.notification_type.id ===
                notificationTypes.connectionRequest
                  ? link
                  : notification.cdss_patient?.photo
                  ? notification.cdss_patient?.photo
                  : getAvatarWithInitials(36, name)
              }
              onError={(e) => {
                e.target.src = getAvatarWithInitials(36, name);
              }}
              alt="pp"
            />
            <div className="notification-info">
              <span className="black-color">{name}</span>
              <div className="meta-info">
                <Trans i18nKey={"notifications.ago"}>
                  {getTimeDateDiff(notification.creation_elapsed_time)}
                </Trans>{" "}
                • {t("notifications." + notification.notification_type.tag)}
              </div>
            </div>
          </div>
          <div className="notification-action">
            <span
              className="link-text"
              onClick={() => markNotificationRead()}
              disabled={isRead || notification.read}
            >
              <img src={tickIcon} alt="mark as read" />
              {notification.read || isRead
                ? t("notifications.read")
                : t("buttons.mark_as_read")}
            </span>
            <button
              className="archive-btn"
              onClick={() => markNotificationArchive()}
              disabled={isArchived}
            >
              <img src={archiveIcon} alt="Archive" />
              {isArchived ? t("notifications.archived") : t("buttons.archive")}
            </button>
          </div>
        </div>
        <div className="notification-body">
          {notification.notification_type.id === 1 ? (
            <>
              <span>
                <Trans i18nKey={"notifications.sent_you_message"} count={count}>
                  {{ count }}
                </Trans>
              </span>
              {notification?.chat?.last_message && (
                <div className="message">
                  <span className="quote">“</span>{" "}
                  {notification?.chat?.last_message}
                </div>
              )}
            </>
          ) : (
            <span>{messages[notification.notification_type.id]?.message}</span>
          )}
          {messages[notification.notification_type.id]?.button_text && (
            <div className="notification-footar">
              <button
                className="button small-button primary-button"
                onClick={() => onClickHandler()}
              >
                {messages[notification.notification_type.id]?.button_text}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  //* *************************************** RENDER *****************************************
  return (
    <MessageModal
      headerText={
        <div className="row-space-between">
          <span className="black-color">
            {t("notifications.notifications")}
          </span>
          <img
            className="cursor-pointer"
            src={crossIcon}
            alt="close pointer"
            onClick={() => closeNotification()}
          />
        </div>
      }
      body={
        <>
          {notifications.map((notification) =>
            __renderNotification(notification)
          )}
        </>
      }
      customStyleClass={{ body: "pb-1" }}
    />
  );
};

export default NotificationModal;
