import {
  GET_PATIENT_INSTITUTIONS_FAIL,
  GET_PATIENT_INSTITUTIONS_SUCCESS,
} from "redux/actions/patients";
import {
  GET_REPORT_FAIL,
  GET_REPORT_SUCCESS,
  GET_MEDICATION_ACTIVITY_SUCCESS,
  GET_MEDICATION_ACTIVITY_FAIL,
  GET_STEP_ACTIVITY_SUCCESS,
  GET_STEP_ACTIVITY_FAIL,
  FILTER_MEDICATION,
} from "../actions/patientReports";

const initialState = {
  has_pd: null,
  report: [],
  episodes: [],
  activity: [],
  prescriptions: [],
  filtered_prescriptions: [],
  institutions: [],
};

const patientsReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_SUCCESS: {
      return {
        ...state,
        has_pd: action.payload.data.data.has_pd,
        report: action.payload.data.data.values,
        episodes: action.payload.data.data.episodes,
      };
    }
    case GET_REPORT_FAIL: {
      return {
        ...state,
        report: initialState.report,
        episodes: initialState.episodes,
      };
    }
    case GET_MEDICATION_ACTIVITY_SUCCESS: {
      return {
        ...state,
        prescriptions: action.payload.data.data ?? [],
        filtered_prescriptions: action.payload.data.data ?? [],
      };
    }
    case GET_MEDICATION_ACTIVITY_FAIL: {
      return {
        ...state,
        prescriptions: initialState.prescriptions,
      };
    }
    case GET_STEP_ACTIVITY_SUCCESS: {
      return {
        ...state,
        activity: action.payload.data.data ?? [],
      };
    }
    case GET_STEP_ACTIVITY_FAIL: {
      return {
        ...state,
        activity: initialState.activity,
      };
    }
    case GET_PATIENT_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: action.payload.data.data,
      };
    case GET_PATIENT_INSTITUTIONS_FAIL:
      return {
        ...state,
        institutions: initialState.institutions,
      };
    case FILTER_MEDICATION:
      return {
        ...state,
        filtered_prescriptions: state.prescriptions.filter((med) =>
          action.payload.names.includes(med.medicine_text)
        ),
      };
    default:
      return state;
  }
};

export default patientsReport;
