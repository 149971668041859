/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* ************************************** UTILS ***************************************

//* ************************************** REDUX *****************************************
import {
  getProtocolsList,
  getFrequencyList,
  createPatientProgram,
  getPatientProgramDetails,
  addProgramProtocol,
  deleteProgramProtocol,
} from "redux/actions/patientsPrograms";

//* ************************************** GLOBALS ***************************************

import bigBackButton from "assets/images/big-left-arrow.svg";
import deleteIcon from "assets/images/delete_base.svg";
import { languageCodes } from "configs/i18n";
import NavBar from "components/navbar";
import Breadcrumbs from "components/breadcrumbs";
import Spinner from "components/spinner";
import MessageModal from "components/messageModal";

const CreateOrEditProgram = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");
  const { program_id } = useParams("program_id");

  const patientProtocols = useSelector((state) => state.patientProtocols);
  const patientProtocolsFrequency = useSelector(
    (state) => state.patientProtocolsFrequency
  );
  const patientProgramDetails = useSelector(
    (state) => state.patientProgramDetails
  );

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [frequency, setFrequency] = useState("");

  const [addedProtocols, setAddedProtocols] = useState([]);
  const [protocolsIndex, setProtocolsIndex] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log(
      "Language: " + i18n.language + " " + languageCodes[i18n.language]
    );

    const languageCode = languageCodes[i18n.language];

    dispatch(getProtocolsList(patient_id, languageCode)).then(
      () => {},
      (err) => {
        console.log("Error getProtocolsList:", err);
      }
    );
    dispatch(getFrequencyList(patient_id, languageCode)).then(
      () => {},
      (err) => {
        console.log("Error getFrequencyList:", err);
      }
    );
    if (program_id) {
      dispatch(getPatientProgramDetails(patient_id, program_id)).then(
        () => {},
        (err) => {
          console.log("Error getPatientProgramDetails:", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (program_id) {
      setName(patientProgramDetails.program_name);

      let protocolsLst = patientProgramDetails.protocols.map((protocol) => ({
        id: protocol.protocol_id,
        type: protocol.protocol_type_id,
        description: protocol.protocol_activity_name,
        frequencyId: 0,
        frequencyDescription: protocol.protocol_frequency,
      }));
      setAddedProtocols(protocolsLst);
    }
  }, [patientProgramDetails]);

  const getProtocolsOptions = () => {
    return (
      <>
        <optgroup label="Surveys">
          {patientProtocols.surveys.map((item) => (
            <option
              key={`${item.id}-${item.type}`}
              value={`${item.id}-${item.type}`}
            >
              {item.description}
            </option>
          ))}
        </optgroup>
        <optgroup label="Tests">
          {patientProtocols.tests.map((item) => (
            <option
              key={`${item.id}-${item.type}`}
              value={`${item.id}-${item.type}`}
            >
              {item.description}
            </option>
          ))}
        </optgroup>
      </>
    );
  };

  const getProtocolsFrequencyOptions = () => {
    return patientProtocolsFrequency.map((item) => (
      <option key={item.id} value={item.id}>
        {item.description}
      </option>
    ));
  };

  const addProtocol = () => {
    const protocolTypeArray = type.split("-");
    const protocolDescription =
      protocolTypeArray[1] === "1"
        ? patientProtocols.tests.find(
            (item) => `${item.id}` === protocolTypeArray[0]
          ).description
        : patientProtocols.surveys.find(
            (item) => `${item.id}` === protocolTypeArray[0]
          ).description;
    const frequencyDescription = patientProtocolsFrequency.find(
      (item) => `${item.id}` === frequency
    ).description;

    let protocol = {
      id: protocolTypeArray[0],
      type: protocolTypeArray[1],
      description: protocolDescription,
      frequencyId: frequency,
      frequencyDescription,
    };

    if (!program_id) {
      setAddedProtocols([...addedProtocols, protocol]);
    } else {
      const newProtocol = {
        protocols: [
          {
            frequency_id: protocol.frequencyId,
            module_id: protocol.id,
            module_type_id: protocol.type,
          },
        ],
      };

      dispatch(addProgramProtocol(patient_id, program_id, newProtocol)).then(
        (res) => {
          console.log("Add protocol to program :", res);
        },
        (err) => console.log("ERROR Add protocol to program :", err)
      );
    }

    setType("");
    setFrequency("");
  };

  const removeProtocol = (index) => {
    if (!program_id) {
      let newProtocolArray = [...addedProtocols];
      newProtocolArray.splice(index, 1);
      setAddedProtocols(newProtocolArray);
      setDeleteConfirmModal(false);
    } else {
      dispatch(
        deleteProgramProtocol(patient_id, program_id, addedProtocols[index].id)
      ).then(
        (res) => {
          console.log("Delete protocol of program :", res);
          dispatch(getPatientProgramDetails(patient_id, program_id)).then(
            () => {
              setDeleteConfirmModal(false);
            },
            (err) => {
              console.log("Error getPatientProgramDetails:", err);
            }
          );
        },
        (err) => console.log("ERROR Delete protocol of program :", err)
      );
    }
  };

  const saveProgram = () => {
    const newProgram = {
      name,
      protocols: addedProtocols.map((protocol) => ({
        frequency_id: protocol.frequencyId,
        module_id: protocol.id,
        module_type_id: protocol.type,
      })),
    };

    setIsSaving(true);
    dispatch(createPatientProgram(patient_id, newProgram)).then(
      (res) => {
        console.log("Create program:", res);
        setIsSaving(false);
        props.history.goBack();
      },
      (err) => {
        setIsSaving(false);
        console.log("Create program:", err);
      }
    );
  };

  const isSaveDisabled = name === "" || addedProtocols.length === 0 || isSaving;
  const canShowCancelModal = name !== "" || addedProtocols.length !== 0;
  const isCancelDisabled = isSaving || !canShowCancelModal;

  const onConfirmCancel = () => {
    setShowCancelModal(false);
    props.history.push(`/patient/${patient_id}/mkinetikos_programs`);
  };
  const onCancelCancel = () => setShowCancelModal(false);

  const onDeleteCancelHandler = () => {
    setProtocolsIndex(null);
    setDeleteConfirmModal(false);
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            history.push(`/patient/${patient_id}/mkinetikos_programs`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>
          {program_id ? t("titles.edit_program") : t("titles.create_program")}
        </h4>
      </div>
      <div className="programs-module-wrapper">
        <div className="programs-module-container">
          <div className="row-space-between">
            <span className="small-title">
              {program_id ? name : t("titles.create_program")}
            </span>
            {!program_id && (
              <div className="d-flex align-items-center">
                <button
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                  type="button"
                  className="small-button transparent-button mr-3"
                  disabled={isCancelDisabled}
                >
                  <u>{t("buttons.cancel")}</u>
                </button>
                <div className="input-image-wrapper m-0">
                  <button
                    disabled={isSaveDisabled}
                    onClick={() => {
                      saveProgram();
                    }}
                    className={`small-button secondary-button ${
                      isSaving && "transparent"
                    }`}
                    value="Send invite"
                  >
                    {t("buttons.save")}
                  </button>
                  {isSaving && <Spinner type={"small"} />}
                </div>
              </div>
            )}
          </div>
          {!program_id && (
            <div className="row mt-6">
              <div className="col-5">
                <label
                  className="d-flex justify-content-between"
                  htmlFor="name"
                >
                  <span className="input-label-text">
                    {t("patient.programs.program_name")}&nbsp;
                    {program_id === null && (
                      <span className="input-label-text warning-color">
                        {t("star")}
                      </span>
                    )}
                  </span>
                </label>
                <input
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className={`form-input`}
                  name="name"
                  id="name"
                  placeholder={t("placeholders.program_name")}
                  disabled={!!program_id}
                />
              </div>
            </div>
          )}
          <div className={`row mt-${!program_id ? "1" : "5"}`}>
            <div className="col-5">
              <label className="d-flex justify-content-between" htmlFor="type">
                <span className="input-label-text">
                  {t("patient.programs.select_test_survey")}
                </span>
              </label>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className={`w-100 dropdown ${type === "" && "notSelected"}`}
                name="type"
                id="type"
              >
                <option value={""} hidden>
                  {t("placeholders.option")}
                </option>
                {getProtocolsOptions()}
              </select>
            </div>
            <div className="col-5">
              <label
                className="d-flex justify-content-between"
                htmlFor="frequency"
              >
                <span className="input-label-text">
                  {t("patient.programs.select_frequency")}
                </span>
              </label>
              <select
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                className={`w-100 dropdown ${
                  frequency === "" && "notSelected"
                }`}
                name="frequency"
                id="frequency"
              >
                <option value={""}>{t("placeholders.option")}</option>
                {getProtocolsFrequencyOptions()}
              </select>
            </div>
            <div className="col d-flex align-items-end">
              <button
                onClick={addProtocol}
                disabled={frequency === "" || type === ""}
                className="button justify-content-center small-wider-button tertiary-button flex-fill"
                style={{ marginBottom: "24px" }}
              >
                {t("buttons.add_survey_test")}
              </button>
            </div>
          </div>
          <div className="row mt-1">
            <span className="input-label-text">
              {t("patient.programs.added_modules")}
            </span>
          </div>
          <div className="mt-3">
            {addedProtocols.map((protocol, index) => (
              <div key={index} className="row added-module-info">
                <div className="col-5">
                  <div className="regular-text black-color">
                    {protocol.description}
                  </div>
                </div>
                <div className="col-auto">
                  <div className="regular-text">|</div>
                </div>
                <div className="col">
                  <div className="regular-text">
                    {protocol.frequencyDescription}
                  </div>
                </div>
                <div className="col-auto mr-1">
                  <img
                    alt="Delete Module"
                    src={deleteIcon}
                    onClick={() => {
                      setProtocolsIndex(index);
                      setDeleteConfirmModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>{" "}
      {canShowCancelModal && showCancelModal && (
        <MessageModal
          headerText={t("modals.cancel_changes_header")}
          body={t("modals.cancel_changes_body")}
          footerButtons={[
            <button
              onClick={onCancelCancel}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={onConfirmCancel}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
      {deleteConfirmModal && protocolsIndex !== null && (
        <MessageModal
          headerText={t("modals.delete_module")}
          body={t("modals.delete_module_msg")}
          footerButtons={[
            <button
              onClick={() => onDeleteCancelHandler()}
              className="transparent-button small-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>,
            <button
              onClick={() => removeProtocol(protocolsIndex)}
              className="secondary-button small-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(CreateOrEditProgram);
