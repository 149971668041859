//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState, useRef } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************
import Spinner from "../spinner";

//* ************************************** CUSTOM HOOKS **************************************
import useOnClickOutside from "../../hooks/useOnClickOutside";

//* ****************************************** UTILS *****************************************
import uuid from "../../utils/uuid";

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import arrowDown from "../../assets/images/arrow-down.svg";
import arrowUp from "../../assets/images/arrow-up.svg";
import crossTag from "../../assets/images/evalModules/close_lightgrey.svg";
import selectedIcon from "../../assets/images/evalModules/ok_base.svg";

/**
 * DropDownInputBox component props can take:
 *  @param { leftImage } (optional)
 *  @param { placeholder }
 *  @param { config } (most important)
 *  @param { getSelectedText } (call back)
 *  @param { isDisabled }
 *  @param { preSetValue } (optional)
 *
 *  preSetValue must be a dictionary and the data fromate should be same as the selector.
 *  (see: groupPatientsImport.js line: 53 variable: preSetDropdown)
 *  (see: institutions/edit/partials/preferences.js line: 131)
 *
 *  config will be a dictionary. the formate of the dictionary is strict. the formate is:
 *  config = {
 *    show: // this will be the key of the endpoint/localstorage that need to show.
 *          // eg: {"name": Sazzadul Haque, "userName": shaque, ...} then if we want to show the name(Sazzadul Haque),
 *          // then the show: "name".
 *          // (mandatory)
 *
 *    set:  // this will be the key of the endpoint/localstorage that need to set as call back.
 *          // eg: {"name": Sazzadul Haque, "userName": shaque, ...} then if we want to set the userName(shaque),
 *          // then the show: "userName".
 *          // (mandatory)
 *
 *    dispatchFunction: // dispatchFunction will be call when a user typle in the input field for search.
 *                      // dispatchFunction should take a parameter
 *                      // eg: searchClinician = (name = "") => { ... } then dispatchFunction: searchClinician
 *                      // (mandatory)
 *
 *    isLoading: // is a state variable. it should set inside dispatchFunction. isLoading is optional. if it is true,
 *               // it will show spinner on the left side of the input field.
 *               // (optional)
 *
 *    selector:  // selector variable for the dropdown option.
 *               // (mandatory)
 *  }
 *
 *  component used: To see this component in action, you have to go my area -> mKinetikos -> then look for "Associate patients to a Clinician"
 *
 */

const DropDownInputBox = ({
  leftImage = "",
  placeholder = "Default placeholder",
  config = {
    show: "",
    set: "",
    dispatchFunction: "",
    isLoading: "",
    selector: "",
  },
  getSelectedText = "",
  isDisabled = false,
  multiSelect = false,
  preSetValue = "",
  reset = "not_reset",
}) => {
  //* **************************************** SETUP *****************************************
  const [searchText, setSearchText] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [tempMultiValueCB, setTempMultiValueCB] = useState([]);
  const textRef = useRef(null);
  const dropDownBoxRef = useRef(null);

  useOnClickOutside([dropDownBoxRef], () => setIsFocus(false));

  //* ************************************** HANDLERS ***************************************
  useEffect(() => {
    if (preSetValue) {
      let preSet = config.selector.filter((value) => {
        return preSetValue.map((preset) => preset[config.set]).includes(value[config.set]);
      });

      if (multiSelect) {
        setSearchText(
          preSet.map((value) => {
            return value[config.show];
          })
        );
        setTempMultiValueCB(
          preSet.map((value) => {
            return value[config.set];
          })
        );
      } else {
        if (preSet && preSet.length > 0) {
          let show = preSet[0][config.show];
          let set = preSet[0][config.set];
          setSearchText(show);
          getSelectedText(set);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (reset === "") {
      setSearchText("");
      setTempMultiValueCB([]);
      if (getSelectedText) {
        getSelectedText("");
      }
    }
  }, [reset]);

  useEffect(() => {
    if (multiSelect && getSelectedText) {
      getSelectedText(tempMultiValueCB);
    }
  }, [tempMultiValueCB]);

  useEffect(() => {
    if (
      (isFocus && searchText !== "" && searchText?.length > 2 && config.dispatchFunction) ||
      ((searchText === "" || searchText.length === 0) && config.dispatchFunction)
    ) {
      config.dispatchFunction(searchText);
    }
    // setIsLoading(false);
  }, [searchText]);

  const removeTag = (value) => {
    setSearchText(
      searchText.filter((remove) => {
        return remove !== value[config.show];
      })
    );
    setTempMultiValueCB(
      tempMultiValueCB.filter((remove) => {
        return remove !== value[config.set];
      })
    );
  };

  const selectMultiValuesHandler = (value) => {
    if (searchText.includes(value[config.show])) {
      removeTag(value);
    } else {
      setSearchText([...searchText, value[config.show]]);
      setTempMultiValueCB([...tempMultiValueCB, value[config.set]]);
    }
  };

  const removeTagCrossBtn = (key) => {
    removeTag(
      config.selector.filter((value) => {
        return value[config.show] === key;
      })[0]
    );
  };

  const selectSingelValueHandler = (value) => {
    setSearchText(value[config.show]);
    if (getSelectedText) {
      getSelectedText(value[config.set]);
    }
    setIsFocus(false);
  };

  //* ************************************** PARTIALS ****************************************
  const __renderDropDownBox = () => {
    return (
      <>
        <div className="drop-down-box" ref={dropDownBoxRef}>
          {config.selector && config.selector.length > 0 ? (
            config.selector.map((value) => {
              return (
                <div
                  key={value.id}
                  className="items"
                  onClick={() => {
                    multiSelect ? selectMultiValuesHandler(value) : selectSingelValueHandler(value);
                  }}
                >
                  <span>
                    {value[config.show]}{" "}
                    {multiSelect && searchText.includes(value[config.show]) && (
                      <img src={selectedIcon} alt={"selected"} />
                    )}
                  </span>
                </div>
              );
            })
          ) : (
            <span className="no-result">no result found</span>
          )}
        </div>
      </>
    );
  };

  const __renderMultiSelect = () => {
    return (
      <ul className="w-100 dropdown custom-dropdown">
        {searchText &&
          searchText.map((value) => {
            return (
              <li className="tag" key={uuid()}>
                {value}{" "}
                <img alt={"cross"} src={crossTag} onClick={() => removeTagCrossBtn(value)} />
              </li>
            );
          })}
        <input
          type="text"
          className={`search-input w-100 multi-select`}
          placeholder={placeholder}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onFocus={() => {
            setIsFocus(true);
          }}
          disabled={isDisabled}
          ref={textRef}
          onKeyDown={(event) => {
            event.preventDefault();
          }}
        />
      </ul>
    );
  };

  //* *************************************** RENDER *****************************************
  return (
    <>
      <div className={`position-relative ${!isFocus && `mb-4`}`}>
        {leftImage && <img alt="search-icon" className="right-icon mt-2" src={leftImage} />}
        {multiSelect ? (
          __renderMultiSelect()
        ) : (
          <input
            type="text"
            className={`search-input w-100 mt-2 ${isFocus ? `mb-1` : `mb-3`} ${
              leftImage ? `pl-4` : `pl-1`
            }`}
            placeholder={placeholder}
            value={searchText ? searchText : ""}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onFocus={() => {
              setIsFocus(true);
            }}
            disabled={isDisabled}
            ref={textRef}
          />
        )}
        {config.isLoading ? (
          <div className="loading mt-2">
            <Spinner type={"small-blue"} />
          </div>
        ) : (
          <img
            alt="search-icon"
            className={`arrow-img ${!multiSelect && "mt-2"}`}
            src={isFocus ? arrowUp : arrowDown}
            onClick={() => {
              !isFocus && textRef.current.focus();
              setIsFocus(!isFocus);
            }}
          />
        )}
      </div>
      {isFocus && __renderDropDownBox()}
    </>
  );
};

export default React.memo(DropDownInputBox);
