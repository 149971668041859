//* *********************************** REACT IMPORTS ************************************
import React, { useState, useRef, useEffect } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SelectWeekDays from "../../../../components/selectWeekDays/index.js";
import MessageModal from "../../../../components/messageModal/index.js";
import VideoPlayer from "../../../../components/showMedia/index.js";
import ShowImage from "../../../../components/showMedia/index.js";
import Spinner from "../../../../components/spinner/index.js";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../../assets/images/big-left-arrow.svg";
import calendar from "../../../../assets/images/calendar-small.svg";
import helpIcon from "../../../../assets/images/help.svg";
import {
  getExerciseDetails,
  updateExerciseDetails,
  deleteExerciseDetails,
} from "../../../../redux/actions/patientExercises.js";
import { errors } from "../../../../constants/general.js";
import { dateToString } from "../../../../utils/dataAndTime.js";
import NavBar from "../../../../components/navbar/index.js";
import Breadcrumbs from "../../../../components/breadcrumbs";
import { localeForDatePicker } from "utils/language.js";
import defaultExerciseImage from "assets/images/default_exercise.png";

const EditExercise = (props) => {
  //* *************************************** SETUP ***************************************
  const exerciseInfo = useSelector(
    (state) => state.patientExercises.curr_exercise
  );
  const exerciseImageURL = useSelector((state) => {
    return state.patientExercises.curr_exercise.exercise.is_kinetikos_exercise
      ? state.patientExercises.curr_exercise.exercise.image
        ? `${process.env.REACT_APP_BACKEND_HOST}/static/${state.patientExercises.curr_exercise.exercise.image}`
        : defaultExerciseImage
      : state.patientExercises.curr_exercise.exercise.image;
  });
  const datePickerInput = useRef(null);

  const dispatch = useDispatch();

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isDeleteExerciseModalOpen, setIsDeleteExerciseModalOpen] =
    useState(false);

  const { patient_id, exercise_id } = useParams();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [exerciseDetails, setExerciseDetails] = useState({
    id: null,
    days: [],
    sets: "",
    reps: "",
    duration: "",
    active: false,
    end_date: null,
  });

  const [exerciseDetailsErrors, setExerciseDetailsErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        if (showImageModal) {
          setShowImageModal(false);
        } else if (showVideoModal) {
          setShowVideoModal(false);
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    dispatch(getExerciseDetails(patient_id, exercise_id))
      .then((res) => {
        if (res.payload && res.payload.data) {
          const { details } = res.payload.data.data;
          setExerciseDetails(details);
        }
      })
      .catch(() => {
        alert(errors.service_error);
      });
  }, []);

  //* ************************************** HANDLERS **************************************
  const updateCurrExerciseDetails = () => {
    setIsLoading(true);
    dispatch(updateExerciseDetails(patient_id, exercise_id, exerciseDetails))
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setExerciseDetailsErrors(data.form_errors);
          }
        } else {
          alert(errors.service_error);
        }
      })
      .then(() => {
        props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteExercise = () => {
    setIsDeleting(true);
    dispatch(deleteExerciseDetails(patient_id, exercise_id))
      .then(() => {
        setIsDeleteExerciseModalOpen(false);
        props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);
      })
      .catch(() => alert(errors.service_error))
      .finally(() => setIsDeleting(false));
  };
  const changeSelectDays = (day) => {
    let newSelectedList = [...exerciseDetails.days];
    if (exerciseDetails.days.includes(day)) {
      newSelectedList = exerciseDetails.days.filter((item) => item !== day);
    } else {
      newSelectedList.push(day);
    }
    setExerciseDetails({ ...exerciseDetails, days: newSelectedList });
  };

  const changeDate = (newDate) => {
    setExerciseDetails({
      ...exerciseDetails,
      end_date: newDate ? dateToString(newDate) : null,
    });
  };

  const inputsNumberHandlerExerciseDetails = (e) => {
    const regexp = /^[1-9]\d*$/;
    if (regexp.test(e.target.value) || e.target.value === "")
      setExerciseDetails({
        ...exerciseDetails,
        [e.target.name]: e.target.value,
      });
  };

  const goBackToExerciseList = () =>
    props.history.replace(`/patient/${patient_id}/mkinetikos_exercises`);

  const areMandatoryFieldsFilled =
    (exerciseDetails.sets && exerciseDetails.days && exerciseDetails.reps) ||
    exerciseDetails.duration;

  const isButtonOrInputDisable = isLoading || isDeleting;

  const hasInformationNotChanged =
    JSON.stringify(exerciseDetails) !== JSON.stringify(exerciseInfo.details);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container position-relative">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={goBackToExerciseList}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.edit_exercise")}</h4>
      </div>
      <div className="position-relative">
        <div className={`create-exercise-box`}>
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title mt-1">
              {t("patient.exercises.edit_exercise")}
            </div>
            <div className="mt-1 flex-d-row d-flexalign-items-center">
              <div className="mr-1">
                <input
                  disabled={isButtonOrInputDisable}
                  value={t("buttons.delete")}
                  onClick={() => setIsDeleteExerciseModalOpen(true)}
                  type="button"
                  className={`warning-button small-button`}
                />
              </div>
              <button
                onClick={() => setIsCancelModalOpen(true)}
                className="transparent-button small-button mr-1"
              >
                <u>{t("buttons.cancel")}</u>
              </button>
              <div>
                <input
                  disabled={
                    isButtonOrInputDisable ||
                    !areMandatoryFieldsFilled ||
                    !hasInformationNotChanged
                  }
                  value={t("buttons.save")}
                  onClick={updateCurrExerciseDetails}
                  type="button"
                  className={`secondary-button small-button ${
                    isLoading && "transparent"
                  }`}
                />
                {isLoading && <Spinner type={"small"} />}
              </div>
            </div>
          </div>
          <div className="mt-4 flex-d-column regular-text black-color">
            <label className="input-label-text">
              {t("patient.exercises.exercise_name")}
            </label>
            {exerciseInfo.exercise.name}
            <label className="input-label-text mt-4">
              {t("patient.exercises.exercise_description")}
            </label>
            {exerciseInfo.exercise.description}
            <div className="row mt-4">
              {exerciseInfo.exercise.image && (
                <div className="col-lg col-md-12">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>{t("files.image")}</span>
                  </label>
                  <div className="exercise-show-img-video">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-auto">my_image_file.jpg</div>
                      <div
                        onClick={() => setShowImageModal(true)}
                        role="button"
                        className="col-auto"
                      >
                        <u>{t("files.watch_image")}</u>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {exerciseInfo.exercise.video && (
                <div className="col-lg col-md-12">
                  <label className="input-label-text d-flex justify-content-between">
                    <span>{t("files.video")}</span>
                  </label>

                  <div className="exercise-show-img-video">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-auto">video_example.mp4</div>
                      <div
                        onClick={() => setShowVideoModal(true)}
                        role="button"
                        className="col-auto"
                      >
                        <u>{t("files.watch_video")}</u>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6 flex-d-column">
              <div className="small-title">
                {t("patient.exercises.exercise_details")}
              </div>
              <label className="input-label-text mt-4">
                <span>
                  {t("patient.exercises.number_of_sets")}{" "}
                  <span className="warning-color">{t("star")}</span>
                </span>
              </label>
              <input
                type="text"
                disabled={isButtonOrInputDisable}
                onChange={inputsNumberHandlerExerciseDetails}
                placeholder={t("placeholders.number_of_sets")}
                autoComplete="off"
                className={`form-input  exercise-details-input ${
                  exerciseDetailsErrors.sets ? "error" : ""
                }`}
                name="sets"
                value={exerciseDetails.sets}
                id="sets"
              />
              <div className="row flex-d-row align-items-center">
                <div className="col-auto flex-d-column">
                  <label className="input-label-text mt-2">
                    <span>
                      {t("patient.exercises.number_of_reps")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    <>
                      <img
                        alt="Help"
                        data-tip={t("patient.exercises.reps_tip")}
                        data-for={`repetitions-metric`}
                        src={helpIcon}
                        className="help-img"
                      />
                      <ReactTooltip
                        className="tooltip"
                        id={`repetitions-metric`}
                        place="top"
                        effect="solid"
                      />
                    </>
                  </label>
                  <input
                    disabled={
                      isButtonOrInputDisable || exerciseDetails.duration
                    }
                    onChange={inputsNumberHandlerExerciseDetails}
                    placeholder={t("placeholders.number_of_reps")}
                    autoComplete="off"
                    className={`form-input  exercise-details-input ${
                      exerciseDetailsErrors.reps ? "error" : ""
                    }`}
                    name="reps"
                    id="reps"
                    value={exerciseDetails.reps}
                    type="text"
                  />
                </div>
                <div className="col-auto ml-6 regular-text mr-6 mt-2">
                  <u>{t("or")}</u>
                </div>
                <div className="col-auto flex-d-column">
                  <label className="input-label-text mt-1">
                    <span>
                      {t("patient.exercises.duration")}{" "}
                      <span className="warning-color">{t("star")}</span>
                    </span>
                    <>
                      <img
                        alt="Help"
                        data-tip={t("patient.exercises.duration_tip")}
                        data-for={`repetitions-metric`}
                        src={helpIcon}
                        className="help-img"
                      />
                      <ReactTooltip
                        className="tooltip"
                        id={`repetitions-metric`}
                        place="top"
                        effect="solid"
                      />
                    </>
                  </label>
                  <input
                    onChange={inputsNumberHandlerExerciseDetails}
                    value={exerciseDetails.duration}
                    disabled={isButtonOrInputDisable || exerciseDetails.reps}
                    placeholder={t("placeholders.minutes")}
                    autoComplete="off"
                    className={`form-input  exercise-details-input ${
                      exerciseDetailsErrors.duration ? "error" : ""
                    }`}
                    name="duration"
                    id="duration"
                    type="text"
                  />
                </div>
              </div>
              <label className="input-label-text mt-2">
                <span>
                  {t("patient.exercises.select_frequency")}{" "}
                  <span className="warning-color">{t("star")}</span>
                </span>
              </label>
              <SelectWeekDays
                disabled={isButtonOrInputDisable}
                activeDays={exerciseDetails.days}
                changeSelectDays={changeSelectDays}
              />
              <label className="input-label-text mt-4">
                {t("patient.exercises.end_date")}
              </label>
              <div className="input-image-wrapper image-right exercise-details-input">
                <DatePicker
                  disabled={isButtonOrInputDisable}
                  ref={datePickerInput}
                  autoComplete="off"
                  className={`form-input  ${
                    exerciseDetailsErrors.end_date ? "error" : ""
                  }`}
                  name="date"
                  id="date"
                  placeholderText={t("placeholders.date")}
                  locale={localeForDatePicker(i18n.language)}
                  minDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                  selected={
                    exerciseDetails.end_date
                      ? new Date(exerciseDetails.end_date)
                      : null
                  }
                  onChange={(date) => changeDate(date)}
                />
                <img
                  disabled={isButtonOrInputDisable}
                  onClick={() => datePickerInput.current.setOpen(true)}
                  alt="calendar"
                  className="input-right-image"
                  src={calendar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteExerciseModalOpen && (
        <MessageModal
          headerText={t("modals.delete_exercise_header")}
          body={t("modals.delete_exercise_body")}
          footerButtons={[
            <button
              onClick={() => setIsDeleteExerciseModalOpen(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                disabled={isButtonOrInputDisable}
                type="button"
                value={t("buttons.delete")}
                onClick={() => deleteExercise()}
                className="warning-button small-wider-button"
              />
            </div>,
          ]}
        />
      )}
      {isCancelModalOpen && (
        <MessageModal
          headerText={t("modals.cancel_add_action_header")}
          body={t("modals.cancel_add_action_body")}
          footerButtons={[
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <button
              onClick={goBackToExerciseList}
              className="small-button secondary-button"
            >
              {t("buttons.confirm")}
            </button>,
          ]}
        />
      )}

      {showVideoModal && (
        <VideoPlayer
          url={exerciseInfo.exercise.video}
          close={() => setShowVideoModal(false)}
        />
      )}
      {showImageModal && (
        <ShowImage
          url={exerciseImageURL}
          type="image"
          close={() => setShowImageModal(false)}
        />
      )}
    </div>
  );
};

export default EditExercise;
