//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************

//* ************************************* OUR COMPONENTS *************************************
import Icon from "components/icon/index.js";
import { icons } from "components/icon/icons.js";

//* ************************************** CUSTOM HOOKS **************************************

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************

const MaxDailyIntake = ({
  medicineDetails,
  maxIntakeCounter,
  resetMaxIntake,
}) => {
  //* **************************************** SETUP *****************************************
  const { t, i18n } = useTranslation();
  // const dispatch = useDispatch()
  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <div className="col mt-2" disabled={!medicineDetails.is_sos}>
      <label
        className="d-flex justify-content-between"
        htmlFor="prescription_id"
      >
        <span className="input-label-text">
          {t("patient.meds.maximum_intakes")}
        </span>
      </label>
      <div className="row">
        <div className="profile-notifications-input-group-plus-minus">
          <input
            type="button"
            value="-"
            onClick={() =>
              maxIntakeCounter(medicineDetails.max_daily_intake - 1)
            }
            className="button-minus plus-minus"
            data-field="quantity"
          />
          <input
            style={{ backgroundColor: "white" }}
            disabled={true}
            value={medicineDetails.max_daily_intake || "---"}
            name="events_count_th"
            className="quantity-field text-center"
          />
          <input
            onClick={() =>
              maxIntakeCounter(medicineDetails.max_daily_intake + 1)
            }
            type="button"
            value="+"
            className="button-plus plus-minus"
            data-field="quantity"
          />
        </div>
        <Icon
          icon={icons["reset-icon"]}
          className={"reset-button"}
          onClick={() => resetMaxIntake()}
        />
      </div>
    </div>
  );
};

export default MaxDailyIntake;
