import {
  GET_PATIENT_PROGRAMS_FAIL,
  GET_PATIENT_PROGRAMS_SUCCESS,
} from "../actions/patientsPrograms";

const initialState = {
  obj_list: [],
  count: 0,
  page: 0,
  num_pages: 0,
};

const patientPrograms = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_PROGRAMS_SUCCESS: {
      return { ...state, ...action.payload.data.data };
    }
    case GET_PATIENT_PROGRAMS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientPrograms;
