import { useTranslation } from "react-i18next";

const Password = ({ goToChangePassword }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="password-wrapper">
      <span className="small-title">
        {t("authentication.password.password")}
      </span>
      <div className="password-body">
        <label className="input-label-text">
          {t("authentication.password.current_password")}
        </label>

        <input
          disabled={true}
          name="password"
          id="password"
          type={"password"}
          placeholder="**************"
          className="form-input"
        />
      </div>
      <div className="password-footer">
        <button
          onClick={goToChangePassword}
          className="small-button primary-button"
        >
          {t("authentication.reset_password.change_password")}
        </button>
      </div>
    </div>
  );
};

export default Password;
