/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SurveysModule from "./partials/surveysModule";
import SurveyResultsModule from "./partials/surveyResultsModule";

//* ************************************** GLOBALS ***************************************
import backButton from "../../../assets/images/back-button.svg";
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import crossIcon from "../../../assets/images/cross_darkgrey.svg";
import crossSelectedIcon from "../../../assets/images/cross_darkgrey_selected.svg";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";

const PatientMkinetikosSurveys = (props) => {
  const history = useHistory();
  const { patient_id } = useParams("patient_id");
  const { survey_id } = useParams("survey_id");

  const { t, i18n } = useTranslation();
  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            history.push(`/patient/${patient_id}/mkinetikos_programs`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.surveys")}</h4>
      </div>
      <div className="regular-text">{t("patient.programs.you_can_check")}</div>
      <div className="programs-filter-container">
        <div
          className="programs-filter-btn"
          onClick={() => {
            props.history.push(`/patient/${patient_id}/mkinetikos_tests`);
          }}
        >
          {t("patient.programs.tests")}
        </div>
        <div
          className="programs-filter-btn active"
          onClick={() => {
            props.history.push(`/patient/${patient_id}/mkinetikos_programs`);
          }}
        >
          {t("patient.programs.surveys")}
        </div>
        <div
          onClick={() => {
            props.history.push(`/patient/${patient_id}/mkinetikos_programs`);
          }}
        >
          <img
            alt="Close Programs Filter"
            className="programs-filter-close-btn"
            onMouseOver={(e) => (e.currentTarget.src = crossSelectedIcon)}
            onMouseOut={(e) => (e.currentTarget.src = crossIcon)}
            src={crossIcon}
          />
        </div>
      </div>

      {survey_id ? (
        <>
          <div
            className="button back-button-wrapper mb-2 align-self-start align-items-center"
            onClick={() => props.history.goBack()}
          >
            <img alt="back Button" src={backButton} />
            {t("buttons.back")}
          </div>
          <SurveyResultsModule history={props.history} />
        </>
      ) : (
        <SurveysModule itemsPerPage={10} history={props.history} />
      )}
    </div>
  );
};

export default React.memo(PatientMkinetikosSurveys);
