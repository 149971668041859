/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************

const SidebarHamburger = ({ onClick, showLeftRadius }) => {
  //* ************************************** SETUP **************************************

  //* ************************************** RENDER **************************************
  return (
    <button
      onClick={onClick}
      className={`sidebar-hamburger ${showLeftRadius && "showLeftRadius"}`}
    >
      <div className="sidebar-hamburger-inner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  );
};

export default SidebarHamburger;
