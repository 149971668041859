//* *********************************** REACT IMPORTS ************************************
import React, { useRef } from "react";
import { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import SaveTemplateModal from "../../../../components/evaluationTemplates/saveTemplateModal";
import AddModule from "../../../../components/evaluationTemplates/addModule";
import CurrModules from "../../../../components/evaluationTemplates/currModules";
import NavBar from "../../../../components/navbar";
//* ************************************** GLOBALS ***************************************
import { addNewEvaluationTemplate } from "../../../../redux/actions/evaluationTemplates";

import edit_blue from "../../../../assets/images/edit-blue.svg";
import clinical_data from "../../../../assets/images/clinical-data.svg";
import questionnaries from "../../../../assets/images/questionnaries.svg";
import treatments from "../../../../assets/images/treatments.svg";
import kinematic_tests from "../../../../assets/images/kinematic-tests.svg";
import big_arrow_left from "../../../../assets/images/big-left-arrow.svg";

import { moduleTypes } from "../../../../constants/general";
const CreateEvaluationTemplates = (props) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [currSelectedModuleType, setCurrSelectedModuleType] = useState(null);
  const [modules, setModules] = useState([]);
  const [evaluationTemplateTitle, setEvaluationTemplateTitle] = useState("");

  const [isSaveNewTemplateOpen, setIsSaveNewTemplateOpen] = useState(false);

  const [isSavingToInstitution, setIsSavingToInstitution] = useState(false);
  const [isSavingToUser, setIsSavingToUser] = useState(false);

  const currModuleTypeList = useSelector(
    (state) => state.evaluationTemplates.module_type_list
  );

  const titleInputRef = useRef(null);

  //* ************************************** HANDLERS **************************************

  const inputsHandler = (e) => {
    setEvaluationTemplateTitle(e.target.value);
  };

  const saveNewEvaluationTemplate = (is_owner_institution) => {
    is_owner_institution
      ? setIsSavingToInstitution(true)
      : setIsSavingToUser(true);
    const template_ids = modules.map((module) => module.id);
    dispatch(
      addNewEvaluationTemplate(
        template_ids,
        evaluationTemplateTitle,
        is_owner_institution
      )
    )
      .then(() => {
        props.history.replace("/my_area/evaluation_templates");
      })
      .catch(() =>
        alert(
          "It was not possible to create the template at this moment. Please try again later or contact support centre if the problem persists"
        )
      )
      .finally(() =>
        is_owner_institution
          ? setIsSavingToInstitution(false)
          : setIsSavingToUser(false)
      );
  };

  const getCurrentModuleTypeImage = (image) => {
    if (image === moduleTypes.clinicalData || image === 1) return clinical_data;
    else if (image === moduleTypes.intervention || image === 2)
      return treatments;
    else if (image === moduleTypes.kinematicTest || image === 4)
      return kinematic_tests;
    else if (image === moduleTypes.questionnarie || image === 3)
      return questionnaries;
    return null;
  };

  const addModule = (module) => {
    module.type = currSelectedModuleType;
    setModules([module, ...modules]);
  };

  const deleteModule = (moduleIndex) => {
    const newModules = [...modules];
    newModules.splice(moduleIndex, 1);
    setModules(newModules);
  };

  const goBack = () => {
    if (props.location.cameFromEvaluationTemplateHome) {
      props.history.goBack();
    } else {
      props.history.replace("/my_area/evaluation_templates");
    }
  };

  const isSaveDisabled = modules.length === 0 || evaluationTemplateTitle === "";
  //* ************************************** RENDER **************************************

  return (
    <div className="col profile-body">
      <NavBar />

      <div className="flex-d-row align-items-center">
        <img
          onClick={() => props.history.replace("/my_area/evaluation_templates")}
          alt="left arrow"
          className="left-arrow"
          src={big_arrow_left}
        />
        <h4 className="m-0">{t("my_area.templates.create")}</h4>
      </div>
      <div className="profile_create_edit_templates">
        <div className="row row-space-between">
          <div className="col-auto">
            <div className="input-title-wrapper image-right">
              <input
                type="text"
                autoComplete="off"
                ref={titleInputRef}
                value={evaluationTemplateTitle}
                name="name"
                className="form-input-3 w-100"
                onChange={inputsHandler}
              />
              <img
                onClick={() => titleInputRef.current.focus()}
                className="edit-input default-cursor"
                alt="Edit blue"
                src={edit_blue}
              />
            </div>
          </div>

          <div className="save-cancel-wrapper">
            <button
              onClick={goBack}
              className="small-wider-button transparent-button"
            >
              <u> {t("buttons.cancel")} </u>
            </button>
            <input
              onClick={() => setIsSaveNewTemplateOpen(true)}
              disabled={isSaveDisabled}
              value={t("buttons.save_template")}
              type="submit"
              className="small-wider-button secondary-button"
            />
          </div>
        </div>

        <div className="row modules-body">
          <AddModule
            currModuleTypeList={currModuleTypeList}
            setCurrSelectedModuleType={(type) =>
              setCurrSelectedModuleType(type)
            }
            currSelectedModuleType={currSelectedModuleType}
            getCurrentModuleTypeImage={(image) =>
              getCurrentModuleTypeImage(image)
            }
            addModule={(module) => addModule(module)}
            addedModuleIDs={modules.map((module) => module.id)}
          />
          <CurrModules
            deleteModule={(moduleId) => deleteModule(moduleId)}
            modules={modules}
            getCurrentModuleTypeImage={(image) =>
              getCurrentModuleTypeImage(image)
            }
          />
        </div>
      </div>
      {isSaveNewTemplateOpen && (
        <SaveTemplateModal
          saveNewEvaluationTemplate={saveNewEvaluationTemplate}
          cancel={() => setIsSaveNewTemplateOpen(false)}
          isSavingToUser={isSavingToUser}
          isSavingToInstitution={isSavingToInstitution}
        />
      )}
    </div>
  );
};

export default React.memo(CreateEvaluationTemplates);
