//* ********************************** REACT IMPORTS **********************************
import { useState } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import { signup, getTermsAndConditions } from "../../../redux/actions/user";
import { getLanguageID, getLanguageString } from "../../../utils/language";
import show_password_logo from "../../../assets/images/show-password.svg";
import hide_password_logo from "../../../assets/images/hide-password.svg";

const SignUp = ({
  navigateToHome,
  showErrorPage,
  changeToLogin,
  changeToSuccessSignUp,
}) => {
  const { i18n } = useTranslation();

  const { search } = useLocation();
  const { invite_token } = queryString.parse(search);
  const [signUpInfo, setSignUpInfo] = useState({
    full_name: "",
    username: "",
    language_id: getLanguageID(i18n.language),
    password1: "",
    password2: "",
    terms_and_conditions: false,
    e_newsletter: false,
    invite_token: invite_token,
  });
  const [isPasswordHidden2, setIsPasswordHidden2] = useState(true);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const [signUpInfoErrors, setSignUpInfoErrors] = useState({});
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const { t, i181n } = useTranslation();

  const dispatch = useDispatch();

  // const [searchParams, setSearchParams] = useSearchParams();

  //* ************************************** HANDLERS **************************************

  const inputsHandler = (e) => {
    if (e.target.name === "language_id") {
      i18n
        .changeLanguage(getLanguageString(parseInt(e.target.value)))
        .then((res) => console.log("Changed language to: " + i18n.language));
    }
    delete signUpInfoErrors[e.target.name];
    setSignUpInfo({
      ...signUpInfo,
      [e.target.name]: e.target.value,
    });
  };

  const removeSpace = (e) => {
    const tempUserInfo = {};
    for (var key in signUpInfo) {
      if (key === "full_name" || key === "username" || key === "email") {
        tempUserInfo[key] = signUpInfo[key].trim();
      } else {
        tempUserInfo[key] = signUpInfo[key];
      }
    }

    setSignUpInfo(tempUserInfo);
  };

  const checkBoxHandler = (e) => {
    setSignUpInfo({
      ...signUpInfo,
      [e.target.name]: e.target.checked,
    });
  };

  const submitSignUp = (e) => {
    e.preventDefault();
    setIsSignUpLoading(true);
    dispatch(signup(signUpInfo))
      .then(async (res) => {
        changeToSuccessSignUp();
      })
      .catch((error) => {
        if (error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setSignUpInfoErrors(data.form_errors);
          } else {
            showErrorPage();
          }
        }
      })
      .finally(() => setIsSignUpLoading(false));
  };

  const isSignUpDisabled =
    signUpInfo.full_name === "" ||
    signUpInfo.username === "" ||
    signUpInfo.password1 === "" ||
    signUpInfo.password2 === "" ||
    signUpInfo.terms_and_conditions === false;

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      <div className="row align-items-center">
        <div className="col">
          <h2>{t("titles.sign_up")}</h2>
        </div>
        <div className="col-md-auto">
          <select
            value={signUpInfo.language_id}
            onChange={inputsHandler}
            className={"dropdown-auth"}
            name="language_id"
            id="language_id"
          >
            <option value={2}>{t("languages.en")}</option>
            <option value={1}>{t("languages.pt")}</option>
          </select>
        </div>
      </div>
      <p
        style={{ marginTop: "1.44em", marginBottom: "2.00em" }}
        className="regular-text"
      >
        {t("forms.all_fields")}{" "}
        <span className="warning-color">{t("star")}</span>{" "}
        {t("forms.are_mandatory")}
      </p>
      <form onSubmit={submitSignUp}>
        <div className="row align-items-center">
          <div className="col">
            <label
              htmlFor="full_name"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("user_info.full_name")}&nbsp;
                <span className="warning-color">{t("star")}</span>{" "}
              </span>
              {signUpInfoErrors.full_name && (
                <span className="warning-color">
                  {signUpInfoErrors.full_name}
                </span>
              )}
            </label>
            <input
              autoComplete="off"
              onChange={inputsHandler}
              onBlur={removeSpace}
              value={signUpInfo.full_name}
              name="full_name"
              type="text"
              id="full_name"
              className={`form-input ${signUpInfoErrors.full_name && "error"}`}
              placeholder={t("placeholders.full_name")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="username"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("user_info.username")}&nbsp;
                <span className="warning-color">{t("star")}</span>{" "}
              </span>
              {signUpInfoErrors.username && (
                <span className="warning-color">
                  {signUpInfoErrors.username}
                </span>
              )}
            </label>
            <input
              autoComplete="off"
              className={`form-input ${signUpInfoErrors.username && "error"}`}
              onChange={inputsHandler}
              onBlur={removeSpace}
              value={signUpInfo.username}
              name="username"
              type="text"
              id="username"
              placeholder={t("placeholders.min_username")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="email"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("user_info.email")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {signUpInfoErrors.email && (
                <span className="warning-color">{signUpInfoErrors.email}</span>
              )}
            </label>
            <input
              autoComplete="off"
              className={`form-input  ${signUpInfoErrors.email && "error"}`}
              onChange={inputsHandler}
              onBlur={removeSpace}
              value={signUpInfo.email}
              name="email"
              type="text"
              id="email"
              placeholder={t("placeholders.email")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="password1"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("authentication.password.password")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {signUpInfoErrors.password1 && (
                <span className="warning-color">
                  {signUpInfoErrors.password1}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                autoComplete="off"
                className={`form-input ${
                  signUpInfoErrors.password1 && "error"
                }`}
                onChange={inputsHandler}
                value={signUpInfo.password1}
                name="password1"
                type={isPasswordHidden ? "password" : "text"}
                id="password1"
                placeholder={t("placeholders.min_password")}
              />
              <img
                onClick={() => setIsPasswordHidden(!isPasswordHidden)}
                alt="Password Show and Hide"
                className="input-right-image"
                src={isPasswordHidden ? show_password_logo : hide_password_logo}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="password2"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("authentication.password.confirm_password")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {signUpInfoErrors.password2 && (
                <span className="warning-color">
                  {signUpInfoErrors.password2}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                className={`form-input ${
                  signUpInfoErrors.password2 && "error"
                }`}
                onChange={inputsHandler}
                value={signUpInfo.password2}
                name="password2"
                id="password2"
                autoComplete="off"
                type={isPasswordHidden2 ? "password" : "text"}
                placeholder={t("placeholders.confirm_password")}
              />
              <img
                onClick={() => setIsPasswordHidden2(!isPasswordHidden2)}
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isPasswordHidden2 ? show_password_logo : hide_password_logo
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="check-box-wrapper">
            <div className="input-wrapper col-md-auto">
              <input
                name="e_newsletter"
                checked={signUpInfo.e_newsletter}
                onChange={checkBoxHandler}
                type={"checkbox"}
              />
            </div>
            <label type="checkbox">
              <span className="regular-text">
                {t("authentication.signup.receive_newsletter")}
              </span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="check-box-wrapper">
            <div className="input-wrapper col-md-auto">
              <input
                name="terms_and_conditions"
                className={`${
                  signUpInfoErrors.terms_and_conditions && "error"
                }`}
                checked={signUpInfo.terms_and_conditions}
                onChange={checkBoxHandler}
                type={"checkbox"}
              />
            </div>
            <label type="checkbox">
              <span className="regular-text">
                <Trans i18nKey={"authentication.signup.agree_with"}>
                  <span
                    className="link-text"
                    onClick={() =>
                      window.open("/terms_and_conditions", "_blank")
                    }
                  ></span>
                </Trans>
                <span className="warning-color">{t("star")}</span>
              </span>
            </label>
          </div>
        </div>
        <div className="form-auth-bottom">
          <div className="input-image-wrapper m-0">
            <input
              disabled={isSignUpDisabled}
              value={isSignUpLoading ? "" : "Sign up"}
              type="submit"
              className="primary-button small-button w-100"
            />
            {isSignUpLoading && <Spinner type={"small"} />}
          </div>
          <div className="auth-bottom-element">
            <span className="regular-text">
              {t("authentication.signup.already_account")}&nbsp;
            </span>
            <span onClick={changeToLogin} className="link-text">
              {t("authentication.signup.login")}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
