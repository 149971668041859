//* ******************************** REACT NATIVE IMPORTS ********************************
import { useState, useEffect, memo } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import MessageModal from "../../../components/messageModal";

import PersonalInfoForm from "./partials/personalInfoForm";
import ContactInfoForm from "./partials/contactInfoForm";
import ClinicalDataForm from "./partials/clinicalDataForm";
import IdentificationDocumentsForm from "./partials/identificationDocumentsForm";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";
//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import backButton from "../../../assets/images/back-button.svg";
import {
  getPatientPersonalData,
  editPatientPersonalData,
  getPatientContactInfo,
  editPatientContactInfo,
  getPatientClinicalData,
  editPatientClinicalData,
  getPatientIdentification,
  editPatientIdentification,
} from "../../../redux/actions/patients";

const PatientEdit = (props) => {
  //* *************************************** SETUP ****************************************
  const { patient_id } = useParams();
  const dispatch = useDispatch();
  const patientProfile = useSelector((state) => state.patientProfile);

  const [currStep, setCurrStep] = useState(1);

  const medicalConditions = patientProfile?.medical_conditions;

  const [personalInfo, setPersonalInfo] = useState({
    full_name: patientProfile.full_name ?? "",
    birth_date: patientProfile.birth_date ?? "",
    process_number: patientProfile.process_number ?? "",
    consent_check: patientProfile.consent_check ?? false,
  });

  const [contactInfo, setContactInfo] = useState({
    email: patientProfile.email ?? "",
    nationality_id: patientProfile.nationality_id
      ? "" + patientProfile.nationality_id
      : "",
    phone: patientProfile.phone ?? "",
    telephone: patientProfile.telephone ?? "",
    occupation: patientProfile.occupation ?? "",
    educational_level_id: patientProfile.educational_level_id
      ? "" + patientProfile.educational_level_id
      : "",
    marital_status_id: patientProfile.marital_status_id
      ? "" + patientProfile.marital_status_id
      : "",
    language_id: patientProfile.language_id ?? "",
  });

  const [clinicalData, setClinicalData] = useState({
    weight: patientProfile.weight ? "" + patientProfile.weight : "",
    height: patientProfile.height ?? "",
    dominant_side_id: patientProfile.dominant_side_id
      ? "" + patientProfile.dominant_side_id
      : "",
    gender_id: patientProfile.gender_id ? "" + patientProfile.gender_id : "",
  });

  const [identificationDocuments, setIdentificationDocuments] = useState(
    patientProfile.identificationDocuments.length === 0 ||
      patientProfile.identificationDocuments === null
      ? [
          {
            document_id: "",
            document_type: "",
            text: "",
          },
        ]
      : patientProfile.identificationDocuments
  );

  const pastPersonalInfo = {
    full_name: patientProfile.full_name ?? "",
    birth_date: patientProfile.birth_date ?? "",
    process_number: patientProfile.process_number ?? "",
    consent_check: patientProfile.consent_check ?? false,
  };

  const pastContactInfo = {
    email: patientProfile.email ?? "",
    nationality_id: patientProfile.nationality_id
      ? "" + patientProfile.nationality_id
      : "",
    phone: patientProfile.phone ?? "",
    telephone: patientProfile.telephone ?? "",
    occupation: patientProfile.occupation ?? "",
    educational_level_id: patientProfile.educational_level_id
      ? "" + patientProfile.educational_level_id
      : "",
    marital_status_id: patientProfile.marital_status_id
      ? "" + patientProfile.marital_status_id
      : "",
    language_id: patientProfile.language_id ?? "",
  };

  const pastClinicalData = {
    weight: patientProfile.weight ? "" + patientProfile.weight : "",
    height: patientProfile.height ?? "",
    dominant_side_id: patientProfile.dominant_side_id
      ? "" + patientProfile.dominant_side_id
      : "",
    gender_id: patientProfile.gender_id ? "" + patientProfile.gender_id : "",
  };

  const pastIdentificationDocuments =
    patientProfile.identificationDocuments.length === 0 ||
    patientProfile.identificationDocuments === null
      ? [
          {
            document_id: "",
            document_type: "",
            text: "",
          },
        ]
      : patientProfile.identificationDocuments;

  const [idDocsToDelete, setIdDocsToDelete] = useState([]);

  const [hasChangedDocs, setHasChangedDocs] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************
  const moveToNext = (e, tabNumber = 1) => {
    e.preventDefault();
    setCurrStep(tabNumber);
    if (e.target.name === "continue" && currStep < 4) {
      setCurrStep(currStep + 1);
    }
  };

  const makeIdentificationDocumentsRequestObject = () => {
    if (
      identificationDocuments.length === 1 &&
      identificationDocuments[0].document_id === "" &&
      identificationDocuments[0].document_type === "" &&
      identificationDocuments[0].text === ""
    ) {
      return [];
    } else {
      return [...identificationDocuments];
    }
  };

  const editPatientRequest = (e) => {
    e.preventDefault();
    if (JSON.stringify(personalInfo) !== JSON.stringify(pastPersonalInfo)) {
      dispatch(editPatientPersonalData(patient_id, personalInfo)).then(
        (res) => {
          dispatch(getPatientPersonalData(patient_id)).then(
            (res) => {
              console.log("getPatientPersonalData RES:", res);
            },
            (err) => {
              console.log("getPatientPersonalData ERROR:", err);
            }
          );
        },
        (err) => {
          console.log("editPatientPersonalData ERROR:", err);
          const { data } = err.error.response;
          if (data.form_errors) {
            setFormErrors(data.form_errors);
          }
        }
      );
    }
    if (JSON.stringify(contactInfo) !== JSON.stringify(pastContactInfo)) {
      dispatch(editPatientContactInfo(patient_id, contactInfo)).then(
        (res) => {
          dispatch(getPatientContactInfo(patient_id)).then(
            (res) => {
              console.log("getPatientContactInfo RES:", res);
            },
            (err) => {
              console.log("getPatientContactInfo ERROR:", err);
            }
          );
        },
        (err) => {
          const { data } = err.error.response;
          if (data.form_errors) {
            setFormErrors(data.form_errors);
          }
        }
      );
    }
    if (JSON.stringify(clinicalData) !== JSON.stringify(pastClinicalData)) {
      dispatch(editPatientClinicalData(patient_id, clinicalData)).then(
        (res) => {
          dispatch(getPatientClinicalData(patient_id)).then(
            (res) => {
              console.log("getPatientClinicalData RES:", res);
            },
            (err) => {
              console.log("getPatientClinicalData ERROR:", err);
            }
          );
        },
        (err) => {
          const { data } = err.error.response;
          if (data.form_errors) {
            setFormErrors(data.form_errors);
          }
        }
      );
    }
    if (
      JSON.stringify(identificationDocuments) !==
      JSON.stringify(pastIdentificationDocuments)
    ) {
      dispatch(
        editPatientIdentification(patient_id, {
          identification_docs: [
            ...makeIdentificationDocumentsRequestObject(),
            ...idDocsToDelete,
          ],
        })
      ).then(
        (res) => {
          setHasChangedDocs(false);
          dispatch(getPatientIdentification(patient_id)).then(
            (res) => {
              console.log("getPatientIdentification RES:", res);
            },
            (err) => {
              console.log("getPatientIdentification ERROR:", err);
            }
          );
        },
        (err) => {
          const { data } = err.error.response;
          if (data.form_errors) {
            setFormErrors(data.form_errors);
          }
        }
      );
    }
    // if (currStep === 1) {
    //   dispatch(editPatientPersonalData(patient_id, personalInfo)).then(
    //     (res) => {
    //       console.log("editPatientPersonalData RES:", res);
    //       dispatch(getPatientPersonalData(patient_id)).then(
    //         (res) => {
    //           console.log("getPatientPersonalData RES:", res);
    //         },
    //         (err) => {
    //           console.log("getPatientPersonalData ERROR:", err);
    //         }
    //       );
    //     },
    //     (err) => {
    //       console.log("editPatientPersonalData ERROR:", err);
    //       const { data } = err.error.response;
    //       if (data.form_errors) {
    //         setFormErrors(data.form_errors);
    //       }
    //     }
    //   );
    // } else if (currStep === 2) {
    //   dispatch(editPatientContactInfo(patient_id, contactInfo)).then(
    //     (res) => {
    //       console.log("editPatientContactInfo RES:", res);
    //       dispatch(getPatientContactInfo(patient_id)).then(
    //         (res) => {
    //           console.log("getPatientContactInfo RES:", res);
    //         },
    //         (err) => {
    //           console.log("getPatientContactInfo ERROR:", err);
    //         }
    //       );
    //     },
    //     (err) => {
    //       console.log("editPatientContactInfo ERROR:", err);
    //       const { data } = err.error.response;
    //       if (data.form_errors) {
    //         setFormErrors(data.form_errors);
    //       }
    //     }
    //   );
    // } else if (currStep === 3) {
    //   dispatch(editPatientClinicalData(patient_id, clinicalData)).then(
    //     (res) => {
    //       console.log("editPatientClinicalData RES:", res);
    //       dispatch(getPatientClinicalData(patient_id)).then(
    //         (res) => {
    //           console.log("getPatientClinicalData RES:", res);
    //         },
    //         (err) => {
    //           console.log("getPatientClinicalData ERROR:", err);
    //         }
    //       );
    //     },
    //     (err) => {
    //       console.log("editPatientClinicalData ERROR:", err);
    //       const { data } = err.error.response;
    //       if (data.form_errors) {
    //         setFormErrors(data.form_errors);
    //       }
    //     }
    //   );
    // } else if (currStep === 4) {
    //   console.log("identificationDocuments:", identificationDocuments);
    //   console.log(
    //     "makeIdentificationDocumentsRequestObject:",
    //     makeIdentificationDocumentsRequestObject()
    //   );

    //   dispatch(
    //     editPatientIdentification(patient_id, {
    //       identification_docs: [
    //         ...makeIdentificationDocumentsRequestObject(),
    //         ...idDocsToDelete,
    //       ],
    //     })
    //   ).then(
    //     (res) => {
    //       console.log("editPatientIdentification RES:", res);
    //       setHasChangedDocs(false);
    //       dispatch(getPatientIdentification(patient_id)).then(
    //         (res) => {
    //           console.log("getPatientIdentification RES:", res);
    //         },
    //         (err) => {
    //           console.log("getPatientIdentification ERROR:", err);
    //         }
    //       );
    //     },
    //     (err) => {
    //       console.log("editPatientIdentification ERROR:", err);
    //       const { data } = err.error.response;
    //       if (data.form_errors) {
    //         setFormErrors(data.form_errors);
    //       }
    //     }
    //   );
    // }
  };

  // PERSONAL INFO

  const personalInfoInputshandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setPersonalInfo({
      ...personalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const changeBirthDate = (date, e) => {
    if (formErrors.hasOwnProperty("birth_date")) {
      delete formErrors["birth_date"];
    }

    const birth_date = moment(date).format("YYYY-MM-DD");
    setPersonalInfo({ ...personalInfo, birth_date });
  };

  const checkBoxHandler = (e) => {
    if (formErrors.hasOwnProperty("consent_check")) {
      delete formErrors["consent_check"];
    }

    setPersonalInfo({
      ...personalInfo,
      consent_check: e.target.checked,
    });
  };

  // CONTACT INFO
  const contactInfoInputsHandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value,
    });
  };

  // CLINICAL DATA
  const clinicalDataInputsHandler = (e) => {
    if (formErrors.hasOwnProperty(e.target.name)) {
      delete formErrors[e.target.name];
    }
    setClinicalData({
      ...clinicalData,
      [e.target.name]: e.target.value,
    });
  };

  // IDENTIFICATION DOCUMENTS

  const identificationDocumentsInputsHandler = (e, docId) => {
    if (
      formErrors &&
      formErrors[docId] &&
      formErrors[docId].hasOwnProperty(e.target.name)
    ) {
      delete formErrors[docId][e.target.name];
    }

    const value =
      e.target.name === "text" ? e.target.value.slice(0, 20) : e.target.value;

    setIdentificationDocuments(
      identificationDocuments.map((doc, index) =>
        docId === index ? { ...doc, [e.target.name]: value } : doc
      )
    );
  };

  const addDocument = () => {
    setIdentificationDocuments([
      ...identificationDocuments,
      { document_type: "", document_id: "", observations: "" },
    ]);
  };

  const deleteDocument = (index) => {
    if (identificationDocuments[index].id) {
      setIdDocsToDelete([
        ...idDocsToDelete,
        { ...identificationDocuments[index], delete: true },
      ]);
    }
    setIdentificationDocuments(
      identificationDocuments.filter((_, i) => i !== index)
    );
  };

  //* ************************************** PARTIALS **************************************

  const _renderCurrentStep = () => {
    if (currStep === 1) {
      return (
        <PersonalInfoForm
          personalInfo={personalInfo}
          medicalConditions={medicalConditions}
          personalInfoErrors={formErrors}
          inputsHandler={personalInfoInputshandler}
          checkBoxHandler={checkBoxHandler}
          changeBirthDate={changeBirthDate}
          editPatientRequest={editPatientRequest}
          moveToNext={moveToNext}
        />
      );
    } else if (currStep === 2) {
      return (
        <ContactInfoForm
          contactInfo={contactInfo}
          contactInfoErrors={formErrors}
          inputsHandler={contactInfoInputsHandler}
          editPatientRequest={editPatientRequest}
          moveToNext={moveToNext}
          goToPrevious={() => setCurrStep(currStep - 1)}
        />
      );
    } else if (currStep === 3) {
      return (
        <ClinicalDataForm
          clinicalData={clinicalData}
          clinicalDataErrors={formErrors}
          inputsHandler={clinicalDataInputsHandler}
          editPatientRequest={editPatientRequest}
          moveToNext={moveToNext}
          goToPrevious={() => setCurrStep(currStep - 1)}
        />
      );
    } else if (currStep === 4) {
      return (
        <IdentificationDocumentsForm
          identificationDocuments={identificationDocuments}
          identificationDocumentsErrors={formErrors}
          inputsHandler={identificationDocumentsInputsHandler}
          editPatientRequest={editPatientRequest}
          deleteDocument={deleteDocument}
          addDocument={addDocument}
          hasChangedDocs={hasChangedDocs}
          setHasChangedDocs={setHasChangedDocs}
          goToPrevious={() => setCurrStep(currStep - 1)}
        />
      );
    }
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-edit-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>

      <div className="row g-0">
        <div className="col-1" />
        <div className="col body-wrapper flex-d-column">
          <h1 className="create_patient_title">{t("titles.edit_patient")}</h1>
          <div
            className="button back-button-wrapper mb-2 align-self-start d-flex align-items-center"
            onClick={() => props.history.goBack()}
          >
            <img alt="back Button" src={backButton} />
            {t("buttons.back")}
          </div>

          <div className="form-auth-wrapper-1 mt-1">
            <div className="form-title-container row">
              <div
                className="col form-title-step pointer"
                onClick={() => {
                  setCurrStep(1);
                }}
              >
                {t("create_patient.main_data.title")}
              </div>
              <div
                className="col form-title-step pointer"
                onClick={() => {
                  setCurrStep(2);
                }}
              >
                <span>
                  {t("create_patient.personal_data.title")}
                  <span className="input-label-text">
                    {" "}
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>
              <div
                className="col form-title-step pointer"
                onClick={() => {
                  setCurrStep(3);
                }}
              >
                <span>
                  {t("create_patient.clinical_data.title")}
                  <span className="input-label-text">
                    {" "}
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>
              <div
                className="col form-title-step pointer"
                onClick={() => {
                  setCurrStep(4);
                }}
              >
                <span>
                  {t("create_patient.documentation.title")}
                  <span className="input-label-text">
                    {" "}
                    {t("create_patient.optional")}
                  </span>
                </span>
              </div>

              <motion.div
                className="form-underline"
                initial={{ width: "25%" }}
                animate={{ width: currStep * 25 + "%" }}
                transition={{ duration: 0.5 }}
              />
            </div>

            <div className="form-inside-container">{_renderCurrentStep()}</div>
          </div>
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
};

export default memo(PatientEdit);
