import { useEffect, useRef, useState } from "react";

import * as d3 from "d3";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { create_card } from "../../../utils/create_card";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

const HorizontalBars = ({ modal = false, details, dataTry = null }) => {
  const [data, setData] = useState(dataTry);
  const [isLoading, setIsLoading] = useState(dataTry ? false : true);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  // const data = [
  //   {
  //     value: 71.1,
  //     absolute: null,
  //     date: "2022-04-09",
  //     evaluation_id: "0f5479be6ee523629eb339128897a056bfaa986c",
  //     category: "Scapulohumeral Rhythm",
  //     label: "Scapulohumeral Rhythm",
  //   },
  //   {
  //     category: "score",
  //     value: "71.1",
  //     label: "Functionality index",
  //   },
  // ];
  // const details = {
  //   id: 16,
  //   type: 2,
  //   title: "Functionality Index Components",
  //   description: "Functionality Index evaluated components",
  //   icon: '<i class="fa fa-area-chart" aria-hidden="true"></i>',
  //   color: "var(--mkinetikos-red-color)",
  //   span_row: 7,
  //   span_col: 10,
  // };

  const reff = useRef(null);

  useEffect(() => {
    create_card(
      reff.current,
      7,
      details.id,
      details.type,
      details.color,
      details.title,
      details.description,
      details.span_row,
      details.span_col,
      false,
      "url"
    );
    if (!data)
      dispatch(
        getPatientEvaluatioCardDetails(
          patient_id,
          evaluation_id,
          details.id,
          getLanguageID(i18n.language)
        )
      )
        .then((res) => {
          if (res.payload && res.payload.data) {
            setData(res.payload.data.data.data);
            setIsLoading(false);
          }
        })
        .catch(() => {});
  }, []);

  useEffect(() => {
    function build_horizontal_bar_chart(card, disability_items, color) {
      if (color === undefined || color === "" || color === null)
        color = "var(--kblue-color)";

      // let card_body = modalString
      //     ? card.querySelector(`.card-body.${modalString}`)
      //     : card.querySelector(".card-body"),

      let card_body = card.querySelector(".card-body"),
        wrapper_div = document.createElement("div");

      wrapper_div.classList = modal
        ? "svg-div modall svg-div-modal noselect"
        : "svg-div noselect";
      card_body.appendChild(wrapper_div);
      let rect = wrapper_div.getBoundingClientRect(),
        wrapper = modal
          ? d3
              .select(`#${card.id} .svg-div.modall`)
              .append("svg")
              .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
              .attr("preserveAspectRatio", "xMidYMin meet")
          : d3
              .select(`#${card.id} .svg-div:not(.modall)`)
              .append("svg")
              .attr("viewBox", `0 0 ${rect.width} ${rect.height}`)
              .attr("preserveAspectRatio", "xMidYMin meet"),
        margin = { top: 15, right: 50, bottom: 0, left: 5 },
        inner_width = rect.width - margin.left - margin.right,
        inner_height = rect.height - margin.top - margin.bottom,
        x_scale = d3.scaleLinear().range([0, inner_width]),
        y_scale = d3.scaleBand().range([inner_height, 0]).padding(0.2);

      let g = wrapper
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // format the data
      disability_items.forEach(function (d) {
        d.value = +d.value;
      });
      // Scale the range of the data in the domains
      x_scale.domain([0, 100]);
      y_scale.domain(
        disability_items.map(function (d) {
          return d.category;
        })
      );

      // dashed vertical lines
      wrapper
        .append("path")
        .attr("class", "tags-vertical-line")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("d", () => {
          return `M ${x_scale(0)}, ${inner_height} ${x_scale(0)}, 0`;
        });

      wrapper
        .append("text")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("class", "axis-text")
        .attr("text-anchor", "middle")
        .attr("x", (d) => {
          return x_scale(1);
        })
        .attr("y", -5)
        .text("0")
        .append("tspan")
        .text("%")
        .attr("font-size", "0.6em")
        .attr("dx", ".1em");

      wrapper
        .append("path")
        .attr("class", "tags-vertical-line")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("d", () => {
          return `M ${x_scale(50)}, ${inner_height} ${x_scale(50)}, 0`;
        });
      wrapper
        .append("text")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("class", "axis-text")
        .attr("text-anchor", "middle")
        .attr("x", (d) => {
          return x_scale(50);
        })
        .attr("y", -5)
        .text("50")
        .append("tspan")
        .text("%")
        .attr("font-size", "0.6em")
        .attr("dx", ".1em");

      wrapper
        .append("path")
        .attr("class", "tags-vertical-line")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("d", () => {
          return `M ${x_scale(100)}, ${inner_height} ${x_scale(100)}, 0`;
        });
      wrapper
        .append("text")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .attr("class", "axis-text")
        .attr("text-anchor", "middle")
        .attr("x", (d) => {
          return x_scale(100);
        })
        .attr("y", -5)
        .text("100")
        .append("tspan")
        .text("%")
        .attr("font-size", "0.6em")
        .attr("dx", ".1em");

      // Horizontal Bars
      let data_entry_element = g
        .selectAll(".background-bar")
        .data(disability_items)
        .enter();

      // Background bar
      data_entry_element
        .append("rect")
        .attr("class", "background-bar")
        .attr("width", (d) => {
          return x_scale(100);
        })
        .attr("y", (d) => {
          return y_scale(d.category);
        })
        .attr("height", y_scale.bandwidth());
      // Data bar
      data_entry_element
        .append("rect")
        .attr("class", (d) => {
          return d.category === "score" ? "score-bar" : "bar";
        })
        .attr("fill", color)
        .attr("rx", 6)
        .attr("width", (d) => {
          return x_scale(d.value);
        })
        .attr("y", (d) => {
          return y_scale(d.category);
        })
        .attr("height", y_scale.bandwidth());

      // text insede the bar
      let label_y_deviation = y_scale.bandwidth() * 0.75;
      data_entry_element
        .append("text")
        .attr("class", "bar-text smallText kinetikosBlue")
        .attr("x", (d) => {
          return x_scale(1);
        })
        .attr("y", (d) => {
          return y_scale(d.category) + label_y_deviation;
        })
        .text((d) => {
          if (d.label === null) {
            let cat = d.category.split(".").reverse()[1];
            return cat.substr(0, cat.length - 2);
          } else {
            return d.label;
          }
        });

      // Percentage Value
      data_entry_element
        .append("text")
        .attr("class", "axis-text")
        .attr("x", (d) => {
          return x_scale(101);
        })
        .attr("y", (d) => {
          return y_scale(d.category) + label_y_deviation;
        })
        .text((d) => {
          return d.value.toFixed(2);
        })
        .append("tspan")
        .text("%")
        .attr("font-size", "0.6em")
        .attr("dx", ".1em");
    }

    if (data) build_horizontal_bar_chart(reff.current, data, details.color);
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default React.memo(HorizontalBars);
