import ReactPlayer from "react-player";

const ShowMedia = ({ url, type = "video", close }) => (
  <div onClick={close} className="show-media">
    <div
      className="modal-container"
      onClick={(event) => event.stopPropagation()}
    >
      {type === "video" ? (
        <ReactPlayer
          autoPlay
          playing={true}
          loop={true}
          controls={true}
          url={url}
        />
      ) : (
        <div className="media-modal">
          <img
            onClick={(event) => event.stopPropagation()}
            style={{
              width: "600px",
              height: "auto",
            }}
            src={url}
            alt="Show"
          />
        </div>
      )}
    </div>
  </div>
);
export default ShowMedia;
