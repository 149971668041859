import React, { useRef } from "react";
import SelectedWeekDays from "../../../../components/selectedWeekDays";
import OptionsButton from "../../../../components/optionsButton";
import DropdownOptions from "../../../../components/dropdownOptions";
import { deleteMedicine } from "../../../../redux/actions/patientMedicines";
import { parseDate } from "../../../../utils/dataAndTime";
import MessageModal from "../../../../components/messageModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { errors } from "../../../../constants/general";
import useOnClickOutside from "hooks/useOnClickOutside";

import { useTranslation } from "react-i18next";

import SOSIcon from "../../../../assets/images/SOS.svg";

const MedicinesModulesList = ({
  medicines,
  currentInstId,
  optionsEnabled = true,
  onDelete,
  disabled = false,
}) => {
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);
  const [isDeleteModalOpenIds, setIsDeleteOpenModalOpenIds] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");
  const history = useHistory();

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const { t, i18n } = useTranslation();

  const deleteSelectedMedicine = (ids) => {
    setIsDeleting(true);
    dispatch(deleteMedicine(patient_id, ids.medicine_id, ids.prescription_id))
      .then(() => {
        setIsDeleteOpenModalOpenIds(null);
        if (onDelete) {
          onDelete();
        }
      })
      .catch(() => alert(errors.service_error))
      .finally(() => setIsDeleting(false));
  };

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setSelectedMedicineId(null)
  );

  const _renderListItem = (medicine) => (
    <div
      key={medicine.medicine_id}
      className="row medicine regular-text black-color"
    >
      <div
        className={`col-3 ${!medicine.medicine_active && "disabled-container"}`}
      >
        {medicine.medicine_text}
        {medicine.medicine_is_sos && (
          <img
            alt="SOS"
            className={`button-icon ml-1 ${
              !medicine.medicine_active && "disabled-container"
            }`}
            src={SOSIcon}
          ></img>
        )}
      </div>
      <div
        className={`col-4 ${!medicine.medicine_active && "disabled-container"}`}
      >
        {medicine?.medicine_is_sos ? (
          <div
            className={`col-5 regular-text grey-intense ${
              !medicine.medicine_active && "disabled-container"
            }`}
          >
            {" "}
            {t("patient.meds.sos_meds_dont_require")}{" "}
          </div>
        ) : (
          <SelectedWeekDays activeDays={medicine.medicine_days} />
        )}
      </div>
      <div
        className={`col-3 ${!medicine.medicine_active && "disabled-container"}`}
      >
        {medicine.medicine_times.map((time, index) =>
          index === 0 ? time.slice(0, -3) + " " : "/ " + time.slice(0, -3) + " "
        )}
      </div>
      <div
        className={`col-2 flex-d-row justify-content-between align-items-center`}
      >
        <div>
          {medicine.medicine_active ? (
            parseDate(medicine.medicine_end_date)
          ) : (
            <div className="grey-label">{t("patient.meds.expired")}</div>
          )}
        </div>
        {optionsEnabled && (
          <div className={`more-image-wrapper `}>
            <OptionsButton
              // type={selectedMedicineId ? 2 : 1}
              ref={(el) => (optionsRef.current[medicine.medicine_id] = el)}
              isSelected={selectedMedicineId === medicine.medicine_id}
              onClick={() =>
                selectedMedicineId === medicine.medicine_id
                  ? setSelectedMedicineId(null)
                  : setSelectedMedicineId(medicine.medicine_id)
              }
              disabled={medicine.medicine_active}
            />
            {selectedMedicineId === medicine.medicine_id && (
              <DropdownOptions
                ref={dropDownRef}
                buttons={
                  currentInstId === medicine.prescription_inst_id
                    ? [
                        medicine.medicine_active && {
                          text: (
                            <span className="regular-text">
                              {t("buttons.edit")}
                            </span>
                          ),
                          action: () =>
                            history.push(
                              `/patient/${patient_id}/mkinetikos_prescriptions/${medicine.prescription_id}/medicines/${medicine.medicine_id}/edit`
                            ),
                        },
                        {
                          text: (
                            <span className="regular-text">
                              {t("buttons.view_details")}
                            </span>
                          ),
                          action: () =>
                            history.push(
                              `/patient/${patient_id}/mkinetikos_prescriptions/${medicine.prescription_id}/medicines/${medicine.medicine_id}/show`
                            ),
                        },
                        medicine.medicine_active && {
                          text: (
                            <span className="regular-text warning">
                              {t("buttons.delete")}
                            </span>
                          ),
                          action: () =>
                            setIsDeleteOpenModalOpenIds({
                              medicine_id: medicine.medicine_id,
                              prescription_id: medicine.prescription_id,
                            }),
                        },
                      ].filter(Boolean)
                    : [
                        {
                          text: (
                            <span className="regular-text">
                              {t("buttons.view_details")}
                            </span>
                          ),
                          action: () =>
                            history.push(
                              `/patient/${patient_id}/mkinetikos_prescriptions/${medicine.prescription_id}/medicines/${medicine.medicine_id}/show`
                            ),
                        },
                      ]
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="medicines-list-wrapper row" disabled={disabled}>
      {medicines && medicines.length > 0 ? (
        <>
          <div className="row list-label align-items-center mb-1">
            <div className="col-3">{t("patient.meds.name_short")}</div>
            <div className="col-4">{t("patient.meds.days")}</div>
            <div className="col-3">{t("patient.meds.time")}</div>
            <div className="col-2">{t("patient.meds.expiration_date")}</div>
          </div>
          {medicines.map((medicine) => _renderListItem(medicine))}
        </>
      ) : (
        <div className="flex-d-row d-flex justify-content-center mb-3 regular-text medium">
          {t("patient.meds.no_meds_added")}
        </div>
      )}
      {isDeleteModalOpenIds && (
        <MessageModal
          headerText={t("modals.delete_med_header")}
          body={t("modals.delete_med_body")}
          footerButtons={[
            <button
              disabled={isDeleting}
              onClick={() => setIsDeleteOpenModalOpenIds(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                disabled={isDeleting}
                type="button"
                value={t("buttons.delete")}
                onClick={() => deleteSelectedMedicine(isDeleteModalOpenIds)}
                className="warning-button small-wider-button"
              />
            </div>,
          ]}
        />
      )}
    </div>
  );
};

export default MedicinesModulesList;
