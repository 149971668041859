import React from "react";
import { generateAppleAppSiteAssociation } from "utils/appLinks";

const AppleAppSiteAssociationContent = () => {
  const appleAppSiteAssociationContent = generateAppleAppSiteAssociation();

  return (
    <div>
      <pre>{appleAppSiteAssociationContent}</pre>
    </div>
  );
};

export default AppleAppSiteAssociationContent;
