//* *********************************** REACT IMPORTS ************************************
import React from "react";
import PrivacyElement from "components/privacyElement";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************

const PublicPrivacyPolicy = (props) => {
  //* ************************************** HANDLERS **************************************
  const { t, i18n } = useTranslation();

  //* ************************************** RENDER **************************************
  return <PrivacyElement title={t("titles.privacy_policy")} isPublic={true} />;
};

export default React.memo(PublicPrivacyPolicy);
