import React from "react";

import CE from "../../assets/images/companyInfo/ce.svg";
import address from "../../assets/images/companyInfo/address.svg";
import ref from "../../assets/images/companyInfo/ref.svg";

const CompanyInfo = () => {
  return (
    <div className="col-3 regular-text company-info-body ">
      <div className="align-center-text">Kinetikos</div>
      <div className="row-space-between mt-1">
        <img src={CE} alt="CE" />
        <span className="mt-4">0197</span>

        <img src={address} alt="address" className="ml-2" />
        <span className="p-1">
          Rua Pedro Nunes <br /> Edifício C <br></br>
          3030-199 Coimbra <br /> Portugal
        </span>
      </div>
      <div className="row-space-between">
        <div>
          <img src={ref} alt="REF" />
          <span className="ml-1">Kinetikos</span>
        </div>
        <div>{new Date().getFullYear()} &copy; Kinetikos</div>
        <div className="col-2"></div>
      </div>
    </div>
  );
};

export default CompanyInfo;
