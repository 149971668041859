//* ********************************** REACT IMPORTS **********************************
import { useState } from "react";
import { useHistory } from "react-router-dom";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "../../../components/spinner";
import MessageBox from "../../../components/messageBox";
import CustomMessage from "../../authentication/partials/customMessage";

//* ************************************** GLOBALS ***************************************
import show_password_logo from "../../../assets/images/show-password.svg";
import hide_password_logo from "../../../assets/images/hide-password.svg";

//* *************************************** STYLES ***************************************
import { changePassword, userLogout } from "../../../redux/actions/user";

import expired_timer from "../../../assets/images/expired-timer.svg";
import { clearRedirectTo } from "../../../redux/actions/redirect";

const PasswordExpiredForm = ({
  changeToSuccessResetPassword,
  showErrorPage,
  showText = true,
}) => {
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });
  const [passwordsErrors, setPasswordsErrors] = useState([]);

  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true);
  const [isNewPassword1Hidden, setIsNewPassword1Hidden] = useState(true);
  const [isNewPassword2Hidden, setIsNewPassword2Hidden] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);

  const [passwordErrors, setPasswordErrors] = useState({});
  const [passwordErrMessage, setPasswordErrMessage] = useState({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  //* ************************************** HANDLERS *************************************

  const submitChangePassword = (e) => {
    setIsResetPasswordLoading(true);
    e.preventDefault();
    dispatch(changePassword(passwords))
      .then((res) => {
        if (!changeToSuccessResetPassword) {
          setSuccessMessage(true);
        }
        changeToSuccessResetPassword();
      })
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setPasswordErrors(data.form_errors);
            setPasswordsErrors(data.form_errors.new_password2);
          }
        }
      })
      .finally(() => setIsResetPasswordLoading(false));
  };

  const inputsHandler = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const passwordValidator = (e) => {
    const passwordInputValue = e.target.value.trim();
    const passwordInputFieldName = e.target.name;
    //for password

    const uppercase = /(?=.*?[A-Z])/;
    const lowercase = /(?=.*?[a-z])/;
    const digits = /(?=.*?[0-9])/;
    const specialChar = /(?=.*?[#?!@$%^&*-])/;
    const minLength = /.{8,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercase.test(passwordInputValue);
    const lowercasePassword = lowercase.test(passwordInputValue);
    const digitsPassword = digits.test(passwordInputValue);
    const specialCharPassword = specialChar.test(passwordInputValue);
    const minLengthPassword = minLength.test(passwordInputValue);

    if (passwordLength === 0) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.password_empty"),
      });
      setIsPasswordValid(false);
    } else if (!uppercasePassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.uppercase"),
      });
      setIsPasswordValid(false);
    } else if (!lowercasePassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.lowercase"),
      });
      setIsPasswordValid(false);
    } else if (!digitsPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.digit"),
      });
      setIsPasswordValid(false);
    } else if (!specialCharPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.special_character"),
      });
      setIsPasswordValid(false);
    } else if (!minLengthPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.min_length_8"),
      });
      setIsPasswordValid(false);
    } else if (passwordInputValue === passwords.old_password) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.repeat_password"),
      });
      setIsPasswordValid(false);
    } else if (
      passwords.new_password2 !== "" &&
      passwords.new_password2 !== passwords.new_password1
    ) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.passwords_dont_match"),
      });
      setIsPasswordValid(false);
    } else {
      setPasswordErrMessage("");
      setIsPasswordValid(true);
    }
  };

  const canUserSubmit =
    !isPasswordValid ||
    isResetPasswordLoading ||
    passwords.old_password === "" ||
    passwords.new_password1 === "" ||
    passwords.new_password2 === "";

  const handleLogout = () => {
    history.replace("/logout");
  };

  //* *************************************** RENDER ***************************************
  return (
    <div className="form-auth-wrapper">
      {successMessage ? (
        <CustomMessage
          type={"successResetPassword"}
          changeToLogin={handleLogout}
        />
      ) : (
        <>
          <h2>{t("titles.password_expired")}</h2>
          {showText ? (
            <p
              style={{ marginTop: "1.44em", marginBottom: "2.00em" }}
              className="input-label-text"
            >
              {t("authentication.password_expired.message")}
            </p>
          ) : (
            ""
          )}
          <form onSubmit={submitChangePassword}>
            {showText ? (
              <div className="input-image-wrapper image-right">
                <MessageBox
                  type={"warning"}
                  messages={[
                    t("authentication.password_expired.error_message"),
                  ]}
                />
                <img className="input-right-image" src={expired_timer} alt="" />
              </div>
            ) : (
              ""
            )}

            <label className="input-label-text">
              {t("authentication.password_expired.current_password")}{" "}
              <span className="warning-color">{t("star")}</span>
              {passwordErrors.old_password && (
                <span className="warning-color">
                  {passwordErrors.old_password}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                // onBlur={checkForPasswordErrors}
                className="form-input"
                onChange={inputsHandler}
                value={passwords.old_password}
                name="old_password"
                id="old_password"
                placeholder={t(
                  "authentication.password_expired.current_password"
                )}
                type={isCurrentPasswordHidden ? "password" : "text"}
                autoComplete="off"
              />
              <img
                onClick={() =>
                  setIsCurrentPasswordHidden(!isCurrentPasswordHidden)
                }
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isCurrentPasswordHidden
                    ? show_password_logo
                    : hide_password_logo
                }
              />
            </div>
            <label className="input-label-text">
              {t("authentication.reset_password.new_password")}{" "}
              <span className="warning-color">{t("star")}</span>
              {passwordErrors.new_password1 && (
                <span className="warning-color">
                  {passwordErrors.new_password1}
                </span>
              )}
              {passwordErrMessage.new_password1 && (
                <span className="warning-color">
                  {passwordErrMessage.new_password1}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                // onBlur={checkForPasswordErrors}
                className="form-input"
                onChange={inputsHandler}
                onKeyUp={passwordValidator}
                value={passwords.new_password1}
                name="new_password1"
                id="new_password1"
                placeholder={t("placeholders.new_password")}
                type={isNewPassword1Hidden ? "password" : "text"}
                autoComplete="off"
              />
              <img
                onClick={() => setIsNewPassword1Hidden(!isNewPassword1Hidden)}
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isNewPassword1Hidden ? show_password_logo : hide_password_logo
                }
              />
            </div>
            <label className="input-label-text">
              {t("authentication.reset_password.confirm_password")}{" "}
              <span className="warning-color">{t("star")}</span>
              {passwordErrors.new_password2 && (
                <span className="warning-color">
                  {passwordErrors.new_password2}
                </span>
              )}
              {passwordErrMessage.new_password2 && (
                <span className="warning-color">
                  {passwordErrMessage.new_password2}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                className="form-input"
                // onBlur={checkForPasswordErrors}
                onChange={inputsHandler}
                onKeyUp={passwordValidator}
                value={passwords.new_password2}
                name="new_password2"
                id="new_password2"
                placeholder={t("placeholders.confirm_password")}
                type={isNewPassword2Hidden ? "password" : "text"}
                autoComplete="off"
              />
              <img
                onClick={() => setIsNewPassword2Hidden(!isNewPassword2Hidden)}
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isNewPassword2Hidden ? show_password_logo : hide_password_logo
                }
              />
            </div>

            <div className="form-auth-bottom">
              <div className="input-image-wrapper m-0">
                <input
                  disabled={canUserSubmit}
                  type="submit"
                  value={isResetPasswordLoading ? "" : t("buttons.save")}
                  onClick={submitChangePassword}
                  className="primary-button small-button w-100"
                />
                {isResetPasswordLoading && <Spinner type={"small"} />}
              </div>
            </div>
          </form>
          <div className="flex-d-row center-justify">
            <button
              className="small-button transparent-button mt-1"
              onClick={handleLogout}
            >
              <span className="link-text">
                <u>{t("buttons.logout")}</u>
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordExpiredForm;
