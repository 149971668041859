import { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import Spinner from "../../../../components/spinner";
import show_password_logo from "../../../../assets/images/show-password.svg";
import hide_password_logo from "../../../../assets/images/hide-password.svg";
import { changePassword } from "../../../../redux/actions/user";
const ChangePassword = ({ cancelChangePassword }) => {
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);
  const [passwordErrMessage, setPasswordErrMessage] = useState({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const inputsHandler = (e) => {
    if (passwordErrors.hasOwnProperty(e.target.name)) {
      delete passwordErrors[e.target.name];
    }
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  // REPLACE WITH HOOK
  const passwordValidator = (e) => {
    const passwordInputValue = e.target.value.trim();
    const passwordInputFieldName = e.target.name;
    //for password

    const uppercase = /(?=.*?[A-Z])/;
    const lowercase = /(?=.*?[a-z])/;
    const digits = /(?=.*?[0-9])/;
    const specialChar = /(?=.*?[#?!@$%^&*-])/;
    const minLength = /.{8,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercase.test(passwordInputValue);
    const lowercasePassword = lowercase.test(passwordInputValue);
    const digitsPassword = digits.test(passwordInputValue);
    const specialCharPassword = specialChar.test(passwordInputValue);
    const minLengthPassword = minLength.test(passwordInputValue);

    if (passwordLength === 0) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.password_empty"),
      });
      setIsPasswordValid(false);
    } else if (!uppercasePassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.uppercase"),
      });
      setIsPasswordValid(false);
    } else if (!lowercasePassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.lowercase"),
      });
      setIsPasswordValid(false);
    } else if (!digitsPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.digit"),
      });
      setIsPasswordValid(false);
    } else if (!specialCharPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.special_character"),
      });
      setIsPasswordValid(false);
    } else if (!minLengthPassword) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.min_length_8"),
      });
      setIsPasswordValid(false);
    } else if (passwordInputValue === passwords.old_password) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.repeat_password"),
      });
      setIsPasswordValid(false);
    } else if (
      passwords.new_password2 !== "" &&
      passwords.new_password2 !== passwords.new_password1
    ) {
      setPasswordErrMessage({
        [passwordInputFieldName]: t("form_errors.passwords_dont_match"),
      });
      setIsPasswordValid(false);
    } else {
      setPasswordErrMessage("");
      setIsPasswordValid(true);
    }
  };

  const isSaveButtonDisabled =
    !isPasswordValid ||
    isLoading ||
    passwords.old_password === "" ||
    passwords.new_password1 === "" ||
    passwords.new_password2 === "";

  const submitChangePassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(changePassword(passwords))
      .catch((error) => {
        if (error.error && error.error.response) {
          const { data } = error.error.response;
          if (data.form_errors) {
            setPasswordErrors(data.form_errors);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="change-password-wrapper">
      <span className="small-title">
        {t("my_area.security.change_password")}
      </span>
      <form onSubmit={submitChangePassword}>
        <div className="change-password-body">
          <div className="regular-text">
            {t("my_area.security.next_time_asked")}
          </div>
          <div className="inputs-wrapper">
            <label
              htmlFor="old_password"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("authentication.password.current_password")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {passwordErrors.old_password && (
                <span className="warning-color">
                  {passwordErrors.old_password}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                autoComplete="true"
                onChange={inputsHandler}
                value={passwords.old_password}
                name="old_password"
                id="old_password"
                type={isCurrentPasswordHidden ? "password" : "text"}
                placeholder={t("placeholders.current_password")}
                className={`form-input ${
                  passwordErrors.old_password && "error"
                }`}
              />
              <img
                onClick={() =>
                  setIsCurrentPasswordHidden(!isCurrentPasswordHidden)
                }
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isCurrentPasswordHidden
                    ? show_password_logo
                    : hide_password_logo
                }
              />
            </div>
            <label
              htmlFor="new_password1"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("authentication.reset_password.new_password")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {passwordErrors.new_password1 && (
                <span className="warning-color">
                  {passwordErrors.new_password1}
                </span>
              )}
              {passwordErrMessage.new_password1 && (
                <span className="warning-color">
                  {passwordErrMessage.new_password1}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                autoComplete="true"
                onChange={inputsHandler}
                onKeyUp={passwordValidator}
                value={passwords.new_password1}
                name="new_password1"
                id="new_password1"
                type={isNewPasswordHidden ? "password" : "text"}
                placeholder={t("placeholders.new_password")}
                className={`form-input ${
                  passwordErrors.new_password1 && "error"
                }`}
              />
              <img
                onClick={() => setIsNewPasswordHidden(!isNewPasswordHidden)}
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isNewPasswordHidden ? show_password_logo : hide_password_logo
                }
              />
            </div>
            <label
              htmlFor="new_password2"
              className="input-label-text d-flex justify-content-between"
            >
              <span>
                {t("authentication.reset_password.confirm_password")}&nbsp;
                <span className="warning-color">{t("star")}</span>
              </span>
              {passwordErrors.new_password2 && (
                <span className="warning-color">
                  {passwordErrors.new_password2}
                </span>
              )}
              {passwordErrMessage.new_password2 && (
                <span className="warning-color">
                  {passwordErrMessage.new_password2}
                </span>
              )}
            </label>
            <div className="input-image-wrapper image-right">
              <input
                autoComplete="true"
                onChange={inputsHandler}
                onKeyUp={passwordValidator}
                value={passwords.new_password2}
                name="new_password2"
                id="new_password2"
                type={isConfirmPasswordHidden ? "password" : "text"}
                placeholder={t("placeholders.confirm_password")}
                className={`form-input ${
                  passwordErrors.new_password2 && "error"
                }`}
              />
              <img
                onClick={() =>
                  setIsConfirmPasswordHidden(!isConfirmPasswordHidden)
                }
                alt="Password Show and Hide"
                className="input-right-image"
                src={
                  isConfirmPasswordHidden
                    ? show_password_logo
                    : hide_password_logo
                }
              />
            </div>
          </div>

          <div className="password-footer">
            <button
              onClick={cancelChangePassword}
              className="small-wider-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>
            <div className="input-image-wrapper m-0">
              <input
                value={t("buttons.save")}
                type="submit"
                disabled={isSaveButtonDisabled}
                className={`small-wider-button secondary-button ${
                  isLoading && "transparent"
                }`}
              />
              {isLoading && <Spinner type={"small"} />}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
