//* *********************************** REACT IMPORTS ************************************
import React, { useState, useEffect, useCallback, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import ChatsList from "./partials/chatsList";
import ChatModule from "./partials/chatModule";
import MessageModal from "../../../components/messageModal";

//* ************************************** REDUX *****************************************
import { getPatientChatsList, getPatientChat } from "../../../redux/actions/patients";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";
import Spinner from "../../../components/spinner";
import useToast from "hooks/useToast";

const PatientMkinetikosMyCareTeam = (props) => {
  const dispatch = useDispatch();
  const { patient_id } = useParams("patient_id");
  const showToast = useToast();

  const patientChatsList = useSelector((state) => state.patientChatsList);
  const patientChat = useSelector((state) => state.patientChat);

  const [openChatId, setOpenChatId] = useState(null);

  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const [canOnlyManageOwnPatients, setCanOnlyManageOwnPatients] = useState(false);

  const chatListTimerControler = useRef(null);
  const chatRequestControler = useRef(null);
  const messageTimerController = useRef(null);
  const messageRequestController = useRef(null);

  // Use this to prevent repeated requests when pending

  const [showChatAlreadyExistsModal, setShowChatAlreadyExistsModal] = useState(false);

  const { t, i18n } = useTranslation();

  const getChats = useCallback(async () => {
    chatRequestControler.current = new AbortController();
    const chatRequest = dispatch(
      getPatientChatsList(patient_id, {
        signal: chatRequestControler.current.signal,
      })
    );
    return chatRequest;
  }, [dispatch, patient_id]);

  const getMessages = useCallback(
    async (update) => {
      messageRequestController.current = new AbortController();
      const messageRequest = dispatch(
        getPatientChat(patient_id, openChatId, {
          update: update,
          signal: messageRequestController.current.signal,
        })
      );
      return messageRequest;
    },
    [dispatch, openChatId, patient_id]
  );

  useEffect(() => {
    console.log(canOnlyManageOwnPatients);
    if (canOnlyManageOwnPatients) {
      showToast({
        message: t("patient.my_care_team.user_dont_have_permission"),
        type: "error",
      });
    }
  }, [canOnlyManageOwnPatients]);

  // Load chats at launch and set a timer
  useEffect(() => {
    setIsLoadingChats(true);
    const firstChatListRequest = getChats();
    firstChatListRequest
      .then((res) => {
        const institutionChatID = res.payload.data.data.institution_chat.id;
        setOpenChatId(institutionChatID);
        chatListTimerControler.current = setInterval(getChats, 5000);
        setCanOnlyManageOwnPatients(res.payload.data.data.can_only_manage_own_patients);
        console.log(res.payload.data.data.can_only_manage_own_patients);
      })
      .finally(() => setIsLoadingChats(false));
    return () => {
      if (chatListTimerControler.current) {
        clearInterval(chatListTimerControler.current);
      }
      if (chatRequestControler.current) {
        chatRequestControler.current.abort();
      }
      setOpenChatId(null);
    };
  }, [patient_id, getChats]);

  // Get messages and set a timer
  useEffect(() => {
    if (openChatId) {
      setIsLoadingMessages(true);
      const firstMessageRequest = getMessages(false);
      firstMessageRequest
        .then(() => {
          messageTimerController.current = setInterval(() => getMessages(true), 5000);
        })
        .finally(() => setIsLoadingMessages(false));
    }

    return () => {
      if (messageTimerController.current) {
        clearInterval(messageTimerController.current);
      }
      if (messageRequestController.current) {
        messageRequestController.current.abort();
      }
    };
  }, [openChatId, getMessages]);

  //* ************************************** HANDLERS ************************************

  const getOpenChat = () => {
    let openChat;
    if (!openChatId) {
      openChat = {
        id: null,
        name: "",
        photo: "",
        participants: [],
      };
    } else {
      if (openChatId === patientChatsList.institution_chat.id) {
        openChat = patientChatsList.institution_chat;
      } else {
        openChat = [...patientChatsList.private_chat].find((chat) => chat.id === openChatId);
      }
    }
    return openChat;
  };

  const isInstitutionChat = () => {
    return openChatId === patientChatsList.institution_chat.id;
  };

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-my_care_team-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            props.history.push(`/patient/${patient_id}/evaluations`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.my_care_team")}</h4>
      </div>
      <div className="row overflow-auto flex-fill">
        <div className="col-6 h-100 chats-list-wrapper">
          {isLoadingChats ? (
            <div className="d-flex justify-content-center align-items-center position-relative h-50">
              <Spinner type={"big-blue"} />
            </div>
          ) : (
            <ChatsList
              openChatId={openChatId}
              setOpenChatId={setOpenChatId}
              chatsList={patientChatsList}
              setShowChatAlreadyExistsModal={setShowChatAlreadyExistsModal}
              canOnlyManageOwnPatients={canOnlyManageOwnPatients}
            />
          )}
        </div>
        <div className="col-6 h-100 chat-module-wrapper">
          {!isLoadingChats && (
            <ChatModule
              openChatId={openChatId}
              openChat={getOpenChat()}
              chatMessages={patientChat}
              isLoadingMessages={isLoadingMessages}
              isInstitutionChat={isInstitutionChat()}
              setShowChatAlreadyExistsModal={setShowChatAlreadyExistsModal}
              canOnlyManageOwnPatients={canOnlyManageOwnPatients}
            />
          )}
        </div>
      </div>
      {showChatAlreadyExistsModal && (
        <MessageModal
          headerText={t("modals.attention_header")}
          body={t("modals.chat_already_exists.body")}
          footerButtons={[
            <button
              onClick={() => {
                setShowChatAlreadyExistsModal(false);
              }}
              className="small-button primary-button"
            >
              {t("buttons.ok")}
            </button>,
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(PatientMkinetikosMyCareTeam);
