import React, { createContext, useCallback, useEffect, useState } from "react";
import Toast from "../index";
import uuid from "../../../utils/uuid";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeMessage } from "../../../redux/actions/messageHandler";

export const ToastContext = createContext();

/**
 *
 * Context component for Toast messages. This component should children of App component (Also for redux).
 * addToast and closeToast is the hook function for adding a toast and removing a toast respectively. (seen hook folder for more...).
 * Redux has used to automatically intercept error/success message. (see messageHandler reducer and action inside redux folder).
 * Auto removing toast is handled inside toast Component.js
 *
 * The component will intercept the server response, then it will look inside translation.json -
 * file for the "key" (key is the server response).
 * Put new message inside translation.json file with the server response msg as key.
 * If key doesn't exist, it will show "Bad request!" as toast message.
 *
 * This context component will return Toast component with props of toast object.
 * toast object {
 *  id: 1234-3456-3343...,
 *  message: "some text",
 *  type: "info",
 * }
 *
 */

const ToastContextProvider = (props) => {
  const { t, i18n } = useTranslation();
  const message = useSelector((state) => state.messageHandler.message);
  const [toastMessages, setToastMessages] = useState([]);
  const dispatch = useDispatch();

  /**
   * auto add toast message if redux value update.
   */
  useEffect(() => {
    if (message && message.length > 0) {
      const newMessage = {
        id: uuid(),
        message: t(`toasts.${message}.message`, {
          defaultValue: "",
        }),
        type: t(`toasts.${message}.type`, { defaultValue: "error" }),
      };

      addToast(newMessage);
      dispatch(removeMessage());
    }
  }, [message]);

  /**
   * hook function for delete Toast.
   * @param {toast_id}
   * ex: toast_id = 123-543...
   */
  const closeToast = useCallback((id) => {
    if (id) {
      setToastMessages(
        toastMessages.filter((toastMessage) => toastMessage.id !== id)
      );
    }
  });

  /**
   * hook function for add Toast.
   * @param {toast_object}
   * ex: toast_object {
   *  id: 1234-3456-3343..., // IMPORTENT:: id is auto generated.
   *  message: "some text",
   *  type: "info",         // supported types: "success", "error", "info" and "connection"
   * }
   * Note: id is automatically generated by uuid function, that can be found inside utils folder.
   */
  const addToast = useCallback(
    (toast) => {
      if (toast && toast.message !== "") {
        const newMessages = {
          id: uuid(),
          type: toast.type,
          message: toast.message,
        };

        setToastMessages((messages) => [...messages, newMessages]);
      }
    },
    [setToastMessages]
  );

  return (
    <ToastContext.Provider
      value={{ addToast: addToast, closeToast: closeToast }}
    >
      {props.children}
      {toastMessages.map((toastMessage) => (
        <Toast key={toastMessage.id} toastMessages={toastMessage} />
      ))}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
