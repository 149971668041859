import {
  GET_EVALUATION_TEMPLATES_FAIL,
  GET_EVALUATION_TEMPLATES_SUCCESS,
  GET_EVALUATION_TEMPLATE_SUCCESS,
  GET_EVALUATION_TEMPLATE_FAIL,
  GET_EVALUATION_TEMPLATES_MODULE_LIST_SUCCESS,
  GET_EVALUATION_TEMPLATES_MODULE_LIST_FAIL,
  MARK_EVALUATION_TEMPLATE_FAVOURITE_SUCCESS,
  REMOVE_TEMPLATE_SUCCESS,
} from "../actions/evaluationTemplates";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

const initialState = {
  templates: {
    obj_list: [],
    count: 0,
    page: 0,
    num_pages: 0,
  },
  currTemplate: {
    modules: [],
  },
  module_type_list: [],
};

const EvaluationTemplates = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVALUATION_TEMPLATES_SUCCESS: {
      return { ...state, templates: { ...action.payload.data.data } };
    }
    case GET_EVALUATION_TEMPLATES_FAIL: {
      return { ...state, templates: initialState.templates };
    }
    case GET_EVALUATION_TEMPLATE_SUCCESS: {
      return { ...state, currTemplate: { ...action.payload.data.data } };
    }
    case GET_EVALUATION_TEMPLATE_FAIL: {
      return { ...state, currTemplate: initialState.currTemplate };
    }
    case GET_EVALUATION_TEMPLATES_MODULE_LIST_SUCCESS: {
      return { ...state, module_type_list: action.payload.data.data };
    }
    case GET_EVALUATION_TEMPLATES_MODULE_LIST_FAIL: {
      return { ...state, module_type_list: [] };
    }
    case MARK_EVALUATION_TEMPLATE_FAVOURITE_SUCCESS: {
      const template_id = action.meta.previousAction.payload.id;
      return {
        ...state,
        templates: {
          ...state.templates,
          obj_list: state.templates.obj_list.map((template) =>
            template.id === template_id
              ? {
                  ...template,
                  is_favorite: !template.is_favorite,
                }
              : template
          ),
        },
      };
    }
    case REMOVE_TEMPLATE_SUCCESS: {
      const template_id = action.meta.previousAction.payload.id;
      return {
        ...state,
        templates: {
          ...state.templates,
          obj_list: state.templates.obj_list.filter(
            (template) => template.id !== template_id && template
          ),
        },
      };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default EvaluationTemplates;
