//* *********************************** REACT IMPORTS ************************************
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import MKinetikosReport from "../../../components/charts/mKinetikosReports/report";
import MKinetikosActivity from "../../../components/charts/mKinetikosReports/activity";
import Spinner from "../../../components/spinner";
import Icon from "components/icon";
import { icons } from "components/icon/icons";
import MessageModal from "components/messageModal";

//* ************************************** GLOBALS ***************************************
import bigBackButton from "../../../assets/images/big-left-arrow.svg";
import moment from "moment";
import {
  getReport,
  getMedicationActivity,
  getStepActivity,
  filterMedication,
} from "../../../redux/actions/patientReports";
import calendar from "../../../assets/images/calendar-small.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";

import {
  getCurrMonthBetweenDates,
  getLastMonthBetweenDates,
} from "../../../utils/dataAndTime";
import NavBar from "../../../components/navbar";
import Breadcrumbs from "../../../components/breadcrumbs";

import { removeRepetitionsByKey } from "utils/arrays";
import { getPatientInstitutions } from "redux/actions/patients";
import useToast from "hooks/useToast";

const PatientMkinetikosReport = (props) => {
  const history = useHistory();

  const activityCalendarRef = useRef();
  const reportCalendarRef = useRef();

  const { t } = useTranslation();

  const showToast = useToast();

  const [reportDateRange, setReportDateRange] = useState({
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [activityDateRange, setActivityDateRange] = useState({
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [medicationCheckboxes, setMedicationCheckboxes] = useState([]);

  const prescriptions = useSelector(
    (state) => state.patientsReports.prescriptions
  );

  const institutionList = useSelector((state) => [
    { name: t("patient.reports.all_institutions"), id: "" },
    { name: t("patient.reports.patient_prescriptions"), id: "patient" },
    ...state.patientsReports.institutions.map((inst) => ({
      name: inst.name,
      id: inst.id,
    })),
  ]);

  const updateMedicationCheckboxes = (name) => {
    setMedicationCheckboxes((prev) => {
      const newList = prev.slice();
      const allChecked = newList[0].checked;
      if (name === "all") {
        if (allChecked) {
          newList.forEach((checkbox) => (checkbox.checked = false));
        } else {
          newList.forEach((checkbox) => (checkbox.checked = true));
        }
      } else {
        const checkbox = newList.find((med) => med.name === name);
        checkbox.checked = !checkbox.checked;
        newList[0].checked = newList
          .slice(1)
          .every((checkbox) => checkbox.checked);
      }

      return newList;
    });
  };

  const onSelectMedicationCheckbox = (e) =>
    updateMedicationCheckboxes(e.target.name);

  const medicinesColors = useMemo(
    () => [
      "#508CC0",
      "#F5BD84",
      "#D76D68",
      "#4ba497",
      "#255986",
      "#FFD953",
      "#B382F180",
      "#66CDE380",
    ],
    []
  );

  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isActivityLoading, setIsActivityLoading] = useState(false);

  const [reportCheckboxs, setReportCheckboxs] = useState({
    all: true,
    test: true,
    triage_score: true,
    survey: true,
    medicines: true,
    // events: true,
    tapping: true,
    tug: true,
    brady: true,
  });

  const [reportRemoveKey, setReportRemoveKey] = useState(null);
  const [reportAddKey, setReportAddKey] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState("");

  const hasParkinson = useSelector((state) => state.patientsReports.has_pd);

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [helpBodyText, setHelpBodyText] = useState("");
  const [helpHeaderText, setHelpHeaderText] = useState("");

  const { patient_id } = useParams("patient_id");
  const dispatch = useDispatch();

  const getMedicineColor = useCallback(
    (index) => medicinesColors[index % medicinesColors.length],
    [medicinesColors]
  );

  const buildMedicationCheckboxes = useCallback(
    () =>
      setMedicationCheckboxes([
        { name: "all", checked: true, color: "#EFF3F6" },
        ...removeRepetitionsByKey(
          prescriptions,
          (med) => med.medicine_text
        ).map((med, index) => ({
          name: med.medicine_text,
          checked: true,
          color: getMedicineColor(index),
        })),
      ]),
    [prescriptions, getMedicineColor]
  );

  const getInstitutions = useCallback(
    () => dispatch(getPatientInstitutions(patient_id)),
    [dispatch, patient_id]
  );

  useEffect(() => {
    setIsReportLoading(true);
    dispatch(
      getReport(patient_id, reportDateRange.startDate, reportDateRange.endDate)
    ).finally(() => setIsReportLoading(false));
  }, [reportDateRange, dispatch, patient_id]);

  useEffect(() => {
    setReportCheckboxs({
      all: true,
      test: true,
      survey: true,
      medicines: true,
      // events: true,
      triage_score: hasParkinson ? true : false,
      tapping: hasParkinson ? true : false,
      tug: hasParkinson ? true : false,
      brady: hasParkinson ? true : false,
    });
  }, [hasParkinson]);

  useEffect(() => {
    setIsActivityLoading(true);
    Promise.all([
      dispatch(
        getMedicationActivity(
          patient_id,
          activityDateRange.startDate,
          activityDateRange.endDate,
          selectedInstitution
        )
      ),
      dispatch(
        getStepActivity(
          patient_id,
          activityDateRange.startDate,
          activityDateRange.endDate
        )
      ),
    ]).finally(() => setIsActivityLoading(false));
  }, [activityDateRange, selectedInstitution, dispatch, patient_id]);

  const selectInstitutionHandler = (e) => {
    setSelectedInstitution(e.target.value);
  };

  const checkboxHandler = (e) => {
    if (!isHelpModalOpen) {
      if (e.target.name === "all") {
        setReportCheckboxs({
          all: e.target.checked,
          test: e.target.checked,
          survey: e.target.checked,
          medicines: e.target.checked,
          triage_score: hasParkinson ? e.target.checked : false,
          tapping: hasParkinson ? e.target.checked : false,
          tug: hasParkinson ? e.target.checked : false,
          brady: hasParkinson ? e.target.checked : false,
          // events: e.target.checked,
        });
      } else if (e.target.checked) {
        const all = Object.keys(reportCheckboxs).every((k) =>
          k === "all" || k === e.target.name
            ? true
            : reportCheckboxs[k] === true
        );
        setReportCheckboxs({
          ...reportCheckboxs,
          all,
          [e.target.name]: e.target.checked,
        });
      } else {
        setReportCheckboxs({
          ...reportCheckboxs,
          all: false,
          [e.target.name]: e.target.checked,
        });
      }
      if (e.target.checked) {
        setReportAddKey(e.target.name);
      } else {
        setReportRemoveKey(e.target.name);
      }
    }
  };

  const requestReportInfoFromThisMonth = () => {
    const { startOfMonth, endOfMonth } = getCurrMonthBetweenDates();
    if (reportCalendarRef && reportCalendarRef.current) {
      reportCalendarRef.current.setStartDate(startOfMonth);
      reportCalendarRef.current.setEndDate(endOfMonth);
    }
    setReportDateRange({
      startDate: startOfMonth.format("YYYY-MM-DD"),
      endDate: endOfMonth.format("YYYY-MM-DD"),
    });
  };

  const requestReportInfoFromLastMonth = () => {
    const { startOfLastMonth, endOflastMonth } = getLastMonthBetweenDates();
    if (reportCalendarRef && reportCalendarRef.current) {
      reportCalendarRef.current.setStartDate(startOfLastMonth);
      reportCalendarRef.current.setEndDate(endOflastMonth);
    }
    setReportDateRange({
      startDate: startOfLastMonth.format("YYYY-MM-DD"),
      endDate: endOflastMonth.format("YYYY-MM-DD"),
    });
  };

  const requestActivityInfoFromThisMonth = () => {
    const { startOfMonth, endOfMonth } = getCurrMonthBetweenDates();
    if (activityCalendarRef && activityCalendarRef.current) {
      activityCalendarRef.current.setStartDate(startOfMonth);
      activityCalendarRef.current.setEndDate(endOfMonth);
    }
    setActivityDateRange({
      startDate: startOfMonth.format("YYYY-MM-DD"),
      endDate: endOfMonth.format("YYYY-MM-DD"),
    });
  };

  const requestActivityFromLastMonth = () => {
    const { startOfLastMonth, endOflastMonth } = getLastMonthBetweenDates();
    if (activityCalendarRef && activityCalendarRef.current) {
      activityCalendarRef.current.setStartDate(startOfLastMonth);
      activityCalendarRef.current.setEndDate(endOflastMonth);
    }
    setActivityDateRange({
      startDate: startOfLastMonth.format("YYYY-MM-DD"),
      endDate: endOflastMonth.format("YYYY-MM-DD"),
    });
  };

  const isActivityDateRangeValid = (startDate, endDate) => {
    return endDate.diff(startDate, "days") >= 6;
  };

  const onClickHelpIcon = (e) => {
    let targetName = e.target.name;

    switch (targetName) {
      case "tests":
        setHelpHeaderText(t("patient.reports.tests"));
        setHelpBodyText(t("patient.reports.help_text.tests"));
        break;
      case "surveys":
        setHelpHeaderText(t("patient.reports.surveys"));
        setHelpBodyText(t("patient.reports.help_text.surveys"));
        break;
      case "prescribed_medication":
        setHelpHeaderText(t("patient.reports.prescribed_meds"));
        setHelpBodyText(t("patient.reports.help_text.prescribed_meds"));
        break;
      case "risk_assessment":
        setHelpHeaderText(t("patient.reports.risk_assessment"));
        setHelpBodyText(t("patient.reports.help_text.risk_assessment"));
        break;
      case "finger_tapping":
        let finger_tapping_body_html = (
          <>
            <p>{t("patient.reports.help_text.finger_tapping_p1")}</p>
            <p>{t("patient.reports.help_text.finger_tapping_p2")}</p>
            <p>{t("patient.reports.help_text.finger_tapping_p3")}</p>
            <p>
              <i>
                [1]: [MDS-UPDRS Part III | AMP-PD](
                <a href="https://amp-pd.org/mds-updrs-partIII" target="_blank">
                  https://amp-pd.org/mds-updrs-partIII
                </a>
                )
              </i>
            </p>
          </>
        );

        setHelpHeaderText(t("patient.reports.finger_tapping"));
        setHelpBodyText(finger_tapping_body_html);
        break;
      case "tug":
        let tug_body_html = (
          <>
            <p>{t("patient.reports.help_text.tug_p1")}</p>
            <p>{t("patient.reports.help_text.tug_p2")}</p>
          </>
        );

        setHelpHeaderText(t("patient.reports.tug"));
        setHelpBodyText(tug_body_html);
        break;
      case "bradykinesia":
        let bradykinesia_body_html = (
          <>
            <p>{t("patient.reports.help_text.brady_p1")}</p>
            <p className="mb-4">{t("patient.reports.help_text.brady_p2")}</p>

            <p>
              <i>
                [1]: [MDS-UPDRS Part III | AMP-PD](
                <a href="https://amp-pd.org/mds-updrs-partIII" target="_blank">
                  https://amp-pd.org/mds-updrs-partIII
                </a>
                )
              </i>
            </p>
            <p>
              <i>
                [2]: Erb, M. K. et al. (2020). mHealth and wearable technology
                should replace motor diaries to track motor fluctuations in
                Parkinson's disease. Npj Digital Medicine, 3(1).
              </i>
            </p>
          </>
        );

        setHelpHeaderText(t("patient.reports.brady"));
        setHelpBodyText(bradykinesia_body_html);
        break;
      default:
        setHelpBodyText("");
    }

    setIsHelpModalOpen(true);
  };

  const onCloseHelpModal = () => {
    setIsHelpModalOpen(false);
    setHelpBodyText("");
  };

  // Ensure the selected dates span a full week
  const onSelectActivityDates = (startDate, endDate) => {
    if (!isActivityDateRangeValid(startDate, endDate)) {
      if (activityCalendarRef && activityCalendarRef.current) {
        const newStartDate = moment(endDate).subtract(6, "days");
        activityCalendarRef.current.setStartDate(newStartDate);

        showToast({
          message: t("patient.reports.date_span_error", {
            startDate: newStartDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
          }),
          type: "alert",
        });
      }
    }
  };

  const onApplyActivityDates = (e, p) => {
    setActivityDateRange({
      startDate: p.startDate.format("YYYY-MM-DD"),
      endDate: p.endDate.format("YYYY-MM-DD"),
    });
  };

  useEffect(getInstitutions, [getInstitutions]);

  useEffect(buildMedicationCheckboxes, [buildMedicationCheckboxes]);

  useEffect(() => {
    const filteredNames = medicationCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.name);
    dispatch(filterMedication(filteredNames));
  }, [medicationCheckboxes, dispatch]);

  // Reset to defaults every time you change patients
  useEffect(() => {
    setSelectedInstitution("");
    setActivityDateRange({
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
    setReportDateRange({
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  }, [patient_id]);

  //* ************************************** RENDER **************************************
  return (
    <div className="col patient-container">
      <div className="row-space-between">
        <Breadcrumbs />
        <NavBar />
      </div>
      <div className="title-container">
        <img
          onClick={() => {
            history.push(`/patient/${patient_id}/evaluations`);
          }}
          alt="back Button"
          src={bigBackButton}
        />
        <h4>{t("titles.reports")}</h4>
      </div>
      <div className="mkinetikos-report">
        <div className="report-box">
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title">{t("patient.reports.report")}</div>
            <div style={{ position: "relative" }}>
              <button
                onClick={requestReportInfoFromLastMonth}
                className="report-select-date-input mr-1"
              >
                {t("patient.reports.last_month")}
              </button>

              <button
                onClick={requestReportInfoFromThisMonth}
                className="report-select-date-input mr-1"
              >
                {t("patient.reports.this_month")}
              </button>
              <DateRangePicker
                initialSettings={{
                  startDate: moment(
                    reportDateRange.startDate,
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY"),
                  endDate: moment(reportDateRange.endDate, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  ),
                }}
                onApply={(e, p) => {
                  setReportDateRange({
                    startDate: p.startDate.format("YYYY-MM-DD"),
                    endDate: p.endDate.format("YYYY-MM-DD"),
                  });
                }}
                ref={reportCalendarRef}
              >
                <button className="report-select-date-input">
                  <img
                    alt="Person exercise"
                    className="button-icon mr-1"
                    src={calendar}
                  />
                  {reportDateRange.startDate} - {reportDateRange.endDate}
                </button>
              </DateRangePicker>
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-auto  mr-2">
              {" "}
              <div className="check-box-wrapper mt-1">
                <div className="input-wrapper col-md-auto">
                  <input
                    checked={reportCheckboxs.all}
                    onChange={checkboxHandler}
                    style={{
                      backgroundColor: reportCheckboxs.all && "#EFF3F6",
                    }}
                    name="all"
                    type={"checkbox"}
                    id="all-checkbox"
                  />
                </div>

                <label type="checkbox" htmlFor="all-checkbox">
                  {" "}
                  <span className="regular-text">
                    {t("patient.reports.all_reports")}
                  </span>
                </label>
              </div>
              <div className="check-box-wrapper">
                <div className="input-wrapper col-md-auto">
                  <input
                    checked={reportCheckboxs.test}
                    onChange={checkboxHandler}
                    style={{
                      backgroundColor: reportCheckboxs.test && "#508CC0",
                    }}
                    name="test"
                    type={"checkbox"}
                    id="test-checkbox"
                  />
                </div>

                <label type="checkbox" htmlFor="test-checkbox">
                  <span className="regular-text metrics-name-container">
                    {t("patient.reports.tests")}
                    <span
                      className="help-container"
                      onClick={(e) => onClickHelpIcon(e)}
                    >
                      <Icon icon={icons["help-icon-gray"]} name="tests" />
                    </span>
                  </span>
                </label>
              </div>
              <div className="check-box-wrapper">
                <div className="input-wrapper col-md-auto">
                  <input
                    checked={reportCheckboxs.survey}
                    onChange={checkboxHandler}
                    style={{
                      backgroundColor: reportCheckboxs.survey && "#F5BD84",
                    }}
                    name="survey"
                    type={"checkbox"}
                    id="survey-checkbox"
                  />
                </div>

                <label type="checkbox" htmlFor="survey-checkbox">
                  <span className="regular-text metrics-name-container">
                    {t("patient.reports.surveys")}
                    <span
                      className="help-container"
                      onClick={(e) => onClickHelpIcon(e)}
                    >
                      <Icon icon={icons["help-icon-gray"]} name="surveys" />
                    </span>
                  </span>
                </label>
              </div>
              <div className="check-box-wrapper mt-1">
                <div className="input-wrapper col-md-auto">
                  <input
                    checked={reportCheckboxs.medicines}
                    onChange={checkboxHandler}
                    style={{
                      backgroundColor: reportCheckboxs.medicines && "#D76D68",
                    }}
                    name="medicines"
                    type={"checkbox"}
                    id="medicines-checkbox"
                  />
                </div>

                <label type="checkbox" htmlFor="medicines-checkbox">
                  <span className="regular-text metrics-name-container">
                    {t("patient.reports.prescribed_meds")}
                    <span
                      className="help-container"
                      onClick={(e) => onClickHelpIcon(e)}
                    >
                      <Icon
                        icon={icons["help-icon-gray"]}
                        name="prescribed_medication"
                      />
                    </span>
                  </span>
                </label>
              </div>
              {hasParkinson && (
                <>
                  <div className="check-box-wrapper mt-1">
                    <div className="input-wrapper col-md-auto">
                      <input
                        checked={reportCheckboxs.triage_score}
                        onChange={checkboxHandler}
                        style={{
                          backgroundColor:
                            reportCheckboxs.triage_score && "#64C1B4",
                        }}
                        name="triage_score"
                        type={"checkbox"}
                        id="checkbox"
                      />
                    </div>

                    <label type="checkbox" htmlFor="checkbox">
                      <span className="regular-text metrics-name-container">
                        {t("patient.reports.risk_assessment")}
                        <span
                          className="help-container"
                          onClick={(e) => onClickHelpIcon(e)}
                        >
                          <Icon
                            icon={icons["help-icon-gray"]}
                            name="risk_assessment"
                          />
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="check-box-wrapper mt-1">
                    <div className="input-wrapper col-md-auto">
                      <input
                        checked={reportCheckboxs.tapping}
                        onChange={checkboxHandler}
                        style={{
                          backgroundColor: reportCheckboxs.tapping && "#FFD953",
                        }}
                        name="tapping"
                        type={"checkbox"}
                        id="tapping-checkbox"
                      />
                    </div>
                    <label type="checkbox" htmlFor="tapping-checkbox">
                      <span className="regular-text metrics-name-container">
                        {t("patient.reports.finger_tapping")}
                        <span
                          className="help-container"
                          onClick={(e) => onClickHelpIcon(e)}
                        >
                          <Icon
                            icon={icons["help-icon-gray"]}
                            name="finger_tapping"
                          />
                        </span>
                      </span>
                    </label>
                  </div>

                  <div className="check-box-wrapper mt-1">
                    <div className="input-wrapper col-md-auto">
                      <input
                        checked={reportCheckboxs.tug}
                        onChange={checkboxHandler}
                        style={{
                          backgroundColor: reportCheckboxs.tug && "#B382F1",
                        }}
                        name="tug"
                        type={"checkbox"}
                        id="tug-checkbox"
                      />
                    </div>

                    <label type="checkbox" htmlFor="tug-checkbox">
                      <span className="regular-text metrics-name-container">
                        {t("patient.reports.tug")}
                        <span
                          className="help-container"
                          onClick={(e) => onClickHelpIcon(e)}
                        >
                          <Icon icon={icons["help-icon-gray"]} name="tug" />
                        </span>
                      </span>
                    </label>
                  </div>

                  <div className="check-box-wrapper mt-1">
                    <div className="input-wrapper col-md-auto">
                      <input
                        checked={reportCheckboxs.brady}
                        onChange={checkboxHandler}
                        style={{
                          backgroundColor: reportCheckboxs.brady && "#66CDE3",
                        }}
                        name="brady"
                        type={"checkbox"}
                        id="brady-checkbox"
                      />
                    </div>

                    <label type="checkbox" htmlFor="brady-checkbox">
                      <span className="regular-text metrics-name-container">
                        {t("patient.reports.brady")}
                        <span
                          className="help-container"
                          onClick={(e) => onClickHelpIcon(e)}
                        >
                          <Icon
                            icon={icons["help-icon-gray"]}
                            name="bradykinesia"
                          />
                        </span>
                      </span>
                    </label>
                  </div>
                </>
              )}
            </div>
            <div className="col chart-container">
              {isReportLoading ? (
                <div className="position-relative">
                  <Spinner type={"big-blue"} />{" "}
                </div>
              ) : (
                <div className="chart-box flex-d-row justify-content-center">
                  <MKinetikosReport
                    reportCheckboxs={reportCheckboxs}
                    addKey={reportAddKey}
                    removeKey={reportRemoveKey}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="activity-box mt-4">
          <div className="flex-d-row d-flex justify-content-between align-items-center">
            <div className="small-title">{t("patient.reports.activity")}</div>
            <div className="">
              {" "}
              <button
                onClick={requestActivityFromLastMonth}
                className="report-select-date-input mr-1"
              >
                {t("patient.reports.last_month")}
              </button>
              <button
                onClick={requestActivityInfoFromThisMonth}
                className="report-select-date-input mr-1"
              >
                {t("patient.reports.this_month")}
              </button>
              <DateRangePicker
                initialSettings={{
                  startDate: moment(
                    activityDateRange.startDate,
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY"),
                  endDate: moment(
                    activityDateRange.endDate,
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY"),
                }}
                onCallback={onSelectActivityDates}
                onApply={onApplyActivityDates}
                ref={activityCalendarRef}
              >
                <button className="report-select-date-input">
                  <img
                    alt="Person exercise"
                    className="button-icon mr-1"
                    src={calendar}
                  />
                  {activityDateRange.startDate} - {activityDateRange.endDate}
                </button>
              </DateRangePicker>
              <select
                value={selectedInstitution}
                disabled={false}
                onChange={(e) => {
                  selectInstitutionHandler(e);
                }}
                className={`dropdown1 form-input-1 ml-1`}
                name="language_id"
                id="language_id"
              >
                {institutionList.map((institution, index) => {
                  return (
                    <option key={`institution ${index}`} value={institution.id}>
                      {institution.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-auto mr-2">
              {!isActivityLoading &&
                medicationCheckboxes.map((medication, index) => {
                  return (
                    <div
                      className="check-box-wrapper mt-1"
                      key={`medicine ${index}`}
                    >
                      <div className="input-wrapper col-md-auto">
                        <input
                          checked={medication.checked || false}
                          onChange={onSelectMedicationCheckbox}
                          style={{
                            backgroundColor:
                              medication.checked && medication.color,
                          }}
                          name={medication.name}
                          type="checkbox"
                          id={`medication-${medication.name}-checkbox`}
                        />
                      </div>
                      <label
                        type="checkbox"
                        htmlFor={`medication-${medication.name}-checkbox`}
                      >
                        <span className="regular-text text-capitalize">
                          {medication.name === "all"
                            ? t("patient.reports.all_reports")
                            : medication.name}
                        </span>
                      </label>
                    </div>
                  );
                })}
            </div>
            <div className="col">
              {isActivityLoading ? (
                <div
                  style={{ marginTop: "100px" }}
                  className="position-relative "
                >
                  <Spinner type={"big-blue"} />{" "}
                </div>
              ) : (
                <div className="chart-box flex-d-row justify-content-center position-relative">
                  <MKinetikosActivity
                    activeMedicineCheckboxes={medicationCheckboxes}
                    medicinesColors={medicinesColors}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isHelpModalOpen && helpBodyText !== "" && (
        <MessageModal
          headerText={helpHeaderText}
          body={helpBodyText}
          hasCloseButton={true}
          handleClose={() => onCloseHelpModal()}
        />
      )}
    </div>
  );
};

export default PatientMkinetikosReport;
