import {
  MY_PERMISSION_FOR_MKINETIKOS_LICENSE,
  MY_PERMISSION_FOR_MKINETIKOS_LICENSE_FAIL,
} from "../actions/permissions";

const initialState = {
  accessPermissionForLicense: false,
};

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case MY_PERMISSION_FOR_MKINETIKOS_LICENSE: {
      return { ...state, accessPermissionForLicense: true };
    }
    case MY_PERMISSION_FOR_MKINETIKOS_LICENSE_FAIL: {
      return { ...state, accessPermissionForLicense: false };
    }

    default:
      return state;
  }
};

export default permissions;
