export function create_activities_button(
  actions_div,
  activity,
  hidden = false,
  disabled = false,
  off = true
) {
  let button = document.createElement("div");
  button.setAttribute("role", "button");
  button.classList = "action";
  // TODO: icons does not exist
  button.innerHTML = {
    zoom: `<i class="icon zoom-in" aria-hidden="true"></i>`,
    drag: `<i class="icon move" aria-hidden="true"></i>`,
    tag: `<i class="icon tag" aria-hidden="true"></i>`,
    save: `<i class="icon save" aria-hidden="true"></i>`,
    undo: `<i class="icon arrow-back" aria-hidden="true"></i>`,
    exit: `<i class="icon close" aria-hidden="true"></i>`,
    hide: `<i class="icon hidden" aria-hidden="true"></i>`,
    show: `<i class="icon visible" aria-hidden="true"></i>`,
    copy: `<i class="icon copy" aria-hidden="true"></i>`,
    chart: `<i class="icon pie-chart" aria-hidden="true"></i>`,
    list: `<i class="icon list" aria-hidden="true"></i>`,
    reset_zoom: `<i class="icon refresh" aria-hidden="true"></i>`,
    download: `<i class="icon download" aria-hidden="true"></i>`,
    delete: `<i class="icon delete" aria-hidden="true"></i>`,
  }[activity];

  if (hidden) button.style.display = "none";
  if (disabled) button.disabled = true;
  if (off) button.classList.add("plot-disabled");

  actions_div.appendChild(button);
  return button;
}

export function getTransformation(transform) {
  // Create a dummy g for calculation purposes only. This will never
  // be appended to the DOM and will be discarded once this function
  // returns.
  var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

  // Set the transform attribute to the provided string value.
  g.setAttributeNS(null, "transform", transform);

  // consolidate the SVGTransformList containing all transformations
  // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
  // its SVGMatrix.
  var matrix = g.transform.baseVal.consolidate().matrix;

  // Below calculations are taken and adapted from the private function
  // transform/decompose.js of D3's module d3-interpolate.
  var { a, b, c, d, e, f } = matrix; // ES6, if this doesn't work, use below assignment
  // var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
  var scaleX, scaleY, skewX;
  if ((scaleX = Math.sqrt(a * a + b * b))) {
    a /= scaleX;
    b /= scaleX;
  }
  if ((skewX = a * c + b * d)) {
    c -= a * skewX;
    d -= b * skewX;
  }
  if ((scaleY = Math.sqrt(c * c + d * d))) {
    c /= scaleY;
    d /= scaleY;
    skewX /= scaleY;
  }
  if (a * d < b * c) {
    a = -a;
    b = -b;
    skewX = -skewX;
    scaleX = -scaleX;
  }
  return {
    translateX: e,
    translateY: f,
    rotate: (Math.atan2(b, a) * 180) / Math.PI,
    skewX: (Math.atan(skewX) * 180) / Math.PI,
    scaleX: scaleX,
    scaleY: scaleY,
  };
}
