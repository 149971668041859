import { useState } from "react";
import { useDispatch } from "react-redux";
import Spinner from "../../../../components/spinner";
import { disableMultiAuth } from "../../../../redux/actions/multiFactorAuth";
import { getCurrentInstitution } from "../../../../redux/actions/institutions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ChangeMethod = ({ cancelChangeMethod }) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const goToMFA = () => history.replace("/setup_multifactor_auth");

  const submitChangeMethod = () => {
    setIsLoading(true);
    dispatch(disableMultiAuth())
      .then(goToMFA)
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="change-password-wrapper">
      <span className="small-title">
        {t("authentication.mfa.change_method")}
      </span>
      <div className="change-password-body">
        <div className="regular-text mb-4">
          {t("authentication.mfa.change_method_body")}
        </div>

        <div className="password-footer">
          <button
            onClick={cancelChangeMethod}
            className="small-wider-button transparent-button"
          >
            <u>{t("buttons.cancel")}</u>
          </button>
          <div className="input-image-wrapper m-0">
            <button
              value={`${t("buttons.cancel")}`}
              className={`small-wider-button secondary-button ${
                isLoading && "transparent"
              }`}
              onClick={submitChangeMethod}
            >
              {isLoading ? <Spinner type={"small"} /> : t("buttons.confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeMethod;
