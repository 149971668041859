//* ******************************** REACT NATIVE IMPORTS ********************************

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

//* *********************************** OUR COMPONENTS ***********************************

import { icons } from "components/icon/icons";
import Icon from "components/icon";
import Spinner from "components/spinner";

//* *************************************** STYLES ***************************************

//* ************************************** GLOBALS ***************************************
import {
  getLastSevenDays,
  getWeekDayAbbrevation,
  getPastDate,
  isoToDate,
} from "utils/dataAndTime";
import { capitalizeFirstLetter } from "utils/string";

import { getMedicineSummary } from "redux/actions/medicineSummary";

const PatientWeeklyMedicineSummary = ({ patientId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const medicineSummary = useSelector((state) => state.medicineSummary);

  const [isLoading, setIsLoading] = useState(false);
  const [hasAccessPermit, setHasAccessPermit] = useState(true);

  const lastSevenDays = getLastSevenDays();

  const [prescriptionsLoading, setPrescriptionsLoading] = useState(true);
  const [medicineSummaryIsLoading, setMedicineSummaryIsLoading] =
    useState(false);
  const [prescriptions, setPrescriptions] = useState([]);

  const legendaItems = [
    {
      icon: "taken-icon",
      title: "home.prescription.taken",
    },
    {
      icon: "not-taken-icon",
      title: "home.prescription.not_taken",
    },
    {
      icon: "no-record",
      title: "home.prescription.no_record",
    },
  ];

  useEffect(() => {
    if (patientId) {
      setMedicineSummaryIsLoading(true);
      setIsLoading(true);
      dispatch(getMedicineSummary(patientId))
        .then(() => setHasAccessPermit(true))
        .catch((error) => {
          if (
            error.error.response.data.msg &&
            error.error.response.data.msg === "cannot_access_patient"
          ) {
            setHasAccessPermit(false);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setMedicineSummaryIsLoading(false);
        });
    }
  }, [patientId]);

  useEffect(() => {
    if (!medicineSummaryIsLoading) {
      setPrescriptions([]);
      getPrescriptionsData();
    }
  }, [medicineSummaryIsLoading]);

  const getPrescriptionUsageFormattedData = (usageData) => {
    if (!usageData) return null;

    let usageList = [];
    const usageKeys = Object.keys(usageData);

    usageKeys.map((key) => {
      const newUsage = {
        date: isoToDate(key),
        usageStatus: usageData[key],
      };
      usageList.push(newUsage);
    });

    return usageList.reverse();
  };

  const getPrescriptionUsageInLastSevenDays = (usageData) => {
    const today = new Date();
    const lastSevenDays = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(today.getTime());
      date.setDate(today.getDate() - i);

      const newDate = {
        date: date,
        usageStatus: undefined,
      };

      lastSevenDays.push(newDate);
    }

    if (!usageData) return lastSevenDays.reverse();

    const usageDataFormatted = getPrescriptionUsageFormattedData(usageData);

    usageDataFormatted.map((element) => {
      const newDate = {
        date: element.date,
        usageStatus: element.usageStatus,
      };

      const index = lastSevenDays.findIndex(
        (object) => object.date.getDate() === element.date.getDate()
      );
      if (index > -1) {
        lastSevenDays[index] = newDate;
      }
    });

    return lastSevenDays.reverse();
  };

  const getPrescriptionsData = () => {
    const medicines = Object.keys(medicineSummary.data);

    medicines?.map((medicine) => {
      const prescriptions = Object.keys(medicineSummary.data[medicine]);

      prescriptions.map((prescriptionKey, index) => {
        const prescriptionItem =
          medicineSummary?.data[medicine][prescriptionKey];
        const medicineName = prescriptionItem?.medicine_text;
        const time = prescriptionKey;

        const usageData = prescriptionItem?.status_list;

        let lastSevenDaysUsage = [];

        if (usageData)
          lastSevenDaysUsage = getPrescriptionUsageInLastSevenDays(usageData);

        let startDate = null;
        let endDate = null;

        if (usageData) {
          startDate = lastSevenDaysUsage?.find(
            (date) => date.usageStatus !== undefined
          )?.date;
          endDate = lastSevenDaysUsage
            ?.reverse()
            ?.find((date) => date.usageStatus !== undefined).date;
        }

        const prescription = {
          id: index,
          medicineName,
          lastSevenDaysUsage,
          startDate,
          endDate,
          time,
        };

        setPrescriptions((prescriptions) => [...prescriptions, prescription]);
        setPrescriptionsLoading(false);
      });
    });
  };

  const _renderWeeklySummary = (prescription, index) => (
    <div
      key={`Prescription ${index}`}
      className={`flex-d-row center-align medicine-box ${
        index % 2 && "white"
      } ${prescription.time && "big"}`}
    >
      <div className="col-3 medicine-info">
        <span className="medicine-name">{prescription.medicineName}</span>
        <ReactTooltip className="tooltip" place="bottom" effect="solid" />

        <span className="medicine-time">{prescription.time}</span>
      </div>
      <div className="col flex-d-row medicine-status">
        {prescriptions[index].lastSevenDaysUsage.reverse().map((usage) => {
          const iconSelector = (status) => {
            return status === true
              ? "taken-icon"
              : status === false
              ? "not-taken-icon"
              : status === null && "no-record";
          };

          return (
            <div className="prescription-usage-container">
              {usage.usageStatus !== undefined && (
                <Icon
                  icon={icons[iconSelector(usage.usageStatus)]}
                  className="prescription-usage-icon"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  const _renderPrescriptionsBody = () => (
    <>
      <div className="row prescriptions-summary-body">
        <div className="row header-date">
          <div className="col-3"></div>
          <div className="col-5">
            <i className="medicine-time">{getPastDate(6, "DD/MM")}</i>
          </div>
          <div className="col">
            <i className="medicine-time right-text">
              {getPastDate(0, "DD/MM")}
            </i>
          </div>
        </div>
        <div className="row title">
          <div className="col-3 flex-d-row center-align">
            <i className="medicine-time">
              {t("home.prescription.last_7_days")}
            </i>
          </div>
          <div className="col flex-d-row week-days">
            {lastSevenDays.map((day, index) => {
              const today = new Date();

              return (
                <div className="week-day-container" key={index}>
                  <p
                    className={`${
                      day.getDay() === today.getDay()
                        ? "week-day-title isToday"
                        : "week-day-title"
                    }`}
                    style={{ color: day.getDay() === 0 ? "#508CC0" : "" }}
                  >
                    {capitalizeFirstLetter(getWeekDayAbbrevation(day))}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {!prescriptionsLoading &&
          prescriptions &&
          prescriptions.map((prescription, index) =>
            _renderWeeklySummary(prescription, index)
          )}
      </div>

      <div className="prescriptions-summary-footer">
        {legendaItems.map((legendaItem) => {
          return (
            <div className="prescription-summary-footer-item">
              <Icon icon={icons[legendaItem.icon]} />
              <span>{t(legendaItem.title)}</span>
            </div>
          );
        })}
      </div>
    </>
  );

  return (
    hasAccessPermit && (
      <div className="data-card">
        <div className="row-space-between">
          <span className="small-title">
            {t("home.prescription.prescriptions")}
          </span>
        </div>
        {isLoading ? (
          <div className="position-relative">
            <Spinner type={"small-blue"} />
          </div>
        ) : Object.keys(medicineSummary.data).length > 0 ? (
          _renderPrescriptionsBody()
        ) : medicineSummary.error ? (
          <div className="medium-base-color-text message-wrapper medicine-message warning-color">
            <span>{t("home.prescription.err")}</span>
          </div>
        ) : (
          <div className="medium-base-color-text message-wrapper medicine-message">
            <div className="no-data-text">{t("home.no_data")}</div>
          </div>
        )}
      </div>
    )
  );
};

export default React.memo(PatientWeeklyMedicineSummary);
