import {
  GET_PATIENT_CONNECTION_REQUESTS_FAIL,
  GET_PATIENT_CONNECTION_REQUESTS_SUCCESS,
} from "../actions/patientConnectionRequests";

const initialState = {
  results: [],
  count: 0,
  page: 0,
  num_pages: 0,
};

const patientConnectionRequests = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_CONNECTION_REQUESTS_SUCCESS: {
      return {
        ...state,
        ...action.payload.data.data,
      };
    }
    case GET_PATIENT_CONNECTION_REQUESTS_FAIL: {
      return initialState;
    }
    default:
      return state;
  }
};

export default patientConnectionRequests;
