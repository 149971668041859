/* eslint-disable react-hooks/exhaustive-deps */
//* *********************************** REACT IMPORTS ************************************
import React, { useState, useRef, Fragment } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

//* *********************************** OUR COMPONENTS ***********************************
import Spinner from "components/spinner";
import DropdownOptions from "components/dropdownOptions";

//* *********************************** HOOKS ***********************************
import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** REDUX *****************************************
import {
  getPatientAvailableChatParticipants,
  addPatientChatParticipant,
  addPatientChatMessage,
  getPatientChat,
} from "redux/actions/patients";

//* ************************************** GLOBALS ***************************************
import plusIcon from "assets/images/plus_blue_back_background.svg";
import plusSelectedIcon from "assets/images/plus_white_back_primary.svg";
import closeIcon from "assets/images/close_primary_back_white.svg";
/* import moreBlue from "../../../../assets/images/more-blue.svg";
import moreBlueSelected from "../../../../assets/images/more-blue-selected-grey.svg"; */
import sendIcon from "assets/images/send.svg";
/* import sentIcon from "../../../../assets/images/sent.svg"; */
import { getAvatarWithInitials } from "utils/avatar";
import { formatDate } from "utils/dataAndTime";

const ChatModule = ({
  openChatId,
  openChat,
  chatMessages,
  isLoadingMessages,
  isInstitutionChat,
  setShowChatAlreadyExistsModal,
  canOnlyManageOwnPatients,
}) => {
  const { patient_id } = useParams("patient_id");
  const history = useHistory();
  const dispatch = useDispatch();
  const institutionId = useSelector((state) => state.institutions.current_institution.id);
  const patientHome = useSelector((state) => state.patientHome);
  const patientAvailableChatParticipants = useSelector(
    (state) => state.patientAvailableChatParticipants
  );
  const { t, i18n } = useTranslation();
  const dropdownOptionsRef = useRef(null);
  const showParticipantsRef = useRef(null);

  const patientsParticipantsId = openChat?.participants
    .map((participant) => (participant.has_mkinetikos === true ? participant.id : null))
    .filter((item) => item !== null);

  const [isAddParticipantOpen, setIsAddParticipantOpen] = useState(false);
  const newParticipantInputRef = useRef(null);
  const [isLoadingNewParticipants, setIsLoadingNewParticipants] = useState(false);
  const [newChatParticipantSearch, setNewChatParticipantSearch] = useState("");
  const [showParticipantsListDropdown, setShowParticipantsListDropdown] = useState(false);

  const [messageInput, setMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const messageInputRef = useRef(null);

  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
    if (newChatParticipantSearch !== "") {
      setIsLoadingNewParticipants(true);
      dispatch(
        getPatientAvailableChatParticipants(patient_id, {
          q: newChatParticipantSearch,
        })
      ).then(
        (res) => {
          setIsLoadingNewParticipants(false);
        },
        (err) => {
          console.log("getPatientAvailableChatParticipants Error:", err);
          setIsLoadingNewParticipants(false);
        }
      );
    }
  };
  const onBlur = () => setFocused(false);
  const onInput = (e) => {
    setNewChatParticipantSearch(e.target.textContent);
    if (e.target.textContent !== "") {
      setIsLoadingNewParticipants(true);
      dispatch(
        getPatientAvailableChatParticipants(patient_id, {
          q: e.target.textContent,
        })
      ).then(
        (res) => {
          setIsLoadingNewParticipants(false);
        },
        (err) => {
          console.log("getPatientAvailableChatParticipants Error:", err);
          setIsLoadingNewParticipants(false);
        }
      );
    }
  };

  //* ************************************** HANDLERS ************************************
  const addNewParticipant = (newParticipantId) => {
    dispatch(addPatientChatParticipant(patient_id, openChatId, [newParticipantId])).then(
      (res) => {
        if (res.payload.data && res.payload.data.msg === "Chat already exists") {
          setShowChatAlreadyExistsModal(true);
          setIsAddParticipantOpen(false);
        }
      },
      (err) => {
        console.log("addPatientChatParticipant Error:", err);
      }
    );
  };

  useOnClickOutside([dropdownOptionsRef, showParticipantsRef], () =>
    setShowParticipantsListDropdown(false)
  );

  const messageInputHandler = (e) => {
    setMessageInput(e.target.value);
  };

  const sendMessage = () => {
    if (messageInput !== "") {
      setIsLoading(true);
      dispatch(addPatientChatMessage(patient_id, openChatId, messageInput)).then(
        (res) => {
          messageInputRef.current.focus();
          setMessageInput("");
          setIsLoading(false);

          dispatch(getPatientChat(patient_id, openChatId, false)).then(
            (res) => {},
            (err) => {
              console.log("getPatientChat Error:", err);
            }
          );
        },
        (err) => {
          console.log("addPatientChatMessage Error:", err);
          messageInputRef.current.focus();
        }
      );
    }
  };

  const getDropdownButtons = () => {
    let buttonList = [];

    if (isLoadingNewParticipants) {
      buttonList = [
        {
          html: (
            <div
              className="loading"
              key="loading"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              <div className="spinner small-blue" />
            </div>
          ),
        },
      ];
    } else {
      if (patientAvailableChatParticipants.length > 0) {
        buttonList = patientAvailableChatParticipants
          .map((avPart) => {
            const alreadyInChat = openChat?.participants.some((participant) => {
              return participant.id === avPart.id;
            });
            if (!alreadyInChat) {
              return {
                html: (
                  <button
                    key={avPart.id}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                      addNewParticipant(avPart.id);
                      newParticipantInputRef.current.textContent = "";
                      setNewChatParticipantSearch("");
                    }}
                  >
                    <img
                      src={avPart.photo}
                      alt="New Participant"
                      className="dropdown-btn-img"
                      onError={(e) => {
                        e.target.src = getAvatarWithInitials(36, avPart.full_name);
                      }}
                    />
                    <div className="regular-text black-color">{avPart.full_name}</div>
                  </button>
                ),
              };
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);
      }

      if (buttonList.length === 0) {
        buttonList = [
          {
            html: (
              <button key="no_users">
                <div className="regular-text black-color">
                  {t("patient.my_care_team.no_available_users")}
                </div>
              </button>
            ),
          },
        ];
      }
    }
    return buttonList;
  };

  const getParticipantsListDropdownButtons = () => {
    return openChat?.participants.map((participant) => {
      return {
        html: (
          <button
            key={participant.id}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
              e.preventDefault();
              history.push(
                participant.role_staff === false
                  ? `/patient/${patient_id}/evaluations`
                  : `/my_area/institutions/${institutionId}/users/${participant.id}`
              );
              // alert(
              //   'TO DO: Send to URL "/my_area/institutions/:institution_id/users/:user_id/"'
              // );
            }}
          >
            <img
              src={participant.photo_url}
              alt="New Participant"
              className="dropdown-btn-img"
              onError={(e) => {
                e.target.src = getAvatarWithInitials(
                  36,
                  participant.id === patientHome.mkinetikos_id ? patientHome.name : participant.name
                );
              }}
            />
            <div className="regular-text black-color">
              {participant.id === patientHome.mkinetikos_id ? patientHome.name : participant.name}
            </div>
          </button>
        ),
      };
    });
  };

  const renderChatParticipantsAvatars = () => {
    if (!isInstitutionChat) {
      const reverseParticipants = [...openChat?.participants].reverse();
      return (
        <div
          ref={showParticipantsRef}
          className="participants-avatars-container"
          onClick={() => {
            setShowParticipantsListDropdown(!showParticipantsListDropdown);
          }}
        >
          {reverseParticipants.map((participant, index) => (
            <img
              className="participant-avatar-img"
              key={index}
              alt="Chat participant avatar"
              src={participant.photo_url}
              onError={(e) => {
                e.target.src = getAvatarWithInitials(
                  36,
                  participant.id === patientHome.mkinetikos_id ? patientHome.name : participant.name
                );
              }}
            />
          ))}
          {showParticipantsListDropdown && (
            <DropdownOptions
              ref={dropdownOptionsRef}
              buttons={getParticipantsListDropdownButtons()}
            />
          )}
        </div>
      );
    }
  };

  const renderSections = () => {
    if (chatMessages.length > 0) {
      const todayString = moment().format("YYYY-MM-DD");
      const yesterdayString = moment().subtract(1, "days").format("YYYY-MM-DD");

      return chatMessages.map((section, index) => {
        return (
          <Fragment key={section.date}>
            {renderSectionMessages(section.messages)}
            <div className="chat-section-header-wrapper">
              <div className="section-line" />
              <div className="regular-text black-color section-header-container">
                {section.date === todayString
                  ? t("today")
                  : section.date === yesterdayString
                  ? t("yesterday")
                  : section.date}
              </div>
              <div className="section-line" />
            </div>
          </Fragment>
        );
      });
    } else {
      return (
        <div className="regular-text">
          {/* TODO: Change chat message for private chat */}
          {isInstitutionChat
            ? t("patient.my_care_team.new_institution_chat_msg")
            : t("patient.my_care_team.new_private_chat_msg")}
        </div>
      );
    }
  };

  const renderSectionMessages = (sectionMessages) => {
    return sectionMessages.map((message) => {
      const participant = openChat?.participants.find(
        (participant) => participant.id === message.sent_by_id
      );
      if (participant) {
        if (patientsParticipantsId.includes(message.sent_by_id)) {
          return (
            <div key={message.id} className="chat-msg-wrapper msg-from-patient">
              <img
                alt="Avatar"
                className="msg-avatar-img msg-from-patient"
                src={participant.photo_url}
                onError={(e) => {
                  e.target.src = getAvatarWithInitials(
                    36,
                    participant.id === patientHome.mkinetikos_id
                      ? patientHome.name
                      : participant.name
                  );
                }}
              />
              <div className="msg-container d-flex align-items-center regular-text black-color msg-from-patient">
                {message.text}
              </div>
              <div className="msg-bottom-info msg-from-patient">
                <div className="msg-date">
                  {formatDate(new Date(message.creation_date), "HH:mm")}
                </div>
                <div className="msg-seen-container">
                  {openChat?.participants
                    .map((item) =>
                      item.last_seen_message_id === message.id && item.id !== message.sent_by_id ? (
                        <Fragment key={item.id}>
                          <img
                            alt="Seen by"
                            src={item.photo_url}
                            className="msg-seen-img"
                            onError={(e) => {
                              e.target.src = getAvatarWithInitials(
                                36,
                                item.id === patientHome.mkinetikos_id ? patientHome.name : item.name
                              );
                            }}
                          />
                          <div className="regular-text msg-seen-info">
                            {t("patient.my_care_team.seen")}
                          </div>
                        </Fragment>
                      ) : null
                    )
                    .filter((item) => item !== null)}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={message.id} className="chat-msg-wrapper">
              <div className="msg-container regular-text d-flex align-items-center">
                <div>{message.text}</div>
              </div>
              <img
                alt="Avatar"
                className="msg-avatar-img"
                src={participant.photo_url}
                onError={(e) => {
                  e.target.src = getAvatarWithInitials(
                    36,
                    participant.id === patientHome.mkinetikos_id
                      ? patientHome.name
                      : participant.name
                  );
                }}
              />
              <div className="msg-bottom-info">
                <div className="msg-date">
                  {formatDate(new Date(message.creation_date), "HH:mm")}
                </div>
                <div className="msg-seen-container">
                  {openChat?.participants
                    .map((item) =>
                      item.last_seen_message_id === message.id && item.id !== message.sent_by_id ? (
                        <Fragment key={item.id}>
                          <img
                            alt="Seen by"
                            src={item.photo_url}
                            className="msg-seen-img"
                            onError={(e) => {
                              e.target.src = getAvatarWithInitials(
                                36,
                                item.id === patientHome.mkinetikos_id ? patientHome.name : item.name
                              );
                            }}
                          />
                          <div className="regular-text msg-seen-info">
                            {t("patient.my_care_team.seen")}
                          </div>
                        </Fragment>
                      ) : null
                    )
                    .filter((item) => item !== null)}
                  {/*
                    TO DO: Add sent to the last message of the current platform user
                    check if message is from platform userinfo id and if it is the first (more recent)
                    show the sent button
                     <img alt="Sent" src={sentIcon} /> */}
                </div>
              </div>
            </div>
          );
        }
      } else {
        return null;
      }
    });
  };

  /* ---------------------- RENDER ---------------- */

  if (openChatId !== null) {
    return isLoadingMessages ? (
      <div className="d-flex justify-content-center align-items-center position-relative h-50">
        <Spinner type={"big-blue"} />
      </div>
    ) : (
      <div className="chat-container">
        <div className="chat-header">
          <div className="flex-fill">
            <div className="small-title chat-title d-flex align-items-center">
              <div>{t("patient.my_care_team.chatting_in")}&nbsp;·&nbsp;</div>
              <div className="chat-title-name">
                {isInstitutionChat
                  ? openChat.name?.toUpperCase()
                  : openChat?.participants.length > 2
                  ? t("patient.my_care_team.group_chat").toUpperCase()
                  : t("patient.my_care_team.private_chat").toUpperCase()}
              </div>
              {renderChatParticipantsAvatars()}
              {!isInstitutionChat && (
                <>
                  <img
                    alt="Add Participant"
                    className="chat-title-img mr-1"
                    src={isAddParticipantOpen ? plusSelectedIcon : plusIcon}
                    onClick={() => {
                      if (!isAddParticipantOpen) {
                        setIsAddParticipantOpen(true);
                        setTimeout(() => {
                          newParticipantInputRef.current.focus();
                        });
                      }
                    }}
                    disabled={isAddParticipantOpen}
                  />
                  {isAddParticipantOpen && (
                    <>
                      <div className="add-participants-container">
                        <span
                          ref={newParticipantInputRef}
                          className="regular-text black-color add-participant-input"
                          contentEditable
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onInput={onInput}
                        />
                        {focused && newChatParticipantSearch !== "" && (
                          <DropdownOptions buttons={getDropdownButtons()} />
                        )}
                      </div>

                      <img
                        src={closeIcon}
                        alt="Close Add Participant"
                        className="add-participant-close"
                        onClick={() => {
                          setIsAddParticipantOpen(false);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="small-title mt-1">{patientHome.name.toUpperCase()}</div>
          </div>
          {/* FOR MVP2 (CHANGE VAR NAMES) <div className="more-image-wrapper">
        <img
          className="image-cursor"
          onMouseOver={(e) =>
            selectedEvaluationId !== evaluation.id &&
            (e.currentTarget.src = moreBlueSelected)
          }
          onMouseOut={(e) =>
            selectedEvaluationId !== evaluation.id &&
            (e.currentTarget.src = moreBlue)
          }
          onClick={
            () => 
            setSelectedEvaluationId(evaluation.id)
          }
          src={
            selectedEvaluationId === evaluation.id
            ? moreBlueSelected
            :  moreBlue
          }
          alt="More Blue"
        />
        {selectedEvaluationId === evaluation.id && (
                  <DropdownOptions
                    closeModal={() => setSelectedEvaluationId(null)}
                    buttons={[
                      {
                        text: (
                          <span className="regular-text">Report</span>
                        ),
                        action: () => {
                          history.push(
                            `/patient/${patient_id}/reports`
                          );
                        },
                      },
                      {
                        text: (
                          <span className="regular-text">Edit</span>
                        ),
                        action: () => {
                          history.push(
                            `/patient/${patient_id}/evaluations/${selectedEvaluationId}`
                          );
                        },
                      },
                      {
                        text: (
                          <span className="regular-text warning">
                            Delete
                          </span>
                        ),
                        action: () => {
                          setDeletingEvaluationId(
                            selectedEvaluationId
                          );
                          setSelectedEvaluationId(null);
                        },
                      },
                    ]}
                  />
                )}
      </div> */}
          {/* Reply to ${patientHome.name} here */}
        </div>
        <div className="chat-messages-container">{renderSections()}</div>
        <div className="chat-input-wrapper">
          <div className="position-relative">
            <input
              ref={messageInputRef}
              autoComplete="off"
              onChange={messageInputHandler}
              value={messageInput}
              className="form-input message-input"
              name="message-input"
              id="message-input"
              placeholder={
                t("patient.my_care_team.reply_to") +
                patientHome.name +
                " " +
                t("patient.my_care_team.here")
              }
              onKeyDown={(e) => {
                if ((e.code === "Enter" || e.code === "NumpadEnter") && !canOnlyManageOwnPatients) {
                  sendMessage();
                }
              }}
              disabled={isLoading ? true : false}
            />
            <img
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={sendMessage}
              alt="Send Message"
              className={`message-input-img ${messageInput === "" ? "disabled" : ""}`}
              style={isLoading ? { display: "none" } : { display: "block" }}
              src={sendIcon}
              disabled={canOnlyManageOwnPatients}
            />
            <div
              className="message-input-loading"
              style={!isLoading ? { display: "none" } : { display: "block" }}
            >
              <Spinner type={"small-blue"} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default React.memo(ChatModule);
