import { useEffect, useRef, useState } from "react";
import { create_activities_button } from "../../../utils/report";
import { create_card } from "../../../utils/create_card";
import { saveCommentToReport } from "../../../redux/actions/patients";
import { getPatientEvaluatioCardDetails } from "../../../redux/actions/patients";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../../utils/language";

const TextCard = ({ id, showInfo, details }) => {
  const reff = useRef(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const { t, i18n } = useTranslation();
  // const data = {
  //   text: "",
  //   evaluation_id: "730c7ef8dba39386408883a38fcc26f7c784cede",
  // };
  // const details = {
  //   id: 9,
  //   type: 5,
  //   title: "Clinical notes ",
  //   description: "Free wrinting field",
  //   icon: '<i class="fa fa-file-text-o" aria-hidden="true"></i>',
  //   color: "var(--kgreen-color)",
  //   span_row: 3,
  //   span_col: 5,
  // };
  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        details.id,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          setData(res.payload.data.data.data);
          setIsLoading(false);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    function build_editable_text_area(card, data, color) {
      let card_body = card.querySelector(".card-body"),
        textarea = document.createElement("textarea"),
        actions_div = document.createElement("div");
      textarea.value = data.text;
      textarea.classList = "fieldInput areaInput";
      textarea.placeholder = "Add your commentary";
      if (color !== undefined) {
        textarea.style.setProperty("--focus-color", color);
      }

      textarea.addEventListener("input", () => {
        save_button.classList.remove("disabled");
      });

      // let save_button = create_activities_button(card_body, "save");
      const save_button = document.createElement("div");
      save_button.className = "small-button secondary-button";
      save_button.innerText = "Save";
      save_button.addEventListener("click", save_clinical_comments, false);
      // save_button.addEventListener('touch', save_clinical_comments, false);

      actions_div.classList = "actions";
      actions_div.appendChild(save_button);

      card_body.appendChild(textarea);

      document
        .querySelector(`[data-card_id="${details.id}"]`)
        .querySelector(".card-header")
        .appendChild(actions_div);

      function save_clinical_comments() {
        save_button.removeEventListener("click", save_clinical_comments);
        save_button.classList.toggle("disabled");
        dispatch(
          saveCommentToReport(
            patient_id,
            evaluation_id,
            details.id,
            textarea.value
          )
        )
          .then((res) => {
            save_button.addEventListener(
              "click",
              save_clinical_comments,
              false
            );
          })
          .catch(() => {
            save_button.addEventListener(
              "click",
              save_clinical_comments,
              false
            );
          });
        // $.ajax({
        //   type: "POST",
        //   url: url,
        //   data: {
        //     comment_text: textarea.value,
        //     evaluation_id: data.evaluation_id,
        //     card_id: card.getAttribute("data-card_id"),
        //     csrfmiddlewaretoken: csrf,
        //   },
        //   success: (data) => {
        //     save_button.addEventListener(
        //       "click",
        //       save_clinical_comments,
        //       false
        //     );
        //   },
        //   error: () => {
        //     save_button.addEventListener(
        //       "click",
        //       save_clinical_comments,
        //       false
        //     );
        //   },
        // });
      }
    }

    function build_non_editable_text_area(card, data) {
      let card_body = card.querySelector(".card-body"),
        textarea = document.createElement("span");
      textarea.classList = "fieldInput areaInput";
      textarea.innerHTML = data;
      card_body.appendChild(textarea);
    }

    function build_matrix_metrics(card, data) {
      let card_body = card.querySelector(".card-body"),
        wrapper = document.createElement("div"),
        actions_div = document.createElement("div");

      wrapper.classList = "metrics noanimation";

      data["kinematic_metrics"].forEach(function (metric_type) {
        let title = document.createElement("p"),
          metrics_wrapper = document.createElement("div");
        title.innerHTML = metric_type.title;
        title.classList = "title rajahGrad";
        metrics_wrapper.classList = "metrics-wrapper boxGrid";

        metric_type["metrics"].forEach(function (metric) {
          let name = document.createElement("span"),
            value = document.createElement("span");
          let metric_wrapper = document.createElement("div");

          metric_wrapper.classList = "flex-d-column metric-wrapper card box";
          name.classList = "box-title";
          name.innerHTML = metric.name;
          value.classList = "box-value";
          let std = metric.std != null ? " ± " + metric.std : "";
          value.innerHTML = metric.value
            ? metric.value + std + " " + metric.unit
            : "NO DATA";
          metric_wrapper.append(name);
          metric_wrapper.append(value);
          metrics_wrapper.append(metric_wrapper);
        });
        wrapper.append(title);
        wrapper.append(metrics_wrapper);
      });
      card_body.append(wrapper);

      //     copy_button = create_activities_button(actions_div, 'download', false, false, false);
      //     copy_button.addEventListener('click', () => copyToClipboard(data))
      //     copy_button.addEventListener('touch', () => copyToClipboard(data))
      //     card_body.append(actions_div);
    }

    function copyToClipboard(data) {
      window.location.href = data.download.url;
    }

    function build_card_info_card(card, data) {
      let card_body = card.querySelector(".card-body"),
        main_value = document.createElement("span"),
        division = document.createElement("span"),
        description = document.createElement("span"),
        actions = document.createElement("span"),
        card_db_id = card.dataset.card_id;
      if (data.value === null) {
        main_value.innerHTML = "-";
      } else {
        main_value.innerHTML =
          card_db_id === "23" ? data.value + "<span>%</span>" : data.value;
      }

      description.innerText = data.description;
      main_value.classList = "main-value";
      division.classList = "division";
      description.classList = "description";

      let plot_button = create_activities_button(
        actions,
        "chart",
        false,
        false,
        false
      );
      plot_button.addEventListener(
        "click",
        () => {
          showInfo(1);
        },
        false
      );
      plot_button.addEventListener(
        "touch",
        () => {
          showInfo(1);
        },
        false
      );

      card_body.appendChild(main_value);
      card_body.appendChild(division);
      card_body.appendChild(description);
      card_body.appendChild(plot_button);
    }

    function build_display_text_card(card, data, color) {
      let card_body = card.querySelector(".card-body"),
        textarea = document.createElement("span");
      textarea.innerHTML = data.text;
      if (color !== undefined) {
        card_body.style.setProperty("--color", color);
      }
      card_body.appendChild(textarea);
      if (data.update_card == true) {
        var refreshIntervalId = setInterval(function () {
          clearInterval(refreshIntervalId);
          textarea.innerHTML = "";
          //refesh_card(card.getAttribute("data-card_id"));
        }, 10000);
      }
    }

    function build_metric(card, data, color) {
      let card_body = card.querySelector(".card-body"),
        wrapper = document.createElement("div"),
        textarea = document.createElement("span");
      wrapper.classList = "contentWrapper";
      textarea.classList = "bigText";
      textarea.innerHTML = data.value;
      card_body.style.setProperty("--color", color);
      textarea.style.setProperty("font-size", "3rem");

      wrapper.appendChild(textarea);

      if (data.url != null) {
        let actions_div = document.createElement("div"),
          button = document.createElement("div");

        actions_div.classList = "plot-actions";
        button.classList = "action";

        if (data.details_card_id === null) {
          button.innerHTML = `<i class="icon edit" aria-hidden="true"></i>`;
          button.addEventListener(
            "click",
            () => {
              document.location.href = data.url;
            },
            false
          );
          button.addEventListener(
            "touch",
            () => {
              document.location.href = data.url;
            },
            false
          );
          // i.style.display = "inherit";
        } else {
          button.classList = " icon alert-circle";
          button.setAttribute("aria-hidden", "true");
          button.addEventListener(
            "click",
            () => {
              showInfo(data.details_card_id);
            },
            false
          );
          button.addEventListener(
            "touch",
            () => {
              showInfo(data.details_card_id);
            },
            false
          );
          // i.style.display = "inherit";
        }
        // actions_div.appendChild(button);
        // wrapper.appendChild(actions_div);
      }
      card_body.appendChild(wrapper);
    }
    if (data === null)
      create_card(
        reff.current,
        90,
        details.id,
        details.type,
        details.color,
        details.title,
        details.description,
        details.span_row,
        details.span_col,
        false,
        "url"
      );
    if (data) {
      if (id === 3) build_metric(reff.current, data, details.color);
      else if (id === 5) build_editable_text_area(reff.current, data);
      else if (id === 6) build_non_editable_text_area(reff.current, data);
      else if (id === 11)
        build_matrix_metrics(reff.current, data, details.color);
      else if (id === 12) build_card_info_card(reff.current, data);
      else if (id === 19)
        build_editable_text_area(reff.current, data, details.color);
    }
  }, [data]);

  return <div ref={reff}>{isLoading && <Spinner type={"biggest-blue"} />}</div>;
};

export default TextCard;
