//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************
import DropdownOptions from "components/dropdownOptions";
import OptionsButton from "components/optionsButton";
import MessageModal from "components/messageModal";
import Spinner from "components/spinner";
import NavBar from "components/navbar";

//* *************************************** HOOKS ***************************************
import useOnClickOutside from "hooks/useOnClickOutside";

//* *************************************** REDUX ***************************************
import {
  getInstitutions,
  removeMyselfInstitution,
  setDefaultInstitution,
  removeDefaultInstitution,
  setCurrentInstitution,
} from "redux/actions/institutions";

//* ************************************** GLOBALS ***************************************
import institutionSelectedIcon from "assets/images/buttonIconsInverted/institution.svg";
import { errors } from "constants/general";
import institution_default from "assets/images/institution-default.svg";

const Institutions = (props) => {
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const [moreOptionsInstitution, setMoreOptionsInstitution] = useState({});
  const [selectedMoreOptionsId, setSelectedMoreOptionsId] = useState(null);
  const [isSetDefaultLoading, setIsSetDefaultLoading] = useState(false);

  const [toRemoveInstitutionId, setToRemoveInstitutionId] = useState(null);
  const user_institutions = useSelector(
    (state) => state.institutions.user_institutions
  );

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setSelectedMoreOptionsId(null)
  );

  useEffect(() => {
    dispatch(getInstitutions())
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }, []);

  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS **************************************
  const removeInstitution = (institution_id) => {
    dispatch(removeMyselfInstitution(institution_id))
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        alert(errors.service_error);
      })
      .finally(() => setToRemoveInstitutionId(null));
  };

  const canUserEdit = (institution) =>
    institution.role_id === 1 && institution.current;

  const setInstitutionToDefault = (institution_id) => {
    setIsSetDefaultLoading(true);
    dispatch(setDefaultInstitution(institution_id))
      .then((res) => {
        console.log(res);
      })
      .catch(() => alert(errors.service_error))
      .finally(() => {
        setIsSetDefaultLoading(false);
        setMoreOptionsInstitution({});
      });
  };

  const removeInstitutionToDefault = (institution_id) => {
    setIsSetDefaultLoading(true);
    dispatch(removeDefaultInstitution(institution_id))
      .then((res) => {
        console.log(res);
      })
      .catch(() => alert(errors.service_error))
      .finally(() => {
        setIsSetDefaultLoading(false);
        setMoreOptionsInstitution({});
      });
  };

  const setInstitutionToCurrent = (institution_id) => {
    dispatch(setCurrentInstitution(institution_id))
      .then((res) => {
        console.log(res);
      })
      .catch(() => alert(errors.service_error))
      .finally(() => setSelectedMoreOptionsId(null));
  };

  //* ************************************** PARTIALS **************************************
  const _renderInstitution = (institution, index) => {
    return (
      <li key={institution.id + index} className="institution-wrapper">
        <div className="photo-name-wrapper">
          <img
            src={institution.photo ? institution.photo : institution_default}
            className="institution-photo"
            alt="Institution"
          />
          <span className="institution-name">{institution.name}</span>
        </div>

        <div className="options-default-wrapper">
          {institution.default && (
            <i className="default-text">
              {t("my_area.institutions.selected_default")}
            </i>
          )}
          <div className="more-image-wrapper">
            <OptionsButton
              ref={(el) => (optionsRef.current[index] = el)}
              isSelected={moreOptionsInstitution.id === institution.id}
              onClick={(e) => {
                e.stopPropagation();
                selectedMoreOptionsId === institution.id
                  ? setSelectedMoreOptionsId(null)
                  : setSelectedMoreOptionsId(institution.id);
              }}
              type={2}
            />
            {selectedMoreOptionsId === institution.id && (
              <DropdownOptions
                ref={dropDownRef}
                buttons={[
                  ...(institution.role_id === 1
                    ? [
                        {
                          html: (
                            <button
                              onClick={() =>
                                canUserEdit(institution) &&
                                props.history.push(
                                  `/my_area/institutions/${institution.id}/edit`
                                )
                              }
                              data-tip
                              data-for="edit-text"
                              data-tip-disable={false}
                              key={institution.id}
                            >
                              <span
                                style={{
                                  opacity: institution.current ? "100%" : "50%",
                                }}
                                className="regular-text"
                              >
                                {t("buttons.edit")}
                              </span>
                              {!institution.current && (
                                <ReactTooltip
                                  className="tooltip"
                                  id="edit-text"
                                  place="top"
                                  effect="solid"
                                >
                                  {t("my_area.institutions.switch_edit")}
                                </ReactTooltip>
                              )}
                            </button>
                          ),
                        },
                      ]
                    : []),
                  {
                    text: (
                      <span className="regular-text">
                        {t("buttons.view_details")}
                      </span>
                    ),
                    action: () =>
                      props.history.push({
                        pathname: `/my_area/institutions/${institution.id}`,
                        cameFromInstitutions: true,
                      }),
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {t("buttons.change_to_institution")}
                      </span>
                    ),
                    action: () => setInstitutionToCurrent(institution.id),
                  },
                  {
                    text: (
                      <span className="regular-text">
                        {institution.default
                          ? t("buttons.remove_default")
                          : t("buttons.set_default")}
                      </span>
                    ),
                    action: () => {
                      setSelectedMoreOptionsId(null);
                      setMoreOptionsInstitution(institution);
                    },
                  },
                  {
                    text: (
                      <span className="regular-text warning">
                        {t("buttons.remove_self_from_institution")}
                      </span>
                    ),
                    action: () => setToRemoveInstitutionId(institution.id),
                  },
                ]}
              />
            )}
          </div>
        </div>
      </li>
    );
  };
  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.institutions")} />
      <div className="profile-institutions">
        <div className="row-space-between">
          <span className="small-title">
            {t("my_area.institutions.associated_institutions")}
          </span>
          <button
            onClick={() =>
              props.history.push({
                pathname: "/create_institution",
                cameFromMyArea: true,
              })
            }
            className="button primary-button small-button"
          >
            <img
              alt="plus-icon"
              className="button-icon"
              src={institutionSelectedIcon}
            />
            {t("buttons.create_institution")}
          </button>
        </div>
        <div className="body">
          <ul>
            {user_institutions.map((institution, index) =>
              _renderInstitution(institution, index)
            )}
          </ul>
        </div>
      </div>
      {Object.keys(moreOptionsInstitution).length !== 0 && (
        <MessageModal
          headerText={
            moreOptionsInstitution.default
              ? t("modals.remove_institution_default_header")
              : t("modals.set_institution_default_header")
          }
          body={
            moreOptionsInstitution.default
              ? `${t("modals.remove_institution_default_body_1")} ${
                  moreOptionsInstitution.name
                } ${t("modals.remove_institution_default_body_2")}`
              : `${t("modals.set_institution_default_body_1")} ${
                  moreOptionsInstitution.name
                } ${t("modals.set_institution_default_body_2")}`
          }
          footerButtons={[
            <button
              onClick={() => setMoreOptionsInstitution({})}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                readOnly
                type="button"
                value={
                  moreOptionsInstitution.default
                    ? t("buttons.remove")
                    : t("buttons.set")
                }
                onClick={() =>
                  moreOptionsInstitution.default
                    ? removeInstitutionToDefault(moreOptionsInstitution.id)
                    : setInstitutionToDefault(moreOptionsInstitution.id)
                }
                className={`${
                  moreOptionsInstitution.default
                    ? "warning-button"
                    : "secondary-button"
                } small-wider-button`}
              />
              {isSetDefaultLoading && <Spinner type={"small"} />}
            </div>,
          ]}
        />
      )}
      {toRemoveInstitutionId !== null && (
        <MessageModal
          headerText={t("modals.remove_self_from_institution_header")}
          body={t("modals.remove_self_from_institution_body")}
          footerButtons={[
            <button
              onClick={() => setToRemoveInstitutionId(null)}
              className="small-button transparent-button"
            >
              <u>{t("buttons.cancel")}</u>
            </button>,
            <div className="input-image-wrapper m-0">
              <input
                readOnly
                type="button"
                value={t("buttons.remove")}
                onClick={() => removeInstitution(toRemoveInstitutionId)}
                className="warning-button small-wider-button"
              />
            </div>,
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(Institutions);
