export const GET_PROTOCOLS_LIST = "GET_PROTOCOLS_LIST";
export const GET_PROTOCOLS_LIST_SUCCESS = "GET_PROTOCOLS_LIST_SUCCESS";
export const GET_PROTOCOLS_LIST_FAIL = "GET_PROTOCOLS_LIST_FAIL";

export const GET_FREQUENCIES_LIST = "GET_FREQUENCIES_LIST";
export const GET_FREQUENCIES_LIST_SUCCESS = "GET_FREQUENCIES_LIST_SUCCESS";
export const GET_FREQUENCIES_LIST_FAIL = "GET_FREQUENCIES_LIST_FAIL";

export const GET_PATIENT_PROGRAMS = "GET_PATIENT_PROGRAMS";
export const GET_PATIENT_PROGRAMS_SUCCESS = "GET_PATIENT_PROGRAMS_SUCCESS";
export const GET_PATIENT_PROGRAMS_FAIL = "GET_PATIENT_PROGRAMS_FAIL";

export const GET_PATIENT_PROGRAM_DETAILS = "GET_PATIENT_PROGRAM_DETAILS";
export const GET_PATIENT_PROGRAM_DETAILS_SUCCESS =
  "GET_PATIENT_PROGRAM_DETAILS_SUCCESS";
export const GET_PATIENT_PROGRAM_DETAILS_FAIL =
  "GET_PATIENT_PROGRAM_DETAILS_FAIL";

export const CREATE_PATIENT_PROGRAM = "CREATE_PATIENT_PROGRAM";
export const CREATE_PATIENT_PROGRAM_SUCCESS = "CREATE_PATIENT_PROGRAM_SUCCESS";
export const CREATE_PATIENT_PROGRAM_FAIL = "CREATE_PATIENT_PROGRAM_FAIL";

export const ADD_PROGRAM_PROTOCOL = "ADD_PROGRAM_PROTOCOL";
export const ADD_PROGRAM_PROTOCOL_SUCCESS = "ADD_PROGRAM_PROTOCOL_SUCCESS";
export const ADD_PROGRAM_PROTOCOL_FAIL = "ADD_PROGRAM_PROTOCOL_FAIL";

export const DELETE_PATIENT_PROGRAM = "DELETE_PATIENT_PROGRAM";
export const DELETE_PROGRAM_PROTOCOL = "DELETE_PROGRAM_PROTOCOL";

export const GET_PATIENT_TESTS = "GET_PATIENT_TESTS";
export const GET_PATIENT_TESTS_SUCCESS = "GET_PATIENT_TESTS_SUCCESS";
export const GET_PATIENT_TESTS_FAIL = "GET_PATIENT_TESTS_FAIL";

export const GET_PATIENT_TEST_DETAILS = "GET_PATIENT_TEST_DETAILS";
export const GET_PATIENT_TEST_DETAILS_SUCCESS =
  "GET_PATIENT_TEST_DETAILS_SUCCESS";
export const GET_PATIENT_TEST_DETAILS_FAIL = "GET_PATIENT_TEST_DETAILS_FAIL";

export const GET_PATIENT_SURVEYS = "GET_PATIENT_SURVEYS";
export const GET_PATIENT_SURVEYS_SUCCESS = "GET_PATIENT_SURVEYS_SUCCESS";
export const GET_PATIENT_SURVEYS_FAIL = "GET_PATIENT_SURVEYS_FAIL";

export const GET_PATIENT_SURVEY_DETAILS = "GET_PATIENT_SURVEY_DETAILS";
export const GET_PATIENT_SURVEY_DETAILS_SUCCESS =
  "GET_PATIENT_SURVEY_DETAILS_SUCCESS";
export const GET_PATIENT_SURVEY_DETAILS_FAIL =
  "GET_PATIENT_SURVEY_DETAILS_FAIL";

export const getProtocolsList = (patient_id, language_id) => ({
  type: GET_PROTOCOLS_LIST,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/protocols/`,
      params: { language_id },
    },
  },
});

export const getFrequencyList = (patient_id, language_id) => ({
  type: GET_FREQUENCIES_LIST,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/frequency/`,
      params: { language_id },
    },
  },
});

export const getPatientPrograms = (
  patient_id,
  {
    page = 1,
    q = undefined,
    order_by = "date",
    items_per_page = 10,
    lang_id,
  } = {}
) => ({
  type: GET_PATIENT_PROGRAMS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/`,
      params: { page, q, order_by, items_per_page, lang_id },
    },
  },
});

export const getPatientProgramDetails = (patient_id, program_id) => ({
  type: GET_PATIENT_PROGRAM_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/${program_id}/`,
    },
  },
});

export const createPatientProgram = (
  patient_id,
  { name = "", protocols = [] } = {}
) => ({
  type: CREATE_PATIENT_PROGRAM,
  payload: {
    request: {
      method: "post",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/`,
      data: { name, protocols },
    },
  },
});

export const addProgramProtocol = (
  patient_id,
  program_id,
  { protocols = [] } = {}
) => ({
  type: ADD_PROGRAM_PROTOCOL,
  payload: {
    request: {
      method: "post",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/${program_id}/protocols/`,
      data: { protocols },
    },
  },
});

export const deletePatientProgram = (patient_id, program_id) => ({
  type: DELETE_PATIENT_PROGRAM,
  payload: {
    request: {
      method: "delete",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/${program_id}/`,
    },
  },
});

export const deleteProgramProtocol = (patient_id, program_id, protocol_id) => ({
  type: DELETE_PROGRAM_PROTOCOL,
  payload: {
    request: {
      method: "delete",
      url: `cdss/patients/${patient_id}/mkinetikos/programs/${program_id}/protocols/${protocol_id}/`,
    },
  },
});

export const getPatientTests = (
  patient_id,
  activity_id,
  {
    page = 1,
    q = undefined,
    order_by = "date",
    filter_type = "all",
    items_per_page = 10,
  } = {}
) => ({
  type: GET_PATIENT_TESTS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/tests/`,
      params: { page, q, order_by, filter_type, items_per_page, activity_id },
    },
  },
});

export const getPatientTestDetails = (patient_id, test_id) => ({
  type: GET_PATIENT_TEST_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/tests/${test_id}/`,
    },
  },
});

export const getPatientSurveys = (
  patient_id,
  activity_id,
  {
    page = 1,
    q = undefined,
    order_by = "date",
    filter_type = "all",
    items_per_page = 10,
  } = {}
) => ({
  type: GET_PATIENT_SURVEYS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/surveys/`,
      params: {
        page,
        q,
        order_by,
        filter_type,
        items_per_page,
        activity_id,
      },
    },
  },
});

export const getPatientSurveyDetails = (patient_id, survey_id) => ({
  type: GET_PATIENT_SURVEY_DETAILS,
  payload: {
    request: {
      method: "get",
      url: `cdss/patients/${patient_id}/mkinetikos/surveys/${survey_id}/`,
    },
  },
});
