import arrowDownIcon from "assets/images/arrow-down.svg";
import arrowUpIcon from "assets/images/arrow-up.svg";
import backButtonIcon from "assets/images/back-button.svg";
import chevronDownIcon from "assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import chevronUpIcon from "assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import closeIcon from "assets/images/close_primary_back_white.svg";
import crossIcon from "assets/images/home/close.svg";
import greenDotIcon from "assets/images/prescriptionsSummary/green-dot.svg";
import noRecordIcon from "assets/images/prescriptionsSummary/no_record.svg";
import notTakenIcon from "assets/images/prescriptionsSummary/not_taken.svg";
import redDotIcon from "assets/images/prescriptionsSummary/red-dot.svg";
import sendIcon from "assets/images/send-icon.svg";
import supportTicketsIcon from "assets/images/buttonIcons/support-tickets.svg";
import supportTicketsInvertedIcon from "assets/images/buttonIconsInverted/support-tickets.svg";
import takenIcon from "assets/images/prescriptionsSummary/taken.svg";
import companyLogo from "assets/images/logo-primary.svg";
import serialNumberIcon from "assets/images/sn.svg";
import errorIcon from "assets/images/toast/error.svg";
import closeCircleIcon from "assets/images/home/close-circle.svg";
import printIcon from "assets/images/print-icon.svg";
import downloadIcon from "assets/images/download-icon.svg";
import downloadIconWhite from "assets/images/download-icon-white.svg";
import helpGrayIcon from "assets/images/help-gray.svg";
import resetIcon from "assets/images/reset-icon.svg";
import lockIcon from "assets/images/lock-input.svg";
import helpIcon from "assets/images/help.svg";

export const icons = {
  "arrow-down": arrowDownIcon,
  "arrow-up": arrowUpIcon,
  "back-icon": backButtonIcon,
  "chevron-down": chevronDownIcon,
  "chevron-up": chevronUpIcon,
  "close-icon": closeIcon,
  "green-dot": greenDotIcon,
  "no-record": noRecordIcon,
  "not-taken-icon": notTakenIcon,
  "red-dot": redDotIcon,
  "send-icon": sendIcon,
  "support-tickets": supportTicketsIcon,
  "support-tickets-inverted": supportTicketsInvertedIcon,
  "taken-icon": takenIcon,
  "cross-icon": crossIcon,
  "company-logo": companyLogo,
  "serial-number-icon": serialNumberIcon,
  "error-icon": errorIcon,
  "close-circle": closeCircleIcon,
  "print-icon": printIcon,
  "download-icon": downloadIcon,
  "help-icon-gray": helpGrayIcon,
  "reset-icon": resetIcon,
  "lock-icon": lockIcon,
  "download-icon-white": downloadIconWhite,
  "help-icon-dark": helpIcon,
};
