import {
  GET_INSTITUTIONS_FAIL,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAIL,
  GET_INSTITUTION_COLLABORATORS_SUCCESS,
  GET_INSTITUTION_COLLABORATORS_FAIL,
  SET_CURRENT_INSTITUTION_SUCCESS,
  SET_DEFAULT_INSTITUTION_SUCCESS,
  GET_INSTITUTION_INFO_SUCCESS,
  UPDATE_INSTITUTION_INFO_SUCCESS,
  GET_INSTITUTION_INFO_FAIL,
  GET_INSTITUTION_PREFERENCES_SUCCESS,
  UPDATE_INSTITUTION_PREFERENCES_SUCCESS,
  UPDATE_INSTITUTION_PREFERENCES_FAIL,
  GET_CURRENT_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_COLLABORATOR_SUCCESS,
  REMOVE_MYSELF_INSTITUTION_SUCCESS,
  REMOVE_DEFAULT_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_COLLABORATOR_SUCCESS,
  ACCEPT_BY_COLLABORATOR_SUCCESS,
  ACCEPT_BY_COLLABORATOR_FAIL,
} from "../actions/institutions";

import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAIL } from "../actions/user";

export const initialState = {
  editing_institution: {
    institution_info: {
      address: "",
      country: "",
      county: "",
      email: "",
      name: "",
      photo: "",
      telephone: "",
      zip_code: "",
    },
    institution_preferences: {
      users_can_see: "",
      users_can_edit: "",
    },
  },
  viewing_institution: {
    id: null,
    name: "",
    photo: null,
    package_plan_id: null,
    package_plan_name: "",
    email: "",
    telephone: "",
    location: {
      address: "",
      zip_code: "",
      county: "",
      country: "",
    },
    current: true,
    default: false,
    role_id: null,
    role_name: "",
  },
  current_institution: {
    id: null,
    name: "",
    photo: "",
    role_id: null,
    role_name: "",
    current: false,
    default: false,
  },
  user_institutions: [],
  collaborators: {
    obj_list: [],
    count: null,
    page: null,
    num_pages: null,
    links: {
      has_next: false,
      has_previous: false,
    },
    invite_institution: {
      name: "",
      id: "",
    },
  },
};

const Institutions = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTIONS_SUCCESS: {
      return { ...state, user_institutions: action.payload.data.data };
    }
    case GET_INSTITUTIONS_FAIL: {
      return { ...state, user_institutions: initialState.user_institutions };
    }
    case GET_INSTITUTION_SUCCESS: {
      return { ...state, viewing_institution: action.payload.data.data };
    }
    case GET_INSTITUTION_FAIL: {
      return {
        ...state,
        viewing_institution: initialState.viewing_institution,
      };
    }
    case GET_INSTITUTION_COLLABORATORS_SUCCESS: {
      return {
        ...state,
        collaborators: action.payload.data.data,
      };
    }
    case GET_INSTITUTION_COLLABORATORS_FAIL: {
      return {
        ...state,
        collaborators: initialState.collaborators,
      };
    }
    case SET_CURRENT_INSTITUTION_SUCCESS: {
      const institution_id = action.meta.previousAction.institution_id;
      return {
        ...state,
        current_institution: action.payload.data.data,
        user_institutions: state.user_institutions.map((institution) =>
          institution.id === institution_id
            ? { ...institution, current: true }
            : { ...institution, current: false }
        ),
      };
    }
    case SET_DEFAULT_INSTITUTION_SUCCESS: {
      const institution_id = action.meta.previousAction.institution_id;
      return {
        ...state,
        user_institutions: state.user_institutions.map((institution) =>
          institution.id === institution_id
            ? { ...institution, default: true }
            : { ...institution, default: false }
        ),
      };
    }
    case REMOVE_DEFAULT_INSTITUTION_SUCCESS: {
      const institution_id = action.meta.previousAction.institution_id;

      return {
        ...state,
        user_institutions: state.user_institutions.map((institution) =>
          institution.id === institution_id
            ? { ...institution, default: false }
            : institution
        ),
      };
    }
    case UPDATE_INSTITUTION_INFO_SUCCESS:
    case GET_INSTITUTION_INFO_SUCCESS: {
      return {
        ...state,
        editing_institution: {
          ...state.editing_institution,
          institution_info: action.payload.data.data,
        },
      };
    }
    case GET_INSTITUTION_INFO_FAIL: {
      return {
        ...state,
        editing_institution: {
          ...state.editing_institution,
          institution_info: initialState.editing_institution.institution_info,
        },
      };
    }
    case GET_INSTITUTION_PREFERENCES_SUCCESS:
    case UPDATE_INSTITUTION_PREFERENCES_SUCCESS: {
      return {
        ...state,
        editing_institution: {
          ...state.editing_institution,
          institution_preferences: action.payload.data.data,
        },
      };
    }
    case UPDATE_INSTITUTION_PREFERENCES_FAIL: {
      return {
        ...state,
        editing_institution: {
          ...state.editing_institution,
          preferences: initialState.editing_institution.institution_info,
        },
      };
    }
    case UPDATE_INSTITUTION_COLLABORATOR_SUCCESS: {
      const collaborator_id = action.meta.previousAction.collaborator_id;
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          obj_list: state.collaborators.obj_list.map((collaborator) =>
            collaborator.id === collaborator_id
              ? { ...collaborator, ...action.payload.data.data }
              : collaborator
          ),
        },
      };
    }
    case REMOVE_MYSELF_INSTITUTION_SUCCESS: {
      const institution_id = action.meta.previousAction.institution_id;

      return {
        ...state,
        current_institution:
          institution_id === state.current_institution.id
            ? {}
            : state.current_institution,
        user_institutions: state.user_institutions.filter(
          (institution) => institution.id !== institution_id && institution
        ),
      };
    }
    case DELETE_INSTITUTION_COLLABORATOR_SUCCESS: {
      const collaborator_id = action.meta.previousAction.collaborator_id;
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          obj_list: state.collaborators.obj_list.filter(
            (collaborator) =>
              collaborator.id !== collaborator_id && collaborator
          ),
        },
      };
    }
    case USER_LOGOUT_FAIL:
    case USER_LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case GET_CURRENT_INSTITUTION_SUCCESS: {
      return {
        ...state,
        current_institution: {
          ...state.current_institution,
          ...action.payload.data.data,
        },
      };
    }

    case ACCEPT_BY_COLLABORATOR_SUCCESS: {
      return { ...state, invite_institution: action.payload.data.data };
    }

    default:
      return state;
  }
};

export default Institutions;
