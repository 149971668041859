//* *********************************** REACT IMPORTS ************************************
import React from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation } from "react-i18next";
import NavBar from "../../../components/navbar";

//* *************************************** STYLES ***************************************

//* *********************************** OUR COMPONENTS ***********************************

//* ************************************** GLOBALS ***************************************

const Privacy = (props) => {
  //* ************************************** SETUP ***************************************
  const { t, i18n } = useTranslation();

  //* ************************************** HANDLERS ************************************

  const openNewWindow = (url) => {
    window.open(url, "_blank");
  };
  //* ************************************** RENDER **************************************
  return (
    <div className="col profile-body">
      <NavBar title={t("titles.privacy")} />
      <div className="row profile-privacy">
        <div className="privacy-box col-xs-12 col-md-8 col-lg-6 col-xl-4">
          <span className="title">{t("titles.terms")}</span>
          <button
            onClick={() =>
              props.history.push("/my_area/privacy/terms_and_conditions")
            }
            className="primary-button small-wider-button"
          >
            {" "}
            {t("buttons.read")}{" "}
          </button>
        </div>
        <div className="privacy-box col-xs-12 col-md-8 col-lg-6 col-xl-4">
          <span className="title">{t("titles.privacy_policy")}</span>
          <button
            onClick={() =>
              props.history.push("/my_area/privacy/privacy_policy")
            }
            className="primary-button small-wider-button"
          >
            {t("buttons.read")}
          </button>
        </div>
        <div className="privacy-box col-xs-12 col-md-8 col-lg-6 col-xl-4">
          <span className="title">{t("titles.about")}</span>
          <button
            onClick={() =>
              openNewWindow("https://www.kinetikoshealth.com/about")
            }
            className="primary-button small-wider-button"
          >
            {" "}
            {t("buttons.read")}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Privacy);
