export const GET_AUTHENTICATOR_URL = "GET_AUTHENTICATOR_URL";

export const SET_SMS_DEVICE = "SET_SMS_DEVICE";
export const CONFIRM_SMS_DEVICE = "CONFIRM_SMS_DEVICE";

export const SEND_SMS_TOKEN = "SEND_SMS_TOKEN";

export const MULTIAUTH_LOGIN = "MULTIAUTH_LOGIN";

export const GET_BACKUP_TOKENS = "GET_BACKUP_TOKENS";

export const BACKUP_LOGIN = "BACKUP_LOGIN";

export const GET_MFA_DEVICES = "GET_MFA_DEVICES";
export const GET_MFA_DEVICES_SUCCESS = "GET_MFA_DEVICES_SUCCESS";
export const GET_MFA_DEVICES_FAIL = "GET_MFA_DEVICES_FAIL";

export const DISABLE_MULTIAUTH = "DISABLE_MULTIAUTH";

export const getAuthenticatorUrl = () => ({
  type: GET_AUTHENTICATOR_URL,
  payload: {
    request: {
      method: "get",
      url: "/cdss/account/totp/create/",
    },
  },
});

export const setSmsDevice = ({ number = "" }) => ({
  type: SET_SMS_DEVICE,
  payload: {
    request: {
      method: "post",
      url: "/cdss/account/totp/create/sms/",
      data: { number },
    },
  },
});

export const confirmSmsDevice = (token = "", { number = "" }) => ({
  type: CONFIRM_SMS_DEVICE,
  payload: {
    request: {
      method: "post",
      url: `/cdss/account/totp/create/sms/${token}/`,
      data: { number },
    },
  },
});

export const sendSmsToken = () => ({
  type: SEND_SMS_TOKEN,
  payload: {
    request: {
      method: "get",
      url: `/cdss/account/totp/login/`,
      params: { method: "sms" },
    },
  },
});

export const multiAuthLogin = (token = "", sms = false) => ({
  type: MULTIAUTH_LOGIN,
  payload: {
    request: {
      method: "post",
      url: `/cdss/account/totp/login/${token}/`,
      params: sms ? { method: "sms" } : {},
    },
  },
});

export const getBackupTokens = () => ({
  type: GET_BACKUP_TOKENS,
  payload: {
    request: {
      method: "get",
      url: `/cdss/account/totp/backup/create/`,
    },
  },
});

export const backupLogin = (token = "") => ({
  type: BACKUP_LOGIN,
  payload: {
    request: {
      method: "post",
      url: `/cdss/account/totp/backup/backup/login/${token}/`,
    },
  },
});

export const getMfaDevices = () => ({
  type: GET_MFA_DEVICES,
  payload: {
    request: {
      method: "get",
      url: "/cdss/account/totp/devices/",
    },
  },
});

export const disableMultiAuth = () => ({
  type: DISABLE_MULTIAUTH,
  payload: {
    request: {
      method: "delete",
      url: `/cdss/account/totp/disable/`,
      data: { consent: true },
    },
  },
});
