import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import episodePlus from "../../../../assets/images/episode-plus.svg";
import {
  isDateBetweenDates,
  timeToDecimal,
} from "../../../../utils/dataAndTime";
import moment from "moment";
const MKinetikosReport = ({
  reportCheckboxs,
  removeKey = null,
  addKey = null,
}) => {
  const svgRef = useRef(null);

  const info = useSelector((state) => state.patientsReports.report);

  const episodes = useSelector((state) => state.patientsReports.episodes);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    d3.selectAll(`#chart`).html("");
    const width = svgRef?.current.parentElement.offsetWidth;
    const height = 400;

    let data = {
      triage_score: [],
      test: [],
      survey: [],
      tapping: [],
      medicines: [],
      tug: [],
      brady: [],
    };

    let dates = [];

    info.forEach((item, index) => {
      data.triage_score.push(item.triage_score);
      data.test.push(item.test);
      data.survey.push(item.survey);
      data.medicines.push(item.medicines);
      data.tapping.push(item.tapping);
      data.tug.push(item.tug);
      data.brady.push(item.brady);
      dates.push(item.calculation_date);
    });

    var formatDay = function (d) {
      return dates[d];
    };

    const episodesHoursToValue = (time) => {
      const timeValue = timeToDecimal(time);
      const result = (timeValue * 100) / 24;
      return result;
    };

    const getIndexOfDate = (date) => {
      if (moment(date).isSameOrAfter(dates[dates.length - 1])) {
        return dates.length - 1;
      }
      for (let i = 0; i < dates.length - 1; i++) {
        if (isDateBetweenDates(date, dates[i], dates[i + 1])) {
          return i;
        }
      }
    };

    const xScale = d3
      .scaleLinear()
      .domain([0, dates.length - 1])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max([100, d3.max(data.triage_score)])])
      .range([height, 0]);

    const generateScaledLine = d3
      .line()
      .x((d, i) => xScale(i))
      .y(yScale)
      .curve(d3.curveCardinal)
      .defined((d3) => d3 !== null);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(dates.length)
      .tickFormat(formatDay);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(10)
      .tickFormat((d) => `${d}%`);

    const area = d3
      .area()
      .curve(d3.curveCardinal)
      .x(function (d, i) {
        return xScale(i);
      })
      .y0(height)
      .y1(function (d) {
        return yScale(d);
      });
    const makeChart = () => {
      const chart = d3

        .select(svgRef.current)
        .attr("id", "chart")
        .attr("width", width)
        .attr("height", height)
        .style("overflow", "visible");

      const xAxisGroup = chart
        .append("g")
        .call(xAxis)
        .attr("class", "axis")
        .attr("transform", `translate(0, ${height})`);

      xAxisGroup
        .selectAll("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-40)");

      const yAxisGroup = chart.append("g").call(yAxis).attr("class", "axis");
      for (const key in reportCheckboxs) {
        if (reportCheckboxs[key] && data[key]) {
          chart
            .append("path")
            .datum(data[key])
            .attr("id", `area${key}`)
            .attr("class", `area ${key}`)
            .attr("d", area);

          chart
            .append("path")
            .datum(data[key])
            .attr("id", `line${key}`)
            .attr("class", `line ${key}`)
            .attr("d", (d) => generateScaledLine(d));
        }
      }
      for (const key in reportCheckboxs) {
        if (reportCheckboxs[key] && data[key]) {
          chart
            .selectAll(`dot ${key}`)
            .data(data[key])
            .enter()
            .append("circle")
            .attr("id", `dot${key}`)
            .attr("class", `dot ${key}`)
            .attr("cx", function (d, i) {
              if (d !== null) return xScale(i);
            })
            .attr("cy", function (d) {
              if (d !== null) return yScale(d);
            })
            .attr("r", function (d) {
              return d !== null ? 5 : 0;
            })
            .on("mouseover", function (a, b, i) {
              d3.select(this).transition().duration("100").attr("r", 7);
              const x = d3.select(this).attr("cx");
              chart
                .append("line")
                .attr("x1", x)
                .attr("x2", x) //making a line for legend
                .attr("y1", yScale(0))
                .attr("y2", yScale(a))
                .attr("class", `dash ${key}`);

              chart
                .append("rect")
                .attr("class", `tooltip-it ${key}`)
                .attr("x", x - 67)
                .attr("y", yScale(a) - 94)
                .attr("rx", 4)
                .attr("ry", 4);

              chart
                .append("svg:text")
                .attr("id", "title")
                .attr("class", `text-title ${key}`)
                .attr("x", x)
                .attr("y", yScale(a) - 80)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "middle")
                .text(function (d) {
                  if (key === "triage_score") {
                    return t("patient.reports.risk_assessment");
                  } else {
                    return key === "medicines"
                      ? t(`patient.reports.prescribed_meds`)
                      : t(`patient.reports.${key}`);
                  }
                });

              chart
                .append("svg:text")
                .attr("id", "text-info")
                .attr("class", `text-info`)
                .attr("x", x)
                .attr("y", yScale(a) - 50)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "middle")
                .text(`Date: ${dates[xScale.invert(x)]}`);
              chart
                .append("svg:text")
                .attr("id", "text-info")
                .attr("class", `text-info`)
                .attr("x", x)
                .attr("y", yScale(a) - 30)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "middle")
                .text(`Score: ${Math.round(a)} %`);
            })
            .on("mouseout", function () {
              d3.select(this).transition().duration("100").attr("r", 5);
              d3.selectAll("line").remove();
              d3.selectAll("#title").remove();
              d3.selectAll("#text-info").remove();
              d3.selectAll("rect.tooltip-it").remove();
            });
        }
      }
      if (reportCheckboxs.events) {
        const indexsOfDatesY = [];
        chart
          .selectAll(`.image-boy`)
          .data(episodes)
          .enter()
          .append("svg:image")
          .attr("xlink:href", episodePlus)

          .attr("x", function (d, i) {
            if (d !== null) {
              const indexOfDate = getIndexOfDate(d.date);
              return xScale(indexOfDate) - 8;
            }
          })
          .attr("y", function (d) {
            // alert(indexsOfDates);
            if (d !== null) {
              const indexOfDate = getIndexOfDate(d.date);
              indexsOfDatesY.push(indexOfDate);
              let occurrences = 0;
              for (let i = 0; i < indexsOfDatesY.length; i++) {
                if (indexsOfDatesY[i] === indexOfDate)
                  occurrences = occurrences + 1;
              }
              return yScale(d === null ? 0 : 100 - occurrences * 10);
            }
          });
        // .on("mouseover", function (a, b, i) {
        //   d3.select(this).transition().duration("100").attr("r", 7);
        //   const x = d3.select(this).attr("x");

        //   const y = yScale(b) - 94;
        //   chart
        //     .append("line")
        //     .attr("x1", x + 100)
        //     .attr("x2", x) //making a line for legend
        //     .attr("y1", yScale(0))
        //     .attr("y2", yScale(b))
        //     .attr("class", `dash events`);

        //   chart
        //     .append("rect")
        //     .attr("class", `tooltip-it events`)
        //     .attr("x", x - 67)
        //     .attr("y", y)
        //     .attr("rx", 4)
        //     .attr("ry", 4);
        // })
        // .on("mouseout", function () {
        //   d3.select(this).transition().duration("100").attr("r", 5);
        //   d3.selectAll("line").remove();
        //   d3.selectAll("rect.tooltip-it").remove();
        // });
      }
    };
    makeChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportCheckboxs, info]);

  return (
    <div className="mkinetikos-report">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default React.memo(MKinetikosReport);
