import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPatientEvaluatioCardDetails } from "../../redux/actions/patients";
import HorizontalBars from "../charts/Report/horizontalBarsChart";
import LineChart from "../charts/Report/lineChart";
import SpiderChart from "../charts/Report/spiderChart";
import DonnutChart from "../charts/Report/donnutChart";

import { useParams } from "react-router-dom";
import Spinner from "../spinner";
import { useTranslation } from "react-i18next";
import { getLanguageID } from "../../utils/language";

const ReportModal = ({ chartId, url, closeModal }) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { patient_id, evaluation_id } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getPatientEvaluatioCardDetails(
        patient_id,
        evaluation_id,
        chartId,
        getLanguageID(i18n.language)
      )
    )
      .then((res) => {
        if (res.payload && res.payload.data) {
          console.log(res.payload.data.data.data);
          setData(res.payload.data.data);
        }
      })
      .catch(() => {
        alert("error");
      })
      .finally(() => setisLoading(false));
  }, [chartId]);
  return (
    <div onClick={closeModal} className="background-modal-container">
      <div className="message-modal-container">
        <div
          style={{ minHeight: "200px", padding: 0 }}
          className="message-modal position-relative"
        >
          {isLoading && <Spinner type={"big-blue"} />}
          {data && data.details.type === 21 && (
            <img
              alt="card"
              style={{ width: "900px", height: "500px" }}
              src={url}
            ></img>
          )}
          {data && data.details.type === 1 && <LineChart />}
          {data && data.details.type === 2 && (
            <HorizontalBars
              modal={true}
              details={data.details}
              dataTry={data.data}
            />
          )}
          {data && data.details.type === 4 && <DonnutChart />}
          {data && data.details.type === 9 && <SpiderChart />}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReportModal);
//16 15
