//* *********************************** REACT IMPORTS ************************************
import React, { useEffect, useState, useRef } from "react";

//* ********************************* EXTERNAL PACKAGES **********************************
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

//* *********************************** OUR COMPONENTS ***********************************
import DropdownOptions from "../../dropdownOptions";
import OptionsButton from "../../optionsButton";
import SaveTemplateModal from "../../evaluationTemplates/saveTemplateModal";
import Spinner from "../../spinner";

import useOnClickOutside from "hooks/useOnClickOutside";

//* ************************************** GLOBALS ***************************************
import {
  addNewEvaluationTemplate,
  downloadRawFile,
  downloadMetrics,
} from "../../../redux/actions/evaluationTemplates";
import { removeSpaces } from "utils/string";

import errorOutline from "../../../assets/images/evalModules/error_outline.svg";

const CurrModules = ({
  modules,
  patientId,
  evaluationId,
  getCurrentModuleTypeImage,
  deleteModule,
  withOptions = true,
}) => {
  //* ************************************** SETUP ***************************************
  const [selectedModuleIndex, setselectedModuleIndex] = useState(null);
  const [isSaveTemplateOpen, setIsSaveTemplateOpen] = useState(false);
  const [isSavingToInstitution, setIsSavingToInstitution] = useState(false);
  const [isSavingToUser, setIsSavingToUser] = useState(false);

  const dropDownRef = useRef(null);
  const optionsRef = useRef([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const fileTypes = { raw: "raw", csv: "csv" };

  //* ************************************** HANDLERS ***************************************
  const saveNewEvaluationTemplate = (
    is_owner_institution,
    evaluationTemplateTitle
  ) => {
    is_owner_institution
      ? setIsSavingToInstitution(true)
      : setIsSavingToUser(true);
    const template_ids = modules.map((module) => module.module_id);
    dispatch(
      addNewEvaluationTemplate(
        template_ids,
        evaluationTemplateTitle,
        is_owner_institution
      )
    )
      .then((res) => {
        setIsSaveTemplateOpen(false);
      })
      .finally(() =>
        is_owner_institution
          ? setIsSavingToInstitution(false)
          : setIsSavingToUser(false)
      );
  };

  useOnClickOutside([dropDownRef, optionsRef], () =>
    setselectedModuleIndex(null)
  );

  const saveFile = (data, filename, fileType) => {
    var a = document.createElement("a");
    a.style = "display: none";
    var blob = new Blob(data, {
      type:
        fileType === fileTypes.raw
          ? "application/x-zip-compressed"
          : "text/csv",
    });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = (type, evaluation_id, module_id, module_name) => {
    const filename = `<${evaluation_id}><${removeSpaces(
      module_name,
      "underscore"
    )}>${type === fileTypes.raw ? "_sensor_data.zip" : "_metrics.csv"}`;

    if (type === fileTypes.raw) {
      dispatch(downloadRawFile(evaluation_id, module_id)).then((response) => {
        saveFile([response.payload.data], filename, fileTypes.raw);
      });
    } else {
      dispatch(downloadMetrics(evaluation_id, module_id)).then((response) =>
        saveFile([response.payload.data], filename, fileTypes.csv)
      );
    }
  };

  //* ************************************** RENDER ***************************************
  return (
    <div className="col-sm-12 col-lg-6">
      <div className="row-space-between">
        <span className="small-title">
          {t("my_area.templates.modules")} ({modules.length})
        </span>
        {modules.length !== 0 && (
          <button
            className="small-button transparent-bordered-button"
            onClick={() => setIsSaveTemplateOpen(true)}
          >
            {t("buttons.save_as_template")}
          </button>
        )}
      </div>
      <div className="modules-list">
        {modules.map((module, index) => (
          <div
            key={module + index.toString()}
            className="module cursor-pointer"
            onClick={() =>
              history.push(
                `/patient/${patientId}/evaluations/${evaluationId}/modules/${module.id}`
              )
            }
          >
            <div className="col-1">
              <img
                className="module-image "
                src={getCurrentModuleTypeImage(module.module_type)}
                alt="More Blue"
              />
            </div>
            <div className="col-4 module-name">{module.name}</div>
            <div className="col-1 position-relative flex-d-row center-justify">
              {module.submission_status_id &&
                module.submission_status_id < 4 && (
                  <Spinner type={"small-waiting"} />
                )}
              {module.submission_status_id > 4 && (
                <img src={errorOutline} alt="error icon" />
              )}
            </div>

            <div className="col-4 flex-d-colum">
              <div className="mb-1 evaluation-data-text">
                {t("patient.evaluations.data")}
              </div>
              <div className="evaluation-data">
                {module.submission_status_id &&
                module.submission_status_id < 4 ? (
                  <span className="orange-color">
                    <Trans i18nKey={"patient.evaluations.queue_position"}>
                      {module.queue_position?.toString()}
                    </Trans>
                  </span>
                ) : module.submission_status_id > 4 ? (
                  <span className="warning-color">
                    {t("patient.evaluations.error_handling_request")}
                  </span>
                ) : module.score === null ? (
                  module.answered ? (
                    t("patient.evaluations.filled")
                  ) : (
                    t("patient.evaluations.no_data")
                  )
                ) : (
                  `${Math.round(module.score)}% ${t(
                    "patient.evaluations.score"
                  )}`
                )}
              </div>
            </div>
            {withOptions && (
              <div className="more-image-wrapper col-1">
                <OptionsButton
                  ref={(el) => (optionsRef.current[index] = el)}
                  isSelected={selectedModuleIndex === index.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectedModuleIndex === index
                      ? setselectedModuleIndex(null)
                      : setselectedModuleIndex(index);
                  }}
                />
                {selectedModuleIndex === index && (
                  <DropdownOptions
                    ref={dropDownRef}
                    buttons={[
                      {
                        text: (
                          <span className="regular-text">
                            {t("buttons.edit")}
                          </span>
                        ),
                        action: () => {
                          history.push(
                            `/patient/${patientId}/evaluations/${evaluationId}/modules/${module.id}`
                          );
                        },
                      },
                      module.answered && module.module_type === 4
                        ? {
                            text: (
                              <span className="regular-text">
                                {t("buttons.download_raw")}
                              </span>
                            ),
                            action: () => {
                              downloadFile(
                                "raw",
                                evaluationId,
                                module.id,
                                module.name
                              );
                            },
                          }
                        : "",
                      module.submission_status_id && // module has 12 submission_status_id ids
                      module.submission_status_id === 4 && // submission_status_id = 4 is the id for success from backend
                      module.module_type === 4 // module_type = 4 meaning, we will consider only "Kinematics tests"
                        ? {
                            text: (
                              <span className="regular-text">
                                {t("buttons.download_metrics")}
                              </span>
                            ),
                            action: () => {
                              downloadFile(
                                "metrics",
                                evaluationId,
                                module.id,
                                module.name
                              );
                            },
                          }
                        : "",
                      {
                        text: <span className="regular-text">{"Clear"}</span>,
                        action: () => {
                          setselectedModuleIndex(null);
                          deleteModule(index, false);
                        },
                      },
                      {
                        text: (
                          <span className="regular-text warning">
                            {t("buttons.delete")}
                          </span>
                        ),
                        action: () => {
                          setselectedModuleIndex(null);
                          deleteModule(index, true);
                        },
                      },
                    ]}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {isSaveTemplateOpen && (
        <SaveTemplateModal
          saveNewEvaluationTemplate={saveNewEvaluationTemplate}
          cancel={() => setIsSaveTemplateOpen(false)}
          isSavingToUser={isSavingToUser}
          isSavingToInstitution={isSavingToInstitution}
          hasTextInput={true}
        />
      )}
    </div>
  );
};

export default React.memo(CurrModules);
