export const SET_REDIRECT = "SET_REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";

export const setRedirectTo = (url) => ({
  type: SET_REDIRECT,
  payload: { url },
});

export const clearRedirectTo = () => ({
  type: CLEAR_REDIRECT,
});
