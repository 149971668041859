export const getAvatarWithInitials = (size, name) => {
  if (size && name) {
    let firstInitial = "-";
    let lastInitial = "-";
    if (name !== "") {
      const nameLst = name.split(" ");
      firstInitial = nameLst[0][0].toUpperCase();
      if (nameLst.length < 2) {
        lastInitial = "";
      } else {
        lastInitial = nameLst[nameLst.length - 1][0].toUpperCase();
      }
    }

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const g = document.createElementNS("http://www.w3.org/2000/svg", "g");
    const circle = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");

    svg.setAttribute("height", `${size}`);
    svg.setAttribute("width", `${size}`);

    circle.setAttribute("cx", `${size / 2}`);
    circle.setAttribute("cy", `${size / 2}`);
    circle.setAttribute("r", `${size / 2}`);
    circle.setAttribute("fill", "#EFF3F6");

    text.setAttribute("x", "50%");
    text.setAttribute("y", "50%");
    text.setAttribute("text-anchor", "middle");
    text.setAttribute("dy", ".4em");
    text.setAttribute("font-size", `${size / 2}`);
    text.setAttribute("font-family", "sans-serif");
    text.setAttribute("fill", "#4C657A");
    text.innerHTML = firstInitial + lastInitial;

    g.appendChild(circle);
    g.appendChild(text);
    svg.appendChild(g);

    const XML = new XMLSerializer().serializeToString(svg);
    const SVG64 = window.btoa(XML);
    return "data:image/svg+xml;base64," + SVG64;
  } else {
    return null;
  }
};

export const nameShortener = (name) => {
  let firstInitial = "-";
  let lastInitial = "-";
  if (name !== "") {
    const nameLst = name.split(" ");
    firstInitial = nameLst[0][0].toUpperCase();
    if (nameLst.length < 2) {
      lastInitial = "";
    } else {
      lastInitial = nameLst[nameLst.length - 1][0].toUpperCase();
    }
  }

  return firstInitial + lastInitial;
};
