//* ************************************** REACT IMPORTS *************************************
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

//* ************************************ EXTERNAL IMPORTS ************************************
import { useTranslation } from "react-i18next";

//* ***************************************** ACTIONS ****************************************
import { getCollaborators } from "../../../../../redux/actions/institutions";

//* ************************************* OUR COMPONENTS *************************************
import AddCollaborator from "../../../../../components/addCollaborator";
import Paginator from "../../../../../components/paginator";
import Spinner from "../../../../../components/spinner";

//* ************************************** CUSTOM HOOKS **************************************
import useSearch from "hooks/useSearch";

//* ****************************************** UTILS *****************************************

//* ***************************************** STYLES *****************************************

//* ***************************************** ASSETS *****************************************
import arrowRight from "../../../../../assets/images/arrow-right.svg";
import plusBlue from "../../../../../assets/images/plus-blue-background-white.svg";
import search from "../../../../../assets/images/search.svg";
import chevronUpSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-up_dark-green.svg";
import chevronUpIcon from "assets/images/evaluationsModuleIcons/chevron-up_light-green.svg";
import chevronDownIcon from "assets/images/evaluationsModuleIcons/chevron-down_light-green.svg";
import chevronDownSelectedIcon from "assets/images/evaluationsModuleIcons/chevron-down_dark-green.svg";
import userIcon from "assets/images/buttonIcons/person.svg";

const Collaborators = ({ canUserEdit, goToUser }) => {
  //* **************************************** SETUP *****************************************
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCollaboratorModalOpen, setIsAddCollaboratorModalOpen] = useState(false);
  const { institution_id } = useParams();
  const collaborators = useSelector((state) => state.institutions.collaborators);

  const currentUserId = useSelector((state) => state.user.widget_info.userinfo_id);

  const getCollaboratorsList = (page) => {
    setIsLoading(true);
    dispatch(
      getCollaborators(institution_id, {
        page: searchOptions?.page,
        search: searchOptions?.q,
        order_by: searchOptions?.orderBy || "status",
        items_per_page: searchOptions?.itemsPerPage,
      })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {});
  };

  const { searchOptions, updateSearchText, updatePage, updateOrderBy } = useSearch(
    getCollaboratorsList,
    300,
    "collaborators",
    "status"
  );

  const { t, i18n } = useTranslation();
  //* ************************************** PARTIALS ****************************************

  //* *************************************** RENDER *****************************************
  return (
    <div className="col-lg col-md-12 colaborators-box-wrapper mt-3">
      <div className="row align-items-center mb-4">
        <div className="col small-title">{t("my_area.institutions.list_of_collaborators")}</div>
        {canUserEdit && (
          <div className="col-auto">
            <button
              onClick={() => setIsAddCollaboratorModalOpen(true)}
              className="button small-button primary-button"
            >
              <img alt="plus-icon" className="button-icon" src={plusBlue} />
              {t("buttons.add_collaborator")}
            </button>
          </div>
        )}
      </div>
      <div className="position-relative mb-4">
        <input
          value={searchOptions?.q}
          onChange={(e) => updateSearchText(e.target.value)}
          type="text"
          className="search-input w-100"
          placeholder="Insert a term to match module name"
        />
        <img alt="search-icon" className="search-icon" src={search} />
      </div>
      <div className="row align-items-center list-label">
        <div
          className="col-7 d-flex pointer"
          onClick={() => {
            updateOrderBy(
              searchOptions?.orderBy === "name"
                ? "name_inv"
                : searchOptions?.orderBy === "name_inv"
                ? "status"
                : "name"
            );
          }}
        >
          {t("user_info.name")}
          <div
            className={`order-img-container ${
              searchOptions?.orderBy === "name" || searchOptions?.orderBy === "name_inv"
                ? "active"
                : ""
            }`}
          >
            {searchOptions?.orderBy !== "name" && (
              <img
                src={searchOptions?.orderBy === "name_inv" ? chevronUpSelectedIcon : chevronUpIcon}
                alt="Order by Name"
              />
            )}
            {searchOptions?.orderBy !== "name_inv" && (
              <img
                src={searchOptions?.orderBy === "name" ? chevronDownSelectedIcon : chevronDownIcon}
                alt="Order by Name Invert"
              />
            )}
          </div>
        </div>
        <div
          className="col d-flex pointer"
          onClick={() => {
            updateOrderBy(
              searchOptions?.orderBy === "role"
                ? "role_inv"
                : searchOptions?.orderBy === "role_inv"
                ? "status"
                : "role"
            );
          }}
        >
          {t("user_info.role")}
          <div
            className={`order-img-container ${
              searchOptions?.orderBy === "role" || searchOptions?.orderBy === "role_inv"
                ? "active"
                : ""
            }`}
          >
            {searchOptions?.orderBy !== "role" && (
              <img
                src={searchOptions?.orderBy === "role_inv" ? chevronUpSelectedIcon : chevronUpIcon}
                alt="Order by Role"
              />
            )}
            {searchOptions?.orderBy !== "role_inv" && (
              <img
                src={searchOptions?.orderBy === "role" ? chevronDownSelectedIcon : chevronDownIcon}
                alt="Order by Role Invert"
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <ul className="col collaborators-items-list mt-1">
          {isLoading ? (
            <div className="position-relative p-5">
              <Spinner type={"big-blue"} />
            </div>
          ) : (
            collaborators.obj_list.map((user, index) => (
              <li
                onClick={() => goToUser(user.id)}
                key={user.name + index.toString()}
                role="button"
                className="row regular-text black-color"
                disabled={user.status !== "pending" && user.status !== "active"}
              >
                <div className="col-7">
                  {user.name || user.email}{" "}
                  {currentUserId === user.id && <img height={12} width={12} src={userIcon} />}
                </div>
                <div className="col">{t(`roles.${user.role_name}`)}</div>

                <div className="col-auto">
                  <img src={arrowRight} alt="Arrow right" />
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
      {collaborators.obj_list.length > 0 && (
        <div className="pagination-wrapper">
          <Paginator
            currPage={collaborators.page}
            totalPages={collaborators.num_pages}
            maxPages={3}
            changePage={updatePage}
          />
        </div>
      )}
      {isAddCollaboratorModalOpen && (
        <AddCollaborator closeModal={() => setIsAddCollaboratorModalOpen(false)} />
      )}
    </div>
  );
};
export default React.memo(Collaborators);
