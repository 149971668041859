export const CREATE_TICKET = "CREATE_TICKET";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";

export const GET_TICKETS = "GET_TICKETS";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAIL = "GET_TICKETS_FAIL";

export const GET_TICKET_INFO = "GET_TICKET_INFO";
export const GET_TICKET_INFO_SUCCESS = "GET_TICKET_INFO_SUCCESS";
export const GET_TICKET_INFO_FAIL = "GET_TICKET_INFO_FAIL";

export const GET_TICKET_REPLIES = "GET_TICKET_REPLIES";
export const GET_TICKET_REPLIES_SUCCESS = "GET_TICKET_REPLIES_SUCCESS";
export const GET_TICKET_REPLIES_FAIL = "GET_TICKET_REPLIES_FAIL";

export const CREATE_TICKET_REPLY = "CREATE_TICKET_REPLY";
export const CREATE_TICKET_REPLY_SUCCESS = "CREATE_TICKET_REPLY_SUCCESS";
export const CREATE_TICKET_REPLY_FAIL = "CREATE_TICKET_REPLY_FAIL";

export const UPDATE_TICKET_REPLY = "UPDATE_TICKET_REPLY";
export const UPDATE_TICKET_REPLY_SUCCESS = "UPDATE_TICKET_REPLY_SUCCESS";
export const UPDATE_TICKET_REPLY_FAIL = "UPDATE_TICKET_REPLY_FAIL";

export const createTicket = (title, message) => ({
  type: CREATE_TICKET,
  payload: {
    request: {
      method: "post",
      url: "/cdss/my_area/support/tickets/new/",
      data: { title, message },
    },
  },
});

export const getTickets = ({
  page = 1,
  order_by = "-creation_date",
  items_per_page = 5,
} = {}) => ({
  type: GET_TICKETS,
  payload: {
    request: {
      method: "get",
      url: "/cdss/my_area/support/tickets",
      params: { page, order_by, items_per_page },
    },
  },
});

export const getTicketInfo = (ticket_id) => ({
  type: GET_TICKET_INFO,
  payload: {
    request: {
      method: "get",
      url: `/cdss/my_area/support/tickets/${ticket_id}/`,
    },
  },
});

export const getTicketReplies = (ticket_id, items_per_page = 20) => ({
  type: GET_TICKET_REPLIES,
  payload: {
    request: {
      method: "get",
      url: `/cdss/my_area/support/tickets/${ticket_id}/replies/`,
      params: { items_per_page },
    },
  },
});

export const createTicketReply = (ticket_id, message) => ({
  type: CREATE_TICKET_REPLY,
  payload: {
    request: {
      method: "post",
      url: `/cdss/my_area/support/tickets/${ticket_id}/replies/new/`,
      data: { message },
    },
  },
});

export const updateTicketReply = (ticket_id, ticket_trial_id, message) => ({
  type: UPDATE_TICKET_REPLY,
  payload: {
    request: {
      method: "put",
      url: `/cdss/my_area/support/tickets/${ticket_id}/replies/${ticket_trial_id}/`,
      data: { id: ticket_trial_id, message: message },
    },
  },
});
