import React from "react";

import Icon from "components/icon";

import { icons } from "components/icon/icons";
import closeIcon from "assets/images/close_primary_back_white.svg";

const MessageModal = ({
  headerText,
  body = "",
  hasCloseButton = false,
  handleClose,
  htmlContent = null,
  footerButtons = [],
  isDisable = false,
  customStyleClass = {
    messageModal: "",
    headerText: "",
    body: "",
    footerButtons: "",
  },
}) => {
  return (
    <div className="background-modal-container">
      <div className="message-modal-container">
        <div
          className={`message-modal ${
            customStyleClass?.messageModal !== "" &&
            customStyleClass.messageModal
          }`}
        >
          <span
            className={`title-text ${
              customStyleClass?.headerText !== "" && customStyleClass.headerText
            }`}
          >
            {headerText}
          </span>
          <div
            className={`message-body-wrapper ${
              customStyleClass?.body !== "" && customStyleClass.body
            }`}
            disabled={isDisable}
          >
            {body}
          </div>
          {htmlContent}
          <div
            className={`footer-wrapper ${
              customStyleClass?.footerButtons !== "" &&
              customStyleClass.footerButtons
            }`}
            disabled={isDisable}
          >
            {footerButtons.map((button, index) => (
              <div
                key={index.toString()}
                className={`${
                  index + 1 !== footerButtons.length && "padding-right"
                }`}
              >
                {button}
              </div>
            ))}
          </div>
        </div>
        {hasCloseButton && handleClose && (
          <img
            src={closeIcon}
            className="message-modal-close"
            onClick={handleClose}
          />
        )}
      </div>
    </div>
  );
};

export default MessageModal;
